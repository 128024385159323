import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { AppState } from 'react-native';

export const useEffectOnForeground = (
  effect: EffectCallback,
  deps?: DependencyList,
): void => {
  const appState = useRef<string | null>(null);
  const destroy = useRef<any>(() => {});
  const handler = () => {
    if (
      (!appState.current || appState.current.match(/inactive|background/)) &&
      AppState.currentState === 'active'
    ) {
      destroy.current = effect();
    } else {
      if (typeof destroy.current === 'function') {
        destroy.current();
        destroy.current = () => {};
      }
    }
    appState.current = AppState.currentState;
  };

  useEffect(() => {
    const subscription = AppState.addEventListener('change', handler);
    handler();
    return () => {
      subscription.remove();
      if (typeof destroy.current === 'function') {
        destroy.current();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
