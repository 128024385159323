import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import CircularProgress from '../../Common/components/common/CircularProgress';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';

type ScreenProps = {
  progress: number;
};

export const TestPracticeProgressHeader = ({
  progress,
}: ScreenProps): React.ReactElement => {
  return (
    <View style={styles.container}>
      <CircularProgress
        percentage={progress}
        radius={18}
        color={Colors.additional.orange[100]}
        containerStyle={styles.progressContainer}>
        <AssetImage
          width={14}
          asset={Assets.test.bullseyeArrow}
          fill={Colors.additional.orange[100]}
        />
      </CircularProgress>
      <Text style={styles.topic} numberOfLines={2}>
        {locale.testPrep.progress_header_text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    marginStart: 2,
  },
  container: {
    top: 5,
    backgroundColor: Colors.white10,
    width: '95%',
    alignSelf: 'center',
    borderRadius: 100,
    padding: 5,
    flexDirection: 'row',
    columnGap: 10,
    alignItems: 'center',
    minHeight: 44,
  },
  topic: {
    ...Fonts.smallBold,
    flex: 1,
    marginEnd: 5,
  },
});
