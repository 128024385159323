import { BetaFeedbackSource, ExamsGoals } from '../../Common/entities';
import { GenerateQuestionResponse } from '../../Learn/entities';

export enum FeedbackTypes {
  like = 'LIKE',
  dislike = 'DISLIKE',
  bookmark = 'BOOKMARK',
  share = 'SHARE',
  report_incorrect = 'REPORT_INCORRECT',
  report_unrelated = 'REPORT_UNRELATED',
  report_offensive = 'REPORT_OFFENSIVE',
  report_not_interested = 'REPORT_NOT_INTERESTED',
  report_other = 'REPORT_OTHER',
}

export type GetInteractedContentRequest = {
  interactionType: FeedbackTypes;
  pageNum: number;
  pageSize: number;
};

export type GetInteractedContentResponse = {
  totalCount: number;
  items: GenerateQuestionResponse[];
};

export enum AppFeedbackType {
  BUG = 'Bug',
  FEATURE_FEEDBACK = 'Feature Feedback',
  CONTENT_FEEDBACK = 'Content Feedback',
  GENERAL_FEEDBACK = 'General Feedback',
  COMPLAINT = 'Complaint',
}

export type AppFeedbackData = {
  userName?: string;
  userEmail: string;
  appVersion: string;
  deviceType: string;
  deviceModel: string;
  deviceOS: string;
  feedbackType: AppFeedbackType;
  feedback: string;
  imageVideoLink?: string;
  isMediaAttached: boolean;
  viewedContentIds?: string;
  courseName?: string;
  unitName?: string;
  source?: BetaFeedbackSource;
  followedCourses?: string;
  appGoal?: ExamsGoals;
  activeCourse?: string;
  activeCourseGoal?: string;
};

export enum ExamResultsFeedbackZoomConsent {
  YES = 'Yes',
  NO = 'No',
}

export type ExamResultsFeedbackDetails = {
  feedback: string;
  zoomConsent?: ExamResultsFeedbackZoomConsent;
};

export type ExamResultsFeedbackData = ExamResultsFeedbackDetails & {
  userName?: string;
  email: string;
  appVersion: string;
  deviceType: string;
  deviceModel: string;
  deviceOS: string;
  appGoal?: ExamsGoals;
};
