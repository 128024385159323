import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { getUserSATProgramDetailsGraphQLCall } from '../../../../Common/graphql';
import { SAT_SLICE_NAME, SATSliceState } from '../SATSlice';

import { checkForFreeTrialExpiration } from './CheckForFreeTrialExpirationAction';

export const getUserSATProgramDetails = createAsyncThunk(
  `${SAT_SLICE_NAME}/getUserSATProgramDetails`,
  async (_, thunkAPI) => {
    try {
      const details = await getUserSATProgramDetailsGraphQLCall();
      thunkAPI.dispatch(checkForFreeTrialExpiration(details ?? undefined));

      return details ?? undefined;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }

      return undefined;
    }
  },
);

export const getUserSATProgramDetailsExtraReducers = (
  builder: ActionReducerMapBuilder<SATSliceState>,
): void => {
  builder.addCase(getUserSATProgramDetails.fulfilled, (state, action) => {
    state.bootcamp = action.payload;
  });
  builder.addCase(getUserSATProgramDetails.rejected, (state, action) => {
    state.bootcamp = undefined;
  });
};
