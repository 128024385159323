import { OnboardingStack } from '../../Common/services/utils/AppConstants';

export const SAT_ONBOARDING_SCREENS = [
  OnboardingStack.EXAM_INPUTS,
  OnboardingStack.LOADING_SCREEN,
  OnboardingStack.SUMMARY,
  OnboardingStack.SAT_TUTORIAL_SCREEN,
];

export const AP_ONBOARDING_SCREENS = [
  OnboardingStack.AP_COURSE_SELECTION,
  OnboardingStack.AP_TARGET_GRADE_SELECTION,
  OnboardingStack.AP_PERSONALIZED_STUDY_PLAN_ANIMATION,
  OnboardingStack.AP_PERSONALIZED_STUDY_PLAN,
  OnboardingStack.AP_SAT_BOOTCAMP_UPSELL,
  OnboardingStack.AP_COURSES_COMING_SOON,
];
