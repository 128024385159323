import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { COMMON_MAX_REPORT_COMMITMENT_TIME_MINUTES } from '../../../../Common/data';
import { CommonStudyMode } from '../../../../Common/entities';
import { getIsAppActive } from '../../../../Common/services/utils';
import { mapApProgramDetailsAvailableStatus } from '../../mappers';
import { AP_SLICE_NAME, setApLearnModeTimeSpentSeconds } from '../APSlice';

import { recordApProgramTimeSpent } from './RecordApProgramTimeSpentAction';

export const incrementApLearnModeTimeSpent = createAsyncThunk(
  `${AP_SLICE_NAME}/incrementApLearnModeTimeSpent`,
  (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apState = rootState.AP;

    const isProgramAvailable = mapApProgramDetailsAvailableStatus(rootState);
    const isAppActive = getIsAppActive();

    if (!isProgramAvailable || !courseId || !isAppActive) {
      return;
    }

    const nextLearnModeTimeSpent =
      (apState.learnModeTimeSpentSeconds[courseId] || 0) + 1;

    const timeSpentMinutes = Math.floor(nextLearnModeTimeSpent / 60);
    if (timeSpentMinutes >= COMMON_MAX_REPORT_COMMITMENT_TIME_MINUTES) {
      thunkAPI.dispatch(
        recordApProgramTimeSpent({
          courseId,
          timeSpentMinutes,
          studyMode: CommonStudyMode.LEARN,
        }),
      );

      thunkAPI.dispatch(
        setApLearnModeTimeSpentSeconds({
          courseId,
          timeSpentSeconds: 0,
        }),
      );
      return;
    }

    thunkAPI.dispatch(
      setApLearnModeTimeSpentSeconds({
        courseId,
        timeSpentSeconds: nextLearnModeTimeSpent,
      }),
    );
  },
);
