import { SATBootcamp } from '../../SAT/entities';

export type GetUserSATProgramDetailsResponse = SATBootcamp | null;

export enum CommonStudyMode {
  TEST_PREP = 'TEST_PREP',
  LEARN = 'LEARN',
}

export type ReportStudyTimeRequest = {
  minutesSpent: number;
  timestamp: string; // yyyy-MM-ddTHH:mm:ss.SSSZ
  courseId: string;
  studyMode: CommonStudyMode;
};

export type ReportStudyTimeResponse = {
  success: boolean;
};

export type APProgramCourse = {
  id: string;
  examDate: string;
  targetGrade: string;

  currentDuration: number;
  currentDurationStartDate: string;
  currentDurationEndDate: string;

  timeSpentMinutes: number;
  targetTimeCommitmentHours: number;
  targetTimeCommitmentPeriod: CommonPeriodType;
};

export type APProgramDetails = {
  enrolledAt: string; // yyyy-MM-dd
  courses: APProgramCourse[];
};

export type GetUserAPProgramDetailsResponse = {
  details?: APProgramDetails;
  accessDetails: ProgramAccessDetails;
};

export type ProgramAccessDetails = {
  accessType: ProgramAccessType;
  expiresAt: string; // yyyy-MM-ddTHH:mm:ss.SSSZ
};

export enum ProgramAccessType {
  FULL_ACCESS = 'FULL_ACCESS',
  EXPIRED = 'EXPIRED',
  TRIAL = 'TRIAL',
}

export enum ProgramType {
  CRASH_COURSE = 'CRASH_COURSE',
  BOOTCAMP = 'BOOTCAMP',
  AP_PROGRAM = 'AP_PROGRAM',
}

export enum CommonPeriodType {
  DAY = 'DAY',
  WEEK = 'WEEK',
}
