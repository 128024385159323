import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import Avatar from '../../Common/components/common/Avatar';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';

type TooltipProps = {
  message: string;
};

const CONTAINER_WIDTH = isWebPlatform
  ? (Sizes.getAppWindowWidth() / 5) * 3
  : Sizes.getAppWindowWidth() - 150;
const TOOLTIP_MAX_HEIGHT = isWebPlatform ? undefined : 55;

export const VideoWithQuestionTooltip: React.FC<TooltipProps> = ({
  message,
}) => {
  const loggedinUser = useAppSelector(state => state.auth.authUser);

  const username = useMemo(() => {
    return (
      loggedinUser?.preferredName ??
      loggedinUser?.email ??
      locale.dms_screen.default_username
    );
  }, [loggedinUser]);

  return (
    <View style={styles.container}>
      <View style={styles.replyContainer}>
        <Text
          style={styles.replyText}
          numberOfLines={isWebPlatform ? 2 : 1}
          ellipsizeMode="tail">
          {locale.dms_screen.reply_username.replace('${USERNAME}', username)}
        </Text>
      </View>
      <View style={styles.subContainer}>
        <Avatar name={username} size={40} />
        <Text style={styles.tooltipText} numberOfLines={3} ellipsizeMode="tail">
          {message}
        </Text>
      </View>
      <View>
        <View style={styles.triangle} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: CONTAINER_WIDTH,
    bottom: '30%',
    left: 16,
    borderRadius: 10,
  },
  subContainer: {
    flexDirection: 'row',
    columnGap: 5,
    padding: 10,
    alignItems: 'flex-start',
  },
  tooltipText: {
    ...Fonts.smallBold,
    color: 'black',
    fontSize: Fonts.semiMediumBold.fontSize,
    flexShrink: 1,
    maxHeight: TOOLTIP_MAX_HEIGHT,
    marginLeft: 4,
    marginRight: 4,
  },
  triangle: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopEndRadius: 5,
    borderTopStartRadius: 20,
    borderLeftWidth: 35,
    borderRightWidth: 0,
    borderBottomWidth: 30,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: 'white',
    alignSelf: 'flex-start',
    position: 'absolute',
    transform: [{ translateX: 0 }, { translateY: -8 }, { rotate: `${180}deg` }],
  },
  replyText: {
    ...Fonts.xsmall,
    color: Colors.black40,
    alignSelf: 'flex-start',
    paddingTop: 2,
    marginRight: 4,
  },
  replyContainer: {
    left: 59,
    top: 8,
    right: 5,
  },
});
