/**
 * Throttle function
 */
export function throttle<T extends (...args: any[]) => any>(
  func: T,
  limit: number,
): (...args: Parameters<T>) => void {
  let lastFunc: ReturnType<typeof setTimeout> | null;
  let lastRan: number | null;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    const context = this;
    if (lastRan === null || Date.now() - lastRan >= limit) {
      if (lastFunc) {
        clearTimeout(lastFunc);
      }

      func.apply(context, args);
      lastRan = Date.now();
    } else {
      if (lastFunc) {
        clearTimeout(lastFunc);
      }

      lastFunc = setTimeout(() => {
        func.apply(context, args);
        lastRan = Date.now();
      }, limit - (Date.now() - (lastRan as number)));
    }
  };
}

/**
 * Throttle a function to ensure it only runs once every specified delay.
 * If invoked again within the delay, it will be called after the timeout.
 * @param func - The function to throttle
 * @param delay - The delay in milliseconds
 * @returns A throttled version of the function
 */
export function throttleAsync<T>(
  func: () => Promise<T>,
  delay: number = 5000,
): () => Promise<T> {
  let lastExecutionTime = 0;
  let timeoutId: NodeJS.Timeout | null = null;

  return async function () {
    const now = Date.now();
    const timeSinceLastExecution = now - lastExecutionTime;

    if (timeSinceLastExecution >= delay) {
      lastExecutionTime = now;
      return func();
    } else {
      return new Promise(resolve => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(async () => {
          lastExecutionTime = Date.now();
          resolve(await func());
        }, delay - timeSinceLastExecution);
      });
    }
  };
}
