import { createAsyncThunk } from '@reduxjs/toolkit';

import { finalizeApLearnModeTimeSpent } from '../../../../AP/services/slices';
import { RootState } from '../../../../App/store';
import { isAPCourse } from '../../../../Common/services/utils';
import { mapCourseDataById } from '../../mappers';
import { FEED_SLICE_NAME } from '../FeedSlice';

export const finalizeLearnModeTimeSpent = createAsyncThunk(
  `${FEED_SLICE_NAME}/finalizeLearnModeTimeSpent`,
  (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const courseData = mapCourseDataById(rootState, courseId);

    if (!courseData?.id) {
      return;
    }

    if (isAPCourse(courseData)) {
      thunkAPI.dispatch(finalizeApLearnModeTimeSpent(courseData.id));
      return;
    }
  },
);
