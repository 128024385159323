import { Card } from '../../../Common/entities';
import { mapCardToAnalyticsPayload } from '../../../Common/services/mappers';
import { SubmitAnswerPayload } from '../../entities';

export const mapCardToSubmitAnswerPayload = (
  card: Card,
  isCorrect: boolean | null,
): SubmitAnswerPayload => ({
  ...mapCardToAnalyticsPayload(card),
  correct: isCorrect,
});
