import moment from 'moment';

import { PurchaseType } from '../../../Paywall/entities';
import {
  BootcampEntryPoint,
  SATBootcampProgramAnalytics,
  SATBootcampProgramDetails,
  SATBootcampUpsellShownAnalytics,
  SATBootcampUpsellShownAnalyticsParams,
} from '../../entities';

export const mapSATBootcampSummaryAnalytics = (
  SATProgramDetails?: SATBootcampProgramDetails,
): SATBootcampProgramAnalytics => {
  if (!SATProgramDetails) {
    return {
      bootcampName: '',
      bootcampDuration: '',
      targetScore: 0,
      examDate: '',
      currentScore: 0,
      timeCommitment: '',
    };
  }

  const userLocalDate = moment(
    SATProgramDetails.userLocalTimestamp,
    'YYYY-MM-DDTHH:mm:ss',
  ).format('YYYY-MM-DD');
  const managedExamDate = moment(SATProgramDetails.examDate, 'YYYY-MM-DD')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  return {
    bootcampName: SATProgramDetails?.programName,
    bootcampDuration: `${userLocalDate} - ${managedExamDate}`,
    targetScore: SATProgramDetails.targetScore,
    examDate: SATProgramDetails.examDate,
    currentScore: SATProgramDetails.currentScore,
    timeCommitment: SATProgramDetails.studyCommitmentTitle,
  };
};

export const mapSATBootcampUpsellShownAnalytics = (
  params: SATBootcampUpsellShownAnalyticsParams,
): SATBootcampUpsellShownAnalytics => {
  const { discount, satProgramDetails, bootcampEntryPoint } = params;

  return {
    course: satProgramDetails?.programName ?? '',
    examDate: satProgramDetails?.examDate ?? '',
    from: bootcampEntryPoint ?? BootcampEntryPoint.AP_RESULTS,
    discount: `${discount ?? 0}%`,
    purchaseType: PurchaseType.SAT_BOOTCAMP,
  };
};
