import React, { useMemo } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  onPreviousQuestion: () => void;
  onNextQuestion: () => void;
  onSubmitTest: () => void;
  currentQuestionIndex: number;
  questionsLength: number;
};

export const TestQuestionNavigationButtons = ({
  onPreviousQuestion,
  onNextQuestion,
  onSubmitTest,
  currentQuestionIndex,
  questionsLength,
}: Props): React.ReactElement => {
  const isLoading = useAppSelector(state => state.testPrep.isLoading);

  const isLastQuestion = currentQuestionIndex === questionsLength - 1;

  const canGoBack = currentQuestionIndex > 0;

  const questions = useAppSelector(
    state => state.testPrep.currentTest?.questions ?? [],
  );
  const allQuestionsAnswered = useMemo(
    () => questions.every(question => !!question.chosenOption),
    [questions],
  );

  const isReviewMode = useAppSelector(
    state => state.testPrep.currentTest?.resultsSeen ?? false,
    (prev, next) => prev === next,
  );

  const managedData = useMemo(() => {
    const shouldShowNextButton = isReviewMode || !isLastQuestion;
    const shouldShowSubmitButton =
      !isReviewMode && (allQuestionsAnswered || isLastQuestion);
    const hasNextQuestion = !isLastQuestion;

    return { shouldShowNextButton, shouldShowSubmitButton, hasNextQuestion };
  }, [allQuestionsAnswered, isLastQuestion, isReviewMode]);

  const managedStyles = useMemo(() => {
    return {
      backButton: [
        styles.backButton,
        (isLoading || !canGoBack) && styles.buttonDisabled,
      ],
      nextButton: [
        styles.nextButton,
        managedData.shouldShowSubmitButton && styles.shortNextButton,
        (isLoading || !managedData.hasNextQuestion) && styles.buttonDisabled,
      ],
      submitButton: [styles.nextButton, isLoading && styles.buttonDisabled],
    };
  }, [
    canGoBack,
    isLoading,
    managedData.hasNextQuestion,
    managedData.shouldShowSubmitButton,
  ]);

  return (
    <View style={styles.buttonContainer}>
      <View style={[styles.navigationButtons]}>
        <TouchableOpacity
          style={managedStyles.backButton}
          onPress={onPreviousQuestion}
          disabled={!canGoBack || isLoading}>
          <AssetImage
            asset={Assets.test.previousQuestionIcon}
            containerStyle={styles.buttonIconContainer}
            width={Sizes.medium}
          />
        </TouchableOpacity>
        {managedData.shouldShowNextButton && (
          <TouchableOpacity
            style={managedStyles.nextButton}
            disabled={isLoading || !managedData.hasNextQuestion}
            onPress={onNextQuestion}>
            <Text style={styles.nextButtonText}>{locale.common.next}</Text>
          </TouchableOpacity>
        )}
        {managedData.shouldShowSubmitButton && (
          <TouchableOpacity
            style={managedStyles.submitButton}
            disabled={isLoading}
            onPress={onSubmitTest}>
            {isLoading ? (
              <ActivityIndicator color={'white'} />
            ) : (
              <Text style={styles.nextButtonText}>
                {locale.testPrep.submit_test}
              </Text>
            )}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    height: 44,
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    marginTop: Sizes.normalizeIP14PM(15),
  },
  navigationButtons: {
    flexDirection: 'row',
    gap: Sizes.small,
    paddingBottom:
      Sizes.safeAreaInsetsBottom === 0 ? Sizes.normalizeIP14PM(15) : 0,
  },
  backButton: {
    aspectRatio: 1,
    height: 44,
    backgroundColor: Colors.white10,
    borderRadius: Sizes.small,
    padding: Sizes.small,
  },
  buttonIconContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextButton: {
    height: 44,
    backgroundColor: Colors.lightBlue,
    borderRadius: Sizes.small,
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  shortNextButton: {
    width: 100,
    flex: 0,
  },
  nextButtonText: {
    ...Fonts.large,
  },
  buttonDisabled: {
    opacity: 0.5,
  },
});
