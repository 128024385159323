import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { useBootcampPaywallData } from '../../services/hooks';

export const BootcampPaywallTestimonials = (): React.ReactElement => {
  const { satConfigs, testimonialIndex, setTestimonialIndex } =
    useBootcampPaywallData();

  const currentTestimonial = useMemo(() => {
    if (!satConfigs?.testimonials?.length) {
      return {
        quote: '',
        user: '',
      };
    }

    return satConfigs.testimonials[
      Math.abs(testimonialIndex % satConfigs.testimonials.length)
    ];
  }, [satConfigs, testimonialIndex]);

  const scrollTestimonial = useCallback(
    (direction: number) => {
      trackAnalyticsEvent(Analytics.testimonialScrolled, {
        direction: direction > 0 ? 'right' : 'left',
      });

      setTestimonialIndex(testimonialIndex + direction);
    },
    [setTestimonialIndex, testimonialIndex],
  );

  return (
    <View style={styles.testimonialSection}>
      <TouchableOpacity
        style={styles.arrowButton}
        onPress={() => scrollTestimonial(-1)}>
        <AssetImage
          asset={Assets.common.backArrowButtonIcon}
          height={20}
          fill="white"
        />
      </TouchableOpacity>

      <View style={styles.testimonial}>
        <Text style={styles.testimonialQuote}>
          “{currentTestimonial.quote}”
        </Text>
        <Text style={styles.testimonialAuthor}>{currentTestimonial.user}</Text>
      </View>

      <TouchableOpacity
        style={styles.arrowButton}
        onPress={() => scrollTestimonial(1)}>
        <AssetImage
          asset={Assets.common.backArrowButtonIcon}
          height={20}
          fill="white"
          containerStyle={{ transform: [{ scaleX: -1 }] }}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  testimonialSection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 100,
  },
  arrowButton: {
    width: 36,
    height: 36,
    borderRadius: 18,
    backgroundColor: Colors.white10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  testimonial: {
    flex: 1,
    alignItems: 'center',
  },
  testimonialQuote: {
    ...Fonts.semiMedium,
    textAlign: 'center',
    color: Colors.greyAA,
  },
  testimonialAuthor: {
    ...Fonts.smallBold,
    marginTop: Sizes.semiMedium,
  },
});
