import { ImageStyle, StyleSheet, ViewStyle } from 'react-native';

import {
  Colors,
  Fonts,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';

export const MatchingStyles = {
  heightHandler: (maxHeight?: number): ViewStyle => ({
    height: maxHeight,
  }),

  thumbs: (maxHeight: number = 0): ImageStyle => ({
    width: Sizes.xxlarge,
    height: Sizes.xxlarge,
    transform: [{ translateY: maxHeight / 2 - Sizes.medium }],
    alignSelf: 'center',
    marginTop: -12,
    zIndex: 4,
  }),

  thumbsDown: (maxHeight: number = 0): ImageStyle => ({
    marginTop: -8,
    transform: [
      { rotate: '180deg' },
      { translateY: -maxHeight / 2 + (Sizes.xxlarge - Sizes.semiMedium) / 4 },
    ],
  }),
};

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexGrow: 1,
    marginBottom: Sizes.medium,
    paddingRight: Sizes.small,
  },

  pairsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '48%',
    zIndex: 20,
  },

  helpContainer: {
    position: 'absolute',
    top: Sizes.xsmall,
    right: Sizes.xsmall,
    zIndex: 20,
  },

  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.emeraldGreen,
    zIndex: 30,
    borderRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.emeraldGreen,
  },
  incorrectOverlayContainer: {
    backgroundColor: Colors.lightRed,
    borderColor: Colors.lightRed,
  },

  itemContainer: {
    marginVertical: Sizes.xsmall,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black60,
    borderWidth: 1,
    borderColor: Colors.white12,
    borderRadius: Sizes.small,
  },
  itemTextContainer: {
    paddingHorizontal: Sizes.semiLarge,
    paddingVertical: Sizes.normal,
    zIndex: 10,
  },
  itemRightTextContainer: {
    paddingVertical: Sizes.xsmall,
    paddingHorizontal: Sizes.xsmall,
    zIndex: 10,
  },
  itemText: {
    ...Fonts.normalize(Fonts.semiMedium),
    color: Colors.grayscale[0],
    textAlign: 'center',
    textShadowColor: 'black',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
  },
});
