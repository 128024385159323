import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  RegisterPurchaseRequest,
  RegisterPurchaseResponse,
} from '../../entities';

export const registerPurchaseGraphQLCall = async (
  request: RegisterPurchaseRequest,
): Promise<RegisterPurchaseResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: {
          ...request,
          metadata: request.metadata
            ? JSON.stringify(request.metadata)
            : undefined,
        },
      },
      query: `
      mutation RegisterPurchase($input: TeachTapRegisterPurchaseInput!) {
        teachTapRegisterPurchase(input: $input) {
          success
        }
      }
      `,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data.teachTapRegisterPurchase;
};
