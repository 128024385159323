import { Asset } from '../../App/assets';
import { CommonTutorType, VideoSubtitles } from '../../Common/entities';
import { AnswerOptions } from '../../TestPrep/entities';

import { SATUnitTestHistoryUnit } from './SATRequestTypes';

export enum SATStarterOrigin {
  TEST_PREP_TAB = 'sat_testPrepTab',
  TEST_PREP_COMPLETION = 'sat_testPrepCompletion',
  TARGETED_PRACTICE_COMPLETION = 'sat_targetedPracticeCompletion',
}

export type SATRowData = {
  image: Asset;
  key: string;
  text: string;
  size: number;
};

export enum SATStimulusType {
  TEXT = 'text_stimulus',
  IMAGE = 'image_stimulus',
}

export type SATStimulusImage = {
  url: string;
};

export type SATStimulus = {
  text: string;
  type: SATStimulusType;
};

export enum LearningContentTextType {
  VIDEO = 'video_content',
  TEXT = 'text_content',
}

export type SATUnitTestContent = {
  question: string;
  learning_content: {
    speaker?: CommonTutorType;
    transcript?: string;
    type: string;
    url?: string;
    subtitles?: VideoSubtitles;
    text?: string;
  };
  options: AnswerOptions[];
  stimulus?: SATStimulus;
  stimulus_image?: SATStimulusImage;
  renderer?: string;
  tutor?: CommonTutorType;
};

// {[unitId]: SATUnitTestHistoryUnit}
export type SATHistoryData = Record<string, SATUnitTestHistoryUnit>;

export type SATUnitsIndex = {
  first: number;
  last: number;
};

export type SATHistoryDataSummary = {
  readingWritingScore: number;
  mathScore: number;
  totalScore: number;
  showReadingWriting: boolean;
  showMath: boolean;
  showTotal: boolean;
  anyTestTaken: boolean;
  totalUnitsIndex: SATUnitsIndex;
  mathUnitsIndex: SATUnitsIndex;
  readingWritingUnitsIndex: SATUnitsIndex;
  maxTotalScore: number;
  maxMathScore: number;
  maxReadingWritingScore: number;

  totalPercentile?: string;
};

export enum SATUnitGradeIndicatorStatus {
  LOCKED = 'LOCKED',
  PROGRESS_SHOWN = 'PROGRESS_SHOWN',
  HIDDEN = 'HIDDEN',
}

export type SATHistoryResponseSummary = {
  mathScore: number;
  readingWritingScore: number;
  totalScore: number;
  isAllUnitsTaken: boolean;
};

// {[scope]: number | undefined}
export type SATUserPercentileType = Record<string, string | undefined>;

// {[courseId]: Percentile}
export type SATUserPercentileData = Record<
  string,
  SATUserPercentileType | undefined
>;

export type SATMaxScores = {
  readingWriting: number;
  math: number;
  total: number;
};
