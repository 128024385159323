import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import { SATBootcamp } from '../../../entities';
import { SAT_SLICE_NAME, setSATFreeTrialEnded } from '../SATSlice';

export const checkForFreeTrialExpiration = createAsyncThunk(
  `${SAT_SLICE_NAME}/checkForFreeTrialExpiration`,
  async (bootcamp: SATBootcamp | undefined, thunkApi) => {
    if (!bootcamp) {
      return;
    }
    const isExpired = moment(bootcamp.accessDetails.expiresAt).isBefore(
      moment(),
    );
    thunkApi.dispatch(setSATFreeTrialEnded(isExpired));
  },
);
