import React, { useCallback } from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useRefWithSubscribe } from '../services/hooks';
import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

type Props<T> = {
  items: T[];
  selectedValue?: T | T[];
  onValueChange: (itemValue: T | T[], itemIndex: number) => void;
  isDisabled?: boolean;
  multiSelect?: boolean;
  disableVirtualization?: boolean;

  getId?: (item: T, index: number) => string;
  getLabel?: (item: T, index: number) => string;
};

export const CommonSelectorsField = <T,>(
  props: Props<T>,
): React.ReactElement => {
  const selectedValueRef = useRefWithSubscribe(props.selectedValue);

  const handleValueChange = useCallback(
    (value: T, itemIndex: number) => {
      if (!props?.items?.length) {
        return;
      }

      if (props.multiSelect) {
        const currentValue =
          (selectedValueRef.current as T[])?.length > 0
            ? (selectedValueRef.current as T[])
            : [];

        const newSelectedValue = [...currentValue];

        const itemIndexInSelected = currentValue.indexOf(value);
        if (itemIndexInSelected > -1) {
          newSelectedValue.splice(itemIndexInSelected, 1);
        } else {
          newSelectedValue.push(value);
        }

        props.onValueChange(newSelectedValue, itemIndex);
        return;
      }

      props.onValueChange(value, itemIndex);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onValueChange, props.multiSelect, selectedValueRef],
  );

  const handleGetId = useCallback(
    (item: T, index: number) => {
      return props.getId ? props.getId(item, index) : index.toString();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getId],
  );

  const handleGetIsItemSelected = useCallback(
    (item: T) => {
      if (props.multiSelect) {
        return (props.selectedValue as T[])?.includes(item);
      }

      return props.selectedValue === item;
    },
    [props.multiSelect, props.selectedValue],
  );

  const handleGetItemStyle = useCallback((index: number) => {
    return [styles.itemContainer, index === 0 && styles.firstItemContainer];
  }, []);

  const handleGetLabel = useCallback(
    (item: T, index: number) => {
      return props.getLabel ? props.getLabel(item, index) : (item as string);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getLabel],
  );

  return (
    <View style={styles.container}>
      {props.disableVirtualization &&
        props.items.map((item, index) => (
          <Pressable
            key={handleGetId(item, index)}
            style={handleGetItemStyle(index)}
            disabled={props.isDisabled}
            onPress={() => handleValueChange(item, index)}>
            <Text style={styles.text}>{handleGetLabel(item, index)}</Text>

            {handleGetIsItemSelected(item) && (
              <AssetImage asset={Assets.common.check} width={14} />
            )}
          </Pressable>
        ))}

      {!props.disableVirtualization && (
        <FlatList
          data={props.items}
          renderItem={({ item, index }) => (
            <Pressable
              key={handleGetId(item, index)}
              style={handleGetItemStyle(index)}
              disabled={props.isDisabled}
              onPress={() => handleValueChange(item, index)}>
              <Text style={styles.text}>{handleGetLabel(item, index)}</Text>

              {handleGetIsItemSelected(item) && (
                <AssetImage asset={Assets.common.check} width={14} />
              )}
            </Pressable>
          )}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white10,
    borderRadius: Sizes.semiMedium,
  },

  text: {
    ...Fonts.caption,
    color: Colors.white70,
  },

  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    paddingHorizontal: Sizes.semiMedium,
    paddingVertical: Sizes.semiMedium,
    borderTopWidth: 1,
    borderTopColor: Colors.white12,
    justifyContent: 'space-between',
  },
  firstItemContainer: {
    borderTopWidth: 0,
  },
});
