import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTeachTapUserSettings } from '../../../../App/services/slices';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import {
  ONBOARDING_SLICE_NAME,
  setBootcampEntryPoint,
  setShouldOpenSatBootcampFlowForUpsell,
  setShouldUseSatBootcampDiscount,
} from '../OnboardingSlice';

export const rejectSATBootcampUpsellFlow = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/rejectSATBootcampUpsellFlow`,
  async (_, thunkApi) => {
    try {
      thunkApi.dispatch(setBootcampEntryPoint(undefined));
      thunkApi.dispatch(setShouldUseSatBootcampDiscount(false));
      thunkApi.dispatch(setShouldOpenSatBootcampFlowForUpsell(false));

      thunkApi.dispatch(getTeachTapUserSettings());
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
