import React, { useMemo } from 'react';
import { StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  containerStyle?: ViewStyle;
  imageFill?: string;
  textStyle?: TextStyle;
  isFreeTrial?: boolean;
};

export const CourseTag = (props: Props): React.ReactElement => {
  const text = useMemo(() => {
    return props.isFreeTrial
      ? locale.common.free_trial
      : locale.common.purchased;
  }, [props.isFreeTrial]);

  return (
    <View style={[styles.purchasedContainer, props.containerStyle]}>
      <AssetImage
        asset={Assets.home.purchaseCheck}
        height={10}
        fill={props.imageFill ?? 'white'}
      />
      <Text style={[styles.purchasedText, props.textStyle]}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  purchasedText: {
    ...Fonts.xsmall,
    color: Colors.black50,
  },
  purchasedContainer: {
    backgroundColor: Colors.black06,
    position: 'absolute',
    right: 0,
    paddingHorizontal: Sizes.xsmall,
    paddingVertical: 2,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    borderBottomLeftRadius: Sizes.small,
    borderTopRightRadius: Sizes.small,
  },
});
