import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../../App/assets';
import locale from '../../../App/locale';
import { CommonFeedBackground } from '../../../Common/components';
import { Course } from '../../../Common/entities';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { CourseFeedHeader } from '../../../Learn/components/study/CourseFeedHeader';
import { useTestDetailNavigator } from '../../services/hooks';

type Props = {
  course: Course;
};

export const HighSchoolTestScreenView = (props: Props): React.ReactElement => {
  const { handleOpenStudyFeedScreen } = useTestDetailNavigator();

  return (
    <View style={styles.container}>
      <CommonFeedBackground course={props.course} />

      <View style={styles.highSchoolEmptyView}>
        <CourseFeedHeader course={props.course} isFloating isFromTestPrep />
        <View style={styles.notAvailableContainer}>
          <Image
            style={styles.notAvailableGif}
            resizeMode={'cover'}
            source={Assets.test.notAvailableGif.path as ImageSourcePropType}
          />
          <Text style={styles.notAvailableHeading}>
            {locale.study.not_available}
          </Text>
          <Text style={styles.notAvailableMessage}>
            {locale.testPrep.high_school_empty_state_message}
          </Text>
        </View>

        <TouchableOpacity
          style={[
            styles.continueButton,
            styles.notAvailableStartLearningButton,
          ]}
          onPress={handleOpenStudyFeedScreen}>
          <Text style={styles.continueButtonText}>
            {locale.courses.start_learning}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.darkBlue,
  },
  highSchoolEmptyView: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },
  continueButton: {
    backgroundColor: Colors.buttonGreen,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Sizes.medium,
    height: 44,
  },
  continueButtonText: {
    ...Fonts.large,
  },
  notAvailableGif: {
    width: Sizes.normalizeIP14PM(115),
    height: Sizes.normalizeIP14PM(115),
  },
  notAvailableHeading: {
    ...Fonts.normalize(Fonts.xxxlargeBold),
  },
  notAvailableMessage: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    textAlign: 'center',
  },
  notAvailableContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 10,
    alignSelf: 'center',
  },
  notAvailableStartLearningButton: {
    position: 'absolute',
    bottom: Sizes.normalizeIP14PM(20),
    width: '100%',
    alignSelf: 'center',
  },
});
