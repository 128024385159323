export enum VersionStatus {
  UP_TO_DATE = 'UP_TO_DATE',
  OUTDATED = 'OUTDATED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
}

export type AppVersionDetails = {
  status: VersionStatus;
  dismissible: boolean;
  message: string;
  androidUrl: string;
  iosUrl: string;
};
