import { useRoute } from '@react-navigation/core';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { MainNavigationRouteProp } from '../../App/navigation/main/MainStackNavigator';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { MainStack } from '../../Common/services/utils/AppConstants';
import { Share } from '../../Common/services/utils/polyfills';
import { shareAiFigure } from '../../Feedback/services/slices';
import { AiFigureModel } from '../entities';
import { getAiFigureShareOptions } from '../services/utils';

export const AiFigureShareAiProfile = (): React.ReactElement => {
  const route =
    useRoute<MainNavigationRouteProp<MainStack.AI_FIGURE_PROFILE>>();
  const dispatch = useAppDispatch();

  const allFigure = useAppSelector(state => state.aiFigure.figure);

  const currentFigure = useMemo((): AiFigureModel | undefined => {
    return allFigure[route.params?.name];
  }, [route.params?.name, allFigure]);

  const disableButton = useMemo(() => {
    return !currentFigure?.name;
  }, [currentFigure]);

  const handleShare = useCallback(async () => {
    if (!currentFigure?.name) {
      return;
    }

    try {
      await Share.open(getAiFigureShareOptions(currentFigure.name));
      dispatch(shareAiFigure({ content: currentFigure }));
    } catch (e) {
      console.log(e);
    }
  }, [currentFigure, dispatch]);

  return (
    <TouchableOpacity
      onPress={handleShare}
      disabled={disableButton}
      style={styles.container}>
      <AssetImage asset={Assets.shareCompactIcon} height={24} fill={'white'} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 8,
  },
});
