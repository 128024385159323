import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { MainNavigationProp } from '../../App/navigation/main/MainStackNavigator';
import { useAppDispatch } from '../../App/store';
import { BetaFeedbackSource, MediaResponse } from '../../Common/entities';
import { isSATCourse } from '../../Common/services/utils';
import { ScreenNames, Sizes } from '../../Common/services/utils/AppConstants';
import { useNavigation } from '../../Common/services/utils/polyfills/functions';
import { useProvideFeedbackService } from '../../Feedback/services/hooks';
import { TestPrepData, TestPrepQuestion } from '../../TestPrep/entities';
import { setTimerPaused } from '../../TestPrep/services/slices';

type FeedbackButtonProps = {
  currentTest?: TestPrepData;
  currentQuestion?: TestPrepQuestion;
};

export const FeedbackButton = ({
  currentTest,
  currentQuestion,
}: FeedbackButtonProps): JSX.Element => {
  const { showFeedbackOptions } = useProvideFeedbackService();
  const navigation = useNavigation<MainNavigationProp<any>>();
  const dispatch = useAppDispatch();

  const handleFeedbackPress = useCallback(async () => {
    dispatch(setTimerPaused(true));

    const asset: MediaResponse | undefined = await showFeedbackOptions(true);
    navigation.navigate(ScreenNames.MainStack.BETA_FEEDBACK, {
      mediaAsset: asset,
      source: isSATCourse(currentTest?.course)
        ? BetaFeedbackSource.SAT_TEST_PREP
        : BetaFeedbackSource.AP_TEST_PREP,

      courseName: currentTest?.course?.name,
      unitName: currentTest?.unit?.name,
      contentId: currentQuestion?.generatedContentId,
    });
  }, [
    dispatch,
    showFeedbackOptions,
    navigation,
    currentTest?.unit,
    currentTest?.course,
    currentQuestion,
  ]);

  return (
    <TouchableOpacity
      style={styles.feedbackButton}
      onPress={handleFeedbackPress}>
      <AssetImage asset={Assets.test.flagIcon} height={Sizes.semiLarge} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  feedbackButton: {
    paddingHorizontal: Sizes.small,
  },
});

export const renderFeedbackButton = (
  props: FeedbackButtonProps,
): React.ReactElement => <FeedbackButton {...props} />;
