import { Product } from 'react-native-iap';

import { RootState } from '../../../App/store';
import {
  PaywallCourseName,
  PaywallEntryPoint,
  PaywallPurchaseType,
} from '../../../Common/entities';
import { getPaywallEntryPointFromBootcamp } from '../../../CourseEnrollment/services/utils';
import { APTargetGrade } from '../../../Onboarding/entities';
import { mapTargetGrades } from '../../../Onboarding/services/mappers';

type GetProgramNameParams = {
  state: RootState;
  purchaseType: PaywallPurchaseType;
};

type GetTargetGradesParams = {
  state: RootState;
  purchaseType: PaywallPurchaseType;
};

type ProductDetailsType = {
  price: string;
  currency: string;
  localizedPrice: string;
};

export const getProgramName = ({
  state,
  purchaseType,
}: GetProgramNameParams): string => {
  switch (purchaseType) {
    case PaywallPurchaseType.AP_PROGRAM:
      return PaywallCourseName.AP_PROGRAM;
    case PaywallPurchaseType.SAT_BOOTCAMP:
      return (
        state.onboarding.SATProgramDetails?.programName ||
        state.SAT.bootcamp?.details?.programName ||
        ''
      );
    default:
      const course = state.purchases.currentCoursePaywall;
      return course?.name ?? '';
  }
};

export const getTargetGrades = ({
  state,
  purchaseType,
}: GetTargetGradesParams): APTargetGrade[] => {
  switch (purchaseType) {
    case PaywallPurchaseType.AP_PROGRAM:
      return mapTargetGrades(
        state.apOnboarding.apProgramDetails?.courseDetails ?? [],
      );
    default:
      return [];
  }
};

export const getFromEntryPoint = (
  state: RootState,
  purchaseType: PaywallPurchaseType,
): PaywallEntryPoint => {
  if (purchaseType === PaywallPurchaseType.COURSE) {
    return (
      state.purchases.highSchoolCourseEntryPoint ?? PaywallEntryPoint.PROFILE
    );
  } else {
    const fromEntryPoint = state.onboarding.bootcampEntryPoint;
    return getPaywallEntryPointFromBootcamp(fromEntryPoint);
  }
};

export const getProductDetails = (
  purchasedItem: Product,
): ProductDetailsType => {
  const price = purchasedItem.price;
  const currency = purchasedItem.currency;
  const localizedPrice = purchasedItem.localizedPrice;
  return { price, currency, localizedPrice };
};
