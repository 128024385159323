import { getRootState } from '../../../App/services/utils';
import {
  mapApProgramDetailsAvailableStatus,
  mapApProgramTargetGradeByCourseId,
} from '../mappers';

export const getAPLearnModeTimeSpentSecondsByCourseId = (
  courseId?: string,
): number => {
  if (!courseId) {
    return 0;
  }

  return getRootState().AP.learnModeTimeSpentSeconds[courseId] || 0;
};

export const getAPTestPrepTimeSpentSecondsByCourseId = (
  courseId?: string,
): number => {
  if (!courseId) {
    return 0;
  }

  return getRootState().AP.testPrepTimeSpentSeconds[courseId] || 0;
};

export const getAPProgramCourseTargetGrade = (
  courseId?: string,
): string | undefined =>
  mapApProgramTargetGradeByCourseId(getRootState(), courseId);

export const getAPProgramUserDetailsEnrollmentStatus = (): boolean =>
  mapApProgramDetailsAvailableStatus(getRootState());
