import React from 'react';
import { StyleSheet, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';

const PLAY_BUTTON_SIZE = 60;

export default (): React.ReactElement => {
  return (
    <View style={styles.buttonContainer} pointerEvents="none">
      <AssetImage
        asset={Assets.home.playVideoButtonIcon}
        fill="white"
        height={PLAY_BUTTON_SIZE}
        containerStyle={styles.playButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignSelf: 'center',
    bottom: '42.5%',
    position: 'absolute',
  },
  playButton: {
    alignSelf: 'center',
    opacity: 0.4,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
});
