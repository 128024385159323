import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { RootState } from '../../../App/store';
import {
  ContentCard,
  FeedType,
  FillGapsWithHelpCard,
  MatchingPairsAnswerOptions,
  MatchingPairsAnswerSelector,
  MatchingPairsCard,
  MultipleChoiceAnswer,
  TruthOrLieCard,
  TruthOrLieOption,
} from '../../../Common/entities';
import { mapCardToAnalyticsPayload } from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import { requestUserFeedback } from '../../../Feedback/services/slices';
import { setUserInteractionHistory } from '../../../RaiseHand/services/slices';
import { recordPracticeMastery } from '../../../TestPrep/services/slices';
import { CheckFuzzyAnswer } from '../../algorithms';
import {
  AssignMatchingPairsAnswerPayload,
  FuzzyAnswerWithInput,
  ProcessMatchingPairsAnswerPayload,
  SelectMCQOptionAction,
  SetGapsAnswerPayload,
  SetMatchingPairsAnswerPayload,
  SetTruthOrLieAnswerPayload,
  TrackMasteryProps,
} from '../../entities';
import { mapCardToSubmitAnswerPayload } from '../mappers';

import { recordMastery } from './CoursesSlice';

const CORRECT_ANSWERS_REQUIRED_FOR_FEEDBACK = 5;

type State = {
  answers: Record<string, MultipleChoiceAnswer>;
  selectedOptions: Record<string, string[] | null>;
  flippedCards: Record<string, boolean>;
  gapsAnswers: Record<string, FuzzyAnswerWithInput>;
  isLoading: boolean;
  error: string | null;
  explainedOptions: Record<string, string | null>;
  truthOrLieAnswer: Record<string, TruthOrLieOption>;
  mcqCorrectOptionSelected: Record<string, boolean>;
  explanationShownTimesMap: Record<string, string>;
  finishedReadingExplanationTimesMap: Record<string, string>;
  helpShownTimesMap: Record<string, string>;
  answeredTimesMap: Record<string, string>;

  matchingAnswers: Record<string, MatchingPairsAnswerOptions>;
  matchingAnswerFalseAnswered: Record<string, boolean>;
  selectedMatchingAnswer: Record<string, MatchingPairsAnswerSelector>;

  correctAnswersInARow: number;

  // For now, uses cardUniqueId as the key.
  // Ideally we should use generatedContentId, but more clarification still needed
  // regarding the UX edge-cases and it will impact more files. So for now, we will use cardUniqueId

  // {cardUniqueId: totalNumberOfAttempts}
  numberOfAttempts: Record<string, number>;

  // {cardUniqueId: points}
  points: Record<string, number>;
};

const initialState: State = {
  answers: {},
  isLoading: false,
  error: null,
  flippedCards: {},
  selectedOptions: {},
  explainedOptions: {},
  gapsAnswers: {},
  truthOrLieAnswer: {},
  mcqCorrectOptionSelected: {},
  explanationShownTimesMap: {},
  finishedReadingExplanationTimesMap: {},
  helpShownTimesMap: {},
  answeredTimesMap: {},

  matchingAnswers: {},
  matchingAnswerFalseAnswered: {},
  selectedMatchingAnswer: {},

  correctAnswersInARow: 0,

  numberOfAttempts: {},
  points: {},
};

export const trackMastery = createAsyncThunk(
  'AnswersSlice/trackMastery',
  async (props: TrackMasteryProps, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.answer;
    const { dispatch } = thunkApi;
    const answeredAt = moment().toISOString();

    trackAnalyticsEvent(Analytics.submitAnswer, {
      ...mapCardToSubmitAnswerPayload(props.card, props.correct),
    });

    if (
      localState.correctAnswersInARow ===
        CORRECT_ANSWERS_REQUIRED_FOR_FEEDBACK &&
      !props.ignoreTrack
    ) {
      dispatch(requestUserFeedback());
    }

    thunkApi.dispatch(
      setAnsweredAt({
        item: props.card,
        answeredAt,
      }),
    );

    if (!props.ignoreTrack) {
      const currentNumberOfAttempt = getNumberOfAttempts(
        state.answer,
        props.card.uniqueId,
      );

      const points = state.answer.points[props.card.uniqueId] ?? 0;

      if (props.card.feedId === FeedType.TestPractice) {
        const currentPractice =
          state.testPrep.targetedPractices[props.card.unit!.id];
        if (currentPractice) {
          dispatch(
            recordPracticeMastery({
              card: props.card,
              answeredAt,
              correct: props.correct,
              helpShownAt: state.answer.helpShownTimesMap[props.card.uniqueId],
              shownAt:
                state.questions.viewedQuestions[props.card.uniqueId] ??
                moment().toISOString(),
              numberOfAttempts: currentNumberOfAttempt + 1,
              pointsAwarded: points ? points : 0,
              testId: currentPractice.testId,
            }),
          );
        }
      } else {
        dispatch(
          recordMastery({
            card: props.card,
            answeredAt,
            correct: props.correct,
            helpShownAt: state.answer.helpShownTimesMap[props.card.uniqueId],
            shownAt:
              state.questions.viewedQuestions[props.card.uniqueId] ??
              moment().toISOString(),
            numberOfAttempts: currentNumberOfAttempt + 1,
            pointsAwarded: points ? points : 0,
          }),
        );
      }
    }

    dispatch(increaseNumberOfAttempts({ id: props.card.uniqueId }));
  },
);

export const selectMCQOption = createAsyncThunk(
  'AnswersSlice/selectMCQOption',
  async (payload: SelectMCQOptionAction, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.answer as State;

    let isCorrect = false;

    const { question, option } = payload;
    const questionId = question.uniqueId;
    const maxOptionSelection = question.options.length - 1;
    const selectedOptions = localState.selectedOptions[questionId] || [];

    if (selectedOptions?.length <= maxOptionSelection) {
      const correctAnswerOption = question.answer?.correct_options[0];

      if (correctAnswerOption && option) {
        if (correctAnswerOption.id === option.id) {
          isCorrect = true;
        }
      }

      if (!isCorrect) {
        // if selected options + 2 are equal to max-options, then the user will not be able to answer
        // set the points to 0
        if (selectedOptions.length + 2 === question.options.length) {
          thunkApi.dispatch(setQuestionPoints({ id: questionId, points: 0 }));
        } else {
          // else, reduce, and keep the points to 10
          thunkApi.dispatch(decreaseQuestionPoints({ id: questionId }));
        }
      }

      thunkApi.dispatch(
        setUserInteractionHistory({
          generatedContentId: question.generatedContentId,
          userInteractionHistory: {
            selectedOption: option?.answer || '',
            correct: isCorrect,
          },
        }),
      );

      thunkApi.dispatch(
        trackMastery({
          card: question,
          correct: isCorrect,
        }),
      );

      return isCorrect;
    }

    return undefined;
  },
);

export const processMatchingPairsAnswer = createAsyncThunk(
  'AnswersSlice/processMatchingPairsAnswer',
  async (payload: ProcessMatchingPairsAnswerPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.answer as State;

    const { item, leftOption, rightOption } = payload;

    const isCorrect =
      item.pairsFromLeftOptions[leftOption.uniqueId].answerUniqueId ===
      rightOption.uniqueId;

    const isPreviouslyAnsweredFalse =
      localState.matchingAnswerFalseAnswered[item.uniqueId] || false;

    thunkApi.dispatch(
      setUserInteractionHistory({
        generatedContentId: item.generatedContentId,
        userInteractionHistory: {
          selectedOption: `${leftOption.text} matches ${rightOption.text}`,
          correct: isCorrect,
        },
      }),
    );

    if (isCorrect) {
      const totalCorrectAnswers =
        localState.matchingAnswers[item.uniqueId].leftOptions.filter(
          option => option.pairedAt,
        ).length + 1;

      const shouldIgnore = totalCorrectAnswers !== item.leftOptions.length;

      thunkApi.dispatch(
        trackMastery({
          card: item,
          correct: isCorrect,
          ignoreTrack: shouldIgnore,
        }),
      );
    } else {
      thunkApi.dispatch(decreaseQuestionPoints({ id: item.uniqueId }));

      // Track mastery once the answer is incorrect, but track it only once
      thunkApi.dispatch(
        trackMastery({
          card: item,
          correct: isCorrect,
          ignoreTrack: isPreviouslyAnsweredFalse,
        }),
      );
    }

    return { item, leftOption, rightOption, isCorrect };
  },
);

export const setGapsAnswer = createAsyncThunk(
  'AnswersSlice/setGapAnswer',
  async (
    payload: SetGapsAnswerPayload,
    thunkApi,
  ): Promise<FuzzyAnswerWithInput> => {
    const { item, value, isReset } = payload;
    const fuzzyResult = CheckFuzzyAnswer.getResult(value, item.possibleAnswers);

    if (isReset) {
      return { ...fuzzyResult, submittedAnswer: payload.value };
    }

    thunkApi.dispatch(
      setUserInteractionHistory({
        generatedContentId: item.generatedContentId,
        userInteractionHistory: {
          selectedOption: value,
          correct: fuzzyResult.isCorrect,
        },
      }),
    );

    thunkApi.dispatch(
      trackMastery({
        card: item,
        correct: fuzzyResult.isCorrect,
      }),
    );

    if (!fuzzyResult.isCorrect) {
      thunkApi.dispatch(decreaseQuestionPoints({ id: item.uniqueId }));
    }

    return { ...fuzzyResult, submittedAnswer: payload.value };
  },
);

export const setTruthOrLieAnswer = createAsyncThunk(
  'AnswersSlice/setTruthOrLieAnswer',
  async (payload: SetTruthOrLieAnswerPayload, thunkApi) => {
    const { item, value } = payload;

    if (!value.isCorrect) {
      thunkApi.dispatch(setQuestionPoints({ id: item.uniqueId, points: 0 }));
    }

    thunkApi.dispatch(
      setUserInteractionHistory({
        generatedContentId: item.generatedContentId,
        userInteractionHistory: {
          selectedOption: value.value,
          correct: value.isCorrect,
        },
      }),
    );

    thunkApi.dispatch(
      trackMastery({
        card: item,
        correct: value.isCorrect,
      }),
    );

    return value;
  },
);

type SetExplanationShownPayload = {
  item: ContentCard;
};

type SetAnsweredAtPayload = {
  item: ContentCard;
  answeredAt: string;
};

type SetQuestionPointsPayload = {
  id: string;
  points: number;
};

type DecreaseQuestionPointsPayload = {
  id: string;
};

type IncreaseNumberOfAttemptsPayload = {
  id: string;
};

const answersSlice = createSlice({
  name: 'AnswersSlice',
  initialState: initialState,
  reducers: {
    resetMatchingPairsAnswer: (
      state,
      action: PayloadAction<AssignMatchingPairsAnswerPayload>,
    ) => {
      const { item } = action.payload;

      state.matchingAnswers[item.uniqueId] = {
        leftOptions: item.leftOptions,
        rightOptions: item.rightOptions,
      };
    },

    selectMatchingPairsOption: (
      state,
      action: PayloadAction<SetMatchingPairsAnswerPayload>,
    ) => {
      const { item, isLeft, option } = action.payload;

      if (option?.pairedAt) {
        return;
      }

      let currentLeftOption = state.selectedMatchingAnswer[item.uniqueId]?.left;
      let currentRightOption =
        state.selectedMatchingAnswer[item.uniqueId]?.right;

      if (isLeft) {
        if (!currentLeftOption) {
          currentLeftOption = option;
        } else if (currentLeftOption?.uniqueId === option?.uniqueId) {
          currentLeftOption = undefined;
        } else if (currentLeftOption?.uniqueId !== option?.uniqueId) {
          currentLeftOption = option;
        }
      } else {
        if (!currentRightOption) {
          currentRightOption = option;
        } else if (currentRightOption?.uniqueId === option?.uniqueId) {
          currentRightOption = undefined;
        } else if (currentRightOption?.uniqueId !== option?.uniqueId) {
          currentRightOption = option;
        }
      }

      state.selectedMatchingAnswer[item.uniqueId] = {
        left: currentLeftOption,
        right: currentRightOption,
      };
    },

    setAnsweredAt: (state, action: PayloadAction<SetAnsweredAtPayload>) => {
      const { item, answeredAt } = action.payload;

      if (!state.answeredTimesMap[item.uniqueId]) {
        state.answeredTimesMap[item.uniqueId] = answeredAt;
      }
    },

    setExplanationShown: (
      state,
      action: PayloadAction<SetExplanationShownPayload>,
    ) => {
      const { item } = action.payload;

      if (state.helpShownTimesMap[item.uniqueId]) {
        return;
      }

      if (!state.explanationShownTimesMap[item.uniqueId]) {
        state.explanationShownTimesMap[item.uniqueId] = moment().toISOString();
      }

      trackAnalyticsEvent(Analytics.explanationShown, {
        ...mapCardToAnalyticsPayload(item),
      });
    },

    setFinishedReadingExplanation: (
      state: State,
      action: PayloadAction<SetExplanationShownPayload>,
    ) => {
      const { item } = action.payload;
      if (!state.finishedReadingExplanationTimesMap[item.uniqueId]) {
        state.finishedReadingExplanationTimesMap[item.uniqueId] =
          moment().toISOString();
      }

      trackAnalyticsEvent(Analytics.finishedReadingExplanation, {
        ...mapCardToAnalyticsPayload(item),
      });
    },

    setHelpShown: (
      state: State,
      action: PayloadAction<SetExplanationShownPayload>,
    ) => {
      const { item } = action.payload;

      if (
        state.explanationShownTimesMap[item.uniqueId] ||
        state.answeredTimesMap[item.uniqueId]
      ) {
        return;
      }

      if (!state.helpShownTimesMap[item.uniqueId]) {
        state.helpShownTimesMap[item.uniqueId] = moment().toISOString();
      }

      trackAnalyticsEvent(Analytics.helpShown, {
        ...mapCardToAnalyticsPayload(item),
      });
    },

    increaseNumberOfAttempts: (
      state: State,
      action: PayloadAction<IncreaseNumberOfAttemptsPayload>,
    ) => {
      const { id } = action.payload;
      if (state.numberOfAttempts[id] === undefined) {
        state.numberOfAttempts[id] = 0;
      }

      state.numberOfAttempts[id] += 1;
    },

    setQuestionPoints: (
      state: State,
      action: PayloadAction<SetQuestionPointsPayload>,
    ) => {
      const { id, points } = action.payload;
      state.points[id] = points;
    },

    decreaseQuestionPoints: (
      state: State,
      action: PayloadAction<DecreaseQuestionPointsPayload>,
    ) => {
      const { id } = action.payload;
      if (!state.points[id]) {
        return;
      }

      state.points[id] = Math.max(10, (state.points[id] || 0) - 10);
    },
  },
  extraReducers: builder => {
    builder.addCase(setGapsAnswer.fulfilled, (state: State, action) => {
      const args = action.meta.arg as SetGapsAnswerPayload;
      state.gapsAnswers[args.item.uniqueId] = action.payload;
    });

    builder.addCase(
      processMatchingPairsAnswer.fulfilled,
      (state: State, action) => {
        const { item, leftOption, rightOption, isCorrect } = action.payload;

        if (!isCorrect) {
          state.matchingAnswerFalseAnswered[item.uniqueId] = true;
        }

        // Only assign the answer and change the order if the answer is correct
        if (isCorrect) {
          const currentLeftOptions = [
            ...state.matchingAnswers[item.uniqueId].leftOptions,
          ];
          const currentRightOptions = [
            ...state.matchingAnswers[item.uniqueId].rightOptions,
          ];

          const leftOptionIndex = currentLeftOptions.findIndex(
            option => option.uniqueId === leftOption.uniqueId,
          );
          const rightOptionIndex = currentRightOptions.findIndex(
            option => option.uniqueId === rightOption.uniqueId,
          );

          // Assign the answer
          const pairedAt = Date.now();
          currentLeftOptions[leftOptionIndex] = { ...leftOption, pairedAt };
          currentRightOptions[rightOptionIndex] = { ...rightOption, pairedAt };

          state.matchingAnswers[item.uniqueId] = {
            leftOptions: currentLeftOptions,
            rightOptions: currentRightOptions,
          };
        }
      },
    );

    builder.addCase(selectMCQOption.fulfilled, (state: State, action) => {
      const args = action.meta.arg as SelectMCQOptionAction;

      const isCorrect = action.payload;

      const { question, option } = args;
      const selectedOptions = state.selectedOptions[question.uniqueId] || [];
      if (isCorrect !== undefined) {
        if (option?.id) {
          selectedOptions.push(option.id);
          state.selectedOptions[question.uniqueId] = selectedOptions;
          state.mcqCorrectOptionSelected[question.uniqueId] = isCorrect;
        }
      }
    });

    builder.addCase(trackMastery.pending, (state: State, action) => {
      const arg = action.meta.arg as TrackMasteryProps;
      if (arg.correct && !arg.ignoreTrack) {
        state.correctAnswersInARow += 1;
      } else {
        state.correctAnswersInARow = 0;
      }
    });

    builder.addCase(setTruthOrLieAnswer.fulfilled, (state: State, action) => {
      const args = action.meta.arg as SetTruthOrLieAnswerPayload;
      state.truthOrLieAnswer[args.item.uniqueId] = action.payload;
    });
  },
});

export const getTruthOrLieAnswer = (
  state: State,
  item: TruthOrLieCard,
): TruthOrLieOption | null => {
  return state.truthOrLieAnswer[item.uniqueId] ?? null;
};

export const getGapAnswer = (
  state: State,
  item: FillGapsWithHelpCard,
): FuzzyAnswerWithInput | null => {
  return state.gapsAnswers[item.uniqueId] ?? null;
};

export const getMatchingAnswer = (
  state: State,
  item: MatchingPairsCard,
): MatchingPairsAnswerOptions | null => {
  return state.matchingAnswers[item.uniqueId] ?? null;
};

export const getSelectedMatchingAnswer = (
  state: State,
  item: MatchingPairsCard,
): MatchingPairsAnswerSelector | null => {
  return state.selectedMatchingAnswer[item.uniqueId] ?? null;
};

export const {
  setExplanationShown,
  setFinishedReadingExplanation,
  setHelpShown,
  setAnsweredAt,
  resetMatchingPairsAnswer,
  selectMatchingPairsOption,
  increaseNumberOfAttempts,
  setQuestionPoints,
  decreaseQuestionPoints,
} = answersSlice.actions;

export const getNumberOfAttempts = (state: State, id: string): number => {
  return state.numberOfAttempts[id] ?? 0;
};

export const getQuestionPoints = (state: State, id: string): number => {
  return state.points[id] ?? 0;
};

export const AnswersSlice = answersSlice.reducer;
