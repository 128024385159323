import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';

export const SATBootcampTutorial3 = (): React.ReactElement => {
  return (
    <View style={styles.container}>
      <View style={styles.maxScoreImprovementContainer}>
        <Text style={styles.labelText}>
          {locale.onboarding.max_score_improvement}
        </Text>
      </View>
      <AssetImage
        asset={OnboardingAssets.ZigZagArrowIcon}
        containerStyle={styles.curvedArrow}
        height={29}
        width={49}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: Sizes.normalizeIP14PM(104),
    width: Dimensions.get('window').width,
  },
  maxScoreImprovementContainer: {
    position: 'absolute',
    bottom: Sizes.xlarge,
    left: Sizes.xlarge,
    transform: [{ rotate: '-4.43deg' }],
  },
  curvedArrow: {
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: Sizes.normal,
    right: Sizes.defaultOptionHeight,
  },

  labelText: {
    ...Fonts.mediumGochiHand,
    color: Colors.actionGreen,
  },
  zigZagLine: {
    alignSelf: 'flex-start',
    bottom: -5,
    left: Sizes.normalizeIP14PM(5),
    position: 'absolute',
  },
  guaranteedResultsContainer: {
    position: 'absolute',
    transform: [{ rotate: '2deg' }],
    bottom: Sizes.normal,
    right: Sizes.small,
  },
});
