import { RootState } from '../../../App/store';
import { Course } from '../../../Common/entities';
import { isAPCourse } from '../../../Common/services/utils';
import { ApResultsStack } from '../../../Common/services/utils/AppConstants';
import { getEnrolledCoursesWithDetails } from '../../../CourseEnrollment/services/mappers';
import {
  ApResultsNextInputsSummary,
  ApResultsScoreEnum,
  ApResultsYesNoEnum,
  GetCourseScoreDetailsStatus,
} from '../../entities';
import { getIsCourseQualifiedForApResults } from '../utils';

export const getRedirectToApResultsFlowStatus = (state: RootState): boolean => {
  const apResultsState = state.apResults;
  const notificationState = state.notification;
  const onboardingState = state.onboarding;

  if (
    !apResultsState.examResultsDate ||
    apResultsState.hasLandingOpened ||
    apResultsState.isApResultsFlowCompleted ||
    apResultsState.notNowPressCount >= 3 ||
    !notificationState.isInitialNotificationDataProcessed ||
    onboardingState.doOnboardingFlowAfterSignup ||
    onboardingState.showEntitlementScreen
  ) {
    return false;
  }

  // Handle case when data is submitted on other device.
  // It shouldn't redirect user to the flow.
  if (!apResultsState.isFlowStarted && apResultsState.isDataSubmitted) {
    return false;
  }

  const currentDate = new Date();
  const examResultsMoment = new Date(apResultsState.examResultsDate);
  if (currentDate < examResultsMoment) {
    return false;
  }

  const enrolledCourses = getEnrolledCoursesWithDetails(state);
  const enrolledApCourses = enrolledCourses.filter(courseData =>
    isAPCourse(courseData.course),
  );
  if (!enrolledApCourses.length) {
    return false;
  }

  const coursesExamsDate = apResultsState.coursesExamsDate;
  const previousExamsDates = apResultsState.previousYearCoursesExamsDate;

  return enrolledApCourses.some(courseData =>
    getIsCourseQualifiedForApResults({
      course: courseData.course,
      courseEnrolledOn: courseData.enrolledOn,
      courseExamDate: coursesExamsDate[courseData.course.name],
      previousExamDate: previousExamsDates[courseData.course.name],
      goalKey: courseData.goalKey,
    }),
  );
};

export const getRedirectToApResultsFlowTarget = (
  state: RootState,
): ApResultsStack | null => {
  const shouldRedirect = getRedirectToApResultsFlowStatus(state);
  if (!shouldRedirect) {
    return null;
  }

  if (state.apResults.isFeedbackSubmitted) {
    return ApResultsStack.AP_RESULTS_UPSELL_SCREEN;
  }

  if (state.apResults.isDataSubmitted) {
    return ApResultsStack.AP_RESULTS_FEEDBACK_SCREEN;
  }

  return ApResultsStack.AP_RESULTS_LANDING_SCREEN;
};

export const getAndSortQualifiedApCourses = (state: RootState): Course[] => {
  const enrolledCourses = getEnrolledCoursesWithDetails(state);
  const enrolledApCourses = enrolledCourses.filter(courseData =>
    isAPCourse(courseData.course),
  );

  if (!enrolledApCourses.length) {
    return [];
  }

  const qualifiedApCourses = enrolledApCourses.filter(courseData =>
    getIsCourseQualifiedForApResults({
      course: courseData.course,
      courseEnrolledOn: courseData.enrolledOn,
      courseExamDate: state.apResults.coursesExamsDate[courseData.course.name],
      previousExamDate:
        state.apResults.previousYearCoursesExamsDate[courseData.course.name],
      goalKey: courseData.goalKey,
    }),
  );

  return qualifiedApCourses
    .sort((a, b) => a.course.name.localeCompare(b.course.name))
    .map(courseData => courseData.course);
};

export const getCourseScoreDetailsStatus = (
  score?: ApResultsScoreEnum,
): GetCourseScoreDetailsStatus => {
  if (!score) {
    return { showCourseDetail: false };
  }

  const showAppreciation = score === ApResultsScoreEnum.FIVE;
  const showHappyValidation =
    score === ApResultsScoreEnum.THREE || score === ApResultsScoreEnum.FOUR;

  return {
    showCourseDetail: showAppreciation || showHappyValidation,
    showAppreciation,
    showHappyValidation,
  };
};

export const getIsAllApResultsInputFilled = (state: RootState): boolean => {
  const courses = getAndSortQualifiedApCourses(state);

  if (!courses.length) {
    return false;
  }

  return courses.every(course => {
    const courseName = course.name;
    const score = state.apResults.examResults[courseName];

    const courseDetailsStatus = getCourseScoreDetailsStatus(score);
    if (courseDetailsStatus.showHappyValidation) {
      const happyValidation = state.apResults.isHappyWithResults[courseName];
      return Boolean(happyValidation);
    }

    return Boolean(score);
  });
};

export const getIsAllNextInputsFilled = (state: RootState): boolean => {
  const planSat = state.apResults.planTakeSat;
  if (!planSat) {
    return false;
  }

  const planApNextYear = state.apResults.planApNextYear;
  if (!planApNextYear) {
    return false;
  }

  const examDate = state.onboarding.examDate;
  if (planSat === ApResultsYesNoEnum.YES && !examDate) {
    return false;
  }

  const selectedApCourses = state.apResults.apCoursesToTakeNextYear;
  if (planApNextYear === ApResultsYesNoEnum.YES && !selectedApCourses.length) {
    return false;
  }

  return true;
};

export const getApResultsNextInputsSummary = (
  state: RootState,
): ApResultsNextInputsSummary => {
  const planSat = state.apResults.planTakeSat;
  const examDate = state.onboarding.examDate;

  const planApNextYear = state.apResults.planApNextYear;
  const selectedApCourses = state.apResults.apCoursesToTakeNextYear;

  return {
    planSat: planSat,
    satDate: planSat === ApResultsYesNoEnum.YES ? examDate : undefined,
    takingApNextYear: planApNextYear,
    nextYearApCourses:
      planApNextYear === ApResultsYesNoEnum.YES ? selectedApCourses : undefined,
  };
};
