import { useRoute } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { LayoutChangeEvent, StyleSheet, View, ViewStyle } from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import ContentPointsView from '../../../../Common/components/home/ContentPointsView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { VideoFrameView } from '../../../../Common/components/home/VideoFrameView';
import { MatchingPairsCard } from '../../../../Common/entities';
import {
  useCardState,
  useCardTimeRecorder,
  useMatchingPairsCardHintVideoVisibility,
} from '../../../../Common/services/hooks';
import {
  CourseStack,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { useFetchRaiseHandTutor } from '../../../../RaiseHand/services/hooks';
import MatchingPairsHeader from '../../../components/contents/MatchingPairsHeader';
import MatchingPairsOptionsView from '../../../components/contents/MatchingPairsOptionsView';
import Animations from '../../../components/courses/CoinsAnimation';
import {
  getCardAttributes,
  getMatchingAnswer,
  getQuestionPoints,
  resetMatchingPairsAnswer,
  setExplanationShown,
} from '../../../services/slices';

import BaseContentCard from './BaseContentCard';
import { Layout } from './MCQItemView';
import homeStyles from './styles';

type Props = {
  item: MatchingPairsCard;
  isVisible: boolean;
  showCoinsAnimation: boolean;
};

const ANSWER_SELECTED_TIMEOUT = 2000;

let questionWidthVar: number | null = null;

export default ({
  item,
  isVisible,
  showCoinsAnimation,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const route = !isWebPlatform ? useRoute() : null;

  const [videoCharacterId, videoActions] =
    useMatchingPairsCardHintVideoVisibility(item);
  const cardTimeRecorder = useCardTimeRecorder(item);

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );
  const collapsedQuestion = useAppSelector(
    state => getCardAttributes(state.questions, item).collapsedQuestion,
  );
  const matchingPairsOptions = useAppSelector(state =>
    getMatchingAnswer(state.answer, item),
  );

  const points = useAppSelector(
    state => getQuestionPoints(state.answer, item.uniqueId),
    (prev, next) => prev === next,
  );

  const firstSelectedCharacterFromLeftOptions = useMemo(() => {
    if (!matchingPairsOptions?.leftOptions) {
      return '';
    }

    return matchingPairsOptions.leftOptions[0].uniqueId;
  }, [matchingPairsOptions?.leftOptions]);

  const matchingPairsOptionsVisible = useMemo(() => {
    return visibilityStyle !== 'no-text' && !!matchingPairsOptions;
  }, [visibilityStyle, matchingPairsOptions]);

  const questionNotCollapsed = useMemo(() => {
    return visibilityStyle !== 'no-text' && !collapsedQuestion;
  }, [collapsedQuestion, visibilityStyle]);

  const isCourseFeedScreen = useMemo(() => {
    return route?.name === CourseStack.COURSE_FEED_SCREEN;
  }, [route?.name]);

  const helpVideoDetails = useMemo(() => {
    if (!videoCharacterId) {
      return null;
    }

    return item.charactersFromLeftOptions[videoCharacterId];
  }, [item, videoCharacterId]);

  const [isAllOptionsSelected, setAllOptionsSelected] = useCardState(
    item,
    false,
  );
  const [animateTryMe, setAnimateTryMe] = useCardState(item, false);
  const [isCorrectAnswered, setIsCorrectAnswered] = useCardState(item, false);
  const [isAnswered, setIsAnswered] = useCardState(item, false);

  const [contentPointLayout, setContentPointLayout] = useCardState<Layout>(
    item,
    {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  );
  const [parentY, setParentY] = useCardState(item, 0);
  const { raiseHandTutor } = useFetchRaiseHandTutor(
    helpVideoDetails?.name ??
      Object.values(item.charactersFromLeftOptions)[0]?.name ??
      '',
  );

  useEffect(() => {
    if (videoCharacterId) {
      dispatch(setExplanationShown({ item }));
    }
  }, [videoCharacterId, dispatch, item]);

  useEffect(() => {
    if (!matchingPairsOptions) {
      dispatch(resetMatchingPairsAnswer({ item }));
    }
  }, [matchingPairsOptions, item, dispatch]);

  const onLayout = useCallback((event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    questionWidthVar = width;
  }, []);

  const handleAllOptionsPaired = useCallback(() => {
    setAllOptionsSelected(true);
  }, [setAllOptionsSelected]);

  const onHintPress = (characterId: string = '', answerIncorrectly = false) => {
    if (isWebPlatform) {
      videoActions.set({
        shouldPlayAllCharacters: false,
        selectedCharacterFromLeftOptions:
          characterId || firstSelectedCharacterFromLeftOptions,
      });
    } else {
      if (!answerIncorrectly) {
        cardTimeRecorder.recordHelpShown();
      }

      const isCharacterIdSelected =
        !!videoCharacterId && videoCharacterId === characterId;

      if (answerIncorrectly && isCharacterIdSelected) {
        return;
      }

      videoActions.proceedSelection(characterId);
    }
  };

  useEffect(() => {
    if (visibilityStyle === 'no-text') {
      videoActions.close();
    }
  }, [videoActions, visibilityStyle]);

  const handleOnAnswerSelected = (uniqueId: string, isCorrect: boolean) => {
    if (!isCorrect) {
      onHintPress(uniqueId, true);
    }

    !animateTryMe && setAnimateTryMe(!isCorrect);
    setIsCorrectAnswered(isCorrect);
    setIsAnswered(true);

    setTimeout(() => {
      setIsCorrectAnswered(false);
      setIsAnswered(false);
    }, ANSWER_SELECTED_TIMEOUT);
  };

  const handleContentPointLayoutChange = (layout: Layout) => {
    setContentPointLayout(layout);
  };

  const handleRaiseHandPress = () => {
    setAnimateTryMe(false);
  };

  return (
    <BaseContentCard item={item}>
      <View style={styles.contentContainer}>
        <View
          style={MatchingPairsStyles.textContainer(isCourseFeedScreen)}
          onLayout={!questionWidthVar ? onLayout : undefined}>
          {questionNotCollapsed && (
            <View
              style={homeStyles.contentTypeContainer}
              onLayout={event => {
                const { y } = event.nativeEvent.layout;
                setParentY(y);
              }}>
              <ContentTypeView
                contentType={item.type}
                isSmall
                typeName={item.typeName}
              />
              {points !== undefined && (
                <ContentPointsView
                  points={points}
                  isSmall
                  isPositive={isAllOptionsSelected || isCorrectAnswered}
                  isAnswered={isAllOptionsSelected || isAnswered}
                  onLayoutChange={handleContentPointLayoutChange}
                  parentY={parentY}
                />
              )}
            </View>
          )}

          {questionNotCollapsed && <MatchingPairsHeader item={item} />}
        </View>

        <View style={styles.expanded}>
          {matchingPairsOptionsVisible ? (
            <MatchingPairsOptionsView
              item={item}
              onHintPress={onHintPress}
              currentPlayedVideo={videoCharacterId}
              onAnswer={handleOnAnswerSelected}
              onAllOptionsPaired={handleAllOptionsPaired}
            />
          ) : null}
        </View>
      </View>

      <ActionButtonsView
        item={item}
        forceMutable
        animateHand={animateTryMe}
        handleRaiseHand={handleRaiseHandPress}
        speakerCharacter={raiseHandTutor}
      />

      {helpVideoDetails && visibilityStyle !== 'no-text' && (
        <VideoFrameView
          card={item}
          videoUrl={helpVideoDetails?.videoUrl}
          subtitleUrl={helpVideoDetails?.subtitleUrl}
          isVisible={isVisible}
          name={helpVideoDetails?.name ?? ''}
          title={helpVideoDetails?.title ?? ''}
          showVideo={!!videoCharacterId}
          shouldRestart={!videoCharacterId}
          hideVideo={videoActions.close}
        />
      )}
      {showCoinsAnimation && contentPointLayout && isAllOptionsSelected && (
        <Animations startPosition={contentPointLayout} />
      )}
    </BaseContentCard>
  );
};

const MatchingPairsStyles = {
  textContainer: (isCourseFeedScreen: boolean): ViewStyle => ({
    flexGrow: 20,
    marginTop: isCourseFeedScreen ? 32 : 0,
    justifyContent: 'flex-end',
    paddingBottom: 8,
  }),
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
  wrappedTextContainer: {
    marginEnd: 0,
    opacity: 0.9,
  },
  questionTextSmall: {
    ...Fonts.normalize(Fonts.large),
  },
});
