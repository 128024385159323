import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionError } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { getAPProgramDetailsGraphQLCall } from '../../../graphql';
import {
  mapAPProgramDetailsWithAvatars,
  mapSelectedApCoursesWithGrouping,
} from '../../mappers';
import {
  AP_ONBOARDING_SLICE_NAME,
  ApOnboardingSliceState,
} from '../ApOnboardingSlice';

export const loadTargetCommitmentAction = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/loadTargetCommitment`,
  async (payload: void, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const { apOnboarding } = rootState;
    const targetGrade = apOnboarding.apCourseTargetGrade;

    const { selectedAvailableCourses } =
      mapSelectedApCoursesWithGrouping(rootState);
    const selectedCourses = selectedAvailableCourses.map(course => ({
      courseName: course.name,
      targetGrade: targetGrade[course.name],
    }));

    try {
      const response = await getAPProgramDetailsGraphQLCall({
        selectedCourses,
      });
      return mapAPProgramDetailsWithAvatars(response, selectedAvailableCourses);
    } catch (error) {
      if (error instanceof Error) {
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const loadTargetCommitmentActionExtraReducers = (
  builder: ActionReducerMapBuilder<ApOnboardingSliceState>,
): void => {
  builder.addCase(loadTargetCommitmentAction.pending, (state, action) => {
    state.apProgramDetailsError = false;
  });
  builder.addCase(loadTargetCommitmentAction.fulfilled, (state, action) => {
    state.apProgramDetails = action.payload;
    state.apProgramDetailsError = false;
  });
  builder.addCase(loadTargetCommitmentAction.rejected, (state, action) => {
    state.apProgramDetailsError = true;
  });
};
