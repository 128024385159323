import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  title?: string;
};

export const CoursePickerSectionHeader = (props: Props): React.ReactElement => {
  return (
    <View style={styles.sectionHeaderContainer}>
      <Text style={styles.sectionHeaderText}>{props.title ?? ''}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  sectionHeaderContainer: {
    marginTop: Sizes.medium,
    marginBottom: Sizes.xsmall,
  },
  sectionHeaderText: {
    ...Fonts.small,
    color: Colors.black60,
  },
});

export const renderCoursePickerSectionHeader = (
  props: Props,
): React.ReactElement => {
  return <CoursePickerSectionHeader title={props.title} />;
};
