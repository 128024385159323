import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { formatLocalizedPrice } from '../../../Common/services/utils';
import { Colors, Fonts } from '../../../Common/services/utils/AppConstants';
import { useBootcampPaywallData } from '../../services/hooks';

export const BootcampPaywallLimitedOffer = (): React.ReactElement => {
  const { userProductData, userProductDataDiscount, discountPercentage } =
    useBootcampPaywallData();

  const regularPriceString = useMemo(() => {
    return userProductData?.localizedPrice
      ? formatLocalizedPrice(userProductData.localizedPrice)
      : 'N';
  }, [userProductData?.localizedPrice]);

  const discountOfferValue = useMemo(() => {
    return locale.bootcamp_paywall.discount_label.replace(
      '${PERCENT}',
      discountPercentage.toString(),
    );
  }, [discountPercentage]);

  const discountPriceString = useMemo(() => {
    return userProductDataDiscount?.localizedPrice
      ? formatLocalizedPrice(userProductDataDiscount.localizedPrice)
      : 'N';
  }, [userProductDataDiscount?.localizedPrice]);

  return (
    <View>
      <Text style={styles.allForText}>
        {locale.bootcamp_paywall.all_for}&nbsp;
        <Text style={styles.regularPriceStrikethrough}>
          {regularPriceString}
        </Text>
        &nbsp;
        <Text style={styles.discountPriceBold}>{discountPriceString}</Text>
      </Text>
      <Text style={styles.text}>
        {locale.bootcamp_paywall.limited_offer}
        <Text>{discountOfferValue}</Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: 5,
  },
  text: {
    ...Fonts.xsmall600,
    textAlign: 'center',
    width: '100%',
    color: Colors.actionGreen,
  },
  allForText: {
    ...Fonts.xxlargeBold600,
    textAlign: 'center',
  },
  regularPriceStrikethrough: {
    ...Fonts.xxlarge,
    textDecorationLine: 'line-through',
    color: Colors.white60,
  },
  discountPriceBold: {
    ...Fonts.xxlargeBold800,
  },
});
