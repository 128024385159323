import {
  externalApi,
  handleRequestError,
} from '../../../App/services/requests';

export const getSubtitleDataRequest = async (url: string): Promise<string> => {
  const response = await externalApi.get<string>(url);
  handleRequestError(response);

  return response.data;
};
