import { MessageScreenEntryPoints } from '../../../../Messages/entities';
import { RaiseHandScreenTypes } from '../../../../RaiseHand/entities';
import { Card } from '../../../entities';

import Container from './container';

export type Props = {
  videoUrl: string;
  isVisible: boolean;
  isPaused: boolean;
  card: Card;
  style: any;
  videoStyle?: any;
  isMuted?: boolean;
  seekToTime?: number;
  seekToRelativePosition?: number;
  repeat?: boolean;
  controlButtonStyle?: any;
  controlButtonSize?: number;
  onVideoEnded?: () => void;
  onVideoRestarted?: () => void;
  controlButtonContainerStyle?: any;
  shouldRestart?: boolean;
  from?: MessageScreenEntryPoints | RaiseHandScreenTypes | string;
  tutorName?: string;
};

export default Container;
