import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Course } from '../../Common/entities';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';

type PaywallHeaderProps = {
  course: Course;
  style?: StyleProp<any>;
  isPushNotification?: boolean;
  freeTrialOfferPrice: string;
  apCoursePrice: string;
};

const COURSE_IMAGE_SIZE = 48;

export const PaywallHeader = ({
  course,
  style,
  isPushNotification = false,
  freeTrialOfferPrice,
  apCoursePrice,
}: PaywallHeaderProps): React.ReactElement => {
  const courseEnrollmentCounts = useAppSelector(
    state => state.courseEnrollments.coursesEnrollmentCount,
  );

  const paywallHeading = useMemo(() => {
    if (isPushNotification) {
      const discountValue = Math.round(
        (1 - parseFloat(freeTrialOfferPrice) / parseFloat(apCoursePrice)) * 100,
      );
      return locale.paywall.final_offer_title.replace(
        '${DISCOUNT}',
        discountValue.toString(),
      );
    }
    return locale.paywall.heading_hs;
  }, [isPushNotification, freeTrialOfferPrice, apCoursePrice]);

  const managedText = useMemo(() => {
    return {
      subtitle: locale.paywall.students_studying
        .replace('${TOTAL_UNITS}', course.units.length.toString())
        .replace(
          '${STUDENTS_STUDYING}',
          courseEnrollmentCounts[course.id].toString(),
        ),

      finalOffer: locale.paywall.final_offer_description.replace(
        '${FREE_TRIAL_PRICE}',
        freeTrialOfferPrice,
      ),
    };
  }, [
    course.units.length,
    courseEnrollmentCounts,
    course.id,
    freeTrialOfferPrice,
  ]);

  return (
    <View style={[styles.courseHeader, style]}>
      <FastImage
        style={styles.courseAvatar}
        source={{ uri: course.avatar ?? '' }}
      />
      <Text style={styles.title}>{course.name}</Text>
      <Text style={styles.subtitle}>{managedText.subtitle}</Text>
      <Text style={styles.header}>{paywallHeading}</Text>

      {isPushNotification && (
        <Text style={styles.trialEndedDescription}>
          {managedText.finalOffer}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  courseHeader: {
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 10,
  },
  courseAvatar: {
    width: COURSE_IMAGE_SIZE,
    height: COURSE_IMAGE_SIZE,
    borderRadius: COURSE_IMAGE_SIZE / 2,
  },
  title: {
    ...Fonts.semixlLargeBold,
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white70,
  },
  header: {
    ...Fonts.normalize(Fonts.xxlargeBold),
    textAlign: 'center',
    marginTop: 20,
  },
  trialEndedDescription: {
    ...Fonts.small,
    color: Colors.white70,
    textAlign: 'center',
    marginTop: 5,
    paddingHorizontal: 20,
  },
});
