import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  isComingFromAPProgramFlow,
  isComingFromSATBootcampFlow,
} from '../../mappers';
import { proceedAPProgramFlowOnSignupSuccess } from '../ApOnboardingSliceActions';
import {
  ONBOARDING_SLICE_NAME,
  setDoOnboardingFlowAfterSignup,
} from '../OnboardingSlice';

import { proceedSATBootcampFlowOnSignupSuccess } from './ProceedSATBootcampFlowOnSignupSuccessAction';

export const proceedOnboardingDataOnSignupSuccess = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedOnboardingDataOnSignupSuccess`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;

      if (isComingFromSATBootcampFlow(state)) {
        await thunkApi
          .dispatch(proceedSATBootcampFlowOnSignupSuccess())
          .unwrap();
      } else if (isComingFromAPProgramFlow(state)) {
        await thunkApi.dispatch(proceedAPProgramFlowOnSignupSuccess()).unwrap();
      }

      thunkApi.dispatch(setDoOnboardingFlowAfterSignup(true));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
