import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';

import { TestPrepTimer } from './TestPrepTimer'; // Import the TestPrepTimer component

type Props = {
  handleBackPress: () => void;
};

export const TutorMessageHeader = ({
  handleBackPress,
}: Props): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();

  const headerContainerStyle = useMemo(
    () => [
      styles.headerContainer,
      {
        paddingTop: safeAreaTop,
        height: safeAreaTop + 40,
      },
    ],
    [safeAreaTop],
  );

  return (
    <LinearGradient
      colors={Colors.topicCompletionHeaderGradient}
      start={{ x: 0.5, y: 0.65 }}
      style={headerContainerStyle}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <AssetImage asset={Assets.common.backArrowButtonIcon} height={24} />
          <Text style={styles.backToTestTitle} numberOfLines={1}>
            {locale.testPrep.back_to_test}
          </Text>
        </TouchableOpacity>
        <View style={styles.expanded} />
        <View style={styles.questionCountContainer}>
          <TestPrepTimer />
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
  },
  backToCourseText: {
    ...Fonts.small,
  },
  backButton: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanded: {
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  questionCountContainer: {
    flexDirection: 'row',
    columnGap: 5,
    marginHorizontal: 10,
    alignItems: 'center',
  },
  backToTestTitle: {
    ...Fonts.small,
  },
});
