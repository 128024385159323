import { AIContentType, ContentAttribute } from '../../Common/entities';

export enum PrefillThreshold {
  generateNewRequest = 1,
  useExistingRequest = 0,
}

export type AskDMFigureRequest = {
  allowFallbackContent?: boolean;
  attributes: ContentAttribute[];
  courseId: string;
  levelId: string;
  contentTypeId?: AIContentType;
  requestId?: string;
  prefillThreshold?: PrefillThreshold;
  contentGeneratorId: string;
  isSyncGeneration?: boolean;
  extendedAttributes: {
    platformId: string;
  }[];
};
