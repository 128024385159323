import { ApResultsScoreEnum, ApResultsScoreType } from '../entities';

export const DEFAULT_EARLIEST_EXAM_DATE = '2023-06-30';

export const AP_RESULTS_SCORE_LIST: ApResultsScoreType[] = [
  {
    key: 'NO_TEST',
    value: ApResultsScoreEnum.NO_TEST,
    label: `${ApResultsScoreEnum.NO_TEST}`,
  },
  {
    key: 'ONE',
    value: ApResultsScoreEnum.ONE,
    label: `${ApResultsScoreEnum.ONE}`,
  },
  {
    key: 'TWO',
    value: ApResultsScoreEnum.TWO,
    label: `${ApResultsScoreEnum.TWO}`,
  },
  {
    key: 'THREE',
    value: ApResultsScoreEnum.THREE,
    label: `${ApResultsScoreEnum.THREE}`,
  },
  {
    key: 'FOUR',
    value: ApResultsScoreEnum.FOUR,
    label: `${ApResultsScoreEnum.FOUR}`,
  },
  {
    key: 'FIVE',
    value: ApResultsScoreEnum.FIVE,
    label: `${ApResultsScoreEnum.FIVE}`,
  },
];

export const AP_RESULTS_SCORES_VALUES = AP_RESULTS_SCORE_LIST.map(
  item => item.value,
);
