import { Amplify } from 'aws-amplify';
import axios from 'axios';

import customEnv from '../../../../custom-aws-exports';
import { CustomAwsEnv } from '../../../Common/entities';
import { environment, ENV_KEYS } from '../../../Common/services/utils';
import { graphqlApi } from '../requests';

export class ConfigService {
  private static instance: ConfigService;

  private constructor() {}

  static getInstance(): ConfigService {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }

    return ConfigService.instance;
  }

  async checkCustomEnvironmentExists(
    preSelectedEnv?: CustomAwsEnv,
  ): Promise<boolean> {
    try {
      const response = await axios.get(
        preSelectedEnv?.aws_appsync_graphqlEndpoint ||
          customEnv.aws_appsync_graphqlEndpoint,
        {
          validateStatus: () => true,
        },
      );

      return Boolean(response.status);
    } catch (e) {
      return false;
    }
  }

  async setCustomEnvironment(
    force: boolean = false,
    preSelectedEnv?: CustomAwsEnv,
  ): Promise<boolean> {
    if (!force) {
      const result = await this.checkCustomEnvironmentExists(preSelectedEnv);
      if (!result) {
        return false;
      }
    }

    const { AWS_BUCKET_NAME, AWS_REGION } = environment.getValues([
      ENV_KEYS.AWS_BUCKET_NAME,
      ENV_KEYS.AWS_REGION,
    ]);

    const selectedEnv = preSelectedEnv || customEnv;

    graphqlApi.setCustomBaseUrl(selectedEnv.aws_appsync_graphqlEndpoint);
    graphqlApi.setCustomAPIKey(selectedEnv.aws_appsync_apiKey);

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: selectedEnv.aws_user_pools_id,
          identityPoolId: selectedEnv.aws_cognito_identity_pool_id,
          userPoolClientId: selectedEnv.aws_user_pools_web_client_id,
        },
      },

      Storage: {
        S3: {
          region: AWS_REGION,
          bucket: AWS_BUCKET_NAME,
        },
      },

      API: {
        GraphQL: {
          region: selectedEnv.aws_appsync_region,
          endpoint: selectedEnv.aws_appsync_graphqlEndpoint,
          apiKey: selectedEnv.aws_appsync_apiKey,
          defaultAuthMode: 'userPool',
        },
      },
    });

    return true;
  }

  setDefaultEnvironment = (): void => {
    graphqlApi.resetAPI();

    const {
      API_KEY,
      AWS_BUCKET_NAME,
      AWS_POOL_WEB_CLIENT_ID,
      AWS_REGION,
      AWS_USER_POOL_ID,
      GRAPHQL_ENDPOINT,
      IDENTITY_POOL_ID,
    } = environment.getValues([
      ENV_KEYS.API_KEY,
      ENV_KEYS.AWS_AUTHENTICATION_TYPE,
      ENV_KEYS.AWS_BUCKET_NAME,
      ENV_KEYS.AWS_POOL_WEB_CLIENT_ID,
      ENV_KEYS.AWS_REGION,
      ENV_KEYS.AWS_USER_POOL_ID,
      ENV_KEYS.GRAPHQL_ENDPOINT,
      ENV_KEYS.IDENTITY_POOL_ID,
    ]);

    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: AWS_USER_POOL_ID,
          identityPoolId: IDENTITY_POOL_ID,
          userPoolClientId: AWS_POOL_WEB_CLIENT_ID,
        },
      },

      Storage: {
        S3: {
          region: AWS_REGION,
          bucket: AWS_BUCKET_NAME,
        },
      },

      API: {
        GraphQL: {
          region: AWS_REGION,
          endpoint: GRAPHQL_ENDPOINT,
          apiKey: API_KEY,
          defaultAuthMode: 'userPool',
        },
      },
    });
  };
}

export const getConfigService = (): ConfigService =>
  ConfigService.getInstance();
