import React, { useMemo } from 'react';
import { StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { AssetImage } from '../../../App/assets/AssetImage';
import { CommonAssets } from '../../../Common/assets';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';
import { useEntitlementConfirmationScreenData } from '../../services/hooks';

export const EntitlementConfirmationHeading = React.memo(
  (): React.ReactElement => {
    const content = useEntitlementConfirmationScreenData();
    const size = useWindowDimensions();

    const confettiSizeStyles = useMemo(() => {
      return {
        width: size.width,
        height: size.width,
      };
    }, [size.width]);

    return (
      <View style={styles.headingContainer}>
        <View style={styles.imagesContainer}>
          {content?.entitlementImages.map(image => (
            <FastImage
              key={image}
              style={styles.entitlementImage}
              source={{ uri: image }}
            />
          ))}
          <AssetImage
            asset={CommonAssets.GiftAnimated}
            imageStyle={styles.headingImage}
            containerStyle={styles.giftContainer}
          />
        </View>
        <AssetImage
          asset={OnboardingAssets.ConfettiExplosionAnimated}
          imageStyle={confettiSizeStyles}
          containerStyle={styles.confettiContainer}
        />
        <Text style={styles.congratulationsText}>{content?.congrats}</Text>
        <Text style={styles.title}>{content?.title}</Text>
        <Text style={styles.subtitle}>{content?.description}</Text>
      </View>
    );
  },
);

const styles = StyleSheet.create({
  headingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.small,
  },
  title: {
    ...Fonts.xxxlargeBold,
    textAlign: 'center',
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'center',
  },
  congratulationsText: {
    ...Fonts.smallSemiBold,
    color: Colors.emeraldGreen,
    textAlign: 'center',
  },
  confettiContainer: {
    position: 'absolute',
    bottom: 66,
  },
  headingImage: {
    width: 80,
    height: 80,
  },
  entitlementImage: {
    width: 80,
    height: 80,
    borderRadius: 40,
    borderWidth: 1,
    borderColor: Colors.white100,
  },
  giftContainer: {
    transform: [{ scale: 1.1 }],
    position: 'absolute',
    top: 14,
    left: 26,
  },
  imagesContainer: {
    gap: 6,
  },
});

export const renderFreeAccessConfirmationHeading = (): React.ReactElement => {
  return <EntitlementConfirmationHeading />;
};
