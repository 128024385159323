import {
  Asset,
  ImageLibraryOptions,
  launchImageLibrary,
} from 'react-native-image-picker';

import locale from '../../../App/locale';

export const pickSingleImageFromGallery = async (
  options: Partial<ImageLibraryOptions>,
): Promise<string | undefined> => {
  const finalOptions = {
    mediaType: 'photo',
    includeBase64: false,
    ...options,
  } as ImageLibraryOptions;
  return new Promise<string | undefined>((resolve, reject) => {
    launchImageLibrary(finalOptions, response => {
      try {
        if (!response) {
          reject('No response');
          return;
        }
        if (response.didCancel) {
          resolve(undefined);
        } else if (response.errorCode) {
          reject(response.errorMessage);
        } else {
          const asset = response.assets && response.assets[0];
          resolve(asset?.uri);
        }
      } catch (e) {
        const error: any = e;
        reject(error?.message ?? error);
      }
    });
  });
};

export const pickFeedbackMediaFromGallery = async (
  options: Partial<ImageLibraryOptions>,
): Promise<Asset | undefined> => {
  const maxWidth = 500;
  const maxHeight = 800;
  const finalOptions = {
    mediaType: 'mixed',
    includeBase64: false,
    maxHeight: maxHeight,
    maxWidth: maxWidth,
    videoQuality: 'low',
    ...options,
  } as ImageLibraryOptions;
  return new Promise<Asset | undefined>((resolve, reject) => {
    launchImageLibrary(finalOptions, response => {
      try {
        if (!response) {
          reject(locale.errors.media_error);
          return;
        }
        if (response.didCancel) {
          resolve(undefined);
        } else if (response.errorCode) {
          reject(response.errorMessage);
        } else {
          const asset = response.assets && response.assets[0];
          resolve(asset);
        }
      } catch (e) {
        const error: any = e;
        reject(error?.message ?? error);
      }
    });
  });
};
