import React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type CoursePickerHeaderProps = {
  title: string;
  titleStyle?: TextStyle;
  onClose: () => void;
};

export const CoursePickerHeader = (
  props: CoursePickerHeaderProps,
): React.ReactElement => {
  return (
    <View style={styles.header}>
      <Text style={[styles.title, props.titleStyle]}>{props.title}</Text>
      <TouchableOpacity style={styles.closeIcon} onPress={props.onClose}>
        <AssetImage
          asset={Assets.home.closeItemCrossIcon}
          width={16}
          fill="black"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    height: Sizes.normalizeIP14PM(50),
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    flex: 1,
    ...Fonts.caption,
    color: 'black',
    textAlign: 'center',
  },
  closeIcon: {
    alignItems: 'center',
    height: 30,
    justifyContent: 'center',
    right: 16,
    width: 30,
  },
});
