import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import {
  CommonLinearProgress,
  CommonOrdinalNumber,
} from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';
import { useSATProgramDetailsTarget } from '../../services/hooks';

type Props = {
  shouldShowStats: boolean;
  percentile?: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
};

export const SATProfileStatsTotalScore = (props: Props): React.ReactElement => {
  const { shouldShowStats, score, maxScore, percentile, unitsIndex } = props;
  const { targetPercentage, target } = useSATProgramDetailsTarget({
    maxScore: maxScore,
  });

  const noScoreTextDescription = useMemo(() => {
    return locale.sat.stats_total_score_hidden
      .replace('${FIRST_UNIT}', `${unitsIndex?.first || 'N'}`)
      .replace('${LAST_UNIT}', `${unitsIndex?.last || 'N'}`);
  }, [unitsIndex]);

  const progressPercentage = useMemo(() => {
    const currentMaxScore = maxScore || 1;
    const currentScore = score || 0;

    return (currentScore / currentMaxScore) * 100;
  }, [score, maxScore]);

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{locale.sat.stats_total_score_title}</Text>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <>
            <Text style={styles.scoreText}>{score}</Text>

            <View style={styles.scorePercentileTextContainer}>
              <CommonOrdinalNumber
                numberInString={percentile}
                textStyle={styles.scorePercentileText}
                ordinalTextStyle={styles.scorePercentileText}
              />
              <Text style={styles.scorePercentileTextSuffix}>
                {locale.sat.stats_percentile_suffix}
              </Text>
            </View>

            <View style={styles.scoreProgressContainer}>
              <CommonLinearProgress
                progressPercentage={progressPercentage}
                targetPercentage={targetPercentage}
                target={target}
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  titleText: {
    ...Fonts.smallBold,
    color: 'white',
    textAlign: 'center',
  },
  detailContainer: {
    marginTop: Sizes.small,
  },
  detailText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },

  scoreProgressContainer: {
    marginTop: Sizes.small,
  },
  scoreText: {
    ...Fonts.xxxxlargeBold,
    color: Colors.emeraldGreen,
    textAlign: 'center',
  },
  scorePercentileText: {
    color: Colors.white60,
  },
  scorePercentileTextSuffix: {
    ...Fonts.small,
    color: Colors.white60,
  },
  scorePercentileTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 4,
  },
});
