import moment from 'moment';

import locale from '../../../App/locale';
import { RootState } from '../../../App/store';
import {
  CommonApExamsConfigs,
  PaywallPurchaseType,
  ProgramAccessType,
} from '../../../Common/entities';

export const mapSATPaywallWithApAddonStatus = (state: RootState): boolean => {
  const apProgramAccessType = state.AP.program?.accessDetails?.accessType;
  return !(
    apProgramAccessType && apProgramAccessType !== ProgramAccessType.EXPIRED
  );
};

export const mapSATPaywallWithApAddonSelectionStatus = (
  state: RootState,
): boolean => {
  const hasAccessToSatAddOn = mapSATPaywallWithApAddonStatus(state);
  return hasAccessToSatAddOn && state.bootcampPaywall.isAPAddOnSelected;
};

export const mapSATPaywallPurchaseType = (
  state: RootState,
): PaywallPurchaseType => {
  return mapSATPaywallWithApAddonSelectionStatus(state)
    ? PaywallPurchaseType.SAT_BOOTCAMP_PLUS_AP_PROGRAM
    : PaywallPurchaseType.SAT_BOOTCAMP;
};

export const mapNextLastApExamDate = (
  configs?: CommonApExamsConfigs,
): string | undefined => {
  if (!configs) {
    return undefined;
  }

  const { apExamDates } = configs;

  if (!apExamDates?.length) {
    return undefined;
  }

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentDateString = today.toISOString().split('T')[0];
  const currentDateMoment = moment(currentDateString, 'YYYY-MM-DD');

  let latestCurrentYearDate: Date | undefined;
  let latestNextYearDate: Date | undefined;

  for (const exam of apExamDates) {
    if (exam.examDates) {
      // Check for future exam dates in the current year
      const currentYearDate = exam.examDates[currentYear];
      if (currentYearDate) {
        const currentYearDateMoment = moment(currentYearDate, 'YYYY-MM-DD');

        if (
          !latestCurrentYearDate ||
          currentYearDateMoment.isAfter(latestCurrentYearDate)
        ) {
          latestCurrentYearDate = currentYearDateMoment.toDate();
        }
      }

      // Track the latest exam date for the next year
      const nextYearDate = exam.examDates[currentYear + 1];
      if (nextYearDate) {
        const nextYearDateMoment = moment(nextYearDate, 'YYYY-MM-DD');

        if (
          !latestNextYearDate ||
          nextYearDateMoment.isAfter(latestNextYearDate)
        ) {
          latestNextYearDate = nextYearDateMoment.toDate();
        }
      }
    }
  }

  if (
    latestCurrentYearDate &&
    currentDateMoment.isBefore(latestCurrentYearDate)
  ) {
    return latestCurrentYearDate?.toISOString().split('T')[0];
  }

  return latestNextYearDate?.toISOString().split('T')[0];
};

export const mapNextApLastExamDateDuration = (state: RootState): string => {
  const nextLastApExamDate = state.bootcampPaywall.nextLastApExamDate;
  if (!nextLastApExamDate) {
    return 'N';
  }

  const inputDate = moment(nextLastApExamDate, 'YYYY-MM-DD');
  const currentDate = moment();

  const diffDays = inputDate.diff(currentDate, 'days');
  const diffWeeks = inputDate.diff(currentDate, 'weeks');
  const diffMonths = inputDate.diff(currentDate, 'months');

  if (diffMonths >= 1) {
    const selectedLocale =
      diffMonths > 1 ? locale.common.x_months : locale.common.x_month;
    return selectedLocale.replace('{MONTH}', diffMonths.toString());
  }

  if (diffWeeks >= 1) {
    const selectedLocale =
      diffWeeks > 1 ? locale.common.x_weeks : locale.common.x_week;
    return selectedLocale.replace('{WEEK}', diffWeeks.toString());
  }

  if (diffDays >= 1) {
    const selectedLocale =
      diffDays > 1 ? locale.common.x_days : locale.common.x_day;
    return selectedLocale.replace('{DAY}', diffDays.toString());
  }

  return 'N';
};
