import React, { useMemo } from 'react';
import { StyleSheet, Text, TextStyle } from 'react-native';

interface SupportedTag {
  tag: string;
  style: TextStyle;
}

const styles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  underline: {
    textDecorationLine: 'underline',
  },
});

const supportedTags: SupportedTag[] = [
  { tag: 'b', style: styles.bold },
  { tag: 'i', style: styles.italic },
  { tag: 'u', style: styles.underline },
];

export const parseStyledText = (input: string): React.ReactNode[] => {
  const tagNames = supportedTags.map(tag => tag.tag).join('|');
  const tagRegex = new RegExp(`<(\/?)(${tagNames})>(.*?)<\/\\2>`, 'g');

  const elements: React.ReactNode[] = [];
  let lastIndex = 0;

  const matches = input.matchAll(tagRegex);

  for (const match of matches) {
    const [fullMatch, closingSlash, tagName, content] = match;
    const offset = match.index!;

    if (lastIndex < offset) {
      elements.push(
        <Text key={lastIndex}>{input.slice(lastIndex, offset)}</Text>,
      );
    }

    const tag = supportedTags.find(tag => tag.tag === tagName);

    if (tag && !closingSlash) {
      elements.push(
        <Text key={offset} style={tag.style}>
          {content}
        </Text>,
      );
    }

    lastIndex = offset + fullMatch.length;
  }

  if (lastIndex < input.length) {
    elements.push(<Text key={lastIndex}>{input.slice(lastIndex)}</Text>);
  }

  return elements;
};

export type CommonRichTextProps = {
  children: string;
  style?: TextStyle;
};

export const CommonRichText = ({
  children,
  style,
}: CommonRichTextProps): React.ReactElement => {
  const parsedText = useMemo(() => parseStyledText(children), [children]);
  return <Text style={style}>{parsedText}</Text>;
};
