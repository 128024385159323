import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { SATBootcamp } from '../../entities';

import { getUserSATProgramDetailsExtraReducers } from './SATSliceActions';

const PERSIST_KEY = 'SATPersistKey';
export const SAT_SLICE_NAME = 'SATSlice';

export type SATSliceState = {
  bootcamp?: SATBootcamp;

  satTimeSpentSeconds: number;

  // Flag to record if free trial is ended for SAT bootcamp
  isFreeTrialEnded: boolean;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['bootcamp', 'isFreeTrialEnded'],
  blacklist: ['satTimeSpentSeconds'],
};

const initialState: SATSliceState = {
  bootcamp: undefined,

  satTimeSpentSeconds: 0,

  isFreeTrialEnded: false,
};

const satSlice = createSlice({
  name: SAT_SLICE_NAME,
  initialState,
  reducers: {
    resetSATSlice: state => {
      state.bootcamp = undefined;
      state.isFreeTrialEnded = false;
    },

    setSATFreeTrialEnded: (state, action: PayloadAction<boolean>) => {
      state.isFreeTrialEnded = action.payload;
    },

    setSatTimeSpentSeconds: (state, action: PayloadAction<number>) => {
      state.satTimeSpentSeconds = action.payload;
    },
  },
  extraReducers: builder => {
    getUserSATProgramDetailsExtraReducers(builder);
  },
});

export const { resetSATSlice, setSatTimeSpentSeconds, setSATFreeTrialEnded } =
  satSlice.actions;

export const SATSlice = persistReducer(persistConfig, satSlice.reducer);
