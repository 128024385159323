type Listener<T = any> = (data: T) => void;

export class EventEmitterWeb<TEvents extends Record<string, any>> {
  private events: Partial<Record<keyof TEvents, Listener[]>> = {};

  on<K extends keyof TEvents>(event: K, listener: Listener<TEvents[K]>): void {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event]!.push(listener);
  }

  off<K extends keyof TEvents>(
    event: K,
    listenerToRemove: Listener<TEvents[K]>,
  ): void {
    if (!this.events[event]) {
      return;
    }

    this.events[event] = this.events[event]!.filter(
      listener => listener !== listenerToRemove,
    );
  }

  emit<K extends keyof TEvents>(event: K, data: TEvents[K]): void {
    if (!this.events[event]) {
      return;
    }

    this.events[event]!.forEach(listener => listener(data));
  }

  once<K extends keyof TEvents>(
    event: K,
    listener: Listener<TEvents[K]>,
  ): void {
    const onceListener: Listener<TEvents[K]> = data => {
      listener(data);
      this.off(event, onceListener);
    };
    this.on(event, onceListener);
  }
}
