import { Course, CourseGoalKeys } from '../../Common/entities';

export enum CourseTagType {
  PURCHASED = 'PURCHASED',
  FREETRIAL = 'FREETRIAL',
  NONE = 'NONE',
}

export type CourseWithEnrollmentData = {
  enrolledOn: string; // ISO STRING
  goalKey?: CourseGoalKeys;
  course: Course;
};
