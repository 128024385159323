import { AxiosResponse } from 'axios';

import {
  googleFormApi,
  handleRequestError,
} from '../../../App/services/requests';
import { ENV_KEYS, environment } from '../../../Common/services/utils';
import { FeedbackFormRequest } from '../../entities';

export const submitAppFeedbackCall = async (
  formData: FeedbackFormRequest,
): Promise<AxiosResponse> => {
  const FEEDBACK_GOOGLE_FORM_ENDPOINT = environment.getValue(
    ENV_KEYS.FEEDBACK_GOOGLE_FORM_ENDPOINT,
  );

  const formBody = new URLSearchParams(formData).toString();
  const response = await googleFormApi.post(
    FEEDBACK_GOOGLE_FORM_ENDPOINT,
    formBody,
  );

  handleRequestError(response);
  return response;
};
