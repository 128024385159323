import moment from 'moment';

import {
  ChatHistory,
  StreamingMessageDetails,
} from '../../../Messages/entities';

export const MAX_WAIT_FOR_CHUNK_TIME = 1_500;
export const MIN_TIME_BETWEEN_CHUNKS = 300;

export const throttle = <T>(
  fn: (args: T) => void,
  delay: number,
): ((key: string, args: T) => void) => {
  const lastCallMap = new Map<string, number>();
  const timeoutMap = new Map<string, NodeJS.Timeout | null>();
  const lastArgsMap = new Map<string, T | null>();

  return function (key: string, args: T) {
    const now = Date.now();
    lastArgsMap.set(key, args);

    const lastCall = lastCallMap.get(key) || 0;
    const timeoutId = timeoutMap.get(key);

    if (now - lastCall >= delay) {
      lastCallMap.set(key, now);
      fn(args);
    } else {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const newTimeoutId = setTimeout(() => {
        lastCallMap.set(key, Date.now());
        fn(lastArgsMap.get(key)!);
        timeoutMap.set(key, null);
      }, delay - (now - lastCall));
      timeoutMap.set(key, newTimeoutId);
    }
  };
};

export const handleTimeout = (
  fn: (key: string) => void,
  timeout: number,
): ((key: string) => void) => {
  const timeoutMap = new Map<string, NodeJS.Timeout>();
  return (key: string) => {
    if (timeoutMap.has(key)) {
      clearTimeout(timeoutMap.get(key));
    }
    const timeoutId = setTimeout(() => fn(key), timeout);
    timeoutMap.set(key, timeoutId);
  };
};

export const checkForNewMessages = async (
  streamedMessage: StreamingMessageDetails,
  loadPage: (page: number) => Promise<ChatHistory>,
): Promise<boolean> => {
  let page = 0;
  let hasNextPage: boolean | undefined = true;
  let hasNewMessages = false;

  while (hasNextPage) {
    const chatHistory = await loadPage(page);

    hasNextPage = chatHistory.hasNextPage;
    hasNewMessages ||= chatHistory.messages.some(message => {
      return moment(message.date).isAfter(moment(streamedMessage.createdAt));
    });
    page++;
  }

  return hasNewMessages;
};
