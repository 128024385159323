import { useCallback, useEffect, useState } from 'react';

import { useRefWithSubscribe } from './RefWithSubscribe';

type GenericFunction<T extends any[]> = (...args: T) => void;

export const useDebounce = <T extends any[]>(
  callback: GenericFunction<T>,
  delay: number,
): GenericFunction<T> => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const callbackRef = useRefWithSubscribe(callback);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  const debouncedFunction = useCallback(
    (...args: T) => {
      if (timer) {
        clearTimeout(timer);
      }

      const newTimer = setTimeout(() => {
        callbackRef.current(...args);
      }, delay);

      setTimer(newTimer);
    },
    [callbackRef, delay, timer],
  );

  return debouncedFunction;
};
