import { AiFigureModel } from '../../AiFigure/entities';
import { ContentCard } from '../../Common/entities';

import { Comment } from './CommentTypes';
import { FeedbackTypes } from './FeedbackTypes';

export type FeedbackAction = {
  content: ContentCard;
  value?: boolean;
  isFromDM?: boolean;
};

export type ShareAiFigureAction = {
  content: AiFigureModel;
};

export type IncreaseInteractionStateAction = {
  count: number;
};

export type CommentFeedbackAction = {
  content: Comment;
  parentCommentId?: string;
  card: ContentCard;
  value: boolean;
  isFromDM?: boolean;
};

export enum ReportContentType {
  Unrelated = 'UNRELATED',
  Incorrect = 'INCORRECT',
  Weird = 'WEIRD',
  NotInteresting = 'NOT_INTERESTING',
  Other = 'OTHER',
}

export type ReportContentPayload = {
  content: ContentCard;
  type: ReportContentType;
  feedbackNotes?: string;
  isFromDM?: boolean;
};

export type RetrieveContentPayload = {
  page: number;
  interactionType: FeedbackTypes;
};
