import React, { useEffect } from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets, { Asset } from '../../../../App/assets';
import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import { OutlinedTextContainer } from '../../../../Common/components/common/OutlinedTextContainer';
import {
  MultipleChoiceAnswer,
  MultipleChoiceOption,
  MultipleChoiceQuestion,
} from '../../../../Common/entities';
import { emitCardGestureEvent } from '../../../../Common/events';
import {
  Colors,
  Fonts,
  isWebPlatform,
  MCQAnswerSelectionState,
} from '../../../../Common/services/utils/AppConstants';
import { selectMCQOption, setCardAttributes } from '../../../services/slices';

type Props = {
  item: MultipleChoiceQuestion;
  onAnswered?: (isCorrect: boolean) => void;
};

const optionsLocationMap: Record<string, number> = {};
const scrollViewHeightsMap: Record<string, number> = {};

export default (props: Props): React.ReactElement => {
  const isLoading = useAppSelector(state => state.answer.isLoading);
  const { item } = props;
  const maxOptionSelection = item.options.length - 1;
  const dispatch = useAppDispatch();

  const scrollRef = React.useRef<ScrollView>(null);

  const optionsRef = React.useRef<Record<string, TouchableOpacity | null>>({});

  const correctAnswer: MultipleChoiceAnswer | null = useAppSelector(
    state => item.answer || state.answer.answers[item.generatedContentId],
  );

  const chosenOptionIds: string[] | null = useAppSelector(
    state => state.answer.selectedOptions[item.uniqueId],
  );

  const isCorrectOptionSelected: boolean = useAppSelector(
    state => state.answer.mcqCorrectOptionSelected[item.uniqueId],
  );

  const isOptionCorrect = (option: MultipleChoiceOption): boolean | null => {
    if (!correctAnswer) {
      return null;
    } else {
      return (
        correctAnswer.correct_options.find(
          correctOption => correctOption.id === option.id,
        ) != null
      );
    }
  };

  const getOptionKey = (
    item: MultipleChoiceQuestion,
    option: MultipleChoiceOption,
  ) => item.uniqueId + option.id.toString();

  const isOptionChosen = (option: MultipleChoiceOption): boolean => {
    return chosenOptionIds?.find(id => id === option.id) != null;
  };

  const getOptionState = (
    option: MultipleChoiceOption,
  ): MCQAnswerSelectionState => {
    const hasSelections = chosenOptionIds && chosenOptionIds.length > 0;
    const selectionOptions = chosenOptionIds;
    const isCorrect = isOptionCorrect(option);
    const isChoosen = isOptionChosen(option);
    if (!hasSelections || isCorrect == null) {
      return MCQAnswerSelectionState.neutral;
    } else if (isCorrect && isChoosen) {
      return MCQAnswerSelectionState.correctChoosen;
    } else if (isCorrect && selectionOptions?.length === maxOptionSelection) {
      return MCQAnswerSelectionState.correct;
    } else if (isChoosen) {
      return MCQAnswerSelectionState.choosen;
    }
    return MCQAnswerSelectionState.neutral;
  };

  const getAnswerColor = (option: MultipleChoiceOption): string => {
    const state = getOptionState(option);
    switch (state) {
      case MCQAnswerSelectionState.correctChoosen:
      case MCQAnswerSelectionState.correct:
        return Colors.answerRight;
      case MCQAnswerSelectionState.choosen:
        return Colors.answerWrong;
      default:
        return Colors.answerNeutral;
    }
  };

  const getAnswerIcon = (option: MultipleChoiceOption): Asset | null => {
    const state = getOptionState(option);
    switch (state) {
      case MCQAnswerSelectionState.correctChoosen:
        return Assets.home.correctAnswerGif;
      case MCQAnswerSelectionState.choosen:
        return Assets.home.wrongAnswerGif;
      default:
        return null;
    }
  };

  const measureOption = (option: MultipleChoiceOption): void => {
    const optionKey = item.uniqueId + option.id.toString();
    optionsRef.current[optionKey]?.measure((_, y, __) => {
      if (optionsLocationMap[optionKey] === undefined) {
        optionsLocationMap[optionKey] = y;
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      item.options.forEach(measureOption);
    }, 100);
  });

  const performOptionPressedLayoutUpdates = (
    option: MultipleChoiceOption,
  ): void => {
    const optionKey = item.uniqueId + option.id.toString();
    const itemUniqueId = item.uniqueId;

    if (optionsLocationMap[optionKey] !== undefined) {
      setTimeout(() => {
        optionsRef.current[optionKey]?.measure((_, y, __, height) => {
          if (
            scrollViewHeightsMap[itemUniqueId] &&
            height > scrollViewHeightsMap[itemUniqueId]
          ) {
            collapseCardQuestion(true);
          }
        });
        scrollRef.current?.scrollTo({
          y: optionsLocationMap[optionKey],
          animated: true,
        });
      }, 10);
    }
  };

  const onLongTap = (longTapEvent: GestureResponderEvent): void => {
    emitCardGestureEvent({
      gesture: 'long-tap',
      uniqueId: item.uniqueId,
      position: {
        x: longTapEvent.nativeEvent.pageX,
        y: longTapEvent.nativeEvent.pageY,
      },
    });
  };

  const onOptionPressed = (option: MultipleChoiceOption): void => {
    const isExist = chosenOptionIds?.filter(
      selectedId => selectedId === option.id,
    );
    if (isExist && isExist?.length !== 0) {
      return;
    }
    collapseCardQuestion(false);
    performOptionPressedLayoutUpdates(option);

    if (isLoading) {
      return;
    }
    if (
      isCorrectOptionSelected ||
      chosenOptionIds?.length === maxOptionSelection
    ) {
      return;
    }

    const isCorrect = isOptionCorrect(option);
    if (isCorrect != null && props.onAnswered) {
      props.onAnswered(isCorrect);
    }

    if (!isWebPlatform) {
      dispatch(selectMCQOption({ question: item, option }));
    }
  };

  const collapseCardQuestion = (value: boolean): void => {
    dispatch(
      setCardAttributes({
        card: item,
        attributes: {
          collapsedQuestion: value,
        },
      }),
    );
  };

  const optionRefSetter = (option: MultipleChoiceOption) => {
    const optionKey = getOptionKey(item, option);

    return (ref: TouchableOpacity | null) => {
      optionsRef.current[optionKey] = ref;
    };
  };

  const getTransformStyles = (option: MultipleChoiceOption) => {
    const isCorrectOption = isOptionCorrect(option);
    return isCorrectOption
      ? [{ scaleX: -1 }]
      : [{ scaleX: -1 }, { scaleY: -1 }];
  };

  const showAnswerAnimation = (
    option: MultipleChoiceOption,
    answerIcon: Asset,
  ) => {
    return (
      <Image
        style={[
          isOptionCorrect(option)
            ? styles.correctAnswerGifStyle
            : styles.wrongAnswerGifStyle,
          {
            transform: getTransformStyles(option),
          },
        ]}
        source={answerIcon.path as ImageSourcePropType}
        resizeMode="cover"
      />
    );
  };

  const renderOption = (
    option: MultipleChoiceOption,
    index: number,
  ): React.ReactElement => {
    const answerIcon = getAnswerIcon(option);
    const answerColor = getAnswerColor(option);

    return (
      <TouchableOpacity
        ref={optionRefSetter(option)}
        style={[
          styles.itemContainer,
          index === 0 && styles.margin0,
          { backgroundColor: answerColor },
        ]}
        key={option.id}
        onLongPress={onLongTap}
        onPress={() => onOptionPressed(option)}>
        <View style={styles.textWrapper}>
          <OutlinedTextContainer hq stroke={1} color={'#000000'}>
            <Text style={styles.optionText}>{option.answer.trim()}</Text>
          </OutlinedTextContainer>
        </View>
        {answerIcon && (
          <View style={styles.animationContainer}>
            {showAnswerAnimation(option, answerIcon)}
          </View>
        )}
      </TouchableOpacity>
    );
  };

  const onLayout = (scrollViewEvent: LayoutChangeEvent): void => {
    const { height } = scrollViewEvent.nativeEvent.layout;
    scrollViewHeightsMap[item.uniqueId] = height;
  };
  return (
    <ScrollView
      scrollEnabled
      style={styles.container}
      ref={scrollRef}
      onLayout={onLayout}>
      {item.options.map(renderOption)}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 10,
    marginBottom: 16,
    marginTop: 8,
  },
  itemContainer: {
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.white12,
    flexDirection: 'row',
    marginTop: 8,
    maxWidth: '100%',
    minHeight: 50,
    paddingHorizontal: 12,
    paddingVertical: 6,
    width: '100%',
  },
  margin0: {
    marginTop: 0,
  },
  optionText: {
    ...Fonts.normalize(Fonts.medium),
    paddingEnd: 2,
    paddingStart: 2,
  },
  optionTextBold: {
    ...Fonts.normalize(Fonts.mediumBold),
    paddingEnd: 2,
    paddingStart: 2,
  },
  textWrapper: {
    alignSelf: 'center',
    flexShrink: 1,
    width: '100%',
  },
  animationContainer: {
    paddingStart: 16,
    flexShrink: 1,
  },
  wrongAnswerGifStyle: {
    transform: [{ rotate: '90deg' }],
    width: 56,
    height: 56,
    alignSelf: 'center',
    top: 5,
  },
  correctAnswerGifStyle: {
    transform: [{ rotate: '90deg' }],
    width: 56,
    height: 56,
    alignSelf: 'center',
    bottom: 5,
  },
});
