import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { CommonProgressIndicator } from '../../Common/components';
import { Unit } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import GradeIndicator from '../../Learn/components/study/GradeIndicator';
import { TestPrepStartTestButton } from '../../TestPrep/components/TestPrepStartTestButton';
import { mapCourseUnitLockedStatus } from '../../UserAccess/services/mappers';
import {
  SATUnitGradeIndicatorStatus,
  SATUnitTestHistoryUnit,
} from '../entities';

type Props = {
  courseId: string;
  unit: Unit;
  onUnitSelected: (unit: Unit) => void;
  history?: SATUnitTestHistoryUnit;
  firstIndex?: boolean;
};

export const SATTestPrepUnits = ({
  courseId,
  unit,
  history,
  onUnitSelected,
  firstIndex,
}: Props): React.ReactElement => {
  const isUnitLocked = useAppSelector(
    state => mapCourseUnitLockedStatus(state, courseId, unit.id),
    (prev, next) => prev === next,
  );

  const title = useMemo(() => unit.name, [unit.name]);
  const subtitle = useMemo(
    () => `${locale.courses.unit} ${unit.number}`,
    [unit.number],
  );
  const gradeIndicatorStatus = useMemo<SATUnitGradeIndicatorStatus>(() => {
    if (isUnitLocked) {
      return SATUnitGradeIndicatorStatus.LOCKED;
    } else if (history?.totalTestsTaken) {
      return SATUnitGradeIndicatorStatus.PROGRESS_SHOWN;
    }

    return SATUnitGradeIndicatorStatus.HIDDEN;
  }, [isUnitLocked, history?.totalTestsTaken]);

  const shouldShowProgress = useMemo(() => {
    return gradeIndicatorStatus === SATUnitGradeIndicatorStatus.PROGRESS_SHOWN;
  }, [gradeIndicatorStatus]);

  const mainContainerStyle = useMemo(() => {
    if (firstIndex) {
      return [styles.container, { marginTop: 0 }];
    }

    return styles.container;
  }, [firstIndex]);

  const isSATFreeTrialEnded = useAppSelector(
    state => state.SAT.isFreeTrialEnded,
  );

  const progressPercentage = useMemo(() => {
    const bestScore = history?.bestScore || 0;
    const totalScore = history?.totalScore || 1;

    return (bestScore / totalScore) * 100;
  }, [history?.bestScore, history?.totalScore]);

  const nextTestNumber = useMemo(() => {
    return (history?.totalTestsTaken || 0) + 1;
  }, [history?.totalTestsTaken]);

  const unitScoreLabel = useMemo(() => {
    return {
      label: locale.sat.last_score,
      value: `${history?.latestScore || 0}`,
    };
  }, [history?.latestScore]);

  return (
    <TouchableOpacity
      style={mainContainerStyle}
      disabled={isSATFreeTrialEnded}
      onPress={() => onUnitSelected(unit)}>
      <View style={styles.subContainer}>
        {gradeIndicatorStatus === SATUnitGradeIndicatorStatus.LOCKED && (
          <View style={styles.gradeContainerWrapper}>
            <GradeIndicator unitLocked viewStyle={styles.progressIndicator} />
          </View>
        )}

        {shouldShowProgress && (
          <View style={styles.gradeContainerWrapper}>
            <CommonProgressIndicator
              percentage={progressPercentage}
              radius={19}
              strokeWidth={Sizes.xsmall}
            />
          </View>
        )}

        <View style={styles.textContainer}>
          <Text style={styles.title}>{title}</Text>

          <View style={styles.subtitleContainer}>
            <Text style={styles.subtitle}>{subtitle}</Text>

            {shouldShowProgress && (
              <>
                <Text style={styles.subtitleBorder}>|</Text>
                <Text style={styles.subtitle}>
                  {unitScoreLabel.label}&nbsp;
                  <Text style={styles.subtitleBold}>
                    {unitScoreLabel.value}
                  </Text>
                </Text>
              </>
            )}
          </View>

          {shouldShowProgress && (
            <Text style={styles.scoreContainer}>
              <Text style={styles.scoreText}>
                {locale.sat.best_score}&nbsp;
                <Text style={styles.scoreTextMain}>
                  {history?.bestScore || 0}/{history?.totalScore || 0}
                </Text>
              </Text>
            </Text>
          )}
        </View>

        <View style={styles.rightContainer}>
          <TestPrepStartTestButton
            unit={unit}
            nextTestNumber={nextTestNumber}
            onUnitSelected={onUnitSelected}
            disabled={isSATFreeTrialEnded}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  container: {
    backgroundColor: Colors.white10,
    marginTop: Sizes.small,
    marginHorizontal: Sizes.semiMedium,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.white12,
    padding: Sizes.semiMedium,
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'row',
    borderRadius: 12,
    backgroundColor: 'transparent',
  },
  gradeContainerWrapper: {
    marginRight: Sizes.small,
  },

  textContainer: {
    flex: 1,
    marginRight: 10,
    rowGap: 1,
  },
  title: {
    ...Fonts.captionBold,
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white70,
  },
  subtitleBold: {
    ...Fonts.smallBold,
    color: Colors.white70,
  },
  subtitleBorder: {
    ...Fonts.small,
    color: Colors.white24,
  },
  subtitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.xsmall,
  },
  scoreContainer: {},
  scoreText: {
    ...Fonts.small,
    color: Colors.emeraldGreen,
  },
  scoreTextMain: {
    ...Fonts.smallBold,
    color: Colors.emeraldGreen,
  },

  progressIndicator: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
});
