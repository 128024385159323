import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetSATUnitTestHistoryRequest,
  GetSATUnitTestHistoryResponse,
} from '../../entities';

export const getSATUnitTestsHistoryGraphQLCall = async (
  request: GetSATUnitTestHistoryRequest,
): Promise<GetSATUnitTestHistoryResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetSATUnitTestsHistory($input: TeachTapGetUnitTestsHistoryInput!) {
          teachTapGetSATUnitTestsHistory(input: $input) {
            courseId
            units {
              id
              totalTestsTaken
              latestScore
              totalScore
              bestScore
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetSATUnitTestsHistory;
};
