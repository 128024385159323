import { useMemo } from 'react';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { ProgramAccessType, ProgramType } from '../../../Common/entities';
import { CompetitorLogoIcons } from '../../../Paywall/services/utils/PaywallUtils';
import {
  AP_PROGRAM_ENTITLEMENT_CONFIRMATION_FEATURES,
  SAT_BOOTCAMP_ENTITLEMENT_CONFIRMATION_FEATURES,
} from '../../data';
import { EntitlementConfirmationScreenData } from '../../entities';
import { mapProgramEntitlementStatus } from '../mappers';

export const useEntitlementConfirmationScreenData = ():
  | EntitlementConfirmationScreenData
  | undefined => {
  const { ap, sat } = useAppSelector(mapProgramEntitlementStatus);
  const isFromSignupFlow = useAppSelector(
    state => state.onboarding.doOnboardingFlowAfterSignup,
    (prev, next) => prev === next,
  );

  const result = useMemo(() => {
    if (sat) {
      return getBootcampEntitlementConfirmationScreenData(
        sat,
        isFromSignupFlow,
      );
    }
    if (ap) {
      return getAPProgramEntitlementConfirmationScreenData(isFromSignupFlow);
    }
    return undefined;
  }, [ap, sat, isFromSignupFlow]);

  return result;
};

export const getAPProgramEntitlementConfirmationScreenData = (
  isFromSignupFlow: boolean,
): EntitlementConfirmationScreenData | undefined => {
  return {
    programType: ProgramType.AP_PROGRAM,
    entitlementImages: [CompetitorLogoIcons.teachtap_icon],
    congrats: locale.onboarding.ap_entitlement_program_unlocked_heading,
    title: locale.onboarding.ap_entitlement_unlocked_access_text,
    description: locale.onboarding.ap_entitlement_description,
    featureList: AP_PROGRAM_ENTITLEMENT_CONFIRMATION_FEATURES,
    showLaterButton: false,
    isFromSignupFlow,
  };
};

export const getBootcampEntitlementConfirmationScreenData = (
  access: ProgramAccessType,
  isFromSignupFlow: boolean,
): EntitlementConfirmationScreenData | undefined => {
  const baseData = {
    entitlementImages: [CompetitorLogoIcons.thumbnail],
    featureList: SAT_BOOTCAMP_ENTITLEMENT_CONFIRMATION_FEATURES,
    showLaterButton: false,
    isFromSignupFlow,
    programType: ProgramType.BOOTCAMP,
  };

  if (access === ProgramAccessType.FULL_ACCESS) {
    return {
      ...baseData,
      congrats: locale.onboarding.entitlement_congrats_bootcamp_unlocked,
      title: locale.onboarding.entitlement_unlock_bootcamp,
      description: locale.onboarding.entitlement_bootcamp_description,
    };
  } else if (access === ProgramAccessType.TRIAL) {
    return {
      ...baseData,
      congrats: locale.onboarding.entitlement_congrats_free_trial_active,
      title: locale.onboarding.entitlement_unlock_free_trial,
      description: locale.onboarding.entitlement_free_trial_description,
    };
  }
  return undefined;
};
