import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useSafeAreaCustomInsets } from '../services/hooks';
import { Colors, Sizes } from '../services/utils/AppConstants';

type Props = {
  onPress: () => void;
  absoluteTopLeft?: boolean;
  containerStyle?: ViewStyle;
  iconSize?: number;
};

export const CommonBackButton = (props: Props): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();

  const containerStyle = useMemo(() => {
    return [
      styles.container,
      props.absoluteTopLeft && styles.absoluteContainer,
      props.absoluteTopLeft && { top: safeAreaTop + Sizes.small },
      props.containerStyle,
    ];
  }, [props.absoluteTopLeft, props.containerStyle, safeAreaTop]);

  return (
    <TouchableOpacity onPress={props.onPress} style={containerStyle}>
      <AssetImage
        asset={Assets.common.backArrowButtonIcon}
        height={props.iconSize || Sizes.medium}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Sizes.small,
    backgroundColor: Colors.white22,
    borderRadius: Sizes.small,
  },
  absoluteContainer: {
    position: 'absolute',
    left: Sizes.semiMedium,
  },
});
