import React from 'react';
import { StyleSheet, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { Course } from '../entities';
import { Colors, Sizes } from '../services/utils/AppConstants';
import { LinearGradient } from '../services/utils/polyfills';

const FEED_BACKGROUND_URL =
  'https://teachtok-images.s3.amazonaws.com/study-and-testprep-feed-background.png';
const BACKGROUND_IMAGE_HEIGHT = Sizes.normalizeIP14PM(500);

type Props = {
  course?: Course;
};

export const CommonFeedBackground = (props: Props): React.ReactElement => {
  return (
    <View style={styles.backgroundImageContainer}>
      <FastImage
        source={{
          uri: props.course?.background ?? FEED_BACKGROUND_URL,
        }}
        style={styles.backgroundImage}
      />

      <View style={styles.backgroundImageOverlay} />
      <LinearGradient
        style={styles.backgroundImageOverlayGradient}
        colors={Colors.screenGradientTransparentToDarkBlue}
        start={{ x: 0, y: 0.4 }}
        end={{ x: 0, y: 1 }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: BACKGROUND_IMAGE_HEIGHT,
    top: 0,
    zIndex: 10,
  },
  backgroundImageOverlay: {
    position: 'absolute',
    width: '100%',
    height: BACKGROUND_IMAGE_HEIGHT,
    top: 0,
    backgroundColor: Colors.black80,
    zIndex: 20,
  },
  backgroundImageOverlayGradient: {
    position: 'absolute',
    width: '100%',
    height: BACKGROUND_IMAGE_HEIGHT,
    top: 0,
    zIndex: 30,
  },
  backgroundImageContainer: {
    position: 'absolute',
    width: '100%',
    height: BACKGROUND_IMAGE_HEIGHT,
    top: 0,
    zIndex: 0,
  },
});
