import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { StudyNavigationProps } from '../../../App/navigation/bottomTabs/StudyStackNavigator';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import Avatar from '../../../Common/components/common/Avatar';
import { Course, Topic, Unit } from '../../../Common/entities';
import { useSafeAreaCustomInsets } from '../../../Common/services/hooks';
import { formatNumber } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  Sizes,
  StudyStack,
} from '../../../Common/services/utils/AppConstants';
import {
  LinearGradient,
  useNavigation,
} from '../../../Common/services/utils/polyfills';
import { setFirstTestCompleted } from '../../../TestPrep/services/slices';
import { useCourseLockStatusGetter } from '../../../UserAccess/services/hooks';
import { resetViewedQuestions } from '../../services/slices';

import TopicProgressHeader from './TopicProgressHeader';

type Props = {
  course?: Course;
  unit?: Unit;
  topic?: Topic;
  isForTopicCompletion?: boolean;
  isForTestCompletion?: boolean;
  isFromStudyLearn?: boolean;
  shouldShowStars?: boolean;
};

const PROFILE_IMAGE_SIZE = 40;

export default ({
  course,
  unit,
  topic,
  isForTopicCompletion,
  isForTestCompletion,
  isFromStudyLearn,
  shouldShowStars,
}: Props): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();
  const navigation =
    useNavigation<StudyNavigationProps<StudyStack.TOPIC_COMPLETION_SCREEN>>();

  const totalPoints = useAppSelector(
    state => state.profile.totalAwardedPoints,
    (prev, next) => prev === next,
  );

  const firstTestCompleted = useAppSelector(
    state => state.testPrep.firstTestCompleted,
    (prev, next) => prev === next,
  );

  const { isCourseLocked } = useCourseLockStatusGetter({
    courseId: course?.id,
  });

  const dispatch = useAppDispatch();

  const formattedTotalPoints = useMemo(
    () => formatNumber(totalPoints),
    [totalPoints],
  );

  const showTopicCompletionHeader = useMemo(
    () => isForTopicCompletion || false,
    [isForTopicCompletion],
  );

  const gradientColors = useMemo(() => {
    return showTopicCompletionHeader
      ? Colors.topicCompletionHeaderGradient
      : Colors.topAlphaGradientStrong;
  }, [showTopicCompletionHeader]);

  const headerContainerStyle = useMemo(
    () => [
      styles.headerContainer,
      {
        paddingTop: safeAreaTop,
        height: safeAreaTop + (!showTopicCompletionHeader ? 100 : 40),
      },
    ],
    [safeAreaTop, showTopicCompletionHeader],
  );

  const handleBackPress = useCallback(() => {
    if (isFromStudyLearn) {
      dispatch(resetViewedQuestions());
    }

    if (!firstTestCompleted && isCourseLocked && isForTestCompletion) {
      dispatch(setFirstTestCompleted());
    }
    if (isForTestCompletion) {
      navigation.popToTop();
    } else {
      navigation.goBack();
    }
  }, [
    dispatch,
    firstTestCompleted,
    isForTestCompletion,
    isFromStudyLearn,
    navigation,
    isCourseLocked,
  ]);

  return (
    <LinearGradient
      colors={gradientColors}
      start={{ x: 0.5, y: 0.4 }}
      end={{ x: 0.5, y: 1 }}
      style={headerContainerStyle}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <AssetImage asset={Assets.common.backArrowButtonIcon} height={24} />
          {showTopicCompletionHeader && (
            <Text style={styles.backToCourseText}>
              {locale.testPrep.back_to_course}
            </Text>
          )}
        </TouchableOpacity>

        {course && !showTopicCompletionHeader && (
          <>
            <TouchableOpacity onPress={handleBackPress}>
              <Avatar image={course.avatar} size={PROFILE_IMAGE_SIZE} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={handleBackPress}
              style={styles.courseTextContainer}>
              <Text style={styles.courseTitle} numberOfLines={1}>
                {course.name}
              </Text>

              {unit && (
                <Text style={styles.unitTitle} numberOfLines={1}>
                  {locale.courses.unit} {unit.number}・{unit.name}
                </Text>
              )}
            </TouchableOpacity>
          </>
        )}

        <View style={styles.expanded} />

        <View style={styles.ratingContainer}>
          <AssetImage asset={Assets.profile.coinPileIcon} height={20} />
          <Text style={styles.ratingText}>{formattedTotalPoints}</Text>
        </View>
      </View>

      {!isForTestCompletion && topic && (
        <TopicProgressHeader topic={topic} shouldShowStars={shouldShowStars} />
      )}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    alignItems: 'center',
  },
  backToCourseText: {
    ...Fonts.small,
  },
  backButton: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanded: {
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: Sizes.xsmall,
  },
  courseButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  courseTextContainer: {
    marginStart: 8,
    flexShrink: 1,
    marginEnd: 8,
  },
  courseTitle: {
    ...Fonts.caption,
  },
  unitTitle: {
    ...Fonts.caption,
  },
  ratingContainer: {
    flexDirection: 'row',
    paddingVertical: 0,
    paddingHorizontal: 6,
    backgroundColor: Colors.white30,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 16,
    height: 32,
    columnGap: 4,
  },
  ratingText: {
    ...Fonts.medium,
  },
});
