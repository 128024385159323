import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { AddOnType } from '../../../entities';
import {
  AP_PROGRAM_PAYWALL_SLICE_NAME,
  ApProgramPaywallSliceState,
} from '../ApProgramPaywallSlice';

export const toggleAPProgramSATAddonTrackAction = createAsyncThunk(
  `${AP_PROGRAM_PAYWALL_SLICE_NAME}/toggleAPProgramSATAddonTrackAction`,
  async (selected: boolean) => {
    trackAnalyticsEvent(Analytics.addOnToggled, {
      addOnType: AddOnType.SAT_PREP_COURSE_12_MONTHS,
      selected,
    });

    return selected;
  },
);

export const toggleAPProgramSATAddonTrackActionExtraReducers = (
  builder: ActionReducerMapBuilder<ApProgramPaywallSliceState>,
): void => {
  builder.addCase(
    toggleAPProgramSATAddonTrackAction.fulfilled,
    (state, action) => {
      state.isAddOnSelected = action.payload;
    },
  );
};
