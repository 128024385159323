import React, { useCallback, useMemo } from 'react';
import { StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { setSubtitlesShown } from '../../../Learn/services/slices';
import { CommonAssets } from '../../assets';
import { Sizes } from '../../services/utils/AppConstants';

type Props = {
  style?: ViewStyle;
  iconSize?: number;
};

export const VideoSubtitlesButtonToggle = (
  props: Props,
): React.ReactElement => {
  const dispatch = useAppDispatch();

  const isSubtitlesShown = useAppSelector(
    state => state.questions.subtitlesShown,
  );

  const selectedAsset = useMemo(() => {
    return isSubtitlesShown
      ? CommonAssets.CaptionsLarge
      : CommonAssets.CaptionsLargeOff;
  }, [isSubtitlesShown]);

  const managedStyles = useMemo(() => {
    return { container: [styles.container, props.style] };
  }, [props.style]);

  const selectedIconSize = useMemo(() => {
    return props.iconSize || Sizes.normalizeIP14PM(40);
  }, [props.iconSize]);

  const handlePress = useCallback(() => {
    dispatch(setSubtitlesShown(!isSubtitlesShown));
  }, [dispatch, isSubtitlesShown]);

  return (
    <TouchableOpacity style={managedStyles.container} onPress={handlePress}>
      <AssetImage
        asset={selectedAsset}
        height={selectedIconSize}
        imageStyle={styles.icon}
        fill="white"
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    shadowColor: 'black',
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
});
