import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  CourseEnrollmentCountOutput,
  EnrolledCoursesResponse,
} from '../../entities';

export const getUserEnrolledCoursesGraphQLCall = async (): Promise<
  EnrolledCoursesResponse[]
> => {
  const response = await graphqlApi.post(
    '',
    {
      query: `
        query TeachTapGetUserEnrolledCourses {
          teachTapGetUserEnrolledCourses {
            courseId
            enrolledOn
            goal
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data?.teachTapGetUserEnrolledCourses;
};

export const getCoursesEnrollmentCountGraphQLCall = async (): Promise<
  CourseEnrollmentCountOutput[]
> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {},
      query: `
        query TeachTapGetCoursesEnrollmentCount {
          teachTapGetCoursesEnrollmentCount {
            courseId
            enrollmentCount
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data?.teachTapGetCoursesEnrollmentCount;
};
