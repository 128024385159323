import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { renderLatexTextView } from '../../SAT/components';
import {
  SUPPORTED_SCREEN_HEIGHTS,
  TestPrepOption,
  TestPrepQuestion,
} from '../entities';

type Props = {
  currentQuestion: TestPrepQuestion;
  onAnswerSelect: (option: TestPrepOption) => void;
  shouldUseMarkdownLatex: boolean;
  isFromReview?: boolean;
};

export const TestPrepFlowAnswers = ({
  currentQuestion,
  onAnswerSelect,
  shouldUseMarkdownLatex,
}: Props): React.ReactElement => {
  const [maxAnswerContainerHeight, setMaxAnswerContainerHeight] = useState(0);
  const [answerContainerHeight, setAnswerContainerHeight] = useState(0);
  const [answerContainerWidth, setAnswerContainerWidth] = useState(0);
  const answerScrollViewRef = useRef<ScrollView>(null);

  const onAnswerLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { height } = event.nativeEvent.layout;
      setAnswerContainerHeight(
        height > maxAnswerContainerHeight
          ? maxAnswerContainerHeight + 25
          : height + 25,
      );
    },
    [maxAnswerContainerHeight],
  );

  const selectedOptionStyle = useCallback(
    (option: TestPrepOption) => {
      return [
        styles.optionButton,
        option.correct && styles.correctOption,
        currentQuestion.chosenOption?.id === option.id &&
          !option.correct &&
          styles.incorrectOption,
      ];
    },
    [currentQuestion.chosenOption],
  );

  const selectedOptionLabelStyle = useCallback((option: TestPrepOption) => {
    return [styles.optionLabel, option.correct && styles.correctOptionLabel];
  }, []);

  const selectedOptionLabelTextStyle = useCallback((option: TestPrepOption) => {
    return [
      styles.optionLabelText,
      option.correct && styles.correctOptionLabelText,
    ];
  }, []);

  const handleAnswerContainerLayout = useCallback(
    (event: LayoutChangeEvent) => {
      setAnswerContainerWidth(event.nativeEvent.layout.width);
    },
    [],
  );

  const webViewStyle = useMemo(() => {
    return { maxWidth: answerContainerWidth - 64 };
  }, [answerContainerWidth]);

  useEffect(() => {
    const screenHeight = Dimensions.get('window').height;
    const isIPhoneSE = SUPPORTED_SCREEN_HEIGHTS.includes(screenHeight);
    setMaxAnswerContainerHeight(screenHeight * (isIPhoneSE ? 0.35 : 0.5));
  }, []);

  useEffect(() => {
    if (currentQuestion?.id) {
      answerScrollViewRef.current?.scrollTo({ y: 0, animated: false });
    }
  }, [currentQuestion?.id]);

  return (
    <View style={[styles.answerContainer, { height: answerContainerHeight }]}>
      <ScrollView
        ref={answerScrollViewRef}
        style={styles.scrollView}
        contentContainerStyle={styles.answerScrollContainer}
        showsVerticalScrollIndicator={false}
        scrollEnabled
        bounces={false}>
        <View onLayout={onAnswerLayout} style={styles.answersContainer}>
          {currentQuestion.options.map(
            (option: TestPrepOption, index: number) => (
              <TouchableOpacity
                key={index}
                disabled={true}
                onLayout={handleAnswerContainerLayout}
                style={selectedOptionStyle(option)}
                onPress={() => onAnswerSelect(option)}>
                <View style={selectedOptionLabelStyle(option)}>
                  <Text style={selectedOptionLabelTextStyle(option)}>
                    {option.id}
                  </Text>
                </View>
                {shouldUseMarkdownLatex && answerContainerWidth ? (
                  renderLatexTextView({
                    identifier: currentQuestion.id,
                    content: option.answer,
                    fontSize: Fonts.semiMedium.fontSize,
                    tutorMessageTextColor: 'white',
                    webViewStyle: webViewStyle,
                  })
                ) : (
                  <Text
                    style={[
                      styles.optionText,
                      currentQuestion.chosenOption?.id === option.id &&
                        styles.selectedOptionText,
                    ]}>
                    {option.answer}
                  </Text>
                )}
              </TouchableOpacity>
            ),
          )}
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
  answersContainer: {
    gap: Sizes.semiMedium,
  },
  answerScrollContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  answerContainer: {
    flex: 1,
    paddingTop: Sizes.normalizeIP14PM(15),
    justifyContent: 'flex-end',
  },
  optionButton: {
    paddingHorizontal: 15,
    justifyContent: 'flex-start',
    backgroundColor: Colors.white10,
    borderRadius: 8,
    flexDirection: 'row',
    columnGap: 5,
    alignItems: 'center',
    paddingVertical: 8,
    minHeight: Sizes.normalizeIP14PM(44),
  },
  correctOption: {
    backgroundColor: Colors.emeraldGreen,
  },
  incorrectOption: {
    backgroundColor: Colors.lightRed,
  },
  optionText: {
    ...Fonts.normalize(Fonts.semiMedium),
    marginEnd: Sizes.normalizeIP14PM(15),
    paddingHorizontal: Sizes.normalizeIP14PM(5),
  },
  optionLabel: {
    backgroundColor: Colors.white10,
    width: Sizes.normalizeIP14PM(24),
    height: Sizes.normalizeIP14PM(24),
    borderRadius: Sizes.normalizeIP14PM(12),
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  correctOptionLabel: {
    backgroundColor: 'white',
  },
  optionLabelText: {
    ...Fonts.normalize(Fonts.semiMedium500),
    color: Colors.white70,
    textAlign: 'center',
  },
  correctOptionLabelText: {
    color: Colors.emeraldGreen,
  },
  selectedOptionText: {
    ...Fonts.normalize(Fonts.semiMedium600),
  },
});
