import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetDMChatHistoryResponse,
  RaiseHandChatHistoryRequest,
} from '../../entities';

export const getRaiseHandChatHistoryGraphQLCall = async (
  request: RaiseHandChatHistoryRequest,
): Promise<GetDMChatHistoryResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
      query GetDMChatHistory($input: GetDMChatHistoryInput!) {
        getDMChatHistory(input: $input) {
          hasNextPage
          chatHistory {
            message
            senderUserId
            platformGeneratedContentId
            timestamp
          }
        }
      }
      `,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );

  handleGraphQLError(response);
  return response.data?.data.getDMChatHistory;
};
