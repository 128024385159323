import React, { useMemo } from 'react';

import { useAppSelector } from '../../../App/services/hooks';
import { CommonTestimonialsCarousel } from '../../../Common/components';

export const SATTestimonialsCarousel = (): React.ReactElement => {
  const satTestimonials = useAppSelector(
    state => state.onboarding.SATConfigs?.testimonials,
  );

  const testimonials = useMemo(() => {
    return (
      satTestimonials?.map(testimonial => ({
        name: testimonial.user,
        quote: testimonial.quote,
      })) || []
    );
  }, [satTestimonials]);

  return <CommonTestimonialsCarousel testimonials={testimonials} />;
};
