import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { getUserAPProgramDetailsGraphQLCall } from '../../../../Common/graphql';
import { AP_SLICE_NAME, APSliceState } from '../APSlice';

export const getUserApProgramDetails = createAsyncThunk(
  `${AP_SLICE_NAME}/getUserApProgramDetails`,
  async (_, _thunkAPI) => {
    try {
      return await getUserAPProgramDetailsGraphQLCall();
    } catch (error) {
      if (error instanceof Error) {
        handleNetworkActionErrorSilently(error);
      }
      return undefined;
    }
  },
);

export const getUserApProgramDetailsExtraReducers = (
  builder: ActionReducerMapBuilder<APSliceState>,
): void => {
  builder.addCase(getUserApProgramDetails.fulfilled, (state, action) => {
    state.program = action.payload;
    state.tempCourseTargetGrade = {};
  });
};
