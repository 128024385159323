import {
  AVPlaybackStatus,
  AVPlaybackStatusSuccess,
  ResizeMode,
  Video,
} from 'expo-av';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { StyleSheet, View } from 'react-native';

import { Props } from '.';
import { useAppSelector } from '../../../../App/services/hooks';
import { emitMediaProgressEvent } from '../../../events';

const VIDEO_END_EPSILON = 0.3 * 1000;

export type BackgroundVideoRefType = {
  seekToRelativePosition: (position: number) => void;
};

export default forwardRef<BackgroundVideoRefType | undefined, Props>(
  (props, ref): React.ReactElement | null => {
    const videoRef = React.useRef<Video>(null);

    const [videoDuration, setVideoDuration] = React.useState(1);
    const [isVideoEnded, setIsVideoEnded] = React.useState(false);

    const videoRate = useAppSelector(state => state.questions.videoRate);

    useEffect(() => {
      if (!props.isVisible) {
        videoRef.current?.setPositionAsync(0);
      } else {
        if (videoRef.current?.getStatusAsync().then) {
          videoRef.current?.getStatusAsync().then(status => {
            if (status.isLoaded && !props.isPaused) {
              videoRef.current?.playAsync();
            }
          });
        }
      }
    }, [props.isPaused, props.isVisible]);

    useEffect(() => {
      if (props.isPaused) {
        videoRef.current?.pauseAsync();
      } else {
        videoRef.current?.playAsync();
      }
    }, [props.isPaused]);

    useEffect(() => {
      if (props.seekToTime) {
        videoRef.current?.setPositionAsync(props.seekToTime * 1000);
      }
    }, [props.seekToTime]);

    useEffect(() => {
      if (props.seekToRelativePosition) {
        videoRef.current?.setPositionAsync(
          props.seekToRelativePosition * videoDuration,
        );
      }
    }, [props.seekToRelativePosition, videoDuration]);

    const onProgress = (data: AVPlaybackStatus): void => {
      if (data.isLoaded) {
        const playbackStatus = data as AVPlaybackStatusSuccess;
        if (playbackStatus.durationMillis) {
          emitMediaProgressEvent({
            uniqueId: props.card.uniqueId,
            progress: playbackStatus.positionMillis / 1000,
            duration: playbackStatus.durationMillis / 1000,
          });

          if (
            playbackStatus.durationMillis - playbackStatus.positionMillis <=
              VIDEO_END_EPSILON &&
            !props.repeat
          ) {
            if (!isVideoEnded) {
              setIsVideoEnded(true);
              props.onVideoEnded && props.onVideoEnded();
            }
          } else {
            setIsVideoEnded(false);
          }

          setVideoDuration(playbackStatus.durationMillis);
        }
      }
    };

    useEffect(() => {
      videoRef.current?.setRateAsync(videoRate, true);
    }, [videoRate]);

    useImperativeHandle(
      ref,
      () => {
        return {
          seekToRelativePosition: (position: number) => {
            videoRef.current?.setPositionAsync(position * videoDuration);
          },
        };
      },
      [videoDuration],
    );

    if (props.isVisible) {
      return (
        <View style={[styles.box, props.style]}>
          <Video
            source={{ uri: props.videoUrl }}
            style={[props.videoStyle, styles.video]}
            videoStyle={[props.videoStyle, styles.video]}
            ref={videoRef}
            isMuted={props.isMuted}
            isLooping={props.repeat}
            shouldPlay
            resizeMode={ResizeMode.COVER}
            onPlaybackStatusUpdate={onProgress}
            onLoad={status => {
              if (status.isLoaded && !props.isPaused) {
                videoRef.current?.playAsync();
              }
            }}
          />
        </View>
      );
    } else {
      return null;
    }
  },
);

const styles = StyleSheet.create({
  box: {
    position: 'absolute',
  },
  video: {
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
});
