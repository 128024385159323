import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Unit } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import GradeIndicator from '../../Learn/components/study/GradeIndicator';
import { mapCourseUnitLockedStatus } from '../../UserAccess/services/mappers';
import { TestInfo, UnitTargetedPractice } from '../entities';

import { renderPracticeProgress } from './TestPracticeButton';
import { TestPrepListAttempts } from './TestPrepListAttempts';
import { TestPrepStartTestButton } from './TestPrepStartTestButton';

type Props = {
  courseId: string;
  unit: Unit;
  testInfo?: TestInfo;
  practice?: UnitTargetedPractice;
  onUnitSelected: (unit: Unit) => void;
  onPracticePressed: (unit: Unit) => void;
  firstIndex?: boolean;
  isSATCourse?: boolean;
};

export const TestPrepListView = ({
  unit,
  courseId,
  onUnitSelected,
  testInfo,
  practice,
  onPracticePressed,
  firstIndex,
  isSATCourse,
}: Props): React.ReactElement => {
  const isUnitLocked = useAppSelector(
    state => mapCourseUnitLockedStatus(state, courseId, unit.id),
    (prev, next) => prev === next,
  );

  const title = useMemo(() => unit.name, [unit.name]);
  const subtitle = useMemo(
    () => `${locale.courses.unit} ${unit.number}`,
    [unit.number],
  );
  const hasAttempted = useMemo(() => !!testInfo?.attempts?.length, [testInfo]);
  const shouldShowGradeIndicator = useMemo(
    () => isUnitLocked || hasAttempted,
    [hasAttempted, isUnitLocked],
  );

  const attemptsHistory = useAppSelector(
    state => state.testPrep.attemptsHistory[unit.id],
  );

  const nextTestNumber = useMemo(() => {
    return (attemptsHistory?.totalAttempts || 0) + 1;
  }, [attemptsHistory]);

  const mainContainerStyle = useMemo(() => {
    if (firstIndex) {
      return [styles.container, { marginTop: 0 }];
    }

    return styles.container;
  }, [firstIndex]);

  return (
    <TouchableOpacity
      style={mainContainerStyle}
      onPress={() => onUnitSelected(unit)}>
      <View style={styles.subContainer}>
        {shouldShowGradeIndicator && (
          <View style={styles.gradeContainerWrapper}>
            <GradeIndicator
              studentGrade={testInfo?.highestScore}
              viewStyle={styles.curvedProgressIndicator}
              unitLocked={isUnitLocked}
              showDash={!testInfo?.highestScore}
              dashWidth={14}
            />
          </View>
        )}

        <View style={styles.textContainer}>
          <Text style={styles.title}>{title}</Text>
          <View style={styles.subtitleContainer}>
            <Text style={styles.subtitle}>{subtitle}</Text>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <TestPrepListAttempts attempts={testInfo?.attempts} />

          <TestPrepStartTestButton
            unit={unit}
            nextTestNumber={nextTestNumber}
            onUnitSelected={onUnitSelected}
          />
        </View>
      </View>

      {renderPracticeProgress({
        unit,
        onPracticeTestPressed: onPracticePressed,
        practice,
        isSATCourse,
      })}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  },
  scores: {
    width: 15,
    height: 15,
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scoresContainer: {
    gap: 3,
    flexDirection: 'row',
  },
  subtitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 14,
  },
  container: {
    backgroundColor: Colors.white10,
    marginTop: Sizes.medium,
    marginHorizontal: Sizes.semiMedium,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.white12,
    padding: 12,
    gap: 8,
  },
  subContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 12,
    backgroundColor: 'transparent',
  },
  gradeContainerWrapper: {
    marginRight: Sizes.small,
  },
  textContainer: {
    flex: 1,
    marginRight: 10,
    rowGap: 1,
  },
  title: {
    ...Fonts.mediumBold,
  },
  score: {
    ...Fonts.small,
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white70,
  },

  selectedUnit: {
    backgroundColor: Colors.white12,
    paddingBottom: 10,
  },
  curvedProgressIndicator: {
    width: 36,
    height: 36,
    borderRadius: 18,
  },
});
