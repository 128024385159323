import React, { useMemo } from 'react';
import { SafeAreaView, StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { CommonActionButton } from '../../Common/components';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  children: React.ReactNode;
  heading: string;

  onNextPress: () => void;
  isNextDisabled: boolean;

  isBackButtonVisible?: boolean;
};

export const OnboardingExamInputContainer = (
  props: Props,
): React.ReactElement => {
  const managedStyles = useMemo(() => {
    return {
      contentContainer: [
        styles.contentContainer,
        props.isBackButtonVisible && { paddingTop: Sizes.small },
      ],
    };
  }, [props.isBackButtonVisible]);

  return (
    <View style={styles.container}>
      <SafeAreaView>
        <View style={managedStyles.contentContainer}>
          <Text style={styles.headingText}>{props.heading}</Text>

          {props.children}

          <CommonActionButton
            disabled={props.isNextDisabled}
            onPress={props.onNextPress}
            text={locale.common.next}
          />
        </View>
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.prussianBlue,
  },

  contentContainer: {
    justifyContent: 'space-between',
    paddingTop: 60,
    paddingBottom: Sizes.semiMedium,
    paddingHorizontal: Sizes.medium,
    height: '100%',
  },

  backButtonContainer: {
    position: 'absolute',
    top: Sizes.medium,
    left: Sizes.medium,
  },

  headingText: {
    ...Fonts.xxlargeBold,
    color: 'white',
    textAlign: 'center',
  },

  closeButtonContainer: {
    right: Sizes.medium,
    top: 0,
  },
});
