import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { getUserProfile } from '../../../../Auth/services/slices';
import {
  showErrorMessage,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { DeleteProfileImageParams } from '../../../entities';
import { deleteUserProfileImageGraphQLCall } from '../../../graphql';
import { mapProfileDetailsUpdateAnalytics } from '../../mappers';
import { PROFILE_SLICE_NAME, ProfileSliceState } from '../ProfileSlice';

export const deleteProfileImage = createAsyncThunk(
  `${PROFILE_SLICE_NAME}/deleteProfileImage`,
  async (params: DeleteProfileImageParams, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      if (!state.auth?.authUser?.profileImageURL) {
        return true;
      }

      const result = await deleteUserProfileImageGraphQLCall();
      if (!result.success) {
        showErrorMessage({
          message: result.message || locale.errors.something_went_wrong,
        });
        return false;
      }

      trackAnalyticsEvent(
        Analytics.profileDetailsUpdated,
        mapProfileDetailsUpdateAnalytics({
          from: params.from,
          profileImageUpdated: true,
        }),
      );

      await thunkApi
        .dispatch(getUserProfile({ silent: true, skipAfterAuth: true }))
        .unwrap();

      return true;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }

      return false;
    }
  },
);

export const deleteProfileImageExtraReducers = (
  builder: ActionReducerMapBuilder<ProfileSliceState>,
): void => {
  builder.addCase(deleteProfileImage.fulfilled, (state: ProfileSliceState) => {
    state.isProfileImageLoading = false;
  });

  builder.addCase(deleteProfileImage.pending, (state: ProfileSliceState) => {
    state.isProfileImageLoading = true;
  });

  builder.addCase(deleteProfileImage.rejected, (state: ProfileSliceState) => {
    state.isProfileImageLoading = false;
  });
};
