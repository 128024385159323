import React from 'react';
import { View } from 'react-native';

import { Colors } from '../../../services/utils/AppConstants';

import {
  BLINK_BUTTON_TAG,
  CORRECT_TEXT_TAG,
  PENDING_TEXT_TAG,
  Props,
  WRONG_TEXT_TAG,
} from './index';

export default (props: Props): React.ReactElement => {
  const renderText = () => {
    const metaHtml = props.text.split('<');

    return (
      <div
        style={{
          ...styles.text,
          backgroundColor: props.color,
          color: props.textStyle.color,
          fontSize: props.textStyle.fontSize,
          fontFamily: props.textStyle.fontFamily,
        }}>
        {metaHtml.map((item, index) => {
          const tag = item.split('>')[0];
          const text = item.split('>')[1];

          switch (tag) {
            case CORRECT_TEXT_TAG:
              return (
                <span
                  key={index}
                  style={{
                    ...styles.correctText,
                    fontSize: props.textStyle.fontSize,
                    fontFamily: props.textStyle.fontFamily,
                  }}>
                  {text}
                </span>
              );
            case WRONG_TEXT_TAG:
              return (
                <span
                  key={index}
                  style={{
                    ...styles.wrongText,
                    fontSize: props.textStyle.fontSize,
                    fontFamily: props.textStyle.fontFamily,
                  }}>
                  {text}
                </span>
              );
            case PENDING_TEXT_TAG:
              return (
                <span
                  key={index}
                  style={{
                    ...styles.pendingText,
                    fontSize: props.textStyle.fontSize,
                    fontFamily: props.textStyle.fontFamily,
                  }}>
                  {text}
                </span>
              );
            case BLINK_BUTTON_TAG:
              return (
                <span
                  key={index}
                  style={{
                    ...styles.blinkingButton,
                    fontSize: props.textStyle.fontSize,
                    fontFamily: props.textStyle.fontFamily,
                  }}
                  onClick={props.onButtonPress}>
                  {text}
                </span>
              );
            default:
              if (tag.charAt(0) === '/') {
                return text;
              }
              return tag;
          }
        })}
      </div>
    );
  };

  return (
    <View
      style={[
        styles.container,
        props.style,
        {
          width: props.width,
        },
      ]}>
      <div
        style={{
          ...styles.textContainer,
        }}>
        {renderText()}
        <svg style={styles.svgFilter}>
          <defs>
            <filter id="instagram">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="5"
                result="blur"
              />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -8"
                result="goo"
              />
              <feComposite in="SourceGraphic" in2="goo" operator="atop" />
            </filter>
          </defs>
        </svg>
      </div>
    </View>
  );
};

const styles: any = {
  container: {
    paddingTop: 4,
    paddingBottom: 16,
    paddingRight: 16,
  },
  svgFilter: {
    visibility: 'hidden',
    position: 'absolute',
    width: 0,
    height: 0,
  },
  text: {
    display: 'inline',
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 6,
    paddingRight: 6,
    fontWeight: 500,
    lineHeight: 1.4,
    // dont allow selection
    userSelect: 'none',
    borderRadius: 4,
    boxDecorationBreak: 'clone',
    WebkitBoxDecorationBreak: 'clone',
    filter: 'url(#instagram)',
  },
  blinkingButton: {
    fontWeight: 500,
    // show cursor when mouse over
    cursor: 'pointer',
    lineHeight: 1 /* adjust this to avoid overlapping the padding */,
    borderRadius: 14,
    paddingLeft: 6,
    paddingRight: 6,
    //animation: 'blinker 2s linear infinite';
    color: '#BBBBBB',
    backgroundColor: '#BBBBBB',
    border: '0',
    animation: 'blinker 2s linear infinite',
  },
  correctText: {
    fontWeight: 500,
    lineHeight: 1 /* adjust this to avoid overlapping the padding */,
    borderRadius: 14,
    paddingLeft: 6,
    paddingRight: 6,
    color: 'white',
    backgroundColor: Colors.answerRight,
    border: '0',
  },
  wrongText: {
    fontWeight: 500,
    lineHeight: 1 /* adjust this to avoid overlapping the padding */,
    borderRadius: 14,
    paddingLeft: 6,
    paddingRight: 6,
    color: 'white',
    backgroundColor: Colors.answerWrong,
    border: '0',
  },
  pendingText: {
    fontWeight: 500,
    lineHeight: 1 /* adjust this to avoid overlapping the padding */,
    borderRadius: 14,
    paddingLeft: 6,
    paddingRight: 6,
    color: 'white',
    backgroundColor: 'grey',
    border: '0',
  },
  textContainer: {},
};
