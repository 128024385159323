import moment from 'moment';

import { RootState } from '../../../App/store';
import { CommonExamType, ProgramAccessType } from '../../../Common/entities';
import { MainStack } from '../../../Common/services/utils/AppConstants';
import {
  BootcampEntryPoint,
  ExamDateItem,
  SATLastScoreType,
} from '../../entities';

import { mapAPProgramEntitlementStatus } from './ApOnboardingStateMapper';

export const getFutureExamDatesWithLimit = (
  examDates?: string[],
  limit?: number,
): ExamDateItem[] => {
  if (!examDates?.length) {
    return [];
  }

  const momentExamDates = examDates.map(date => {
    return moment(date, 'YYYY-MM-DD');
  });

  const futureExamDates = momentExamDates.filter(date => {
    return date.isAfter(moment());
  });

  if (!futureExamDates.length) {
    return [];
  }

  const sortedExamDates = futureExamDates.sort((a, b) => {
    return a.diff(b);
  });

  if (!limit) {
    return sortedExamDates.map(date => {
      return {
        date: date.toDate(),
        dateString: date.format('YYYY-MM-DD'),
        label: date.format('MMMM D, YYYY'),
      };
    });
  }

  return sortedExamDates.slice(0, limit).map(date => {
    return {
      date: date.toDate(),
      dateString: date.format('YYYY-MM-DD'),
      label: date.format('MMMM D, YYYY'),
    };
  });
};

export const mapSATLastScores = (minimumScore: number): SATLastScoreType[] => {
  const lastScores: SATLastScoreType[] = [];
  for (let i = minimumScore; i <= 1600; i += 10) {
    lastScores.push({ label: `${i}`, value: i });
  }

  if (minimumScore === 0) {
    return lastScores;
  }

  const earliestScoreValue = Math.max(minimumScore - 100, 0);
  lastScores.unshift({
    label: `Less than ${minimumScore}`,
    value: earliestScoreValue,
  });

  return lastScores;
};

export const mapSATTargetScores = (
  minimumScore: number,
  lastScore?: number,
): number[] => {
  const targetScores: number[] = [];
  for (let i = minimumScore; i <= 1600; i += 10) {
    if (i === 1600 || !lastScore || i > lastScore) {
      targetScores.push(i);
    }
  }

  return targetScores;
};

export const mapOnboardingRedirectionTarget = (
  state: RootState,
): CommonExamType | undefined => {
  const isLoggedIn = Boolean(state.auth.authUser?.id);
  if (!isLoggedIn) {
    return undefined;
  }

  const signUpExamType = state.onboarding.signUpExamType;
  const examType = state.onboarding.examType;
  const doOnboardingFlowAfterSignup =
    state.onboarding.doOnboardingFlowAfterSignup;

  if ((examType || signUpExamType) && doOnboardingFlowAfterSignup) {
    return examType ?? signUpExamType;
  }

  if (state.onboarding.shouldOpenSatBootcampFlowForUpsell) {
    return CommonExamType.SAT;
  }

  return undefined;
};

export const mapIsUserEligibleForSatBootcampDiscount = (
  state: RootState,
): boolean => {
  const isLoggedIn = Boolean(state.auth.authUser?.id);
  if (!isLoggedIn) {
    return false;
  }

  return state.onboarding.shouldUseSatBootcampDiscount;
};

export const mapApFlowRedirectionTarget = (
  state: RootState,
): MainStack | undefined => {
  const isLoggedIn = Boolean(state.auth.authUser?.id);
  const proceedFlow = state.onboarding.doOnboardingFlowAfterSignup;

  if (!isLoggedIn || !proceedFlow) {
    return undefined;
  }

  const showTutorialScreen = state.tutorial.showTutorialScreen;
  if (showTutorialScreen) {
    return MainStack.TUTORIAL_SCREEN;
  }

  const courseListOnboarding = state.courseEnrollments.showCourseListOnboarding;
  if (courseListOnboarding) {
    return MainStack.COURSE_LIST_MAIN_SCREEN;
  }

  return undefined;
};

export const mapSatBootcampEntitlementStatus = (
  state: RootState,
): ProgramAccessType | undefined => {
  const isLoggedIn = Boolean(state.auth.authUser?.id);
  const bootcampData = state.SAT.bootcamp;

  if (!isLoggedIn || !bootcampData?.accessDetails?.accessType) {
    return undefined;
  }

  if (
    bootcampData?.details?.examDate ||
    !bootcampData?.accessDetails?.expiresAt
  ) {
    return undefined;
  }

  const currentDate = new Date();
  const expiryDate = new Date(bootcampData.accessDetails.expiresAt);

  if (
    currentDate > expiryDate ||
    bootcampData.accessDetails.accessType === ProgramAccessType.EXPIRED
  ) {
    return undefined;
  }

  return bootcampData.accessDetails.accessType;
};

export const mapIsBootcampFromCoursePickerStatus = (
  entryPoint?: BootcampEntryPoint,
): boolean => {
  return (
    entryPoint === BootcampEntryPoint.COURSE_PICKER ||
    entryPoint === BootcampEntryPoint.FOLLOW_COURSE_HOME ||
    entryPoint === BootcampEntryPoint.FOLLOW_COURSE_PROFILE ||
    entryPoint === BootcampEntryPoint.LOCKED_AP_COURSE
  );
};

export const mapProgramEntitlementStatus = (
  state: RootState,
): {
  ap: ProgramAccessType | undefined;
  sat: ProgramAccessType | undefined;
} => {
  return {
    ap: mapAPProgramEntitlementStatus(state),
    sat: mapSatBootcampEntitlementStatus(state),
  };
};
