import Container from './container';

export type Props = {
  imageUrl: string;
  urlSuffix: string | null;
  isVisible: boolean;
  isPaused: boolean;
  style: any;
};

export default Container;
