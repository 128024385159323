import React, { useCallback, useEffect, useMemo } from 'react';
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Animated, { FadeInDown } from 'react-native-reanimated';

import locale from '../../App/locale';
import { MainNavigationProp } from '../../App/navigation/main/MainStackNavigator';
import { useAppSelector } from '../../App/services/hooks';
import store, { useAppDispatch } from '../../App/store';
import { redirectToAppstore } from '../../Common/services/utils';
import {
  Colors,
  Durations,
  Fonts,
  isWebPlatform,
  ScreenNames,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import {
  LinearGradient,
  useNavigation,
} from '../../Common/services/utils/polyfills';
import { DMSpeaker, MessageScreenEntryPoints } from '../../Messages/entities';
import {
  getDMFiguresIfEmpty,
  getSpeakerByUsername,
  setNewDmSpeaker,
} from '../../Messages/services/slices';
import {
  AiFigureLoadingItem,
  AiFigureProfileFamousQuoteList,
  AiFigureProfileHeader,
  AiFigureProfileInterestList,
  AiFigureProfileNotableContributionList,
  AiFigureProfileSubjectList,
} from '../components';
import { AiFigureModel } from '../entities';
import { getFigureData } from '../services/slices';

export type AiFigureProfileScreenProps = {
  name: string;
};

type ScreenProps = {
  route?: { params: AiFigureProfileScreenProps };
};

export const AiFigureProfile = (props: ScreenProps): React.ReactElement => {
  const name = props?.route?.params?.name || '';
  const dispatch = useAppDispatch();
  const navigation = useNavigation<MainNavigationProp<any>>();

  const isFigureLoading = useAppSelector(
    state => state.aiFigure.isFigureDataLoading,
  );

  const selectedFigure: AiFigureModel | undefined = useAppSelector(
    state => state.aiFigure.figure[name],
  );

  const isAskDmLoading = useAppSelector(
    state => !state.messages.hasFetchTop50Figures,
  );

  const selectedDMFigure: DMSpeaker | undefined = useAppSelector(state => {
    if (!selectedFigure?.handle) {
      return undefined;
    }

    return getSpeakerByUsername(state.messages, selectedFigure?.handle || '');
  });

  const isLoading = useMemo(() => {
    return isFigureLoading && !selectedFigure;
  }, [isFigureLoading, selectedFigure]);

  const isFigureNotFound = useMemo(() => {
    return !isLoading && !selectedFigure;
  }, [isLoading, selectedFigure]);

  const containerStyles = useMemo(() => {
    if (!isWebPlatform) {
      return styles.container;
    }

    return [styles.container, styles.webContainer];
  }, []);

  const parentStyles = useMemo(() => {
    if (!isWebPlatform) {
      return styles.gradientContainer;
    }

    return [styles.gradientContainer, styles.webWrapper];
  }, []);

  const shouldShowAskQuestionButton = useMemo(() => {
    if (isWebPlatform || isLoading || isAskDmLoading) {
      return false;
    }

    const hasPlatformIdAndHandle =
      !!selectedFigure?.platformId && !!selectedFigure?.handle;

    return !!selectedDMFigure || hasPlatformIdAndHandle;
  }, [isLoading, selectedDMFigure, selectedFigure, isAskDmLoading]);

  const handleAskQuestionPress = useCallback(() => {
    if (!shouldShowAskQuestionButton) {
      return;
    }

    if (selectedDMFigure) {
      navigation.navigate(ScreenNames.MainStack.MESSAGE_MAIN, {
        screen: ScreenNames.MessageStack.MESSAGE_SCREEN,
        params: {
          speaker: selectedDMFigure,
          entryPoint: MessageScreenEntryPoints.DM_THE_TEACHER,
        },
      });

      return;
    }

    if (selectedFigure?.platformId && selectedFigure?.handle) {
      const dmFigure: DMSpeaker = {
        name: selectedFigure.name,
        username: selectedFigure.handle,
        avatarUrl: selectedFigure.avatarUrl,
        title: selectedFigure.bio || '',
        platformId: selectedFigure.platformId,
        biography: selectedFigure.socialBio || '',
      };

      dispatch(setNewDmSpeaker(dmFigure));

      const speaker = getSpeakerByUsername(
        store.getState().messages,
        selectedFigure.handle,
      );

      if (speaker) {
        navigation.navigate(ScreenNames.MainStack.MESSAGE_MAIN, {
          screen: ScreenNames.MessageStack.MESSAGE_SCREEN,
          params: {
            speaker,
            entryPoint: MessageScreenEntryPoints.DM_THE_TEACHER,
          },
        });
      }
    }
  }, [
    selectedDMFigure,
    selectedFigure,
    shouldShowAskQuestionButton,
    dispatch,
    navigation,
  ]);

  const handleOpenTeachTap = useCallback(() => {
    redirectToAppstore();
  }, []);

  useEffect(() => {
    if (name) {
      dispatch(getFigureData({ name }));
    }
  }, [dispatch, name]);

  useEffect(() => {
    if (!isWebPlatform) {
      dispatch(getDMFiguresIfEmpty());
    }
  }, [dispatch]);

  if (isFigureNotFound) {
    return (
      <LinearGradient style={parentStyles} colors={Colors.screenGradient}>
        {isWebPlatform && (
          <TouchableOpacity
            activeOpacity={1}
            onPress={handleOpenTeachTap}
            style={styles.webButtonContainer}
          />
        )}

        <SafeAreaView style={containerStyles}>
          <View style={styles.mainEmptyFigureContainer}>
            <Text style={styles.bio}>{locale.ai_profile.not_found}</Text>
          </View>
        </SafeAreaView>
      </LinearGradient>
    );
  }

  return (
    <LinearGradient style={parentStyles} colors={Colors.screenGradient}>
      {isWebPlatform && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={handleOpenTeachTap}
          style={styles.webButtonContainer}
        />
      )}

      <SafeAreaView style={containerStyles}>
        <ScrollView bounces={false}>
          <View style={styles.mainContentContainer}>
            <AiFigureProfileHeader
              isLoading={isLoading}
              selectedFigure={selectedFigure}
            />

            {isLoading ? (
              <View style={styles.bio}>
                <AiFigureLoadingItem itemWidth="100%" itemHeight={92} />
              </View>
            ) : (
              <Text style={styles.bio}>{selectedFigure.biography}</Text>
            )}

            {shouldShowAskQuestionButton && (
              <Animated.View
                entering={FadeInDown.duration(Durations.quickTransition)}>
                <TouchableOpacity
                  style={styles.actionButton}
                  onPress={handleAskQuestionPress}>
                  <Text style={styles.actionButtonText}>
                    {locale.ai_profile.ask_me_question}
                  </Text>
                </TouchableOpacity>
              </Animated.View>
            )}
          </View>

          <AiFigureProfileSubjectList
            isLoading={isLoading}
            data={selectedFigure}
          />
          <AiFigureProfileInterestList
            isLoading={isLoading}
            data={selectedFigure}
          />
          <AiFigureProfileNotableContributionList
            isLoading={isLoading}
            data={selectedFigure}
          />
          <AiFigureProfileFamousQuoteList
            isLoading={isLoading}
            data={selectedFigure}
          />
        </ScrollView>
      </SafeAreaView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  webWrapper: {
    flex: 1,
    width: Sizes.getAppWindowWidth(),
  },
  gradientContainer: {
    flex: 1,
  },
  webContainer: {
    marginTop: Sizes.large,
  },
  container: {
    flex: 1,
    marginVertical: Sizes.semiMedium,
    zIndex: 10,
  },
  webButtonContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 20,
  },

  mainEmptyFigureContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContentContainer: {
    marginHorizontal: Sizes.semiMedium,
  },

  bio: {
    ...Fonts.small,
    marginTop: 16,
    color: Colors.white60,
  },

  actionButton: {
    width: '100%',
    padding: Sizes.semiMedium,
    marginTop: Sizes.large,
    borderRadius: Sizes.small,
    backgroundColor: Colors.actionGreen,
  },
  actionButtonText: {
    ...Fonts.medium,
    textAlign: 'center',
    color: Colors.grayscale[0],
  },
});
