import locale from '../../App/locale';
import { Course } from '../../Common/entities';
import {
  isAPCourse,
  isHighSchoolCourse,
  isSATCourse,
} from '../../Common/services/utils';
import { EnrolledCourse, GetSortedCoursesSection } from '../entities';

export class CourseListAlgorithm {
  static getApCourses = (courses: Course[]): Course[] => {
    return courses.filter(isAPCourse);
  };

  static getSATCourses = (courses: Course[]): Course[] => {
    return courses.filter(isSATCourse);
  };

  static getHighSchoolCourses = (courses: Course[]): Course[] => {
    return courses.filter(isHighSchoolCourse);
  };

  static isCourseIncludedInEntryPoint = (
    isFromTestPrep: boolean,
    course?: Course,
  ): boolean => {
    if (isFromTestPrep) {
      return course?.hasTestPrep ?? false;
    }

    return course?.hasLearnMode ?? false;
  };

  static sortFollowedCourses = (
    followedCourses: EnrolledCourse[],
  ): EnrolledCourse[] => {
    if (!followedCourses?.length) {
      return [];
    }

    return followedCourses.sort((a, b) => {
      if (a.enrolledOn && b.enrolledOn) {
        return (
          new Date(b.enrolledOn).getTime() - new Date(a.enrolledOn).getTime()
        );
      }

      return 0;
    });
  };

  static getSortedCoursesSection = (
    courses: Course[],
    followedCourses: Course[],
    selectedHeader: string | null,
    isCourseDataReady: boolean,
  ): GetSortedCoursesSection[] => {
    const result: GetSortedCoursesSection[] = [];

    const satCourses = CourseListAlgorithm.getSATCourses(courses).sort((a, b) =>
      a?.name.localeCompare(b?.name),
    );

    const apCourses = CourseListAlgorithm.getApCourses(courses).sort((a, b) =>
      a?.name.localeCompare(b?.name),
    );
    const highSchoolCourses = CourseListAlgorithm.getHighSchoolCourses(
      courses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));

    if (selectedHeader) {
      result.push({
        title: selectedHeader,
        data: [],
      });
    }

    // Still loading, just show the data we have
    if (!isCourseDataReady) {
      if (apCourses.length) {
        result.push({ title: locale.courses.ap_courses, data: apCourses });
      }

      if (highSchoolCourses.length) {
        result.push({
          title: locale.courses.high_school_courses,
          data: highSchoolCourses,
        });
      }

      return result;
    }

    const followedSatCourses = CourseListAlgorithm.getSATCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));

    const notFollowedSatCourses = satCourses
      .filter(course => !followedSatCourses.includes(course))
      .sort((a, b) => a?.name.localeCompare(b?.name));

    const followedApCourses = CourseListAlgorithm.getApCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));

    const notFollowedApCourses = apCourses
      .filter(course => !followedApCourses.includes(course))
      .sort((a, b) => a?.name.localeCompare(b?.name));

    const followedHighSchoolCourses = CourseListAlgorithm.getHighSchoolCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));

    const notFollowedHighSchoolCourses = highSchoolCourses
      .filter(course => !followedHighSchoolCourses.includes(course))
      .sort((a, b) => a?.name.localeCompare(b?.name));

    if (satCourses.length) {
      result.push({
        title: locale.courses.sat_course,
        data: [...followedSatCourses, ...notFollowedSatCourses],
      });
    }

    if (apCourses.length) {
      result.push({
        title: locale.courses.ap_courses,
        data: [...followedApCourses, ...notFollowedApCourses],
      });
    }

    if (highSchoolCourses.length) {
      result.push({
        title: locale.courses.high_school_courses,
        data: [...followedHighSchoolCourses, ...notFollowedHighSchoolCourses],
      });
    }

    return result;
  };

  static getSortedAllCoursesSection = (
    courses: Course[],
    followedCourses: Course[],
    isFromTestPrep: boolean,
  ): GetSortedCoursesSection[] => {
    const result: GetSortedCoursesSection[] = [];

    const notFollowedCourses = courses.filter(
      course => !followedCourses.includes(course),
    );

    const satCourses = CourseListAlgorithm.getSATCourses(courses);
    const apCourses = CourseListAlgorithm.getApCourses(courses);
    const highSchoolCourses = CourseListAlgorithm.getHighSchoolCourses(courses);

    const followedSatCourses =
      CourseListAlgorithm.getSATCourses(followedCourses);

    const notFollowedSatCourses = satCourses
      .filter(course => !followedSatCourses.includes(course))
      .sort((a, b) => a?.name.localeCompare(b?.name));

    const followedApCourses = CourseListAlgorithm.getApCourses(
      followedCourses,
    ).filter(course =>
      CourseListAlgorithm.isCourseIncludedInEntryPoint(isFromTestPrep, course),
    );

    const notFollowedApCourses = apCourses
      .filter(
        course =>
          !followedApCourses.includes(course) &&
          CourseListAlgorithm.isCourseIncludedInEntryPoint(
            isFromTestPrep,
            course,
          ),
      )
      .sort((a, b) => a?.name.localeCompare(b?.name));

    const followedHighSchoolCourses = CourseListAlgorithm.getHighSchoolCourses(
      followedCourses,
    ).filter(course =>
      CourseListAlgorithm.isCourseIncludedInEntryPoint(isFromTestPrep, course),
    );

    const notFollowedHighSchoolCourses = highSchoolCourses
      .filter(
        course =>
          !followedHighSchoolCourses.includes(course) &&
          CourseListAlgorithm.isCourseIncludedInEntryPoint(
            isFromTestPrep,
            course,
          ),
      )
      .sort((a, b) => a?.name.localeCompare(b?.name));

    if (followedCourses.length) {
      result.push({
        title: locale.study.followed_courses,
        data: [
          ...followedSatCourses,
          ...followedApCourses,
          ...followedHighSchoolCourses,
        ],
      });
    }

    if (notFollowedCourses.length) {
      result.push({
        title: locale.courses.other_courses,
        data: [
          ...notFollowedSatCourses,
          ...notFollowedApCourses,
          ...notFollowedHighSchoolCourses,
        ],
      });
    }

    return result;
  };

  static getSortedFollowedCourses = (followedCourses: Course[]): Course[] => {
    if (!followedCourses?.length) {
      return [];
    }

    const followedSatCourses = CourseListAlgorithm.getSATCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));
    const followedApCourses = CourseListAlgorithm.getApCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));
    const followedHighSchoolCourses = CourseListAlgorithm.getHighSchoolCourses(
      followedCourses,
    ).sort((a, b) => a?.name.localeCompare(b?.name));

    return [
      ...followedSatCourses,
      ...followedApCourses,
      ...followedHighSchoolCourses,
    ];
  };
}
