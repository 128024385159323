import { RootState } from '../../../App/store';
import { ProgramAccessType } from '../../../Common/entities';

export const mapAPProgramEntitlementStatus = (
  state: RootState,
): ProgramAccessType | undefined => {
  const isLoggedIn = Boolean(state.auth.authUser?.id);
  const programData = state.AP.program;

  if (!isLoggedIn || !programData?.accessDetails?.accessType) {
    return undefined;
  }

  if (
    (programData.details?.courses || []).length > 0 ||
    !programData?.accessDetails?.expiresAt
  ) {
    return undefined;
  }

  const currentDate = new Date();
  const expiryDate = new Date(programData.accessDetails.expiresAt);

  if (
    currentDate > expiryDate ||
    programData.accessDetails.accessType === ProgramAccessType.EXPIRED
  ) {
    return undefined;
  }

  return programData.accessDetails.accessType;
};
