export const messageExcuses: string[] = [
  "Greetings! I'm delving into your query right now. Please give me a few minutes to gather the information and I'll respond with a video shortly.",
  "Hi there! I need a little bit of time to ponder your question. Hang tight, and I'll have a video answer ready for you in just a few minutes.",
  "Hello! Your question has sparked my curiosity. Allow me a few moments to consider it and I'll get back to you with a video response shortly.",
  "Good day! I'm currently investigating your inquiry. Please bear with me for a few minutes while I prepare a detailed video reply.",
  "Hey! I'm excited to answer your question. Just need a couple of minutes to think it through and I'll return with a video response.",
  "Ah, an interesting query! I'm on it. Give me a few minutes to compile a thoughtful video answer for you.",
  "Salutations! I'm taking a moment to thoroughly explore your question. I'll be back with a video response in a short while.",
  "Hello! Your question deserves a detailed answer. I'm working on it and will present you with a video in just a few minutes.",
  "Hi! I'm currently processing your intriguing question. Please hold on for a few minutes as I prepare a video explanation.",
  "Greetings! Your question has captured my attention. Allow me a brief interlude to reflect and I'll return with a video answer.",
];

export const apologyExcuses: string[] = [
  "Oops, it seems I'm a bit tied up at the moment! Sorry for the delay. Why not try asking your question again now or later? I'll be all ears soon!",
  "Looks like I've hit a snag in the time-space continuum! I can't answer right now, but please don't hesitate to try again shortly or ask a different question. Ready for round two soon!",
  "Seems I'm encountering a technical time-out! Apologies for not responding right now. Feel free to rephrase your question or ask a new one. I'll be back with answers shortly!",
  "Ah, the quirks of technology! I'm temporarily out of service. Please try your question again or explore a new one. Eager to chat with you soon!",
  "A little glitch on my end, it appears! Can't respond at this moment. I encourage you to ask again or try a different question. Looking forward to our next interaction!",
];
