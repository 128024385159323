import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

export type CommonSelectAddonToggleProps = {
  isSelected: boolean;
  onPress: () => void;
  title: string;
  subtitle: string;
  offer: string;
  offerSubtitle: string;
  regularPrice: string;
};

export const CommonSelectAddonToggle = ({
  isSelected = true,
  onPress,
  title,
  subtitle,
  offer,
  offerSubtitle,
  regularPrice,
}: CommonSelectAddonToggleProps): React.ReactElement => {
  const toggleContainerStyle = useMemo(() => {
    return {
      ...styles.container,
      backgroundColor: isSelected ? Colors.actionGreen30 : Colors.white10,
    };
  }, [isSelected]);

  const toggleStyle = useMemo(() => {
    return {
      ...styles.toggle,
      backgroundColor: isSelected ? Colors.actionGreen : Colors.white30,
    };
  }, [isSelected]);

  const priceContent = useMemo(() => {
    return regularPrice.length > 4 ? (
      <>
        <Text style={styles.regularPriceStrikethrough}>{regularPrice}</Text>
        {'\n'}
        <Text style={styles.discountPriceBold}>{offer}</Text>
      </>
    ) : (
      <>
        <Text style={styles.regularPriceStrikethrough}>{regularPrice}</Text>
        &nbsp;
        <Text style={styles.discountPriceBold}>{offer}</Text>
      </>
    );
  }, [regularPrice, offer]);

  return (
    <TouchableOpacity style={toggleContainerStyle} onPress={onPress}>
      <View style={styles.toggleContainer}>
        <View style={toggleStyle}>
          {isSelected && (
            <AssetImage asset={Assets.common.check} height={Sizes.semiMedium} />
          )}
        </View>
      </View>
      <View style={styles.contentContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>
      </View>
      <View style={styles.offerContainer}>
        <Text style={styles.offer}>{priceContent}</Text>
        <Text style={styles.limitedTimeOffer}>{offerSubtitle}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.white10,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.normal,
    borderWidth: 1,
    borderColor: Colors.white10,
  },
  toggleContainer: {
    justifyContent: 'center',
  },
  toggle: {
    width: Sizes.semiLarge,
    height: Sizes.semiLarge,
    backgroundColor: Colors.white30,
    borderRadius: Sizes.semiLarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 2,
    paddingHorizontal: Sizes.small,
  },
  title: {
    ...Fonts.caption600,
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white60,
  },
  offerContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  offer: {
    textAlign: 'right',
  },
  regularPriceStrikethrough: {
    ...Fonts.small,
    textDecorationLine: 'line-through',
    color: Colors.white60,
  },
  discountPriceBold: {
    ...Fonts.largeBold,
  },
  limitedTimeOffer: {
    ...Fonts.xsmall600,
    textAlign: 'right',
    width: '100%',
    color: Colors.actionGreen,
  },
});
