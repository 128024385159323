import React, { useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { Fonts } from '../../Common/services/utils/AppConstants';

type TranscriptTextViewProps = {
  text: string;
  previewLength: number;
};

export const TranscriptTextView: React.FC<TranscriptTextViewProps> = ({
  text,
  previewLength,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <View style={styles.container}>
      <Text style={styles.transcriptText}>
        {isExpanded ? text : `${text.substring(0, previewLength)}...`}
      </Text>
      <TouchableOpacity
        style={styles.moreLessContainer}
        onPress={toggleExpanded}>
        <Text style={styles.moreLessButtonText}>
          {isExpanded ? 'less' : 'more'}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    paddingVertical: 10,
    paddingRight: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  transcriptText: {
    ...Fonts.small,
  },
  moreLessButtonText: {
    ...Fonts.smallBold,
  },
  moreLessContainer: {
    marginHorizontal: 5,
    alignSelf: 'flex-end',
  },
});
