import { createSlice } from '@reduxjs/toolkit';

export const TRACK_USER_EVENT_SLICE_NAME = 'TrackUserEventSlice';

export const trackUserEventSlice = createSlice({
  name: TRACK_USER_EVENT_SLICE_NAME,
  initialState: {},
  reducers: {},
});

export const TrackUserEventSlice = trackUserEventSlice.reducer;
