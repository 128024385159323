import { useNavigation } from '@react-navigation/native';
import { useCallback } from 'react';

import { MainNavigationProp } from '../../../App/navigation/main/MainStackNavigator';
import {
  isWebPlatform,
  MainStack,
  ScreenNames,
} from '../../../Common/services/utils/AppConstants';

type AiFigureCharacterNavigator = (name: string) => void;

export const useAiFigureCharacterNavigator = (): AiFigureCharacterNavigator => {
  const navigation =
    useNavigation<MainNavigationProp<MainStack.AI_FIGURE_PROFILE>>();

  return useCallback(
    (name: string) => {
      if (isWebPlatform) {
        window.open(`/ai-figure/${encodeURIComponent(name)}`);
        return;
      }

      navigation.navigate(ScreenNames.MainStack.AI_FIGURE_PROFILE, {
        name,
      });
    },
    [navigation],
  );
};
