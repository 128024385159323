import React, { useCallback, useEffect, useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { APProgramCommitment } from '../../../AP/components';
import { useApProgramCourseTargetGrade } from '../../../AP/services/hooks';
import {
  mapApProgramDetailsAvailableStatus,
  mapApProgramTargetGradeByCourseId,
} from '../../../AP/services/mappers';
import locale from '../../../App/locale';
import { useAppNavigation, useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { CommonFeedBackground } from '../../../Common/components';
import { Course, PaywallEntryPoint, Unit } from '../../../Common/entities';
import { mapDataToUnitTestPayload } from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  MainStack,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import {
  mapGradeToColor,
  mapGradeToNumberIndex,
} from '../../../Learn/services/utils';
import { ApProgramUpsellOverlay } from '../../../Onboarding/components';
import { useCourseLockStatusGetter } from '../../../UserAccess/services/hooks';
import { getCourseUnitLockedStatusUtil } from '../../../UserAccess/services/utils';
import { TestPrepProgressAlgorithm } from '../../algorithms';
import {
  TestPrepFeedHeader,
  TestPrepListView,
  TestPrepRefreshHistoryControl,
} from '../../components';
import { TestInfo } from '../../entities';
import { useTestDetailNavigator } from '../../services/hooks';
import { getTestPrepHistory, resetState } from '../../services/slices';
import { TestPracticeIntroOrigin } from '../TestPracticeIntroScreen';
import { TestPreviewOrigin } from '../TestPreviewScreen';

type Props = {
  selectedCourse: Course;
};

export const APTestScreenView = (props: Props): React.ReactElement => {
  const { selectedCourse } = props;

  const {
    handleOpenTestScreen,
    handleOpenTestPracticeScreen,
    handleOpenPracticeFeedScreen,
  } = useTestDetailNavigator();

  useApProgramCourseTargetGrade({
    courseId: selectedCourse.id,
    entryPoint: PaywallEntryPoint.TEST_PREP,
  });

  const dispatch = useAppDispatch();
  const mainNavigation = useAppNavigation();

  const attemptsHistory = useAppSelector(
    state => state.testPrep.attemptsHistory,
  );
  const courseTargetGrade = useAppSelector(state =>
    mapApProgramTargetGradeByCourseId(state, selectedCourse?.id),
  );
  const isUserHasApProgram = useAppSelector(mapApProgramDetailsAvailableStatus);

  const { isCourseLocked } = useCourseLockStatusGetter({
    courseId: selectedCourse.id,
  });

  const bestAttempts = useAppSelector(state => state.testPrep.bestAttempts);

  const targetedPractices = useAppSelector(
    state => state.testPrep.targetedPractices,
  );

  const chartLabels = useMemo(() => {
    const result = TestPrepProgressAlgorithm.getTestPrepPercentagePerGrade(
      selectedCourse,
      bestAttempts,
    );

    return result
      .filter(labelData => labelData.percentage > 0)
      .sort((a, b) => {
        return mapGradeToNumberIndex(b.step) - mapGradeToNumberIndex(a.step);
      });
  }, [bestAttempts, selectedCourse]);

  const managedSliceColor = useMemo(() => {
    return chartLabels.map(labelData => mapGradeToColor(labelData.step));
  }, [chartLabels]);

  const handlePracticePress = useCallback(
    (unit: Unit) => {
      const isUnitLocked = getCourseUnitLockedStatusUtil(
        selectedCourse?.id,
        unit.id,
      );

      if (selectedCourse && unit && isUnitLocked) {
        mainNavigation.navigate(MainStack.PAYWALL_SCREEN, {
          course: selectedCourse!,
          showTestPrep: true,
          entryPoint: PaywallEntryPoint.TEST_PREP,
        });
        return;
      }

      const targetedPractice = targetedPractices[unit.id];
      if (
        selectedCourse &&
        unit &&
        targetedPractice &&
        targetedPractice.levelIds.length > 0
      ) {
        if (targetedPractice.progress === 0) {
          handleOpenTestPracticeScreen(
            selectedCourse,
            unit,
            targetedPractice.levelIds,
            targetedPractice.topicIds,
            TestPracticeIntroOrigin.TEST_PREP_TAB,
          );
        } else {
          handleOpenPracticeFeedScreen(
            selectedCourse,
            unit,
            targetedPractice.levelIds,
            targetedPractice.topicIds,
          );

          trackAnalyticsEvent(Analytics.targetedPracticeStarted, {
            ...mapDataToUnitTestPayload(selectedCourse, unit),
            from: TestPracticeIntroOrigin.TEST_PREP_TAB,
          });
        }
      }
    },
    [
      handleOpenPracticeFeedScreen,
      handleOpenTestPracticeScreen,
      mainNavigation,
      selectedCourse,
      targetedPractices,
    ],
  );

  const handleUnitPress = useCallback(
    (unit: Unit) => {
      const isUnitLocked = getCourseUnitLockedStatusUtil(
        selectedCourse?.id,
        unit.id,
      );

      if (isUnitLocked) {
        mainNavigation.navigate(MainStack.PAYWALL_SCREEN, {
          course: selectedCourse!,
          showTestPrep: true,
          entryPoint: PaywallEntryPoint.TEST_PREP,
        });
        return;
      }
      dispatch(resetState());
      if (selectedCourse && unit) {
        handleOpenTestScreen(
          selectedCourse!,
          unit,
          TestPreviewOrigin.TEST_PREP_TAB,
        );
      }
    },
    [dispatch, selectedCourse, mainNavigation, handleOpenTestScreen],
  );

  useEffect(() => {
    if (selectedCourse?.id) {
      dispatch(
        getTestPrepHistory({
          course: selectedCourse,
        }),
      );
    }
  }, [dispatch, selectedCourse]);

  const attemptsData = useMemo(() => {
    const data: Record<string, TestInfo> = {};
    Object.keys(attemptsHistory).forEach(key => {
      const testAttempts =
        attemptsHistory[key].recentAttempts?.slice(0, 4) ?? [];
      data[key] = {
        attempts: testAttempts.map(attempt => attempt.grade),
        highestScore: bestAttempts[key]?.grade,
        progress: 40,
      };
    });
    return data;
  }, [attemptsHistory, bestAttempts]);

  const startFeedHeaderDescription = useMemo(() => {
    if (!isUserHasApProgram) {
      return locale.study.start_test_prep_desc;
    }

    const targetGrade = courseTargetGrade || 'N';

    return `${locale.ap.ap_test_prep_description
      .replace('{TARGET_SCORE}', targetGrade)
      .replace('{REQUIRED_SCORE}', targetGrade)} ${
      locale.ap.ap_test_prep_description_tail
    }`;
  }, [courseTargetGrade, isUserHasApProgram]);

  return (
    <>
      <ScrollView
        style={styles.container}
        showsVerticalScrollIndicator={false}
        scrollEnabled={!isCourseLocked}
        refreshControl={
          <TestPrepRefreshHistoryControl
            courseId={selectedCourse?.id}
            disabled={isCourseLocked}
          />
        }
        contentContainerStyle={styles.contentContainer}>
        <CommonFeedBackground course={selectedCourse} />

        {selectedCourse && (
          <TestPrepFeedHeader
            selectedCourse={selectedCourse}
            managedSliceColor={managedSliceColor}
            chartLabels={chartLabels}
            attempts={attemptsData}
            title={locale.study.start_test_prep}
            description={startFeedHeaderDescription}
          />
        )}

        <View>
          {selectedCourse?.units
            ?.filter(unit => unit.hasTestPrep)
            .map((unit: Unit, index) => (
              <TestPrepListView
                key={unit.id}
                firstIndex={index === 0}
                unit={unit}
                courseId={selectedCourse.id}
                testInfo={attemptsData[unit.id]}
                practice={targetedPractices[unit.id]}
                onUnitSelected={unit => handleUnitPress(unit)}
                onPracticePressed={unit => handlePracticePress(unit)}
              />
            )) || null}
        </View>
      </ScrollView>

      {!isCourseLocked && <APProgramCommitment courseId={selectedCourse?.id} />}
      {isCourseLocked && (
        <ApProgramUpsellOverlay courseName={selectedCourse.name} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.darkBlue,
  },

  contentContainer: {
    paddingBottom: Sizes.medium,
  },
  promoText: {
    ...Fonts.medium,
    textAlign: 'center',
  },
  promoTextBold: {
    ...Fonts.mediumBold,
  },
});
