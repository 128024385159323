import React, { useEffect } from 'react';

import { AiFigureProfile } from '../../AiFigure/screens/AiFigureProfile';
import { MainStack } from '../../Common/services/utils/AppConstants';

// FOR WEB, DO NOT IMPORT INDEX.
// Reason: Index will import extra files that potentially imports libraries that are not supported in web.
// If that kind of library is imported, it will cause an error.
// So we need to import the specific file that we need, and be more extra careful on it.
import { HomeScreenWeb } from '../../Learn/screens/home/HomeScreen.web';

const getRoute = (): string => {
  if (window.location.pathname.includes('/ai-figure/')) {
    return MainStack.AI_FIGURE_PROFILE;
  }

  return MainStack.HOME_MAIN;
};

// need to use this because react-navigation doesn't work properly with
// our web setup somehow
const MainNavigatorWeb = (): React.ReactElement => {
  // purposely not using memo here because we want to re-run this effect for any state change
  const currentRoute = getRoute();

  useEffect(() => {
    if (
      currentRoute === MainStack.HOME_MAIN &&
      !window.location.pathname.includes('/cid/')
    ) {
      window.location.href = 'https://teachtap.com';
    }
  }, [currentRoute]);

  switch (currentRoute) {
    case MainStack.AI_FIGURE_PROFILE:
      const name = window.location.pathname.split('/ai-figure/').pop() || '';
      return (
        <AiFigureProfile
          route={{ params: { name: decodeURIComponent(name) } }}
        />
      );
    default:
      return <HomeScreenWeb />;
  }
};

export default MainNavigatorWeb;
