import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { GetConfigurationResponse } from '../../entities';

export const getTeachTapConfigurationsGraphQLCall =
  async (): Promise<GetConfigurationResponse> => {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }

    const response = await graphqlApi.post(
      '',
      {
        query: `
      query GetTeachTapConfigurations {
        teachTapGetConfigurations {
          configurations {
            name
            key
            value
          }
        }
      }`,
      },
      {
        headers: authHeader,
      },
    );

    handleGraphQLError(response);

    return response.data.data.teachTapGetConfigurations;
  };
