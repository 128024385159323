import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { useDispatch } from 'react-redux';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useAppSelector } from '../../App/services/hooks';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { setRemainingTime } from '../services/slices';

type Props = {
  handleTimerEnd?: () => void;
};

export const TestPrepTimer = ({
  handleTimerEnd,
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const currentTest = useAppSelector(state => state.testPrep.currentTest);
  const [timer, setTimer] = useState(currentTest?.timeRemaining ?? 0);
  const [timerColor, setTimerColor] = useState(Colors.white10);
  const [isNavigated, setIsNavigated] = useState(false);

  const formatTime = useCallback(() => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes < 10 ? '0' : ''}${minutes}:${
      seconds < 10 ? '0' : ''
    }${seconds}`;
  }, [timer]);

  const timerPaused = useAppSelector(state => state.testPrep.timerPaused);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (!timerPaused) {
      interval = setInterval(() => {
        setTimer(prevTime => {
          if (prevTime <= 1) {
            clearInterval(interval as NodeJS.Timeout);
            return 0;
          }
          const newTime = prevTime - 1;
          const minutes = Math.floor(newTime / 60);
          if (minutes < 1) {
            setTimerColor(Colors.lightRed50);
          } else if (minutes < 2) {
            setTimerColor(Colors.lightYellow50);
          } else {
            setTimerColor(Colors.white10);
          }

          return newTime;
        });
      }, 1000);
    } else {
      if (interval) {
        clearInterval(interval);
      }
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [timerPaused]);

  useEffect(() => {
    if (timer === 0 && !isNavigated && handleTimerEnd) {
      setIsNavigated(true);
      handleTimerEnd();
    } else if (!timerPaused) {
      dispatch(setRemainingTime(timer));
    }
  }, [timer, handleTimerEnd, isNavigated, dispatch, timerPaused]);

  const timerBackgroundColor = useMemo(() => {
    if (timerPaused) {
      return { backgroundColor: 'white' };
    }
    return { backgroundColor: timerColor };
  }, [timerColor, timerPaused]);

  const timerIcon = useMemo(() => {
    return timerPaused
      ? Assets.test.pauseTimerIcon
      : Assets.test.timerIconWithouBackground;
  }, [timerPaused]);

  return (
    <View style={[styles.timerContainer, timerBackgroundColor]}>
      <AssetImage
        asset={timerIcon}
        height={18}
        fill={timerPaused ? 'black' : 'none'}
        fillOpacity={timerPaused ? 0.5 : 1}
        containerStyle={{}}
      />
      <Text style={[styles.timerText, timerPaused && styles.pausedTimerText]}>
        {formatTime()}
      </Text>
    </View>
  );
};

const styles = {
  timerContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 4,
    padding: 6,
    borderRadius: 100,
    backgroundColor: Colors.white10,
    height: 30,
    width: 70,
    marginHorizontal: 5,
  },
  timerText: {
    ...Fonts.small500,
  },
  pausedTimerText: {
    color: Colors.black50,
  },
};

export type RenderTestPrepTimerWithHandleTimerEnd = {
  handleTimerEnd: () => void;
};
export const renderTestPrepTimerWithHandleTimerEnd = ({
  handleTimerEnd,
}: RenderTestPrepTimerWithHandleTimerEnd): React.ReactElement => {
  return <TestPrepTimer handleTimerEnd={handleTimerEnd} />;
};

export const renderTestPrepTimer = (): React.ReactElement => {
  return <TestPrepTimer />;
};
