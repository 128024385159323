import moment from 'moment/moment';
import React, { lazy, useMemo, useRef } from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import Video from 'react-native-video';

import VideoPlayButton from '../../Common/components/common/VideoPlayButton';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { MessageItem } from '../entities';
import { MessageDateFormats } from '../services/utils';

const VideoLazy = lazy(() => import('react-native-video'));

type VideoMessageProps = {
  item: MessageItem;
  onVideoPress: (item: MessageItem) => void;
};

const VIDEO_FRAME_HEIGHT = 200;
const VIDEO_FRAME_WIDTH = 200 * 0.73;

export const VideoMessage = ({
  item,
  onVideoPress,
}: VideoMessageProps): React.ReactElement => {
  const videoRef = useRef<Video>(null);

  const time = useMemo(() => {
    return moment(item.date).format(MessageDateFormats.time);
  }, [item.date]);

  return (
    <Pressable style={styles.mediaContainer} onPress={() => onVideoPress(item)}>
      <VideoLazy
        ref={videoRef}
        source={{
          uri: item.video,
        }}
        style={styles.media}
        paused={true}
        resizeMode="cover"
      />

      <VideoPlayButton />

      <Text style={styles.timeText}>{time}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  media: {
    width: '100%',
    height: '100%',
    borderRadius: 12,
  },
  mediaContainer: {
    width: VIDEO_FRAME_WIDTH,
    height: VIDEO_FRAME_HEIGHT,
    borderRadius: 12,
    backgroundColor: Colors.appBackground,
  },
  timeText: {
    ...Fonts.xsmall,
    position: 'absolute',
    right: Sizes.semiMedium,
    bottom: Sizes.semiMedium,
    color: Colors.white60,
    textAlign: 'right',
  },
});
