import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, Dimensions, StyleSheet } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { Sizes } from '../../../Common/services/utils/AppConstants';

const coinsNo = 10;
const SCREEN_HEIGHT = Dimensions.get('window').height;

type Props = {
  startPosition: { x: number; y: number };
  isFromTestCompletionScreen?: boolean;
};

const Animations = ({
  startPosition,
  isFromTestCompletionScreen,
}: Props): React.ReactElement => {
  const [coinShown, setCoinShown] = useState(false);

  const position = useMemo(() => {
    if (isFromTestCompletionScreen) {
      return {
        x: startPosition.x - 100,
        y: startPosition.y + 50,
      };
    } else {
      return {
        x: startPosition.x,
        y: SCREEN_HEIGHT * 0.3,
      };
    }
  }, [isFromTestCompletionScreen, startPosition]);

  const flyX = useRef(
    new Array(coinsNo).fill(null).map(() => new Animated.ValueXY(position)),
  ).current;

  const animate = useCallback(() => {
    setCoinShown(true);
    flyX.forEach((_, i) => {
      flyX[i].setValue(position);
      const endPosition = isFromTestCompletionScreen
        ? {
            x: Dimensions.get('window').width - 258,
            y: Sizes.safeAreaInsetsTop + 10,
          }
        : { x: Dimensions.get('window').width - 50, y: -20 };
      Animated.timing(flyX[i], {
        toValue: endPosition,
        useNativeDriver: true,
        delay: i * 100,
        duration: 650,
      }).start(() => {
        if (i + 1 === coinsNo) {
          setCoinShown(false);
        }
      });
    });
  }, [flyX, isFromTestCompletionScreen, position]);

  useEffect(() => {
    animate();
  }, [animate]);

  return (
    <>
      {coinShown &&
        flyX.map((_, i) => (
          <Animated.View
            key={i}
            style={[
              {
                transform: [
                  { translateX: flyX[i].x },
                  { translateY: flyX[i].y },
                ],
              },
              styles.container,
            ]}>
            <AssetImage asset={Assets.home.coinIcon} height={10} width={10} />
          </Animated.View>
        ))}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: 10,
    height: 10,
  },
});

export default Animations;
