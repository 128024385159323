import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTeachTapUserSettings,
  upsertTeachTapUserSettings,
} from '../../../../App/services/slices';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { ONBOARDING_SLICE_NAME, setIsDataRecorded } from '../OnboardingSlice';

export const proceedOnboardingDataOnLogin = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedOnboardingDataOnLogin`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;

      const shouldUpdateUserSetting = Boolean(state.examType);
      const requestPromiseAction = shouldUpdateUserSetting
        ? thunkApi.dispatch(upsertTeachTapUserSettings())
        : thunkApi.dispatch(getTeachTapUserSettings());

      await requestPromiseAction.unwrap();

      if (shouldUpdateUserSetting) {
        thunkApi.dispatch(setIsDataRecorded(true));
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
