import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { toggleAPProgramSATAddonTrackActionExtraReducers } from './ApPaywallSliceActions';

export const AP_PROGRAM_PAYWALL_SLICE_NAME = 'ApProgramPaywallSlice';

export type ApProgramPaywallSliceState = {
  isLoading: boolean;
  isAddOnSelected: boolean;
};

const persistConfig = {
  key: AP_PROGRAM_PAYWALL_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['isAddOnSelected'],
} as PersistConfig<ApProgramPaywallSliceState>;

const initialState: ApProgramPaywallSliceState = {
  isLoading: false,
  isAddOnSelected: false,
};

const slice = createSlice({
  name: AP_PROGRAM_PAYWALL_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetApProgramPaywall: state => {
      state.isLoading = false;
      state.isAddOnSelected = false;
    },
    setApProgramPaywallLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: builder => {
    toggleAPProgramSATAddonTrackActionExtraReducers(builder);
  },
});

export const { resetApProgramPaywall, setApProgramPaywallLoading } =
  slice.actions;

export const ApProgramPaywallSlice = persistReducer(
  persistConfig,
  slice.reducer,
);
