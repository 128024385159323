import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  index: number;
  activeIndex: number;
};

export const TutorialIndicator = ({
  index,
  activeIndex,
}: Props): React.ReactElement => {
  const opacity = useMemo(
    () => (index === activeIndex ? 1 : 0.5),
    [index, activeIndex],
  );

  return <View key={index} style={[styles.dot, { opacity }]} />;
};

const styles = StyleSheet.create({
  dot: {
    height: Sizes.small,
    width: Sizes.small,
    borderRadius: Sizes.xsmall,
    backgroundColor: '#fff',
    margin: Sizes.xsmall,
  },
});

export const renderTutorialIndicator = (
  key: string,
  index: number,
  activeIndex: number,
): React.ReactElement => (
  <TutorialIndicator key={key} index={index} activeIndex={activeIndex} />
);
