import {
  AuthError,
  AuthSession,
  confirmResetPassword,
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
  resetPassword,
  signIn,
  signOut,
  updatePassword,
} from 'aws-amplify/auth';

import {
  ENV_KEYS,
  environment,
  SecureStorageUtil,
} from '../../../Common/services/utils';
import { isWebPlatform } from '../../../Common/services/utils/AppConstants';
import { AuthServiceUpdatePasswordWithCodeParams } from '../../entities';

export class AuthService {
  private static instance: AuthService;

  private session?: AuthSession;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  constructor() {
    this.session = undefined;
  }

  async getIdToken(): Promise<string> {
    this.session = await this.handleResourceNotFoundException(async () => {
      return await fetchAuthSession();
    });
    return this.session?.tokens?.idToken?.toString() || '';
  }

  async signIn(
    username: string,
    password: string,
    saveToIcloud: boolean,
  ): Promise<void> {
    await this.handleResourceNotFoundException(async () => {
      await signIn({
        username,
        password,
      });
    });

    if (saveToIcloud) {
      SecureStorageUtil.saveCredentials({ username, password });
    } else {
      SecureStorageUtil.removeCredentials();
    }
  }

  async getCurrentUserId(): Promise<string> {
    const user = await fetchUserAttributes();
    return user['custom:userID'] || '';
  }

  async signOut(): Promise<void> {
    if (isWebPlatform) {
      return;
    }

    await this.handleResourceNotFoundException(async () => {
      await signOut();
    });
    this.session = undefined;
  }

  async checkPassword(password: string): Promise<boolean> {
    try {
      await this.handleResourceNotFoundException(async () => {
        await updatePassword({ oldPassword: password, newPassword: password });
      });

      return true;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        const code = error?.name;
        switch (code) {
          case 'UserNotFoundException':
          case 'NotAuthorizedException':
          case 'PasswordResetRequiredException':
          case 'UserNotConfirmedException':
            return false;
          default:
            throw error;
        }
      } else {
        throw e;
      }
    }
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      const result = await this.handleResourceNotFoundException(async () => {
        return await getCurrentUser();
      });
      return Boolean(result.signInDetails);
    } catch (e) {
      return false;
    }
  }

  async resetPasswordRequest(email: string): Promise<void> {
    await this.handleResourceNotFoundException(async () => {
      await resetPassword({ username: email });
    });
  }

  async updatePasswordWithCode(
    params: AuthServiceUpdatePasswordWithCodeParams,
  ): Promise<void> {
    await this.handleResourceNotFoundException(async () => {
      await confirmResetPassword({
        username: params.email,
        confirmationCode: params.confirmationCode,
        newPassword: params.newPassword,
      });
    });
  }

  private async handleResourceNotFoundException<T>(
    fn: () => Promise<T>,
  ): Promise<T> {
    try {
      return await fn();
    } catch (e) {
      if (e instanceof AuthError && e.name === 'ResourceNotFoundException') {
        environment.invalidateCachedValue(ENV_KEYS.AWS_POOL_WEB_CLIENT_ID);
      }
      throw e;
    }
  }
}

export const getAuthService = (): AuthService => AuthService.getInstance();
