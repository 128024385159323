import { useIsFocused, useRoute } from '@react-navigation/core';
import React, { useEffect, useMemo } from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';

import { useAppDispatch } from '../../App/store';
import { Course, FeedType, Unit } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
  TestStack,
} from '../../Common/services/utils/AppConstants';
import FeedPage from '../../Learn/screens/home/page/FeedPage';
import { useFeedTimeSpentCounter } from '../../Learn/services/hooks';
import { clearItems } from '../../Learn/services/slices/FeedSlice';
import { TestPracticeFeedHeader } from '../components';

export type TestLevelsFeedProps = {
  course: Course;
  unit: Unit;
  levelIds: string[];
  topicIds: string[];
};

type ScreenProps = {
  route: { params: TestLevelsFeedProps };
};

export const TestLevelsFeedScreen = (
  props: ScreenProps,
): React.ReactElement => {
  const params = props.route?.params;
  const route = useRoute();

  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const { tabBarHeightWithSafeArea } = useSafeAreaCustomInsets();

  useFeedTimeSpentCounter(params?.course?.id);

  const itemHeight = useMemo(() => {
    if (route.name === TestStack.TEST_PREP_LIST) {
      return Dimensions.get('window').height - tabBarHeightWithSafeArea;
    }

    return Dimensions.get('window').height;
  }, [tabBarHeightWithSafeArea, route.name]);

  useEffect(() => {
    dispatch(
      clearItems({
        feedId: FeedType.TestPractice,
      }),
    );
  }, [dispatch]);

  return (
    <View style={styles.container}>
      <FeedPage
        tabTag={FeedType.TestPractice}
        isVisible={isFocused}
        onSearchPress={() => {}}
        isSearchVisible={false}
        itemHeight={itemHeight}
        course={params.course}
        unit={params.unit}
        levelIds={params.levelIds}
        showCoinsAnimation={true}
      />
      {params.unit && (
        <TestPracticeFeedHeader course={params.course} unit={params.unit} />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  badgeContainer: {
    marginEnd: 8,
    verticalAlign: 'middle',
    backgroundColor: 'green',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: Colors.appBackground,
    flex: 1,
  },
  descriptionText: {
    ...Fonts.medium,
    color: Colors.white30,
    textAlign: 'center',
  },
  emptyViewContainer: {
    alignItems: 'center',
    backgroundColor: Colors.appBackground,
    flex: 1,
    justifyContent: 'center',
  },
  titleText: {
    ...Fonts.mediumBold,
    color: 'white',
    marginBottom: 10,
    textAlign: 'center',
  },
  headerTitle: {
    ...Fonts.normalize(Fonts.semiMedium),
    textAlign: 'center',
    marginStart: 5,
    marginEnd: 10,
    lineHeight: isAndroidPlatform ? 18 : undefined,
  },
  header: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    columnGap: 2,
  },
});
