import { Asset } from '../../App/assets';

export enum TutorialType {
  APTutorial1 = 'apTutorial1',
  APTutorial2 = 'apTutorial2',
  APTutorial3 = 'apTutorial3',
  APTutorial4 = 'apTutorial4',
  SATBootcampTutorial1 = 'satBootcamp1',
  SATBootcampTutorial2 = 'satBootcamp2',
  SATBootcampTutorial3 = 'satBootcamp3',
  SATBootcampTutorial4 = 'satBootcamp4',
}

export type Tutorial = {
  tutorialKey: TutorialType;
  icon?: Asset;
  header?: React.ReactElement;
  screenImage?: Asset;
  component?: React.ReactElement;
  title: string;
  description?: string | React.ReactElement;
};

export enum TutorialNextButtonType {
  NEXT = 'next',
  FINISH = 'finish',
}
