import React from 'react';
import { StyleProp, StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { TestPrepQuestion } from '../entities';

import { TestPrepQuestionSelectorButton } from './TestPrepQuestionSelectorButton';

type Props = {
  questions: TestPrepQuestion[];
  style?: StyleProp<any>;
  onQuestionPress: (index: number) => void;
};

export const AnswersStatsView = (props: Props): React.ReactElement => {
  return (
    <>
      <Text style={styles.tapToReview}>
        {locale.testPrep.tap_a_question_to_review}
      </Text>
      <View style={[styles.container, props.style]}>
        {props.questions.map((question, index) => (
          <TestPrepQuestionSelectorButton
            key={question.id}
            index={index}
            question={question}
            onPress={props.onQuestionPress}
            isReviewMode
          />
        ))}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    gap: 6,
  },
  tapToReview: {
    ...Fonts.normalize(Fonts.xsmall),
    color: Colors.white40,
  },
});
