import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Dimensions,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { CommonAssets } from '../assets';
import { Testimonial } from '../entities';

import { renderCommonTestimonialCarouselItem } from './CommonTestimonialCarouselItem';

const SCREEN_WIDTH = Dimensions.get('window').width;
const CAROUSEL_ITEM_WIDTH = SCREEN_WIDTH - 70;

export type CommonTestimonialsCarouselProps = {
  testimonials: Testimonial[];
};

export const CommonTestimonialsCarousel = ({
  testimonials = [],
}: CommonTestimonialsCarouselProps): React.ReactElement => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const flatListRef = useRef<FlatList>(null);

  const handlePressNext = useCallback(() => {
    if (currentIndex < testimonials.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
  }, [currentIndex, testimonials.length]);

  const handlePressPrev = useCallback(() => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentIndex(testimonials.length - 1);
    }
  }, [currentIndex, testimonials.length]);

  const keyExtractor = useCallback(
    (item: Testimonial, index: number) =>
      JSON.stringify(item) || index.toString(),
    [],
  );

  const getItemLayout = useCallback(
    (data: Testimonial[] | null | undefined, index: number) => ({
      length: CAROUSEL_ITEM_WIDTH,
      offset: CAROUSEL_ITEM_WIDTH * index,
      index,
    }),
    [],
  );

  const onScrollToIndexFailed = useCallback((info: { index: number }) => {
    const wait = new Promise(resolve => setTimeout(resolve, 500));
    wait.then(() => {
      flatListRef.current?.scrollToIndex({
        index: info.index,
        animated: false,
      });
    });
  }, []);

  useEffect(() => {
    if (testimonials.length > 0) {
      flatListRef.current?.scrollToIndex({
        index: currentIndex,
        animated: false,
      });
    }
  }, [currentIndex, testimonials.length]);

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.titleRow}>
          <View style={styles.quoteLeft}>
            <AssetImage asset={CommonAssets.QuoteLeft} height={Sizes.large} />
          </View>
          <Text style={styles.title}>
            {locale.tutorial.ap_tutorial_carousel_title}
          </Text>

          <View style={styles.quoteRight}>
            <AssetImage asset={CommonAssets.QuoteRight} height={Sizes.large} />
          </View>
        </View>
        <Text style={styles.description}>
          {locale.tutorial.ap_tutorial_carousel_description}
        </Text>
      </View>

      <View style={styles.carouselContainer}>
        <TouchableOpacity style={styles.button} onPress={handlePressPrev}>
          <AssetImage
            asset={Assets.home.leftChevronIcon}
            height={Sizes.semiLarge}
          />
        </TouchableOpacity>
        <View style={styles.listContainer}>
          <FlatList
            data={testimonials}
            renderItem={renderCommonTestimonialCarouselItem}
            horizontal
            snapToInterval={CAROUSEL_ITEM_WIDTH}
            decelerationRate="fast"
            keyExtractor={keyExtractor}
            getItemLayout={getItemLayout}
            onScrollToIndexFailed={onScrollToIndexFailed}
            showsHorizontalScrollIndicator={false}
            ref={flatListRef}
            scrollEnabled={false}
          />
        </View>

        <TouchableOpacity style={styles.button} onPress={handlePressNext}>
          <AssetImage
            asset={Assets.home.rightChevronIcon}
            height={Sizes.semiLarge}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    marginBottom: Sizes.normalizeIP14PM(80),
  },
  titleRow: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  quoteLeft: {
    width: Sizes.semiLarge,
    height: Sizes.semiLarge,
    top: -Sizes.normal,
    marginRight: Sizes.normal,
  },

  quoteRight: {
    width: Sizes.semiLarge,
    height: Sizes.semiLarge,
    top: -Sizes.normal,
  },
  title: {
    ...Fonts.normalize(Fonts.xlargeBold),
    textAlign: 'center',
    marginTop: Sizes.semiMedium,
    color: Colors.grayscale[0],
  },
  description: {
    ...Fonts.small,
    textAlign: 'center',
    marginTop: Sizes.xsmall,
    color: Colors.white60,
    marginHorizontal: Sizes.semiMedium,
  },
  carouselContainer: {
    flexDirection: 'row',
    marginTop: Sizes.semiMedium,
  },
  button: {
    width: Sizes.xlarge,
    alignItems: 'center',
    justifyContent: 'center',
  },
  listContainer: {
    width: CAROUSEL_ITEM_WIDTH,
  },
});
