import Assets from '../../App/assets';

import CaptionsLarge from './images/captions_large.svg';
import CaptionsLargeOff from './images/captions_large_off.svg';
import InputArrowDown from './images/input_arrow_down.svg';
import PlainChat from './images/plain_chat.svg';
import quote_left from './images/quote_left.svg';
import quote_right from './images/quote_right.svg';
import UpsellCheck from './images/upsell_check.svg';

export const CommonAssets = {
  arrowAnimation: Assets.getBitmap(require('./gifs/arrow_animation.gif')),
  GiftAnimated: Assets.getBitmap(require('./gifs/gift-animated.gif')),

  InputArrowDown: Assets.getVector(InputArrowDown),
  UpsellCheck: Assets.getVector(UpsellCheck),
  PlainChat: Assets.getVector(PlainChat),
  CaptionsLarge: Assets.getVector(CaptionsLarge),
  CaptionsLargeOff: Assets.getVector(CaptionsLargeOff),
  QuoteLeft: Assets.getVector(quote_left),
  QuoteRight: Assets.getVector(quote_right),
};
