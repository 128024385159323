import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';

import locale from '../../../../App/locale';
import { getAuthService } from '../../../../App/services/amplify/AuthService';
import { handleNetworkActionError } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  deleteUserAccountGraphQLCall,
  deleteUserProfileImageGraphQLCall,
} from '../../../../Profile/graphql';
import { AUTH_SLICE_NAME, AuthSliceState, logout } from '../AuthSlice';

export const deleteUserProfile = createAsyncThunk(
  `${AUTH_SLICE_NAME}/deleteUserProfile`,
  async (password: string, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;

      if (await getAuthService().checkPassword(password)) {
        if (rootState.auth.authUser?.profileImageURL) {
          await deleteUserProfileImageGraphQLCall();
        }

        await deleteUserAccountGraphQLCall();
        await thunkApi.dispatch(logout()).unwrap();
      } else {
        showMessage({
          message: locale.errors.wrong_password,
          type: 'danger',
        });
        return thunkApi.rejectWithValue(locale.errors.wrong_password);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const deleteUserProfileExtraReducers = (
  builder: ActionReducerMapBuilder<AuthSliceState>,
): void => {
  builder.addCase(deleteUserProfile.pending, (state: AuthSliceState) => {
    state.isLoading = true;
  });

  builder.addCase(deleteUserProfile.fulfilled, (state: AuthSliceState) => {
    state.isLoading = false;
  });

  builder.addCase(deleteUserProfile.rejected, (state: AuthSliceState) => {
    state.isLoading = false;
  });
};
