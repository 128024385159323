import { Colors } from '../../../Common/services/utils/AppConstants';
import { AP_GRADES, GRADES_COLORS, HS_GRADES } from '../../data';

export const mapGradeToNumber = (grade: string): number => {
  if (AP_GRADES.includes(grade)) {
    return AP_GRADES.indexOf(grade) / (AP_GRADES.length - 1);
  } else if (HS_GRADES.includes(grade)) {
    return HS_GRADES.indexOf(grade) / (HS_GRADES.length - 1);
  }

  return 0;
};

export const mapGradeToNumberIndex = (grade: string): number => {
  if (AP_GRADES.includes(grade)) {
    return AP_GRADES.indexOf(grade);
  } else if (HS_GRADES.includes(grade)) {
    return HS_GRADES.indexOf(grade);
  }

  return -1;
};

export const isHighestGrade = (grade?: string): boolean => {
  if (!grade) {
    return false;
  }

  if (AP_GRADES.includes(grade)) {
    return grade === AP_GRADES[AP_GRADES.length - 1];
  } else if (HS_GRADES.includes(grade)) {
    return grade === HS_GRADES[HS_GRADES.length - 1];
  }

  return false;
};

export const mapNumberIndexToGrade = (
  index: number,
  isAPCourse: boolean,
): string => {
  const grades = isAPCourse ? AP_GRADES : HS_GRADES;
  return grades[index];
};

export const mapGradeToColor = (grade: string): string => {
  const index = mapGradeToNumberIndex(grade);
  return GRADES_COLORS?.[index] || Colors.white16;
};

export const getProgressBorderColor = (grade?: string): string => {
  const numericGrade = mapGradeToNumberIndex(grade ?? '');
  if (numericGrade === 2) {
    return Colors.sliceLightGreen;
  } else if (numericGrade === 3) {
    return Colors.sliceGreen;
  } else if (numericGrade === 4) {
    return Colors.emeraldGreen;
  } else {
    return Colors.grayscale[0];
  }
};
