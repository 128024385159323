import {
  AP_COURSE_GOALS_DATA,
  HIGH_SCHOOL_COURSE_GOALS_DATA,
  SAT_COURSE_GOALS_DATA,
} from '../../data';
import { Course, GoalItem } from '../../entities';

export const isAPCourseGrade = (grade?: string): boolean => {
  return grade === 'AP';
};

export const isAPCourse = (course?: Course): boolean => {
  return isAPCourseGrade(course?.grade);
};

export const isSATCourseGrade = (grade?: string): boolean => {
  if (!grade) {
    return false;
  }

  return parseInt(grade, 10) === 12;
};

export const isSATCourse = (course?: Course): boolean => {
  return isSATCourseGrade(course?.grade);
};

export const isHighSchoolCourse = (course?: Course): boolean => {
  if (!course?.grade) {
    return false;
  }

  return parseInt(course?.grade, 10) >= 9 && parseInt(course?.grade, 10) < 12;
};

export const getCourseGoalsFromCourse = (course?: Course): GoalItem[] => {
  if (!course) {
    return [];
  }

  if (isAPCourse(course)) {
    return AP_COURSE_GOALS_DATA;
  }

  if (isHighSchoolCourse(course)) {
    return HIGH_SCHOOL_COURSE_GOALS_DATA;
  }

  if (isSATCourse(course)) {
    return SAT_COURSE_GOALS_DATA;
  }

  return [];
};
