import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';

export const SATBootcampTutorial2 = (): React.ReactElement => {
  return (
    <View style={styles.container}>
      <View style={styles.masterKeyContainer}>
        <Text style={styles.labelText}>
          {locale.onboarding.high_end_tutoring_strategies}
        </Text>
        <AssetImage
          asset={OnboardingAssets.ZigZagLineIcon}
          containerStyle={styles.zigZagLine}
          height={25}
          width={Sizes.normalizeIP14PM(182.25)}
        />
      </View>

      <View style={styles.unlimitedTutorContainer}>
        <Text style={styles.labelText}>
          {locale.onboarding.affordable_expert_guidance}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: Sizes.normalizeIP14PM(104),
    width: Dimensions.get('window').width,
  },
  unlimitedTutorContainer: {
    position: 'absolute',
    top: 16,
    right: Sizes.small,
    transform: [{ rotate: '7deg' }],
  },
  masterKeyContainer: {
    transform: [{ rotate: '-7deg' }],
    position: 'absolute',
    bottom: -4,
    left: Sizes.small,
  },
  labelText: {
    ...Fonts.mediumGochiHand,
    color: Colors.actionGreen,
    textAlign: 'center',
    alignSelf: 'center',
  },
  zigZagLine: {
    alignSelf: 'center',
    top: -8,
    left: Sizes.normalizeIP14PM(5),
    transform: [{ rotate: '6deg' }],
  },
});
