import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GenerateQuestionResponse,
  LearningContentItemOutputGQLSchema,
} from '../../../Learn/entities';
import {
  AwardPointsRequest,
  GetTargetedPracticeLearningContentItemsRequest,
  RecordPracticeAnswerRequest,
  SendUserEmailRequest,
  SubmitUnitTestQuestionRequest,
  SubmitUnitTestRequest,
  AwardPointsResponse,
  RecordPracticeAnswerResponse,
  ShareUserEmailResponse,
  SubmitUnitTestResponse,
} from '../../entities';

export const submitUnitTestQuestionGraphQLCall = async (
  request: SubmitUnitTestQuestionRequest,
): Promise<void> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapSubmitUnitTestQuestion($input: TeachTapSubmitUnitTestQuestionInput!) {
          teachTapSubmitUnitTestQuestion(input: $input) {
              success
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data;
};

export const sendUserEmailGraphQLCall = async (
  request: SendUserEmailRequest,
): Promise<ShareUserEmailResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapSendUserEmail($input: TeachTapSendUserEmailInput!) {
          teachTapSendUserEmail(input: $input) {
            success
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapSendUserEmail;
};

export const submitUnitTestGraphQLCall = async (
  request: SubmitUnitTestRequest,
): Promise<SubmitUnitTestResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapSubmitUnitTest($input: TeachTapSubmitUnitTestInput!) {
          teachTapSubmitUnitTest(input: $input) {
            courseId
            unitId
            grade
            pointsAwarded
            questions {
              sequenceNumber
              questionId
              isCorrect
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapSubmitUnitTest;
};

export const getTargetedPracticeLearningContentItemsGraphQLCall = async (
  request: GetTargetedPracticeLearningContentItemsRequest,
  signal?: AbortSignal,
): Promise<GenerateQuestionResponse[]> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation GetTargetedPracticeLearningContentItems($input: TeachTapGetTargetedPracticeLearningContentItemsInput!) {
          teachTapGetTargetedPracticeLearningContentItems(input: $input) {
            ${LearningContentItemOutputGQLSchema}
          }
        }`,
    },
    {
      signal,
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetTargetedPracticeLearningContentItems;
};

export const recordPracticeAnswerGraphQLCall = async (
  request: RecordPracticeAnswerRequest,
): Promise<RecordPracticeAnswerResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation UpdateTargetedPracticeUserProgress($input: TeachTapUpdateTargetedPracticeUserProgressInput!) {
          teachTapUpdateTargetedPracticeUserProgress(input: $input) {
            targetedPracticeProgress
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapUpdateTargetedPracticeUserProgress;
};

export const awardPointsGraphQLCall = async (
  request: AwardPointsRequest,
): Promise<AwardPointsResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
       mutation TeachTapAwardPoints($input: TeachTapAwardPointsInput!) {
          teachTapAwardPoints(input: $input) {
              success
              pointsAwarded
              newTotalPoints
          }
      }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapAwardPoints;
};
