import React from 'react';
import { StyleSheet } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import CircularProgress from '../../Common/components/common/CircularProgress';
import { Colors } from '../../Common/services/utils/AppConstants';

type Props = {
  progress: number;
};

export const PracticeProgressView = (props: Props): React.ReactElement => {
  return (
    <CircularProgress
      percentage={props.progress}
      radius={18}
      color={Colors.additional.orange[100]}
      containerStyle={styles.progressContainer}>
      <AssetImage
        width={14}
        asset={Assets.test.bullseyeArrow}
        fill={Colors.additional.orange[100]}
      />
    </CircularProgress>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    marginStart: 2,
  },
});
