import React, { useCallback } from 'react';
import { Text, StyleSheet } from 'react-native';

import locale from '../../App/locale';
import { openWebLink } from '../../Common/services/utils';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { LEARN_MORE_URL } from '../services/utils';

export const APTutorialDescriptionWithLearnMore = (): React.ReactElement => {
  const handleLearnMorePress = useCallback(() => {
    openWebLink(LEARN_MORE_URL);
  }, []);

  return (
    <Text>
      {locale.tutorial.ap_tutorial_4_description}{' '}
      <Text onPress={handleLearnMorePress} style={styles.learnMore}>
        {locale.common.learn_more}
      </Text>
    </Text>
  );
};

const styles = StyleSheet.create({
  learnMore: {
    ...Fonts.small,
    textAlign: 'center',
    marginTop: Sizes.xsmall,
    color: Colors.white60,
    textDecorationLine: 'underline',
  },
});
