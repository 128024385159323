import { useCallback, useMemo, useRef } from 'react';

import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { MatchingPairsSetVideoPayload } from '../../../Learn/entities';
import {
  getCardAttributes,
  getMatchingAnswer,
  setHintVideoVisibility,
  setMatchingPairsCharacterVisibility,
} from '../../../Learn/services/slices';
import { ContentCard, MatchingPairsCard } from '../../entities';

type CardHintVideoVisibilityReturnType = [boolean, (value: boolean) => void];

export const useCardHintVideoVisibility = (
  card: ContentCard,
): CardHintVideoVisibilityReturnType => {
  const dispatch = useAppDispatch();

  const hintVideoVisible = useAppSelector(
    state => getCardAttributes(state.questions, card)?.showHintVideo || false,
  );

  const setVideoVisibility = useCallback(
    (value: boolean) => {
      dispatch(
        setHintVideoVisibility({
          card,
          value,
        }),
      );
    },
    [dispatch, card],
  );

  return [hintVideoVisible, setVideoVisibility];
};

type MatchingPairsCardHintVideoVisibility = [
  string, // selected video character id
  {
    set: (props: MatchingPairsSetVideoPayload) => void; // set the video specified by the payload
    close: () => void; // close the video
    proceedSelection: (characterId: string) => void; // the algorithm to proceed with the video selection
  },
];

export const useMatchingPairsCardHintVideoVisibility = (
  card: MatchingPairsCard,
): MatchingPairsCardHintVideoVisibility => {
  const dispatch = useAppDispatch();
  const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);

  const selectedVideoCharacterId = useAppSelector(
    state =>
      getCardAttributes(state.questions, card)
        ?.selectedCharacterFromLeftOptions || '',
  );

  const matchingPairsOptions = useAppSelector(state =>
    getMatchingAnswer(state.answer, card),
  );

  const firstSelectedCharacterFromLeftOptions = useMemo(() => {
    if (!matchingPairsOptions?.leftOptions) {
      return '';
    }

    return matchingPairsOptions.leftOptions[0].uniqueId;
  }, [matchingPairsOptions?.leftOptions]);

  const setMatchingPairsVideo = useCallback(
    ({
      shouldPlayAllCharacters,
      selectedCharacterFromLeftOptions,
    }: MatchingPairsSetVideoPayload) => {
      dispatch(
        setMatchingPairsCharacterVisibility({
          card,
          selectedCharacterFromLeftOptions,
          shouldPlayAllCharacters,
        }),
      );
    },
    [dispatch, card],
  );

  const closeMatchingPairsVideo = useCallback(() => {
    dispatch(
      setMatchingPairsCharacterVisibility({
        card,
        selectedCharacterFromLeftOptions: '',
        shouldPlayAllCharacters: false,
      }),
    );
  }, [card, dispatch]);

  const proceedVideoSelection = useCallback(
    (characterId: string = '') => {
      const videoCharacterId = selectedVideoCharacterId;

      const isCharacterIdSelected =
        !!videoCharacterId && videoCharacterId === characterId;

      const noVideoSelected = !videoCharacterId;
      const selectedDifferentCharacter =
        !!videoCharacterId && !!characterId && videoCharacterId !== characterId;

      const shouldSwitchToOtherVideo =
        noVideoSelected || selectedDifferentCharacter;

      if (isCharacterIdSelected) {
        closeMatchingPairsVideo();
        return;
      }

      if (shouldSwitchToOtherVideo) {
        closeMatchingPairsVideo();

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setMatchingPairsVideo({
            shouldPlayAllCharacters: false,
            selectedCharacterFromLeftOptions:
              characterId || firstSelectedCharacterFromLeftOptions,
          });
        }, 300);

        return;
      }

      closeMatchingPairsVideo();
    },
    [
      closeMatchingPairsVideo,
      setMatchingPairsVideo,
      selectedVideoCharacterId,
      firstSelectedCharacterFromLeftOptions,
    ],
  );

  const matchingPairsActions = useMemo(() => {
    return {
      set: setMatchingPairsVideo,
      close: closeMatchingPairsVideo,
      proceedSelection: proceedVideoSelection,
    };
  }, [setMatchingPairsVideo, closeMatchingPairsVideo, proceedVideoSelection]);

  return [selectedVideoCharacterId, matchingPairsActions];
};
