import { event } from './index';
import { UnsubscribeCallback } from './types';

export const addEventListenerBuilder =
  <T>(eventType: string) =>
  (listener: (payload: T) => void): UnsubscribeCallback => {
    event.on(eventType, listener);
    return () => event.off(eventType, listener);
  };

export const emitEventBuilder =
  <T>(eventType: string) =>
  (payload: T): void => {
    event.emit(eventType, payload);
  };

export const addEventEmitterListenerPair = <T>(
  eventType: string,
): [
  (payload: T) => void,
  (listener: (payload: T) => void) => UnsubscribeCallback,
] => [emitEventBuilder<T>(eventType), addEventListenerBuilder<T>(eventType)];
