import React from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import BoxedTextContainer from '../../../../Common/components/common/BoxedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import CharacterDescriptionView from '../../../../Common/components/home/CharacterDescriptionView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { DidYouKnowCard } from '../../../../Common/entities';
import {
  getQuestionStyle,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { getCardAttributes, setContentMuted } from '../../../services/slices';

import BaseContentCard from './BaseContentCard';
import homeStyles from './styles';

let questionWidthVar: number | null = null;

type Props = {
  item: DidYouKnowCard;
  isVisible: boolean;
};

export default ({ item, isVisible }: Props): React.ReactElement => {
  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const contentMuted = useAppSelector(state => state.questions.contentMuted);

  const dispatch = useAppDispatch();

  const onLayout = (event: any) => {
    const { width } = event.nativeEvent.layout;

    questionWidthVar = width;
  };

  const renderQuestionText = () => {
    const textStyling = getQuestionStyle(item.generatedContentId);
    return (
      <TouchableOpacity activeOpacity={1}>
        <BoxedTextContainer
          textStyle={{
            ...homeStyles.questionText,
            color: textStyling.textColor,
          }}
          text={item.text}
          width={questionWidthVar}
          outlineColor={textStyling.outlinedColor}
          color={textStyling.backgroundColor}
          style={styles.question}
        />
      </TouchableOpacity>
    );
  };

  const onCardTap = () => {
    if (isWebPlatform) {
      if (!contentMuted) {
        redirectToAppstore();
      } else {
        dispatch(setContentMuted(false));
      }
      return true;
    }
    return false;
  };
  return (
    <BaseContentCard item={item} onCardTap={onCardTap}>
      <View style={styles.contentContainer}>
        <View
          style={styles.textContainerDown}
          onLayout={!questionWidthVar ? onLayout : undefined}>
          {visibilityStyle !== 'no-text' && (
            <ContentTypeView contentType={item.type} typeName={item.typeName} />
          )}
          {renderQuestionText()}
          <CharacterDescriptionView
            avatarUrl={item.character.avatarUrl}
            description={item.character.title}
            name={item.character.name}
          />
        </View>
      </View>
      <ActionButtonsView item={item} />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    paddingLeft: 16,
    justifyContent: 'flex-end',
  },
  textContainerUp: {
    justifyContent: 'flex-start',
    paddingBottom: 8,
    flexGrow: 1,
  },
  textContainerMiddle: {
    justifyContent: 'center',
    paddingBottom: 8,
    flexGrow: 1,
  },
  textContainerDown: {
    justifyContent: 'flex-end',
    paddingBottom: 16,
    flexGrow: 1,
  },
  expanded: {
    flexShrink: 1,
  },
  revealButton: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  revealButtonSuccess: {
    backgroundColor: Colors.answerRight,
    borderRadius: 10,
  },
  revealButtonWrong: {
    backgroundColor: Colors.answerWrong,
    borderRadius: 10,
  },
  revealBackground: {
    backgroundColor: 'grey',
    borderRadius: 10,
  },
  inputFieldContainer: {
    //position: 'absolute',
    width: '100%',
    backgroundColor: 'white',
    height: 0,
    alignSelf: 'flex-end',
    paddingHorizontal: 16,
  },
  textInput: {
    ...Fonts.large,
    flex: 1,
    color: 'black',
  },
  keyboardHandler: {
    position: 'absolute',
    bottom: 0,
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    height: '100%',
    width: '100%',
  },
  itemContainer: {
    borderRadius: 8,
    paddingVertical: 6,
    marginTop: 8,
    flexDirection: 'row',
    paddingHorizontal: 12,
    width: '100%',
    minHeight: 50,
    maxWidth: '100%',
  },
  optionRight: {
    backgroundColor: Colors.answerRight,
  },
  optionWrong: {
    backgroundColor: Colors.answerWrong,
  },
  statusIcon: {
    flexGrow: 1,
    justifyContent: 'center',
    minWidth: 24,
    paddingStart: 16,
    alignItems: 'flex-end',
  },
  optionText: {
    ...Fonts.normalize(Fonts.medium),
    paddingStart: 2,
    paddingEnd: 2,
  },
  textWrapper: {
    flexShrink: 1,
    width: '100%',
    alignSelf: 'center',
  },
  optionsContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 10,
    marginBottom: 16,
  },
  contentTypeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 4,
  },
  contentTypeTitle: {
    ...Fonts.mediumBold,
    color: '#28B18F',
    marginStart: 6,
  },
  question: {
    opacity: 0.9,
  },
});
