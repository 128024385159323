import React, { useCallback, useEffect, useRef } from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import BoxedTextContainer from '../../../../Common/components/common/BoxedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import ContentPointsView from '../../../../Common/components/home/ContentPointsView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { VideoFrameView } from '../../../../Common/components/home/VideoFrameView';
import { MultipleChoiceQuestionV2 } from '../../../../Common/entities';
import {
  useCardHintVideoVisibility,
  useCardState,
  useCardTimeRecorder,
  useTimedToggle,
} from '../../../../Common/services/hooks';
import {
  getQuestionStyle,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Durations,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { useFetchRaiseHandTutor } from '../../../../RaiseHand/services/hooks';
import HintButton from '../../../components/contents/HintButton';
import Animations from '../../../components/courses/CoinsAnimation';
import {
  getCardAttributes,
  getQuestionPoints,
  setExplanationShown,
} from '../../../services/slices';

import AnswerOptionsView from './AnswerOptionsView';
import BaseContentCard from './BaseContentCard';
import homeStyles from './styles';

type Props = {
  item: MultipleChoiceQuestionV2;
  isVisible: boolean;
  showCoinsAnimation: boolean;
};

export type Layout = {
  x: number;
  y: number;
  width: number;
  height: number;
};

let questionWidthVar: number | null = null;

const TEXT_OFFSET = 90;

export default ({
  item,
  isVisible,
  showCoinsAnimation,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [showVideo, setShowVideo] = useCardHintVideoVisibility(item);
  const cardTimeRecorder = useCardTimeRecorder(item);
  const [isCorrectAnswered, setIsCorrectAnswered] = useCardState(item, false);
  const [isAnswered, setIsAnswered] = useCardState(item, false);

  const [contentPointLayout, setContentPointLayout] = useCardState<Layout>(
    item,
    {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  );
  const [parentY, setParentY] = useCardState(item, 0);
  const [animateTryMe, setAnimateTryMe] = useCardState(item, false);

  const { raiseHandTutor } = useFetchRaiseHandTutor(item.character.name);

  const [blink, setBlink, blinkControls] = useTimedToggle({
    toggleDuration: Durations.blinkDelay,
  });

  const points = useAppSelector(
    state => getQuestionPoints(state.answer, item.uniqueId),
    (prev, next) => prev === next,
  );

  const preventBlinkRef = useRef(showVideo);

  const handleStopBlink = useCallback(() => {
    blinkControls.cancel();
    setBlink(false);
    preventBlinkRef.current = true;
  }, [blinkControls, setBlink]);

  useEffect(() => {
    if (!preventBlinkRef.current && !blinkControls.ref.current) {
      if (!isVisible) {
        blinkControls.cancel();
        setBlink(false);
      } else {
        blinkControls.trigger();
      }
    }
  }, [isVisible, setBlink, blinkControls]);

  useEffect(() => {
    if (showVideo) {
      dispatch(
        setExplanationShown({
          item,
        }),
      );

      handleStopBlink();
    }
  }, [showVideo, dispatch, item, handleStopBlink]);

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const collapsedQuestion = useAppSelector(
    state => getCardAttributes(state.questions, item).collapsedQuestion,
  );

  const recordFinishedReadingExplanation = (answeredQuestion = false) => {
    if (showVideo || answeredQuestion) {
      cardTimeRecorder.recordFinishedReadingExplanation();
    }
  };

  const onLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;

    questionWidthVar = width;
  };

  const onHintPress = () => {
    handleStopBlink();

    if (isWebPlatform) {
      if (!showVideo) {
        setShowVideo(true);
      } else {
        redirectToAppstore();
      }
    } else {
      cardTimeRecorder.recordHelpShown();
      recordFinishedReadingExplanation();
      setShowVideo(!showVideo);
    }
  };

  useEffect(() => {
    if (visibilityStyle === 'no-text') {
      setShowVideo(false);
    }
  }, [setShowVideo, visibilityStyle]);

  const renderQuestionText = (shown: boolean, collapsed: boolean) => {
    const textStyling = getQuestionStyle(item.generatedContentId);

    const boxedTextContainerStyle: any = [
      styles.wrappedTextContainer,
      collapsed && { height: 0 },
    ];

    let textStyle: any = {};

    if (showVideo) {
      textStyle = { ...styles.questionTextSmall };
    } else {
      textStyle = { ...homeStyles.questionText };
    }

    textStyle = { ...textStyle, color: textStyling.textColor };

    return (
      <TouchableOpacity activeOpacity={1}>
        <BoxedTextContainer
          style={boxedTextContainerStyle}
          textStyle={textStyle}
          text={item.question}
          width={
            showVideo ? questionWidthVar! - TEXT_OFFSET : questionWidthVar!
          }
          outlineColor={textStyling.outlinedColor}
          color={textStyling.backgroundColor}
        />
      </TouchableOpacity>
    );
  };

  const onAnswered = (isCorrect: boolean) => {
    recordFinishedReadingExplanation(true);
    handleStopBlink();
    setShowVideo(true);

    setIsCorrectAnswered(isCorrect);
    setIsAnswered(true);
    !animateTryMe && setAnimateTryMe(!isCorrect);

    setTimeout(() => {
      setIsAnswered(false);
    }, 3000);
  };

  const handleContentPointLayoutChange = (layout: Layout) => {
    setContentPointLayout(layout);
  };

  const handleRaiseHandPress = () => {
    setAnimateTryMe(false);
  };

  return (
    <BaseContentCard item={item} onHintPress={onHintPress}>
      <View style={styles.contentContainer}>
        <View
          style={styles.textContainer}
          onLayout={!questionWidthVar ? onLayout : undefined}>
          {visibilityStyle !== 'no-text' && !collapsedQuestion && (
            <View
              style={homeStyles.contentTypeContainer}
              onLayout={event => {
                const { y } = event.nativeEvent.layout;
                setParentY(y);
              }}>
              <ContentTypeView
                contentType={item.type}
                typeName={item.typeName}
              />
              {points !== undefined && (
                <ContentPointsView
                  points={points}
                  isPositive={isCorrectAnswered}
                  isAnswered={isAnswered}
                  onLayoutChange={handleContentPointLayoutChange}
                  parentY={parentY}
                />
              )}
            </View>
          )}
          {renderQuestionText(
            visibilityStyle !== 'no-text',
            collapsedQuestion ?? false,
          )}
          {visibilityStyle !== 'no-text' && !collapsedQuestion && (
            <HintButton onHintPress={onHintPress} blinkHighlight={blink} />
          )}
        </View>
        <View style={styles.expanded}>
          {visibilityStyle !== 'no-text' && (
            <AnswerOptionsView item={item} onAnswered={onAnswered} />
          )}
        </View>
      </View>
      <ActionButtonsView
        item={item}
        forceMutable
        animateHand={animateTryMe}
        handleRaiseHand={handleRaiseHandPress}
        speakerCharacter={raiseHandTutor}
      />
      {visibilityStyle !== 'no-text' && (
        <VideoFrameView
          card={item}
          videoUrl={item.character.videoUrl}
          subtitleUrl={item.character.subtitleUrl}
          isVisible={isVisible}
          name={item.character.name}
          title={item.character.title}
          showVideo={showVideo}
          shouldRestart={!showVideo}
          hideVideo={() => setShowVideo(false)}
        />
      )}
      {showCoinsAnimation && contentPointLayout && isCorrectAnswered && (
        <Animations startPosition={contentPointLayout} />
      )}
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
  textContainer: {
    flexGrow: 20,
    justifyContent: 'flex-end',
    paddingBottom: 8,
  },
  wrappedTextContainer: {
    marginEnd: 0,
    opacity: 0.9,
  },
  questionTextSmall: {
    ...Fonts.normalize(Fonts.large),
  },
});
