import { RootState } from '../../../App/store';
import { isAPCourse } from '../../../Common/services/utils';
import { ApOnboardingCourseListType } from '../../entities';

export const mapApOnboardingCourseList = (
  state: RootState,
): ApOnboardingCourseListType => {
  const coursesEnrollmentCount = state.courseEnrollments.coursesEnrollmentCount;
  const availableCourses = Object.values(state.courses.coursesMap)
    .filter(course => isAPCourse(course))
    .map(course => ({
      key: `available-${course.id}`,
      name: course.name,
      numberOfUnits: course.units.length,
      followers: coursesEnrollmentCount[course.id] ?? 0,
      available: true,
      avatar: course.avatar,
    }));

  const nonAvailableCourses = state.apResults.allApUpcomingCourses.map(
    (course, index) => ({
      key: `nonAvailable-${index}`,
      name: course.name,
      available: false,
      avatar: course.thumbnailUrl,
      status: course.status,
    }),
  );

  const sortedAvailableCourses = availableCourses.sort((a, b) =>
    a.name.localeCompare(b.name),
  );
  const sortedNonAvailableCourses = nonAvailableCourses.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return {
    availableCourses: sortedAvailableCourses,
    nonAvailableCourses: sortedNonAvailableCourses,
    combinedCourses: [...sortedAvailableCourses, ...sortedNonAvailableCourses],
  };
};
