import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import locale from '../../../App/locale';
import { MainNavigationProp } from '../../../App/navigation/main/MainStackNavigator';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { CommonOrdinalNumber } from '../../../Common/components';
import { useSafeAreaCustomInsets } from '../../../Common/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';
import { LearnAssets } from '../../../Learn/assets';
import Animations from '../../../Learn/components/courses/CoinsAnimation';
import TopicFeedHeader from '../../../Learn/components/courses/TopicFeedHeader';
import { Layout } from '../../../Learn/screens/home/content/MCQItemView';
import { getCourseLockedStatusUtil } from '../../../UserAccess/services/utils';
import { TestPrepProgressAlgorithm } from '../../algorithms';
import { TimesUpTestPrepBanner } from '../../components';
import { AnswersStatsView } from '../../components/AnswersStatsView';
import { BestScoreBadgeView } from '../../components/BestScoreBadgeView';
import { SATGradeIndicator } from '../../components/SATGradeIndicator';
import { TestPrepData } from '../../entities';
import { useTestDetailNavigator } from '../../services/hooks';
import {
  setCurrentTestResultsSeen,
  setFirstTestCompleted,
} from '../../services/slices';
import { TestPreviewOrigin } from '../TestPreviewScreen';

const TopicAnimation = React.memo(() => {
  return (
    <View style={styles.gifContainer}>
      <Image
        style={styles.gif}
        resizeMode={'cover'}
        source={LearnAssets.topicCompletionGif.path as ImageSourcePropType}
      />
    </View>
  );
});

type Props = {
  currentTest: TestPrepData;
};

export const SATTestCompletionScreenView = (
  props: Props,
): React.ReactElement => {
  const dispatch = useAppDispatch();

  const { currentTest } = props;

  const { course, unit } = currentTest;

  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const maxScore = useMemo(() => {
    return currentTest.maxScore ?? 1;
  }, [currentTest.maxScore]);

  const lastBestScore = useMemo(() => {
    return currentTest.bestScore ?? 0;
  }, [currentTest.bestScore]);

  const score = useMemo(
    () => TestPrepProgressAlgorithm.getSATTestScore(currentTest)!,
    [currentTest],
  );

  const [contentPointLayout, setContentPointLayout] = useState<Layout>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const showBonusPoints = useMemo(() => {
    return !!currentTest.pointsAwarded;
  }, [currentTest.pointsAwarded]);

  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 1200);
    return () => {
      setShowAnimation(false);
    };
  }, [currentTest.grade]);

  const firstTestCompleted = useAppSelector(
    state => state.testPrep.firstTestCompleted,
  );

  const navigation = useNavigation<MainNavigationProp<any>>();

  const { handleOpenTestScreen, handleOpenTestReviewQuestionFlowScreen } =
    useTestDetailNavigator();

  const numericGrade = useMemo(() => score / maxScore, [score, maxScore]);

  const headerTitle = useMemo(() => {
    if (numericGrade < 0.6) {
      return locale.testPrep.keep_trying_title;
    } else if (numericGrade < 0.8) {
      return locale.testPrep.solid_effort_title;
    } else if (numericGrade < 1) {
      return locale.testPrep.well_done_title;
    } else {
      return locale.testPrep.perfect_score_title;
    }
  }, [numericGrade]);

  const bestScoreMessage = useMemo(() => {
    if (!lastBestScore) {
      return '';
    }

    if (score === lastBestScore) {
      return locale.testPrep.best_score_match_message;
    } else if (score > lastBestScore) {
      return locale.testPrep.best_performance_message;
    }
    return '';
  }, [score, lastBestScore]);

  const completionMessage = useMemo(() => {
    const correctAnswers = currentTest.questions.filter(
      question => question.chosenOption?.correct,
    );

    if (correctAnswers.length === currentTest.questions.length) {
      if (score === maxScore) {
        return locale.testPrep.sat_completion_message_perfect_maintained
          .replace('${UNIT_NAME}', `Unit ${unit.number}: ${unit.name}`)
          .replace(
            '${TOTAL_QUESTIONS}',
            currentTest.questions.length.toString(),
          );
      }

      return locale.testPrep.sat_completion_message_perfect
        .replace('${UNIT_NAME}', `Unit ${unit.number}: ${unit.name}`)
        .replace('${TOTAL_QUESTIONS}', currentTest.questions.length.toString());
    }

    return locale.testPrep.sat_completion_message
      .replace('${UNIT_NAME}', `Unit ${unit.number}: ${unit.name}`)
      .replace('${CORRECT_ANSWER}', correctAnswers.length.toString())
      .replace('${TOTAL_QUESTIONS}', currentTest.questions.length.toString());
  }, [currentTest.questions, unit, score, maxScore]);

  const onBackToCoursePress = useCallback(() => {
    const isCourseLocked = getCourseLockedStatusUtil(course?.id);
    if (!firstTestCompleted && isCourseLocked) {
      dispatch(setFirstTestCompleted());
    }
    navigation.popToTop();
  }, [course, dispatch, firstTestCompleted, navigation]);

  const onTakeAnotherTestPress = useCallback(() => {
    handleOpenTestScreen(course, unit, TestPreviewOrigin.TEST_PREP_COMPLETION);
  }, [course, handleOpenTestScreen, unit]);

  const onLayout = (event: LayoutChangeEvent) => {
    const { x, y, width, height } = event.nativeEvent.layout;
    setContentPointLayout({ x: x, y: y, width, height });
  };

  useEffect(() => {
    dispatch(setCurrentTestResultsSeen(true));
  }, [dispatch]);

  return (
    <LinearGradient
      style={styles.contentContainer}
      colors={Colors.screenGradient}>
      {currentTest.endedDueToTimeout && (
        <View style={styles.bannerContainer}>
          <TimesUpTestPrepBanner />
        </View>
      )}
      <View style={styles.subContainer}>
        {lastBestScore > 0 && (
          <BestScoreBadgeView
            bestScore={Math.max(lastBestScore, score)}
            highlight={score >= lastBestScore}
            style={styles.bestScoreBadge}
          />
        )}

        <SATGradeIndicator
          score={score}
          maxScore={maxScore}
          onLayout={onLayout}
        />
        <View style={styles.messageContainer}>
          <Text style={styles.completionHeading}>{headerTitle}</Text>
          {bestScoreMessage && (
            <Text style={styles.bestScoreMessage}>{bestScoreMessage}</Text>
          )}

          <Text style={styles.completionMessageText}>
            {completionMessage}{' '}
            <View style={styles.scorePercentileTextContainer}>
              <CommonOrdinalNumber
                numberInString={currentTest.percentileString}
                textStyle={styles.completionPercentile}
                ordinalTextStyle={styles.completionOrdinalSuffixPercentile}
              />
              <Text style={styles.completionPercentile}>
                {locale.testPrep.sat_completion_percentile}
              </Text>
            </View>
          </Text>
        </View>
        <AnswersStatsView
          questions={currentTest.questions}
          style={styles.answerStatusContainer}
          onQuestionPress={handleOpenTestReviewQuestionFlowScreen}
        />
        {currentTest.grade && <TopicAnimation />}
      </View>
      <View style={styles.actionButtonContainer}>
        <TouchableOpacity
          style={styles.takeAnotherTestContainer}
          onPress={onTakeAnotherTestPress}>
          <Text style={styles.takeAnotherTestText}>
            {locale.testPrep.retake_practice_test.replace(
              '${UNIT_NUMBER}',
              unit?.number.toString(),
            )}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.backToCourseContainer,
            { marginBottom: safeAreaBottom },
          ]}
          onPress={onBackToCoursePress}>
          <Text style={styles.keepStudyingText}>
            {locale.testPrep.back_to_course}
          </Text>
        </TouchableOpacity>
      </View>

      {showBonusPoints && showAnimation ? (
        <Animations
          startPosition={contentPointLayout}
          isFromTestCompletionScreen
        />
      ) : null}

      <TopicFeedHeader
        course={course}
        unit={unit}
        isForTopicCompletion={true}
        isForTestCompletion={true}
      />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    position: 'absolute',
    top: Sizes.safeAreaInsetsTop + Sizes.normalizeIP14PM(64),
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Sizes.normalizeIP14PM(10),
    justifyContent: 'space-between',
  },
  coinsAnimation: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    transform: [
      {
        translateY: -Dimensions.get('window').height * 0.3,
      },
      {
        translateX: Dimensions.get('window').width * 0.2,
      },
      {
        scale: 0.4,
      },
      { rotate: '30deg' },
    ],
  },
  keepStudyingText: {
    ...Fonts.large,
  },
  completionMessageHighlight: {
    ...Fonts.normalize(Fonts.captionBold),
    color: Colors.white70,
    textAlign: 'center',
  },
  bestScoreMessage: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.actionGreen,
    textAlign: 'center',
  },
  completionHeading: {
    ...Fonts.normalize(Fonts.xxxxlargeBold),
  },
  completionMessageText: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    marginTop: Sizes.semiMedium,
    marginHorizontal: Sizes.normalizeIP14PM(20),
    textAlign: 'center',
  },
  completionPercentile: {
    ...Fonts.normalize(Fonts.captionBold),
    color: 'white',
  },
  completionOrdinalSuffixPercentile: {
    ...Fonts.normalize(Fonts.xxsmallBold),
    color: 'white',
  },
  practiceHintMessageText: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    marginBottom: 12,
    marginHorizontal: 16,
    textAlign: 'center',
  },
  subContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    top: Sizes.normalizeIP14PM(50),
  },
  messageContainer: {
    paddingVertical: Sizes.normalizeIP14PM(10),
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonContainer: {
    width: '95%',
    justifyContent: 'flex-end',
    rowGap: Sizes.normalizeIP14PM(12),
  },
  takeAnotherTestContainer: {
    backgroundColor: Colors.lightBlue,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  accessAllUnitsContainer: {
    backgroundColor: Colors.actionGreen,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  startTargetedPracticeButton: {
    backgroundColor: Colors.additional.orange[100],
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    flexDirection: 'row',
    columnGap: 8,
    marginBottom: 16,
  },
  takeAnotherTestText: {
    ...Fonts.large,
  },
  backToCourseContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    borderWidth: 1,
    borderColor: Colors.white12,
  },
  description: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    textAlign: 'center',
    marginBottom: Sizes.normalizeIP14PM(5),
    paddingHorizontal: Sizes.normalizeIP14PM(8),
  },
  gifContainer: {
    width: '100%',
    height: '60%',
    position: 'absolute',
    top: 0,
  },
  gif: {
    width: '100%',
    height: '100%',
  },
  answerStatusContainer: {
    width: '95%',
    paddingVertical: Sizes.normalizeIP14PM(5),
    paddingHorizontal: Sizes.normalizeIP14PM(10),
  },
  questionStatus: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Sizes.normalizeIP14PM(33),
    height: Sizes.normalizeIP14PM(40),
    borderRadius: 20,
    margin: Sizes.normalizeIP14PM(5),
    rowGap: Sizes.normalizeIP14PM(2),
  },
  questionNumber: {
    ...Fonts.normalize(Fonts.xsmallBold),
    color: Colors.white60,
  },
  bonusPoints: {
    ...Fonts.normalize(Fonts.semiMediumBold),
    color: Colors.emeraldGreen,
    marginVertical: Sizes.normalizeIP14PM(8),
  },
  gradeIndicator: {
    width: Sizes.normalizeIP14PM(80),
    height: Sizes.normalizeIP14PM(80),
    borderRadius: Sizes.normalizeIP14PM(40),
  },
  gradeIndicatorText: {
    ...Fonts.normalize(Fonts.plusPlusSize),
  },
  emojiContainer: {
    position: 'absolute',
    width: 35,
    height: 35,
    bottom: 0,
    end: 0,
  },
  bestScoreBadge: {
    marginBottom: 16,
  },
  upsellUnlockHeading: {
    ...Fonts.normalize(Fonts.captionBold),
    textAlign: 'center',
  },

  scorePercentileText: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'center',
  },
  scorePercentileTextContainer: {
    minHeight: 0,
    flexDirection: 'row',
    alignItems: 'flex-end',
    transform: [{ translateY: 3 }],
    gap: 4,
  },
});
