import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';

export const SATBootcampTutorial1 = (): React.ReactElement => {
  return (
    <View style={styles.container}>
      <View style={styles.maxScoreImprovementContainer}>
        <Text style={styles.twentyFourSevenText}>
          {locale.onboarding.twenty_four_seven}
        </Text>
        <AssetImage
          asset={OnboardingAssets.CircleIcon}
          containerStyle={styles.circleIcon}
          height={40}
          width={35}
        />
        <Text style={styles.labelText}>
          {locale.onboarding.ai_guidance_24_7}
        </Text>
      </View>

      <View style={styles.guaranteedResultsContainer}>
        <Text style={styles.labelText}>
          {locale.onboarding.redefine_learning}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: Sizes.normalizeIP14PM(104),
    width: Dimensions.get('window').width,
  },

  maxScoreImprovementContainer: {
    position: 'absolute',
    bottom: 0,
    left: Sizes.small,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
  },
  curvedArrow: {
    alignSelf: 'flex-start',
    transform: [{ rotate: '6deg' }],
    position: 'absolute',
    left: Sizes.semiLarge,
    bottom: -24,
  },

  labelText: {
    ...Fonts.mediumGochiHand,
    color: Colors.actionGreen,
  },
  twentyFourSevenText: {
    ...Fonts.smallGochiHand,
    color: Colors.actionGreen,
    left: 2,
  },

  guaranteedResultsContainer: {
    position: 'absolute',
    transform: [{ rotate: '6deg' }],
    top: Sizes.xlarge,
    right: Sizes.small,
  },
  circleIcon: {
    position: 'absolute',
    left: -5,
    top: -10,
  },
});
