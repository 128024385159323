import axios from 'axios';

import { AWS_EXPORT_FILE, AWS_EXPORT_ROOT_FOLDER } from '../../data';
import { CustomAwsEnv } from '../../entities';
import { environment, ENV_KEYS } from '../utils';

export const getAwsExportJson = async (
  prNumber: string,
): Promise<CustomAwsEnv> => {
  const AWS_CUSTOM_ENV_URL_PREFIX = environment.getValue(
    ENV_KEYS.AWS_CUSTOM_ENV_URL_PREFIX,
  );

  const customEnvApi = axios.create({
    baseURL: AWS_CUSTOM_ENV_URL_PREFIX,
    headers: {
      'Cache-Control': 'no-cache',
    },
  });

  try {
    const response = await customEnvApi.get<CustomAwsEnv>(
      `${AWS_EXPORT_ROOT_FOLDER}/${prNumber}/${AWS_EXPORT_FILE}`,
    );

    if (response.status !== 200) {
      throw new Error('No aws-exports.json found');
    }

    return response.data;
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw e?.message;
    } else {
      throw e;
    }
  }
};
