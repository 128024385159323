import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import Assets from '../../../../App/assets';
import { AssetImage } from '../../../../App/assets/AssetImage';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';

type Props = {
  onHintPress: () => void;
  blinkHighlight?: boolean;
};

export default ({ onHintPress, blinkHighlight }: Props): React.ReactElement => {
  const [blink, setBlink] = useState<boolean>(false);
  const blinkRef = useRef<unknown>(null);

  const progress = useDerivedValue(() => {
    return withTiming(blink ? 1 : 0);
  });

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        progress.value,
        [0, 1],
        [Colors.lightBlue, Colors.actionYellow],
      ),
    };
  });

  useEffect(() => {
    if (blinkHighlight) {
      blinkRef.current = setInterval(() => {
        setBlink(prev => !prev);
      }, Durations.blinkInterval);
    } else {
      clearInterval(blinkRef.current as number);
      setBlink(false);
    }

    return () => {
      clearInterval(blinkRef.current as number);
    };
  }, [blinkHighlight]);

  return (
    <TouchableOpacity onPress={onHintPress} style={styles.container}>
      <Animated.View style={[styles.hintContainer, reanimatedStyle]}>
        <AssetImage
          asset={Assets.home.lightbulbIcon}
          width={Sizes.semiMedium}
          fill="white"
        />
      </Animated.View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderRadius: Sizes.medium,
  },
  hintContainer: {
    backgroundColor: Colors.lightBlue,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    height: Sizes.medium,
    width: Sizes.medium,
    borderRadius: Sizes.medium,
  },
  hintText: {
    ...Fonts.medium,
    marginStart: 4,
  },
});
