import {
  SATBootcampRawConfigsType,
  SATRawConfigsType,
} from '../../Onboarding/entities';

export type CommonSatBootcampConfigs = {
  exam: {
    SAT: SATRawConfigsType;
  };
  bootcamp: SATBootcampRawConfigsType;
};

export type CommonYearDatesType = Record<number, string | undefined>;
export type CommonApExamDatesType = {
  name: string;
  examDates: CommonYearDatesType | undefined; // [year: number]: YYYY-MM-DD
};

export type CommonApExamsConfigs = {
  apCourses: string[];
  apResultDates: CommonYearDatesType; // [year: number]: ISO date string
  apExamDates: CommonApExamDatesType[];
};

export type CommonApUpcomingCoursesConfigs = {
  name: string;
  status: string;
  thumbnailUrl: string;
}[];

export type CommonApExamsTestimonialsConfigs = {
  quote: string;
  name: string;
};

export enum TeachTapConfigKeys {
  SAT_BOOTCAMP = 'teachtap-sat-bootcamp-configuration',
  AP_EXAMS = 'teachtap-ap-exams-configuration',
  AP_UPCOMMING_COURSES = 'teachtap-ap-upcoming-courses-configuration',
  AP_EXAMS_TESTIMONIALS = 'teachtap-ap-user-quotes-configuration',
}

export type CommonTeachTapRawConfigType = {
  name: string;
  key: string;
  value: string;
};

export type CommonTeachTapConfigType = {
  satBootcamp?: CommonSatBootcampConfigs;
  apExams?: CommonApExamsConfigs;
  apUpcomingCourses?: CommonApUpcomingCoursesConfigs;
  apExamsTestimonials?: CommonApExamsTestimonialsConfigs[];
};
