import { APProgramDetails } from '../../entities';

export const mapAPProgramDetailsWithAvatars = (
  response: APProgramDetails,
  selectedAvailableCourses: Array<{ name: string; avatar?: string }>,
): APProgramDetails => {
  const avatarMap = new Map(
    selectedAvailableCourses.map(course => [course.name, course.avatar]),
  );

  return {
    courseDetails: response.courseDetails.map(courseDetail => ({
      ...courseDetail,
      avatar: avatarMap.get(courseDetail.name),
    })),
    totalStudyCommitmentHours: response.totalStudyCommitmentHours,
  };
};
