import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionError } from '../../../../App/services/utils';
import {
  showErrorMessage,
  showSuccessMessage,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  getUsernameAvailabilityGraphQLCall,
  updateUserDetailsGraphQLCall,
} from '../../../../Profile/graphql';
import { mapProfileDetailsUpdateAnalytics } from '../../../../Profile/services/mappers';
import { UpdateUsernameActionParams } from '../../../entities';
import { AUTH_SLICE_NAME, AuthSliceState } from '../AuthSlice';

import { getUserProfile } from './GetUserProfileAction';

export const updateUsername = createAsyncThunk(
  `${AUTH_SLICE_NAME}/updateUsername`,
  async (params: UpdateUsernameActionParams, thunkAPI) => {
    try {
      const checkResult = await getUsernameAvailabilityGraphQLCall({
        username: params.username,
      });

      if (!checkResult.isAvailable || !checkResult.isAppropriate) {
        showErrorMessage({
          message: locale.profile_screen_settings.username_failed_update,
        });

        return {
          success: false,
          message: !checkResult.isAppropriate
            ? locale.profile_screen_settings.username_inappropriate_error
            : locale.profile_screen_settings.username_taken_error,
        };
      }

      const result = await updateUserDetailsGraphQLCall({
        username: params.username,
      });

      if (!result.success) {
        showErrorMessage({
          message: locale.profile_screen_settings.username_failed_update,
        });
        return {
          success: false,
          message: result.message || locale.errors.something_went_wrong,
        };
      }

      trackAnalyticsEvent(
        Analytics.profileDetailsUpdated,
        mapProfileDetailsUpdateAnalytics({
          from: params.from,
          usernameUpdated: true,
        }),
      );

      await thunkAPI
        .dispatch(getUserProfile({ silent: true, skipAfterAuth: true }))
        .unwrap();

      showSuccessMessage({
        message: locale.profile_screen_settings.profile_updated_message,
      });

      return { success: true, message: '' };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
      }

      return { success: false, message: locale.errors.something_went_wrong };
    }
  },
);

export const updateUsernameExtraReducers = (
  builder: ActionReducerMapBuilder<AuthSliceState>,
): void => {
  builder.addCase(updateUsername.pending, (state: AuthSliceState) => {
    state.isLoading = true;
  });

  builder.addCase(updateUsername.fulfilled, (state: AuthSliceState) => {
    state.isLoading = false;
  });
};
