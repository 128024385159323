import moment from 'moment';

import { PurchasableItem } from '../../entities';

const PROMOTION_HOURS = 6;

export const DEFAULT_BOOTCAMP_PRODUCT_NAME = 'SAT Prep';

export const PROMO_CODE_REGEX = /^[A-Z0-9]+$/;
export const PROMO_CODE_INVALID_INPUT_REGEX = /[^a-zA-Z0-9]/g;

export enum StorePurchaseItems {
  COURSE_UNLOCK_EARLY_DISCOUNT_CONSUMABLE = 'course_unlock_early_discount_consumable',
  COURSE_UNLOCK_CONSUMABLE = 'course_unlock_consumable',
  SAT_DIGITAL_BOOTCAMP_REGULAR_CONSUMABLE = 'sat_digital_bootcamp_regular_consumable',
  SAT_DIGITAL_CRASH_COURSE_REGULAR_CONSUMABLE = 'sat_digital_crash_course_regular_consumable',
  SAT_DIGITAL_BOOTCAMP_CONSUMABLE = 'sat_digital_bootcamp_consumable',
  SAT_DIGITAL_CRASH_COURSE_CONSUMABLE = 'sat_digital_crash_course_consumable',
  SAT_DIGITAL_BOOTCAMP_DISCOUNT_CONSUMABLE = 'sat_digital_bootcamp_discount_consumable',
  SAT_DIGITAL_CRASH_COURSE_DISCOUNT_CONSUMABLE = 'sat_digital_crash_course_discount_consumable',
  AP_PROGRAM_CONSUMABLE = 'ap_program_consumable',
  AP_PROGRAM_REGULAR_CONSUMABLE = 'ap_program_regular_consumable',
  AP_PROGRAM_WITH_SAT_PREP_ADDON_CONSUMABLE = 'ap_program_with_sat_prep_addon_consumable',
  SAT_DIGITAL_BOOTCAMP_WITH_AP_PROGRAM_ADDON_CONSUMABLE = 'sat_digital_bootcamp_with_ap_program_addon_consumable',
  SAT_DIGITAL_CRASH_COURSE_WITH_AP_PROGRAM_ADDON_CONSUMABLE = 'sat_digital_crash_course_with_ap_program_addon_consumable',
}

export const PURCHASABLE_ITEMS: Record<string, PurchasableItem> = {
  [StorePurchaseItems.COURSE_UNLOCK_EARLY_DISCOUNT_CONSUMABLE]: {
    sku: StorePurchaseItems.COURSE_UNLOCK_EARLY_DISCOUNT_CONSUMABLE,
    type: 'product',
    consumable: true,
  },
  [StorePurchaseItems.COURSE_UNLOCK_CONSUMABLE]: {
    sku: StorePurchaseItems.COURSE_UNLOCK_CONSUMABLE,
    type: 'product',
    consumable: true,
  },

  [StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_REGULAR_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_REGULAR_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_REGULAR_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_REGULAR_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_DISCOUNT_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_DISCOUNT_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_DISCOUNT_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_DISCOUNT_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.AP_PROGRAM_CONSUMABLE]: {
    sku: StorePurchaseItems.AP_PROGRAM_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.AP_PROGRAM_REGULAR_CONSUMABLE]: {
    sku: StorePurchaseItems.AP_PROGRAM_REGULAR_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.AP_PROGRAM_WITH_SAT_PREP_ADDON_CONSUMABLE]: {
    sku: StorePurchaseItems.AP_PROGRAM_WITH_SAT_PREP_ADDON_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_WITH_AP_PROGRAM_ADDON_CONSUMABLE]: {
    sku: StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_WITH_AP_PROGRAM_ADDON_CONSUMABLE,
    type: 'product',
    consumable: true,
    manuallyConsumed: true,
  },
  [StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_WITH_AP_PROGRAM_ADDON_CONSUMABLE]:
    {
      sku: StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_WITH_AP_PROGRAM_ADDON_CONSUMABLE,
      type: 'product',
      consumable: true,
      manuallyConsumed: true,
    },
};

export const getPromotionEndTime = (paywallOpenedOn?: string): moment.Moment =>
  moment(paywallOpenedOn).add(PROMOTION_HOURS, 'hours');

export const CompetitorLogoIcons = {
  thumbnail:
    'https://s3.amazonaws.com/assets.gt.school/courses/3491d53d-d196-4c36-b948-2787e26a5b54/thumbnail.png',
  teachtap_icon:
    'https://teachtok-images.s3.amazonaws.com/paywall/teachtap_icon.png',
};
