import React from 'react';
import { StyleSheet } from 'react-native';
import Markdown from 'react-native-markdown-display';

import { Fonts } from '../../Common/services/utils/AppConstants';
import { renderLatexTextView } from '../../SAT/components';

type SATMathTutorTextViewProps = {
  identifier: string;
  isMathQuestion: boolean;
  message: string;
  isError: boolean;
  isStreaming: boolean;
};

export const SATMathTutorTextView = ({
  identifier,
  isMathQuestion,
  message,
  isError,
}: SATMathTutorTextViewProps): React.ReactElement => {
  const isMathQuestionAndNotError = isMathQuestion && !isError;
  return isMathQuestionAndNotError ? (
    renderLatexTextView({
      content: message,
      identifier: identifier,
      tutorMessageTextColor: 'white',
      fontSize: Fonts.semiMedium.fontSize,
    })
  ) : (
    <Markdown style={markdownStyles}>{message}</Markdown>
  );
};

const styles = StyleSheet.create({
  paragraph: {
    marginTop: 0,
    marginBottom: 0,
  },
  messageText: {
    ...Fonts.semiMedium,
  },
});

const markdownStyles = {
  body: styles.messageText,
  paragraph: styles.paragraph,
};

export const renderSATMathTutorTextView = (
  props: SATMathTutorTextViewProps,
): React.ReactElement => <SATMathTutorTextView {...props} />;
