import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import FastImage from 'react-native-fast-image';

import { useAiFigureCharacterNavigator } from '../../AiFigure/services/hooks';
import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { FeedbackTypes } from '../../Feedback/entities';
import { RaiseHandScreenTypes } from '../../RaiseHand/entities';
import { feedbackRaiseHandMessage } from '../../RaiseHand/services/slices';
import { DMSpeaker, MessageItem, UserType } from '../entities';
import { MessageDateFormats } from '../services/utils';

import { renderSATMathTutorTextView } from './SATMathTutorTextView';
import { VideoMessage } from './VideoMessage';

type Props = {
  speaker: DMSpeaker;
  item: MessageItem;
  index: number;
  onVideoPress: (messageItem: MessageItem) => void;
  showChatFeedback?: boolean;
  fromScreen: RaiseHandScreenTypes;
  isFromMathTest?: boolean;
};

type ChatItemDateProps = {
  timestamp: string;
  renderedTimestamp?: string;
};

export const ChatItemDate = React.memo(
  ({ timestamp, renderedTimestamp }: ChatItemDateProps): React.ReactElement => {
    const formatMessageDate = useMemo((): string => {
      if (renderedTimestamp) {
        return renderedTimestamp;
      }

      const momentDateTime = moment(timestamp);
      if (momentDateTime.isValid()) {
        return momentDateTime.format(MessageDateFormats.shortDate);
      }

      return '';
    }, [timestamp, renderedTimestamp]);

    return <Text style={styles.dateSeparator}>{formatMessageDate}</Text>;
  },
);

type ReactionContainerProps = {
  generatedContentId: string;
  fromScreen: RaiseHandScreenTypes;
};

const ReactionContainerPlaceholder = React.memo(() => {
  return <View style={styles.reactContainerWrapper} />;
});

const ReactionContainer = React.memo(
  ({ generatedContentId, fromScreen }: ReactionContainerProps) => {
    const dispatch = useAppDispatch();
    const likeStatus = useAppSelector(state => {
      return state.raiseHand.likeStatus[generatedContentId] || undefined;
    });

    const handleLikePress = useCallback(() => {
      dispatch(
        feedbackRaiseHandMessage({
          generatedContentId,
          feedbackType: FeedbackTypes.like,
          fromScreen,
        }),
      );
    }, [dispatch, generatedContentId, fromScreen]);

    const handleDislikePress = useCallback(() => {
      dispatch(
        feedbackRaiseHandMessage({
          generatedContentId,
          feedbackType: FeedbackTypes.dislike,
          fromScreen,
        }),
      );
    }, [dispatch, generatedContentId, fromScreen]);

    return (
      <View style={styles.reactionContainer}>
        <TouchableOpacity
          style={[
            styles.reactionButton,
            likeStatus === FeedbackTypes.like && styles.likedReaction,
          ]}
          onPress={handleLikePress}>
          <AssetImage
            asset={Assets.message.chatLikeIcon}
            height={Sizes.normalizeIP14PM(16)}
            fillOpacity={likeStatus === FeedbackTypes.like ? 1 : 0.6}
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={[
            styles.reactionButton,
            likeStatus === FeedbackTypes.dislike && styles.dislikedReaction,
          ]}
          onPress={handleDislikePress}>
          <AssetImage
            asset={Assets.message.chatDislikeIcon}
            height={Sizes.normalizeIP14PM(16)}
            fillOpacity={likeStatus === FeedbackTypes.dislike ? 1 : 0.6}
          />
        </TouchableOpacity>
      </View>
    );
  },
);

export const ChatItem = React.memo(
  ({
    item,
    speaker,
    onVideoPress,
    showChatFeedback = false,
    fromScreen,
    isFromMathTest,
  }: Props): React.ReactElement => {
    const handleCharacterPress = useAiFigureCharacterNavigator();

    const isUser = useMemo(() => item.sender === UserType.user, [item.sender]);
    const isError = useMemo(
      () => item.sender === UserType.error,
      [item.sender],
    );

    const showLikeDislikeButtons = useMemo(() => {
      return !isUser && showChatFeedback && !!item.generatedContentId;
    }, [isUser, showChatFeedback, item.generatedContentId]);

    const showFigureThumbnail = useMemo(() => {
      return !isUser && !isError;
    }, [isUser, isError]);

    const showErrorIcon = useMemo(() => {
      return !isUser && isError;
    }, [isUser, isError]);

    const containerStyle = useMemo(() => {
      return [
        isUser ? styles.userMessageContainer : styles.botMessageContainer,
        isError ? styles.errorMessageContainer : undefined,
      ];
    }, [isUser, isError]);

    const time = useMemo(() => {
      return moment(item.date).format(MessageDateFormats.time);
    }, [item.date]);

    return (
      <View style={styles.container}>
        <View style={!isUser ? styles.botMessageRow : {}}>
          {showFigureThumbnail && (
            <TouchableOpacity
              onPress={() => handleCharacterPress(speaker.name)}>
              <FastImage
                source={{ uri: speaker.avatarUrl }}
                style={styles.botAvatar}
              />
            </TouchableOpacity>
          )}

          {showErrorIcon && (
            <View style={styles.errorIconContainer}>
              <Text style={styles.errorIcon}>!</Text>
            </View>
          )}

          {item.isVideoMessage ? (
            <View>
              <VideoMessage item={item} onVideoPress={onVideoPress} />
              {showLikeDislikeButtons && (
                <ReactionContainer
                  generatedContentId={item.generatedContentId as string}
                  fromScreen={fromScreen}
                />
              )}
            </View>
          ) : (
            <View style={containerStyle}>
              {isUser ? (
                <Text style={styles.messageText}>{item.message}</Text>
              ) : (
                renderSATMathTutorTextView({
                  identifier: item.id,
                  isMathQuestion: isFromMathTest ?? false,
                  message: item.message ?? '',
                  isError,
                  isStreaming: item.isStreaming ?? false,
                })
              )}
              {!item.isStreaming && <Text style={styles.timeText}>{time}</Text>}
              {item.isStreaming && (
                <View style={styles.streamingDotsContainer}>
                  <Image
                    style={styles.loadingGif}
                    source={Assets.message.dotGif.path as ImageSourcePropType}
                    resizeMode="contain"
                  />
                </View>
              )}

              {showLikeDislikeButtons && (
                <ReactionContainer
                  generatedContentId={item.generatedContentId as string}
                  fromScreen={fromScreen}
                />
              )}
            </View>
          )}
        </View>

        {showLikeDislikeButtons && <ReactionContainerPlaceholder />}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    marginTop: Sizes.medium,
    marginBottom: Sizes.medium,
  },
  userMessageContainer: {
    alignSelf: 'flex-end',
    backgroundColor: Colors.white29,
    borderRadius: 12,
    padding: 10,
    marginLeft: 50,
    marginRight: Sizes.medium,
  },
  botMessageContainer: {
    alignSelf: 'flex-start',
    backgroundColor: Colors.emeraldGreen,
    borderRadius: 12,
    padding: 10,
    marginRight: 50,
    width: '80%',
  },
  errorMessageContainer: {
    backgroundColor: Colors.lightRed40,
  },
  botAvatar: {
    width: Sizes.large,
    height: Sizes.large,
    borderRadius: Sizes.large,
    alignSelf: 'flex-end',
  },
  botMessageRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: Sizes.xsmall,
    marginHorizontal: Sizes.medium,
    zIndex: 30,
  },
  errorIconContainer: {
    width: Sizes.large,
    height: Sizes.large,
    borderRadius: Sizes.large,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    backgroundColor: Colors.lightRed,
  },
  errorIcon: {
    ...Fonts.largeBold,
  },
  messageText: {
    ...Fonts.small,
  },
  dateSeparator: {
    ...Fonts.xsmall,
    textAlign: 'center',
    color: Colors.captionGrey,
    marginTop: 10,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.captionGrey,
    width: '30%',
    marginVertical: 15,
  },
  oldConversationSeparator: {
    width: '100%',
  },
  separatorContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  timeText: {
    ...Fonts.xsmall,
    color: Colors.white60,
    textAlign: 'right',
  },

  reactContainerWrapper: {
    paddingTop: Sizes.xsmall,
    height: 28,
    width: '100%',
    zIndex: 10,
  },
  reactionContainer: {
    position: 'absolute',
    bottom: -Sizes.xlarge,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: Sizes.xsmall,
    gap: Sizes.xsmall,
    zIndex: 20,
  },
  reactionButton: {
    width: 28,
    height: 28,
    borderRadius: 14,
    backgroundColor: Colors.white10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  likedReaction: {
    backgroundColor: Colors.emeraldGreen,
  },
  dislikedReaction: {
    backgroundColor: Colors.lightRed,
  },
  streamingDotsContainer: {
    paddingTop: Sizes.xsmall,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loadingGif: {
    width: 20,
    height: Sizes.medium,
    opacity: 0.6,
  },
});
