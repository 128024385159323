import React, { useCallback, useRef, useState } from 'react';

type StateWithRefReturn<T> = [
  T,
  React.Dispatch<React.SetStateAction<T>>,
  React.MutableRefObject<T>,
];

export const useStateWithRef = <T>(initialState: T): StateWithRefReturn<T> => {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);

  const setAndSyncState = useCallback((value: T | ((prevState: T) => T)) => {
    setState(prevState => {
      const newState = value instanceof Function ? value(prevState) : value;
      stateRef.current = newState;
      return newState;
    });
  }, []);

  return [state, setAndSyncState, stateRef];
};
