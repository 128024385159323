import { AiFigureModel } from '../../../AiFigure/entities';
import {
  SubmitUnitTestResponse,
  TestPrepOption,
  TestPrepQuestion,
  TestPrepQuestionEntryPoint,
  TestPrepQuestionStatus,
} from '../../../TestPrep/entities';
import {
  AiFigureAnalyticsPayload,
  Card,
  CardAnalyticsPayload,
  ContentCard,
  Course,
  Unit,
  UnitTestAnalyticsPayload,
  UnitTestCompletedAnalyticsPayload,
  UnitTestQuestionViewedAnalyticsPayload,
  UnitTestSkippedQuestionReviewDialogShownAnalyticsPayload,
  UnitTestSubmitAnswerAnalyticsPayload,
} from '../../entities';

import { mapCardTypeToAIContentType } from './ContentMapper';

export const mapCardToAnalyticsPayload = (card: Card): CardAnalyticsPayload => {
  if ((card as ContentCard).unit !== undefined) {
    const contentCard = card as ContentCard;

    const selectedUnitName =
      contentCard?.topic?.name ?? contentCard?.unit?.name ?? 'none';
    const selectedCardType =
      contentCard?.aiContentType ||
      mapCardTypeToAIContentType(contentCard.type);

    return {
      generatedContentId: contentCard.generatedContentId,
      cardType: selectedCardType,
      course: contentCard.course.name,
      unit: selectedUnitName,
      feedId: contentCard.feedId,
    };
  } else {
    return {
      cardType: mapCardTypeToAIContentType(card.type),
      feedId: card.feedId,
    };
  }
};

export const mapAiFigureAnalyticsPayload = (
  figure: AiFigureModel,
): AiFigureAnalyticsPayload => {
  return {
    figureName: figure.name,
    figureUsername: figure.handle,
  };
};

export const mapDataToUnitTestPayload = (
  course: Course,
  unit: Unit,
  testNumber?: number,
): UnitTestAnalyticsPayload => ({
  unitId: unit.id,
  unitNumber: unit.number,
  courseId: course.id,
  courseName: course.name,
  testNumber,
});

export const mapDataToUnitTestSubmitAnswerPayload = (
  course: Course,
  unit: Unit,
  question: TestPrepQuestion,
  option: TestPrepOption,
  testNumber?: number,
): UnitTestSubmitAnswerAnalyticsPayload => ({
  ...mapDataToUnitTestPayload(course, unit, testNumber),
  correct: option.correct,
  questionId: question.id,
});

export const mapDataToUnitTestCompletedPayload = (
  course: Course,
  unit: Unit,
  response: SubmitUnitTestResponse,
  timeRemaining: number,
  SATscore?: number,
  testNumber?: number,
): UnitTestCompletedAnalyticsPayload => ({
  ...mapDataToUnitTestPayload(course, unit, testNumber),
  grade: SATscore ? '' : response.grade,
  SATscore,
  timeRemaining,
  questionsAnswered: response.questions.length,
  questionsCorrect: response.questions.filter(q => q.isCorrect).length,
  pointsEarned: response.pointsAwarded,
});

export const mapDataToUnitTestSkippedQuestionReviewDialogShownPayload = (
  course: Course,
  unit: Unit,
  skippedQuestionsCount: number,
  testNumber?: number,
): UnitTestSkippedQuestionReviewDialogShownAnalyticsPayload => ({
  ...mapDataToUnitTestPayload(course, unit, testNumber),
  skippedQuestionsCount,
});

export const mapDataToUnitTestQuestionViewedPayload = (
  course: Course,
  unit: Unit,
  questions: TestPrepQuestion[],
  index: number,
  highestSeenQuestionIndex: number,
  from: TestPrepQuestionEntryPoint,
  testNumber?: number,
): UnitTestQuestionViewedAnalyticsPayload => ({
  ...mapDataToUnitTestPayload(course, unit, testNumber),
  questionId: questions[index].id,
  questionStatus: mapTestPrepQuestionStatusToAnalyticsPayload(
    questions[index],
    index,
    highestSeenQuestionIndex,
  ),
  from,
});

export const mapTestPrepQuestionStatusToAnalyticsPayload = (
  question: TestPrepQuestion,
  index: number,
  highestSeenQuestionIndex: number,
): string => {
  if (question.chosenOption && question.chosenOption.correct) {
    return TestPrepQuestionStatus.ANSWERED_CORRECTLY;
  } else if (question.chosenOption && !question.chosenOption.correct) {
    return TestPrepQuestionStatus.ANSWERED_INCORRECTLY;
  } else if (index >= highestSeenQuestionIndex) {
    return TestPrepQuestionStatus.UNANSWERED;
  }
  return TestPrepQuestionStatus.SKIPPED;
};
