import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { mapSelectedApCoursesWithGrouping } from '../../mappers';
import { AP_ONBOARDING_SLICE_NAME } from '../ApOnboardingSlice';

import { loadTargetCommitmentAction } from './LoadTargetCommitmentAction';

export const submitApCourseTargetGradeAction = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/submitApCourseTargetGradeAction`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const localState = rootState.apOnboarding;

    const { selectedAvailableCourses } =
      mapSelectedApCoursesWithGrouping(rootState);

    const goalSettingData = selectedAvailableCourses.map(courseItem => {
      return {
        course: courseItem.name,
        targetGrade: localState.apCourseTargetGrade[courseItem.name] || 'N/A',
      };
    });
    trackAnalyticsEvent(Analytics.apPrepDataSubmitted, {
      goalSettingData,
      from: rootState.onboarding.bootcampEntryPoint,
    });
    thunkAPI.dispatch(loadTargetCommitmentAction());
  },
);
