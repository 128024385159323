import fuzz from 'fuzzball';

import { FuzzyAnswer, FuzzyAnswerOutput } from '../entities';

export class CheckFuzzyAnswer {
  static getResult = (
    submittedAnswer: string,
    possibleAnswers: string[],
  ): FuzzyAnswerOutput => {
    let bestMatch = { ratio: 0, answer: possibleAnswers[0] } as FuzzyAnswer;

    for (const answer of possibleAnswers) {
      const ratio = fuzz.ratio(submittedAnswer, answer);

      if (ratio > (bestMatch?.ratio || 0)) {
        bestMatch = { ratio, answer };
      }

      if (ratio >= 95) {
        return {
          isCorrect: true,
          answer,
          ratio,
        };
      }
    }

    return {
      isCorrect: false,
      ratio: bestMatch.ratio,
      answer: bestMatch.answer,
    };
  };
}
