export enum PurchaseReason {
  redeemed = 'REDEEMED',
  purchased = 'PURCHASED',
}

export type PaywallCharacterResponse = {
  courseId: string;
  courseName: string;
  message: string;
  character: {
    name: string;
    avatar: string;
  };
  video?: string;
};

export enum TeachTapVoucherValidity {
  UNLIMITED_SUBSCRIPTION = 'UNLIMITED_SUBSCRIPTION',
  SEVEN_DAYS_SUBSCRIPTION = 'SEVEN_DAYS_SUBSCRIPTION',
}

export type TeachTapVoucherMetadata = {
  validity: TeachTapVoucherValidity;
};

export type TeachTapValidateVoucherOutput = {
  success: boolean;
  message?: string;
  metadata?: TeachTapVoucherMetadata;
};

export type TeachTapRedeemVoucherOutput = {
  success: boolean;
  message?: string;
};

export enum RegisterPurchaseType {
  COURSE = 'COURSE',
  AP_PROGRAM = 'AP_PROGRAM',
  SAT_BOOTCAMP = 'SAT_BOOTCAMP',
}

export enum RegisterPurchaseSource {
  PURCHASED = 'PURCHASED',
  REDEEMED = 'REDEEMED',
  COMPLIMENTARY = 'COMPLIMENTARY',
  EMPLOYEE = 'EMPLOYEE',
}

export type CourseMetadata = {
  subjectId: string;
  courseId: string;
  targetGrade: number;
};

export type SingleCourseMetadata = {
  subjectId: string;
  courseId: string;
};

export type APProgramMetadata = {
  courses: CourseMetadata[];
  optedForAddon: boolean;
};
export type RegisterPurchaseRequest = {
  transactionId: string;
  receiptData: string;
  purchasedAt: string;
  purchaseType: RegisterPurchaseType;
  purchaseSource: RegisterPurchaseSource;
  metadata?: SingleCourseMetadata;
};

export type RegisterPurchaseResponse = {
  success: boolean;
};
