import { appBucketApi } from '../../../App/services/requests';
import { GetAppConfigsFromS3Response } from '../../entities';

export const getAppConfigsFromS3Request =
  async (): Promise<GetAppConfigsFromS3Response> => {
    // Using seed to avoid caching
    const response = await appBucketApi.get(
      'app-configs/configs.json?seed=' + Date.now(),
    );
    return response.data;
  };
