import markdownIt from 'markdown-it';
import markdownItMathjax from 'markdown-it-mathjax3';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import {
  adjustDollarRenderingFormat,
  getMarkdownLatexHtmlTemplate,
  removeSpaceBetweenDollarSignsInLatex,
} from '../services/utils';
import { Colors } from '../services/utils/AppConstants';

type Props = {
  content: string;
  showSelectedOptionHighLight?: boolean;
  fontSize?: number;
  tutorMessageTextColor?: string;
  webViewStyle?: ViewStyle;
  identifier?: string;
};

const md = markdownIt({ html: true, breaks: true });
md.use(markdownItMathjax);

export const CommonMarkdownLatex = (props: Props): React.ReactElement => {
  const [height, setHeight] = useState(1);
  const contentRef = useRef<HTMLDivElement>(null);

  const textColor = useMemo(() => {
    if (props?.tutorMessageTextColor) {
      return props.tutorMessageTextColor;
    }
    return props.showSelectedOptionHighLight ? 'white' : Colors.white70;
  }, [props.showSelectedOptionHighLight, props?.tutorMessageTextColor]);

  const fontSize = useMemo(() => {
    return props.fontSize ?? 13;
  }, [props.fontSize]);

  const manipulatedContent = useMemo(() => {
    return adjustDollarRenderingFormat(
      removeSpaceBetweenDollarSignsInLatex(props.content),
    );
  }, [props.content]);

  const htmlOutput = useMemo(() => {
    return getMarkdownLatexHtmlTemplate({
      content: md.render(manipulatedContent),
      fontSize,
      textColor,
      fontFamily: 'sans-serif',
    });
  }, [fontSize, manipulatedContent, textColor]);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(+contentRef.current.scrollHeight);
    }
  }, [htmlOutput]);

  return (
    <View style={[{ height: height }, styles.container]}>
      <div
        ref={contentRef}
        key={props.identifier}
        style={{
          ...styles.webView,
          ...(props.webViewStyle as React.CSSProperties),
        }}
        dangerouslySetInnerHTML={{ __html: htmlOutput }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  webView: {
    backgroundColor: 'transparent',
  },
  container: {
    top: -7,
  },
});
