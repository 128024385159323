import { StyleSheet } from 'react-native';

import {
  Colors,
  Fonts,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';

export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    borderRadius: Sizes.normalizeIP14PM(50),
  },
  hintContainer: {
    backgroundColor: Colors.lightBlue,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    height: Sizes.normalizeIP14PM(34),
    paddingVertical: Sizes.normalizeIP14PM(4),
    paddingHorizontal: Sizes.normalizeIP14PM(10),
    borderRadius: Sizes.normalizeIP14PM(50),
  },
  hintText: {
    ...Fonts.medium,
    marginStart: Sizes.normalizeIP14PM(4),
  },
});
