import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { Fonts, Sizes } from '../../../Common/services/utils/AppConstants';
import { mapSATCourseData } from '../../../Learn/services/mappers';

export const SATTutorialHeader = (): React.ReactElement => {
  const SATCourse = useAppSelector(mapSATCourseData);
  const programDetails = useAppSelector(
    state => state.onboarding.SATProgramDetails,
  );

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <FastImage
          source={{ uri: SATCourse?.avatar || '' }}
          style={styles.image}
        />

        <Text style={styles.heading}>
          {programDetails?.programName || locale.common.not_available}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  subContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    ...Fonts.smallBold,
    marginTop: Sizes.xsmall,
  },

  image: {
    height: Sizes.large,
    width: Sizes.large,
    borderRadius: Sizes.semiMedium,
  },
});
