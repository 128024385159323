export enum CommonExamType {
  AP = 'AP',
  SAT = 'SAT',
}

export enum CommonAppGoalKeys {
  CRUSH_SAT = 'CRUSH_SAT',
  CRUSH_AP_TEST = 'CRUSH_AP_TEST',
  CRUSH_UPCOMING_TEST = 'CRUSH_UPCOMING_TEST',
}
