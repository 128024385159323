import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  large?: boolean;
};

export const ApProgramHeader = ({ large }: Props): React.ReactElement => {
  const iconsStyles = useMemo(() => {
    return { ...styles.icon, ...(large && styles.largeIcon) };
  }, [large]);
  const titleStyles = useMemo(() => {
    return { ...styles.title, ...(large && styles.largeTitle) };
  }, [large]);
  return (
    <View style={styles.container}>
      <AssetImage asset={Assets.platformIcon} imageStyle={iconsStyles} />
      <Text style={titleStyles}>
        {locale.onboarding.ap_program_overview_ap_program_title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 6,
  },
  icon: {
    width: Sizes.large,
    height: Sizes.large,
    borderWidth: 1,
    borderRadius: Sizes.large,
    borderColor: Colors.white100,
  },
  largeIcon: {
    width: 40,
    height: 40,
  },
  title: {
    ...Fonts.xsmall,
  },
  largeTitle: {
    ...Fonts.small600,
    color: Colors.emeraldGreen,
  },
});

export const renderApProgramHeaderWithoutProps = (): React.ReactElement => {
  return <ApProgramHeader />;
};
