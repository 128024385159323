import { Course } from '../../Common/entities';
import {
  isAPCourse,
  isHighSchoolCourse,
  isSATCourse,
} from '../../Common/services/utils';
import { EnrolledCourse } from '../../CourseEnrollment/entities';

export class TestPrepCourseAlgorithm {
  static shouldSelectTestPrepAsDefaultTab = (
    enrolledCourses?: EnrolledCourse[],
    courses?: Record<string, Course>,
  ): boolean => {
    if (!enrolledCourses?.length) {
      return true;
    }

    const coursesData = courses || {};
    const enrolledCoursesData = enrolledCourses || [];

    const apOrSatCourses = enrolledCoursesData.filter(
      coursePreview =>
        isAPCourse(coursesData[coursePreview.id]) ||
        isSATCourse(coursesData[coursePreview.id]),
    );

    if (apOrSatCourses.length > 0) {
      return true;
    }

    const highSchoolCourses = enrolledCoursesData.filter(coursePreview =>
      isHighSchoolCourse(coursesData[coursePreview.id]),
    );
    if (highSchoolCourses.length > 0 && !apOrSatCourses?.length) {
      return false;
    }

    return enrolledCoursesData.length > 0;
  };
}
