import {
  AppFeedbackData,
  ExamResultsFeedbackData,
  ExamResultsFeedbackRequest,
  FeedbackFormRequest,
} from '../../entities';

export const mapFeedbackDataToFormPayload = (
  data: AppFeedbackData,
): FeedbackFormRequest => {
  return {
    'entry.1000143011': data.userName ?? '',
    'entry.609663986': data.userEmail,
    'entry.2084797910': data.appVersion,
    'entry.771515968': data.deviceType,
    'entry.1922162840': data.deviceModel,
    'entry.154555869': data.deviceOS,
    'entry.656080931': data.feedbackType,
    'entry.1956235877': data.feedback,
    'entry.379728671': data.imageVideoLink || '',
    'entry.253928392': data?.viewedContentIds || '',
    'entry.426179312': data.courseName || '',
    'entry.1852472399': data.unitName || '',
    'entry.280451869': data.source || '',
    'entry.651602244': data.followedCourses || '',
    'entry.2139501568': data.activeCourseGoal || '',
    'entry.899490407': data.activeCourse || '',
    'entry.982414506': data.appGoal || '',
  };
};

export const mapExamResultsFeedbackDataToFormRequest = (
  props: ExamResultsFeedbackData,
): ExamResultsFeedbackRequest => {
  return {
    'entry.1000143011': props.userName ?? '',
    'entry.609663986': props.email,
    'entry.2084797910': props.appVersion,
    'entry.771515968': props.deviceType,
    'entry.1922162840': props.deviceModel,
    'entry.154555869': props.deviceOS,
    'entry.1956235877': props.feedback,
    'entry.280451869': props.zoomConsent || '',
    'entry.982414506': props.appGoal || '',
  };
};
