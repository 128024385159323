import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { CommonStudyMode } from '../../../../Common/entities';
import { reportStudyTimeGraphQLCall } from '../../../../Common/graphql/mutations';
import { getSATCourse } from '../../../../Learn/services/utils';
import { mapSATUserProgramDetailsAvailableStatus } from '../../mappers';
import { SAT_SLICE_NAME, setSatTimeSpentSeconds } from '../SATSlice';

import { getUserSATProgramDetails } from './GetUserSATProgramDetailsAction';

export const recordSATTestPrepTimeSpent = createAsyncThunk(
  `${SAT_SLICE_NAME}/recordSATTestPrepTimeSpent`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;

    const isProgramAvailable =
      mapSATUserProgramDetailsAvailableStatus(rootState);
    const satCourse = getSATCourse();
    const timeSpentMinutes = Math.floor(rootState.SAT.satTimeSpentSeconds / 60);

    if (!satCourse?.id || !isProgramAvailable) {
      return;
    }

    thunkAPI.dispatch(setSatTimeSpentSeconds(0));
    if (timeSpentMinutes > 0) {
      await reportStudyTimeGraphQLCall({
        minutesSpent: timeSpentMinutes,
        timestamp: new Date().toISOString(),
        courseId: satCourse.id,
        studyMode: CommonStudyMode.TEST_PREP,
      });

      thunkAPI.dispatch(getUserSATProgramDetails());
    }
  },
);
