import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { reportStudyTimeGraphQLCall } from '../../../../Common/graphql/mutations';
import { RecordApProgramTimeSpentParams } from '../../../entities';
import { mapApProgramDetailsAvailableStatus } from '../../mappers';
import { AP_SLICE_NAME } from '../APSlice';

import { getUserApProgramDetails } from './GetUserApProgramDetailsAction';

export const recordApProgramTimeSpent = createAsyncThunk(
  `${AP_SLICE_NAME}/recordApProgramTimeSpent`,
  async (params: RecordApProgramTimeSpentParams, thunkAPI) => {
    const { courseId, timeSpentMinutes, studyMode } = params;
    if (!timeSpentMinutes || !courseId) {
      return;
    }

    const rootState = thunkAPI.getState() as RootState;
    const isProgramAvailable = mapApProgramDetailsAvailableStatus(rootState);

    if (!isProgramAvailable) {
      return;
    }

    if (timeSpentMinutes > 0) {
      await reportStudyTimeGraphQLCall({
        minutesSpent: timeSpentMinutes,
        timestamp: new Date().toISOString(),
        courseId: courseId,
        studyMode: studyMode,
      });

      thunkAPI.dispatch(getUserApProgramDetails());
    }
  },
);
