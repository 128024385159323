import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { UpdateUserProfileImageRequest } from '../../../Common/entities';
import {
  CommonProfileSuccessMessageResponse,
  SignupRequest,
  TeachTapUpdateUserDetailsRequest,
  TeachTapUserSettingsResponse,
  UpsertUserSettingsRequest,
} from '../../entities';

export const updateUserDetailsGraphQLCall = async (
  input: TeachTapUpdateUserDetailsRequest,
): Promise<CommonProfileSuccessMessageResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input,
      },
      query: `mutation TeachTapUpdateUserDetails($input: TeachTapUpdateUserDetailsInput!) {
        teachTapUpdateUserDetails(input: $input) {
          success
          message
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);

  return response.data.data.teachTapUpdateUserDetails;
};

export const updateUserProfileImageGraphQLCall = async (
  request: UpdateUserProfileImageRequest,
): Promise<void> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
      mutation UpdateUserProfileImage($input: UpdateUserProfileImageInput!) {
        updateUserProfileImage(userDetails: $input) {
          id
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data.data.updateUserProfileImage;
};

export const createUserAccountGraphQLCall = async (
  request: SignupRequest,
): Promise<any> => {
  const response = await graphqlApi.post('', {
    variables: {
      input: request,
    },
    query: `
    mutation TeachTapSignUp($input: TeachTapSignUpInput!) {
      teachTapSignUp(input: $input) {
        success
      }
    }`,
  });
  handleGraphQLError(response);
  return response.data?.data?.teachTapSignUp;
};

export const deleteUserAccountGraphQLCall = async (): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      query: `
      mutation deleteUser {
        softDeleteUser {
          userId
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const upsertUserSettingsGraphQLCall = async (
  input: UpsertUserSettingsRequest,
): Promise<TeachTapUserSettingsResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input,
      },
      query: `
        mutation UpsertTeachTapUserSettings($input: UpsertTeachTapUserSettingsInput!) {
          upsertTeachTapUserSettings(input: $input) {
            goal
            examType
            emailOptIn
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const deleteUserProfileImageGraphQLCall =
  async (): Promise<CommonProfileSuccessMessageResponse> => {
    const response = await graphqlApi.post(
      '',
      {
        query: `mutation TeachTapDeleteUserProfileImage {
        teachTapDeleteUserProfileImage {
          success
          message
        }
      }`,
      },
      {
        headers: {
          ...(await getAuthHeader()),
          'x-api-key': undefined,
        },
      },
    );
    handleGraphQLError(response);

    return response.data.data.teachTapDeleteUserProfileImage;
  };
