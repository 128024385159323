import moment from 'moment/moment';

import locale from '../../../App/locale';
import { UserType, MessageItemData, MessageItemType } from '../../entities';

export const DUPLICATE_MESSAGE_THRESHOLD_IN_SECONDS = 2;

export const MessageDateFormats = {
  shortDate: 'M/D/YYYY',
  dateWithTimeUS: 'M/D/YYYY, h:mm:ss A',
  dateWithTimeGlobal: 'D/M/YYYY, h:mm:ss A',
  time: 'h:mm A',
};

export const getDateMessageItem = (
  timestamp: string,
  renderedTimestamp: string,
): MessageItemData => {
  return {
    type: MessageItemType.Date,
    timestamp,
    renderedTimestamp,
  };
};

export const generateMessageId = (
  speakerPlatformId: string,
  senderUserId: UserType,
  timestamp: string,
  isNewConversation = false,
  isError = false,
): string => {
  const idArray = [speakerPlatformId, senderUserId.toString(), timestamp];

  if (isNewConversation) {
    idArray.push('new-conversation');
  }

  if (isError) {
    idArray.push('error');
  }

  return idArray.join('-');
};

export const getChatItemKey = (item: MessageItemData): string => {
  if (item.type === MessageItemType.Date) {
    return `timestamp-${item.timestamp || ''}`;
  }

  if (item.type === MessageItemType.Header) {
    return 'message-item-header';
  }

  return item.data?.id || `message-item-${Math.random()}`;
};

export const getMessageDateTime = (
  dateISOString: string,
  focused: boolean,
): string => {
  const momentDate = moment(dateISOString);
  const recent = moment();

  if (recent.diff(momentDate, 'minutes') < 1 && focused) {
    return locale.dms_screen.just_now;
  }

  if (recent.diff(momentDate, 'day') < 1) {
    return locale.dms_screen.today;
  }

  if (recent.diff(momentDate, 'day') < 2) {
    return locale.dms_screen.yesterday;
  }

  return momentDate.format(MessageDateFormats.shortDate);
};
