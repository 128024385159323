import { createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';
import { PurchaseError } from 'react-native-iap';

import locale from '../../../../App/locale';
import { handlePaywallErrorSilently } from '../../../../App/services/utils';
import { PaywallEntryPoint } from '../../../../Common/entities';
import {
  PURCHASABLE_ITEMS,
  StorePurchaseItems,
} from '../../utils/PaywallUtils';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

import { purchaseProduct } from './PurchaseProductAction';

type PurchaseApProgramArgs = {
  item: StorePurchaseItems;
  entryPoint: PaywallEntryPoint;
};

export const purchaseApProgram = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/purchaseApProgram`,
  async (params: PurchaseApProgramArgs, thunkApi) => {
    try {
      await thunkApi
        .dispatch(purchaseProduct(PURCHASABLE_ITEMS[params.item]))
        .unwrap();

      return true;
    } catch (e) {
      const error = e as PurchaseError;
      if (error.code !== 'E_USER_CANCELLED') {
        showMessage({
          message: locale.errors.purchase_error,
          type: 'danger',
        });
      } else {
        handlePaywallErrorSilently(e as Error);
      }
      return thunkApi.rejectWithValue(e);
    }
  },
);
