import React from 'react';
import { TextStyle, View, ViewStyle } from 'react-native';
import { WebView, WebViewMessageEvent } from 'react-native-webview';

import { Colors, Sizes } from '../../Common/services/utils/AppConstants';

type MathJaxProps = {
  html: string;
  mathJaxOptions?: object;
  style?: ViewStyle;
  shouldAddEndMargin?: boolean;
  showSelectedOptionHighLight?: boolean;
  isSelectedOptionCorrect?: boolean;
  textStyle: TextStyle;
  mainContainerColor: string;
  textBackgroundColor: string;
  tutorMessageTextColor?: string;
};

const defaultOptions = {
  messageStyle: 'none',
  extensions: ['tex2jax.js'],
  jax: ['input/TeX', 'output/HTML-CSS'],
  tex2jax: {
    inlineMath: [
      ['$', '$'],
      ['\\(', '\\)'],
    ],
    displayMath: [
      ['$$', '$$'],
      ['\\[', '\\]'],
    ],
    processEscapes: true,
  },
  TeX: {
    extensions: [
      'AMSmath.js',
      'AMSsymbols.js',
      'noErrors.js',
      'noUndefined.js',
    ],
  },
};

/// We are using class component here because when changing the html prop, we need to force re-render the component
/// and with functional component it is not possible to force re-render and might need to bit of hack to achieve the same which requires more time.

export class MathLatex extends React.Component<MathJaxProps> {
  state = {
    height: 1,
    webref: React.createRef<WebView>(),
    webViewOpacity: 0,
  };

  handleMessage = (event: WebViewMessageEvent): void => {
    this.setState({
      height: Number(event.nativeEvent.data),
    });
  };

  handleLoad = (): void => {
    this.setState({ webViewOpacity: 1 }); // Make WebView visible after it's loaded
  };

  componentDidUpdate(prevProps: MathJaxProps): void {
    if (
      this.props.html !== prevProps.html ||
      this.props.mathJaxOptions !== prevProps.mathJaxOptions
    ) {
      this.forceUpdate(); // Forces re-render with new props
    }
  }

  wrapMathjax = (content: string): string => {
    const options = JSON.stringify(
      Object.assign({}, defaultOptions, this.props.mathJaxOptions),
    );

    const containerBackgroundColor = this.props.showSelectedOptionHighLight
      ? this.props.isSelectedOptionCorrect
        ? Colors.emeraldGreen
        : Colors.lightRed
      : this.props.mainContainerColor;

    const textColor =
      this.props.showSelectedOptionHighLight ||
      this.props?.tutorMessageTextColor
        ? 'white'
        : Colors.white70;

    const subContainerColor = this.props.showSelectedOptionHighLight
      ? 'transparent'
      : this.props.textBackgroundColor;

    const shouldAddMarginRight = this.props.shouldAddEndMargin
      ? Sizes.normalizeIP14PM(25)
      : 0;

    return `
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
      <style>
        body {
          background-color: ${containerBackgroundColor} !important;
          display: flex;
          overflow: hidden;
          width: auto !important;
          height: auto !important;
          margin: auto;
          padding: auto;
          align-self: center;
          margin-right: ${shouldAddMarginRight}px;
          padding-top: ${this.props.shouldAddEndMargin ? 0 : 2}px;
          font-size: ${this.props.textStyle.fontSize} !important;
          font-family: ${this.props.textStyle.fontFamily} !important;
          opacity: ${this.state.webViewOpacity} !important;
        }
        #formula {
          white-space: normal;
          font-size: ${this.props.textStyle.fontSize} !important;
          font-family: ${this.props.textStyle.fontFamily} !important;
          word-wrap: break-word !important;
          margin-right: ${shouldAddMarginRight}px;
        }
      </style>
      <script type="text/x-mathjax-config">
        MathJax.Hub.Config(${options});
        MathJax.Hub.Queue(function() {
          var height = document.documentElement.scrollHeight;
          window.ReactNativeWebView.postMessage(String(height));
          document.getElementById("formula").style.visibility = '';
        });
      </script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.0/MathJax.js"></script>
      <div id="formula" class="latext_text" style="background-color: ${subContainerColor} !important; width: 80%; color: ${textColor}; padding-right: 50px; visibility: hidden;">
        ${content}
      </div>
    `;
  };

  render(): JSX.Element {
    const html = this.wrapMathjax(this.props.html);
    const props: React.ComponentProps<typeof WebView> = Object.assign(
      {},
      this.props,
      { html: undefined },
    );

    return (
      <View
        style={{
          height: this.state.height,
          opacity: this.state.webViewOpacity,
        }}>
        <WebView
          ref={this.state.webref}
          scrollEnabled={false}
          onMessage={this.handleMessage}
          source={{ html }}
          onLoadEnd={this.handleLoad}
          {...props}
        />
      </View>
    );
  }
}
