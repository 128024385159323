import React from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import FastImage from 'react-native-fast-image';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { User } from '../../../Profile/entities';
import {
  Colors,
  Fonts,
  isWebPlatform,
} from '../../services/utils/AppConstants';
import {
  Animated,
  LinearGradient,
  useAnimatedStyle,
  useSharedValue,
} from '../../services/utils/polyfills';

type Props = {
  image?: string;
  user?: User;
  size: number;
  imageStyle?: StyleProp<any>;
  name?: string;
  allowFollow?: boolean;
  isFollowed?: boolean;
  style?: ViewStyle;
};

export default (props: Props): React.ReactElement => {
  const [isError, setIsError] = React.useState(false);

  const image = props.user?.profileImageURL ?? props.image;
  const username =
    props.user?.preferredName ??
    props.user?.email ??
    props.name ??
    locale.home_screen_card.anonymous;

  const rotate = useSharedValue(0);
  const rotateTick = useSharedValue(180);
  const scale = useSharedValue(1);
  const scaleTick = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: scale.value,
      transform: [{ rotate: `${rotate.value}deg` }, { scale: scale.value }],
    };
  });

  const animatedStylesTick = useAnimatedStyle(() => {
    return {
      opacity: scaleTick.value,
      transform: [
        { rotate: `${rotateTick.value}deg` },
        { scale: scaleTick.value },
      ],
    };
  });

  const renderDefaultAvatar = (): React.ReactElement => {
    const name = username || '';
    const nameParts = name.split(' ');
    const initials =
      nameParts.length > 1
        ? nameParts[0].charAt(0) + nameParts[1].charAt(0)
        : nameParts[0].charAt(0);

    return (
      <LinearGradient
        useAngle
        locations={[0, 1]}
        colors={Colors.avatarGradient}
        angle={180 + 45}
        style={[
          styles.defaultAvatar,
          {
            height: props.size,
            width: props.size,
            borderRadius: props.size / 2,
          },
        ]}>
        <Text
          style={[
            styles.initials,
            {
              fontSize: props.size / 2.2,
            },
          ]}>
          {initials.toLocaleUpperCase()}
        </Text>
      </LinearGradient>
    );
  };

  const renderImage = (): React.ReactElement => {
    if (isError || !image) {
      return renderDefaultAvatar();
    }

    return (
      <FastImage
        source={{ uri: image }}
        style={[
          styles.image,
          {
            height: props.size,
            width: props.size,
            borderRadius: props.size / 2,
          },
          props.imageStyle,
        ]}
        resizeMode={FastImage.resizeMode.cover}
        onError={() => setIsError(true)}
      />
    );
  };

  return (
    <View style={[styles.container, props.style]}>
      {renderImage()}
      {!isWebPlatform &&
        props.allowFollow &&
        scale.value === 1 &&
        scaleTick.value === 0 && (
          <>
            <Animated.View style={[styles.plusIcon, animatedStyles]}>
              <AssetImage
                height={24}
                asset={Assets.home.followAvatarTickIcon}
              />
            </Animated.View>
            <Animated.View style={[styles.plusIcon, animatedStylesTick]}>
              <AssetImage height={24} asset={Assets.home.tickIcon} />
            </Animated.View>
          </>
        )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  defaultAvatar: {
    alignItems: 'center',
    backgroundColor: Colors.lightGrey,
    justifyContent: 'center',
  },
  image: {
    borderColor: 'white',
    borderWidth: 1,
  },
  initials: {
    ...Fonts.xsmall,
  },

  plusIcon: {
    bottom: -10,
    position: 'absolute',
  },
});
