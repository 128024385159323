import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  GestureResponderEvent,
  PanResponder,
  PanResponderInstance,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { addMediaProgressEventListener } from '../../events';
import { Sizes } from '../../services/utils/AppConstants';

type Props = {
  uniqueId: string;
  customWidth?: number;
  seekTo?: (time: number) => void;

  fillFollowPosition?: boolean;

  style?: StyleProp<ViewStyle>;
  fillStyle?: StyleProp<ViewStyle>;
  circleStyle?: StyleProp<ViewStyle>;
  trackStyle?: StyleProp<ViewStyle>;
};

export const SeekbarView = (props: Props): React.ReactElement => {
  const [seekerScrubberPosition, setSeekerScrubberPosition] = React.useState<
    number | null
  >(null);

  const [seekerPosition, setSeekerPosition] = React.useState<number>(0);
  const useScrubberPosition = useRef(false);
  const draggingScrubber = useRef(false);

  const selectedWidth = useMemo(() => {
    if (props.customWidth) {
      return props.customWidth;
    }

    return Sizes.getAppWindowWidth();
  }, [props.customWidth]);

  const handleGetPosition = useCallback(
    (evt: GestureResponderEvent): number => {
      const position = evt.nativeEvent.locationX;
      if (position < 0 || !position) {
        return 0;
      }

      return position;
    },
    [],
  );

  const handleCalculatePercentage = useCallback(
    (position: number, selectedWidth: number) => {
      const result = position / selectedWidth;
      if (result < 0) {
        return 0;
      }

      if (result > 1) {
        return 1;
      }

      return result;
    },
    [],
  );

  const panResponder = React.useRef<PanResponderInstance>(
    PanResponder.create({
      // Ask to be the responder.
      onStartShouldSetPanResponder: (_evt, _gestureState) => true,
      onMoveShouldSetPanResponder: (_evt, _gestureState) => true,
      onPanResponderGrant: (evt, _gestureState) => {
        const position = handleGetPosition(evt);
        setSeekerScrubberPosition(
          handleCalculatePercentage(position, selectedWidth),
        );
      },
      onPanResponderMove: (evt, _gestureState) => {
        const position = handleGetPosition(evt);
        useScrubberPosition.current = true;
        draggingScrubber.current = true;
        setSeekerScrubberPosition(
          handleCalculatePercentage(position, selectedWidth),
        );
      },
      onPanResponderRelease: (evt, _gestureState) => {
        const position = handleCalculatePercentage(
          handleGetPosition(evt),
          selectedWidth,
        );
        draggingScrubber.current = false;

        if (props.seekTo) {
          props.seekTo(position);
        }
      },
      onPanResponderTerminate: (evt, _gestureState) => {
        const position = handleCalculatePercentage(
          handleGetPosition(evt),
          selectedWidth,
        );
        draggingScrubber.current = false;

        if (props.seekTo) {
          props.seekTo(position);
        }
      },
    }),
  ).current;

  useEffect(() => {
    const unsubscribe = addMediaProgressEventListener(event => {
      if (event.uniqueId === props.uniqueId && !draggingScrubber.current) {
        useScrubberPosition.current = false;
        setSeekerPosition(event.progress / event.duration);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [props.uniqueId]);

  const finalSeekerPosition = useScrubberPosition.current
    ? seekerScrubberPosition || 0
    : seekerPosition;

  const fillWidth = props.fillFollowPosition
    ? (finalSeekerPosition || 0) * selectedWidth
    : '100%';

  return (
    <View
      style={[styles.container, props.style]}
      collapsable={false}
      {...panResponder.panHandlers}>
      <View style={[styles.track, props.trackStyle]} pointerEvents={'none'}>
        <View
          style={[styles.fill, props.fillStyle, { width: fillWidth }]}
          pointerEvents="none"
        />
      </View>
      <View
        style={[styles.handle, { left: finalSeekerPosition * selectedWidth }]}
        pointerEvents={'none'}>
        <View
          style={[styles.circle, props.circleStyle]}
          pointerEvents={'none'}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  circle: {
    backgroundColor: '#FFF',
    borderRadius: 12,
    height: 8,
    left: 10,
    position: 'relative',
    top: 10,
    width: 8,
  },
  container: {
    alignSelf: 'stretch',
    height: 15,
    opacity: 0.8,
  },
  fill: {
    backgroundColor: '#FFF',
    height: 1,
    width: '100%',
  },
  handle: {
    height: 28,
    marginLeft: -7,
    position: 'absolute',
    width: 28,
  },
  track: {
    backgroundColor: '#333',
    height: 1,
    position: 'relative',
    top: 14,
    width: '100%',
  },
});
