import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

type Props = {
  backgroundColor: string;
  viewStyle?: ViewStyle;
};

export const GradeEmpty = (props: Props): React.ReactElement => {
  const { backgroundColor, viewStyle } = props;
  return <View style={[styles.circle, { backgroundColor }, viewStyle]} />;
};

const styles = StyleSheet.create({
  circle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
