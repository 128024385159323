import { PrefillThreshold } from './MessagesRequestTypes';

export type AskDMFigureRequestParams = {
  speaker: DMSpeaker;
  question: string;
  prefillThreshold: PrefillThreshold;
};

export type MessageSpeakers = {
  speakers: DMSpeaker[];
};

export type DMFigures = {
  bio: string;
  handle: string;
  image: string;
  name: string;
  socialBio: string;
  platformId: string;
};

export enum UserType {
  user,
  dmFigure,
  none,
  error,
}

export type MessageItem = {
  id: string;
  sender: UserType;
  message?: string;
  date: string;
  isVideoMessage?: boolean;
  video?: string;
  generatedContentId?: string;
  speakerUsername: string;
  isNewConversation: boolean;
  showDate?: boolean;
  isStreaming?: boolean;
};

export type DMSpeaker = {
  name: string;
  title: string;
  avatarUrl: string;
  username: string;
  biography: string;
  lastMessage?: MessageItem;
  platformId: string;
};

export type MessageItemIdPayload = {
  contentId: string;
  category?: string;
};

export type GeneratingMessageItem = {
  speakerData: DMSpeaker;
  message: string;
  dateString: string;
  dateTimeLimitString: string;
};

export type MessagesDataFromNotification = {
  generatedContentId: string;
  username: string;
  figureName: string;
  receivedAt: string;
  category: string;
};

export enum MessageScreenEntryPoints {
  VIDEO_PUSH_NOTIFICATION = 'videoPushNotification',
  DM_PUSH_NOTIFICATION = 'DMPushNotification',
  DM_THE_TEACHER = 'DMTheTeacher',
}

export enum MessageItemType {
  Date = 'date',
  Message = 'message',
  Header = 'header',
  FigureLoading = 'figureLoading',
  StartNewConversation = 'startNewConversation',
}

export type MessageItemData = {
  data?: MessageItem;
  timestamp?: string;
  renderedTimestamp?: string;
  type: MessageItemType;
};
