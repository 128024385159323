import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import { handleNetworkActionError } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { getUserProfile } from '../../../Auth/services/slices';
import {
  CommonApExamsConfigs,
  CommonApUpcomingCoursesConfigs,
} from '../../../Common/entities';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import { ExamResultsFeedbackZoomConsent } from '../../../Feedback/entities';
import { submitExamResultsFeedbackForm } from '../../../Feedback/services/slices';
import {
  ApResultsEntryPoint,
  ApResultsScoreEnum,
  ApResultsYesNoEnum,
  SetExamResultPayload,
  SetIsHappyWithResultsPayload,
} from '../../entities';
import { submitApAcademicDataGraphQLCall } from '../../graphql';
import {
  getAndSortQualifiedApCourses,
  getApResultsNextInputsSummary,
  mapApExamsRawConfig,
  mapApResultsAcademicDataToSubmit,
  mapApResultsInputForAnalytics,
} from '../mappers';

const PERSIST_KEY = 'ApResults';
const SLICE_NAME = 'ApResultsSlice';

type State = {
  notNowPressCount: number;
  apResultsEntryPoint: ApResultsEntryPoint;

  // For tracking if the AP Results landing screen has been opened, within the same section
  hasLandingOpened: boolean;

  // For tracking if the user has completed the AP Results flow
  isApResultsFlowCompleted: boolean;

  // [courseName: string]: YYYY-MM-DD
  coursesExamsDate: Record<string, string>;
  previousYearCoursesExamsDate: Record<string, string>;

  // ISO DATE-TIM, because we'll want to execute this in precise time specific behavior
  examResultsDate?: string;

  // [courseName: string]: ApResultsScoreEnum
  examResults: Record<string, ApResultsScoreEnum | undefined>;

  // [courseName: string]: ApResultsYesNoEnum
  isHappyWithResults: Record<string, ApResultsYesNoEnum | undefined>;

  allApCourses: string[];
  allApUpcomingCourses: CommonApUpcomingCoursesConfigs;

  planTakeSat?: ApResultsYesNoEnum;
  planApNextYear?: ApResultsYesNoEnum;
  apCoursesToTakeNextYear: string[];

  isDataSubmitted: boolean;
  isFeedbackSubmitted: boolean;
  isFlowStarted: boolean;

  apResultsFeedback: string;
  zoomChatConsent?: ApResultsYesNoEnum;
};

const initialState: State = {
  notNowPressCount: 0,
  apResultsEntryPoint: ApResultsEntryPoint.APP_OPEN,
  hasLandingOpened: false,
  isApResultsFlowCompleted: false,

  coursesExamsDate: {},
  previousYearCoursesExamsDate: {},
  examResultsDate: undefined,

  examResults: {},
  isHappyWithResults: {},

  allApCourses: [],
  allApUpcomingCourses: [],
  planTakeSat: undefined,
  planApNextYear: undefined,
  apCoursesToTakeNextYear: [],

  isDataSubmitted: false,
  isFeedbackSubmitted: false,
  isFlowStarted: false,

  apResultsFeedback: '',
  zoomChatConsent: undefined,
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'notNowPressCount',
    'examResultsDate',
    'coursesExamsDate',
    'previousYearCoursesExamsDate',
    'isApResultsFlowCompleted',
    'examResults',
    'isHappyWithResults',
    'allApCourses',
    'planTakeSat',
    'planApNextYear',
    'apCoursesToTakeNextYear',
    'isDataSubmitted',
    'isFeedbackSubmitted',
    'isFlowStarted',
    'apResultsFeedback',
    'zoomChatConsent',
  ],
  blacklist: ['apResultsEntryPoint', 'hasLandingOpened'],
};

export const trackApResultsFeedbackSubmitted = createAsyncThunk(
  `${SLICE_NAME}/trackApResultsFeedback`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apResultsState = rootState.apResults;

    let feedbackFormZoomConsent: ExamResultsFeedbackZoomConsent | undefined;
    if (apResultsState.zoomChatConsent) {
      feedbackFormZoomConsent =
        apResultsState.zoomChatConsent === ApResultsYesNoEnum.YES
          ? ExamResultsFeedbackZoomConsent.YES
          : ExamResultsFeedbackZoomConsent.NO;
    }

    trackAnalyticsEvent(Analytics.examPreparationFeedbackSubmitted, {
      feedback: apResultsState.apResultsFeedback,
      zoomChatConsent: apResultsState.zoomChatConsent ?? '',
    });

    thunkAPI.dispatch(setIsFeedbackSubmitted(true));

    if (
      !apResultsState.apResultsFeedback?.trim() &&
      feedbackFormZoomConsent === undefined
    ) {
      return;
    }

    thunkAPI.dispatch(
      submitExamResultsFeedbackForm({
        feedback: apResultsState.apResultsFeedback || '',
        zoomConsent: feedbackFormZoomConsent,
      }),
    );
  },
);

export const recordApResultsData = createAsyncThunk(
  `${SLICE_NAME}/recordApResultsData`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apResultsState = rootState.apResults;
    const onboardingState = rootState.onboarding;

    try {
      const request = mapApResultsAcademicDataToSubmit({
        planApNextYear: apResultsState.planApNextYear,
        planTakeSat: apResultsState.planTakeSat,
        apCoursesToTakeNextYear: apResultsState.apCoursesToTakeNextYear,
        courses: getAndSortQualifiedApCourses(rootState),
        satExamDate: onboardingState.examDate || '',
        examResults: apResultsState.examResults,
        isHappyWithResults: apResultsState.isHappyWithResults,
      });

      await submitApAcademicDataGraphQLCall(request);

      thunkAPI.dispatch(getUserProfile({ silent: true }));
      thunkAPI.dispatch(setIsDataSubmitted(true));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkAPI.rejectWithValue(error.message);
      } else {
        return thunkAPI.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const trackApResultsNextInputsSubmitted = createAsyncThunk(
  `${SLICE_NAME}/trackApResultsNextInputsSubmitted`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const nextInputsSummary = getApResultsNextInputsSummary(rootState);

    trackAnalyticsEvent(Analytics.nextExamsDataSubmitted, {
      takingSAT: nextInputsSummary.planSat,
      satDate: nextInputsSummary.satDate ?? '',
      takingAPExamsNextYear: nextInputsSummary.takingApNextYear,
      apExamsNextYear: nextInputsSummary?.nextYearApCourses || [],
    });

    thunkAPI.dispatch(recordApResultsData());
  },
);

export const startApResultsTracking = createAsyncThunk(
  `${SLICE_NAME}/startApResultsTracking`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apResultsState = rootState.apResults;

    trackAnalyticsEvent(Analytics.apExamResultFlowStarted, {
      from: apResultsState.apResultsEntryPoint || ApResultsEntryPoint.APP_OPEN,
    });

    thunkAPI.dispatch(setHasLandingOpened(true));
  },
);

export const closeApResultsLanding = createAsyncThunk(
  `${SLICE_NAME}/closeApResultsLanding`,
  async (_, thunkAPI) => {
    trackAnalyticsEvent(Analytics.apExamResultScreenClosed);
    thunkAPI.dispatch(incrementNotNowPressCount());
  },
);

export const startApResultsInputTracking = createAsyncThunk(
  `${SLICE_NAME}/startApResultsInputTracking`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apResultsState = rootState.apResults;

    const courses = getAndSortQualifiedApCourses(rootState);

    trackAnalyticsEvent(
      Analytics.apExamResultsSubmitted,
      mapApResultsInputForAnalytics(
        courses,
        apResultsState.examResults,
        apResultsState.isHappyWithResults,
      ),
    );
  },
);

const apResultsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetApResultsSlice: state => {
      state.notNowPressCount = 0;
      state.apResultsEntryPoint = ApResultsEntryPoint.APP_OPEN;
      state.isApResultsFlowCompleted = false;
      state.hasLandingOpened = false;
      state.examResults = {};
      state.isHappyWithResults = {};
      state.planApNextYear = undefined;
      state.planTakeSat = undefined;
      state.apCoursesToTakeNextYear = [];
      state.isDataSubmitted = false;
      state.isFeedbackSubmitted = false;
      state.isFlowStarted = false;
      state.apResultsFeedback = '';
      state.zoomChatConsent = undefined;
    },
    incrementNotNowPressCount: state => {
      state.notNowPressCount += 1;
    },
    setApResultsEntryPoint: (
      state,
      action: PayloadAction<ApResultsEntryPoint>,
    ) => {
      state.apResultsEntryPoint = action.payload;
    },
    setHasLandingOpened: (state, action: PayloadAction<boolean>) => {
      state.hasLandingOpened = action.payload;
    },
    setIsApResultsFlowCompleted: (state, action: PayloadAction<boolean>) => {
      state.isApResultsFlowCompleted = action.payload;
    },
    setApExamsConfig: (
      state,
      action: PayloadAction<CommonApExamsConfigs | undefined>,
    ) => {
      const configs = mapApExamsRawConfig(action.payload);

      state.coursesExamsDate = configs.coursesExamsDate;
      state.previousYearCoursesExamsDate = configs.previousYearCoursesExamsDate;
      state.examResultsDate = configs.examResultsDate;
      state.allApCourses = action.payload?.apCourses || [];
    },
    setApUpcomingCoursesConfig: (
      state,
      action: PayloadAction<CommonApUpcomingCoursesConfigs | undefined>,
    ) => {
      state.allApUpcomingCourses = action.payload || [];
    },
    setApExamResult: (state: State, action: SetExamResultPayload) => {
      state.examResults[action.payload.courseName] = action.payload.score;

      if (
        action.payload.score === ApResultsScoreEnum.ONE ||
        action.payload.score === ApResultsScoreEnum.TWO ||
        action.payload.score === ApResultsScoreEnum.FIVE
      ) {
        delete state.isHappyWithResults[action.payload.courseName];
      }
    },
    setIsHappyWithResults: (
      state: State,
      action: SetIsHappyWithResultsPayload,
    ) => {
      state.isHappyWithResults[action.payload.courseName] =
        action.payload.isHappy;
    },
    setPlanTakeSat: (state, action: PayloadAction<ApResultsYesNoEnum>) => {
      state.planTakeSat = action.payload;
    },
    setPlanApNextYear: (state, action: PayloadAction<ApResultsYesNoEnum>) => {
      state.planApNextYear = action.payload;
    },
    setApCoursesToTakeNextYear: (state, action: PayloadAction<string[]>) => {
      state.apCoursesToTakeNextYear = action.payload;
    },
    setIsDataSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isDataSubmitted = action.payload;
    },
    setApResultsFeedback: (state, action: PayloadAction<string>) => {
      state.apResultsFeedback = action.payload;
    },
    setZoomChatConsent: (state, action: PayloadAction<ApResultsYesNoEnum>) => {
      state.zoomChatConsent = action.payload;
    },
    setIsFeedbackSubmitted: (state, action: PayloadAction<boolean>) => {
      state.isFeedbackSubmitted = action.payload;
    },
    setIsFlowStarted: (state, action: PayloadAction<boolean>) => {
      state.isFlowStarted = action.payload;
    },
  },
});

export const {
  setApExamsConfig,
  setApUpcomingCoursesConfig,
  incrementNotNowPressCount,
  resetApResultsSlice,
  setApResultsEntryPoint,
  setHasLandingOpened,
  setIsApResultsFlowCompleted,

  setApExamResult,
  setIsHappyWithResults,

  setPlanTakeSat,
  setPlanApNextYear,
  setApCoursesToTakeNextYear,
  setIsDataSubmitted,

  setApResultsFeedback,
  setZoomChatConsent,
  setIsFeedbackSubmitted,
  setIsFlowStarted,
} = apResultsSlice.actions;

export const ApResultsSlice = persistReducer(
  persistConfig,
  apResultsSlice.reducer,
);
