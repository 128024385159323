import locale from '../../App/locale';

export const SAT_BOOTCAMP_ENTITLEMENT_CONFIRMATION_FEATURES = [
  locale.onboarding.entitlement_feature1,
  locale.onboarding.entitlement_feature2,
  locale.onboarding.entitlement_feature3,
  locale.onboarding.entitlement_feature4,
];

export const AP_PROGRAM_ENTITLEMENT_CONFIRMATION_FEATURES = [
  locale.onboarding.ap_entitlement_benefit_1,
  locale.onboarding.ap_entitlement_benefit_2,
  locale.onboarding.ap_entitlement_benefit_3,
  locale.onboarding.ap_entitlement_benefit_4,
];
