import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionError } from '../../../../App/services/utils';
import { teachTapGetUserAccessGraphQLQuery } from '../../../graphql';
import {
  USER_ACCESS_SLICE_NAME,
  UserAccessSliceState,
} from '../UserAccessSlice';

export const getUserAccess = createAsyncThunk(
  `${USER_ACCESS_SLICE_NAME}/getUserAccess`,
  async (_, thunkAPI) => {
    try {
      return await teachTapGetUserAccessGraphQLQuery();
    } catch (error) {
      if (error instanceof Error) {
        handleNetworkActionError(error);
      }

      return undefined;
    }
  },
);

export const getUserAccessExtraReducers = (
  builder: ActionReducerMapBuilder<UserAccessSliceState>,
): void => {
  builder.addCase(getUserAccess.fulfilled, (state, action) => {
    state.accessibleCourses = (action.payload?.accessibleCourses || []).reduce(
      (acc: Record<string, boolean>, course) => {
        acc[course.courseId] = true;
        return acc;
      },
      {},
    );

    state.hasUltimateAccess = action.payload?.hasUltimateAccess || false;
    state.hasSATPrepAccess = action.payload?.hasSATPrepAccess || false;
  });
};
