import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer, REHYDRATE, RehydrateAction } from 'redux-persist';

import locale from '../../../App/locale';
import { getConfigService } from '../../../App/services/amplify/ConfigService';
import {
  handleNetworkActionError,
  setUtilsShowServerErrors,
  setUtilsStickyErrorMessages,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { logout } from '../../../Auth/services/slices';
import {
  FeedRenderEngine,
  FeedRenderEngineOptions,
} from '../../components/home/feed';
import {
  CustomAwsEnv,
  CustomEnvPayload,
  ResetCustomEnvironmentPayload,
} from '../../entities';
import { isProductionEnv } from '../utils';

const PERSIST_KEY = 'settings';
export const SETTINGS_SLICE_NAME = 'SettingsSlice';

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  // reset settings on rehydrate
  blacklist: [
    'showCustomEnvironmentSwitch',
    'screenTimeLoading',
    'shouldCheckCustomEnvOnAppLoad',
  ],
};

type State = {
  feedRenderEngine: FeedRenderEngine;
  feedRenderEngineOptions: FeedRenderEngine[];

  showServerErrors: boolean;
  stickyErrorMessages: boolean;

  screenTimeEnabled: boolean;
  screenTimeLoading: boolean;
  useCustomEnvironment: boolean;

  showCustomEnvironmentSwitch: boolean;

  developerMode: boolean;
  selectedCustomEnv?: CustomAwsEnv;
  selectedPrNumber?: string;

  screnTimeAppChosen: boolean;
  shouldCheckCustomEnvOnAppLoad: boolean;

  trackingPermission?: string;
};

const initialState: State = {
  feedRenderEngine: 'flashlist',
  feedRenderEngineOptions: FeedRenderEngineOptions,
  showServerErrors: false,
  stickyErrorMessages: false,
  screenTimeEnabled: false,
  showCustomEnvironmentSwitch: false,
  useCustomEnvironment: false,
  screenTimeLoading: false,
  screnTimeAppChosen: false,
  shouldCheckCustomEnvOnAppLoad: false,

  developerMode: false,
};

export const initCustomEnvOnAppLoad = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/initCustomEnvOnAppLoad`,
  async (_, thunkApi): Promise<boolean> => {
    const state = (thunkApi.getState() as RootState).settings;

    const proceedCheckCustomEnv =
      state.shouldCheckCustomEnvOnAppLoad &&
      !!state.selectedCustomEnv &&
      !!state.selectedPrNumber &&
      state.useCustomEnvironment;

    if (!proceedCheckCustomEnv) {
      // If the custom env is not set, we can safely assume that the user is using the default env (production).
      // No need to clean up the custom env, just set the default env and reset the state.
      thunkApi.dispatch(resetCustomEnvironment({ removeSelectedEnv: false }));
      getConfigService().setDefaultEnvironment();
      return false;
    }

    const isEnvExist = await thunkApi
      .dispatch(
        checkCustomEnvironment({
          selectedEnv: state.selectedCustomEnv as CustomAwsEnv,
          selectedPrNumber: state.selectedPrNumber as string,
        }),
      )
      .unwrap();

    if (!isEnvExist) {
      // If env does not exist, reset the custom env and force logout.
      // Potentially this case is when the user login and selected a custom env, but the
      // env is deleted from the backend.
      thunkApi.dispatch(resetCustomEnvironment({ removeSelectedEnv: true }));
      getConfigService().setDefaultEnvironment();
      thunkApi.dispatch(logout());
      return false;
    }

    const result = await getConfigService().setCustomEnvironment(
      false,
      state.selectedCustomEnv,
    );

    if (!result) {
      // If env does exist, but failed to connect, it is safe to reset the custom env and force logout
      // because the user will not be able to use the app without a working custom env.
      thunkApi.dispatch(resetCustomEnvironment({ removeSelectedEnv: true }));
      getConfigService().setDefaultEnvironment();
      thunkApi.dispatch(logout());
      return false;
    }

    return true;
  },
);

export const checkCustomEnvironment = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/checkCustomEnvironment`,
  async (payload: CustomEnvPayload | undefined, thunkApi) => {
    try {
      const result = await getConfigService().checkCustomEnvironmentExists(
        payload?.selectedEnv,
      );
      return result;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const setUseCustomEnvironment = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/setUseCustomEnvironment`,
  async (useCustomEnvironment: boolean, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const useCustomEnv = state.settings.useCustomEnvironment;

    try {
      if (useCustomEnvironment) {
        thunkApi.dispatch(logout());

        return await getConfigService().setCustomEnvironment(
          false,
          state.settings.selectedCustomEnv,
        );
      } else {
        // If currently already in production env, no need to reset the env
        if (!useCustomEnv) {
          getConfigService().setDefaultEnvironment();
          return useCustomEnv;
        }

        thunkApi.dispatch(logout());
        thunkApi.dispatch(checkCustomEnvironment());
        getConfigService().setDefaultEnvironment();
      }
      return useCustomEnvironment;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

export const resetEnvironment = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/resetEnvironment`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    try {
      if (state.settings.useCustomEnvironment) {
        const result = await getConfigService().setCustomEnvironment(
          false,
          state.settings.selectedCustomEnv,
        );
        return result;
      } else {
        await getConfigService().setDefaultEnvironment();
        return true;
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

const settingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState: initialState,
  reducers: {
    setShowServerErrors: (state, action: { payload: boolean }) => {
      state.showServerErrors = action.payload;
      setShowServerErrors(action.payload);
    },
    setStickyErrorMessages: (state, action: { payload: boolean }) => {
      state.stickyErrorMessages = action.payload;
      setStickyErrorMessages(action.payload);
    },
    setScreenTimeAppChosen: (state, action: { payload: boolean }) => {
      state.screnTimeAppChosen = action.payload;
    },
    setTrackingPermission: (state, action: { payload: string }) => {
      state.trackingPermission = action.payload;
    },
    setDeveloperMode: (state, action: { payload: boolean }) => {
      state.developerMode = action.payload;
    },
    resetCustomEnvironment: (
      state,
      action: { payload: ResetCustomEnvironmentPayload },
    ) => {
      state.shouldCheckCustomEnvOnAppLoad = false;
      state.useCustomEnvironment = false;
      state.showCustomEnvironmentSwitch = false;

      if (action?.payload?.removeSelectedEnv) {
        state.selectedCustomEnv = undefined;
        state.selectedPrNumber = undefined;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(checkCustomEnvironment.fulfilled, (state, action) => {
      const requestArgs = action.meta.arg;

      if (!state.useCustomEnvironment) {
        state.showCustomEnvironmentSwitch = action.payload ?? false;
      }

      if (requestArgs?.selectedPrNumber && action.payload) {
        state.showCustomEnvironmentSwitch = action.payload ?? false;
        state.selectedPrNumber = requestArgs.selectedPrNumber;
        state.selectedCustomEnv = requestArgs.selectedEnv;
      }
    });

    builder.addCase(setUseCustomEnvironment.fulfilled, (state, action) => {
      state.useCustomEnvironment = action.payload ?? false;
    });

    // On rehydrate: should be called once to resolve the actions of persisted values
    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      if (action.key === PERSIST_KEY) {
        const payload = action.payload as State | undefined;

        if (!payload) {
          return;
        }

        if (payload.useCustomEnvironment) {
          if (isProductionEnv() && !payload.selectedCustomEnv) {
            state.useCustomEnvironment = false;
          } else {
            if (payload.selectedCustomEnv && payload.selectedPrNumber) {
              state.shouldCheckCustomEnvOnAppLoad = true;
              state.showCustomEnvironmentSwitch = true;
            }
          }
        }

        setUtilsShowServerErrors(payload.showServerErrors);
        setUtilsStickyErrorMessages(payload.stickyErrorMessages);
      }
    });
  },
});

export const {
  setShowServerErrors,
  setStickyErrorMessages,
  setScreenTimeAppChosen,
  setTrackingPermission,
  setDeveloperMode,
  resetCustomEnvironment,
} = settingsSlice.actions;

export const SettingsSlice = persistReducer(
  persistConfig,
  settingsSlice.reducer,
);
