import { useIsFocused } from '@react-navigation/core';
import { useEffect } from 'react';

import { useAppDispatch } from '../../../App/store';
import { useRefWithSubscribe } from '../../../Common/services/hooks';
import { countLearnModeTimeSpent, finalizeLearnModeTimeSpent } from '../slices';

export const useFeedTimeSpentCounter = (courseId?: string): void => {
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const isFocusedRef = useRefWithSubscribe(isFocused);
  const courseIdRef = useRefWithSubscribe(courseId);

  useEffect(() => {
    if (!isFocused && courseIdRef.current) {
      dispatch(finalizeLearnModeTimeSpent(courseIdRef.current));
    }
  }, [isFocused, dispatch, courseIdRef]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isFocusedRef.current || !courseIdRef.current) {
        return;
      }

      dispatch(countLearnModeTimeSpent(courseIdRef.current));
    }, 1000);

    return () => clearInterval(interval);
  }, [isFocusedRef, courseIdRef, dispatch]);
};
