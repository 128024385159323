import { appBucketApi } from '../../../App/services/requests';
import { PaywallCharacterResponse } from '../../entities';

export const fetchCoursePaywallCharactersCall = async (): Promise<
  PaywallCharacterResponse[]
> => {
  // Using seed to avoid caching
  const response = await appBucketApi.get(
    'paywall/paywall-characters.json?seed=' + Date.now(),
  );
  return response.data;
};
