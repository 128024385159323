import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Sizes, isWebPlatform } from '../utils/AppConstants';

type SafeAreaProps = {
  tabBarHeightWithSafeArea: number;
  topHeaderSafeAreaHeight: number;
  safeAreaBottom: number;
  safeAreaTop: number;
};

export const useSafeAreaCustomInsets = (): SafeAreaProps => {
  const insets = useSafeAreaInsets();

  const tabBarHeightWithSafeArea =
    Platform.select({
      ios: Sizes.tabs.height + (!isWebPlatform ? insets.bottom : 0),
      android: Sizes.tabs.height,
      web: Sizes.tabs.height,
    }) ?? 0;

  const topHeaderSafeAreaHeight =
    Platform.select({
      ios: Sizes.header.height + (!isWebPlatform ? insets.top : 0),
      android: Sizes.header.height,
      web: Sizes.header.height,
    }) ?? 0;

  const safeAreaBottom = isWebPlatform ? 0 : insets.bottom;
  const safeAreaTop = isWebPlatform ? 0 : insets.top;

  return {
    tabBarHeightWithSafeArea,
    topHeaderSafeAreaHeight,
    safeAreaBottom,
    safeAreaTop,
  };
};
