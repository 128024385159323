import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { CommonPeriodType } from '../../Common/entities';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';

export const SATProgramExamDate = (): React.ReactElement | null => {
  const bootcamp = useAppSelector(state => state.SAT.bootcamp);
  const doesBootcampExists = useMemo(() => Boolean(bootcamp), [bootcamp]);
  const isSATFreeTrialEnded = useAppSelector(
    state => state.SAT.isFreeTrialEnded,
  );

  const durationText = useMemo(() => {
    if (!doesBootcampExists) {
      return '';
    }

    const periodDuration =
      bootcamp?.details?.durationPeriod === CommonPeriodType.WEEK
        ? locale.common.week
        : locale.common.day;
    const durationLength = bootcamp?.details?.durationLength || 0;
    const currentDuration = bootcamp?.details?.currentDuration || 0;

    return `${periodDuration} ${currentDuration}/${durationLength}`;
  }, [doesBootcampExists, bootcamp]);

  const currentDurationText = useMemo(() => {
    if (bootcamp?.details?.durationPeriod !== CommonPeriodType.WEEK) {
      return '';
    }

    const startDateString = moment(
      bootcamp?.details?.currentDurationStartDate,
      'YYYY-MM-DD',
    ).format('MMM D');
    const endDateString = moment(
      bootcamp?.details?.currentDurationEndDate,
      'YYYY-MM-DD',
    ).format('MMM D');

    return locale.sat.commitment_duration_dates
      .replace('${START_DATE}', startDateString)
      .replace('${END_DATE}', endDateString);
  }, [bootcamp?.details]);

  const shouldShowCurrentDuration = useMemo(
    () => Boolean(currentDurationText),
    [currentDurationText],
  );

  const examDateText = useMemo(() => {
    if (!bootcamp?.details?.examDate) {
      return '';
    }

    return moment(bootcamp.details.examDate, 'YYYY-MM-DD').format('MMMM D');
  }, [bootcamp]);

  if (!doesBootcampExists) {
    return null;
  }

  return (
    <Text style={styles.mainText}>
      {isSATFreeTrialEnded ? (
        <Text style={styles.strong}>{locale.sat.trial_ended}</Text>
      ) : (
        <>
          <Text style={styles.strong}>{durationText}</Text>
          {shouldShowCurrentDuration && (
            <Text style={styles.text}>{currentDurationText}</Text>
          )}
        </>
      )}
      <Text style={styles.text}>{locale.sat.sat_program_date}</Text>

      <Text style={styles.strong}>{examDateText}</Text>
    </Text>
  );
};

const styles = StyleSheet.create({
  mainText: {
    textAlign: 'center',
  },
  text: {
    ...Fonts.small,
    color: Colors.white70,
  },
  strong: {
    ...Fonts.smallBold,
    color: Colors.white70,
  },
});
