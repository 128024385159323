import { createAsyncThunk } from '@reduxjs/toolkit';

import { finalizeApTestPrepTimeSpent } from '../../../../AP/services/slices';
import { RootState } from '../../../../App/store';
import { isAPCourse, isSATCourse } from '../../../../Common/services/utils';
import { mapCourseDataById } from '../../../../Learn/services/mappers';
import { recordSATTestPrepTimeSpent } from '../../../../SAT/services/slices';
import { TEST_PREP_SLICE_NAME } from '../TestPrepSlice';

export const finalizeTestPrepTimeSpent = createAsyncThunk(
  `${TEST_PREP_SLICE_NAME}/finalizeTestPrepTimeSpent`,
  (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const courseData = mapCourseDataById(rootState, courseId);

    if (!courseData?.id) {
      return;
    }

    if (isAPCourse(courseData)) {
      thunkAPI.dispatch(finalizeApTestPrepTimeSpent(courseData.id));
      return;
    }

    if (isSATCourse(courseData)) {
      thunkAPI.dispatch(recordSATTestPrepTimeSpent());
      return;
    }
  },
);
