import { Colors } from '../../Common/services/utils/AppConstants';

export const AP_GRADES = ['1', '2', '3', '4', '5'];
export const HS_GRADES = ['F', 'D', 'C', 'B', 'A'];
export const GRADES_COLORS = [
  Colors.white16,
  Colors.white16,
  Colors.sliceLightGreen,
  Colors.sliceGreen,
  Colors.emeraldGreen,
];

export const AP_STEPS = ['3', '4', '5'];
export const HS_STEPS = ['C', 'B', 'A'];

export const MILESTONE_BONUS_POINTS = '500';
