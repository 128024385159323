import {
  Course,
  PaywallEntryPoint,
  ProgramAccessType,
} from '../../../Common/entities';
import { isSATCourse } from '../../../Common/services/utils';
import { BootcampEntryPoint } from '../../../Onboarding/entities';
import { SATBootcamp } from '../../../SAT/entities';
import { CourseTagType } from '../../entities';

export const getCourseTag = (
  course?: Course | null,
  isLocked?: boolean,
  bootcampDetails?: SATBootcamp,
  hasSATPrepAddon?: boolean,
): CourseTagType => {
  if (!course) {
    return CourseTagType.NONE;
  }

  if (isSATCourse(course) && hasSATPrepAddon) {
    return CourseTagType.PURCHASED;
  }

  if (isSATCourse(course) && bootcampDetails) {
    if (bootcampDetails.accessDetails.accessType === ProgramAccessType.TRIAL) {
      return CourseTagType.FREETRIAL;
    } else if (
      bootcampDetails.accessDetails.accessType === ProgramAccessType.FULL_ACCESS
    ) {
      return CourseTagType.PURCHASED;
    } else {
      return CourseTagType.NONE;
    }
  }

  if (!isLocked && !isSATCourse(course)) {
    return CourseTagType.PURCHASED;
  }

  return CourseTagType.NONE;
};

export const getBootcampEntryPointForPaywall = (
  entryPoint?: PaywallEntryPoint,
): BootcampEntryPoint => {
  if (entryPoint === PaywallEntryPoint.FOLLOW_COURSE_HOME) {
    return BootcampEntryPoint.FOLLOW_COURSE_HOME;
  } else if (entryPoint === PaywallEntryPoint.FOLLOW_COURSE_PROFILE) {
    return BootcampEntryPoint.FOLLOW_COURSE_PROFILE;
  }
  return BootcampEntryPoint.COURSE_PICKER;
};

export const getPaywallEntryPointFromBootcamp = (
  entryPoint?: BootcampEntryPoint,
): PaywallEntryPoint => {
  if (entryPoint === BootcampEntryPoint.FOLLOW_COURSE_HOME) {
    return PaywallEntryPoint.FOLLOW_COURSE_HOME;
  } else if (entryPoint === BootcampEntryPoint.FOLLOW_COURSE_PROFILE) {
    return PaywallEntryPoint.FOLLOW_COURSE_PROFILE;
  } else if (entryPoint === BootcampEntryPoint.COURSE_PICKER) {
    return PaywallEntryPoint.COURSE_PICKER;
  } else if (entryPoint === BootcampEntryPoint.AP_RESULTS) {
    return PaywallEntryPoint.AP_RESULTS;
  } else if (entryPoint === BootcampEntryPoint.AP_ONBOARDING) {
    return PaywallEntryPoint.AP_ONBOARDING;
  } else if (entryPoint === BootcampEntryPoint.LOCKED_AP_COURSE) {
    return PaywallEntryPoint.LOCKED_AP_COURSE;
  }
  return PaywallEntryPoint.SAT_BOOTCAMP_INTRO;
};
