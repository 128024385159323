import React, { useCallback, useMemo } from 'react';
import { Alert, StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../../App/locale';
import { useAppNavigation, useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import {
  CommonBackButton,
  CommonCloseButton,
} from '../../../Common/components';
import { useBottomTabNavigator } from '../../../Common/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { useBootcampCoursePicker } from '../../../Onboarding/services/hooks';
import { mapIsUserEligibleForSatBootcampDiscount } from '../../../Onboarding/services/mappers';
import { rejectSATBootcampUpsellFlow } from '../../../Onboarding/services/slices';
import { CompetitorLogoIcons } from '../../services/utils/PaywallUtils';

export const BootcampPaywallHeader = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const bottomTabNavigator = useBottomTabNavigator();
  const navigation = useAppNavigation();

  const programDetails = useAppSelector(
    state => state.onboarding.SATProgramDetails,
  );
  const isAuthenticated = useAppSelector(
    state => Boolean(state.auth.authUser?.id),
    (prev, next) => prev === next,
  );
  const fromSignupFlow = useAppSelector(
    state => state.onboarding.doOnboardingFlowAfterSignup,
    (prev, next) => prev === next,
  );

  const shouldUseDiscount = useAppSelector(
    state => mapIsUserEligibleForSatBootcampDiscount(state),
    (prev, next) => prev === next,
  );

  const { handleCloseButtonPress, isBootcampFromCoursePicker } =
    useBootcampCoursePicker();

  const isLoading = useAppSelector(
    state =>
      state.purchases.isLoading ||
      state.bootcampPaywall.isLoading ||
      state.onboarding.isLoading,
    (prev, next) => prev === next,
  );

  const showCloseButton = useMemo(
    () => isAuthenticated && !fromSignupFlow,
    [isAuthenticated, fromSignupFlow],
  );

  const content = useMemo(() => {
    return {
      title: programDetails?.programName || 'N/A',
      headline: locale.bootcamp_paywall.headline.replace(
        '${SCORE}',
        programDetails?.targetScore?.toString() ?? 'N',
      ),
      duration: programDetails?.programDuration || 'N/A',
      dedication: programDetails?.studyCommitmentHours.toString() || 'N',
    };
  }, [programDetails]);

  const handleCloseButton = useCallback(() => {
    Alert.alert(
      locale.bootcamp_paywall.miss_out_heading,
      locale.bootcamp_paywall.miss_out_description,
      [
        {
          text: locale.bootcamp_paywall.go_back,
          style: 'cancel',
        },
        {
          text: locale.bootcamp_paywall.skip_offer,
          style: 'destructive',
          onPress: () => {
            if (shouldUseDiscount) {
              dispatch(rejectSATBootcampUpsellFlow());
            }
            bottomTabNavigator.navigate();
          },
        },
      ],
    );
  }, [bottomTabNavigator, dispatch, shouldUseDiscount]);

  const onCloseButtonPress = useCallback(() => {
    if (isBootcampFromCoursePicker) {
      handleCloseButtonPress();
    } else {
      handleCloseButton();
    }
  }, [handleCloseButton, handleCloseButtonPress, isBootcampFromCoursePicker]);

  return (
    <View style={styles.header}>
      <CommonBackButton
        onPress={navigation.goBack}
        containerStyle={styles.backButtonContainer}
      />

      <FastImage
        style={styles.logo}
        source={{
          uri: CompetitorLogoIcons.thumbnail,
        }}
      />
      <Text style={styles.title}>{content.title}</Text>
      <Text style={styles.mainHeading}>{content.headline}</Text>
      <Text style={styles.subheading}>
        {locale.bootcamp_paywall.description}
      </Text>

      {showCloseButton && (
        <CommonCloseButton
          onPress={onCloseButtonPress}
          size={Sizes.medium}
          disabled={isLoading}
          mainContainerStyle={styles.closeButtonContainer}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
  },
  logo: {
    width: 40,
    height: 40,
  },
  title: {
    ...Fonts.small,
    color: Colors.emeraldGreen,
    marginTop: 10,
  },
  mainHeading: {
    ...Fonts.xxxlargeBold,
    marginTop: 2,
  },
  subheading: {
    ...Fonts.semiMedium,
    textAlign: 'center',
    marginTop: 2,
    color: Colors.white50,
  },

  backButtonContainer: {
    zIndex: 30,
    position: 'absolute',
    left: 0,
    top: Sizes.small,
  },

  closeButtonContainer: {
    right: 0,
    top: Sizes.small,
  },
});
