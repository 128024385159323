import { useEffect, useMemo, useState } from 'react';

import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { fetchSubtitleDataOneTime } from '../../../Learn/services/slices';
import {
  VideoSubtitlesDataParams,
  VideoSubtitlesDataType,
} from '../../entities';
import { addMediaProgressEventListener } from '../../events';
import {
  getSubtitleTextForPosition,
  parseSubtitleStringToData,
} from '../utils';

import { useRefWithSubscribe } from './RefWithSubscribe';

export const useVideoSubtitlesData = (
  params: VideoSubtitlesDataParams,
): VideoSubtitlesDataType => {
  const dispatch = useAppDispatch();

  const [currentSubtitle, setCurrentSubtitle] = useState('');
  const uniqueIdRef = useRefWithSubscribe(params.uniqueId);

  const rawSubtitles = useAppSelector(
    state => state.questions.subtitleData[params.subtitleUrl ?? ''],
    (prev, next) => prev === next,
  );
  const subtitleData = useMemo(
    () => parseSubtitleStringToData(rawSubtitles),
    [rawSubtitles],
  );
  const subtitleDataRef = useRefWithSubscribe(subtitleData);

  const subtitlesShown = useAppSelector(
    state => state.questions.subtitlesShown,
    (prev, next) => prev === next,
  );

  const hideSubtitle = useMemo(() => {
    return (
      !subtitlesShown ||
      !params.subtitleUrl ||
      !currentSubtitle ||
      !subtitleData.length
    );
  }, [subtitleData, subtitlesShown, currentSubtitle, params.subtitleUrl]);

  useEffect(() => {
    if (params.uniqueId || params.subtitleUrl) {
      setCurrentSubtitle('');
    }
  }, [params.uniqueId, params.subtitleUrl]);

  useEffect(() => {
    if (params.subtitleUrl) {
      dispatch(fetchSubtitleDataOneTime(params.subtitleUrl));
    }
  }, [dispatch, params.subtitleUrl]);

  useEffect(() => {
    const unsubscribe = addMediaProgressEventListener(e => {
      if (e.uniqueId !== uniqueIdRef.current) {
        return;
      }

      if (subtitleDataRef.current.length) {
        setCurrentSubtitle(
          getSubtitleTextForPosition(subtitleDataRef.current, e.progress),
        );
      } else {
        setCurrentSubtitle('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, [uniqueIdRef, subtitleDataRef]);

  return {
    hideSubtitle,
    currentSubtitle,
  };
};
