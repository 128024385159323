import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets, { Asset } from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

export enum TestPrepBannerType {
  warning = 'warning',
}

type TestPrepBannerProps = {
  icon: Asset;
  text: string;
  type: TestPrepBannerType;
};

export const TestPrepBanner: React.FC<TestPrepBannerProps> = ({
  icon,
  text,
  type,
}) => (
  <View style={[styles.bannerContainer, styles[type]]}>
    <AssetImage
      asset={icon}
      width={Sizes.medium}
      height={Sizes.medium}
      imageStyle={styles.icon}
    />
    <Text style={styles.bannerText}>{text}</Text>
  </View>
);

const styles = StyleSheet.create({
  bannerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: Sizes.small,
    borderRadius: Sizes.small,
    gap: Sizes.xsmall,
  },
  icon: {
    width: Sizes.medium,
    height: Sizes.medium,
    color: Colors.whiteCC,
  },
  bannerText: {
    ...Fonts.small,
    color: Colors.whiteCC,
    textAlign: 'center',
  },
  warning: {
    backgroundColor: Colors.lightYellow50,
  },
});

export const TimesUpTestPrepBanner = React.memo((): React.ReactElement => {
  return (
    <TestPrepBanner
      icon={Assets.test.timesUpHourglass}
      text={locale.testPrep.test_ended_due_to_timeout}
      type={TestPrepBannerType.warning}
    />
  );
});
