import locale from '../../App/locale';
import { Course, Topic, Unit } from '../../Common/entities';
import { AP_STEPS, HS_STEPS } from '../data';
import {
  AveragePercentageAndGrade,
  GetStudyGradeFromStepType,
  GetTopicsPercentagePerGrade,
} from '../entities';
import {
  mapGradeToNumber,
  mapGradeToNumberIndex,
  mapNumberIndexToGrade,
} from '../services/utils';

export class ProgressCalculationAlgorithm {
  static roundWithAdjustment = (value: number, adjustment: number): number => {
    return Math.round(value + adjustment);
  };

  static getStudyGradeFromStep = (step: string): GetStudyGradeFromStepType => {
    switch (step) {
      case '5':
      case 'A':
        return {
          grade: locale.study.expert,
          gradeMasteryText: locale.study.expert_mastery,
          stars: 3,
        };

      case '4':
      case 'B':
        return {
          grade: locale.study.advanced,
          gradeMasteryText: locale.study.advanced_mastery,
          stars: 2,
        };
      case '3':
      case 'C':
        return {
          grade: locale.study.intermediate,
          gradeMasteryText: locale.study.intermediate_mastery,
          stars: 1,
        };
      default:
        return {
          grade: locale.common.unknown,
          gradeMasteryText: locale.study.unknown_mastery,
          stars: 0,
        };
    }
  };

  static getTopicsPercentagePerGrade = (
    course: Course | null,
  ): GetTopicsPercentagePerGrade[] => {
    const isAPCourse = course?.grade?.includes('AP') || false;

    const topics = course?.units.flatMap(unit => unit.topics) ?? [];
    const topicsLength = topics.length || 1;

    const steps = isAPCourse ? AP_STEPS : HS_STEPS;

    let adjustment = 0;

    const resultsWithoutUnknown = steps.map(step => {
      const topicsWithGrade = topics.filter(topic => topic.grade === step);
      const percentage = (topicsWithGrade.length / topicsLength) * 100;

      const roundedPercentage =
        ProgressCalculationAlgorithm.roundWithAdjustment(
          percentage,
          adjustment,
        );
      adjustment += percentage - roundedPercentage;

      const { grade, stars } =
        ProgressCalculationAlgorithm.getStudyGradeFromStep(step);

      return {
        label: locale.study.topics_with_grade_stat
          .replace('${PERCENT}', roundedPercentage.toString())
          .replace('${GRADE}', grade),
        step,
        percentage: roundedPercentage,
        numberOfStars: stars,
      };
    });

    const totalPercentage = resultsWithoutUnknown.reduce(
      (acc, result) => acc + result.percentage,
      0,
    );
    const unknownResultPercentage = 100 - totalPercentage;

    const unknownResult = {
      label: locale.study.topics_with_unknown_stat.replace(
        '${PERCENT}',
        unknownResultPercentage.toString(),
      ),
      step: locale.common.unknown,
      percentage: unknownResultPercentage,
    };

    return [...resultsWithoutUnknown, unknownResult];
  };

  static getAveragePercentageAndGrade(
    course: Course,
  ): AveragePercentageAndGrade {
    const isApCourse = course.grade.includes('AP');

    const topics = course.units.flatMap(unit => unit.topics);
    const topicsLength = topics.length || 1;
    const topicsWithGrade = topics.filter(topic => !!topic.grade);

    if (!topicsWithGrade.length) {
      return {
        percentage: 0,
        grade: locale.common.unknown,
      };
    }

    const percentage = topicsWithGrade.length / topicsLength;

    const numericGradesTotal = topicsWithGrade.reduce(
      (acc, topic) => acc + mapGradeToNumberIndex(topic.grade as string) + 1,
      0,
    );

    const averageNumericGrade = Math.round(
      numericGradesTotal / topicsWithGrade.length,
    );

    const gradeResult = mapNumberIndexToGrade(
      averageNumericGrade - 1,
      isApCourse,
    );

    return {
      percentage,
      grade: gradeResult,
    };
  }

  static calculateLowerGrade = (unit: Unit): Topic => {
    return unit.topics.reduce((acc, topic) => {
      if (acc) {
        // if there is an accumulator, compare it with the current topic
        if (!acc.grade) {
          // if the accumulator is not graded, return it
          return acc;
        } else if (!topic.grade) {
          // if the current topic is not graded, return it
          return topic;
        } else {
          // if both are graded, compare them
          const accNumericGrade = mapGradeToNumber(acc.grade);
          const topicNumericGrade = mapGradeToNumber(topic.grade);

          if (accNumericGrade < topicNumericGrade) {
            return acc;
          } else if (accNumericGrade > topicNumericGrade) {
            return topic;
          } else {
            if (topic.progress > acc.progress) {
              return acc;
            } else {
              return topic;
            }
          }
        }
      } else {
        // if there is no accumulator, return the current topic
        return topic;
      }
    });
  };

  static isGradeProgressing = (
    existingGrade?: string,
    grade?: string,
  ): boolean => {
    if (!grade) {
      return false;
    }

    if (!existingGrade) {
      return true;
    }

    const mappedExistingGrade = mapGradeToNumberIndex(existingGrade);
    const mappedGrade = mapGradeToNumberIndex(grade);

    return mappedGrade > mappedExistingGrade;
  };

  static isGradeDecreasing = (
    existingGrade?: string,
    grade?: string,
  ): boolean => {
    if (!existingGrade) {
      return false;
    }

    if (!grade) {
      return true;
    }

    const mappedExistingGrade = mapGradeToNumberIndex(existingGrade);
    const mappedGrade = mapGradeToNumberIndex(grade);

    return mappedGrade < mappedExistingGrade;
  };

  static hasUserProgressedInCourse = (course: Course): boolean => {
    return course.units.some(unit =>
      unit.topics.some(topic => topic.progress > 0 || !!topic.grade),
    );
  };
}
