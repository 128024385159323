import React from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { Fonts } from '../../../Common/services/utils/AppConstants';

type Props = {
  backgroundColor: string;
  color: string;
  viewStyle?: ViewStyle;
  textStyle?: TextStyle;
  dashWidth?: number;
};

export const GradeWithDash = (props: Props): React.ReactElement => {
  const { backgroundColor, color, viewStyle, textStyle, dashWidth } = props;

  return (
    <View style={[styles.circle, { backgroundColor }, viewStyle]}>
      <AssetImage
        width={dashWidth || 30}
        asset={Assets.test.dashGrade}
        containerStyle={[styles.text, { color }, textStyle]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  circle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    ...Fonts.largeBold,
  },
});
