import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity, View, ViewStyle } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useBootcampCoursePicker } from '../../Onboarding/services/hooks';
import { Colors, Sizes } from '../services/utils/AppConstants';

type Props = {
  onPress: () => void;
  style?: ViewStyle;
  size?: number;
  disabled?: boolean;
  mainContainerStyle?: ViewStyle;
};

export const CommonCloseButton = (props: Props): React.ReactElement => {
  const buttonSize = useMemo(() => props.size || Sizes.xlarge, [props.size]);

  const mainContainerStyle = useMemo(() => {
    return [styles.mainContainer, props.mainContainerStyle];
  }, [props.mainContainerStyle]);

  const buttonStyle = useMemo(() => {
    return [styles.container, props.style];
  }, [props.style]);

  return (
    <View style={mainContainerStyle}>
      <TouchableOpacity
        style={buttonStyle}
        onPress={props.onPress}
        disabled={props.disabled}>
        <AssetImage
          asset={Assets.home.closeItemCrossIcon}
          width={buttonSize}
          fill="white"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white24,
    padding: Sizes.small,
    borderRadius: Sizes.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainContainer: {
    position: 'absolute',
    top: Sizes.safeAreaInsetsTop + Sizes.small,
    right: Sizes.large,
  },

  onboardingNavMainContainer: {
    position: 'relative',
    top: 0,
    right: Sizes.small,
  },
});

const CommonCloseButtonForOnboarding = React.memo((): React.ReactElement => {
  const { handleCloseButtonPress } = useBootcampCoursePicker();

  return (
    <CommonCloseButton
      onPress={handleCloseButtonPress}
      size={Sizes.medium}
      mainContainerStyle={styles.onboardingNavMainContainer}
    />
  );
});

export const renderCommonCloseButtonForOnboarding = (): React.ReactElement => {
  return <CommonCloseButtonForOnboarding />;
};
