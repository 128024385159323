import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { TrackedUserEventType, TrackUserEventAction } from '../../../entities';
import {
  throttledTrackUserEventGraphQLCall,
  trackUserEventGraphQLCall,
} from '../../../graphql';
import { TRACK_USER_EVENT_SLICE_NAME } from '../TrackUserEventSlice';

export const trackUserEvent = createAsyncThunk(
  `${TRACK_USER_EVENT_SLICE_NAME}/trackUserEvent`,
  async (action: TrackUserEventAction, thunkApi) => {
    const attributes: { name: string; value: string }[] = [];

    if (action.courseId) {
      attributes.push({ name: 'courseId', value: action.courseId });
    }

    if (action.unitId) {
      attributes.push({ name: 'unitId', value: action.unitId });
    }

    try {
      if (action.eventType === TrackedUserEventType.SessionInitiated) {
        throttledTrackUserEventGraphQLCall({
          eventType: action.eventType,
          attributes,
        });
      } else {
        await trackUserEventGraphQLCall({
          eventType: action.eventType,
          attributes,
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);
