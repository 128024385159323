import { RootState } from '../../../App/store';
import {
  isAPCourse,
  isHighSchoolCourse,
  isSATCourse,
} from '../../../Common/services/utils';
import { mapCourseDataById } from '../../../Learn/services/mappers';
import { mapSATUserProgramDetailsAvailableStatus } from '../../../SAT/services/mappers';
import { mapCourseLockedStatus } from '../../../UserAccess/services/mappers';

export const mapTestPrepDefaultTabSelectorStatus = (
  state: RootState,
): boolean => {
  const selectedCourse = state.courseEnrollments.selectedCourse;
  const hasSatBootcamp = mapSATUserProgramDetailsAvailableStatus(state);

  if (!selectedCourse?.id) {
    return hasSatBootcamp;
  }
  const courseData = mapCourseDataById(state, selectedCourse?.id);
  if (!courseData?.id) {
    return hasSatBootcamp;
  }

  if (isHighSchoolCourse(courseData)) {
    return false;
  }

  const isCourseLocked = mapCourseLockedStatus(state, courseData.id);
  if (isAPCourse(courseData) && !isCourseLocked) {
    return false;
  }

  if (isSATCourse(courseData) && !isCourseLocked) {
    return true;
  }

  return false;
};
