import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  EnrollCourseRequest,
  EnrollmentCourseResponse,
  UnenrollCoursePayload,
} from '../../entities';

export const enrollCourseGraphQLCall = async (
  props: EnrollCourseRequest,
): Promise<EnrollmentCourseResponse> => {
  const { courseId, goal } = props;

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        courseId,
        goal,
      },
      query: `
        mutation TeachTapEnrollUserToCourse($courseId: String!, $goal: CourseGoal) {
          teachTapEnrollUserToCourse(input: {courseId: $courseId, goal: $goal}) {
            success
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const unenrollUserFromCourseGraphQLCall = async (
  props: UnenrollCoursePayload,
): Promise<EnrollmentCourseResponse> => {
  const { courseId } = props;

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        courseId,
      },
      query: `
        mutation TeachTapUnenrollUserFromCourse($courseId: String!) {
          teachTapUnenrollUserFromCourse(input: {courseId: $courseId}) {
            success
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data?.teachTapUnenrollUserFromCourse;
};
