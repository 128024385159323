import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Dimensions,
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useMediaQuery } from 'react-responsive';

import Assets from '../../App/assets';
import { useRefWithSubscribe } from '../../Common/services/hooks';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { renderLatexTextView } from '../../SAT/components';
import { SATStimulusType } from '../../SAT/entities';
import { TestPrepQuestion } from '../entities';

type Props = {
  currentQuestion: TestPrepQuestion;
  shouldUseMarkdownLatex: boolean;
};

export const TestPrepFlowQuestion = ({
  currentQuestion,
  shouldUseMarkdownLatex,
}: Props): React.ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [questionContainerHeight, setQuestionContainerHeight] = useState(0);
  const [managedId, setManagedId] = useState(
    currentQuestion.generatedContentId,
  );
  const [maxQuestionContainerHeight, setMaxQuestionContainerHeight] =
    useState(0);

  const questionScrollViewRef = useRef<ScrollView>(null);
  const managedIdRef = useRefWithSubscribe(managedId);

  const onTextLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    if (height > maxQuestionContainerHeight) {
      setQuestionContainerHeight(maxQuestionContainerHeight);
      setIsExpandable(true);
    } else {
      setQuestionContainerHeight(height + 5);
      setIsExpandable(false);
    }
  };

  const handleExpandQuestion = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const shouldShowStimulus = useMemo(() => {
    return (
      !!currentQuestion?.stimulus?.text &&
      currentQuestion?.stimulus?.type === SATStimulusType.TEXT
    );
  }, [currentQuestion?.stimulus]);

  const shouldShowImageStimulus = useMemo(() => {
    return !!currentQuestion?.stimulusImage;
  }, [currentQuestion?.stimulusImage]);

  const expandQuestionContainerStyle = useMemo(() => {
    return { height: questionContainerHeight - 25 };
  }, [questionContainerHeight]);

  const isTouchScreenDevice = useMediaQuery({
    query: '(pointer: coarse)',
  });

  const shouldShowExpandableElement = useMemo(() => {
    return isExpandable && !isExpanded;
  }, [isExpandable, isExpanded]);

  useEffect(() => {
    const screenHeight = Dimensions.get('window').height;
    setMaxQuestionContainerHeight(screenHeight * 0.5);
  }, []);

  useEffect(() => {
    if (currentQuestion?.generatedContentId) {
      questionScrollViewRef.current?.scrollTo({ y: 0, animated: false });
      setIsExpanded(false);

      const isAnswerSelected = Boolean(currentQuestion?.chosenOption?.id);
      const shouldUseTimeout =
        managedIdRef.current === currentQuestion.generatedContentId;

      const selectedId = isAnswerSelected
        ? `${currentQuestion.generatedContentId}-answered`
        : currentQuestion.generatedContentId;

      if (isAnswerSelected && shouldUseTimeout) {
        if (shouldUseTimeout) {
          setTimeout(() => {
            setManagedId(selectedId);
          }, Durations.quickTransition);
        }
      } else {
        setManagedId(selectedId);
      }
    }
  }, [
    currentQuestion?.chosenOption?.id,
    currentQuestion.generatedContentId,
    managedIdRef,
  ]);

  const managedContent = useMemo(() => {
    if (shouldShowStimulus) {
      return `${currentQuestion.stimulus?.text}\n\n${currentQuestion.question}`;
    }

    return currentQuestion.question;
  }, [
    currentQuestion.question,
    currentQuestion?.stimulus?.text,
    shouldShowStimulus,
  ]);

  return (
    <View
      style={[styles.questionContainer, { height: questionContainerHeight }]}>
      <ScrollView
        ref={questionScrollViewRef}
        style={styles.scrollView}
        showsVerticalScrollIndicator={false}
        scrollEnabled={isExpandable}
        onScrollBeginDrag={handleExpandQuestion}>
        <View style={styles.questionTextContainer} onLayout={onTextLayout}>
          {shouldShowImageStimulus && (
            <img
              style={{
                ...(styles.questionImageWeb as React.CSSProperties),
              }}
              src={currentQuestion?.stimulusImage?.url}
            />
          )}
          {shouldUseMarkdownLatex ? (
            renderLatexTextView({
              identifier: managedId,
              content: managedContent,
              fontSize: Fonts.caption.fontSize,
            })
          ) : (
            <Text style={styles.questionText} onLayout={onTextLayout}>
              {shouldShowStimulus && (
                <Text
                  onLayout={onTextLayout}
                  style={[styles.questionText, styles.questionStimulusText]}>
                  {currentQuestion?.stimulus?.text + '\n\n'}
                </Text>
              )}
              {currentQuestion.question}
            </Text>
          )}
        </View>
      </ScrollView>
      {shouldShowExpandableElement && (
        <View style={[styles.expandContainer, expandQuestionContainerStyle]}>
          <LinearGradient
            colors={Colors.testPrepQuestionGradient}
            style={[styles.fadeOutEffect]}
          />
          {isTouchScreenDevice && (
            <View style={[styles.expandGif]}>
              <Image
                style={styles.gif}
                resizeMode={'cover'}
                source={
                  Assets.test.expandQuestionGif.path as ImageSourcePropType
                }
              />
            </View>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },

  questionContainer: {
    justifyContent: 'center',
    borderRadius: 8,
    backgroundColor: Colors.white10,
    overflow: 'hidden',
    position: 'relative',
  },
  questionTextContainer: {
    flex: 1,
    marginBottom: 20,
    paddingHorizontal: 15,
    paddingVertical: 12,
  },
  questionStimulusText: {
    paddingBottom: Sizes.medium,
  },
  questionText: {
    flex: 1,
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
  },

  expandContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    pointerEvents: 'none',
  },
  fadeOutEffect: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 36,
  },

  expandGif: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    paddingBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    transform: [{ translateX: -30 }],
    overflow: 'hidden',
  },
  gif: {
    width: Sizes.normalizeIP14PM(120),
    height: Sizes.normalizeIP14PM(60),
    opacity: 0.6,
  },
  questionImageWeb: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    marginBottom: Sizes.normalizeIP14PM(10),
    objectFit: 'contain',
  },
});
