import { AIContentType, ContentAttribute } from '../../../Common/entities';
import {
  PrefillThreshold,
  AskDMFigureRequest,
} from '../../../Messages/entities';
import {
  RaiseHandMessageType,
  RaiseHandUserInteractionHistory,
} from '../../entities';

export const mapRaiseHandAskRequest = (
  speakerPlatformId: string,
  contentGeneratorId: RaiseHandMessageType,
  generatedContentId: string,
  userInteractionHistory: RaiseHandUserInteractionHistory[] = [],
  question: string,
  courseId: string,
  levelId: string,
  prefillThreshold: PrefillThreshold,
  requestId?: string,
): AskDMFigureRequest => {
  const attributes: ContentAttribute[] = [
    {
      attributeName: 'userMessage',
      attributeValue: question,
    },
    {
      attributeName: 'generatedContentId',
      attributeValue: generatedContentId,
    },
    {
      attributeName: 'userInteractionHistory',
      attributeValue: JSON.stringify({ interactions: userInteractionHistory }),
    },
  ];

  return {
    contentGeneratorId: contentGeneratorId,
    contentTypeId: AIContentType.DMsFromTheDeadV2Text,
    courseId,
    levelId,
    attributes: attributes,
    isSyncGeneration: true,
    prefillThreshold,
    extendedAttributes: [
      {
        platformId: speakerPlatformId,
      },
    ],
    requestId,
  };
};
