import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import {
  MainStack,
  OnboardingStack,
} from '../../../../Common/services/utils/AppConstants';
import { OpenApProgramFlowParams } from '../../../entities';
import {
  AP_ONBOARDING_SLICE_NAME,
  ApOnboardingSliceState,
} from '../ApOnboardingSlice';
import { setBootcampEntryPoint } from '../OnboardingSlice';

export const openApProgramFlow = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/openApProgramFlow`,
  async (params: OpenApProgramFlowParams, thunkAPI) => {
    thunkAPI.dispatch(setBootcampEntryPoint(params.entrypoint));

    if (!RootNavigatorRef?.current?.isReady()) {
      return;
    }

    RootNavigatorRef.current?.navigate({
      name: MainStack.ONBOARDING_STACK,
      params: {
        screen: OnboardingStack.AP_COURSE_SELECTION,
      },
    });
  },
);

export const openApProgramFlowExtraReducers = (
  builder: ActionReducerMapBuilder<ApOnboardingSliceState>,
): void => {
  builder.addCase(openApProgramFlow.pending, (state, action) => {
    const args = action.meta.arg;
    state.selectedCourses = [args.courseName];
  });
};
