import locale from '../../App/locale';
import { SAT_UNIT_CATEGORY } from '../../Learn/entities';
import { SATAssets } from '../assets';
import { SATRowData } from '../entities';

export const SATInfoData = (satCategory: SAT_UNIT_CATEGORY): SATRowData[] => {
  return [
    {
      image: SATAssets.clockIcon,
      key: 'first_sat_info',
      text:
        satCategory === SAT_UNIT_CATEGORY.MATH
          ? locale.sat.first_sat_info_row_math
          : locale.sat.first_sat_info_row,
      size: 30,
    },
    {
      image:
        satCategory === SAT_UNIT_CATEGORY.MATH
          ? SATAssets.mathTutorAvatar
          : SATAssets.readingTutorAvatar,
      text: locale.sat.second_sat_info_row,
      key: 'second_sat_info',
      size: 30,
    },
    {
      image: SATAssets.taskListIcon,
      text: locale.sat.third_sat_info_row,
      key: 'third_sat_info',
      size: 30,
    },
  ];
};

export const SAT_MAX_TOTAL_SCORE = 1600;
export const SAT_MAX_CATEGORY_SCORE = 800;
