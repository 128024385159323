import {
  GetSATProgramDetailsRequest,
  GetSATProgramDetailsResponse,
} from './OnboardingRequestTypes';

export type SATBootcampProgramDetails = GetSATProgramDetailsRequest &
  GetSATProgramDetailsResponse;

export enum BootcampEntryPoint {
  AP_RESULTS = 'APResults',
  AP_ONBOARDING = 'APOnboarding',
  INTRO = 'intro',
  COURSE_PICKER = 'coursePicker',
  FOLLOW_COURSE_HOME = 'followCourseHome',
  FOLLOW_COURSE_PROFILE = 'followCourseProfile',
  SAT_BOOTCAMP_INTRO = 'SATBootcampIntro',
  AP_BOOTCAMP_INTRO = 'APBootcampIntro',
  UNCLAIMED_ENTITLEMENT = 'unclaimedEntitlement',
  LOCKED_AP_COURSE = 'lockedAPCourse',
  COURSE_PROFILE = 'courseProfile',
}

export type SATExamCountdownDuration = {
  duration: moment.Duration;
  isExpired: boolean;
};

export type SATExamDateTime = {
  dateTime: moment.Moment;
};

export enum OnboardingContainerEntryPoint {
  INTRO_VIDEO = 'introVideo',
  EXAM_SELECTION = 'examSelection',
}
