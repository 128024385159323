import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react-native';
import moment from 'moment';
import FastImage from 'react-native-fast-image';
import { PersistConfig, persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import {
  ErrorMessageType,
  handleFeedError,
  resetFeedErrorTime,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import {
  AIContentType,
  Card,
  CardAttributes,
  CardType,
  ContentCard,
  DmsFromDeadCard,
  FeedType,
  Topic,
} from '../../../Common/entities';
import {
  mapCardToAnalyticsPayload,
  mapGenerateQuestionResponseToContentCard,
} from '../../../Common/services/mappers';
import {
  commonAutoCorrectOnSimulator,
  commonExposeDevCardAnswer,
  getDefaultCardAttributes,
  isDevelopmentEnv,
  trackAnalyticsEvent,
} from '../../../Common/services/utils';
import {
  Analytics,
  isWebPlatform,
} from '../../../Common/services/utils/AppConstants';
import { GetTargetedPracticeLearningContentItemsRequest } from '../../../TestPrep/entities';
import { getTargetedPracticeLearningContentItemsGraphQLCall } from '../../../TestPrep/graphql';
import { CoursesAlgorithm } from '../../algorithms';
import {
  FeedIdPayload,
  FeedItemIdPayload,
  FeedItemPayload,
  GenerateQuestionParams,
  GenerateQuestionRequest,
  GenerateQuestionResponse,
  GenerateQuestionsStackParams,
  MatchingPairsSetVideoPayloadWithCard,
  PutCardToFeedPayload,
  SetSubtitleDataPayload,
  SyncAllAttributesPayload,
} from '../../entities';
import {
  generateQuestionGraphQLCall,
  getQuestionByIdGraphQLCall,
  recordContentViewGraphQLCall,
} from '../../graphql';
import { getSubtitleUrlsFromContentCard } from '../mappers';
import { getSubtitleDataRequest } from '../requests';
import { getKeyForLearningContentItems } from '../utils';

import { setQuestionPoints } from './AnswersSlice';
import { getAllCourses } from './CoursesSlice';

let requestController = new AbortController();

const INVALID_CONTENT_TYPE = 'invalid_content_type';

const MAX_QUESTIONS_CACHE_SIZE = 2;
const UNFOLD_QUESTION_TO_BLOCK_TAP_TO_REVEAL = 999999;
const TAP_TO_REVEAL_DELAY = 2000;

const PERSIST_KEY = 'feed';
export const FEED_SLICE_NAME = 'QuestionsSlice';
const ALL_FEED_IDS = Object.values(FeedType);

export const generateQuestionsStack = createAsyncThunk(
  `${FEED_SLICE_NAME}/generateQuestionsStack`,
  async (props: GenerateQuestionsStackParams, thunkApi) => {
    if (!props.topic && !props.levelIds) {
      return;
    }

    const rootState = thunkApi.getState() as RootState;
    const key = getKeyForLearningContentItems(
      props.feedId,
      props.unit?.id,
      props.topic?.id,
      props.levelIds,
    );

    const generating = rootState.questions.generatingRandomAIQuestions[key];
    if (generating) {
      return;
    }

    const feed = rootState.questions.feeds[props.feedId];
    const passedItems = rootState.questions.passedQuestions;

    let questionsToGenerate = MAX_QUESTIONS_CACHE_SIZE;
    for (let i = feed.length - 1; i > 0; i--) {
      const item = feed[i];
      if (item.generatedContentId && !passedItems[item.uniqueId]) {
        questionsToGenerate--;
      }

      if (questionsToGenerate <= 0) {
        return;
      }
    }

    try {
      thunkApi.dispatch(setGeneratingRandomAIQuestions({ key, value: true }));

      await thunkApi
        .dispatch(
          generateRandomAIQuestionsBatch({
            unit: props.unit,
            feedId: props.feedId,
            topic: props.topic,
            levelIds: props.levelIds,
            limit: MAX_QUESTIONS_CACHE_SIZE,
          }),
        )
        .unwrap();

      thunkApi.dispatch(setGeneratingRandomAIQuestions({ key, value: false }));
    } catch (e) {
      thunkApi.dispatch(setGeneratingRandomAIQuestions({ key, value: false }));
    }
  },
);

export const fetchSubtitleDataFromCard = createAsyncThunk(
  `${FEED_SLICE_NAME}/fetchSubtitleDataFromCard`,
  async (card: ContentCard, thunkApi) => {
    const subtitles = getSubtitleUrlsFromContentCard(card);
    if (!subtitles?.length) {
      return;
    }

    subtitles.forEach(url => {
      thunkApi.dispatch(fetchSubtitleDataOneTime(url));
    });
  },
);

export const fetchSubtitleDataOneTime = createAsyncThunk(
  `${FEED_SLICE_NAME}/fetchSubtitleData`,
  async (url: string | undefined, thunkApi) => {
    if (!url) {
      return;
    }

    const rootState = thunkApi.getState() as RootState;
    const localState = rootState.questions;
    if (localState.subtitleData[url]) {
      return;
    }

    try {
      const subtitleData = await getSubtitleDataRequest(url);
      if (subtitleData) {
        thunkApi.dispatch(setSubtitleData({ url: url, data: subtitleData }));
      }
    } catch (e) {
      return thunkApi.rejectWithValue(locale.errors.network_error);
    }
  },
);

export const syncAllAttributes = createAsyncThunk(
  `${FEED_SLICE_NAME}/syncAllAttributes`,
  async ({ attributes, card }: SyncAllAttributesPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const generatedContentId = card.generatedContentId;

    // Just point out something important, this is the example of using "multiple data-source".
    // Notice that we need to do "hacks" here to achieve the "sync" between the cards in multiple feeds,
    // which become challenging and tricky to maintain. Need to adjust this approach later once we have time.

    // Basically, we want to make sure that our some of the attributes are in sync
    // across all the feeds and other places where the card is visible.
    //
    // This is become super-important because we store the "State" in cards, not in independent Redux states.
    // Try to remove this implementation, you will see bugs between the card-feeds and bookmarks.

    if (card.type === CardType.DMs_FROM_THE_DEAD_CARD) {
      const dmsCard = card as DmsFromDeadCard;

      const cards = state.messages.dmsFromTheDead[dmsCard.character.username];
      cards.forEach(card => {
        if (card.generatedContentId === generatedContentId) {
          thunkApi.dispatch(
            setCardAttributes({
              card,
              attributes,
            }),
          );
        }
      });
    } else {
      ALL_FEED_IDS.forEach(feedId => {
        const feed = state.questions.feeds[feedId];
        const cardIndex = feed.findIndex(
          card => card.generatedContentId === generatedContentId,
        );
        if (cardIndex >= 0) {
          thunkApi.dispatch(
            setCardAttributes({
              card: feed[cardIndex],
              attributes,
            }),
          );
        }
      });
    }

    state.feedback.bookmarksList.forEach(card => {
      if (card.generatedContentId === generatedContentId) {
        thunkApi.dispatch(
          setCardAttributes({
            card,
            attributes,
          }),
        );
      }
    });

    state.feedback.likesList.forEach(card => {
      if (card.generatedContentId === generatedContentId) {
        thunkApi.dispatch(
          setCardAttributes({
            card,
            attributes,
          }),
        );
      }
    });
  },
);

export const getAICard = createAsyncThunk(
  `${FEED_SLICE_NAME}/getAICard`,
  async (payload: FeedItemIdPayload, thunkApi) => {
    try {
      const response = await getQuestionByIdGraphQLCall(payload.itemId);

      const state = thunkApi.getState() as RootState;

      const course = state.courses.coursesMap[response.courseId];
      const unit = course?.units?.find(unit => unit.id === response.unitId);
      const topic = unit?.topics?.find(topic => topic.id === response.topicId);
      const result = mapGenerateQuestionResponseToContentCard({
        feedId: payload.feedId,
        course,
        unit: unit!,
        topic: topic!,
        response,
      });
      thunkApi.dispatch(fetchSubtitleDataFromCard(result));

      thunkApi.dispatch(
        setQuestionPoints({
          id: result.uniqueId,
          points: result.points || 0,
        }),
      );

      return result;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        return thunkApi.rejectWithValue(error?.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const generateRandomAIQuestionsBatch = createAsyncThunk(
  `${FEED_SLICE_NAME}/generateAIQuestion`,
  async (props: GenerateQuestionParams, thunkApi) => {
    if (props.forceLoadCurriculum) {
      await thunkApi.dispatch(getAllCourses()).unwrap();
    }

    let state = thunkApi.getState() as RootState;
    const courses = Object.values(state.courses.coursesMap);

    let topic = props.topic as Topic;

    let request:
      | GenerateQuestionRequest
      | GetTargetedPracticeLearningContentItemsRequest
      | undefined;

    if (!topic && !props.levelIds) {
      let topic = CoursesAlgorithm.getRandomTopic(courses);
      if (!topic) {
        return thunkApi.rejectWithValue(locale.errors.no_topics);
      }
    }

    const course = props.unit
      ? CoursesAlgorithm.getCourseByUnit(courses, props.unit)
      : CoursesAlgorithm.getCourseByTopic(courses, topic);
    if (!course) {
      return thunkApi.rejectWithValue(locale.errors.course_not_found);
    }

    if (props.levelIds && props.unit) {
      request = {
        courseId: course.id,
        unitId: props.unit.id,
        levelIds: props.levelIds,
        batchSize: props.limit,
      };
    } else {
      request = {
        topicId: topic.id,
        courseId: course.id,
        batchSize: props.limit,
      };
    }

    try {
      let response: GenerateQuestionResponse[] | null = null;
      if (props.levelIds && props.unit) {
        response = await getTargetedPracticeLearningContentItemsGraphQLCall(
          request as GetTargetedPracticeLearningContentItemsRequest,
          requestController.signal,
        );
      } else {
        response = await generateQuestionGraphQLCall(
          request as GenerateQuestionRequest,
          requestController.signal,
        );
      }

      // Although it's a bit redundant but we want to explicitly state
      // that response needs to be an array,
      if (!response?.length) {
        return [];
      }

      const cards: Card[] = [];
      for (let rawContent of response) {
        if (
          !Object.values(AIContentType).includes(
            rawContent.contentTypeId as unknown as AIContentType,
          )
        ) {
          continue;
        }

        const course = state.courses.coursesMap[rawContent.courseId];
        const unit = course?.units?.find(unit => unit.id === rawContent.unitId);
        const topic = unit?.topics?.find(
          topic => topic.id === rawContent.topicId,
        );

        if (!course || !unit || !topic) {
          continue;
        }

        const card = mapGenerateQuestionResponseToContentCard({
          feedId: props.feedId,
          course,
          unit: unit!,
          topic: topic!,
          response: rawContent,
        });
        thunkApi.dispatch(fetchSubtitleDataFromCard(card));

        // Dev utils to help testing on simulator
        if (isDevelopmentEnv()) {
          commonExposeDevCardAnswer(card, false);
          commonAutoCorrectOnSimulator(card, false);
        }

        thunkApi.dispatch(
          setQuestionPoints({
            id: card.uniqueId,
            points: card.points || 0,
          }),
        );

        // add image if allowed by settings
        if (card.image) {
          if (!isWebPlatform) {
            FastImage.preload([
              { uri: card.image.url, priority: FastImage.priority.low },
            ]);
          }
        }

        cards.push(card);
      }

      return cards;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        if (error.name === 'CanceledError') {
          return thunkApi.rejectWithValue(null);
        } else if (
          error.message !== locale.errors.network_error &&
          error.message !== INVALID_CONTENT_TYPE
        ) {
          if (!isWebPlatform) {
            Sentry.captureException(error, {
              tags: {
                type: 'generateQuestionGraphQLCall',
              },
              extra: {
                request,
              },
            });
          }
        }
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

const onQuestionViewed = createAsyncThunk(
  `${FEED_SLICE_NAME}/onQuestionViewed`,
  async (card: Card, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    if ((card as ContentCard).showTapToReveal) {
      if (state.questions.viewedQuestions[card.uniqueId] === undefined) {
        if (
          state.questions.unfoldedQuestions <
          UNFOLD_QUESTION_TO_BLOCK_TAP_TO_REVEAL
        ) {
          await new Promise(resolve =>
            setTimeout(resolve, TAP_TO_REVEAL_DELAY),
          );
          state = thunkApi.getState() as RootState;
          const attributes = state.questions.cardsAttributes[card.uniqueId];
          if (!attributes || attributes.visibilityStyle === 'no-text') {
            thunkApi.dispatch(
              setCardAttributes({
                card,
                attributes: {
                  tapToRevealShown: true,
                },
              }),
            );
          }
        }
      }
    }
  },
);

export const viewQuestion = createAsyncThunk(
  `${FEED_SLICE_NAME}/viewQuestion`,
  (card: Card, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const localState = state.questions;
    if (localState.viewedQuestions[card.uniqueId]) {
      return thunkApi.rejectWithValue(locale.errors.unknown_error);
    }

    const analyticsPayload = mapCardToAnalyticsPayload(card);
    if (analyticsPayload.cardType !== AIContentType.Unknown) {
      trackAnalyticsEvent(Analytics.viewCard, {
        ...mapCardToAnalyticsPayload(card),
      });
      if (card.generatedContentId) {
        recordContentViewGraphQLCall(card.generatedContentId!);
      }
      if (
        Object.keys(localState.viewedQuestions).length === 0 &&
        state.signup.email !== ''
      ) {
        trackAnalyticsEvent(Analytics.firstContentViewed, {
          ...mapCardToAnalyticsPayload(card),
        });
      } else {
        trackAnalyticsEvent(Analytics.viewCard, {
          ...mapCardToAnalyticsPayload(card),
        });
      }
    }
    thunkApi.dispatch(onQuestionViewed(card));
    return card.uniqueId;
  },
);

export type ScrollToCardID = {
  uniqueId?: string | null;
  feedId: FeedType;
  animated?: boolean;
};

type State = {
  feeds: Record<FeedType, Array<Card>>;
  feedsLoading: Record<FeedType, boolean>;
  feedsError: Record<FeedType, string | null | unknown>;

  contentMuted: boolean;

  cardsAttributes: Record<string, CardAttributes>;

  searchMode: boolean;

  viewedQuestions: Record<string, string>;
  passedQuestions: Record<string, string>;

  scrollToCardID: Record<FeedType, ScrollToCardID | null>;

  unfoldedQuestions: number;

  lastViewedCard?: Card;

  videoRate: number;

  subtitlesShown: boolean;

  // {[key: url]: srt data}
  subtitleData: Record<string, string>;

  // {[key: string]: boolean}
  // key is the combination between: feedId + topicId + unitId + levelIds
  generatingRandomAIQuestions: Record<string, boolean>;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['videoRate'],
} as PersistConfig<State>;

const initialState: State = {
  feeds: {
    [FeedType.Messages]: [],
    [FeedType.Topic]: [],
    [FeedType.Favorites]: [],
    [FeedType.TestPractice]: [],
    [FeedType.TestPrep]: [],
  },

  feedsLoading: {
    [FeedType.Messages]: false,
    [FeedType.Topic]: false,
    [FeedType.Favorites]: false,
    [FeedType.TestPractice]: false,
    [FeedType.TestPrep]: false,
  },
  feedsError: {
    [FeedType.Messages]: null,
    [FeedType.Topic]: null,
    [FeedType.Favorites]: null,
    [FeedType.TestPractice]: null,
    [FeedType.TestPrep]: null,
  },

  scrollToCardID: {
    [FeedType.Messages]: null,
    [FeedType.Topic]: null,
    [FeedType.Favorites]: null,
    [FeedType.TestPractice]: null,
    [FeedType.TestPrep]: null,
  },
  lastViewedCard: undefined,

  searchMode: false,

  cardsAttributes: {},
  unfoldedQuestions: 0,

  viewedQuestions: {},
  passedQuestions: {},

  subtitlesShown: false,
  subtitleData: {},
  contentMuted: isWebPlatform,
  generatingRandomAIQuestions: {},

  videoRate: 1,
};

export const getCardAttributes = (state: State, item: Card): CardAttributes => {
  return state.cardsAttributes[item.uniqueId] ?? getDefaultCardAttributes(item);
};

const questionsSlice = createSlice({
  name: FEED_SLICE_NAME,
  initialState: initialState,
  reducers: {
    setVideoRate: (state: State, action: { payload: number }) => {
      state.videoRate = action.payload;
    },
    resetViewedQuestions: (state: State) => {
      state.viewedQuestions = {};
    },
    setPassedQuestions: (state: State, action: { payload: string }) => {
      state.passedQuestions[action.payload] = new Date().toISOString();
    },
    resetFeedState: (state: State) => {
      state.passedQuestions = {};
      state.viewedQuestions = {};
      state.subtitleData = {};
    },
    setCardAttributes: (
      state: State,
      action: {
        payload: { card: Card; attributes: Partial<CardAttributes> };
      },
    ) => {
      state.cardsAttributes[action.payload.card.uniqueId] = {
        ...(state.cardsAttributes[action.payload.card.uniqueId] ??
          getDefaultCardAttributes(action.payload.card)),
        ...action.payload.attributes,
      };
    },
    setGeneratingRandomAIQuestions: (
      state: State,
      action: { payload: { key: string; value: boolean } },
    ) => {
      state.generatingRandomAIQuestions[action.payload.key] =
        action.payload.value;
    },
    setFeedItems: (
      state: State,
      action: { payload: { feedId: FeedType; items: Array<Card> } },
    ) => {
      state.feeds[action.payload.feedId] = action.payload.items;
    },
    setContentMuted: (state: State, action: { payload: boolean }) => {
      state.contentMuted = action.payload;
    },
    setSubtitlesShown: (state: State, action: { payload: boolean }) => {
      state.subtitlesShown = action.payload;
    },
    setSubtitleData: (state: State, action: SetSubtitleDataPayload) => {
      state.subtitleData[action.payload.url] = action.payload.data;
    },
    setSearchMode: (state: State, action) => {
      state.searchMode = action.payload;
    },
    clearItems: (state: State, action: { payload: FeedIdPayload }) => {
      requestController.abort();
      requestController = new AbortController();
      state.feeds[action.payload.feedId] = [];
      state.feedsError[action.payload.feedId] = null;
      state.feedsLoading[action.payload.feedId] = false;
    },
    cancelPendingItems: (state: State, action: { payload: FeedIdPayload }) => {
      requestController.abort();
      requestController = new AbortController();
      state.feedsLoading[action.payload.feedId] = false;
    },
    removeCard: (state: State, action: { payload: FeedItemPayload }) => {
      const feed = state.feeds[action.payload.card.feedId];
      state.feeds[action.payload.card.feedId] = feed.filter(
        card => card.uniqueId !== action.payload.card.uniqueId,
      );
    },
    clearCachedQuestions: (
      state: State,
      action: { payload: FeedIdPayload },
    ) => {
      const feed = state.feeds[action.payload.feedId];
      state.feeds[action.payload.feedId] = feed.filter(
        card =>
          !card.generatedContentId || state.viewedQuestions[card.uniqueId],
      );
    },
    scrollToCardID: (state: State, action: { payload: ScrollToCardID }) => {
      state.scrollToCardID[action.payload.feedId] = action.payload;
    },
    switchCardVisibilityStyle: (
      state: State,
      action: { payload: ContentCard },
    ) => {
      const attributes = getCardAttributes(state, action.payload);

      const visibilityStyle = attributes.visibilityStyle;
      if (visibilityStyle === 'no-text') {
        state.cardsAttributes[action.payload.uniqueId] = {
          ...attributes,
          visibilityStyle: 'eye',
          tapToRevealShown: false,
        };
      } else {
        state.cardsAttributes[action.payload.uniqueId] = {
          ...attributes,
          visibilityStyle: 'no-text',
        };
      }
    },
    setHintVideoVisibility: (
      state: State,
      action: { payload: { card: ContentCard; value: boolean } },
    ) => {
      const attributes = getCardAttributes(state, action.payload.card);
      state.cardsAttributes[action.payload.card.uniqueId] = {
        ...attributes,
        showHintVideo: action.payload.value,
      };
    },
    setMatchingPairsCharacterVisibility: (
      state: State,
      action: { payload: MatchingPairsSetVideoPayloadWithCard },
    ) => {
      const attributes = getCardAttributes(state, action.payload.card);
      state.cardsAttributes[action.payload.card.uniqueId] = {
        ...attributes,
        selectedCharacterFromLeftOptions:
          action.payload.selectedCharacterFromLeftOptions,
        shouldPlayAllCharacters: action.payload.shouldPlayAllCharacters,
      };
    },

    putCardToFeed: (
      state: State,
      action: { payload: PutCardToFeedPayload },
    ) => {
      const feedId = action.payload.card.feedId;
      const feed = state.feeds[feedId];
      const targetCard = action.payload.card;
      const anchorCard = action.payload.after;
      if (anchorCard) {
        const index = feed.findIndex(
          card => card.uniqueId === anchorCard.uniqueId,
        );
        if (index !== -1) {
          state.feeds[feedId] = [
            ...feed.slice(0, index + 1),
            targetCard,
            ...feed.slice(index + 1),
          ];
          return;
        }
      }
      state.feeds[feedId] = [...feed, targetCard];
    },
    setLikeContent: (
      state: State,
      action: { payload: { content: ContentCard; value: boolean } },
    ) => {
      const targetContentCard = action.payload.content;
      const isLiked = action.payload.value;

      // We should update the totalBookmarks of the card in all feed-type
      // because the card may be visible in multiple feeds and
      // we want to have consistent data between the feeds
      ALL_FEED_IDS.forEach(feedId => {
        const feed = state.feeds[feedId];

        feed.forEach(card => {
          const contentCard = card as ContentCard;
          if (
            contentCard.generatedContentId &&
            contentCard.generatedContentId ===
              targetContentCard.generatedContentId
          ) {
            if (contentCard) {
              if (isLiked) {
                contentCard.totalLikes += 1;
              } else if (contentCard.totalLikes > 0) {
                contentCard.totalLikes -= 1;
              }
            }
          }
        });
      });
    },
    setBookmarkContent: (
      state: State,
      action: { payload: { content: ContentCard; value: boolean } },
    ) => {
      const targetContentCard = action.payload.content;
      const isBookmarked = action.payload.value;

      // We should update the totalBookmarks of the card in all feed-type
      // because the card may be visible in multiple feeds and
      // we want to have consistent data between the feeds
      ALL_FEED_IDS.forEach(feedId => {
        const feed = state.feeds[feedId];

        feed.forEach(card => {
          const contentCard = card as ContentCard;
          if (
            contentCard.generatedContentId &&
            contentCard.generatedContentId ===
              targetContentCard.generatedContentId
          ) {
            if (contentCard) {
              if (isBookmarked) {
                contentCard.totalBookmarks += 1;
              } else if (contentCard.totalBookmarks > 0) {
                contentCard.totalBookmarks -= 1;
              }
            }
          }
        });
      });
    },
    setShareContent: (state: State, action: { payload: ContentCard }) => {
      const targetContentCard = action.payload;

      // We should update the totalShares of the card in all feed-type
      // because the card may be visible in multiple feeds and
      // we want to have consistent data between the feeds
      ALL_FEED_IDS.forEach(feedId => {
        const feed = state.feeds[feedId];

        feed.forEach(card => {
          const contentCard = card as ContentCard;
          if (
            contentCard.generatedContentId &&
            contentCard.generatedContentId ===
              targetContentCard.generatedContentId
          ) {
            if (contentCard) {
              contentCard.totalShares += 1;
            }
          }
        });
      });
    },
    resetLastViewedCard: (state: State) => {
      state.lastViewedCard = undefined;
    },
  },
  extraReducers: builder => {
    // AI Question

    builder.addCase(getAICard.pending, (state: State, action) => {
      const params = action.meta.arg;

      state.feedsLoading[params.feedId] = true;
      state.feedsError[params.feedId] = null;
    });

    builder.addCase(getAICard.rejected, (state: State, action) => {
      const params = action.meta.arg;
      state.feedsLoading[params.feedId] = false;
      state.feedsError[params.feedId] = action.payload;
    });

    builder.addCase(getAICard.fulfilled, (state: State, action) => {
      const params = action.meta.arg;
      const feed = state.feeds[params.feedId];

      state.feedsLoading[params.feedId] = false;
      state.feedsError[params.feedId] = null;

      if (action.payload) {
        state.feeds[params.feedId] = [action.payload!];
      }
    });

    builder.addCase(
      generateRandomAIQuestionsBatch.fulfilled,
      (state: State, action) => {
        if (!action.payload?.length) {
          return;
        }

        const params = action.meta.arg;

        const feed = state.feeds[params.feedId];
        action.payload.forEach(card => {
          let rejectCard = false;

          if (feed.length > 0) {
            const existingCard = feed
              .slice(-5)
              .find(f => f.generatedContentId === card.generatedContentId);

            if (existingCard) {
              rejectCard = true;
            }
          }

          resetFeedErrorTime();

          if (!rejectCard) {
            feed.push(card);
          }
        });
        state.feeds[params.feedId] = [...feed];
        state.feedsError[params.feedId] = null;
        state.feedsLoading[params.feedId] = false;
      },
    );

    builder.addCase(
      generateRandomAIQuestionsBatch.pending,
      (state: State, action) => {
        const params = action.meta.arg;
        const feed = state.feeds[params.feedId];

        if (feed.length === 0) {
          state.feedsLoading[params.feedId] = true;
        }
      },
    );
    builder.addCase(
      generateRandomAIQuestionsBatch.rejected,
      (state: State, action) => {
        if (!action.payload || action.payload === INVALID_CONTENT_TYPE) {
          return state;
        }

        const params = action.meta.arg;

        handleFeedError({ message: action.payload } as ErrorMessageType);

        state.feedsError[params.feedId] = action.payload;
        state.feedsLoading[params.feedId] = false;
      },
    );

    builder.addCase(viewQuestion.fulfilled, (state: State, action) => {
      state.viewedQuestions[action.meta.arg.uniqueId] = moment().toISOString();
      state.lastViewedCard = action.meta.arg;
    });
  },
});

export const {
  setCardAttributes,
  setSearchMode,
  clearCachedQuestions,
  clearItems,
  cancelPendingItems,
  scrollToCardID,
  removeCard,
  setContentMuted,
  setSubtitlesShown,
  switchCardVisibilityStyle,
  putCardToFeed,
  setLikeContent,
  setBookmarkContent,
  setShareContent,
  setHintVideoVisibility,
  setMatchingPairsCharacterVisibility,
  setFeedItems,
  setVideoRate,
  resetLastViewedCard,
  resetViewedQuestions,
  setGeneratingRandomAIQuestions,
  setPassedQuestions,
  resetFeedState,
  setSubtitleData,
} = questionsSlice.actions;

export const FeedSlice = persistReducer(persistConfig, questionsSlice.reducer);
