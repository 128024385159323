import { TestPrepQuestionEntryPoint } from '../../TestPrep/entities';

import { AIContentType } from './CommonRequestsTypes';
import { FeedType } from './index';

export type CardAnalyticsPayload = {
  cardType: AIContentType | string;
  generatedContentId?: string;
  course?: string;
  unit?: string;
  feedId: FeedType;
};

export type AiFigureAnalyticsPayload = {
  figureName: string;
  figureUsername: string;
};

export type UnitTestAnalyticsPayload = {
  courseId: string;
  courseName: string;
  unitId: string;
  unitNumber: number;
  testNumber?: number;
};

export type UnitTestSubmitAnswerAnalyticsPayload = {
  correct: boolean;
  questionId: string;
} & UnitTestAnalyticsPayload;

export type UnitTestCompletedAnalyticsPayload = {
  questionsAnswered: number;
  questionsCorrect: number;
  pointsEarned: number;
  grade: string;
  SATscore?: number;
  timeRemaining: number;
} & UnitTestAnalyticsPayload;

export type UnitTestSkippedQuestionReviewDialogShownAnalyticsPayload = {
  skippedQuestionsCount: number;
} & UnitTestAnalyticsPayload;

export type UnitTestQuestionViewedAnalyticsPayload = {
  questionId: string;
  questionStatus: string;
  from: TestPrepQuestionEntryPoint;
} & UnitTestAnalyticsPayload;

export enum PaywallEntryPoint {
  LEARN_TAB_UNIT = 'learnTabUnit',
  LEARN_TAB_PROMO = 'learnTabPromo',
  COMPLETION_SCREEN_NEXT_UNIT = 'completionScreenNextUnit',
  FOLLOW_COURSE_HOME = 'followCourseHome',
  FOLLOW_COURSE_PROFILE = 'followCourseProfile',
  TEST_PREP = 'testPrep',
  LEARN_TAB = 'learnTab',
  UNIT_TEST_COMPLETION = 'unitTestCompletion',
  FREE_TRIAL_ENDED = 'freeTrialEnded',
  FEED = 'feed',
  PUSH_NOTIFICATION = 'pushNotification',
  COURSE_PICKER = 'coursePicker',
  SAT_BOOTCAMP_INTRO = 'SATBootcampIntro',
  SAT_BOOTCAMP_PAYWALL_SCREEN = 'SATBootcampPaywallScreen',
  AP_RESULTS = 'APResults',
  PROFILE = 'profile',
  FREE_TRIAL_COUNTDOWN = 'freeTrialCountdown',
  AP_ONBOARDING = 'APOnboarding',
  LOCKED_AP_COURSE = 'lockedAPCourse',
}

export enum PaywallPurchaseType {
  COURSE = 'course',
  SAT_BOOTCAMP = 'SATBootcamp',
  SAT_BOOTCAMP_PLUS_AP_PROGRAM = 'SATBootcampPlusAPProgram',
  ULTIMATE_AP_PACKAGE = 'ultimateAPPackage',
  AP_PROGRAM = 'APProgram',
  AP_PROGRAM_PLUS_SAT_PREP = 'APProgramPlusSATPrep',
}

export enum PaywallCourseName {
  AP_PROGRAM = 'AP Program',
}
