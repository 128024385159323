import AsyncStorage from '@react-native-async-storage/async-storage';
import { StackActions } from '@react-navigation/native';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import { handleNetworkActionError } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import {
  APCourseGoals,
  Course,
  CourseGoalKeys,
  FeedType,
  PaywallEntryPoint,
} from '../../../Common/entities';
import {
  isSATCourse,
  trackAnalyticsEvent,
  updateUserProperties,
} from '../../../Common/services/utils';
import {
  Analytics,
  ScreenNames,
} from '../../../Common/services/utils/AppConstants';
import {
  clearCachedQuestions,
  clearItems,
  getCourseProgress,
  setSearchMode,
} from '../../../Learn/services/slices';
import { PaywallScreenProps } from '../../../Paywall/screens/PaywallScreen';
import { mapCourseLockedStatus } from '../../../UserAccess/services/mappers';
import { TrackedUserEventType } from '../../../UserEvent/entities';
import { trackUserEvent } from '../../../UserEvent/services/slices';
import { CourseListAlgorithm } from '../../algorithms';
import {
  EnrollCoursePayload,
  EnrolledCourse,
  EnrolledCourseWithGoal,
  ResetSelectedCoursePayload,
  SelectedCourseEntryPoint,
  SetSelectedCoursePayload,
  SyncSelectedCoursePayload,
} from '../../entities';
import {
  enrollCourseGraphQLCall,
  getCoursesEnrollmentCountGraphQLCall,
  getUserEnrolledCoursesGraphQLCall,
  unenrollUserFromCourseGraphQLCall,
} from '../../graphql';

const COURSE_ENROLLMENT_COUNT_COOLDOWN = 60 * 60 * 1000; // (in ms) 1 hour

export const COURSE_ENROLLMENTS_SLICE_NAME = 'CourseEnrollmentsSlice';

const persistConfig = {
  key: COURSE_ENROLLMENTS_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: [
    'showCourseListOnboarding',
    'showCourseListStudyTab',
    'selectedCourse',
    'coursesEnrollmentCount',
    'coursesEnrollmentCountLastFetchOn',
    'followedCoursesWithGoal',
  ],
} as PersistConfig<CourseEnrollmentsSliceState>;

export type CourseEnrollmentsSliceState = {
  // We don't really need to store the "exact" course data. Would be better to store
  // only "needed" data, then refer to courseMap (the curriculum data) to get the course details.
  // Use 1 source-of-truth as much as possible, otherwise we'll need to maintain multiple source-of-truth
  // which become super-challenging and tricky.
  following: EnrolledCourse[];
  // {courseId, enrollmentCount (number)}
  coursesEnrollmentCount: Record<string, number>;

  // to temporarily store the course data that the user unenrolled from
  // for restoring the state if the unenroll action fails
  courseUnEnrolled: Record<string, EnrolledCourse>;

  // date flag to check if the course enrollment count has been fetched
  coursesEnrollmentCountLastFetchOn?: string;

  selectedCourse?: EnrolledCourse;
  courseLoading: boolean;
  isFollowGoalPopupVisible: boolean;
  showCourseListOnboarding: boolean;
  showCourseListStudyTab: boolean;
  followedCoursesWithGoal: Record<string, EnrolledCourseWithGoal>;
};

const initialState: CourseEnrollmentsSliceState = {
  following: [],
  coursesEnrollmentCount: {},
  courseUnEnrolled: {},
  selectedCourse: undefined,
  courseLoading: false,
  isFollowGoalPopupVisible: false,

  showCourseListOnboarding: false,
  showCourseListStudyTab: false,
  followedCoursesWithGoal: {},
};

export const fetchEnrolledCourses = createAsyncThunk(
  'CourseEnrollmentsSlice/fetchEnrolledCourses',
  async (_, thunkApi) => {
    try {
      const response = await getUserEnrolledCoursesGraphQLCall();
      if (!response?.length) {
        thunkApi.dispatch(setShowCourseListStudyTab(true));
      }

      return response;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const resetSelectedCourse = createAsyncThunk(
  'CourseEnrollmentsSlice/resetSelectedCourse',
  async (props: ResetSelectedCoursePayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.courseEnrollments;

    const courseMap = state.courses.coursesMap;

    const sortedFollowing = CourseListAlgorithm.sortFollowedCourses([
      ...localState.following,
    ] as EnrolledCourse[]);

    if (sortedFollowing.length > 0) {
      if (localState.selectedCourse) {
        const course = courseMap[localState.selectedCourse?.id];
        updateUserProperties({
          latestActiveCourse: course?.name,
        });
      }

      const followedCourses = sortedFollowing.map(course => {
        return {
          course: courseMap[course.id]?.name,
          goal: course.goalKeys,
        };
      });
      updateUserProperties({
        followedCourses: followedCourses,
      });
      thunkApi.dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.CourseActivity,
          courseId: sortedFollowing[0].id,
        }),
      );
    }

    return props;
  },
);

export const fetchCoursesEnrollmentCount = createAsyncThunk(
  'CourseEnrollmentsSlice/fetchCoursesEnrollmentsCount',
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.courseEnrollments;

      if (state.coursesEnrollmentCountLastFetchOn) {
        const lastFetchDate = new Date(state.coursesEnrollmentCountLastFetchOn);
        const currentDate = new Date();

        const diff = currentDate.getTime() - lastFetchDate.getTime();
        if (diff < COURSE_ENROLLMENT_COUNT_COOLDOWN) {
          return { result: [], skip: true };
        }
      }

      const result = await getCoursesEnrollmentCountGraphQLCall();
      return { result, skip: false };
    } catch (e: unknown) {
      if (e instanceof Error) {
        handleNetworkActionError(e);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const unenrollUserFromCourse = createAsyncThunk(
  'CourseEnrollmentsSlice/unenrollUserFromCourse',
  async (course: Course, thunkApi) => {
    try {
      if (
        (thunkApi.getState() as RootState).courseEnrollments.following
          .length === 0
      ) {
        thunkApi.dispatch(
          clearItems({
            feedId: FeedType.Topic,
          }),
        );
        thunkApi.dispatch(setSearchMode(true));
      }

      await unenrollUserFromCourseGraphQLCall({
        courseId: course.id,
      });

      thunkApi.dispatch(syncSelectedCourse());
      thunkApi.dispatch(
        setCurrentSelectedCourse({
          id: course.id,
          tab: SelectedCourseEntryPoint.STUDY,
        }),
      );

      trackAnalyticsEvent(Analytics.unfollowCourse, {
        course: course.name,
      });

      thunkApi.dispatch(
        clearCachedQuestions({
          feedId: FeedType.Topic,
        }),
      );
      return course;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const enrollUserToCourse = createAsyncThunk(
  'CourseEnrollmentsSlice/enrollUserToCourse',
  async (payload: EnrollCoursePayload, thunkApi) => {
    const { course, goal, entryPoint } = payload;
    try {
      trackAnalyticsEvent(Analytics.followCourse, {
        _firebase: true,
        course: course.name,
      });

      const state = thunkApi.getState() as RootState;

      const courseData = state.courses.coursesMap[course.id];
      const isCourseLocked = mapCourseLockedStatus(state, course.id);

      if (isCourseLocked && !payload.skipRedirectToPaywall) {
        RootNavigatorRef.dispatch(
          StackActions.push(ScreenNames.MainStack.PAYWALL_SCREEN, {
            course: courseData,
            entryPoint: entryPoint,
            isFromCoursePicker: payload.isFromCoursePicker,
          } as PaywallScreenProps),
        );
      } else if (payload.isFromCoursePicker) {
        RootNavigatorRef.dispatch(StackActions.pop(1));
      }

      await enrollCourseGraphQLCall({
        courseId: courseData.id,
        goal: goal ?? CourseGoalKeys.JUST_EXPLORING,
      });

      thunkApi.dispatch(syncSelectedCourse());
      thunkApi.dispatch(getCourseProgress({ courseId: courseData.id }));

      let tab = null;
      if (entryPoint) {
        tab =
          entryPoint === PaywallEntryPoint.TEST_PREP
            ? SelectedCourseEntryPoint.TEST_PREP
            : SelectedCourseEntryPoint.STUDY;
      }

      thunkApi.dispatch(
        setCurrentSelectedCourse({
          id: courseData.id,
          tab,
        }),
      );

      if (goal) {
        let analyticsGoal = goal.toLowerCase().replace(' ', '_');

        if (isSATCourse(courseData)) {
          analyticsGoal = 'sat_' + analyticsGoal;
        }

        trackAnalyticsEvent(Analytics.followCourse, {
          _firebase: true,
          _suffix: analyticsGoal,
          course: courseData.name,
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }

    thunkApi.dispatch(
      clearCachedQuestions({
        feedId: FeedType.Topic,
      }),
    );

    return course;
  },
);

export const syncSelectedCourse = createAsyncThunk(
  'CourseEnrollmentsSlice/syncSelectedCourse',
  async (props: SyncSelectedCoursePayload | undefined = {}, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const { overrideStudyListState } = props;
    const availableCourses = Object.values(state.courses.coursesMap).filter(
      course => course.available,
    );

    thunkApi.dispatch(
      resetSelectedCourse({ availableCourses, overrideStudyListState }),
    );
  },
);

export const setCurrentSelectedCourse = createAsyncThunk(
  'CourseEnrollmentsSlice/setSelectedCourse',
  async (props: SetSelectedCoursePayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const course = state.courses.coursesMap[props.id];
    updateUserProperties({
      latestActiveCourse: course?.name,
    });
    thunkApi.dispatch(
      trackUserEvent({
        eventType: TrackedUserEventType.CourseActivity,
        courseId: props.id,
      }),
    );
    return props;
  },
);

const courseEnrollmentsSlice = createSlice({
  name: 'CourseEnrollmentsSlice',
  initialState: initialState,
  reducers: {
    filterOnlyAvailableCourses: (state, action: { payload: Course[] }) => {
      state.following = state.following.filter(course =>
        action.payload.find(follower => follower.id === course.id),
      );
      if (Object.keys(state.followedCoursesWithGoal).length === 0) {
        state.followedCoursesWithGoal = state.following.reduce<
          Record<string, EnrolledCourseWithGoal>
        >((acc, course) => {
          acc[course.id] = {
            id: course.id,
            enrolledOn: course.enrolledOn,
            goal: '',
            name:
              action.payload.find(follower => follower.id === course.id)
                ?.name || '',
          };
          return acc;
        }, {});
      }
    },

    resetCourseEnrollmentsState: (state: CourseEnrollmentsSliceState) => {
      state.showCourseListStudyTab = false;
      state.showCourseListOnboarding = false;
      state.selectedCourse = undefined;
      state.coursesEnrollmentCountLastFetchOn = undefined;
      state.followedCoursesWithGoal = {};
      state.following = [];
    },
    setShowCourseListOnboarding: (
      state: CourseEnrollmentsSliceState,
      action: { payload: boolean },
    ) => {
      state.showCourseListOnboarding = action.payload;
    },
    setShowCourseListStudyTab: (
      state: CourseEnrollmentsSliceState,
      action: { payload: boolean },
    ) => {
      state.showCourseListStudyTab = action.payload;
    },
    startLearningPressed: (state: CourseEnrollmentsSliceState) => {
      const sortedFollowing = CourseListAlgorithm.sortFollowedCourses([
        ...state.following,
      ] as EnrolledCourse[]);

      // Select the last followed course
      state.selectedCourse = sortedFollowing[0];

      state.showCourseListOnboarding = false;
      state.showCourseListStudyTab = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(unenrollUserFromCourse.pending, (state, action) => {
      const courseId = action.meta.arg.id;

      const enrollmentCount = state.coursesEnrollmentCount[courseId] - 1;
      state.coursesEnrollmentCount[courseId] =
        enrollmentCount > 0 ? enrollmentCount : 0;

      const courseToUnEnroll = state.following.find(
        course => course.id === action.meta.arg.id,
      );

      if (!courseToUnEnroll) {
        return;
      }

      state.courseUnEnrolled[courseToUnEnroll.id] = courseToUnEnroll;
      state.following = state.following.filter(
        course => course.id !== action.meta.arg.id,
      );
    });

    builder.addCase(unenrollUserFromCourse.rejected, (state, action) => {
      const courseId = action.meta.arg.id;
      const enrollmentCount = state.coursesEnrollmentCount[courseId] + 1;
      state.coursesEnrollmentCount[courseId] = enrollmentCount;

      const followedCourse = state.following.find(
        course => course.id === action.meta.arg.id,
      );
      if (followedCourse) {
        delete state.courseUnEnrolled[followedCourse.id];
        return;
      }

      const courseToRestore = state.courseUnEnrolled[action.meta.arg.id];
      state.following = [...state.following, courseToRestore];
    });

    builder.addCase(unenrollUserFromCourse.fulfilled, (state, action) => {
      const courseId = action.meta.arg.id;

      if (state.followedCoursesWithGoal[courseId]) {
        delete state.followedCoursesWithGoal[courseId];
      }
    });

    builder.addCase(enrollUserToCourse.pending, (state, action) => {
      const followingState = {
        ...action.meta.arg.course,
        enrolledOn: new Date().toISOString(),
      };

      const enrollmentCount =
        state.coursesEnrollmentCount[followingState.id] + 1;
      state.coursesEnrollmentCount[followingState.id] = enrollmentCount;

      const followedCourse = state.following.find(
        course => course.id === followingState.id,
      );
      if (followedCourse) {
        return;
      }

      state.following = [
        ...state.following,
        {
          id: followingState.id,
          enrolledOn: new Date().toISOString(),
          goalKeys: action.meta.arg?.goal as CourseGoalKeys | undefined,
        },
      ];
      state.followedCoursesWithGoal[followingState.id] = {
        id: followingState.id,
        enrolledOn: new Date().toISOString(),
        goal: action.meta.arg.goal ?? APCourseGoals.JUST_EXPLORING,
        name: action.meta.arg.course.name,
      };
    });

    builder.addCase(enrollUserToCourse.rejected, (state, action) => {
      const courseId = action.meta.arg.course.id;

      const enrollmentCount = state.coursesEnrollmentCount[courseId] - 1;
      state.coursesEnrollmentCount[courseId] =
        enrollmentCount > 0 ? enrollmentCount : 0;

      state.following = state.following.filter(
        course => course.id !== action.meta.arg.course.id,
      );

      if (state.followedCoursesWithGoal[courseId]) {
        delete state.followedCoursesWithGoal[courseId];
      }
    });

    builder.addCase(fetchEnrolledCourses.fulfilled, (state, action) => {
      const sortedFollowing = action.payload.sort((a, b) => {
        if (a.enrolledOn && b.enrolledOn) {
          return (
            new Date(b.enrolledOn).getTime() - new Date(a.enrolledOn).getTime()
          );
        }

        return 0;
      });

      state.following = sortedFollowing.map(course => ({
        id: course.courseId,
        enrolledOn: course.enrolledOn,
        goalKeys: course.goal,
      }));
    });

    builder.addCase(fetchCoursesEnrollmentCount.fulfilled, (state, action) => {
      if (action.payload.skip) {
        return;
      }

      action.payload.result.forEach(course => {
        state.coursesEnrollmentCount[course.courseId] =
          course?.enrollmentCount ?? 0;
      });
      state.coursesEnrollmentCountLastFetchOn = new Date().toISOString();
    });

    builder.addCase(
      setCurrentSelectedCourse.fulfilled,
      (state, action: PayloadAction<SetSelectedCoursePayload>) => {
        const { id } = action.payload;
        const selectedCourse = state.following.find(course => course.id === id);

        if (action.payload.tab) {
          trackAnalyticsEvent(Analytics.courseSelected, {
            courseId: selectedCourse?.id,
            tab: action.payload.tab,
          });
        }

        if (selectedCourse) {
          state.selectedCourse = selectedCourse;
        } else {
          state.selectedCourse = undefined;
        }
      },
    );

    builder.addCase(
      resetSelectedCourse.fulfilled,
      (state, action: PayloadAction<ResetSelectedCoursePayload>) => {
        const { availableCourses, overrideStudyListState } = action.payload;
        const sortedFollowing = CourseListAlgorithm.sortFollowedCourses([
          ...state.following,
        ] as EnrolledCourse[]);

        if (sortedFollowing.length === 0) {
          state.selectedCourse = undefined;
          return;
        }

        if (state.selectedCourse) {
          const selectedCourse = availableCourses.find(
            course => course.id === state.selectedCourse?.id,
          );

          if (!selectedCourse) {
            state.selectedCourse = sortedFollowing[0];
          }
        } else {
          state.selectedCourse = sortedFollowing[0];
        }

        if (overrideStudyListState) {
          state.showCourseListStudyTab = false;
        }
      },
    );
  },
});

export const {
  resetCourseEnrollmentsState,
  filterOnlyAvailableCourses,
  startLearningPressed,
  setShowCourseListStudyTab,
  setShowCourseListOnboarding,
} = courseEnrollmentsSlice.actions;

export const CourseEnrollmentsSlice = persistReducer(
  persistConfig,
  courseEnrollmentsSlice.reducer,
);
