import { createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';
import { PurchaseError } from 'react-native-iap';

import locale from '../../../../App/locale';
import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handlePaywallErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  ScreenNames,
  StudyStack,
  TabNames,
  TestStack,
} from '../../../../Common/services/utils/AppConstants';
import {
  setCurrentSelectedCourse,
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { PurchaseHighSchoolCourseParams } from '../../../entities/PaywallPurchasesActionTypes';
import { PURCHASABLE_ITEMS } from '../../utils/PaywallUtils';
import {
  PURCHASE_SLICE_NAME,
  setHighSchoolCourseEntryPoint,
} from '../PurchasesSlice';

import { purchaseProduct } from './PurchaseProductAction';

export const purchaseHighSchoolCourse = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/purchaseHighSchoolCourse`,
  async (params: PurchaseHighSchoolCourseParams, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    thunkApi.dispatch(setHighSchoolCourseEntryPoint(params.entryPoint));
    try {
      await thunkApi
        .dispatch(purchaseProduct(PURCHASABLE_ITEMS[params.item]))
        .unwrap();

      if (!params.showPostSignupCourses) {
        thunkApi.dispatch(
          setCurrentSelectedCourse({ id: params.course.id, tab: null }),
        );

        if (state.courseEnrollments.showCourseListOnboarding) {
          thunkApi.dispatch(setShowCourseListStudyTab(false));
          thunkApi.dispatch(setShowCourseListOnboarding(false));

          // wait 100ms to make sure the course is unlocked before navigating to the course
          await new Promise(resolve => setTimeout(resolve, 100));
        }
      }

      if (RootNavigatorRef.isReady()) {
        if (params.showPostSignupCourses) {
          RootNavigatorRef.goBack();
        } else if (params.showTestPrep) {
          RootNavigatorRef.reset({
            index: 0,
            routes: [
              {
                name: ScreenNames.MainStack.BOTTOM_TABS,
                params: {
                  screen: TabNames.TEST,
                  params: { screen: TestStack.TEST_PREP_LIST },
                },
              },
            ],
          });
        } else {
          RootNavigatorRef.reset({
            index: 0,
            routes: [
              {
                name: ScreenNames.MainStack.BOTTOM_TABS,
                params: {
                  screen: TabNames.HOME,
                  params: { screen: StudyStack.STUDY_FEED_SCREEN },
                },
              },
            ],
          });
        }
      }
    } catch (e) {
      const error = e as PurchaseError;
      if (error.code !== 'E_USER_CANCELLED') {
        showMessage({
          message: locale.errors.purchase_error,
          type: 'danger',
        });
      } else {
        handlePaywallErrorSilently(e as Error);
      }
      return thunkApi.rejectWithValue(e);
    }
  },
);
