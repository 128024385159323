import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  CommonLinearProgress,
  CommonOrdinalNumber,
} from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATAssets } from '../../assets';
import { SATUnitsIndex } from '../../entities';
import { useSATProgramDetailsTarget } from '../../services/hooks';

type Props = {
  shouldShowStats: boolean;
  percentile?: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
};

export const SATStatsTotalScore = (props: Props): React.ReactElement => {
  const { shouldShowStats, score, maxScore, percentile, unitsIndex } = props;
  const { targetPercentage, target } = useSATProgramDetailsTarget({
    maxScore: maxScore,
  });

  const noScoreTextDescription = useMemo(() => {
    return locale.sat.stats_total_score_hidden
      .replace('${FIRST_UNIT}', `${unitsIndex?.first || 'N'}`)
      .replace('${LAST_UNIT}', `${unitsIndex?.last || 'N'}`);
  }, [unitsIndex]);

  const progressPercentage = useMemo(() => {
    const currentMaxScore = maxScore || 1;
    const currentScore = score || 0;

    return (currentScore / currentMaxScore) * 100;
  }, [score, maxScore]);

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <AssetImage
          asset={SATAssets.summaryIcon}
          height={Sizes.large}
          containerStyle={styles.icon}
        />

        <Text style={styles.titleText}>
          {locale.sat.stats_total_score_title}
        </Text>
      </View>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <>
            <View style={styles.scoreContainer}>
              <Text style={styles.scoreText}>{score}</Text>

              <View style={styles.scorePercentileTextContainer}>
                <Text style={styles.scorePercentileText}>
                  {locale.sat.stats_percentile_prefix}
                </Text>
                <CommonOrdinalNumber numberInString={percentile} />
                <Text style={styles.scorePercentileText}>
                  {locale.sat.stats_percentile_suffix}
                </Text>
              </View>
            </View>

            <CommonLinearProgress
              progressPercentage={progressPercentage}
              targetPercentage={targetPercentage}
              target={target}
            />
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white12,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.semiMedium,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
  },
  titleText: {
    ...Fonts.semiMediumBold,
    color: 'white',
  },
  icon: {
    height: Sizes.large,
  },

  detailContainer: {
    marginTop: Sizes.small,
  },
  detailText: {
    ...Fonts.semiMedium,
    color: Colors.white60,
  },

  scoreContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: Sizes.small,
    marginBottom: Sizes.small,
  },
  scoreText: {
    ...Fonts.xxxxxlargeBold,
    color: Colors.emeraldGreen,
  },
  scorePercentileText: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'right',
  },
  scorePercentileTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
});
