import { Asset } from 'react-native-image-picker';

import { SATBootcampMetadata } from '../../Onboarding/entities';
import { APProgramMetadata, CourseMetadata } from '../../Paywall/entities';

import { Course, MultipleChoiceOption } from './index';

export enum AIContentType {
  ControversialConversation = 'Controversial Conversation',
  DidYouKnow = 'Did You Know',
  FillInTheBlankWithHelp = 'FITB with Help',
  MatchingPairs = 'Matching Pairs',
  MCQWithHelp = 'MCQ with Help',
  Meme = 'Meme',
  Rap = 'Rap',
  TruthOrLie = 'Truth or Lie',
  DMsFromTheDead = 'DMs from the Dead',
  DMsFromTheDeadV2Text = 'DMs from the Dead v2 - Text Message',
  UnitTestMCQ = 'Unit Test MCQ',
  MCQForDSAT = 'MCQ - DSAT',
  MCQForAPCourse = 'MCQ with Help - Practice Test',
  Unknown = 'Unknown',
}

export enum CardType {
  EMPTY_LOADING_ITEM = 'empty_loading_item',
  MEME_CARD = 'meme_card',
  RAP_CARD = 'rap_card',
  CONTENT_CARD = 'content_card',
  FILL_IN_THE_BLANK_CARD = 'fill_in_the_blank_card',
  DID_YOU_KNOW_CARD = 'did_you_know_card',
  MCQ_CARD = 'mcq_card',
  CONTROVERSIAL_CONVERSATION_CARD = 'controversial_conversation_card',
  TRUTH_OR_LIE_CARD = 'truth_or_lie_card',
  CONGRATULATIONS_CARD = 'congratulations',
  MATCHING_PAIRS_CARD = 'matching_pairs_card',
  LEVEL_MASTERED_CARD = 'level_mastered_card',
  DMs_FROM_THE_DEAD_CARD = 'dms_from_the_dead_card',
  DMs_FROM_THE_DEAD_CARD_V2 = 'dms_from_the_dead_card_v2',
  MCQ_FOR_DSAT = 'mcq_for_dsat',
  MCQ_FOR_AP_COURSE = 'mcq_for_ap_course',
}

export type ContentAttribute = {
  attributeName: string;
  attributeValue: string | {};
};

export type GeneratedImage = {
  type: string;
  url: string;
};

export type GeneratedMCQOption = {
  id: string;
  answer: string;
  explanation: string;
  correct: boolean;
};

export type GeneratedQuestionMCQ = {
  question: string;
  options: GeneratedMCQOption[];
};

export type SingleQuestionResponse = {
  id: number;
  type: CardType;
  playlist: string;
  flashcard_front?: string;
  flashcard_back?: string;
  description: string;
  image?: string;
  course: Course;
  question?: string;
  options?: Array<MultipleChoiceOption>;
};

export type NetworkError = {
  url: string;
  method: string;
  message?: string;
  response?: any;
  status?: number;
  requestBody?: any;
};

export type UpdateUserProfileImageRequest = {
  userId: string;
  profileImageURL: string;
};

export type CurriculumLevelResponse = {
  levelName: string;
  levelId: string;
  platformId: string;
  order: string;
};

export type CurriculumTopicResponse = {
  topicName: string;
  topicId: string;
  platformId: string;
  order: string;
  levels: CurriculumLevelResponse[];
};

export type MediaResponse = {
  asset: Asset;
};

export enum SubmitDataType {
  AP_PROGRAM_ONBOARDING = 'AP_PROGRAM_ONBOARDING',
  SAT_BOOTCAMP_ONBOARDING = 'SAT_BOOTCAMP_ONBOARDING',
  AP_COURSE_TARGET_GRADE = 'AP_COURSE_TARGET_GRADE',
}

export type SubmitDataInput = {
  type: SubmitDataType;
  metadata: APProgramMetadata | SATBootcampMetadata | CourseMetadata;
};

export type SubmitDataOutput = {
  success: boolean;
};

export type GetAppConfigsFromS3Response = {
  testPrepPreGeneratedMessages: string[];
  testPrepMathPreGeneratedMessages: string[];

  stagingBuildNumber?: string;
  stagingEnv?: string;
};
