import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';

import MainNavigatorWeb from './src/App/navigation/RootNavigator.web';
import { assignRequestInstancesForWeb } from './src/App/services/requests/index.web';
import store from './src/App/store';
import { setUseCustomEnvironment } from './src/Common/services/slices';
import { ENV_KEYS, environment } from './src/Common/services/utils';
import { Colors } from './src/Common/services/utils/AppConstants';

export default (): React.ReactElement => {
  // use this to make sure we wait for the custom-env check properly before loading any data
  const [isEnvReady, setIsEnvReady] = useState(false);

  useFonts({
    'SFProRounded-Bold': require('./assets/fonts/SFProRounded-Bold.ttf'),
    'SFProRounded-Regular': require('./assets/fonts/SFProRounded-Regular.ttf'),
    'SFRounded-Black': require('./assets/fonts/SFProRounded-Black.ttf'),
    'SFRounded-Heavy': require('./assets/fonts/SFProRounded-Heavy.ttf'),
    'SFRounded-Light': require('./assets/fonts/SFProRounded-Light.ttf'),
    'SFRounded-Medium': require('./assets/fonts/SFProRounded-Medium.ttf'),
    'SFRounded-Semibold': require('./assets/fonts/SFProRounded-Semibold.ttf'),
    'SFRounded-Thin': require('./assets/fonts/SFProRounded-Thin.ttf'),
    'SFRounded-Ultralight': require('./assets/fonts/SFProRounded-Ultralight.ttf'),
  });

  useEffect(() => {
    assignRequestInstancesForWeb();

    let useCustomEnvironment = false;
    if (typeof window !== 'undefined') {
      const { WEB_APP_PREVIEW_LINK } = environment.getValues([
        ENV_KEYS.WEB_APP_PREVIEW_LINK,
      ]);
      const webAppPreviewRegex = new RegExp(
        WEB_APP_PREVIEW_LINK.replace('{PR_NUMBER}', '[0-9]+')
          .split('//')[1]
          .split('/')[0],
      );

      if (
        webAppPreviewRegex.test(window.location.href) || // make sure it works in the staging environment
        window.location.href.includes('localhost') // make sure it works in the local environment
      ) {
        useCustomEnvironment = true;
      }
    }

    // need this so we only start-showing the web-preview
    // when we are sure that the custom-env check is done
    store
      .dispatch(setUseCustomEnvironment(useCustomEnvironment))
      .finally(() => {
        setIsEnvReady(true);
      });
  }, []);

  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <View style={styles.container}>
          {isEnvReady && (
            <NavigationContainer documentTitle={{ enabled: false }}>
              <MainNavigatorWeb />
            </NavigationContainer>
          )}
        </View>
        <StatusBar style="dark" />
        <FlashMessage position="top" />
      </Provider>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.appBackground,
    flex: 1,
    width: '100%',
  },
});
