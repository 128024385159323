import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DeviceInfo from 'react-native-device-info';

import { handleNetworkActionErrorSilently } from '../../../App/services/utils';
import {
  ENV_KEYS,
  environment,
  isDevelopmentEnv,
} from '../../../Common/services/utils';
import { isWebPlatform } from '../../../Common/services/utils/AppConstants';
import { AppVersionDetails, VersionStatus } from '../../entities';
import { getAppVersionCompatibilityGraphQLCall } from '../../graphql';
import { mapAppVersionResponseToAppVersionDetails } from '../mappers';

type State = {
  details: AppVersionDetails;
  dismiss: boolean;
  error: string;
};

const initialState: State = {
  details: {
    status: VersionStatus.UNKNOWN,
    message: '',
    androidUrl: '',
    iosUrl: '',
    dismissible: true,
  },

  dismiss: false,
  error: '',
};

export const getAppVersion = createAsyncThunk(
  'AppVersionSlice/getAppVersion',
  async (_, thunkApi) => {
    if (isWebPlatform || isDevelopmentEnv()) {
      return mapAppVersionResponseToAppVersionDetails({
        status: VersionStatus.UNKNOWN,
        message: '',
        androidUpgradeUrl: '',
        iosUpgradeUrl: '',
      });
    }

    try {
      const response = await getAppVersionCompatibilityGraphQLCall({
        version: DeviceInfo.getVersion(),
        product: environment.getValue(ENV_KEYS.PLATFORM_NAME),
      });

      return mapAppVersionResponseToAppVersionDetails(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

const appVersionSlice = createSlice({
  name: 'AppVersionSlice',
  initialState: initialState,
  reducers: {
    resetState(state: State) {
      state = initialState;
      state.dismiss = false;
      state.error = '';
    },

    updateDismissed(state: State) {
      state.dismiss = true;
    },
  },

  extraReducers: builder => {
    builder
      .addCase(getAppVersion.fulfilled, (state, action) => {
        state.details = action.payload as AppVersionDetails;
      })
      .addCase(getAppVersion.rejected, (state, action) => {
        state.error = action.payload as string;
      });
  },
});

export const shouldShowUpdateScreen = (state: State): boolean => {
  if (state.error) {
    return false;
  }

  if (state.details.status === VersionStatus.EXPIRED) {
    return true;
  }

  return state.details.status === VersionStatus.OUTDATED && !state.dismiss;
};

export const isAppVersionExpired = (state: State): boolean => {
  return state.details.status === VersionStatus.EXPIRED;
};

export const isUpdateDismissible = (state: State): boolean => {
  return state.details.dismissible;
};

export const { updateDismissed } = appVersionSlice.actions;
export const AppVersionSlice = appVersionSlice.reducer;
