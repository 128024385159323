import React from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Assets from '../../../App/assets';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';

export const EmptyFeedScreenView = (): React.ReactElement => {
  return (
    <LinearGradient
      style={styles.contentContainer}
      colors={Colors.screenGradient}>
      <View style={styles.subContainer}>
        <View>
          <Image
            style={styles.emojiContainer}
            resizeMode={'cover'}
            source={Assets.test.cuteDisappointedBun.path as ImageSourcePropType}
          />
        </View>
        <View style={styles.messageContainer}>
          <Text style={styles.completionHeading}>
            {locale.study.not_available}
          </Text>
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Sizes.normalizeIP14PM(16),
    paddingHorizontal: Sizes.normalizeIP14PM(16),
  },
  completionHeading: {
    ...Fonts.normalize(Fonts.xxxxlargeBold),
  },
  subContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    top: Sizes.normalizeIP14PM(115),
  },
  messageContainer: {
    paddingVertical: Sizes.normalizeIP14PM(10),
    rowGap: Sizes.normalizeIP14PM(5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emojiContainer: {
    position: 'absolute',
    width: 115,
    height: 115,
    bottom: 0,
    alignSelf: 'center',
  },
});
