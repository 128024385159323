import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import {
  RegisterPurchaseSource,
  RegisterPurchaseType,
} from '../../../entities';
import { HandleProductPurchaseParams } from '../../../entities/PaywallPurchasesActionTypes';
import { registerPurchaseGraphQLCall } from '../../../graphql/mutations/PaywallGraphQLMutation';
import { mapPurchaseSourceBasedOnAppBuild } from '../../mappers';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

export const handleSatBootcampPurchase = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/handleSatBootcampPurchase`,
  async ({ purchase, receipt }: HandleProductPurchaseParams) => {
    try {
      await registerPurchaseGraphQLCall({
        transactionId: purchase.transactionId ?? 'null',
        receiptData: receipt,
        purchasedAt: moment(Math.round(purchase.transactionDate)).toISOString(),
        purchaseType: RegisterPurchaseType.SAT_BOOTCAMP,
        purchaseSource: mapPurchaseSourceBasedOnAppBuild(
          RegisterPurchaseSource.PURCHASED,
        ),
      });
    } catch (e) {
      if (e instanceof Error) {
        handleNetworkActionErrorSilently(e);
      }
    }
  },
);
