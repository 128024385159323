import React from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import BoxedTextContainer from '../../../../Common/components/common/BoxedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import CharacterDescriptionView from '../../../../Common/components/home/CharacterDescriptionView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { ControversialConversationCard } from '../../../../Common/entities';
import {
  getQuestionStyle,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { getCardAttributes, setContentMuted } from '../../../services/slices';

import BaseContentCard from './BaseContentCard';
import homeStyles from './styles';

type Props = {
  item: ControversialConversationCard;
  isVisible: boolean;
};

let titleWidthVar: number | null = null;

export default ({ item, isVisible }: Props): React.ReactElement => {
  const [firstCharacter, secondCharacter] = item.speakers.debaters;
  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const contentMuted = useAppSelector(state => state.questions.contentMuted);

  const isTextVisible = visibilityStyle !== 'no-text';

  const dispatch = useAppDispatch();

  const renderControversialTitle = (): React.ReactElement => {
    const textStyling = getQuestionStyle(item.generatedContentId);
    return (
      <TouchableOpacity activeOpacity={1}>
        <BoxedTextContainer
          style={[
            homeStyles.questionTextContainer,
            styles.contentTypeTitleHeading,
          ]}
          textStyle={{
            ...homeStyles.questionText,
            color: textStyling.textColor,
          }}
          text={item.controversy}
          width={titleWidthVar}
          outlineColor={textStyling.outlinedColor}
          color={textStyling.backgroundColor}
          opacity={0.7}
        />
      </TouchableOpacity>
    );
  };

  const onLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;

    titleWidthVar = width;
  };

  const onCardTap = () => {
    if (isWebPlatform) {
      if (!contentMuted) {
        redirectToAppstore();
      } else {
        dispatch(setContentMuted(false));
      }
      return true;
    }
    return false;
  };

  return (
    <BaseContentCard item={item} onCardTap={onCardTap}>
      <View style={styles.container}>
        <View
          style={styles.textContainer}
          onLayout={!titleWidthVar ? onLayout : undefined}>
          {isTextVisible && (
            <ContentTypeView contentType={item.type} typeName={item.typeName} />
          )}
          {renderControversialTitle()}
          {isTextVisible && (
            <View style={styles.characterContainer}>
              <CharacterDescriptionView
                avatarUrl={firstCharacter.avatarUrl}
                description={firstCharacter.title}
                name={firstCharacter.name}
              />
              <View style={styles.versus}>
                <Text style={styles.versusText}>VS</Text>
              </View>
              <CharacterDescriptionView
                avatarUrl={secondCharacter.avatarUrl}
                description={secondCharacter.title}
                name={secondCharacter.name}
              />
            </View>
          )}
        </View>
      </View>
      <ActionButtonsView item={item} />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
  textContainer: {
    flexGrow: 20,
    justifyContent: 'flex-end',
    paddingBottom: 16,
  },
  contentTypeTitleHeading: {
    marginTop: 5,
    opacity: 0.9,
  },
  versus: {
    backgroundColor: Colors.white29,
    width: 32,
    height: 32,
    borderRadius: 16,
    marginStart: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  versusText: {
    ...Fonts.smallBold,
    textAlign: 'center',
    color: Colors.white90,
  },
  characterContainer: {
    rowGap: 5,
    marginTop: 10,
  },
});
