import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import locale from '../../../../App/locale';
import { handleNetworkActionError } from '../../../../App/services/utils';
import {
  showSuccessMessage,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import {
  Analytics,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { ProfileUpdateEntryPoints } from '../../../../Profile/entities';
import { updateUserDetailsGraphQLCall } from '../../../../Profile/graphql';
import { mapProfileDetailsUpdateAnalytics } from '../../../../Profile/services/mappers';
import { UpdateUserProfileActionParams } from '../../../entities';
import { AUTH_SLICE_NAME, AuthSliceState } from '../AuthSlice';

import { getUserProfile } from './GetUserProfileAction';
import { updateUserIdentifier } from './UpdateUserIdentifierAction';

export const updateUserProfile = createAsyncThunk(
  `${AUTH_SLICE_NAME}/updateUserProfile`,
  async (params: UpdateUserProfileActionParams, thunkApi) => {
    try {
      const { silent, preferredName } = params;

      const response = await updateUserDetailsGraphQLCall({
        preferredName,
      });

      if (!response.success) {
        throw new Error(response.message);
      }

      trackAnalyticsEvent(
        Analytics.profileDetailsUpdated,
        mapProfileDetailsUpdateAnalytics({
          from: ProfileUpdateEntryPoints.PROFILE,
          nameUpdated: true,
        }),
      );

      if (!isWebPlatform) {
        thunkApi.dispatch(updateUserIdentifier({}));
      }

      await thunkApi
        .dispatch(getUserProfile({ silent: true, skipAfterAuth: true }))
        .unwrap();

      if (!silent) {
        showSuccessMessage({
          message: locale.profile_screen_settings.profile_updated_message,
        });
      }
      return response;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const updateUserProfileExtraReducers = (
  builder: ActionReducerMapBuilder<AuthSliceState>,
): void => {
  builder.addCase(
    updateUserProfile.pending,
    (state: AuthSliceState, action) => {
      const { preferredName } = action.meta.arg;

      if (state.authUser && preferredName) {
        state.authUser.preferredName = action.meta.arg.preferredName;
      }
    },
  );
};
