import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Sentry from '@sentry/react-native';
import axios from 'axios';
import moment from 'moment';
import { z } from 'zod';

import {
  API_KEY,
  APPSFLYER_APPID,
  APPSFLYER_DEV_KEY,
  APP_BUCKET_URL,
  AWS_AUTHENTICATION_TYPE,
  AWS_BUCKET_NAME,
  AWS_CUSTOM_ENV_URL_PREFIX,
  AWS_POOL_WEB_CLIENT_ID,
  AWS_REGION,
  AWS_USER_POOL_ID,
  CONTENT_URL_PREFIX,
  ENVIRONMENT_CONFIG_S3_URL,
  EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT,
  FEEDBACK_GOOGLE_FORM_ENDPOINT,
  GRAPHQL_ENDPOINT,
  IDENTITY_POOL_ID,
  MIXPANEL_DEV_TOKEN,
  MIXPANEL_TEACHTAP_PROD_TOKEN,
  PLATFORM_NAME,
  SEGMENT_API_DEV_KEY,
  SEGMENT_API_KEY,
  SENTRY_DSN,
  WEB_APP_LINK,
  WEB_APP_PREVIEW_LINK,
  WEB_APP_REDIRECT_LINK,
} from '../../../../environment';
import { handleRequestError } from '../../../App/services/requests';
import {
  NetworkError,
  handleNetworkActionError,
} from '../../../App/services/utils';
import store from '../../../App/store';
import { resetEnvironment } from '../slices';

import { logger } from './Logger';
import { throttleAsync } from './Throttle';

export enum ENV_KEYS {
  API_KEY = 'API_KEY',
  AWS_REGION = 'AWS_REGION',
  AWS_USER_POOL_ID = 'AWS_USER_POOL_ID',
  AWS_POOL_WEB_CLIENT_ID = 'AWS_POOL_WEB_CLIENT_ID',
  AWS_AUTHENTICATION_TYPE = 'AWS_AUTHENTICATION_TYPE',
  SENTRY_DSN = 'SENTRY_DSN',
  IDENTITY_POOL_ID = 'IDENTITY_POOL_ID',
  AWS_BUCKET_NAME = 'AWS_BUCKET_NAME',
  AWS_CUSTOM_ENV_URL_PREFIX = 'AWS_CUSTOM_ENV_URL_PREFIX',
  CONTENT_URL_PREFIX = 'CONTENT_URL_PREFIX',
  GRAPHQL_ENDPOINT = 'GRAPHQL_ENDPOINT',
  WEB_APP_LINK = 'WEB_APP_LINK',
  WEB_APP_PREVIEW_LINK = 'WEB_APP_PREVIEW_LINK',
  WEB_APP_REDIRECT_LINK = 'WEB_APP_REDIRECT_LINK',
  FEEDBACK_GOOGLE_FORM_ENDPOINT = 'FEEDBACK_GOOGLE_FORM_ENDPOINT',
  EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT = 'EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT',
  SEGMENT_API_KEY = 'SEGMENT_API_KEY',
  SEGMENT_API_DEV_KEY = 'SEGMENT_API_DEV_KEY',
  PLATFORM_NAME = 'PLATFORM_NAME',
  APP_BUCKET_URL = 'APP_BUCKET_URL',
  APPSFLYER_DEV_KEY = 'APPSFLYER_DEV_KEY',
  APPSFLYER_APPID = 'APPSFLYER_APPID',
  MIXPANEL_DEV_TOKEN = 'MIXPANEL_DEV_TOKEN',
  MIXPANEL_TEACHTAP_PROD_TOKEN = 'MIXPANEL_TEACHTAP_PROD_TOKEN',
}

type EnvironmentConfig = Record<ENV_KEYS, string>;

const EnvironmentConfigSchema = z.object(
  Object.values(ENV_KEYS).reduce((acc, key) => {
    acc[key] = z.string().optional();
    return acc;
  }, {} as Record<ENV_KEYS, z.ZodOptional<z.ZodString>>),
);

const FALLBACK_VALUES: EnvironmentConfig = {
  [ENV_KEYS.API_KEY]: API_KEY,
  [ENV_KEYS.AWS_REGION]: AWS_REGION,
  [ENV_KEYS.AWS_USER_POOL_ID]: AWS_USER_POOL_ID,
  [ENV_KEYS.AWS_POOL_WEB_CLIENT_ID]: AWS_POOL_WEB_CLIENT_ID,
  [ENV_KEYS.AWS_AUTHENTICATION_TYPE]: AWS_AUTHENTICATION_TYPE,
  [ENV_KEYS.SENTRY_DSN]: SENTRY_DSN,
  [ENV_KEYS.IDENTITY_POOL_ID]: IDENTITY_POOL_ID,
  [ENV_KEYS.AWS_BUCKET_NAME]: AWS_BUCKET_NAME,
  [ENV_KEYS.AWS_CUSTOM_ENV_URL_PREFIX]: AWS_CUSTOM_ENV_URL_PREFIX,
  [ENV_KEYS.CONTENT_URL_PREFIX]: CONTENT_URL_PREFIX,
  [ENV_KEYS.GRAPHQL_ENDPOINT]: GRAPHQL_ENDPOINT,
  [ENV_KEYS.WEB_APP_LINK]: WEB_APP_LINK,
  [ENV_KEYS.WEB_APP_PREVIEW_LINK]: WEB_APP_PREVIEW_LINK,
  [ENV_KEYS.WEB_APP_REDIRECT_LINK]: WEB_APP_REDIRECT_LINK,
  [ENV_KEYS.FEEDBACK_GOOGLE_FORM_ENDPOINT]: FEEDBACK_GOOGLE_FORM_ENDPOINT,
  [ENV_KEYS.EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT]:
    EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT,
  [ENV_KEYS.SEGMENT_API_KEY]: SEGMENT_API_KEY,
  [ENV_KEYS.SEGMENT_API_DEV_KEY]: SEGMENT_API_DEV_KEY,
  [ENV_KEYS.PLATFORM_NAME]: PLATFORM_NAME,
  [ENV_KEYS.APP_BUCKET_URL]: APP_BUCKET_URL,
  [ENV_KEYS.APPSFLYER_DEV_KEY]: APPSFLYER_DEV_KEY,
  [ENV_KEYS.APPSFLYER_APPID]: APPSFLYER_APPID,
  [ENV_KEYS.MIXPANEL_DEV_TOKEN]: MIXPANEL_DEV_TOKEN,
  [ENV_KEYS.MIXPANEL_TEACHTAP_PROD_TOKEN]: MIXPANEL_TEACHTAP_PROD_TOKEN,
};

class DynamicEnvironment {
  private readonly configKey = 'environmentConfig';
  private readonly cacheExpirationKey = 'cacheExpiration';

  private static instance: DynamicEnvironment;
  private _loaded: boolean = false;

  // This lock is used to prevent multiple loads from storage (values won't change)
  private static _loading: boolean = false;
  // This lock is used to prevent multiple concurrent fetches from S3, ensuring the values from the latest fetch are saved.
  // We don't allow fetching while loading from storage to prevent race conditions
  private static _fetching: boolean = false;

  private cache: Partial<EnvironmentConfig> = {};
  private cacheExpiration: number = 0;

  private constructor() {}

  static getInstance(): DynamicEnvironment {
    if (!DynamicEnvironment.instance) {
      DynamicEnvironment.instance = new DynamicEnvironment();
    }
    return DynamicEnvironment.instance;
  }

  private updateCache(
    cache: Partial<EnvironmentConfig>,
    expiration?: number,
  ): void {
    this.cache = cache;
    this.cacheExpiration = expiration || moment().add(24, 'hours').valueOf();
    this.resetEnvironment();
  }

  private async fetchFromS3(force?: boolean): Promise<void> {
    try {
      while (DynamicEnvironment._fetching) {
        logger.debug('Waiting for lock to be released to fetchFromS3');
        await new Promise(resolve => setTimeout(resolve, 100));
      }

      if (!force && !this.isCacheExpired()) {
        logger.debug('Environment config from S3 is up to date');
        return;
      }

      DynamicEnvironment._fetching = true;
      logger.debug('Fetching environment config from S3...');
      const response = await axios.get(ENVIRONMENT_CONFIG_S3_URL, {
        headers: {
          'Cache-Control': 'no-store',
        },
      });
      const parsedData = EnvironmentConfigSchema.safeParse(response.data);

      if (parsedData.success) {
        logger.debug('Fetched environment config from S3');
        this.updateCache(parsedData.data);
        await this.saveToStorage();
      } else {
        logger.error('Invalid environment config from S3:');
        Sentry.captureException(parsedData.error.name, {
          extra: parsedData.error.format(),
        });
      }

      handleRequestError(response);
    } catch (error) {
      logger.error('Failed to fetch environment config from S3:');
      handleNetworkActionError(error as NetworkError);
    } finally {
      DynamicEnvironment._fetching = false;
    }
  }

  private async loadFromStorage(): Promise<boolean> {
    if (DynamicEnvironment._loading) {
      logger.debug(
        'Skipping loadFromStorage because it is already in progress',
      );
      return false;
    }
    try {
      DynamicEnvironment._loading = true;
      DynamicEnvironment._fetching = true;
      logger.debug('Loading environment config from storage...');
      const [cachedData, cachedExpiration] = await Promise.all([
        AsyncStorage.getItem(this.configKey),
        AsyncStorage.getItem(this.cacheExpirationKey),
      ]);
      if (cachedData && cachedExpiration) {
        const parsedData = EnvironmentConfigSchema.safeParse(
          JSON.parse(cachedData),
        );
        if (parsedData.success) {
          logger.debug('Loaded environment config from storage');
          this.updateCache(parsedData.data, parseInt(cachedExpiration, 10));
          return true;
        } else {
          logger.error(
            'Invalid environment config from storage: ' +
              JSON.stringify(parsedData.error),
          );
        }
      }
    } catch (error) {
      logger.error('Failed to load environment config from storage:');
      Sentry.captureException(error);
    } finally {
      DynamicEnvironment._loading = false;
      DynamicEnvironment._fetching = false;
    }
    return false;
  }

  private async saveToStorage(): Promise<void> {
    try {
      logger.debug('Saving environment config to storage...');
      await Promise.all([
        AsyncStorage.setItem(this.configKey, JSON.stringify(this.cache)),
        AsyncStorage.setItem(
          this.cacheExpirationKey,
          this.cacheExpiration.toString(),
        ),
      ]);
      logger.debug('Saved environment config to storage');
    } catch (error) {
      logger.error('Failed to save environment config to storage:');
      Sentry.captureException(error);
    }
  }

  private isCacheExpired(): boolean {
    return Date.now() > this.cacheExpiration;
  }

  private throttledFetchFromS3 = throttleAsync(() => this.fetchFromS3(), 1000);
  private async reloadIfExpired(): Promise<void> {
    logger.debug('Reloading environment if expired');
    if (Object.keys(this.cache).length === 0) {
      await this.loadFromStorage();
      // We always fetch the config from S3 on App Launch even if the config is cached
      // But we don't wait for it to finish
      this.fetchFromS3(true);
    } else {
      if (this.isCacheExpired()) {
        await this.throttledFetchFromS3();
      }
    }
  }

  async load() {
    if (this._loaded) {
      return;
    }
    const loaded = await this.loadFromStorage();

    // We always fetch the config from S3 on App Launch even if the config is cached
    // The difference is that we wait for the fetch to finish if the config is not cached
    if (!loaded) {
      await this.fetchFromS3(true);
    } else {
      this.fetchFromS3(true);
    }
    this._loaded = true;
  }

  private resetEnvironment(): void {
    logger.debug('Resetting environment');
    store.dispatch(resetEnvironment());
  }

  /**
   * Invalidate the cached value for a specific key
   * @param key - The key to invalidate
   */
  public invalidateCachedValue(key: ENV_KEYS): void {
    logger.debug('Invalidating cached value for key:');
    delete this.cache[key];
    this.cacheExpiration = 0;
    this.saveToStorage().then(() => {
      this.resetEnvironment();
    });

    // Once the cache is invalidated, we need to reload the config
    this.reloadIfExpired();
  }

  private getConfigValue(key: ENV_KEYS): string {
    return this.cache[key] || FALLBACK_VALUES[key];
  }

  getValue(key: ENV_KEYS): string {
    return this.getConfigValue(key);
  }

  getValues(keys: ENV_KEYS[]): Record<ENV_KEYS, string> {
    const values: Record<ENV_KEYS, string> = {} as Record<ENV_KEYS, string>;
    for (const key of keys) {
      values[key] = this.getConfigValue(key);
    }
    return values;
  }
}

export const environment: DynamicEnvironment = DynamicEnvironment.getInstance();
