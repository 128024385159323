import { UnifiedDeepLinkData } from 'react-native-appsflyer';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import store, { RootState } from '../../../App/store';
import { SelectedCourseEntryPoint } from '../../../CourseEnrollment/entities';
import { setCurrentSelectedCourse } from '../../../CourseEnrollment/services/slices';
import {
  getAICard,
  getNextUnitAndTopicForCourseSelector,
} from '../../../Learn/services/slices';
import { getDMFigures } from '../../../Messages/services/slices';
import { resetState } from '../../../TestPrep/services/slices';
import { mapCourseUnitLockedStatus } from '../../../UserAccess/services/mappers';
import { DEEPLINK_SCHEME } from '../../data';
import {
  Course,
  FeedType,
  PaywallEntryPoint,
  Topic,
  Unit,
} from '../../entities';

import { trackAnalyticsEvent } from './AnalyticsUtil';
import { Analytics } from './AppConstants';
import {
  redirectToFeed,
  redirectToInbox,
  redirectToPaywall,
  redirectToProfile,
  redirectToProfileManageCourses,
  redirectToStudy,
  redirectToTestPrep,
  redirectToUnitTest,
} from './RedirectionUtils';

/**
 * Handles the deep link by redirecting the user to the correct screen and setting the correct state
 * Supports both deeplinks with scheme teachtap and teachtap.onelink.me links
 * For onelinks, the deep_link_value is the screen name
 *
 * You can test deep links in the iOS Simulator by running the command below
 * xcrun simctl openurl booted '<deeplink>'
 *
 * @param data The deep link data
 * @param state The current state
 * @returns void
 */
export const handleDeepLink = async (
  data: UnifiedDeepLinkData,
  state: RootState,
): Promise<void> => {
  if (data?.status !== 'success') {
    return;
  }

  const screen =
    data?.data?.scheme === DEEPLINK_SCHEME
      ? data?.data.host
      : data?.data.deep_link_value;

  // For each screen where we want to handle the deep link, we add a handler here
  // The handler is responsible for redirecting the user to the correct screen and setting the correct state
  // The handler receives the current RootState and the UnifiedDeepLinkData
  if (RootNavigatorRef.isReady()) {
    const handlers: Record<
      string,
      (state: RootState, data: UnifiedDeepLinkData) => Promise<void>
    > = {
      testprep: handleTestPrep,
      startunittest: handleStartUnitTest,
      study: handleStudy,
      feed: handleFeed,
      inbox: handleInbox,
      profile: handleProfile,
      managecourses: handleManageCourses,
    };

    if (handlers[screen]) {
      await handlers[screen]?.(state, data);
      trackAnalyticsEvent(Analytics.deepLinkSucceeded, {
        link: data.data.link,
      });
    } else {
      trackAnalyticsEvent(Analytics.deepLinkFailed, {
        link: data.data.link,
        error: locale.deep_links.no_handler_error,
      });
    }
  }
};

// Handles the deeplinks in the cases below
// teachtap://testprep
// teachtap.onelink.me with deep_link_value=testprep
// Possible attributes: courseId(optional)
// Refer to @readDeepLinkAttributes for the deep link attribute mapping
// Example: teachtap://testprep?courseId=f92552c0-1bfe-11ee-abde-edaa52f0420b
// If the user is not enrolled in the course, the user will be redirected to the test prep screen
const handleTestPrep = async (state: RootState, data?: UnifiedDeepLinkData) => {
  const { courseId } = readDeepLinkAttributes(data);
  if (courseId) {
    store.dispatch(
      setCurrentSelectedCourse({
        id: courseId,
        tab: SelectedCourseEntryPoint.TEST_PREP,
      }),
    );
  }
  const shouldShowCourseManagement =
    !!courseId && !isEnrolledInCourse(state, courseId);
  redirectToTestPrep(shouldShowCourseManagement);
};

// Handles the deeplinks in the cases below
// teachtap://startunittest
// teachtap.onelink.me with deep_link_value=startunittest
// Possible attributes: courseId and unitNumber
// If the test is behind a paywall, the user will be redirected to the paywall screen
// Refer to @readDeepLinkAttributes for the deep link attribute mapping
// Example: teachtap://startunittest?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b&unitNumber=1
// Example: teachtap://startunittest?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b&unitNumber=2
const handleStartUnitTest = async (
  state?: RootState,
  data?: UnifiedDeepLinkData,
) => {
  const { courseId, unitNumber } = readDeepLinkAttributes(data);
  if (!courseId || !unitNumber) {
    return;
  }
  if (isNaN(unitNumber)) {
    return;
  }
  const course = state?.courses.coursesMap[courseId];
  const unit = course?.units?.find(u => u.number === unitNumber);
  if (!course || !unit) {
    return;
  }

  const isUnitLocked = mapCourseUnitLockedStatus(state, courseId, unit.id);
  if (isUnitLocked) {
    redirectToPaywall(course, PaywallEntryPoint.TEST_PREP);
    return;
  }
  store.dispatch(resetState());
  redirectToUnitTest(course, unit);
};

// Handles the deeplinks in the cases below
// teachtap://study
// teachtap.onelink.me with deep_link_value=study
// Possible attributes: courseId or contentId
// Refer to @readDeepLinkAttributes for the deep link attribute mapping
// Examples:
// teachtap://study?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b
// teachtap://study?contentId=00006fa7-a62e-4169-a38f-7086683836ff
const handleStudy = async (state: RootState, data?: UnifiedDeepLinkData) => {
  // either courseId or contentId is required
  const { courseId, contentId } = readDeepLinkAttributes(data);
  if (contentId) {
    // Warning: this is not supported if the currently selected course doesn't support the Learn Tab
    const content = await store
      .dispatch(getAICard({ itemId: contentId, feedId: FeedType.Messages }))
      .unwrap();
    if (!content.unit || !content.topic) {
      return;
    }
    redirectToFeed(content.course, content.unit, content.topic, content);
    return;
  }

  if (courseId) {
    await store.dispatch(
      setCurrentSelectedCourse({
        id: courseId,
        tab: SelectedCourseEntryPoint.STUDY,
      }),
    );
  }
  const shouldShowCourseManagement =
    !!courseId && !isEnrolledInCourse(state, courseId);
  redirectToStudy(shouldShowCourseManagement);
};

// Handles the deeplinks in the cases below
// teachtap://feed
// teachtap.onelink.me with deep_link_value=feed
// Possible attributes: courseId, unitId(optional), topicId(optional)
// Refer to @readDeepLinkAttributes for the deep link attribute mapping
// Examples:
// teachtap://feed?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b
// teachtap://feed?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b&unitId=f8a1079b-1bfe-11ee-abde-edaa52f0420b
// teachtap://feed?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b&topicId=f8a1079e-1bfe-11ee-abde-edaa52f0420b
// teachtap://feed?courseId=f89e2160-1bfe-11ee-abde-edaa52f0420b&unitId=f8a1079b-1bfe-11ee-abde-edaa52f0420b&topicId=f8a1079e-1bfe-11ee-abde-edaa52f0420b
const handleFeed = async (state: RootState, data?: UnifiedDeepLinkData) => {
  // courseId + unitId(optional) + topicId(optional)
  const { courseId, unitId, topicId } = readDeepLinkAttributes(data);
  if (!courseId) {
    return;
  }
  await store.dispatch(
    setCurrentSelectedCourse({
      id: courseId,
      tab: SelectedCourseEntryPoint.STUDY,
    }),
  );
  const { course, unit, topic } = computeCourseUnitTopic(
    state,
    courseId,
    unitId,
    topicId,
  );
  if (!unit || !topic) {
    return;
  }
  const shouldShowCourseManagement = !isEnrolledInCourse(state, courseId);
  redirectToFeed(course, unit, topic, undefined, shouldShowCourseManagement);
};

// Handles the deeplinks in the cases below
// teachtap://inbox
// teachtap.onelink.me with deep_link_value=inbox
// Possible attributes: tutorId and courseId
// courseId is currently not used
// Refer to @readDeepLinkAttributes for the deep link attribute mapping
// Example: teachtap://inbox?tutorId=7316eca1-b672-45a8-8502-cb0bf11e8c04&courseId=3491d53d-d196-4c36-b948-2787e26a5b54
const handleInbox = async (state?: RootState, data?: UnifiedDeepLinkData) => {
  const { tutorId } = readDeepLinkAttributes(data);
  if (!tutorId) {
    return;
  }
  const speakers =
    state?.messages.speakers && state.messages.speakers.length > 0
      ? state.messages.speakers
      : (await store.dispatch(getDMFigures()).unwrap()).speakers;
  const tutor = speakers?.find(s => s.platformId === tutorId);
  if (!tutor) {
    return;
  }
  redirectToInbox(tutor);
};

// Handles the deeplinks in the cases below
// teachtap://profile
// teachtap.onelink.me with deep_link_value=profile
const handleProfile = async (state?: RootState, data?: UnifiedDeepLinkData) => {
  redirectToProfile();
};

// Handles the deeplinks in the cases below
// teachtap://managecourses
// teachtap.onelink.me with deep_link_value=managecourses
const handleManageCourses = async (
  state?: RootState,
  data?: UnifiedDeepLinkData,
) => {
  redirectToProfileManageCourses();
};

type DeepLinkAttributes = {
  courseId?: string;
  unitId?: string;
  unitNumber?: number;
  topicId?: string;
  contentId?: string;
  tutorId?: string;
};

/**
 * Reads the deep link attributes from the deep link data.
 * Supports both the ideal attribute names and the onelink.me ones with better deferred deep link support
 * The mapping is as follows:
 * - courseId: deep_link_sub1
 * - unitId: deep_link_sub2
 * - unitNumber: deep_link_sub2
 * - topicId: deep_link_sub3
 * - contentId: deep_link_sub4
 * - tutorId: deep_link_sub5
 * @param data The deep link data
 * @returns The deep link attributes
 */
const readDeepLinkAttributes = (
  data?: UnifiedDeepLinkData,
): DeepLinkAttributes => {
  return {
    courseId: data?.data.courseId ?? data?.data.deep_link_sub1,
    unitId: data?.data.unitId ?? data?.data.deep_link_sub2,
    unitNumber: Number(data?.data.unitNumber ?? data?.data.deep_link_sub2),
    topicId: data?.data.topicId ?? data?.data.deep_link_sub3,
    contentId: data?.data.contentId ?? data?.data.deep_link_sub4,
    tutorId: data?.data.tutorId ?? data?.data.deep_link_sub5,
  };
};

/**
 * Computes the course, unit, and topic for the given courseId, unitId, and topicId.
 * If unitId is defined, we will return the unit based on the unitId.
 * If unitId is not defined and topicId is defined, we will return the unit and topic based on the topicId.
 * If topicId is defined, we will return the topic based on the topicId.
 * If unitId and topicId are not defined, we will return the next unit and topic based on the course progress.
 * If the unit or topic is not found, we will return the first unit and topic.
 * @param state The state
 * @param courseId The course id
 * @param unitId The unit id
 * @param topicId The topic id
 * @returns The course, unit, and topic
 */
const computeCourseUnitTopic = (
  state: RootState,
  courseId: string,
  unitId?: string,
  topicId?: string,
): {
  course: Course;
  unit?: Unit;
  topic?: Topic;
} => {
  const course = state.courses.coursesMap[courseId];
  const nextUnitAndTopic = getNextUnitAndTopicForCourseSelector(state, course);
  const unit =
    (unitId
      ? course?.units?.find(u => u.id === unitId)
      : course?.units?.find(u => u.topics.some(t => t.id === topicId))) ??
    nextUnitAndTopic?.unit ??
    course?.units[0];
  const topic =
    unit?.topics?.find(t => t.id === topicId) ??
    nextUnitAndTopic.topic ??
    unit?.topics[0];
  return { course, unit, topic };
};

/**
 * Checks if the user is enrolled in the given course.
 * @param state The state
 * @param courseId The course id
 * @returns True if the user is enrolled in the course, false otherwise
 */
const isEnrolledInCourse = (state: RootState, courseId: string) => {
  return state.courseEnrollments.following.find(e => e.id === courseId);
};
