import React, { ReactElement, useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { Course, PaywallEntryPoint } from '../../Common/entities';
import { useProgramCourseNameGetter } from '../../Common/services/hooks';
import {
  getCourseGoalsFromCourse,
  isAPCourse,
  isSATCourse,
} from '../../Common/services/utils';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { setFollowGoalPopup } from '../../Popup/services/slices';
import { CourseTagType } from '../entities';
import { useGetCourseTag } from '../services/hooks';
import { unenrollUserFromCourse } from '../services/slices';
import {
  enrollUserToAPCourse,
  enrollUserToSATCourse,
} from '../services/slices/CourseEnrllmentsSliceActions';

import { renderNullableCourseFollowButton } from './CourseFollowButton';
import { CourseTag } from './CourseTag';

const PROFILE_IMAGE_SIZE = 26;
const DEFAULT_FOLLOW_BUTTON_WIDTH = 72;

type Props = {
  course: Course;
  entryPoint: PaywallEntryPoint;
  isSelectedCourse: boolean;
  onFollowPress: (course: Course) => void;
};

export const CoursePickerItem = (props: Props): ReactElement<any, any> => {
  const dispatch = useAppDispatch();
  const courseName = useProgramCourseNameGetter(props.course);

  const isCourseSelected = useMemo(
    () => props?.isSelectedCourse || false,
    [props.isSelectedCourse],
  );
  const tagStyles = useMemo(() => {
    if (!isCourseSelected) {
      return {
        containerStyle: undefined,
        imageFill: Colors.black50,
        textStyle: undefined,
      };
    }

    return {
      containerStyle: styles.selectedCoursePurchaseContainer,
      imageFill: 'white',
      textStyle: styles.selectedText,
    };
  }, [isCourseSelected]);

  const isFollowed = useAppSelector(
    state =>
      state.courseEnrollments.following.find(
        item => item.id === props.course?.id,
      ) !== undefined,
  );

  const courseEnrollmentsCount = useAppSelector(
    state => {
      return (
        state.courseEnrollments.coursesEnrollmentCount[
          props.course?.id || ''
        ] || 0
      );
    },
    (prev, next) => prev === next,
  );

  const courseTag = useGetCourseTag({ course: props.course });

  const unitText = useMemo(() => {
    const unitsLength = props.course?.units?.length || 0;

    return locale.courses.total_units.replace(
      '${COUNT}',
      unitsLength.toLocaleString('en-US'),
    );
  }, [props.course?.units?.length]);

  const studyingText = useMemo(() => {
    return locale.courses.total_enrollments.replace(
      '${COUNT}',
      courseEnrollmentsCount.toLocaleString('en-US'),
    );
  }, [courseEnrollmentsCount]);

  const handleFollowPress = useCallback(async () => {
    if (!props.course?.grade) {
      return;
    }

    let enrolled = false;

    if (isFollowed) {
      dispatch(unenrollUserFromCourse(props.course));
    } else if (isSATCourse(props.course)) {
      enrolled = await dispatch(
        enrollUserToSATCourse({
          course: props.course,
          paywallEntrypoint: props.entryPoint,
          isFromCoursePicker: true,
        }),
      ).unwrap();
    } else if (isAPCourse(props.course)) {
      enrolled = await dispatch(
        enrollUserToAPCourse({
          course: props.course,
          paywallEntrypoint: props.entryPoint,
          isFromCoursePicker: true,
        }),
      ).unwrap();
    } else {
      enrolled = true;
      dispatch(
        setFollowGoalPopup({
          course: props.course,
          goalsList: getCourseGoalsFromCourse(props.course),
          entryPoint: props.entryPoint,
          isFromCoursePicker: true,
        }),
      );
    }

    if (enrolled) {
      props.onFollowPress(props.course);
    }
  }, [dispatch, isFollowed, props]);

  return (
    <View style={styles.container}>
      {courseTag.isVisible && (
        <CourseTag
          containerStyle={tagStyles.containerStyle}
          imageFill={tagStyles.imageFill}
          textStyle={tagStyles.textStyle}
          isFreeTrial={courseTag.tag === CourseTagType.FREETRIAL}
        />
      )}
      <View style={styles.titleRow}>
        <FastImage
          style={styles.profileImage}
          source={{ uri: props?.course?.avatar || '' }}
        />

        <View style={styles.titleTextContainer}>
          <Text
            numberOfLines={2}
            style={[styles.titleText, isCourseSelected && styles.selectedText]}>
            {courseName}
          </Text>
          {!isFollowed && (
            <View style={styles.courseDetailContainer}>
              <Text
                style={[
                  styles.courseDetailText,
                  isCourseSelected && styles.selectedText,
                ]}>
                {unitText}
              </Text>
              <View
                style={[
                  styles.courseDetailSeparator,
                  isCourseSelected && styles.selectedSeparator,
                ]}
              />
              <Text
                style={[
                  styles.courseDetailText,
                  isCourseSelected && styles.selectedText,
                ]}>
                {studyingText}
              </Text>
            </View>
          )}
        </View>

        {renderNullableCourseFollowButton(isFollowed, handleFollowPress)}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  selectedCoursePurchaseContainer: {
    backgroundColor: Colors.white24,
  },
  container: {
    borderRadius: Sizes.semiMedium,
    padding: Sizes.medium,
    maxWidth: Sizes.getAppWindowWidth(),
  },
  expanded: {
    flex: 1,
  },
  titleRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleTextContainer: {
    marginHorizontal: 12,
    maxWidth: Sizes.getAppWindowWidth() - 200,
    justifyContent: 'center',
    flex: 1,
  },
  titleText: {
    ...Fonts.caption,
    color: Colors.black80,
  },
  selectedText: {
    color: 'white',
  },
  profileImage: {
    width: PROFILE_IMAGE_SIZE,
    height: PROFILE_IMAGE_SIZE,
    borderRadius: PROFILE_IMAGE_SIZE / 2,
  },
  followButtonContainer: {
    justifyContent: 'center',
    minWidth: DEFAULT_FOLLOW_BUTTON_WIDTH,
  },
  followButtonActive: {
    alignItems: 'center',
    backgroundColor: Colors.buttonGreen,
    borderRadius: 6,
    justifyContent: 'center',
    paddingHorizontal: Sizes.xsmall,
    paddingVertical: 6,
  },
  buttonText: {
    ...Fonts.small,
    lineHeight: isAndroidPlatform ? 18 : undefined,
  },

  courseDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  courseDetailText: {
    ...Fonts.xsmall,
    fontSize: 12,
    color: Colors.black50,
  },
  courseDetailSeparator: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: Colors.black50,
    marginHorizontal: Sizes.small,
  },
  selectedSeparator: {
    backgroundColor: 'white',
  },
});
