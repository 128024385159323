import { createAsyncThunk } from '@reduxjs/toolkit';

import { setPreGeneratedMessages } from '../../../../TestPrep/services/slices';
import { getAppConfigsFromS3Request } from '../../requests';
import { SETTINGS_SLICE_NAME } from '../SettingsSlice';

import { proceedEnvSelectionFromConfigs } from './ProceedEnvSelectionFromConfigsAction';

export const getAppConfigsFromS3 = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/getAppConfigsFromS3`,
  async (_, thunkAPI) => {
    const configsData = await getAppConfigsFromS3Request();

    thunkAPI.dispatch(
      setPreGeneratedMessages({
        math: configsData?.testPrepMathPreGeneratedMessages || [],
        testPrep: configsData?.testPrepPreGeneratedMessages || [],
      }),
    );

    thunkAPI.dispatch(
      proceedEnvSelectionFromConfigs({
        stagingBuildNumber: configsData?.stagingBuildNumber,
        stagingEnv: configsData?.stagingEnv,
      }),
    );
  },
);
