import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import {
  PersistConfig,
  persistReducer,
  REHYDRATE,
  RehydrateAction,
} from 'redux-persist';

import { getHistoricalFiguresGraphQLCall } from '../../../AiFigure/graphql';
import { getFigureData } from '../../../AiFigure/services/slices';
import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import store, { RootState } from '../../../App/store';
import {
  Card,
  ContentCard,
  DmsFromDeadCard,
  FeedType,
  AIContentType,
} from '../../../Common/entities';
import { mapGenerateQuestionResponseToContentCard } from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  ScreenNames,
  TabNames,
} from '../../../Common/services/utils/AppConstants';
import {
  getQuestionByIdGraphQLCall,
  recordContentViewGraphQLCall,
} from '../../../Learn/graphql';
import {
  setBookmarkContent,
  setLikeContent,
} from '../../../Learn/services/slices';
import { VideoNotificationType } from '../../../Notification/entities/NotificationTypes';
import { isAppMainPageLoaded } from '../../../Notification/services/slices/NotificationSlice';
import { clearPopups } from '../../../Popup/services/slices';
import { apologyExcuses, messageExcuses } from '../../data';
import {
  AskDMFigureRequestParams,
  DMSpeaker,
  GeneratingMessageItem,
  MessageItem,
  MessageItemIdPayload,
  MessagesDataFromNotification,
  MessageSpeakers,
  PrefillThreshold,
  UserType,
  SetDraftMessagePayload,
  SetSpeakerScreenPayload,
  SaveDmsFromTheDeadCard,
  SetUnreadMessagePayload,
  DeleteUnreadMessagePayload,
  SetStartNewConversationPayload,
  NavigateToMessageScreenPayload,
  DmsFromDeadCards,
} from '../../entities';
import { askDMFigureGraphQLCall } from '../../graphql';
import {
  mapCardToDMFromDeadPayload,
  mapDmsFromDeadQuestionToPayload,
  mapDmsSpeakerToPayload,
  mapAskDMFigureRequest,
  mapMessageSpeakers,
} from '../mappers';
import { MessageDateFormats } from '../utils';
import { generateMessageId } from '../utils';

// Need this, because technically we still able receive the notification (video) from V1.
// this is edge-case that less-likely to be happened, but we still need to handle it.

const GENERATING_MESSAGE_TIMEOUT = 1000 * 60 * 15; // 15 minutes, the max timeout of lambda
const GENERATING_MESSAGE_DELAY = 15000; // 15 seconds

type State = {
  isLoading: boolean;
  error: string | null;

  speakers: DMSpeaker[];
  messages: Record<string, MessageItem[]>;

  // key: speakerPlatformId, value: draft message
  draftMessage: Record<string, string>;

  // key: speakerPlatformId, value: generatedContentId[]
  unreadMessages: Record<string, string[]>;

  receivedDataFromNotification: MessagesDataFromNotification[];

  generatingMessage: Record<string, GeneratingMessageItem>;
  showStartNewConversation: Record<string, boolean>;

  dmsFromTheDead: Record<string, DmsFromDeadCards[]>;
  showDmsMessages: boolean;
  isDMFigureTyping: Record<string, boolean>;
  hasFetchTop50Figures: boolean;
  hasTransformedMessagesToV2: boolean;
  currentSpeakerScreen?: string;
};

const PERSIST_KEY = 'messages';

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'speakers',
    'dmsFromTheDead',
    'hasFetchTop50Figures',
    'hasTransformedMessagesToV2',
    'messages',
    'draftMessage',
    'unreadMessages',
    'showDmsMessages',
    'generatingMessage',
    'showStartNewConversation',
    'receivedDataFromNotification',
  ],
  blacklist: ['currentSpeakerScreen'],
} as PersistConfig<State>;

const initialState: State = {
  isLoading: false,
  error: null,
  dmsFromTheDead: {},

  unreadMessages: {},
  generatingMessage: {},
  showStartNewConversation: {},
  receivedDataFromNotification: [],

  speakers: [],
  messages: {},
  draftMessage: {},
  hasFetchTop50Figures: false,
  hasTransformedMessagesToV2: false,
  isDMFigureTyping: {},
  currentSpeakerScreen: undefined,
  showDmsMessages: false,
};

export const navigateToMessageScreen = createAsyncThunk(
  'MessageSlice/navigateToMessageScreen',
  async (payload: NavigateToMessageScreenPayload, thunkApi) => {
    const isMainPageLoaded = isAppMainPageLoaded();
    const isNavigationReady = RootNavigatorRef?.isReady();

    let speaker = getSpeakerByUsername(
      store.getState().messages,
      payload.username,
    );

    if (!speaker && payload.name) {
      const response = await thunkApi
        .dispatch(getFigureData({ name: payload.name }))
        .unwrap();

      if (response?.data?.platformId) {
        const selectedFigure = response.data;
        const dmFigure: DMSpeaker = {
          name: selectedFigure.name,
          username: selectedFigure.handle,
          avatarUrl: selectedFigure.avatarUrl,
          title: selectedFigure.bio || '',
          platformId: selectedFigure.platformId,
          biography: selectedFigure.socialBio || '',
        };

        thunkApi.dispatch(setNewDmSpeaker(dmFigure));
        speaker = dmFigure;
      }
    }

    if (speaker && isMainPageLoaded && isNavigationReady) {
      thunkApi.dispatch(clearPopups());

      RootNavigatorRef?.current?.reset({
        index: 1,
        routes: [
          {
            name: ScreenNames.MainStack.BOTTOM_TABS,
            params: {
              screen: TabNames.INBOX,
            },
          },
          {
            name: ScreenNames.MainStack.MESSAGE_MAIN,
            params: {
              screen: ScreenNames.MessageStack.MESSAGE_SCREEN,
              params: {
                speaker,
                entryPoint: payload.entryPoint,
              },
            },
          },
        ],
      });
    }
  },
);

export const getDMFiguresIfEmpty = createAsyncThunk(
  'MessageSlice/getDMFiguresIfEmpty',
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      if (!state.messages.speakers.length) {
        thunkApi.dispatch(getDMFigures());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const getDMFigures = createAsyncThunk(
  'MessageSlice/getDMFigures',
  async (_, thunkApi) => {
    try {
      const response = await getHistoricalFiguresGraphQLCall({
        facet: 'Top50Figure',
      });

      return mapMessageSpeakers(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const handleReFetchRequestedMessages = createAsyncThunk(
  'MessageSlice/reFetchRequestedMessages',
  async (_payload, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const generatingMessage = state.messages.generatingMessage;

    const keys = Object.keys(generatingMessage);
    if (!keys.length) {
      return;
    }

    for (let i = 0; i < keys.length; i++) {
      const speakerPlatformId = keys[i];
      const messageToGenerate = generatingMessage[speakerPlatformId];

      thunkApi.dispatch(
        askDMFigure({
          prefillThreshold: PrefillThreshold.useExistingRequest,
          speaker: messageToGenerate.speakerData,
          question: messageToGenerate.message,
        }),
      );
    }
  },
);

export const getRequestedMessage = createAsyncThunk(
  'MessageSlice/getRequestedMessage',
  async (payload: { speaker: DMSpeaker }, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const speaker = payload.speaker;

    const generatingMessage =
      state.messages.generatingMessage[speaker.platformId];

    if (!generatingMessage?.dateTimeLimitString) {
      return;
    }

    const currentDateNumber = new Date().getTime();
    const timeLimit = new Date(generatingMessage.dateTimeLimitString).getTime();

    if (currentDateNumber <= timeLimit) {
      await new Promise(resolve =>
        setTimeout(resolve, GENERATING_MESSAGE_DELAY),
      );

      thunkApi.dispatch(
        askDMFigure({
          speaker,
          question: generatingMessage.message,
          prefillThreshold: PrefillThreshold.useExistingRequest,
        }),
      );
    } else {
      thunkApi.dispatch(
        deleteGeneratingMessage({ speakerPlatformId: speaker.platformId }),
      );
    }
  },
);

export const getAllReceivedDataFromNotification = createAsyncThunk(
  'MessageSlice/triggerReceivedDataFromNotification',
  async (_payload, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const receivedData = rootState.messages.receivedDataFromNotification;

    if (!receivedData.length) {
      return;
    }

    for (let i = 0; i < receivedData.length; i++) {
      const data = receivedData[i];
      thunkApi.dispatch(
        getVideoReceivedFromNotification({
          contentId: data.generatedContentId,
          category: data.category,
        }),
      );
    }

    thunkApi.dispatch(clearReceivedDataFromNotification());
  },
);

export const askDMFigure = createAsyncThunk(
  'MessageSlice/askDMFigure',
  async (
    { speaker, question, prefillThreshold }: AskDMFigureRequestParams,
    thunkApi,
  ) => {
    let state = thunkApi.getState() as RootState;
    const request = mapAskDMFigureRequest(
      speaker,
      question,
      AIContentType.DMsFromTheDead,
      prefillThreshold,
      false,
    );

    trackAnalyticsEvent(Analytics.askDMFigureQuestion, {
      ...mapDmsFromDeadQuestionToPayload(question, {
        username: speaker.username,
        name: speaker.name,
      }),
    });

    try {
      const response = await askDMFigureGraphQLCall(request);
      const allCourses = Object.values(state.courses.coursesMap);

      const course =
        state.courses.coursesMap[response.courseId] || allCourses[0];

      const card = mapGenerateQuestionResponseToContentCard({
        response,
        course,
        unit: course.units[0],
        topic: course.units[0].topics[0],
        feedId: FeedType.Messages,
      }) as DmsFromDeadCard;

      thunkApi.dispatch(
        setLikeContent({ content: card, value: response.isLikedByUser }),
      );

      thunkApi.dispatch(
        setBookmarkContent({
          content: card,
          value: response.isBookmarkedByUser,
        }),
      );

      thunkApi.dispatch(
        saveDmsFromTheDeadCard({
          speakerPlatformId: speaker.platformId,
          card,
        }),
      );

      state = thunkApi.getState() as RootState;
      const isCurrentSpeakerScreenOpen =
        state.messages.currentSpeakerScreen === speaker.platformId;

      if (isCurrentSpeakerScreenOpen) {
        if (card.generatedContentId) {
          recordContentViewGraphQLCall(card.generatedContentId).catch(e => {
            const error: Error = e;
            handleNetworkActionErrorSilently(error);
          });
        }
      } else {
        thunkApi.dispatch(
          setUnreadMessage({
            speakerPlatformId: speaker.platformId,
            generatedContentId: card.generatedContentId,
          }),
        );
      }

      trackAnalyticsEvent(Analytics.answerDMFigureQuestion, {
        ...mapCardToDMFromDeadPayload(card as Card),
      });

      return { card };
    } catch (e: unknown) {
      // On error, need to check if the generating message is still valid
      // if yes, then we don't need to show the error message
      state = thunkApi.getState() as RootState;
      const timeLimitString =
        state.messages.generatingMessage[speaker.platformId]
          .dateTimeLimitString;

      const currentDateNumber = new Date().getTime();
      const timeoutNumber = new Date(timeLimitString).getTime();

      if (currentDateNumber <= timeoutNumber) {
        thunkApi.dispatch(getRequestedMessage({ speaker }));
        return { card: null };
      }

      // Else process the error
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(
          locale.errors.error_generating_response_video,
        );
      }
    }
  },
);

export const recordOpenDmMessageListScreen = createAsyncThunk(
  'MessageSlice/recordOpenDmMessageScreen',
  async (_, _thunkApi) => {
    trackAnalyticsEvent(Analytics.openDmMessageListScreen);
  },
);

// Edge-case for now: Below action will only triggerred when user received the notification from V1
export const getVideoReceivedFromNotification = createAsyncThunk(
  'MessageSlice/getVideoReceivedFromNotification',
  async (payload: MessageItemIdPayload, thunkApi) => {
    try {
      const response = await getQuestionByIdGraphQLCall(payload.contentId);
      let state = thunkApi.getState() as RootState;

      const course = Object.values(state.courses.coursesMap)[0];
      const unit = course?.units[0];
      const topic = unit?.topics[0];

      const mappedCard = mapGenerateQuestionResponseToContentCard({
        course,
        unit: unit!,
        topic: topic!,
        response,
        feedId: FeedType.Messages,
      }) as DmsFromDeadCard;

      let character = mappedCard.character;

      // Edge-case: Since we are using platform-id as the key and tuns-out DMs V1 content doesn't have platformId,
      // we need to ensure the platform-id is available
      if (!character.platformId) {
        state = thunkApi.getState() as RootState;

        let speaker = state.messages.speakers.find(
          speaker => speaker.username === character.username,
        );

        if (!speaker) {
          const response = await thunkApi
            .dispatch(getFigureData({ name: character.name }))
            .unwrap();

          if (response?.data?.platformId) {
            const selectedFigure = response.data;
            const dmFigure: DMSpeaker = {
              name: selectedFigure.name,
              username: selectedFigure.handle,
              avatarUrl: selectedFigure.avatarUrl,
              title: selectedFigure.bio || '',
              platformId: selectedFigure.platformId,
              biography: selectedFigure.socialBio || '',
            };

            thunkApi.dispatch(setNewDmSpeaker(dmFigure));
            speaker = dmFigure;

            character.platformId = dmFigure.platformId;
          }
        }

        if (speaker?.platformId) {
          mappedCard.character.platformId = speaker.platformId;
        } else {
          return thunkApi.rejectWithValue('No figure found');
        }
      }

      state = thunkApi.getState() as RootState;
      const isCurrentSpeakerScreenOpen =
        state.messages.currentSpeakerScreen === character.platformId;

      if (isCurrentSpeakerScreenOpen) {
        if (mappedCard.generatedContentId) {
          recordContentViewGraphQLCall(mappedCard.generatedContentId).catch(
            e => {
              const error: Error = e;
              handleNetworkActionErrorSilently(error);
            },
          );
        }
      } else {
        thunkApi.dispatch(
          setUnreadMessage({
            speakerPlatformId: mappedCard.character.platformId,
            generatedContentId: mappedCard.generatedContentId,
          }),
        );
      }

      return mappedCard;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        return thunkApi.rejectWithValue(error?.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const setSpeakerScreenAndClearUnreadMessages = createAsyncThunk(
  'MessageSlice/setSpeakerScreenAndClearUnreadMessages',
  async (payload: SetSpeakerScreenPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const lastSpeakerScreen = state.messages.currentSpeakerScreen;
    const speakerPlatformId = payload.speakerPlatformId;

    thunkApi.dispatch(setSpeakerScreen(payload));

    if (!speakerPlatformId) {
      if (lastSpeakerScreen) {
        const dmSpeaker = state.messages.speakers.find(
          speaker => speaker.platformId === lastSpeakerScreen,
        );
        if (!dmSpeaker) {
          return;
        }

        trackAnalyticsEvent(
          Analytics.closeDmMessageScreen,
          mapDmsSpeakerToPayload(dmSpeaker, payload.entryPoint),
        );
      }

      return;
    }

    const dmSpeaker = state.messages.speakers.find(
      speaker => speaker.platformId === speakerPlatformId,
    );

    if (dmSpeaker) {
      trackAnalyticsEvent(
        Analytics.openDmMessageScreen,
        mapDmsSpeakerToPayload(dmSpeaker, payload.entryPoint),
      );
    }

    const unreadMessages = [
      ...(state.messages.unreadMessages[speakerPlatformId] || []),
    ];
    if (!unreadMessages?.length) {
      return;
    }

    for (let i = 0; i < unreadMessages.length; i++) {
      const generatedContentId = unreadMessages[i];
      if (generatedContentId) {
        recordContentViewGraphQLCall(generatedContentId).catch(e => {
          const error: Error = e;
          handleNetworkActionErrorSilently(error);
        });
      }
    }

    thunkApi.dispatch(deleteUnreadMessage({ speakerPlatformId }));
  },
);

const messageSlice = createSlice({
  name: 'MessageSlice',
  initialState: initialState,
  reducers: {
    clearMessageData: (state: State) => {
      state.speakers = [];
      state.isLoading = false;
      state.error = null;
      state.messages = {};
      state.dmsFromTheDead = {};
      state.draftMessage = {};
      state.hasTransformedMessagesToV2 = false;
      state.isDMFigureTyping = {};
      state.currentSpeakerScreen = undefined;
      state.unreadMessages = {};
      state.showStartNewConversation = {};
      state.generatingMessage = {};
      state.receivedDataFromNotification = [];
    },
    setDraftMessage: (
      state: State,
      action: { payload: SetDraftMessagePayload },
    ) => {
      const { speakerPlatformId, message } = action.payload;
      state.draftMessage[speakerPlatformId] = message;
    },
    setUserMessages: (state: State, action: { payload: MessageItem }) => {
      const message = action.payload;
      const index = state.speakers.findIndex(
        speaker => speaker.username === message.speakerUsername,
      );
      const speaker = state.speakers[index];

      if (
        state.messages[speaker.platformId] &&
        state.messages[speaker.platformId].length > 0
      ) {
        state.messages[speaker.platformId].push(message);
      } else {
        state.messages[speaker.platformId] = [message];
      }
      if (index !== -1 && message.sender !== UserType.none) {
        state.speakers[index].lastMessage = message;
      }
    },
    setVideoLikeContent: (
      state: State,
      action: { payload: { content: DmsFromDeadCard; value: boolean } },
    ) => {
      const contentCard = action.payload.content;
      const platformId = contentCard.character.platformId;
      const isLiked = action.payload.value;

      if (platformId) {
        const dmFromDeads = state.dmsFromTheDead[platformId]?.filter(
          content =>
            content.generatedContentId === contentCard.generatedContentId,
        );

        if (dmFromDeads.length > 0) {
          dmFromDeads.forEach(dmsFromTheDead => {
            if (isLiked) {
              dmsFromTheDead.totalLikes += 1;
            } else if (dmsFromTheDead.totalLikes > 0) {
              dmsFromTheDead.totalLikes -= 1;
            }
          });
        }
      }
    },
    setVideoBookmarkContent: (
      state: State,
      action: { payload: { content: DmsFromDeadCard; value: boolean } },
    ) => {
      const contentCard = action.payload.content;
      const isBookmarked = action.payload.value;
      const platformId = contentCard.character.platformId;

      if (platformId) {
        const dmFromDeads = state.dmsFromTheDead[platformId]?.filter(
          content =>
            content.generatedContentId === contentCard.generatedContentId,
        );

        if (dmFromDeads.length > 0) {
          dmFromDeads.forEach(dmsFromTheDead => {
            if (isBookmarked) {
              dmsFromTheDead.totalBookmarks += 1;
            } else if (dmsFromTheDead.totalBookmarks > 0) {
              dmsFromTheDead.totalBookmarks -= 1;
            }
          });
        }
      }
    },
    setVideoShareContent: (
      state: State,
      action: { payload: DmsFromDeadCard },
    ) => {
      const contentCard = action.payload;
      const platformId = contentCard.character.platformId;

      if (platformId) {
        const dmFromDeads = state.dmsFromTheDead[platformId]?.filter(
          content => content.uniqueId === contentCard.uniqueId,
        );
        if (dmFromDeads && dmFromDeads.length > 0) {
          const dmsFromTheDead = dmFromDeads[0];
          (dmsFromTheDead as ContentCard).totalShares += 1;
        }
      }
    },
    setNewConversationFlag: (state: State, action: { payload: DMSpeaker }) => {
      const speaker = action.payload;
      const separateMessages = state.messages[speaker.platformId];
      if (separateMessages) {
        state.messages[speaker.platformId] = separateMessages.map(message => ({
          ...message,
          isNewConversation:
            message.sender !== UserType.none
              ? message.isNewConversation
              : false,
        }));
      }
    },
    setNewDmSpeaker: (state: State, action: { payload: DMSpeaker }) => {
      const speaker = action.payload;
      const currentSpeaker = state.speakers.find(
        existingSpeaker => existingSpeaker.username === speaker.username,
      );

      if (!currentSpeaker) {
        state.speakers.push(speaker);
      }
    },

    setShowDmsMessages: (state: State, action: { payload: boolean }) => {
      state.showDmsMessages = action.payload;
    },
    setSpeakerScreen: (
      state: State,
      action: { payload: SetSpeakerScreenPayload },
    ) => {
      // Algorithm on handling this:
      // 1. set the current speaker screen
      const speakerPlatformId = action.payload.speakerPlatformId;
      state.currentSpeakerScreen = speakerPlatformId;

      if (speakerPlatformId && state.messages[speakerPlatformId]?.length) {
        const messageCopy = [...state.messages[speakerPlatformId]];

        // 2. set the new conversation flag to false, because when we re-open the chat, we expect the user to read the messages
        // based on the latest pagination (which may be updated from backend).
        messageCopy.forEach(message => {
          message.isNewConversation = false;
        });
      }
    },
    saveDmsFromTheDeadCard: (
      state: State,
      action: { payload: SaveDmsFromTheDeadCard },
    ) => {
      const { speakerPlatformId, card } = action.payload;
      const dmsFromTheDead = state.dmsFromTheDead[speakerPlatformId] ?? [];

      const existingCardIndex = dmsFromTheDead.findIndex(
        existingCard =>
          existingCard.generatedContentId === card.generatedContentId,
      );

      if (existingCardIndex === -1) {
        state.dmsFromTheDead[speakerPlatformId] = [...dmsFromTheDead, card];
      }
    },
    setUnreadMessage: (
      state: State,
      action: { payload: SetUnreadMessagePayload },
    ) => {
      const { speakerPlatformId, generatedContentId } = action.payload;
      if (!generatedContentId || !speakerPlatformId) {
        return;
      }

      const unreadMessages = state.unreadMessages[speakerPlatformId] ?? [];

      if (!unreadMessages.includes(generatedContentId)) {
        state.unreadMessages[speakerPlatformId] = [
          ...unreadMessages,
          generatedContentId,
        ];
      }
    },
    deleteUnreadMessage: (
      state: State,
      action: { payload: DeleteUnreadMessagePayload },
    ) => {
      const { speakerPlatformId } = action.payload;
      if (state.unreadMessages[speakerPlatformId]) {
        delete state.unreadMessages[speakerPlatformId];
      }
    },
    deleteGeneratingMessage(
      state: State,
      action: { payload: { speakerPlatformId: string } },
    ) {
      delete state.generatingMessage[action.payload.speakerPlatformId];
    },
    setShowStartNewConversation: (
      state: State,
      action: { payload: SetStartNewConversationPayload },
    ) => {
      state.showStartNewConversation[action.payload.speakerPlatformId] =
        action.payload.value;
    },
    clearReceivedDataFromNotification: (state: State) => {
      state.receivedDataFromNotification = [];
    },
    setReceivedDataFromNotification: (
      state: State,
      action: { payload: MessagesDataFromNotification },
    ) => {
      state.receivedDataFromNotification.push(action.payload);
    },
  },
  extraReducers: builder => {
    builder.addCase(getDMFigures.pending, (state: State) => {
      state.isLoading = true;
    });
    builder.addCase(
      getDMFigures.fulfilled,
      (state: State, action: { payload: MessageSpeakers }) => {
        let newSpeakers: DMSpeaker[] = [];

        // need this flag to ensure we don't remove speakers that are not in the top 50
        // because now all speakers are DM-able
        if (!state.hasFetchTop50Figures) {
          const updatedSpeakers = action.payload.speakers.map(newSpeaker => {
            const existingSpeaker = state.speakers.find(
              existing => existing.username === newSpeaker.username,
            );
            return existingSpeaker
              ? { ...existingSpeaker, ...newSpeaker }
              : newSpeaker;
          });

          newSpeakers = updatedSpeakers.filter(newSpeaker =>
            action.payload.speakers.some(
              responseSpeaker =>
                responseSpeaker.username === newSpeaker.username,
            ),
          );

          state.hasFetchTop50Figures = true;
        } else {
          const filteredNewSpeaker = action.payload.speakers.filter(
            newSpeaker =>
              !state.speakers.some(
                existingSpeaker =>
                  existingSpeaker.username === newSpeaker.username,
              ),
          );

          newSpeakers = [...state.speakers, ...filteredNewSpeaker];
        }

        state.speakers = newSpeakers;
        state.isLoading = false;
      },
    );

    builder.addCase(getDMFigures.rejected, (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });

    builder.addCase(getRequestedMessage.pending, (state: State, action) => {
      const { speaker } = action.meta.arg;
      state.isDMFigureTyping[speaker.platformId] = true;
    });

    builder.addCase(askDMFigure.pending, (state: State, action) => {
      const { speaker, question, prefillThreshold } = action.meta.arg;

      state.isLoading = true;
      state.isDMFigureTyping[speaker.platformId] = true;

      if (prefillThreshold === PrefillThreshold.useExistingRequest) {
        return;
      }

      const message: MessageItem = {
        sender: UserType.user,
        message: question,
        date: new Date().toISOString(),
        speakerUsername: speaker.username,
        id: generateMessageId(
          speaker.platformId,
          UserType.user,
          new Date().toISOString(),
          true,
        ),
        isNewConversation: true,
      };

      state.messages[speaker.platformId] =
        state.messages[speaker.platformId] ?? [];
      state.messages[speaker.platformId].push(message);

      const randomMessages =
        messageExcuses[Math.floor(Math.random() * messageExcuses.length)];

      const excuseMessage: MessageItem = {
        sender: UserType.dmFigure,
        message: randomMessages,
        date: new Date().toISOString(),
        speakerUsername: speaker.username,
        id: generateMessageId(
          speaker.platformId,
          UserType.dmFigure,
          new Date().toISOString(),
          true,
        ),
        isNewConversation: true,
      };
      state.messages[speaker.platformId].push(excuseMessage);

      const generatingDate = new Date();

      // Set the generating message to the state
      state.generatingMessage[speaker.platformId] = {
        speakerData: speaker,
        message: question,
        dateString: generatingDate.toISOString(),
        dateTimeLimitString: new Date(
          generatingDate.getTime() + GENERATING_MESSAGE_TIMEOUT,
        ).toISOString(),
      };
      state.showStartNewConversation[speaker.platformId] = true;
    });
    builder.addCase(askDMFigure.fulfilled, (state: State, action) => {
      if (!action.payload.card) {
        return;
      }

      const speaker = action.meta.arg.speaker;
      const currentScreenOpen =
        state.currentSpeakerScreen === speaker.platformId;
      const { timestamp, video, excuse, generatedContentId } = action.payload
        .card as DmsFromDeadCard;

      // Algorithm on handling this:
      // 1. Assign the response to the dmFigure
      const message: MessageItem = {
        sender: UserType.dmFigure,
        message: excuse,
        isVideoMessage: true,
        video: video,
        date: timestamp,
        speakerUsername: speaker.username,
        id: generateMessageId(speaker.platformId, UserType.dmFigure, timestamp),
        generatedContentId: generatedContentId,
        isNewConversation: currentScreenOpen,
      };

      // 2. push the message to the messages for speaker
      state.messages[speaker.platformId] =
        state.messages[speaker.platformId] ?? [];

      if (state.messages[speaker.platformId].length > 0) {
        const dmFigureMessages = state.messages[speaker.platformId].filter(
          m => {
            return m.sender === UserType.dmFigure;
          },
        );

        if (dmFigureMessages.length > 0) {
          const lastDmFigureMessage =
            dmFigureMessages[dmFigureMessages.length - 1];

          if (lastDmFigureMessage.isNewConversation) {
            message.isNewConversation = false;
          }
        }

        const currentMessages = state.messages[speaker.platformId];
        const lastMessage = currentMessages[currentMessages.length - 1];

        const shouldNotPushMessage =
          lastMessage?.sender === UserType.dmFigure &&
          lastMessage?.generatedContentId === message.generatedContentId;
        if (!shouldNotPushMessage) {
          state.messages[speaker.platformId].push(message);
        }
      }

      // 3. Clear generating message flag
      delete state.generatingMessage[speaker.platformId];

      // 4. update the last message of the speaker
      state.isLoading = false;
      state.isDMFigureTyping[speaker.platformId] = false;
    });

    builder.addCase(askDMFigure.rejected, (state: State, action) => {
      const { speaker, prefillThreshold } = action.meta.arg;

      // If rejected on generating message, just return immediately because
      // we need to check (within specific timeframe) if the backend still processing the request
      if (prefillThreshold === PrefillThreshold.generateNewRequest) {
        return;
      }

      const currentScreenOpen =
        state.currentSpeakerScreen === speaker.platformId;

      const messages = state.messages[speaker.platformId] ?? [];
      const lastUserMessage = messages.find(m => {
        return m.id.includes('new-conversation');
      });

      if (lastUserMessage) {
        lastUserMessage.id = generateMessageId(
          speaker.platformId,
          UserType.user,
          lastUserMessage.date,
          false,
          true,
        );
      }

      const randomApologyExcuse =
        apologyExcuses[Math.floor(Math.random() * apologyExcuses.length)];

      const errorMessage: MessageItem = {
        sender: UserType.error,
        message: randomApologyExcuse,
        date: new Date().toISOString(),
        speakerUsername: speaker.username,
        id: generateMessageId(
          speaker.platformId,
          UserType.error,
          new Date().toISOString(),
          false,
          true,
        ),
        isNewConversation: currentScreenOpen,
      };

      messages.push(errorMessage);

      state.messages[speaker.platformId] = messages;

      state.isLoading = false;
      state.isDMFigureTyping[speaker.platformId] = false;
      state.error = action.payload as string;

      delete state.generatingMessage[speaker.platformId];
    });

    builder.addCase(
      getVideoReceivedFromNotification.pending,
      (state: State) => {
        state.isLoading = true;
      },
    );
    builder.addCase(
      getVideoReceivedFromNotification.fulfilled,
      (state: State, action) => {
        const card = action.payload;
        const args = action.meta.arg;

        const key = `${card.character.platformId}`;
        if (!state.dmsFromTheDead[key]) {
          state.dmsFromTheDead[key] = [];
        }

        const existingCardIndex = state.dmsFromTheDead[key].findIndex(
          existingCard =>
            existingCard.generatedContentId === card.generatedContentId,
        );

        if (args.category === VideoNotificationType.VIDEO_NOTIFICATION_V3) {
          // If the card is from V3, we need to clear the question, so it will never
          // visible in the video-message
          card.question = '';
        }

        if (
          existingCardIndex === -1 ||
          args.category === VideoNotificationType.VIDEO_NOTIFICATION_V3
        ) {
          state.dmsFromTheDead[key].push(card);

          const date = new Date(card.timestamp);
          const message: MessageItem = {
            sender: card.sender,
            date: date.toISOString(),
            speakerUsername: card.character.username,
            message: card.excuse,
            id: generateMessageId(
              card.character.platformId,
              UserType.dmFigure,
              card.timestamp,
            ),
            isVideoMessage: !!card.video,
            video: card.video,
            generatedContentId: card.generatedContentId,
            isNewConversation: false,
          };

          if (state.messages[key] && state.messages[key].length > 0) {
            const messageWithSameGeneratedContentId = state.messages[key].find(
              message => message.generatedContentId === card.generatedContentId,
            );

            if (
              !messageWithSameGeneratedContentId ||
              args.category === VideoNotificationType.VIDEO_NOTIFICATION_V3
            ) {
              state.messages[key].push(message);
            }
          } else {
            state.messages[key] = [message];
          }
        }

        state.isLoading = false;
      },
    );

    builder.addCase(
      getVideoReceivedFromNotification.rejected,
      (state: State, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );

    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      if (action.key === PERSIST_KEY) {
        const payload = action.payload as State | undefined;

        // to transform old messages to new format with ISO string and use platformId as the main key
        if (!payload?.hasTransformedMessagesToV2) {
          const transformedMessages: Record<string, MessageItem[]> = {};

          const usernamesOrPlatformIds = Object.keys(payload?.messages ?? {});
          for (let i = 0; i < usernamesOrPlatformIds.length; i++) {
            const usernameOrPlatformId = usernamesOrPlatformIds[i];
            const speaker = payload?.speakers.find(
              speaker => speaker.username === usernameOrPlatformId,
            );

            // use platformId if speaker found
            const selectedKey = speaker?.platformId;

            // if speaker not found, most-likely because we have the messages in platformId
            // or speaker is not in the list of speakers. Keep it as is
            if (!speaker || !selectedKey) {
              transformedMessages[usernameOrPlatformId] =
                payload?.messages[usernameOrPlatformId] ?? [];
              continue;
            }

            // assign the messages to the transformedMessages
            transformedMessages[selectedKey] = (
              payload?.messages[usernameOrPlatformId] ?? []
            ).map(message => {
              let date = new Date(message.date);
              const dateMoment = moment(
                message.date,
                MessageDateFormats.dateWithTimeUS,
              );

              // for compatibility with old "date" format
              if (dateMoment.isValid()) {
                date = dateMoment.toDate();
              }

              return {
                ...message,
                date: date.toISOString(),
              };
            });
          }

          // transform dmsFromTheDead to use platformId as key
          const transformedDmsFromTheDead: Record<string, DmsFromDeadCard[]> =
            {};

          const dmsFromTheDeadUsernamesOrPlatformIds = Object.keys(
            payload?.dmsFromTheDead ?? {},
          );

          for (
            let i = 0;
            i < dmsFromTheDeadUsernamesOrPlatformIds.length;
            i++
          ) {
            const usernameOrPlatform = dmsFromTheDeadUsernamesOrPlatformIds[i];
            const speaker = payload?.speakers.find(
              speaker => speaker.username === usernameOrPlatform,
            );

            // use platformId if speaker found
            const selectedKey = speaker?.platformId;

            // if speaker not found, most-likely because we have the dmsFromTheDead in platformId
            // or speaker is not in the list of speakers. Keep it as is.
            // Need the type-cast below because it will always be an array of DmsFromDeadCard
            const result = (payload?.dmsFromTheDead[usernameOrPlatform] ||
              []) as DmsFromDeadCard[];

            if (!speaker || !selectedKey) {
              transformedDmsFromTheDead[usernameOrPlatform] = result;
              continue;
            }

            // assign the dmsFromTheDead to the transformedDmsFromTheDead
            transformedDmsFromTheDead[selectedKey] = result;
          }

          state.messages = transformedMessages;
          state.dmsFromTheDead = transformedDmsFromTheDead;
          state.hasTransformedMessagesToV2 = true;
        }
      }
    });
  },
});

export const getSpeakerMessages = (
  state: State,
  speaker: DMSpeaker,
): MessageItem[] => {
  if (speaker.platformId) {
    return state.messages[speaker.platformId] ?? [];
  }

  return [];
};

export const getSpeakerByUsername = (
  state: State,
  username: string,
): DMSpeaker | undefined => {
  return state.speakers.find(speaker => speaker.username === username);
};

export const getDmsFromDeadCardFromGeneratedId = (
  state: State,
  speaker: DMSpeaker,
): DmsFromDeadCards[] => {
  if (speaker.platformId) {
    return state.dmsFromTheDead[speaker.platformId] ?? [];
  }
  return [];
};

export const getIsSpeakerHasUnreadMessages = (
  state: State,
  speakerPlatformId: string,
): boolean => {
  return !!state.unreadMessages[speakerPlatformId]?.length;
};

export const getTotalUnreadMessages = (state: State): number => {
  const fetchedUnreadMessages = Object.values(state.unreadMessages).reduce(
    (acc, messages) => {
      // As per-requirement and the Figma design, we count them only for "speaker" that has unread messages
      // not the total unread messages from all speakers
      if (messages.length) {
        return acc + 1;
      }

      return acc;
    },
    0,
  );

  const selectedUsername: Record<string, boolean> = {};
  const nonFetchedMessagesFromNotification =
    state.receivedDataFromNotification.reduce((acc, data) => {
      if (data.username && !selectedUsername[data.username]) {
        selectedUsername[data.username] = true;
        const speaker = state.speakers.find(
          speaker => speaker.username === data.username,
        );

        if (!speaker) {
          return acc + 1;
        }
      }

      return acc;
    }, 0);

  return fetchedUnreadMessages + nonFetchedMessagesFromNotification;
};

export const {
  clearMessageData,
  setUserMessages,
  setVideoLikeContent,
  setVideoBookmarkContent,
  setVideoShareContent,
  setNewConversationFlag,
  setDraftMessage,
  setNewDmSpeaker,
  setSpeakerScreen,
  saveDmsFromTheDeadCard,
  setUnreadMessage,
  deleteUnreadMessage,
  setShowDmsMessages,
  deleteGeneratingMessage,
  setShowStartNewConversation,
  clearReceivedDataFromNotification,
  setReceivedDataFromNotification,
} = messageSlice.actions;

export const MessagesSlice = persistReducer(
  persistConfig,
  messageSlice.reducer,
);
