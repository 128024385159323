import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { CommentsResponse, GetCommentsRequest } from '../../entities';

export const getCommentsGraphQLCall = async (
  request: GetCommentsRequest,
): Promise<CommentsResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        generatedContentId: request.generatedContentId,
        parentCommentId: request.parentCommentId,
      },
      query: `
      query getComments($generatedContentId: ID!, $parentCommentId: ID) {
        getGeneratedContentComments(input: {
          generatedContentId: $generatedContentId, 
          parentCommentId: $parentCommentId
        }) {
          generatedContentId
          comments {
            commentId
            userProfileImage
            userPreferredName
            userId
            creationDateTime
            comment
            likesCount
            repliesCount
          }
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data.getGeneratedContentComments;
};
