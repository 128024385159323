import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { mapApProgramCourseDetailsById } from '../services/mappers';

type Props = {
  courseId?: string;
};

export const APProgramExamDate = ({
  courseId,
}: Props): React.ReactElement | null => {
  const courseProgram = useAppSelector(state =>
    mapApProgramCourseDetailsById(state, courseId),
  );
  const doesProgramExists = useMemo(
    () => Boolean(courseProgram),
    [courseProgram],
  );

  const isAPFreeTrialEnded = false;

  const durationText = useMemo(() => {
    return `${locale.common.week} ${courseProgram?.currentDuration || 'N'} `;
  }, [courseProgram]);

  const currentDurationText = useMemo(() => {
    if (
      !courseProgram?.currentDurationStartDate ||
      !courseProgram?.currentDurationEndDate
    ) {
      return locale.ap.commitment_duration_dates
        .replace('${START_DATE}', '')
        .replace('${END_DATE}', '');
    }

    const startDateString = moment(
      courseProgram?.currentDurationStartDate,
      'YYYY-MM-DD',
    ).format('MMM D');
    const endDateString = moment(
      courseProgram?.currentDurationEndDate,
      'YYYY-MM-DD',
    ).format('MMM D');

    return locale.ap.commitment_duration_dates
      .replace('${START_DATE}', startDateString)
      .replace('${END_DATE}', endDateString);
  }, [courseProgram]);

  const shouldShowCurrentDuration = useMemo(
    () => Boolean(currentDurationText),
    [currentDurationText],
  );

  const examDateText = useMemo(() => {
    if (!courseProgram?.examDate) {
      return '';
    }

    return moment(courseProgram.examDate, 'YYYY-MM-DD').format('MMMM D');
  }, [courseProgram?.examDate]);

  if (!doesProgramExists) {
    return null;
  }

  return (
    <Text style={styles.mainText}>
      {isAPFreeTrialEnded ? (
        <Text style={styles.strong}>{locale.ap.trial_ended}</Text>
      ) : (
        <>
          <Text style={styles.strong}>{durationText}</Text>
          {shouldShowCurrentDuration && (
            <Text style={styles.text}>{currentDurationText}</Text>
          )}
        </>
      )}
      <Text style={styles.text}>{locale.ap.ap_program_date}</Text>
      <Text style={styles.strong}>{examDateText}</Text>
    </Text>
  );
};

const styles = StyleSheet.create({
  mainText: {
    textAlign: 'center',
  },
  text: {
    ...Fonts.small,
    color: Colors.white70,
  },
  strong: {
    ...Fonts.smallBold,
    color: Colors.white70,
  },
});
