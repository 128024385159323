import locale from '../../App/locale';
import { Course } from '../../Common/entities';
import { isSATCourse } from '../../Common/services/utils';
import { GetTopicsPercentagePerGrade } from '../../Learn/entities';
import { mapGradeToNumber } from '../../Learn/services/utils';
import { TestAttempt, TestPrepData } from '../entities';

export class TestPrepProgressAlgorithm {
  static getTestPrepPercentagePerGrade = (
    course: Course | null,
    bestAttempts: Record<string, TestAttempt>,
  ): GetTopicsPercentagePerGrade[] => {
    const unitTestGrades =
      course?.units
        .filter(unit => unit.hasTestPrep)
        .map(unit => bestAttempts[unit.id]?.grade) ?? [];

    if (!unitTestGrades.length) {
      return [];
    }

    const gradesCount: Record<string, number> = {};

    unitTestGrades.forEach(grade => {
      if (grade) {
        gradesCount[grade] = (gradesCount[grade] ?? 0) + 1;
      }
    });

    const uniqueGrades = Object.keys(gradesCount);

    uniqueGrades.sort((a, b) => {
      return mapGradeToNumber(a) - mapGradeToNumber(b);
    });

    const resultsWithoutUnknown = uniqueGrades.map(grade => {
      const selectedGrade = gradesCount[grade];
      const selectedStatLocale =
        selectedGrade > 1
          ? locale.study.tests_with_grade_stat
          : locale.study.tests_with_grade_stat_singular;

      return {
        label: selectedStatLocale
          .replace('${NUMBER}', selectedGrade.toString())
          .replace('${GRADE}', grade),
        step: grade,
        percentage: selectedGrade,
      };
    });

    const totalPercentage = resultsWithoutUnknown.reduce(
      (acc, result) => acc + result.percentage,
      0,
    );
    const unknownResultCount = course
      ? course?.units.length - totalPercentage
      : 0;

    const selectedUnknownWording =
      unknownResultCount > 1
        ? locale.study.tests_with_unknown_stat
        : locale.study.tests_with_unknown_stat_singular;

    const unknownResult = {
      label: selectedUnknownWording.replace(
        '${NUMBER}',
        unknownResultCount.toString(),
      ),
      step: locale.common.unknown,
      percentage: unknownResultCount,
    };

    return [...resultsWithoutUnknown, unknownResult];
  };

  static getSATTestScore = (testData: TestPrepData): number | undefined => {
    if (!testData.maxScore || !testData.minScore) {
      return undefined;
    }

    if (!testData.questions?.length) {
      return 0;
    }

    const correctAnswers = testData.questions.filter(
      question => question.chosenOption?.correct,
    );

    const progress = correctAnswers.length / testData.questions.length;

    const valueOfProgress = (testData.maxScore! - testData.minScore) * progress;

    return Math.round(testData.minScore + valueOfProgress);
  };

  static getTestPointsAwarded = (testData: TestPrepData): number => {
    if (isSATCourse(testData.course)) {
      return TestPrepProgressAlgorithm.getSATTestScore(testData) ?? 0;
    } else {
      const correctAnswers = testData.questions.filter(
        question => question.chosenOption?.correct,
      );
      return correctAnswers.reduce((acc, q) => acc + q.difficulty, 0) * 10;
    }
  };

  static getTestCorrectAnswersPercentage = (testData: TestPrepData): number => {
    if (isSATCourse(testData.course)) {
      const score = TestPrepProgressAlgorithm.getSATTestScore(testData) ?? 0;
      return score / testData.maxScore!;
    } else {
      const correctAnswers = testData.questions.filter(
        question => question.chosenOption?.correct,
      );

      if (!testData.questions.length) {
        return 0;
      }

      return correctAnswers.length / testData.questions.length;
    }
  };

  static getTestRawScore = (testData: TestPrepData): number => {
    if (isSATCourse(testData.course)) {
      return TestPrepProgressAlgorithm.getSATTestScore(testData) ?? 0;
    } else {
      const correctAnswers = testData.questions.filter(
        question => question.chosenOption?.correct,
      );

      return correctAnswers.length;
    }
  };
}
