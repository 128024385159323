import { DMSpeaker } from '../../Messages/entities';

import { ChatHistoryItem, RaiseHandScreenTypes } from './RaiseHandTypes';

export enum RaiseHandMessageType {
  StartConversation = 'Tutor Conversation - Text Message - Initial',
  ContinueConversation = 'Tutor Conversation - Text Message - Continuing',
  TestPrepTutorInteraction = 'Tutor Conversation - Text Message - Test Prep',
  TestPrepTutorInteractionInitial = 'Tutor Conversation - Text Message - Test Prep - Initial',
  SATTestPrepMath = 'Tutor Conversation - Text Message - SAT Prep - Math',
  SATTestPrepReadWrite = 'Tutor Conversation - Text Message - SAT Prep - R&W',
  SATTestPrepReadWriteInitial = 'Tutor Conversation - Text Message - SAT Prep - R&W - Initial',
}

export type RaiseHandUserInteractionHistory = {
  selectedOption: string;
  correct: boolean;
};

export type RaiseHandRequestParams = {
  speaker: DMSpeaker;
  question: string;
  contentGeneratorId: RaiseHandMessageType;
  generatedContentId: string;
  testPrepLevelId?: string;
  fromScreen: RaiseHandScreenTypes;
  requestId: string;
};

export type GetDMChatHistoryRequest = {
  keyFigureName: string;
  page: number;
};

export type GetDMChatHistoryResponse = {
  hasNextPage?: boolean;
  chatHistory: ChatHistoryItem[];
};

export type RaiseHandChatHistoryRequest = {
  generatedContentId: string;
} & GetDMChatHistoryRequest;
