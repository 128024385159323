import { firebase, getAnalytics } from '@react-native-firebase/analytics';
import { NavigationState, PartialState } from '@react-navigation/native';
import appsFlyer from 'react-native-appsflyer';

import {
  getAppsFlyerTracker,
  getMixpanelTracker,
} from '../../../App/services/trackers';

import { Analytics, APPSFLYER_EVENTS_WHITELIST } from './AppConstants';
import { isProductionEnv } from './CommonUtil';

export const syncAnalyticsIdsWithMixpanel = async (): Promise<void> => {
  const mixpanelID = await getMixpanelTracker().getDistinctId();
  appsFlyer.setCustomerUserId(mixpanelID);
  firebase.analytics().setUserId(mixpanelID);
};

export const getActiveRouteName = (
  state: NavigationState | PartialState<NavigationState> | undefined,
): string => {
  if (!state || typeof state.index !== 'number') {
    return 'Unknown';
  }

  const route = state.routes[state.index];

  if (route.state) {
    return getActiveRouteName(route.state);
  }

  return route.name;
};

type AnalyticsPayload = {
  _prefix?: string;
  _suffix?: string;
  _addition?: string;
  _firebase?: boolean;
  _mixpanel_only?: boolean;
} & Record<string, any>;

export const trackAnalyticsEvent = (
  eventName: Analytics,
  eventProperties?: AnalyticsPayload,
): void => {
  let eventTitle: string = eventName;
  if (eventProperties) {
    if (eventProperties._suffix) {
      eventTitle = `${eventTitle}_${eventProperties._suffix}`;
      eventProperties._suffix = undefined;
    }

    if (eventProperties._prefix) {
      eventTitle = `${eventProperties._prefix}_${eventTitle}`;
      eventProperties._prefix = undefined;
    }

    if (eventProperties._addition) {
      eventTitle = `${eventTitle} ${eventProperties._addition}`;
      eventProperties._addition = undefined;
    }

    if (eventProperties._mixpanel_only) {
      eventProperties._mixpanel_only = undefined;
      getMixpanelTracker().track(eventTitle, eventProperties);
      return;
    }

    if (eventProperties._firebase) {
      eventProperties._firebase = undefined;
      // translate name to snake case
      const snakeCaseTitle = eventTitle
        .replace(/([A-Z])/g, '_$1')
        .toLowerCase();
      getAnalytics().logEvent(snakeCaseTitle, eventProperties);
    }
  }
  getMixpanelTracker().track(eventTitle, eventProperties);
  if (isProductionEnv() && APPSFLYER_EVENTS_WHITELIST.has(eventName)) {
    getAppsFlyerTracker().log(eventTitle, eventProperties);
  }
};

export type UpdateUserPropertiesPayload = Record<
  string,
  number | string | null | undefined | boolean | object
>;

/**
 * Update user properties in mixpanel
 * A null value will remove the property
 * An undefined value will be ignored
 * @param properties - UpdateUserPropertiesPayload - key-value pairs to update
 */
export const updateUserProperties = (
  properties: UpdateUserPropertiesPayload,
): void => {
  Object.entries(properties)
    .filter(([_, value]) => value !== undefined)
    .forEach(([key, value]) => {
      getMixpanelTracker().setPeople(`$${key}`, value);
    });
};
