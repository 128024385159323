export const MEDIA_PROGRESS_EVENT = 'mediaProgressEvent';
export const MEDIA_STATUS_EVENT = 'mediaStatusEvent';
export const MEDIA_VOLUME_EVENT = 'mediaVolumeEvent';
export const CARD_GESTURE_EVENT = 'cardGestureEvent';
export const ITEM_VISIBILITY_CHANGE_EVENT = 'itemVisibilityChangeEvent';

export const MEDIA_PLAYBACK_EVENT = 'mediaPlaybackEvent';

export type MediaProgressEventPayload = {
  uniqueId: string;
  progress: number;
  duration: number;
};

enum MediaStatus {
  PLAYING = 'playing',
  PAUSED = 'paused',
  STOPPED = 'stopped',
}

export enum MediaPlayback {
  PLAY = 'play',
  PAUSE = 'pause',
}

export type MediaStatusEventPayload = {
  uniqueId: string;
  status: MediaStatus;
};

export type MediaPlaybackEventPayload = {
  uniqueId: string;
  status: MediaPlayback;
};

export type MediaVolumeEventPayload = {
  isMuted?: boolean;
};

export type CardGestureEventPayload = {
  uniqueId: string;
  position: {
    x: number;
    y: number;
  };
  gesture: 'tap' | 'double-tap' | 'long-tap';
  isFromDM?: boolean;
};

export type VisibilityChangeEventPayload = {
  visibleItemUniqueId?: string;
  visibleItemIndex?: number;
  holderTag: string;
};

export type UnsubscribeCallback = () => void;
