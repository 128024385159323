import React from 'react';
import { StyleSheet, View } from 'react-native';

import { CommonUpsellFeatureListItem } from '../../../Common/components';
import { Colors, Sizes } from '../../../Common/services/utils/AppConstants';
import { useEntitlementConfirmationScreenData } from '../../services/hooks';

export const EntitlementConfirmationFeatures = (): React.ReactElement => {
  const content = useEntitlementConfirmationScreenData();
  return (
    <View style={styles.featureContainer}>
      {content?.featureList.map(feature => (
        <CommonUpsellFeatureListItem key={feature} feature={feature} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  featureContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.medium,
    padding: Sizes.semiMedium,
    backgroundColor: Colors.white10,
    borderRadius: Sizes.normal,
  },
});
