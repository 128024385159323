import { useEffect, useMemo } from 'react';

import { AiFigureModel } from '../../../AiFigure/entities';
import { getFigureOneTime } from '../../../AiFigure/services/slices';
import { useAppSelector } from '../../../App/services/hooks';
import { RootState, useAppDispatch } from '../../../App/store';
import { DMSpeaker } from '../../../Messages/entities';

type FetchRaiseHandTutorReturn = {
  raiseHandTutor: DMSpeaker | undefined;
};

export const useFetchRaiseHandTutor = (
  characterName: string,
): FetchRaiseHandTutorReturn => {
  const dispatch = useAppDispatch();
  const selectedFigure: AiFigureModel | undefined = useAppSelector(
    (state: RootState) => state.aiFigure.figure[characterName],
  );

  useEffect(() => {
    if (characterName && !selectedFigure) {
      dispatch(getFigureOneTime({ name: characterName }));
    }
  }, [dispatch, characterName, selectedFigure]);

  const raiseHandTutor = useMemo<DMSpeaker | undefined>(() => {
    if (!selectedFigure) {
      return undefined;
    }

    return {
      name: selectedFigure.name,
      username: selectedFigure.handle,
      avatarUrl: selectedFigure.avatarUrl,
      title: selectedFigure.bio || '',
      platformId: selectedFigure.platformId,
      biography: selectedFigure.socialBio || '',
    };
  }, [selectedFigure]);

  return { raiseHandTutor };
};
