import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class ExternalApi {
  private static instance: ExternalApi;
  private apiInstance: Promise<AxiosInstance>;

  private constructor() {
    this.apiInstance = this.createApiInstance();
  }

  public static getInstance(): ExternalApi {
    if (!ExternalApi.instance) {
      ExternalApi.instance = new ExternalApi();
    }
    return ExternalApi.instance;
  }

  private async createApiInstance(): Promise<AxiosInstance> {
    return axios.create();
  }

  public async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    const api = await this.apiInstance;
    return api.get(url, config);
  }
}
