import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

export const APCourseSelectionHeader = (): React.ReactElement => {
  return (
    <View style={styles.textContainer}>
      <Text style={styles.title}>
        {locale.onboarding.ap_exam_selection_title}
      </Text>
      <Text style={styles.subtitle}>
        {locale.onboarding.ap_exam_selection_description}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  textContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.small,
    paddingVertical: Sizes.medium,
  },
  title: {
    ...Fonts.xxxlargeBold,
    textAlign: 'center',
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'center',
  },
});
