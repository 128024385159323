import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { CommentFeedbackRequest, ContentFeedbackRequest } from '../../entities';

export const addContentFeedbackGraphQLCall = async (
  request: ContentFeedbackRequest,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        generatedContentId: request.generatedContentId,
        feedbackNotes: request.feedbackNotes,
      },
      query: `mutation GeneratedContentFeedback(
        $generatedContentId: ID!,
        $feedbackNotes: String
      ) {
        generatedContentFeedback(
          generatedContentId: $generatedContentId, 
          feedbackType: ${request.feedbackType},
          feedbackNotes: $feedbackNotes
        )
    }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const removeContentFeedbackGraphQLCall = async (
  request: ContentFeedbackRequest,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        generatedContentId: request.generatedContentId,
      },
      query: `mutation DeleteContentFeedback(
        $generatedContentId: ID!
      ) {
        deleteGeneratedContentFeedback(
          generatedContentId: $generatedContentId, 
          feedbackType: ${request.feedbackType}
        )
    }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const updateCommentFeedbackGraphQLCall = async (
  request: CommentFeedbackRequest,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        commentId: request.commentId,
        feedback: request.feedback,
      },
      query: `mutation updateCommentFeedback(
        $commentId: ID!,
        $feedback: Boolean
      ) {
        updateGeneratedContentCommentFeedback(
          input: {like: $feedback, commentId: $commentId}) {
          commentFeedbackId
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};
