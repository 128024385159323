import { AxiosError } from 'axios';

import { NetworkError } from '../../entities';

export const mapAxiosError = (error: AxiosError): NetworkError => ({
  method: error.request._method,
  url: error.request._url,
  requestBody: error.config?.data,
  response: error.request._response,
  message: error.message,
  status: error.status,
});
