import Assets from '../../App/assets';

import CurvePathLeft from './icons/curve_path_left.svg';
import CurvePathRight from './icons/curve_path_right.svg';
import Star1 from './icons/star_1.svg';
import Star1Gold from './icons/star_1_gold.svg';
import Star2 from './icons/star_2.svg';
import Star2Gold from './icons/star_2_gold.svg';
import Star3 from './icons/star_3.svg';
import Star3Gold from './icons/star_3_gold.svg';

export const LearnAssets = {
  star1Gold: Assets.getVector(Star1Gold),
  star2Gold: Assets.getVector(Star2Gold),
  star3Gold: Assets.getVector(Star3Gold),
  curvePathLeft: Assets.getVector(CurvePathLeft),
  curvePathRight: Assets.getVector(CurvePathRight),
  star1: Assets.getVector(Star1),
  star2: Assets.getVector(Star2),
  star3: Assets.getVector(Star3),

  topicCompletionGif: Assets.getBitmap(
    require('../../../assets/gifs/topic_completion.gif'),
  ),
};
