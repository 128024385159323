import locale from '../../App/locale';

export const AP_PROGRAM_UPSELL_FEATURE_LIST = [
  {
    key: 'ap-program-upsell-feature-1',
    text: locale.onboarding.ap_upsell_overlay_point_access_all_ap_courses,
  },
  {
    key: 'ap-program-upsell-feature-2',
    text: locale.onboarding.ap_upsell_overlay_point_continue_study,
  },
  {
    key: 'ap-program-upsell-feature-3',
    text: locale.onboarding.ap_upsell_overlay_point_guaranteed_results,
  },
];
