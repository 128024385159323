import { RootState } from '../../../App/store';
import { PaywallPurchaseType } from '../../../Common/entities';

export const mapAPPaywallPurchaseType = (
  state: RootState,
): PaywallPurchaseType => {
  return state.apProgramPaywall.isAddOnSelected
    ? PaywallPurchaseType.AP_PROGRAM_PLUS_SAT_PREP
    : PaywallPurchaseType.AP_PROGRAM;
};
