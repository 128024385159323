import Assets from '../../App/assets';

import FeedbackIcon from './images/feedback.svg';
import ClockIcon from './images/sat_clock.svg';
import SatMathIcon from './images/sat_math.svg';
import MathTutorAvatar from './images/sat_math_avatar.svg';
import ReadingTutorAvatar from './images/sat_reading_writing_avatar.svg';
import SatSummaryIcon from './images/sat_summary.svg';
import TaskListIcon from './images/sat_task_list.svg';
import SatWriteIcon from './images/sat_writing.svg';

export const SATAssets = {
  clockIcon: Assets.getVector(ClockIcon),
  mathTutorAvatar: Assets.getVector(MathTutorAvatar),
  readingTutorAvatar: Assets.getVector(ReadingTutorAvatar),
  taskListIcon: Assets.getVector(TaskListIcon),
  mathIcon: Assets.getVector(SatMathIcon),
  writeIcon: Assets.getVector(SatWriteIcon),
  summaryIcon: Assets.getVector(SatSummaryIcon),
  feedbackIcon: Assets.getVector(FeedbackIcon),
};
