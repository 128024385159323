import moment from 'moment/moment';

import { User } from '../../../Profile/entities';
import { Comment, CommentsResponse } from '../../entities';

const parseProbablyMalformedURI = (uri: string): string => {
  // try parsing uri using try catch and encodeURIComponent, remove characters from the end one after another until it succeed
  let tries = 20;
  while (uri.length > 0 && tries-- > 0) {
    try {
      return decodeURIComponent(uri);
    } catch (e) {
      uri = uri.substring(0, uri.length - 1);
    }
  }
  return uri;
};

export const generateNumericId = (): number => {
  return Math.round(Math.random() * 10000000);
};

export const mapTextToComment = (author: User, text: string): Comment => {
  return {
    id: generateNumericId().toString(),
    body: text,
    author,
    time_label: moment.utc().format(),
    local: true,
    likes_count: 0,
    replies_count: 0,
  };
};

export const mapCommentsResponseToComments = (
  response: CommentsResponse,
): Comment[] => {
  return response.comments.map(comment => ({
    id: comment.commentId,
    body: parseProbablyMalformedURI(comment.comment),
    time_label: comment.creationDateTime,
    likes_count: comment.likesCount,
    author: {
      id: comment.userId!,
      profileImageURL: comment.userProfileImage,
      preferredName: comment.userPreferredName,
    },
    replies_count: comment.repliesCount,
  }));
};
