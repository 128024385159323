import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { ProceedEnvSelectionFromConfigsParams } from '../../../entities/SettingsActionTypes';
import { getAwsExportJson } from '../../requests';
import { isDevelopmentEnv, isProductionEnv } from '../../utils';
import { isWebPlatform } from '../../utils/AppConstants';
import { DeviceInfo } from '../../utils/polyfills';
import {
  checkCustomEnvironment,
  SETTINGS_SLICE_NAME,
  setUseCustomEnvironment,
} from '../SettingsSlice';

export const proceedEnvSelectionFromConfigs = createAsyncThunk(
  `${SETTINGS_SLICE_NAME}/proceedEnvSelectionFromConfigs`,
  async (params: ProceedEnvSelectionFromConfigsParams, thunkAPI) => {
    try {
      if (isWebPlatform) {
        return;
      }

      const { stagingBuildNumber, stagingEnv } = params;
      const rootState = thunkAPI.getState() as RootState;
      const currentSelectedPrNumber = rootState.settings.selectedPrNumber;

      if (!stagingBuildNumber || !stagingEnv) {
        if (isProductionEnv()) {
          thunkAPI.dispatch(setUseCustomEnvironment(false));
        }
        return;
      }

      const buildNumber = isDevelopmentEnv() ? '0.00' : DeviceInfo.getVersion();
      if (buildNumber !== stagingBuildNumber) {
        if (isProductionEnv()) {
          thunkAPI.dispatch(setUseCustomEnvironment(false));
        }
        return;
      }

      const data = await getAwsExportJson(stagingEnv);
      const result = await thunkAPI
        .dispatch(
          checkCustomEnvironment({
            selectedPrNumber: stagingEnv,
            selectedEnv: data,
          }),
        )
        .unwrap();

      if (!result) {
        if (isProductionEnv()) {
          thunkAPI.dispatch(setUseCustomEnvironment(false));
        }
        return;
      }

      // If the current selected PR number is not the same as the staging env, then we need to set the custom environment
      if (currentSelectedPrNumber !== stagingEnv) {
        thunkAPI.dispatch(setUseCustomEnvironment(true));
        return;
      }

      // If the current selected PR number is the same as the staging env, but the user is using the default environment, then we need to set the custom environment
      if (
        currentSelectedPrNumber === stagingEnv &&
        !rootState.settings.useCustomEnvironment
      ) {
        thunkAPI.dispatch(setUseCustomEnvironment(true));
        return;
      }
    } catch (e) {
      thunkAPI.dispatch(setUseCustomEnvironment(false));
    }
  },
);
