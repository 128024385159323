import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  isSatBootcampSummaryReady,
  mapSATBootcampSummaryAnalytics,
} from '../../mappers';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const trackSatOnboardingProgramDetails = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/trackLearnMorePressEvent`,
  async (eventName: Analytics | undefined, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;

      if (!isSatBootcampSummaryReady(state) || !eventName) {
        return;
      }

      trackAnalyticsEvent(eventName, {
        ...mapSATBootcampSummaryAnalytics(state.SATProgramDetails),
        from: state.bootcampEntryPoint,
      });
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
