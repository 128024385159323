import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { Course, SubmitDataType } from '../../../../Common/entities';
import { submitDataGraphQLCall } from '../../../../Common/graphql/mutations';
import { isAPCourse } from '../../../../Common/services/utils';
import { APProgramMetadata } from '../../../../Paywall/entities';
import { AP_ONBOARDING_SLICE_NAME } from '../ApOnboardingSlice';

export const recordApOnboardingTargetsAction = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/recordApOnboardingTargetsAction`,
  async (_, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const details = state.apOnboarding.apProgramDetails?.courseDetails;
      const courses = state.courses.coursesMap;

      const courseMap = new Map<string, Course>(
        Object.values(courses).map(course => [course.name, course]),
      );
      const metadata: APProgramMetadata = {
        courses:
          details
            ?.filter(
              d => courseMap.has(d.name) && isAPCourse(courseMap.get(d.name)),
            )
            .map(detail => ({
              subjectId: courseMap.get(detail.name)!.subjectId,
              courseId: courseMap.get(detail.name)!.id,
              targetGrade: detail.targetGrade,
            })) ?? [],
        optedForAddon: state.apProgramPaywall.isAddOnSelected,
      };
      return await submitDataGraphQLCall({
        type: SubmitDataType.AP_PROGRAM_ONBOARDING,
        metadata,
      });
    } catch (e) {
      if (e instanceof Error) {
        handleNetworkActionErrorSilently(e);
      }
      return {
        success: false,
      };
    }
  },
);
