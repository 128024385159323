import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Asset } from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Topic } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

interface InfoRow {
  image: Asset;
  title: string;
  size: number;
}

type RowDataProps = {
  key: number;
  topics: Topic[];
} & InfoRow;

type TestPreviewInfoViewProps = {
  rowData: InfoRow[];
  topics: Topic[];
};

export const TestPracticeTopicsView = ({
  rowData,
  topics,
}: TestPreviewInfoViewProps): React.ReactElement => {
  const testInfoRow = ({ image, title, key, size, topics }: RowDataProps) => {
    return (
      <View style={styles.testInfoRow} key={key}>
        <AssetImage
          asset={image}
          height={size}
          width={size}
          fill="white"
          containerStyle={[styles.testInfoAssetContainer, { height: size }]}
        />
        <View style={styles.infoContainer}>
          <Text style={styles.testInfoText}>{title}</Text>
          {topics.map(topic => (
            <View style={styles.topicContainer} key={topic.name}>
              <Text style={[styles.dot]}>{'•'}</Text>
              <Text style={styles.topicName}>{topic.name}</Text>
            </View>
          ))}
        </View>
      </View>
    );
  };

  return (
    <View style={[styles.testInfoContainer, styles.gap20]}>
      {rowData.map((rowData, index) =>
        testInfoRow({
          image: rowData.image,
          title: rowData.title,
          key: index,
          size: rowData.size,
          topics: topics,
        }),
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  infoContainer: {
    flex: 1,
  },
  topicContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  dot: {
    paddingHorizontal: 8,
    ...Fonts.small,
    color: Colors.white80,
  },
  topicName: {
    flex: 1,
    ...Fonts.small,
    color: Colors.white80,
  },
  testInfoContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 14,
    borderColor: Colors.white12,
  },
  gap20: {
    gap: Sizes.normalizeIP14PM(20),
  },
  testInfoRow: {
    flexDirection: 'row',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  testInfoAssetContainer: {
    width: Sizes.normalizeIP14PM(34),
    alignItems: 'center',
    alignSelf: 'center',
  },
  testInfoText: {
    ...Fonts.caption,
    color: Colors.white80,
  },
});
