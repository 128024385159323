export type User = {
  id: string;
  email?: string;
  preferredName?: string;
  profileImageURL?: string;
};

export type AuthUserEnrollment = {
  role: string;
  username?: string;
};

export type AuthUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleName: string;
  profileImageURL?: string;
  coverImageURL?: string;
  acceptedTerms: boolean;
  permissions: string[];
  preferredName?: string;
  userEnrollments?: AuthUserEnrollment[];
};

export type SignupRequest = {
  email: string;
  password: string;
  preferredName: string;
};

export type TeachTapUserSettingsResponse = {
  goal?: string;
  examType?: string;
  emailOptIn?: boolean;
};

export enum UserPermissions {
  TEACHTAP_AP_RESULTS_UNLOCK_SAT_DISCOUNT = 'TeachTap AP Results Unlock SAT Discount',
  TEACHTAP_AP_PROGRAM_ACCESS = 'TeachTap AP Program Access',
}

export enum EditFieldAction {
  EditPreferredName,
  EditUsername,
  EditEvironmentURL,
}

export enum TeachTapGrade {
  FRESHMAN = 'FRESHMAN',
  SOPHOMORE = 'SOPHOMORE',
  JUNIOR = 'JUNIOR',
  SENIOR = 'SENIOR',
  POST_12TH_GRADE = 'POST_12TH_GRADE',
}

export enum TeachTapUserFlag {
  AP_RESULTS_SUBMITTED = 'AP_RESULTS_SUBMITTED',
}

export enum ProfileMenuItemStatus {
  DEFAULT = 'default',
  DANGER = 'danger',
}

export enum ProfileUpdateEntryPoints {
  PROFILE = 'profile',
  ONBOARDING = 'onboarding',
}
