import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { MainStack } from '../../../../Common/services/utils/AppConstants';
import { BootcampEntryPoint } from '../../../entities';
import {
  mapAPProgramEntitlementStatus,
  mapSatBootcampEntitlementStatus,
} from '../../mappers';
import {
  ONBOARDING_SLICE_NAME,
  setBootcampEntryPoint,
  setShowEntitlementScreen,
} from '../OnboardingSlice';

export const checkIfUserHasEntitlement = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/checkIfUserHasEntitlement`,
  async (entryPoint: BootcampEntryPoint, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const [satEntitlement, apEntitlement] = [
        mapSatBootcampEntitlementStatus(rootState),
        mapAPProgramEntitlementStatus(rootState),
      ];
      const hasEntitlement = satEntitlement || apEntitlement;
      if (hasEntitlement) {
        thunkApi.dispatch(setShowEntitlementScreen(true));
        thunkApi.dispatch(setBootcampEntryPoint(entryPoint));

        RootNavigatorRef.current?.reset({
          index: 0,
          routes: [{ name: MainStack.BOOTCAMP_ENTITLEMENT_SCREEN }],
        });
      }

      return hasEntitlement;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
