import locale from '../../App/locale';

export const SAT_BOOTCAMP_PAYWALL_FEATURES = [
  locale.bootcamp_paywall.feature1,
  locale.bootcamp_paywall.feature2,
  locale.bootcamp_paywall.feature3,
  locale.bootcamp_paywall.feature4,
];

export const AP_PAYWALL_FEATURES = [
  locale.bootcamp_paywall.ap_feature1,
  locale.bootcamp_paywall.ap_feature2,
  locale.bootcamp_paywall.ap_feature3,
  locale.bootcamp_paywall.ap_feature4,
];
