import React from 'react';
import { StyleSheet, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { Colors, Sizes } from '../../services/utils/AppConstants';

const PLAY_BUTTON_SIZE = Sizes.normalizeIP14PM(20);

type VideoFrameViewButtonProps = {
  isVideoEnded: boolean;
  isVideoPaused: boolean;
};

export const VideoFrameViewButton = ({
  isVideoPaused,
  isVideoEnded,
}: VideoFrameViewButtonProps): React.ReactElement | null => {
  if (isVideoEnded) {
    return (
      <View style={styles.buttonContainer}>
        <AssetImage
          asset={Assets.home.repeatIcon}
          fill="white"
          height={PLAY_BUTTON_SIZE}
          containerStyle={styles.playButton}
        />
      </View>
    );
  } else if (!isVideoEnded && isVideoPaused) {
    return (
      <View style={styles.buttonContainer}>
        <AssetImage
          asset={Assets.home.playVideoButtonIcon}
          fill="white"
          height={PLAY_BUTTON_SIZE}
          containerStyle={styles.playButton}
        />
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  buttonContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: '45%',
    backgroundColor: Colors.overlayBackgroundColor,
    width: 50,
    height: 50,
    borderRadius: 25,
    zIndex: 60,
  },
  playButton: {
    alignSelf: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,

    opacity: 1,
    width: PLAY_BUTTON_SIZE,
  },
});
