import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetAPProgramDetailsRequest,
  GetAPProgramDetailsResponse,
} from '../../entities';

export const getAPProgramDetailsGraphQLCall = async (
  input: GetAPProgramDetailsRequest,
): Promise<GetAPProgramDetailsResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      query: `
      query TeachTapGetAPProgramDetails($input: TeachTapGetAPProgramDetailsInput!) {
        teachTapGetAPProgramDetails(input: $input) {
          courseDetails {
            name
            targetGrade
            examDate
            studyCommitmentHours
          }
          totalStudyCommitmentHours
        }
      }`,
      variables: {
        input,
      },
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetAPProgramDetails;
};
