import React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import FastImage from 'react-native-fast-image';

import { Course } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { Animatable } from '../../Common/services/utils/polyfills';
import { PaywallCharacterResponse } from '../entities';

type AnimatingAvatarProps = {
  course: Course;
  character: PaywallCharacterResponse;
  style?: StyleProp<any>;
  onPress?: () => void;
};

export const AnimatingAvatar = ({
  course,
  character,
  style,
  onPress,
}: AnimatingAvatarProps): React.ReactElement => {
  return (
    <TouchableOpacity style={[styles.avatarContainer, style]} onPress={onPress}>
      <View style={styles.tapToHearContainer}>
        <Text style={styles.tapToHearText}>{character.message}</Text>
        <View style={styles.firstBubble} />
        <View style={styles.secondBubble} />
      </View>
      <Animatable.View
        animation="pulse"
        easing="ease-out"
        iterationCount="infinite"
        style={styles.pulsatingCircle}>
        <FastImage
          source={{ uri: character.character.avatar }}
          style={styles.avatar}
        />
      </Animatable.View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  tapToHearContainer: {
    backgroundColor: Colors.emeraldGreen,
    padding: 10,
    borderRadius: 14,
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tapToHearText: {
    ...Fonts.xsmall,
    textAlign: 'left',
  },
  pulsatingCircle: {
    borderRadius: Sizes.normalizeIP14PM(35),
    backgroundColor: Colors.white30,
    width: Sizes.normalizeIP14PM(70),
    height: Sizes.normalizeIP14PM(70),
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    width: Sizes.normalizeIP14PM(60),
    height: Sizes.normalizeIP14PM(60),
    borderRadius: Sizes.normalizeIP14PM(30),
  },
  avatarContainer: {
    alignItems: 'center',
    rowGap: 2,
  },
  firstBubble: {
    backgroundColor: Colors.emeraldGreen,
    bottom: -6,
    width: 10,
    height: 10,
    borderRadius: 5,
    position: 'absolute',
    left: '10%',
  },
  secondBubble: {
    backgroundColor: Colors.emeraldGreen,
    bottom: -15,
    width: 5,
    height: 5,
    borderRadius: 2.5,
    position: 'absolute',
    left: '15%',
  },
});
