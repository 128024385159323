import { CommonExamType } from '../../Common/entities';
import {
  APProgramDetails,
  APTargetGrade,
  SATBootcampProgramDetails,
} from '../../Onboarding/entities';
import { SATBootcampAnalytics } from '../../SAT/entities';

export enum EmailOptInType {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type UserSignupDataInput = {
  emailOptIn?: boolean;
  studentGrade?: string;
  examType?: CommonExamType;
  SATProgramDetails?: SATBootcampProgramDetails;
  apProgramDetails?: APProgramDetails;
};

export type UserSignupAPProgramDetails = {
  APTargetGrades?: APTargetGrade[];
  programDuration?: string;
};

export type UserSignupDataOutput = {
  emailOptIn: string;
  appGoal?: string;
  studentGrade?: string;
  examType?: CommonExamType;
} & SATBootcampAnalytics &
  UserSignupAPProgramDetails;
