import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { LearningContentItemOutputGQLSchema } from '../../../Learn/entities';
import {
  GetInteractedContentRequest,
  GetInteractedContentResponse,
} from '../../entities';

export const getInteractedContentGraphQLCall = async (
  request: GetInteractedContentRequest,
): Promise<GetInteractedContentResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetInteractedLearningContentItems($input: TeachTapInteractedLearningContentItemsInput!) {
          teachTapGetInteractedLearningContentItems(input: $input) {
            totalCount
            items {
              ${LearningContentItemOutputGQLSchema}
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetInteractedLearningContentItems;
};
