import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import { handleNetworkActionError } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import {
  GetHistoricalFigureRequest,
  AiFigureModel,
  GetHistoricalFigureResponse,
} from '../../entities';
import { getHistoricalFiguresGraphQLCall } from '../../graphql';
import { mapHistoricalFigure } from '../mappers';

type State = {
  figure: Record<string, AiFigureModel>;
  isFetchingFigure: Record<string, boolean>;
  isFigureDataLoading: boolean;
};

const initialState: State = {
  figure: {},
  isFetchingFigure: {},
  isFigureDataLoading: false,
};

const PERSIST_KEY = 'AiFigure';
const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  blacklist: ['isFigureDataLoading', 'isFetchingFigure'],
};

export const getFigureOneTime = createAsyncThunk(
  'AiFigureSlice/getFigureOneTime',
  async (payload: GetHistoricalFigureRequest, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const aiFigureState = state.aiFigure;

    const figure = aiFigureState.figure[payload?.name || ''];
    const isFetching = aiFigureState.isFetchingFigure[payload?.name || ''];

    if (figure || isFetching) {
      return undefined;
    }

    return await thunkApi.dispatch(getFigureData(payload)).unwrap();
  },
);

export const getFigureData = createAsyncThunk(
  'AiFigureSlice/getFigureData',
  async (payload: GetHistoricalFigureRequest, thunkApi) => {
    try {
      const response = await getHistoricalFiguresGraphQLCall({
        name: payload.name,
      });

      let selectedFigure: GetHistoricalFigureResponse | undefined = response[0];
      if (response?.length > 1) {
        selectedFigure = response.find(
          figure =>
            figure.name.toLowerCase() === payload.name?.toLowerCase() || '',
        );

        if (!selectedFigure) {
          selectedFigure = response[0];
        }
      }

      if (!selectedFigure) {
        throw new Error('No figure found');
      }

      return {
        name: payload.name,
        data: mapHistoricalFigure(
          selectedFigure as GetHistoricalFigureResponse,
        ),
      };
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(locale.errors.network_error);
      }
    }
  },
);

const aiFigureSlice = createSlice({
  name: 'AiFigureSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getFigureData.pending, state => {
      state.isFigureDataLoading = true;
    });

    builder.addCase(getFigureData.fulfilled, (state, action) => {
      state.isFigureDataLoading = false;

      const { name } = action.meta.arg;
      const { payload } = action || {};

      if (payload?.data && payload?.name) {
        state.figure[payload.name] = payload.data;
      }

      if (name) {
        state.isFetchingFigure[name] = false;
      }
    });

    builder.addCase(getFigureData.rejected, (state, action) => {
      const { name } = action.meta.arg;
      state.isFigureDataLoading = false;

      if (name) {
        state.isFetchingFigure[name] = false;
      }
    });
  },
});

export const AiFigureSlice = persistReducer(
  persistConfig,
  aiFigureSlice.reducer,
);
