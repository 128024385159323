import React from 'react';

import { QuestionItemProps } from '../../../../Learn/screens/home/page/FeedCardView';
import { ScrollToCardID } from '../../../../Learn/services/slices';
import { Card, FeedType } from '../../../entities';

import FlashListFeedView from './FlashListFeedView';
import FlatListFeedView from './FlatListFeedView';

export type FeedRenderEngine = 'flatlist' | 'flashlist';
export const FeedRenderEngineOptions: FeedRenderEngine[] = [
  'flatlist',
  'flashlist',
];

export type Props = {
  isLoading: boolean;
  holderTag: FeedType;
  isError: boolean;
  onEndReached: () => void;
  ItemView: React.FC<QuestionItemProps>;
  data: Card[];
  itemHeight: number;
  onEndReachedThreshold?: number;
  isVisible?: boolean;
  scrollToCardID: ScrollToCardID | null;
  engine: 'flatlist' | 'flashlist';
  onPlaylistPress?: () => void;
  shouldShowPlaylist?: boolean;
  isScrollDisabled?: boolean;
  initialScrollIndex?: number;
  showCoinsAnimation?: boolean;
};

export default (props: Props): React.ReactElement => {
  switch (props.engine) {
    case 'flatlist':
      return <FlatListFeedView {...props} />;
    case 'flashlist':
      return <FlashListFeedView {...props} />;
    default:
      return <></>;
  }
};
