import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  finishTransaction,
  ProductPurchase,
  SubscriptionPurchase,
} from 'react-native-iap';

import { sendAttributionToken } from '../../../../App/services/ad-services/AttributionService';
import { RootState } from '../../../../App/store';
import { PaywallPurchaseType } from '../../../../Common/entities';
import { getAllCourses } from '../../../../Learn/services/slices';
import {
  proceedApProgramFlowAfterPurchase,
  proceedSATBootcampFlowAfterPurchase,
} from '../../../../Onboarding/services/slices';
import {
  getUserAccess,
  setAccessibleCourse,
} from '../../../../UserAccess/services/slices';
import {
  mapAPPaywallPurchaseType,
  mapPurchaseTypeBySku,
  mapSATPaywallPurchaseType,
} from '../../mappers';
import {
  PURCHASABLE_ITEMS,
  StorePurchaseItems,
} from '../../utils/PaywallUtils';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

import { handleApProgramPurchase } from './HandleApProgramPurchaseAction';
import { handleCoursePurchase } from './HandleCoursePurchaseAction';
import { handleSatBootcampPurchase } from './HandleSatBootcampPurchaseAction';
import { mapPurchaseToAnalyticsEvent } from './MapPurchaseToAnalyticsEventAction';

export const handlePurchaseUpdate = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/handlePurchaseUpdate`,
  async (purchase: SubscriptionPurchase | ProductPurchase, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const receipt = purchase.transactionReceipt;
    const sku = purchase.productId;
    const product = PURCHASABLE_ITEMS[sku];
    const course = state.purchases.currentCoursePaywall;

    const currentProductsData = state.purchases.productsData[sku];

    if (!product || !receipt) {
      return;
    }

    let purchasePassed = false;

    if (
      product.consumable &&
      course &&
      [
        StorePurchaseItems.COURSE_UNLOCK_CONSUMABLE,
        StorePurchaseItems.COURSE_UNLOCK_EARLY_DISCOUNT_CONSUMABLE,
      ].includes(sku as StorePurchaseItems)
    ) {
      thunkApi.dispatch(
        mapPurchaseToAnalyticsEvent({
          purchasedItem: currentProductsData,
          purchaseType: PaywallPurchaseType.COURSE,
        }),
      );

      thunkApi.dispatch(
        setAccessibleCourse({ courseId: course.id, accessible: true }),
      );
      purchasePassed = await thunkApi
        .dispatch(handleCoursePurchase({ purchase, course, receipt }))
        .unwrap();
    } else {
      purchasePassed = true;
    }

    if (purchasePassed && product.type === 'product') {
      const purchaseType = mapPurchaseTypeBySku(sku);

      if (purchaseType.SAT || purchaseType.AP) {
        thunkApi.dispatch(
          mapPurchaseToAnalyticsEvent({
            purchasedItem: currentProductsData,
            purchaseType: purchaseType.SAT
              ? mapSATPaywallPurchaseType(state)
              : mapAPPaywallPurchaseType(state),
          }),
        );
      }

      if (purchaseType.SAT) {
        await thunkApi
          .dispatch(handleSatBootcampPurchase({ purchase, receipt }))
          .unwrap();
      } else if (purchaseType.AP) {
        await thunkApi
          .dispatch(handleApProgramPurchase({ purchase, receipt }))
          .unwrap();
      }

      await thunkApi.dispatch(getUserAccess()).unwrap();

      const blockingPromises: Promise<unknown>[] = [
        sendAttributionToken(),
        finishTransaction({ purchase, isConsumable: product.consumable }),
      ];

      if (product.manuallyConsumed && purchaseType.SAT) {
        blockingPromises.push(
          thunkApi
            .dispatch(
              proceedSATBootcampFlowAfterPurchase({
                fetchUserProgramDetails: true,
                resetUpsellState: true,
                redirectAfterPurchase: true,
                shouldSendEntitlementCheck: false,
              }),
            )
            .unwrap(),
        );
      } else if (product.manuallyConsumed && purchaseType.AP) {
        blockingPromises.push(
          thunkApi
            .dispatch(
              proceedApProgramFlowAfterPurchase({
                redirectAfterPurchase: true,
              }),
            )
            .unwrap(),
        );

        await Promise.all(blockingPromises);
      }
    }

    thunkApi.dispatch(getAllCourses());
  },
);
