import { getUrl, uploadData } from 'aws-amplify/storage';

export class S3Service {
  private static instance: S3Service;
  private FOLDER_NAME = 'feedback';

  private constructor() {}

  static getInstance(): S3Service {
    if (!S3Service.instance) {
      S3Service.instance = new S3Service();
    }

    return S3Service.instance;
  }

  async uploadImage(
    uri: string,
    isFromFeedback: boolean = false,
  ): Promise<string> {
    try {
      const fileName = uri.split('/').pop();
      const fileType = uri.split('.').pop();
      const blob = await fetch(uri).then(r => r.blob());

      const result = await uploadData({
        path: isFromFeedback ? `${this.FOLDER_NAME}/${fileName!}` : fileName!,
        data: blob,
        options: {
          contentType: `image/${fileType}`,
        },
      }).result;

      const getUrlResult = await getUrl({ path: result.path });
      return getUrlResult.url.toString().split('?')[0];
    } catch (e: Error | unknown) {
      throw e instanceof Error ? e.message : e;
    }
  }

  async uploadVideo(uri: string): Promise<string> {
    try {
      const fileName = uri.split('/').pop();
      const fileType = uri.split('.').pop();
      const blob = await fetch(uri).then(r => r.blob());

      const result = await uploadData({
        path: `${this.FOLDER_NAME}/${fileName!}`,
        data: blob,
        options: {
          contentType: `video/${fileType}`,
        },
      }).result;

      const getUrlResult = await getUrl({ path: result.path });
      return getUrlResult.url.toString().split('?')[0];
    } catch (e: Error | unknown) {
      throw e instanceof Error ? e.message : e;
    }
  }

  async uploadFeedbackMedia(uri: string): Promise<string> {
    const fileType = uri.split('.').pop();
    if (fileType?.includes('image')) {
      return this.uploadImage(uri, true);
    } else {
      return this.uploadVideo(uri);
    }
  }
}

export const getS3Service = (): S3Service => S3Service.getInstance();
