import React from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';

import locale from '../../../../App/locale';
import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import { OutlinedTextContainer } from '../../../../Common/components/common/OutlinedTextContainer';
import { TruthOrLieCard, TruthOrLieOption } from '../../../../Common/entities';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
  isWebPlatform,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';
import {
  getTruthOrLieAnswer,
  setTruthOrLieAnswer,
} from '../../../services/slices';

interface PollOptionProps {
  item: TruthOrLieCard;
  onAnswered: (isCorrect: boolean) => void;
  showStats: boolean;
}

const TruthOrLieOptionView: React.FC<PollOptionProps> = ({
  item,
  onAnswered,
  showStats,
}) => {
  const dispatch = useAppDispatch();
  const correctAnswer = item.answer;
  const storedSelectedOption = useAppSelector(state =>
    getTruthOrLieAnswer(state.answer, item),
  );

  const getProgressIndicatorStyle = (option: TruthOrLieOption) => {
    return {
      width: `${option.selection_rate}%`,
      backgroundColor: getAnswerColor(option),
    };
  };

  const isOptionCorrect = (option: TruthOrLieOption): boolean | null => {
    if (!correctAnswer) {
      return null;
    } else {
      return correctAnswer.value === option.value;
    }
  };

  const isOptionChosen = (option: TruthOrLieOption): boolean => {
    return option.id === storedSelectedOption?.id;
  };

  const onOptionPressed = (option: TruthOrLieOption) => {
    if (storedSelectedOption === null) {
      onAnswered(isOptionCorrect(option) ?? false);
      if (!isWebPlatform) {
        dispatch(
          setTruthOrLieAnswer({
            item: item,
            value: option,
          }),
        );
      }
    }
  };

  const getText = (option: TruthOrLieOption): string => {
    const isCorrect = isOptionCorrect(option);
    const isChosen = isOptionChosen(option);

    if (isCorrect == null || storedSelectedOption === null) {
      return option.value;
    } else {
      if (isCorrect && isChosen) {
        return option.value + locale.home_screen_card.correct_answer;
      } else if (isChosen) {
        return option.value + locale.home_screen_card.incorrect_answer;
      } else {
        return option.value;
      }
    }
  };

  const getAnswerColor = (option: TruthOrLieOption): string => {
    const isCorrect = isOptionCorrect(option);
    const isChosen = isOptionChosen(option);
    if (isCorrect == null || storedSelectedOption === undefined) {
      return Colors.transparent55;
    } else {
      if (isCorrect) {
        return Colors.answerRight70;
      } else if (isChosen || (!isChosen && !isCorrect)) {
        return Colors.answerWrong70;
      } else {
        return Colors.answerNeutral;
      }
    }
  };

  const renderOption = (option: TruthOrLieOption) => {
    return (
      <Pressable
        key={option.id}
        style={styles.optionContainer}
        onPress={() => {
          onOptionPressed(option);
        }}>
        <View style={styles.progressBar}>
          <View
            style={[
              styles.progressIndicator,
              storedSelectedOption && getProgressIndicatorStyle(option),
            ]}
          />
          <View style={styles.progressContainer}>
            <View style={styles.emojiContainer}>
              <Text style={styles.emoji}>{option.emoji}</Text>
              <OutlinedTextContainer hq stroke={1} color={Colors.bottomBar}>
                <Text style={styles.outlineText}>{getText(option)}</Text>
              </OutlinedTextContainer>
            </View>
            {(storedSelectedOption || showStats) && (
              <View style={styles.percentageContainer}>
                <Text style={styles.percentageText}>
                  {option.selection_rate}
                </Text>
                <Text
                  style={[
                    styles.percentage,
                    isAndroidPlatform && styles.percentageAndroid,
                  ]}>
                  %
                </Text>
              </View>
            )}
          </View>
        </View>
      </Pressable>
    );
  };

  return (
    <ScrollView scrollEnabled={false} style={styles.container}>
      {item.options.map(option => renderOption(option))}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    rowGap: Sizes.normalizeIP14PM(5),
    paddingBottom: Sizes.normalizeIP14PM(16),
    flexGrow: 1,
    flexShrink: 10,
  },
  optionContainer: {
    marginBottom: Sizes.normalizeIP14PM(5),
    justifyContent: 'center',
  },
  emoji: {
    ...Fonts.normalize(Fonts.xxxlarge),
    paddingLeft: Sizes.normalizeIP14PM(10),
    textAlign: 'left',
    fontSize: Sizes.normalizeIP14PM(28),
  },
  progressBar: {
    width: '100%',
    height: Sizes.normalizeIP14PM(52),
    backgroundColor: Colors.answerNeutral,
    borderRadius: Sizes.normalizeIP14PM(8),
    borderWidth: Sizes.normalizeIP14PM(1),
    borderColor: Colors.white12,
    overflow: 'hidden',
    justifyContent: 'center',
  },
  progressIndicator: {
    height: '100%',
    position: 'absolute',
  },
  percentageText: {
    ...Fonts.normalize(Fonts.xxxlargeBold),
    paddingLeft: Sizes.normalizeIP14PM(5),
    fontSize: Sizes.normalizeIP14PM(32),
  },
  outlineText: {
    ...Fonts.normalize(Fonts.largeBold),
    padding: Sizes.normalizeIP14PM(5),
  },
  progressContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emojiContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  percentage: {
    ...Fonts.normalize(Fonts.largeBold),
    textAlign: 'left',
    paddingRight: Sizes.normalizeIP14PM(8),
    bottom: Sizes.normalizeIP14PM(5),
    alignSelf: 'flex-end',
  },
  percentageAndroid: {
    top: 2,
  },
  percentageContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default TruthOrLieOptionView;
