import React, { useEffect } from 'react';
import { Image, LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { OutlinedTextContainer } from '../../../../Common/components/common/OutlinedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import { DoubleMemeTextCard } from '../../../../Common/entities';
import {
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';

import BaseContentCard from './BaseContentCard';

type Props = {
  item: DoubleMemeTextCard;
  isVisible: boolean;
};

let layoutWidthCached = 1;
let layoutHeightCached = 1;

export default ({ item, isVisible }: Props): React.ReactElement => {
  // Fix to prevent old memes from showing up and breaking the app (just in case)
  const imagesData = item.images ?? [];

  const [imagesRatio, setImagesRatio] = React.useState(
    Array(imagesData.length).fill(1),
  );

  const [layoutWidth, setLayoutWidth] = React.useState(layoutWidthCached);
  const [layoutHeight, setLayoutHeight] = React.useState(layoutHeightCached);

  const setRatio = (index: number, ratio: number): void => {
    setImagesRatio(prev => {
      const newRatios = [...prev];
      newRatios[index] = ratio;
      return newRatios;
    });
  };

  useEffect(() => {
    if (isWebPlatform) {
      imagesData.forEach((image, index) => {
        Image.getSize(image, (width, height) => {
          const newRatio = height / width;
          setRatio(index, newRatio);
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLayout = (event: LayoutChangeEvent): void => {
    const { width, height } = event.nativeEvent.layout;

    layoutWidthCached = Math.max(width - 8, layoutWidthCached);
    layoutHeightCached = height - 8;

    setLayoutWidth(layoutWidthCached);
    setLayoutHeight(layoutHeightCached);
  };

  let imageWidth = layoutWidth ?? 1;
  let heights: number[] = [];

  const sumOfCalcedHeight = imagesRatio
    .slice(0, imagesData.length)
    .reduce((a, b) => a + b * imageWidth, 0);
  if (sumOfCalcedHeight > layoutHeight) {
    imageWidth = layoutHeight / (sumOfCalcedHeight / imageWidth);
    heights = imagesRatio.map((ratio: number) => imageWidth * ratio);
  } else {
    heights = imagesRatio.map((ratio: number) => imageWidth * ratio);
  }

  heights = heights.map(height => height ?? 50);

  return (
    <BaseContentCard item={item}>
      <View style={styles.contentContainer}>
        <View style={styles.memeContainer} onLayout={onLayout}>
          {imagesData.map((image, index) => {
            let memeContainerStyle;

            if (imagesData.length === 1) {
              memeContainerStyle = styles.memeImageOnly;
            } else if (index === 0) {
              memeContainerStyle = styles.memeImageTop;
            } else if (index === imagesData.length - 1) {
              memeContainerStyle = styles.memeImageBottom;
            } else {
              memeContainerStyle = styles.memeImageMid;
            }
            const height =
              heights[index] && !Number.isNaN(heights[index])
                ? heights[index]
                : 50;

            const text = item.texts[index] ?? '';
            return (
              <View
                key={index}
                style={[
                  styles.memeImageContainer,
                  {
                    height: height,
                    width: imageWidth,
                  },
                ]}>
                <FastImage
                  source={{ uri: image }}
                  style={memeContainerStyle}
                  resizeMode={FastImage.resizeMode.contain}
                  onLoad={e => {
                    if (!isWebPlatform) {
                      const newRatio =
                        e.nativeEvent.height / e.nativeEvent.width;
                      setRatio(index, newRatio);
                    }
                  }}
                />
                {item.texts[index] && (
                  <OutlinedTextContainer
                    hq
                    stroke={1}
                    color={'#000000'}
                    style={styles.memeImageTextContainer}>
                    <Text
                      style={{
                        ...styles.memeImageText,
                        maxHeight: height - 16,
                        maxWidth: imageWidth,
                      }}
                      numberOfLines={6}>
                      {item.texts[index].toUpperCase()}
                    </Text>
                  </OutlinedTextContainer>
                )}
              </View>
            );
          })}
        </View>
      </View>
      <ActionButtonsView item={item} />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
  memeContainer: {
    alignContent: 'center',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginBottom: 8,
  },
  memeImageBottom: {
    borderBottomEndRadius: 8,
    borderBottomStartRadius: 8,
    height: '100%',
    width: '100%',
  },
  memeImageContainer: { flexShrink: 1 },
  memeImageMid: {
    height: '100%',
    width: '100%',
  },
  memeImageOnly: {
    borderRadius: 8,
    height: '100%',
    width: '100%',
  },
  memeImageText: {
    ...Fonts.mediumBold,
    paddingHorizontal: 16,
    textAlign: 'center',
  },
  memeImageTextContainer: {
    alignContent: 'center',
    alignItems: 'center',
    bottom: 16,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  memeImageTop: {
    borderTopEndRadius: 8,
    borderTopStartRadius: 8,
    height: '100%',
    width: '100%',
  },
});
