import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { CardType } from '../../entities';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  contentType: CardType;
  typeName?: string;
  isMiniature?: boolean;
  isSmall?: boolean;
};

const getIconForContentType = (type: CardType) => {
  switch (type) {
    case CardType.DID_YOU_KNOW_CARD:
      return Assets.home.dykTypeIcon;
    case CardType.FILL_IN_THE_BLANK_CARD:
      return Assets.home.keyboardIcon;
    case CardType.MCQ_CARD:
      return Assets.home.mcqTypeIcon;
    case CardType.CONTROVERSIAL_CONVERSATION_CARD:
      return Assets.home.controversialTypeIcon;
    case CardType.TRUTH_OR_LIE_CARD:
      return Assets.home.truthOrLieTypeIcon;
    case CardType.MATCHING_PAIRS_CARD:
      return Assets.home.matchingPairsTypeIcon;
    default:
      return Assets.home.keyboardIcon;
  }
};

export default (props: Props): React.ReactElement => {
  const isSmallOrMiniature = useMemo(
    () => props.isMiniature || props.isSmall,
    [props.isMiniature, props.isSmall],
  );

  const textStyles = useMemo(() => {
    if (!props.isSmall && !props.isMiniature) {
      return styles.contentTypeTitle;
    }

    if (props.isSmall) {
      return styles.smallContentTypeTitle;
    }

    if (props.isMiniature) {
      return styles.miniContentTypeTitle;
    }
  }, [props.isSmall, props.isMiniature]);

  return (
    <View
      style={[
        styles.contentTypeContainer,
        isSmallOrMiniature && styles.miniContentTypeContainer,
      ]}>
      <AssetImage
        asset={getIconForContentType(props.contentType)}
        width={isSmallOrMiniature ? 12 : 20}
        fill={Colors.actionGreen}
      />

      <Text numberOfLines={2} style={textStyles}>
        {props?.typeName || locale.common.none}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  contentTypeContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.black60,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    height: Sizes.normalizeIP14PM(30),
    paddingHorizontal: Sizes.normalizeIP14PM(10),
    borderRadius: Sizes.normalizeIP14PM(20),
    marginBottom: Sizes.normalizeIP14PM(4),
  },
  miniContentTypeContainer: {
    height: Sizes.normalizeIP14PM(24),
    marginBottom: 0,
  },
  contentTypeTitle: {
    ...Fonts.normalize(Fonts.small),
    color: Colors.actionGreen,
    marginStart: Sizes.normalizeIP14PM(3),
    padding: Sizes.normalizeIP14PM(5),
  },
  smallContentTypeTitle: {
    ...Fonts.normalize(Fonts.small),
    color: Colors.actionGreen,
    marginStart: Sizes.normalizeIP14PM(Sizes.xsmall),
  },
  miniContentTypeTitle: {
    ...Fonts.normalize(Fonts.xsmall),
    color: Colors.actionGreen,
    marginStart: Sizes.normalizeIP14PM(3),
  },
});
