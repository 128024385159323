export enum PromoCodeExamType {
  SAT_BOOTCAMP = 'SAT_BOOTCAMP',
}

export enum PromoCodeFrom {
  SAT_BOOTCAMP_INTRO = 'SATBootcampIntro',
  AP_RESULTS = 'APResults',
  COURSE_PICKER = 'CoursePicker',
  AP_ONBOARDING = 'APOnboarding',
  LOCKED_AP_COURSE = 'lockedAPCourse',
}

export enum PurchaseType {
  SAT_BOOTCAMP = 'SATBootcamp',
  AP_RESULTS = 'APResults',
  AP_PROGRAM = 'APProgram',
}

export enum PromoCodeType {
  PURCHASE = 'purchase',
  FREE_TRIAL = 'freeTrial',
  DISCOUNT = 'discount',
}

export type PromoCodeRequest = {
  voucherCode: string;
  type: PromoCodeExamType;
};

export enum PromoCodeValidationSuccess {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export enum PaywallFeatureType {
  AP = 'AP',
  SAT = 'SAT',
}
