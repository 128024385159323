import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  RecordContentAnswerRequest,
  RecordContentAnswerResponse,
  GenerateQuestionRequest,
  GenerateQuestionResponse,
  LearningContentItemOutputGQLSchema,
} from '../../entities';

const GENERATE_QUESTION_RETRY_LIMIT = 3;

export const recordContentAnswerGraphQLCall = async (
  request: RecordContentAnswerRequest,
): Promise<RecordContentAnswerResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapUpdateUserProgress($input: TeachTapUpdateUserProgressInput!) {
          teachTapUpdateUserProgress(input: $input) {
            currentUnitId
            currentTopicId
            topicProgress {
              topicId
              studentGrade
              nextGradeProgress
            }
            unitProgress {
              unitId
              studentGrade
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapUpdateUserProgress;
};

export const generateQuestionGraphQLCall = async (
  request: GenerateQuestionRequest,
  signal?: AbortSignal,
): Promise<GenerateQuestionResponse[]> => {
  const retryCount = request.retryCount || 0;
  const inputRequest = { ...request };
  delete inputRequest.retryCount;

  try {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }

    const response = await graphqlApi.post(
      '',
      {
        variables: {
          input: inputRequest,
        },
        query: `
        mutation GenerateTeachTapContent($input: TeachTapGetLearningContentItemsInput!) {
          teachTapGetLearningContentItems(input: $input) {
            ${LearningContentItemOutputGQLSchema}
          }
        }`,
      },
      {
        signal,
        headers: {
          ...authHeader,
        },
      },
    );

    handleGraphQLError(response);

    const result = response.data.data?.teachTapGetLearningContentItems;
    if (!result?.length && retryCount < GENERATE_QUESTION_RETRY_LIMIT) {
      throw new Error('No content generated. Retrying...');
    }

    return result;
  } catch (e) {
    if ((e as Error).name === 'AbortError') {
      return [];
    }

    if (retryCount < GENERATE_QUESTION_RETRY_LIMIT) {
      return await generateQuestionGraphQLCall(
        {
          ...request,
          retryCount: retryCount + 1,
        },
        signal,
      );
    }

    throw e;
  }
};

export const getQuestionByIdGraphQLCall = async (
  generatedContentId: string,
): Promise<GenerateQuestionResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        generatedContentId,
      },
      query: `
      query TeachTapGetLearningContentItemById($generatedContentId: String!) {
        teachTapGetLearningContentItemById(id: $generatedContentId) {
          ${LearningContentItemOutputGQLSchema}
        }
      }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );
  handleGraphQLError(response);
  return response.data.data?.teachTapGetLearningContentItemById;
};

export const recordContentViewGraphQLCall = async (
  generatedContentId: string,
): Promise<void> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: {
          generatedContentId,
        },
      },
      query: `
        mutation RecordGeneratedContentView($input: RecordGeneratedContentViewInput!) {
          recordGeneratedContentView(input: $input)
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data;
};
