import React, { useEffect, useMemo } from 'react';
import {
  Dimensions,
  ImageBackground,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Course, Unit } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { mapDataToUnitTestPayload } from '../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import {
  TestFeedHeader,
  TestPracticeTopicsView,
  TestPreviewInfoView,
} from '../components';
import { TestAttempt } from '../entities';
import { useTestDetailNavigator } from '../services/hooks';

const TEST_PREP_IMAGE_SOURCE =
  'https://teachtok-images.s3.amazonaws.com/test-prep-background.png';

export enum TestPracticeIntroOrigin {
  TEST_PREP_TAB = 'TestPrepTab',
  TEST_COMPLETION = 'TestCompletion',
}

export type TestPracticeIntroProps = {
  course: Course;
  unit: Unit;
  levelIds: string[];
  topicIds: string[];
  origin: TestPracticeIntroOrigin;
};

export type TestPracticeIntroScreenProps = {
  route: { params: TestPracticeIntroProps };
};

const bestPerformance = (bestAttempt: TestAttempt) => (
  <View style={styles.testInfoRow}>
    <AssetImage
      asset={Assets.test.yellowStar}
      height={28}
      width={28}
      fill="white"
    />
    <Text style={styles.bestAttemptText}>
      {locale.testPrep.best_performance}
      <Text style={styles.bestAttemptTextBold}>
        {locale.testPrep.best_performance_ap.replace(
          '${GRADE}',
          bestAttempt.grade,
        )}
      </Text>
    </Text>
  </View>
);

export const TestPracticeIntroScreen = (
  props: TestPracticeIntroScreenProps,
): React.ReactElement => {
  const { unit, course, levelIds, topicIds } = props.route?.params;
  const bestAttempt = useAppSelector(
    state => state.testPrep.bestAttempts[unit.id],
  );

  const { safeAreaBottom } = useSafeAreaCustomInsets();
  const { handleOpenPracticeFeedScreen } = useTestDetailNavigator();

  const testInfoRowData = useMemo(() => {
    return [
      {
        image: Assets.test.bullseye,
        text: locale.testPrep.first_practice_info_row,
        size: 28,
      },
      {
        image: Assets.test.lightBulbIcon,
        text: locale.testPrep.second_practice_info_row,
        size: 28,
      },
    ];
  }, []);

  const topicsInfoRowData = [
    {
      image: Assets.test.magnifyingGlass,
      title: locale.testPrep.topics_in_focus,
      size: 28,
    },
  ];

  const topicsInFocus = unit.topics.filter(topic =>
    topicIds.includes(topic.id),
  );

  const handleStartTestPress = () => {
    handleOpenPracticeFeedScreen(course, unit, levelIds, topicIds);

    trackAnalyticsEvent(Analytics.targetedPracticeStarted, {
      ...mapDataToUnitTestPayload(course, unit),
      from: props.route.params?.origin,
    });
  };

  useEffect(() => {
    trackAnalyticsEvent(Analytics.targetedPracticeOpened, {
      ...mapDataToUnitTestPayload(course, unit),
      from: props.route.params?.origin,
    });
  }, [course, props.route.params?.origin, unit]);

  return (
    <ScrollView
      style={styles.container}
      showsVerticalScrollIndicator={true}
      contentContainerStyle={[
        styles.contentContainer,
        { paddingBottom: safeAreaBottom + 16 },
      ]}>
      <ImageBackground
        source={{
          uri: TEST_PREP_IMAGE_SOURCE,
        }}
        style={styles.backgroundImage}
      />
      <View style={styles.bottomGradientContainer}>
        <LinearGradient
          colors={Colors.bottomGradientAppColor}
          style={styles.bottomGradient}
        />
        <View style={styles.bottomGradientView} />
      </View>
      <View style={styles.previewDataContainer}>
        {unit && (
          <View style={styles.titleContainer}>
            <Text style={styles.titleText} numberOfLines={1}>
              {locale.courses.unit} {unit.number}{' '}
              {locale.testPrep.targeted_practice}
            </Text>
            <Text style={styles.descriptionText}>
              {locale.testPrep.bridge_the_gaps} {locale.courses.unit}{' '}
              {unit.number}
              {':'} <Text style={styles.descriptionTextBold}>{unit.name}</Text>
            </Text>
          </View>
        )}

        <TestPreviewInfoView rowData={testInfoRowData} />
        <TestPracticeTopicsView
          rowData={topicsInfoRowData}
          topics={topicsInFocus}
        />
      </View>
      <View style={styles.contentPadding}>
        {bestAttempt && (
          <View style={[styles.bestAttemptInfoContainer, styles.gap20]}>
            {bestPerformance(bestAttempt)}
          </View>
        )}
        <TouchableOpacity
          style={styles.continueButton}
          onPress={() => handleStartTestPress()}>
          <Text style={styles.continueButtonText}>
            {locale.testPrep.start_practicing}
          </Text>
        </TouchableOpacity>
      </View>
      {unit && <TestFeedHeader />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  unitHeading: {
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  frqTitleContainer: {
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  bottomGradientContainer: {
    top: 200,
    width: '100%',
    position: 'absolute',
  },
  bottomGradient: {
    height: 100,
  },
  testInfoRow: {
    flexDirection: 'row',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  gap20: {
    gap: 20,
  },
  continueButton: {
    backgroundColor: Colors.additional.orange[100],
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    marginTop: Sizes.normalizeIP14PM(24),
    marginBottom: Sizes.normalizeIP14PM(12),
  },
  linkButton: {
    backgroundColor: Colors.lightBlue,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    marginTop: Sizes.normalizeIP14PM(15),
  },
  continueButtonText: {
    ...Fonts.large,
  },
  bestAttemptTextBold: {
    ...Fonts.captionBold,
    color: Colors.white80,
    flex: 1,
  },
  previewDataContainer: {
    paddingTop: Dimensions.get('window').height * 0.09,
    paddingHorizontal: 16,
    width: '100%',
    gap: Sizes.normalizeIP14PM(15),
  },
  frqDataContainer: {
    paddingHorizontal: 16,
    width: '100%',
    gap: Sizes.normalizeIP14PM(10),
  },
  contentPadding: {
    paddingHorizontal: 16,
    gap: Sizes.normalizeIP14PM(5),
  },
  contentContainer: {
    justifyContent: 'flex-end',
    backgroundColor: Colors.darkBlue,
    minHeight: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.black100,
    paddingBottom: 50,
  },
  titleText: {
    ...Fonts.xxxlargeBold,
    marginBottom: 10,
    textAlign: 'center',
  },
  descriptionText: {
    ...Fonts.medium,
    color: Colors.white70,
    textAlign: 'center',
    paddingBottom: Sizes.normalizeIP14PM(15),
  },
  descriptionTextBold: {
    ...Fonts.mediumBold,
    color: Colors.white70,
    textAlign: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: Sizes.normalizeIP14PM(400),
    top: 0,
    opacity: 0.2,
  },
  bottomGradientView: {
    backgroundColor: Colors.bottomGradientAppColor[1],
    width: '100%',
    height: Dimensions.get('window').height,
  },
  testInfoAssetContainer: {
    width: Sizes.normalizeIP14PM(34),
    alignItems: 'center',
    alignSelf: 'center',
  },
  bestAttemptInfoContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 14,
    borderColor: Colors.white12,
    marginTop: Sizes.normalizeIP14PM(20),
  },
  bestAttemptText: {
    ...Fonts.caption,
    color: Colors.white80,
    flex: 1,
  },
  subHeadingContainerText: {
    ...Fonts.normalize(Fonts.captionBold),
  },
  subHeadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});
