import React from 'react';
import { StyleProp, StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  bestScore: number;
  highlight?: boolean;
  style?: StyleProp<any>;
};

export const BestScoreBadgeView = (props: Props): React.ReactElement => {
  return (
    <View
      style={[
        styles.container,
        props.highlight && styles.containerHighlight,
        props.style,
      ]}>
      <AssetImage
        asset={Assets.test.star}
        height={20}
        fill={props.highlight ? 'white' : Colors.white40}
      />
      <Text style={styles.title}>
        {locale.testPrep.best_score_title}:{' '}
        <Text style={styles.scoreText}>{props.bestScore}</Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.white12,
    borderRadius: 10,
    columnGap: 4,
    padding: Sizes.small,
  },
  containerHighlight: {
    backgroundColor: Colors.actionGreen,
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: 110,
    height: 110,
    borderRadius: 100,
    backgroundColor: Colors.greenBonusOption,
  },
  scoreText: {
    ...Fonts.smallBold,
  },
  title: {
    ...Fonts.small,
  },
});
