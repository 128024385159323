import {
  CommonNameValueAttribute,
  MapAwardPointsAttributesRequestParams,
} from '../../entities/CommonAwardPointsRequestTypes';

export const mapAwardPointsAttributesRequest = (
  params: MapAwardPointsAttributesRequestParams,
): CommonNameValueAttribute[] => {
  return Object.entries(params).map(([name, value]) => ({ name, value }));
};
