import { Card, DmsFromDeadCard } from '../../../Common/entities';
import { mapCardToAnalyticsPayload } from '../../../Common/services/mappers';
import {
  DMSpeaker,
  DMFromDeadAnalyticsPayload,
  DmsFromDeadAnalyticsPayload,
  QuestionAskedAnalyticsPayload,
  MessageScreenEntryPoints,
} from '../../entities';

export const mapCardToDMFromDeadPayload = (
  card: Card,
): DMFromDeadAnalyticsPayload => {
  const dmsFromDeadCard = card as DmsFromDeadCard;
  return {
    ...mapCardToAnalyticsPayload(card),
    question: dmsFromDeadCard.question,
    speakerUsername: dmsFromDeadCard.character.username,
    speakerName: dmsFromDeadCard.character.name,
    answer: dmsFromDeadCard.transcript,
  };
};

export const mapDmsFromDeadQuestionToPayload = (
  question: string,
  dmFigure: { username: string; name: string },
): QuestionAskedAnalyticsPayload => {
  return {
    question: question,
    speakerUsername: dmFigure.username,
    speakerName: dmFigure.name,
  };
};

export const mapDmsSpeakerToPayload = (
  speaker: DMSpeaker,
  entryPoint: MessageScreenEntryPoints,
): DmsFromDeadAnalyticsPayload => {
  return {
    speakerUsername: speaker.username,
    speakerName: speaker.name,
    from: entryPoint,
  };
};
