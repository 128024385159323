import moment from 'moment';

import {
  APProgramCourseDetailWithAvatar,
  APProgramDetails,
  APProgramDetailsAnalytics,
  APTargetGrade,
} from '../../entities';

export const mapAPProgramDetailsToAnalytics = (
  apProgramDetails: APProgramDetails,
): APProgramDetailsAnalytics => {
  const { courseDetails, totalStudyCommitmentHours } = apProgramDetails;

  const programDuration = calculateProgramDuration(courseDetails);
  const targetGrades = mapTargetGrades(courseDetails);
  const timeCommitment = calculateTimeCommitment(totalStudyCommitmentHours);

  return {
    programDuration,
    targetGrades,
    studyCommitmentHours: totalStudyCommitmentHours,
    timeCommitment,
  };
};

const calculateProgramDuration = (
  courseDetails: APProgramCourseDetailWithAvatar[],
): string => {
  const sortedDates = courseDetails
    .map(course => moment(course.examDate, 'YYYY-MM-DD'))
    .sort((a, b) => a.valueOf() - b.valueOf());

  const startDate = moment().format('YYYY-MM-DD');
  const endDate = sortedDates.at(-1)?.format('YYYY-MM-DD') ?? startDate;

  return `${startDate} - ${endDate}`;
};

export const mapTargetGrades = (
  courseDetails: APProgramCourseDetailWithAvatar[],
): APTargetGrade[] => {
  return courseDetails.map(course => ({
    course: course.name,
    targetGrade: course.targetGrade,
  }));
};

const calculateTimeCommitment = (hoursPerWeek: number): string => {
  const hourText = hoursPerWeek === 1 ? 'Hour' : 'Hours';
  return `${hoursPerWeek} ${hourText} Per Week`;
};
