import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  Colors,
  isWebPlatform,
} from '../../Common/services/utils/AppConstants';

import { AiFigureProfileList } from './AiFigureProfileListContainer';

// need to lazy load to avoid error on web-preview build, react-native-skeleton-placeholder is not supported on web
const SkeletonPlaceholder = React.lazy(
  () => import('react-native-skeleton-placeholder'),
);
const SkeletonPlaceholderItem = React.lazy(() =>
  import('react-native-skeleton-placeholder').then(module => ({
    default: module.default.Item,
  })),
);

type Props = {
  itemNumber: number;
  itemWidth: number;
  itemHeight: number;
};

type LoadingItemProps = {
  itemHeight: number;
  itemWidth: number | string;
  borderRadius?: number;
};

const DEFAULT_BORDER_RADIUS = 8;

const WebLoadingItem = ({
  itemWidth,
  itemHeight,
  borderRadius = DEFAULT_BORDER_RADIUS,
}: LoadingItemProps) => {
  return (
    <View
      style={[
        styles.itemWeb,
        { width: itemWidth, height: itemHeight, borderRadius: borderRadius },
      ]}
    />
  );
};

const MobileLoadingItem = ({
  itemWidth,
  itemHeight,
  borderRadius = DEFAULT_BORDER_RADIUS,
}: LoadingItemProps) => {
  return (
    <SkeletonPlaceholder backgroundColor={Colors.white30}>
      <SkeletonPlaceholderItem
        width={itemWidth}
        height={itemHeight}
        borderRadius={borderRadius}
        style={styles.item}
      />
    </SkeletonPlaceholder>
  );
};

export const AiFigureLoadingItem = ({
  itemWidth,
  itemHeight,
  borderRadius,
}: LoadingItemProps): React.ReactElement => {
  if (isWebPlatform) {
    return (
      <WebLoadingItem
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        borderRadius={borderRadius}
      />
    );
  }

  return (
    <MobileLoadingItem
      itemWidth={itemWidth}
      itemHeight={itemHeight}
      borderRadius={borderRadius}
    />
  );
};

export const AiFigureProfileLoading = ({
  itemWidth,
  itemHeight,
  itemNumber,
}: Props): React.ReactElement => {
  const generatedItems = useMemo(() => {
    if (!itemNumber) {
      return [];
    }

    return new Array(itemNumber)
      .fill(0)
      .map((_, index) => `loading-state-${index}`);
  }, [itemNumber]);

  return (
    <AiFigureProfileList data={generatedItems}>
      {() => (
        <AiFigureLoadingItem itemWidth={itemWidth} itemHeight={itemHeight} />
      )}
    </AiFigureProfileList>
  );
};

const styles = StyleSheet.create({
  container: { marginHorizontal: 16, marginTop: 4 },
  item: { marginRight: 8 },
  itemWeb: {
    marginRight: 8,
    backgroundColor: Colors.white30,
  },
});
