import { SATUnitTestContent } from '../../../SAT/entities';
import {
  APUnitTestContent,
  QuestionTutorHelp,
} from '../../../TestPrep/entities';

export const mapTestPrepTutorForSat = (
  content: SATUnitTestContent,
): QuestionTutorHelp | undefined => {
  if (content.learning_content.speaker && content.learning_content.url) {
    return {
      name: content.learning_content.speaker.name,
      avatarURL: content.learning_content.speaker.image.url,
      videoURL: content.learning_content.url,
      subtitleURL: content.learning_content.subtitles?.url,
      title: content.learning_content.speaker.biography_short,
    };
  } else if (content.tutor?.name) {
    return {
      name: content.tutor.name,
      avatarURL: content.tutor.image.url,
      title: content.tutor.biography_short,
    };
  }

  return undefined;
};

export const mapTestPrepTutorForAp = (
  content: APUnitTestContent,
): QuestionTutorHelp | undefined => {
  return {
    name: content.help.video.speaker.name,
    avatarURL: content.help.video.speaker.image.url,
    videoURL: content.help.video.url,
    subtitleURL: content.help.video.subtitles?.url,
    title: content.help.video.speaker.biography_short,
  };
};
