import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';

import locale from '../../../../App/locale';
import { getAuthService } from '../../../../App/services/amplify/AuthService';
import { getS3Service } from '../../../../App/services/amplify/S3Service';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { getUserProfile } from '../../../../Auth/services/slices';
import {
  showErrorMessage,
  trackAnalyticsEvent,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { UpdateProfileImageProps } from '../../../entities';
import { updateUserProfileImageGraphQLCall } from '../../../graphql';
import { mapProfileDetailsUpdateAnalytics } from '../../mappers/ProfileAnalyticsMapper';
import { PROFILE_SLICE_NAME, ProfileSliceState } from '../ProfileSlice';

const getProfileImageURL = async (localImagePath: string | undefined) => {
  const cases = {
    hasLocalImage: async () =>
      localImagePath ? await getS3Service().uploadImage(localImagePath) : '',
    default: () => '',
  };

  if (localImagePath) {
    return await cases.hasLocalImage();
  }
  return cases.default();
};

export const updateUserProfileImage = createAsyncThunk(
  `${PROFILE_SLICE_NAME}/updateUserProfileImage`,
  async (params: UpdateProfileImageProps, thunkApi) => {
    const { localImagePath, from } = params;

    try {
      const userId = await getAuthService().getCurrentUserId();
      const profileImageURL = await getProfileImageURL(localImagePath);

      await updateUserProfileImageGraphQLCall({
        userId,
        profileImageURL,
      });

      trackAnalyticsEvent(
        Analytics.profileDetailsUpdated,
        mapProfileDetailsUpdateAnalytics({ from, profileImageUpdated: true }),
      );

      await thunkApi
        .dispatch(getUserProfile({ silent: true, skipAfterAuth: true }))
        .unwrap();

      showMessage({
        message: locale.profile_screen_settings.profile_updated_message,
        type: 'success',
      });

      return { success: true, errorMessage: '' };
    } catch (e: unknown) {
      let message = '';
      if (e instanceof Error) {
        const error: Error = e;
        message = error?.message || locale.errors.something_went_wrong;
        if (
          message ===
          locale.profile_screen_settings.photo_not_allowed_server_error
        ) {
          message = locale.profile_screen_settings.photo_inappropriate_error;
        }

        handleNetworkActionErrorSilently(error);
        showErrorMessage({
          message: locale.profile_screen_settings.photo_failed_error,
        });
      }

      return { success: false, errorMessage: message };
    }
  },
);

export const updateUserProfileImageExtraReducers = (
  builder: ActionReducerMapBuilder<ProfileSliceState>,
): void => {
  builder.addCase(
    updateUserProfileImage.fulfilled,
    (state: ProfileSliceState) => {
      state.isProfileImageLoading = false;
    },
  );
  builder.addCase(
    updateUserProfileImage.pending,
    (state: ProfileSliceState) => {
      state.isProfileImageLoading = true;
    },
  );
  builder.addCase(
    updateUserProfileImage.rejected,
    (state: ProfileSliceState) => {
      state.isProfileImageLoading = false;
    },
  );
};
