import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { openWebLink } from '../../../Common/services/utils';
import { Colors, Fonts } from '../../../Common/services/utils/AppConstants';

export const BootcampPaywallFooter = (): React.ReactElement => {
  const handleTermsAndConditionsPress = useCallback(() => {
    openWebLink(locale.auth_screen.terms_of_service_url);
  }, []);

  const handlePrivacyPolicyPress = useCallback(() => {
    openWebLink(locale.auth_screen.privacy_policy_url);
  }, []);

  return (
    <View style={styles.footer}>
      <Text style={styles.footerText}>
        {locale.bootcamp_paywall.footer_text}
        <Text style={styles.footerLink} onPress={handleTermsAndConditionsPress}>
          {locale.bootcamp_paywall.terms_conditions}
        </Text>
        &nbsp;{locale.common.and}&nbsp;
        <Text style={styles.footerLink} onPress={handlePrivacyPolicyPress}>
          {locale.bootcamp_paywall.privacy_policy}
        </Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
  },
  footerText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },
  footerLink: {
    color: Colors.white60,
    textDecorationLine: 'underline',
  },
});
