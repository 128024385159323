import BottomSheet from '@gorhom/bottom-sheet';
import { Portal } from '@gorhom/portal';
import React, {
  forwardRef,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FlatList, Pressable, StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useSafeAreaCustomInsets } from '../services/hooks';
import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

import { renderCommonSheetBackdrop } from './CommonSheetBackdrop';

export type CommonDropdownRefType = {
  open: () => void;
  close: () => void;
};

type Props<T> = {
  items: T[];
  selectedValue?: T;
  onValueChange: (itemValue: T, itemIndex: number) => void;
  isDisabled?: boolean;

  getId?: (item: T, index: number) => string;
  getLabel?: (item: T, index: number) => string;
  onClose?: () => void;

  defaultHeight?: number;
};

export const CommonDropdown = forwardRef<
  CommonDropdownRefType | undefined,
  Props<any>
>((props, ref): React.ReactElement => {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [sheetIndex, setSheetIndex] = useState(-1);
  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const isSheetVisible = useMemo(() => sheetIndex > -1, [sheetIndex]);

  const snapPoints = useMemo(
    () => [safeAreaBottom + (props.defaultHeight || 250)],
    [props.defaultHeight, safeAreaBottom],
  );

  const handleOpen = useCallback(() => {
    if (!props.isDisabled) {
      setSheetIndex(0);
      bottomSheetRef.current?.expand();
    }
  }, [props.isDisabled]);

  const handleClose = useCallback(() => {
    setSheetIndex(-1);
    bottomSheetRef.current?.close();

    if (props.onClose) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  const handleValueChange = useCallback(
    (_value: unknown, itemIndex: number) => {
      if (!props?.items?.length) {
        return;
      }

      props.onValueChange(props.items[itemIndex], itemIndex);
      handleClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onValueChange, props.items, handleClose],
  );

  const handleGetItemStyle = useCallback((index: number) => {
    return [styles.itemContainer, index === 0 && styles.firstItemContainer];
  }, []);

  const handleGetId = useCallback(
    (item: any, index: number) => {
      return props.getId ? props.getId(item, index) : index.toString();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getId],
  );

  const containerStyle = useMemo(() => {
    return [
      styles.mainContainer,
      { paddingBottom: safeAreaBottom + Sizes.medium },
    ];
  }, [safeAreaBottom]);

  const handleGetLabel = useCallback(
    (item: any, index: number) => {
      return props.getLabel ? props.getLabel(item, index) : item;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getLabel],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        open: handleOpen,
        close: handleClose,
      };
    },
    [handleOpen, handleClose],
  );

  return (
    <Portal>
      <BottomSheet
        ref={bottomSheetRef}
        enablePanDownToClose
        index={sheetIndex}
        backgroundStyle={styles.background}
        handleIndicatorStyle={styles.handle}
        snapPoints={snapPoints}
        animateOnMount={false}
        onChange={setSheetIndex}
        backdropComponent={() =>
          renderCommonSheetBackdrop({
            onPress: handleClose,
            isVisible: isSheetVisible,
            style: styles.backdrop,
          })
        }>
        <View style={containerStyle}>
          <FlatList
            showsVerticalScrollIndicator
            data={props.items}
            renderItem={({ item, index }) => (
              <Pressable
                key={handleGetId(item, index)}
                style={handleGetItemStyle(index)}
                onPress={() => handleValueChange(item, index)}>
                <View style={styles.selectedContainer}>
                  {props.selectedValue === item && (
                    <AssetImage asset={Assets.common.check} width={14} />
                  )}
                </View>

                <Text style={styles.text}>{handleGetLabel(item, index)}</Text>
              </Pressable>
            )}
          />
        </View>
      </BottomSheet>
    </Portal>
  );
});

export const renderCommonDropdown = <T,>(
  props: Props<T> & { ref?: Ref<CommonDropdownRefType | undefined> },
): React.ReactElement => <CommonDropdown {...props} />;

const styles = StyleSheet.create({
  background: {
    backgroundColor: Colors.mediumGray,
  },
  backdrop: {
    backgroundColor: Colors.black20,
  },
  handle: {
    backgroundColor: 'white',
  },

  mainContainer: {
    flex: 1,
  },

  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    paddingHorizontal: Sizes.small,
    paddingVertical: Sizes.semiMedium,
    borderTopWidth: 1,
    borderTopColor: Colors.white22,
  },
  firstItemContainer: {
    borderTopWidth: 0,
  },

  selectedContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 16,
  },
  text: {
    ...Fonts.semiLarge,
    color: 'white',
  },
});
