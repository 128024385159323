import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, ScrollView, ViewStyle } from 'react-native';
import {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import {
  MatchingPairsAnswerOptions,
  MatchingPairsCard,
  MatchingPairsOptions,
} from '../../../../Common/entities';
import {
  useDebounce,
  useRefWithSubscribe,
  useStateWithRef,
} from '../../../../Common/services/hooks';
import { redirectToAppstore } from '../../../../Common/services/utils';
import {
  Colors,
  isWebPlatform,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';
import {
  getSelectedMatchingAnswer,
  processMatchingPairsAnswer,
  selectMatchingPairsOption,
} from '../../../services/slices';

export type InitialLayoutHandlerOutput = {
  maxHeight: number | undefined; // maxHeight of left/right options
  scrollViewRef: RefObject<ScrollView>; // ref to scroll to top
  handleOnItemLayout: (e: LayoutChangeEvent, itemUniqueId: string) => void; // handle onLayout event of each option
};

type HeightList = Record<string, number>;

export const useMatchingPairsOptionsInitialLayoutHandler =
  (): InitialLayoutHandlerOutput => {
    const [maxHeight, setMaxHeight, maxHeightRef] = useStateWithRef<
      number | undefined
    >(undefined);
    const heightRef = useRef<HeightList>({});
    const scrollViewRef = useRef<ScrollView>(null);

    const handleCompareHeightRef = useCallback(() => {
      const newMaxHeight = Object.values(heightRef.current).reduce(
        (acc, curr) => Math.max(acc, curr),
        0,
      );

      if (newMaxHeight < Sizes.xxlarge) {
        setMaxHeight(Sizes.xxlarge);
      } else if (!maxHeightRef.current) {
        setMaxHeight(newMaxHeight);
      } else if (newMaxHeight > maxHeightRef.current) {
        setMaxHeight(newMaxHeight);
      }
    }, [heightRef, setMaxHeight, maxHeightRef]);

    const debounceHandleCompareHeightRef = useDebounce(
      handleCompareHeightRef,
      100,
    );

    const handleOnItemLayout = useCallback(
      (e: LayoutChangeEvent, itemUniqueId: string) => {
        heightRef.current[itemUniqueId] = e.nativeEvent.layout.height;
        debounceHandleCompareHeightRef();
      },
      [debounceHandleCompareHeightRef],
    );

    const handleScrollToTop = useCallback(() => {
      if (!scrollViewRef.current) {
        return;
      }

      scrollViewRef.current.scrollTo({
        x: 0,
        y: 0,
        animated: true,
      });
    }, []);

    useEffect(() => {
      handleScrollToTop();
    }, [maxHeight, handleScrollToTop]);

    return {
      maxHeight,
      scrollViewRef,
      handleOnItemLayout,
    };
  };

type OptionsHandlerOutput = {
  left?: MatchingPairsOptions; // selected left option
  right?: MatchingPairsOptions; // selected right option
  handleSelectLeftOption: (option: MatchingPairsOptions) => void; // select left option
  handleSelectRightOption: (option: MatchingPairsOptions) => void; // select right option
  correctAnimation: boolean; // show correct animation
  showAnimation: boolean; // show animation
};

type OptionsHandlerProps = {
  item: MatchingPairsCard; // current card
  answers: MatchingPairsAnswerOptions; // current answers
  onAnswer: (answer: string, isCorrect: boolean) => void; // callback when answer is selected
};

enum AnimationState {
  DEFAULT = 0,
  SELECTED = 1,
  PAIRED = 2,
  PAIRED_CORRECT = 3,
}

export const useAnimationsState = (
  isSelected: boolean,
  shouldAnimate: boolean,
  isPaired: boolean,
): ViewStyle => {
  const [animationColorSate, setAnimationColorState] = useStateWithRef(
    isPaired ? AnimationState.PAIRED_CORRECT : AnimationState.DEFAULT,
  );
  const localPairedRef = useRef(isPaired);

  const progress = useDerivedValue(() => {
    return withTiming(animationColorSate, {
      duration: !isSelected && isPaired ? 100 : 300,
    });
  });

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        progress.value,
        [
          AnimationState.DEFAULT,
          AnimationState.SELECTED,
          AnimationState.PAIRED,
          AnimationState.PAIRED_CORRECT,
        ],
        [
          Colors.black60,
          isSelected ? Colors.answerSelected70 : Colors.black60,
          isPaired ? Colors.answerRight70 : Colors.answerWrong70,
          Colors.buttonGrey,
        ],
      ),
    };
  });

  const handleAnimationColorState = useCallback(
    (selected: boolean, animate: boolean, paired: boolean) => {
      if (!selected && paired) {
        setAnimationColorState(AnimationState.PAIRED_CORRECT);
        return;
      }

      if (!selected) {
        setAnimationColorState(AnimationState.DEFAULT);
        return;
      }

      if (animate) {
        setAnimationColorState(AnimationState.PAIRED);

        if (paired) {
          localPairedRef.current = true;
          setTimeout(() => {
            setAnimationColorState(AnimationState.PAIRED_CORRECT);
          }, 1000);
        }
        return;
      }

      setAnimationColorState(AnimationState.SELECTED);
    },
    [setAnimationColorState],
  );

  useEffect(() => {
    handleAnimationColorState(isSelected, shouldAnimate, isPaired);
  }, [handleAnimationColorState, isSelected, shouldAnimate, isPaired]);

  return reanimatedStyle;
};

export const useMatchingPairsOptionsHandler = ({
  onAnswer,
  item,
  answers,
}: OptionsHandlerProps): OptionsHandlerOutput => {
  const dispatch = useAppDispatch();

  const { left, right } = useAppSelector(
    state =>
      getSelectedMatchingAnswer(state.answer, item) || {
        left: undefined,
        right: undefined,
      },
  );
  const [showAnimation, setShowAnimation] = useState(false);
  const [correctAnimation, setCorrectAnimation] = useState(false);
  const preventSelectRef = useRef(false);

  // To prevent re-render each-time any vars below changed
  const onAnswerRef = useRefWithSubscribe(onAnswer);
  const itemRef = useRefWithSubscribe(item);

  const handleSelectLeftOption = useCallback(
    (option: MatchingPairsOptions) => {
      const currentLeftOption = answers.leftOptions.find(
        leftOption => leftOption.uniqueId === option.uniqueId,
      );
      if (currentLeftOption?.pairedAt || preventSelectRef.current) {
        return;
      }

      dispatch(
        selectMatchingPairsOption({
          item: itemRef.current,
          option,
          isLeft: true,
        }),
      );
    },
    [dispatch, itemRef, answers.leftOptions, preventSelectRef],
  );

  const handleSelectRightOption = useCallback(
    (option: MatchingPairsOptions) => {
      const currentRightOption = answers.rightOptions.find(
        rightOption => rightOption.uniqueId === option.uniqueId,
      );
      if (currentRightOption?.pairedAt || preventSelectRef.current) {
        return;
      }

      dispatch(
        selectMatchingPairsOption({
          item: itemRef.current,
          option,
          isLeft: false,
        }),
      );
    },
    [dispatch, itemRef, answers.rightOptions, preventSelectRef],
  );

  const handleResetOption = useCallback(() => {
    dispatch(
      selectMatchingPairsOption({
        item: itemRef.current,
        option: undefined,
        isLeft: false,
      }),
    );

    dispatch(
      selectMatchingPairsOption({
        item: itemRef.current,
        option: undefined,
        isLeft: true,
      }),
    );
  }, [dispatch, itemRef]);

  const handleProceedAnimation = useCallback(
    async (isCorrect: boolean) => {
      preventSelectRef.current = true;
      setCorrectAnimation(isCorrect);

      await new Promise(resolve => setTimeout(resolve, 300));
      setShowAnimation(true);

      await new Promise(resolve => setTimeout(resolve, 1500));
      setShowAnimation(false);

      preventSelectRef.current = false;
      handleResetOption();
    },
    [setCorrectAnimation, setShowAnimation, handleResetOption],
  );

  const handleProcessMatchingPairsAnswer = useCallback(
    (left: MatchingPairsOptions, right: MatchingPairsOptions) => {
      if (isWebPlatform) {
        redirectToAppstore();
        return;
      }
      dispatch(
        processMatchingPairsAnswer({
          item: itemRef.current,
          leftOption: left,
          rightOption: right,
        }),
      );
    },
    [dispatch, itemRef],
  );

  const handleOnOptionChange = useCallback(
    (left?: MatchingPairsOptions, right?: MatchingPairsOptions) => {
      if (!left || !right) {
        return;
      }

      if (
        item.pairsFromLeftOptions[left.uniqueId].answerUniqueId ===
        right.uniqueId
      ) {
        handleProceedAnimation(true);
        onAnswerRef.current(left.uniqueId, true);
      } else {
        handleProceedAnimation(false);
        onAnswerRef.current(left.uniqueId, false);
      }

      handleProcessMatchingPairsAnswer(left, right);
    },
    [
      item.pairsFromLeftOptions,
      handleProceedAnimation,
      handleProcessMatchingPairsAnswer,
      onAnswerRef,
    ],
  );

  useEffect(() => {
    handleOnOptionChange(left, right);
  }, [left, right, handleOnOptionChange]);

  return {
    left,
    right,
    handleSelectLeftOption,
    handleSelectRightOption,
    correctAnimation,
    showAnimation,
  };
};
