export enum RaiseHandScreenTypes {
  DmsDead = 'dmsDead',
  StudyFeed = 'feed',
  UnitTestQuestion = 'unitTestQuestion',
  TargetedPractice = 'targetedPracticeFeed',
}

export type ChatHistoryItem = {
  message: string;
  // if null, then it's a message from the system
  senderUserId?: string;
  timestamp: string;
  platformGeneratedContentId: string;
};

export type GetRaiseHandContentGeneratorIdParams = {
  isFromTestPrep?: boolean;
  isSAT?: boolean;
  isMathQuestion?: boolean;
  isInitial?: boolean;
};
