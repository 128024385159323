import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import { RootState } from '../../../App/store';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import { TrackNextTutorialPressedParams, TutorialType } from '../../entities';

interface TutorialState {
  showTutorialScreen: boolean;
  shownTutorial: Record<string, boolean>;
}

const initialState: TutorialState = {
  showTutorialScreen: false,
  shownTutorial: {},
};

const SLICE_NAME = 'TutorialSlice';

const persistConfig = {
  key: SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['showTutorialScreen', 'shownTutorial'],
};

export const setShownTutorial = createAsyncThunk(
  `${SLICE_NAME}/setShownTutorial`,
  async (tutorialType: TutorialType, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    if (rootState.tutorial.shownTutorial[tutorialType]) {
      return thunkApi.rejectWithValue('Tutorial already shown');
    }

    trackAnalyticsEvent(Analytics.viewedTutorial, {
      _suffix: tutorialType,
    });
    return thunkApi.fulfillWithValue(tutorialType);
  },
);

export const trackNextTutorialPressed = createAsyncThunk(
  `${SLICE_NAME}/trackNextTutorialPressed`,
  async (params: TrackNextTutorialPressedParams, thunkApi) => {
    const { tutorialType, isLast, from } = params;

    if (isLast) {
      trackAnalyticsEvent(Analytics.getStartedTutorialPressed, { from });
      thunkApi.dispatch(setShowTutorialScreen(false));
      return;
    }

    trackAnalyticsEvent(Analytics.nextTutorialPressed, {
      _suffix: tutorialType,
      from,
    });
  },
);

const tutorialSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setShowTutorialScreen: (state, action) => {
      state.showTutorialScreen = action.payload;
    },
    resetTutorialState: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(setShownTutorial.fulfilled, (state, action) => {
      const tutorialType = action.payload;
      state.shownTutorial[tutorialType] = true;
    });
  },
});

export const { setShowTutorialScreen, resetTutorialState } =
  tutorialSlice.actions;

export const TutorialSlice = persistReducer(
  persistConfig,
  tutorialSlice.reducer,
);
