export type PurchasableItem = {
  sku: string;
  type: 'product' | 'subscription';
  consumable: boolean;
  offerToken?: string;
  manuallyConsumed?: boolean;
};

export enum AddOnType {
  SAT_PREP_COURSE_12_MONTHS = '12MonthSATPrep',
  AP_PROGRAM = 'APProgram',
}

export type PurchaseTypeBySku = {
  SAT: boolean;
  AP: boolean;
};
