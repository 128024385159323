import React, { useEffect } from 'react';
import { LayoutChangeEvent, StyleSheet, View } from 'react-native';

import CharacterDescriptionView from '../../Common/components/home/CharacterDescriptionView';
import { DmsFromDeadCard } from '../../Common/entities';
import { AIContentType } from '../../Common/entities';
import { mapCardToAnalyticsPayload } from '../../Common/services/mappers';
import { redirectToAppstore } from '../../Common/services/utils';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  isWebPlatform,
} from '../../Common/services/utils/AppConstants';
import BaseContentCard from '../../Learn/screens/home/content/BaseContentCard';

import { SpeakerActionButtonsView } from './SpeakerActionButtonsView';
import { TranscriptTextView } from './TranscriptTextView';

type Props = {
  card: DmsFromDeadCard;
  onMediaPaused: () => void;
  isMediaPaused: boolean;
  onMediaMuted: () => void;
  isMediaMuted: boolean;
};

let titleWidthVar: number | null = null;

export const MessageVideoExplanation = (props: Props): React.ReactElement => {
  const onLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;
    titleWidthVar = width;
  };

  const onCardTap = () => {
    if (isWebPlatform) {
      if (!props.isMediaMuted) {
        redirectToAppstore();
      } else {
        props.onMediaMuted();
      }
      return true;
    } else {
      props.onMediaPaused();
    }
    return true;
  };

  const onMediaMute = () => {
    props.onMediaMuted();
  };

  useEffect(() => {
    const analyticsPayload = mapCardToAnalyticsPayload(props.card);
    if (analyticsPayload.cardType !== AIContentType.Unknown) {
      trackAnalyticsEvent(Analytics.dmAnswerView, {
        ...mapCardToAnalyticsPayload(props.card),
      });
    }
  }, [props.card]);

  return (
    <BaseContentCard item={props.card} onCardTap={onCardTap}>
      <View style={styles.contentContainer}>
        <View
          style={styles.textContainer}
          onLayout={!titleWidthVar ? onLayout : undefined}>
          <View style={styles.characterContainer}>
            <CharacterDescriptionView
              avatarUrl={props.card.character.avatarUrl}
              description={props.card.character.title}
              name={props.card.character.name}
            />
          </View>
        </View>
        <View style={styles.expanded}>
          <TranscriptTextView text={props.card.transcript} previewLength={50} />
        </View>
      </View>
      <SpeakerActionButtonsView
        item={props.card}
        onMediaMute={onMediaMute}
        mediaMuted={props.isMediaMuted}
      />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  expanded: {
    flexShrink: 1,
  },
  characterContainer: {
    rowGap: 5,
    marginTop: 10,
  },
  textContainer: {
    flexGrow: 20,
    justifyContent: 'flex-end',
    paddingBottom: 16,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: 10,
    backgroundColor: '#05071B00',
  },
});
