import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { CommonStartFeedHeader } from '../../Common/components';
import { Course } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { CourseFeedHeader } from '../../Learn/components/study/CourseFeedHeader';
import { SATHistoryDataSummary } from '../entities';
import { useSATProgramDetailsExist } from '../services/hooks';

import { SATProgramExamDate } from './SATProgramExamDate';
import { SATStatsSummary } from './SATStatsSummary';

type Props = {
  selectedCourse: Course;
  historyDataSummary?: SATHistoryDataSummary;
  title: string;
  description: string;
};

export const SATTestPrepFeedHeader = ({
  selectedCourse,
  historyDataSummary,
  title,
  description,
}: Props): React.ReactElement => {
  const shouldShowStats = useMemo(
    () => historyDataSummary?.anyTestTaken || false,
    [historyDataSummary],
  );

  const { safeAreaTop } = useSafeAreaCustomInsets();
  const isInProgram = useSATProgramDetailsExist();

  const contentContainerStyle = useMemo(() => {
    return [styles.contentContainer, { paddingTop: safeAreaTop }];
  }, [safeAreaTop]);

  return (
    <View>
      <View style={styles.topHeaderFiller} />

      <View style={contentContainerStyle}>
        <CourseFeedHeader
          course={selectedCourse}
          hideFollowingText
          isFromTestPrep
        />

        <View style={styles.examDateContainer}>
          <SATProgramExamDate />
        </View>

        {!shouldShowStats && (
          <View style={styles.startContainer}>
            <CommonStartFeedHeader title={title} description={description} />
          </View>
        )}

        {shouldShowStats && (
          <View style={styles.statsContainer}>
            <SATStatsSummary historyDataSummary={historyDataSummary} />
          </View>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  startContainer: {
    marginTop: Sizes.medium,
    paddingHorizontal: Sizes.small,
    paddingTop: Sizes.medium,
  },
  examDateContainer: {
    marginTop: Sizes.semiMedium,
  },
  statsContainer: {
    marginTop: Sizes.medium,
  },
  topHeaderFiller: {
    position: 'absolute',
    top: -300,
    left: 0,
    width: '100%',
    height: 300,
    backgroundColor: Colors.black80,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: Sizes.medium,
  },
  chartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Sizes.medium,
    padding: Sizes.semiMedium,
    paddingBottom: Sizes.small,
  },
  chartTitle: {
    ...Fonts.small,
    textAlign: 'center',
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: Sizes.semiMedium,
    marginTop: Sizes.xsmall,
  },
  legendIcon: {
    marginTop: 3,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
  },
  chartWithLegendContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: Sizes.medium,
    paddingTop: Sizes.medium,
  },
  legendContainer: {
    justifyContent: 'flex-start',
    marginLeft: Sizes.medium,
    marginRight: Sizes.medium,
  },
  legendText: {
    ...Fonts.small,
    color: Colors.white80,
    maxWidth: '100%',
  },
});
