import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { CommonApExamsConfigs } from '../../../Common/entities';
import { mapNextLastApExamDate } from '../mappers';

import { toggleSATBootcampAPAddonTrackActionExtraReducers } from './BootcampPaywallSliceActions';

export const BOOTCAMP_PAYWALL_SLICE_NAME = 'BootcampPaywallSlice';

export type BootcampPaywallSliceState = {
  isLoading: boolean;
  nextLastApExamDate?: string;
  isAPAddOnSelected: boolean;
};

const persistConfig = {
  key: BOOTCAMP_PAYWALL_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['promoCode', 'voucherValidity'],
} as PersistConfig<BootcampPaywallSliceState>;

const initialState: BootcampPaywallSliceState = {
  isLoading: false,
  nextLastApExamDate: undefined,
  isAPAddOnSelected: false,
};

const slice = createSlice({
  name: BOOTCAMP_PAYWALL_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetBootcampPaywallSlice: state => {
      state.isAPAddOnSelected = false;
      state.isLoading = false;
    },
    setBootcampPaywallLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setNextLastApExamDate: (
      state,
      action: PayloadAction<CommonApExamsConfigs | undefined>,
    ) => {
      state.nextLastApExamDate = mapNextLastApExamDate(action.payload);
    },
  },
  extraReducers: builder => {
    toggleSATBootcampAPAddonTrackActionExtraReducers(builder);
  },
});

export const {
  resetBootcampPaywallSlice,
  setBootcampPaywallLoading,
  setNextLastApExamDate,
} = slice.actions;

export const BootcampPaywallSlice = persistReducer(
  persistConfig,
  slice.reducer,
);
