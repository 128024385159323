import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { APProgram, SetApProgramTimeSpentSecondsParams } from '../../entities';

import {
  getUserApProgramDetailsExtraReducers,
  submitAndEnrollApCourseTargetGradeExtraReducers,
} from './APSliceActions';

const PERSIST_KEY = 'ApSlice';
export const AP_SLICE_NAME = 'ApSlice';

export type APSliceState = {
  program?: APProgram;

  learnModeTimeSpentSeconds: Record<string, number>;
  testPrepTimeSpentSeconds: Record<string, number>;
  submitAndEnrollApCourseLoading: Record<string, boolean>;

  tempCourseTargetGrade: Record<string, string>;
};

export const APSliceInitialState: APSliceState = {
  program: undefined,

  learnModeTimeSpentSeconds: {},
  testPrepTimeSpentSeconds: {},
  submitAndEnrollApCourseLoading: {},

  tempCourseTargetGrade: {},
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['program', 'learnModeTimeSpentSeconds'],
} as PersistConfig<APSliceState>;

const apSlice = createSlice({
  name: AP_SLICE_NAME,
  initialState: APSliceInitialState,
  reducers: {
    resetApSliceState: state => {
      state.program = undefined;

      state.learnModeTimeSpentSeconds = {};
      state.testPrepTimeSpentSeconds = {};
      state.submitAndEnrollApCourseLoading = {};

      state.tempCourseTargetGrade = {};
    },

    setApLearnModeTimeSpentSeconds: (
      state,
      action: SetApProgramTimeSpentSecondsParams,
    ) => {
      state.learnModeTimeSpentSeconds[action.payload.courseId] =
        action.payload.timeSpentSeconds;
    },
    setApTestPrepTimeSpentSeconds: (
      state,
      action: SetApProgramTimeSpentSecondsParams,
    ) => {
      state.testPrepTimeSpentSeconds[action.payload.courseId] =
        action.payload.timeSpentSeconds;
    },
  },
  extraReducers: builder => {
    getUserApProgramDetailsExtraReducers(builder);
    submitAndEnrollApCourseTargetGradeExtraReducers(builder);
  },
});

export const {
  resetApSliceState,
  setApLearnModeTimeSpentSeconds,
  setApTestPrepTimeSpentSeconds,
} = apSlice.actions;

export const APSlice = persistReducer(persistConfig, apSlice.reducer);
