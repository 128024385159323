import { useIsFocused } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  LayoutAnimation,
  LayoutAnimationConfig,
  StyleSheet,
  View,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { RootState, useAppDispatch } from '../../App/store';
import { mapDataToUnitTestSkippedQuestionReviewDialogShownPayload } from '../../Common/services/mappers';
import {
  isSATCourse,
  logger,
  trackAnalyticsEvent,
} from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { setSubmitIncompleteTestPopup } from '../../Popup/services/slices';
import { setUserInteractionHistory } from '../../RaiseHand/services/slices';
import {
  TestPrepFlowAnswers,
  TestPrepFlowQuestion,
  TestPrepHeader,
  TestPrepHelpPanel,
  TestPrepQuestionSelector,
} from '../components';
import { TestQuestionNavigationButtons } from '../components/TestQuestionNavigationButtons';
import { TestPrepOption, TestPrepQuestionEntryPoint } from '../entities';
import {
  useTestDetailNavigator,
  useTestPrepTimeSpentCounter,
} from '../services/hooks';
import {
  recordQuestionViewed,
  setCurrentTestHighestSeenQuestionIndex,
  setCurrentTestQuestionIndex,
  setTimerPaused,
  submitTestQuestionAnswer,
  submitTestResults,
} from '../services/slices';

const ANIMATION_CONFIG: LayoutAnimationConfig = {
  ...LayoutAnimation.Presets.easeInEaseOut,
  delete: undefined,
  create: undefined,
};

export type TestQuestionFlowScreenProps = {
  isFromReview?: boolean;
  questionIndex?: number;
};

type ScreenProps = {
  route: { params: TestQuestionFlowScreenProps };
};

export const TestQuestionFlowScreen = ({
  route,
}: ScreenProps): React.ReactElement => {
  const { isFromReview } = route?.params;

  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();

  const currentQuestionIndex = useAppSelector(
    state => state.testPrep.currentTest?.currentQuestionIndex ?? 0,
  );
  const isLoading = useAppSelector(
    state => state.testPrep.isLoading,
    (prev, next) => prev === next,
  );
  const currentTest = useAppSelector(
    (state: RootState) => state.testPrep.currentTest!,
  );

  useTestPrepTimeSpentCounter(currentTest.course?.id);

  const setCurrentQuestionIndex = useCallback(
    (index: number, from: TestPrepQuestionEntryPoint) =>
      dispatch(setCurrentTestQuestionIndex({ index, from })),
    [dispatch],
  );

  const [correctAnswers, setCorrectAnswers] = useState(0);

  const [testEnded, setTestEnded] = useState(false);

  const { handleReviewBackPress } = useTestDetailNavigator();

  const totalQuestions = useMemo(
    () => currentTest?.questions?.length ?? 1,
    [currentTest?.questions],
  );

  const handleCorrectAnswer = () => {
    setCorrectAnswers(correctAnswers + 1);
  };

  const endTest = useCallback(
    async (timesUp: boolean) => {
      if (!isLoading && !testEnded) {
        try {
          setTestEnded(true); // Set the flag to true to prevent further calls
          await dispatch(submitTestResults({ timesUp })).unwrap();
        } catch (e) {
          logger.error('Error submitting test results: ' + e);
          showMessage({
            message: locale.errors.error_submitting_test_results,
            type: 'danger',
            duration: 10000,
          });

          setTestEnded(false);
        }
      }
    },
    [isLoading, testEnded, setTestEnded, dispatch],
  );

  const handleAnswerSelect = (option: TestPrepOption) => {
    dispatch(
      submitTestQuestionAnswer({
        testQuestion: currentTest.questions[currentQuestionIndex],
        option,
      }),
    );
    const isCorrect = option.correct;
    if (isCorrect) {
      handleCorrectAnswer();
    }

    dispatch(
      setUserInteractionHistory({
        generatedContentId: currentQuestion.generatedContentId,
        userInteractionHistory: {
          selectedOption: option.answer,
          correct: isCorrect,
        },
      }),
    );
  };

  const handlePreviousQuestion = useCallback(() => {
    setCurrentQuestionIndex(
      Math.max(currentQuestionIndex - 1, 0),
      TestPrepQuestionEntryPoint.PREVIOUS_QUESTION,
    );
  }, [currentQuestionIndex, setCurrentQuestionIndex]);

  const handleSubmitTest = useCallback(() => {
    const skippedQuestionsCount = currentTest.questions.filter(
      question => !question.chosenOption,
    ).length;
    if (skippedQuestionsCount > 0) {
      dispatch(setSubmitIncompleteTestPopup({ showPopup: true }));
      trackAnalyticsEvent(
        Analytics.unitTestSkippedQuestionReviewDialogShown,
        mapDataToUnitTestSkippedQuestionReviewDialogShownPayload(
          currentTest.course,
          currentTest.unit,
          skippedQuestionsCount,
          currentTest.testNumber,
        ),
      );
    } else {
      endTest(false);
    }
  }, [
    currentTest.course,
    currentTest.questions,
    currentTest.testNumber,
    currentTest.unit,
    dispatch,
    endTest,
  ]);

  const handleNextQuestion = useCallback(() => {
    setCurrentQuestionIndex(
      Math.min(currentQuestionIndex + 1, currentTest.questions.length - 1),
      TestPrepQuestionEntryPoint.NEXT_QUESTION,
    );
  }, [currentQuestionIndex, currentTest.questions, setCurrentQuestionIndex]);

  const currentQuestion = useMemo(() => {
    return currentTest.questions[currentQuestionIndex];
  }, [currentQuestionIndex, currentTest.questions]);

  const isSAT = useMemo(() => {
    return isSATCourse(currentTest.course);
  }, [currentTest.course]);

  const shouldUseMarkdownLatex = useMemo(() => {
    return (
      currentQuestion.renderer === 'latex' ||
      currentQuestion.renderer === 'markdown' ||
      isSAT
    );
  }, [currentQuestion.renderer, isSAT]);

  const isAnswered = useMemo(
    () => Boolean(currentQuestion.chosenOption?.id),
    [currentQuestion],
  );

  const questionElementContainerStyle = useMemo(() => {
    return [
      styles.questionElementContainer,
      (isAnswered || isFromReview) && { marginRight: Sizes.small },
    ];
  }, [isAnswered, isFromReview]);

  useEffect(() => {
    if (currentQuestion?.generatedContentId) {
      dispatch(recordQuestionViewed(currentQuestion.generatedContentId));
    }
  }, [dispatch, currentQuestion.generatedContentId, isFromReview]);

  useEffect(() => {
    if (isFocused) {
      if (testEnded || currentQuestion.chosenOption) {
        dispatch(setTimerPaused(true));
      } else {
        dispatch(setTimerPaused(false));
      }
    } else {
      dispatch(setTimerPaused(true));
    }
  }, [dispatch, testEnded, currentQuestion, isFocused]);

  useEffect(() => {
    LayoutAnimation.configureNext(ANIMATION_CONFIG);
  }, [currentQuestion]);

  const questionContainerStyles = useMemo(() => {
    return [
      styles.questionContainer,
      isFromReview && !isSAT && styles.topMargin,
    ];
  }, [isFromReview, isSAT]);

  useEffect(() => {
    dispatch(setCurrentTestHighestSeenQuestionIndex(currentQuestionIndex));
  }, [dispatch, currentQuestionIndex]);

  useEffect(() => {
    if (!isFromReview) {
      dispatch(
        setCurrentTestQuestionIndex({
          index: 0,
          from: TestPrepQuestionEntryPoint.UNIT_TEST_START,
        }),
      );
    }
  }, [dispatch, isFromReview]);

  return (
    <View style={styles.container}>
      <TestPrepQuestionSelector />
      <View style={styles.subContainer}>
        <View style={questionContainerStyles}>
          <View style={questionElementContainerStyle}>
            <TestPrepFlowQuestion
              currentQuestion={currentQuestion}
              shouldUseMarkdownLatex={shouldUseMarkdownLatex}
            />
          </View>

          <View style={styles.questionHelperContainer}>
            <TestPrepHelpPanel
              key={currentQuestion.id}
              currentQuestion={currentQuestion}
              currentTest={currentTest}
              isFromReview={isFromReview}
            />
          </View>
        </View>

        <View style={styles.answersContainer}>
          <TestPrepFlowAnswers
            isFromReview={isFromReview}
            currentQuestion={currentQuestion}
            onAnswerSelect={handleAnswerSelect}
            shouldUseMarkdownLatex={shouldUseMarkdownLatex}
          />
        </View>
        <TestQuestionNavigationButtons
          onPreviousQuestion={handlePreviousQuestion}
          onNextQuestion={handleNextQuestion}
          onSubmitTest={handleSubmitTest}
          currentQuestionIndex={currentQuestionIndex}
          questionsLength={totalQuestions}
        />
      </View>

      <TestPrepHeader
        questionIndex={currentQuestionIndex + 1}
        totalQuestions={totalQuestions}
        isFromReview={isFromReview}
        onReviewBackPress={handleReviewBackPress}
        handleTimerEnd={() => {
          if (!testEnded) {
            endTest(true);
          }
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.darkBlue,
    paddingTop: Sizes.safeAreaInsetsTop + 20,
    paddingBottom: Sizes.safeAreaInsetsBottom,
  },
  subContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  feedbackButton: {
    paddingVertical: Sizes.normalizeIP14PM(20),
    alignItems: 'center',
    left: -3,
    marginBottom: Sizes.normalizeIP14PM(10),
  },

  questionContainer: {
    flexDirection: 'row',
    flex: 6,
    minHeight: '40%',
  },
  questionElementContainer: {
    flex: 1,
    marginRight: Sizes.medium,
  },
  questionHelperContainer: {
    overflow: 'hidden',
    marginRight: -Sizes.medium,
  },
  topMargin: {
    marginTop: Sizes.normalizeIP14PM(20),
  },
  answersContainer: {
    maxHeight: '45%',
  },
  navigationButtonsContainer: {
    marginBottom: 10,
  },
});
