import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import Animated, { FadeInDown } from 'react-native-reanimated';

import { useAiFigureCharacterNavigator } from '../../AiFigure/services/hooks';
import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { DMSpeaker } from '../entities';

type Props = {
  speaker: DMSpeaker;
  showAskMeAnything: boolean;
};

type AskMeAnythingProps = {
  showAskMeAnything?: boolean;
};

const AskMeAnything = ({ showAskMeAnything }: AskMeAnythingProps) => {
  if (!showAskMeAnything) {
    return null;
  }

  return (
    <Animated.View entering={FadeInDown.duration(Durations.quickTransition)}>
      <View style={styles.askMeAnythingContainer}>
        <Text style={styles.askMeAnythingText}>Ask me anything!</Text>
      </View>

      <View style={styles.askMeAnythingBubble1} />
      <View style={styles.askMeAnythingBubble2} />
    </Animated.View>
  );
};

export const MessageSpeakerHeader = ({
  speaker,
  showAskMeAnything,
}: Props): React.ReactElement => {
  const handleCharacterPress = useAiFigureCharacterNavigator();

  return (
    <View style={styles.speakerInfoContainer}>
      <View style={styles.speakerContainer}>
        <AskMeAnything showAskMeAnything={showAskMeAnything} />

        <TouchableOpacity onPress={() => handleCharacterPress(speaker.name)}>
          <FastImage
            source={{ uri: speaker.avatarUrl }}
            style={styles.speakerAvatar}
            resizeMode="cover"
          />
        </TouchableOpacity>
      </View>

      <TouchableOpacity onPress={() => handleCharacterPress(speaker.name)}>
        <Text style={styles.speakerName}>{speaker.name}</Text>
      </TouchableOpacity>

      <Text style={styles.speakerInfo}>{speaker.username}</Text>
      <Text style={styles.speakerInfo}>{speaker.biography}</Text>
      <View style={styles.speakerStats}>
        <Text style={styles.speakerInfo}>
          97K {locale.dms_screen.followers}
        </Text>
        <AssetImage asset={Assets.message.dotIcon} height={4} />
        <Text style={styles.speakerInfo}>2.5K {locale.dms_screen.convos}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  speakerInfoContainer: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    rowGap: 7,
    paddingHorizontal: 20,
  },
  loadingContainer: {
    paddingTop: Sizes.semiMedium,
  },
  speakerName: {
    ...Fonts.mediumBold,
  },
  speakerInfo: {
    ...Fonts.small,
    color: Colors.captionGrey,
    fontSize: Fonts.small.fontSize - 1,
    textAlign: 'center',
  },
  speakerStats: {
    flexDirection: 'row',
    columnGap: 5,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  speakerAvatar: {
    width: 100,
    height: 100,
    borderRadius: 50,
    alignSelf: 'center',
  },

  speakerContainer: {
    justifyContent: 'center',
  },
  askMeAnythingContainer: {
    padding: 10,
    borderRadius: 14,
    backgroundColor: Colors.actionGreen,
    marginBottom: Sizes.semiMedium,
  },
  askMeAnythingText: {
    ...Fonts.small,
    color: Colors.grayscale[0],
    textAlign: 'center',
  },
  askMeAnythingBubble1: {
    position: 'absolute',
    width: Sizes.semiMedium,
    height: Sizes.semiMedium,
    borderRadius: Sizes.semiMedium,
    backgroundColor: Colors.actionGreen,

    right: Sizes.medium,
    bottom: Sizes.semiMedium / 2 + 1,
  },
  askMeAnythingBubble2: {
    position: 'absolute',
    width: Sizes.xsmall + 1,
    height: Sizes.xsmall + 1,
    borderRadius: Sizes.xsmall + 1,
    backgroundColor: Colors.actionGreen,

    right: Sizes.large + Sizes.xsmall,
    bottom: 0,
  },
});
