import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { CommonMarkdownLatex } from '../../Common/components';

interface LatexViewProps {
  identifier: string;
  content: string;
  fontSize?: number;
  showSelectedOptionHighLight?: boolean;
  tutorMessageTextColor?: string;
  webViewStyle?: ViewStyle;
}

export const LatexTextView = React.memo(
  ({
    identifier,
    content,
    fontSize,
    showSelectedOptionHighLight,
    tutorMessageTextColor,
    webViewStyle,
  }: LatexViewProps): React.ReactElement => {
    return (
      <View style={styles.latexText}>
        <CommonMarkdownLatex
          key={identifier}
          identifier={identifier}
          content={content}
          fontSize={fontSize}
          showSelectedOptionHighLight={showSelectedOptionHighLight}
          tutorMessageTextColor={tutorMessageTextColor}
          webViewStyle={webViewStyle}
        />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  latexText: {
    width: '100%',
  },
});

export const renderLatexTextView = (
  props: LatexViewProps,
): React.ReactElement => <LatexTextView {...props} />;
