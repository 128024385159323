import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { Unit } from '../../Common/entities';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { UnitTargetedPractice } from '../entities';

import { PracticeProgressView } from './PracticeProgressView';

interface ListViewProgressProps {
  progress: number;
  onPress?: () => void;
}

export const TestPracticeButton = ({
  progress,
  onPress,
}: ListViewProgressProps): React.ReactElement => {
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <PracticeProgressView progress={progress} />
      <Text style={styles.title}>{locale.testPrep.targeted_practice}</Text>
      <View style={styles.emptyView} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    marginStart: 2,
  },
  emptyView: {
    width: 28,
  },
  title: {
    ...Fonts.mediumBold,
    color: Colors.additional.orange[100],
  },
  container: {
    backgroundColor: Colors.lightOrange20,
    borderRadius: 6,
    padding: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
});

type RenderPracticeProgressProps = {
  unit: Unit;
  onPracticeTestPressed: (unit: Unit) => void;
  practice?: UnitTargetedPractice;
  isSATCourse?: boolean;
};

export const renderPracticeProgress = (
  props: RenderPracticeProgressProps,
): React.ReactElement | null => {
  if (!props.practice?.isAvailable || props.isSATCourse) {
    return null;
  }

  return (
    <TestPracticeButton
      progress={props.practice.progress}
      onPress={() => props.onPracticeTestPressed(props.unit)}
    />
  );
};
