import React, { useEffect, useRef } from 'react';
import { Animated, Dimensions, StyleSheet, View } from 'react-native';

interface ProgressBarProps {
  currentQuestionIndex: number;
  totalQuestions: number;
}

const DOT_SIZE = 10;
const BAR_WIDTH = Dimensions.get('window').width - 40;

export const TestPrepProgressBar = ({
  currentQuestionIndex,
  totalQuestions,
}: ProgressBarProps): React.ReactElement => {
  const progressAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    let progressPercentage = currentQuestionIndex / totalQuestions;

    // Adjust progressPercentage to slightly underfill on the last question
    if (currentQuestionIndex === totalQuestions - 1) {
      progressPercentage = (currentQuestionIndex * 0.99) / totalQuestions;
    } else if (currentQuestionIndex === totalQuestions) {
      progressPercentage = 1;
    }

    Animated.timing(progressAnim, {
      toValue: progressPercentage * BAR_WIDTH,
      duration: 500,
      useNativeDriver: false,
    }).start();
  }, [currentQuestionIndex, totalQuestions, progressAnim]);

  return (
    <View style={styles.container}>
      <View style={styles.progressBar}>
        {currentQuestionIndex === 0 && <View style={styles.dot} />}
        <Animated.View style={[styles.progress, { width: progressAnim }]} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  progressBar: {
    height: 10,
    backgroundColor: '#FFFFFF1F',
    borderRadius: 10,
    overflow: 'hidden',
    position: 'relative',
    width: BAR_WIDTH,
  },
  progress: {
    height: '100%',
    backgroundColor: '#349ECB',
    borderRadius: 10,
  },
  dot: {
    position: 'absolute',
    backgroundColor: '#349ECB',
    justifyContent: 'center',
    alignItems: 'center',
    left: 2,
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
  },
});
