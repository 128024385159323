import React, { useCallback, useEffect, useMemo } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { CommonFeedBackground } from '../../../Common/components';
import { Course, Unit } from '../../../Common/entities';
import { isSATCourse } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATBootcampFreeTrialCountdown } from '../../../FreeTrial/components/SATBootcampFreeTrialCountdown';
import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import {
  SATProgramCommitment,
  SATTestPrepFeedHeader,
  SATTestPrepUnits,
} from '../../../SAT/components';
import { SATFreeTrialExpireView } from '../../../SAT/components/paywall/SATFreeTrialExpireView';
import { SATStarterOrigin } from '../../../SAT/entities';
import { useSATNavigator } from '../../../SAT/services/hooks';
import { TestPrepRefreshHistoryControl } from '../../components';
import {
  getSATCalculatedScore,
  getSATTestPrepHistory,
  resetState,
} from '../../services/slices';

type Props = {
  selectedCourse: Course;
};

export const SATTestScreenView = (props: Props): React.ReactElement => {
  const { selectedCourse } = props;
  const { handleOpenStarterScreen } = useSATNavigator();

  const dispatch = useAppDispatch();

  const SATHistoryData = useAppSelector(state => state.testPrep.SATHistory);
  const SATHistorySummary = useAppSelector(state =>
    getSATCalculatedScore(state, selectedCourse?.id),
  );

  const isFreeTrialEnded = useAppSelector(state => state.SAT.isFreeTrialEnded);

  const handleUnitPress = useCallback(
    (unit: Unit) => {
      dispatch(resetState());
      if (selectedCourse && unit) {
        if (isSATCourse(selectedCourse)) {
          handleOpenStarterScreen(
            selectedCourse!,
            unit,
            SATStarterOrigin.TEST_PREP_TAB,
          );
        }
      }
    },
    [dispatch, selectedCourse, handleOpenStarterScreen],
  );

  useEffect(() => {
    if (selectedCourse?.id) {
      dispatch(
        getSATTestPrepHistory({
          course: selectedCourse,
        }),
      );
    }
  }, [dispatch, selectedCourse]);

  const readingUnits = useMemo(
    () =>
      selectedCourse.units?.filter(
        unit => unit.category === SAT_UNIT_CATEGORY.READING,
      ),
    [selectedCourse],
  );

  const mathUnits = useMemo(
    () =>
      selectedCourse.units?.filter(
        unit => unit.category === SAT_UNIT_CATEGORY.MATH,
      ),
    [selectedCourse],
  );

  return (
    <View style={styles.container}>
      <ScrollView
        style={styles.scrollContainer}
        showsVerticalScrollIndicator={false}
        refreshControl={
          <TestPrepRefreshHistoryControl courseId={selectedCourse?.id} />
        }
        contentContainerStyle={{ paddingBottom: Sizes.medium }}>
        <CommonFeedBackground course={selectedCourse} />

        <SATTestPrepFeedHeader
          selectedCourse={selectedCourse}
          historyDataSummary={SATHistorySummary}
          title={locale.study.start_sat_prep}
          description={locale.study.start_sat_prep_desc}
        />

        <View style={styles.sectionsContainer}>
          <View>
            <Text style={styles.sectionTitle}>
              {locale.study.sat_reading_writing}
            </Text>
            {readingUnits.map((unit: Unit, index) => (
              <SATTestPrepUnits
                key={unit.id}
                courseId={selectedCourse.id}
                firstIndex={index === 0}
                unit={unit}
                onUnitSelected={unit => handleUnitPress(unit)}
                history={SATHistoryData[unit.id]}
              />
            ))}
          </View>
          <View>
            <Text style={styles.sectionTitle}>{locale.study.sat_math}</Text>
            {mathUnits.map((unit: Unit, index) => (
              <SATTestPrepUnits
                key={unit.id}
                courseId={selectedCourse.id}
                firstIndex={index === 0}
                unit={unit}
                onUnitSelected={unit => handleUnitPress(unit)}
                history={SATHistoryData[unit.id]}
              />
            ))}
          </View>
        </View>
      </ScrollView>

      <SATProgramCommitment />
      <SATBootcampFreeTrialCountdown />
      {isFreeTrialEnded && <SATFreeTrialExpireView />}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: Colors.darkBlue,
  },
  promoText: {
    ...Fonts.medium,
    textAlign: 'center',
  },
  promoTextBold: {
    ...Fonts.mediumBold,
  },
  sectionsContainer: {
    gap: 24,
    marginTop: Sizes.large,
  },
  sectionTitle: {
    ...Fonts.smallBold,
    marginBottom: Sizes.small,
    marginLeft: Sizes.semiMedium,
  },
});
