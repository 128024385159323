import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  CreateUnitTestRequest,
  CreateUnitTestResponse,
  GetGeneratorExternalUrlRequest,
  GetGeneratorExternalUrlResponse,
  GetTestScorePercentileRequest,
  GetTestScorePercentileResponse,
  GetUnitTestsHistoryRequest,
  GetUnitTestsHistoryResponse,
} from '../../entities';

export const getUnitTestsHistoryGraphQLCall = async (
  request: GetUnitTestsHistoryRequest,
): Promise<GetUnitTestsHistoryResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetUnitTestsHistory($input: TeachTapGetUnitTestsHistoryInput!) {
          teachTapGetUnitTestsHistory(input: $input) {
            courseId
            units {
              id
              bestAttempt {
                grade
                submittedAt
              }
              recentAttempts {
                grade
                submittedAt
              }
              targetedPractice {
                isAvailable
                testId
                levelIds
                topicIds
                progress
              }
              totalAttempts
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetUnitTestsHistory;
};

export const getTestScorePercentileGraphQLCall = async (
  request: GetTestScorePercentileRequest,
): Promise<GetTestScorePercentileResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query GetTestScorePercentile($input: TeachTapGetTestScorePercentileInput!) {
          teachTapGetTestScorePercentile(input: $input) {
            percentile
            percentileExtended
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );
  handleGraphQLError(response);
  return response.data.data?.teachTapGetTestScorePercentile;
};

export const createUnitTestGraphQLCall = async (
  request: CreateUnitTestRequest,
): Promise<CreateUnitTestResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapCreateUnitTest($input: TeachTapCreateUnitTestInput!) {
          teachTapCreateUnitTest(input: $input) {
            courseId
            unitId
            testId
            maxScore
            minScore
            questions {
              difficulty
              sequenceNumber
              contentTypeId
              questionId
              content
              contentId
              levelId
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );
  handleGraphQLError(response);
  return response.data.data?.teachTapCreateUnitTest;
};

export const getGeneratorExternalUrlGraphQLCall = async (
  request: GetGeneratorExternalUrlRequest,
): Promise<GetGeneratorExternalUrlResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetGeneratorExternalUrl($input: TeachTapGetGeneratorExternalUrlInput!) {
          teachTapGetGeneratorExternalUrl(input: $input) {
            externalUrl
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetGeneratorExternalUrl;
};
