import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { updateUserProperties } from '../../../../Common/services/utils';
import { mapUserSignupData } from '../../mappers';
import { AUTH_SLICE_NAME } from '../AuthSlice';

export const updateMixpanelUserProfile = createAsyncThunk(
  `${AUTH_SLICE_NAME}/updateMixpanelUserProfile`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const onboardingState = rootState.onboarding;
      const apOnboardingState = rootState.apOnboarding;
      const signupState = rootState.signup;
      const profileState = rootState.profile;

      const userSignupData = mapUserSignupData({
        examType: onboardingState.examType,
        emailOptIn: profileState.marketingConsent,
        studentGrade: signupState.grade,
        SATProgramDetails: onboardingState.SATProgramDetails,
        apProgramDetails: apOnboardingState.apProgramDetails,
      });

      Object.keys(userSignupData).forEach(key => {
        const value = userSignupData[key as keyof typeof userSignupData];
        if (value) {
          updateUserProperties({
            [key]: value,
          });
        }
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);
