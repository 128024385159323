export type MapAwardPointsAttributesRequestParams = {
  subjectId?: string;
  courseId?: string;
  unitId?: string;
  topicId?: string;
};

export type CommonNameValueAttribute = { name: string; value: string };

export enum CommonAwardPointsActivityType {
  TARGETED_PRACTICE_FINISHED = 'TARGETED_PRACTICE_FINISHED',
  MASTERY_MILESTONE_ACHIEVED = 'MASTERY_MILESTONE_ACHIEVED',
}
