import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { redirectToLink } from '../../Common/services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { AiFigureModel } from '../entities';

import { AiFigureProfileHeaderLoading } from './AiFigureProfileHeaderLoading';

type Props = {
  isLoading: boolean;
  selectedFigure: AiFigureModel | undefined;
};

export const AiFigureProfileHeader = ({
  isLoading,
  selectedFigure,
}: Props): React.ReactElement => {
  const isTikTokVisible = useMemo(() => {
    return !isWebPlatform && selectedFigure?.tiktokLink;
  }, [selectedFigure?.tiktokLink]);

  const handleTikTokPress = useCallback(() => {
    if (!selectedFigure?.tiktokLink) {
      return;
    }

    redirectToLink(selectedFigure.tiktokLink);
  }, [selectedFigure?.tiktokLink]);

  if (isLoading) {
    return <AiFigureProfileHeaderLoading />;
  }

  return (
    <View style={style.container}>
      <View>
        <LinearGradient
          colors={Colors.aiProfileGradient}
          style={style.profileImageOuter}
          useAngle
          angle={180}>
          <FastImage
            source={{ uri: selectedFigure?.avatarUrl }}
            style={style.profileImage}
          />
        </LinearGradient>
      </View>

      <View>
        <Text style={style.name}>{selectedFigure?.displayName}</Text>
        {selectedFigure?.handle && (
          <Text style={style.handle}>{selectedFigure.handle}</Text>
        )}

        {isTikTokVisible && (
          <TouchableOpacity
            onPress={handleTikTokPress}
            style={style.tiktokContainer}>
            <View style={style.tiktokImageContainer}>
              <AssetImage
                asset={Assets.tiktokIcon}
                height={12}
                fill={'white'}
              />
            </View>
            <Text style={style.tiktokLabel}>{locale.ai_profile.tiktok}</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 16,
    marginTop: 8,
    alignItems: 'center',
  },

  profileImageOuter: {
    padding: 4,
    borderRadius: 100,
  },
  profileImage: {
    backgroundColor: 'black',
    width: Sizes.icons[80],
    height: Sizes.icons[80],
    borderRadius: 80,
  },

  name: { ...Fonts.mediumBold, color: Colors.grayscale[0] },
  handle: { ...Fonts.small, color: Colors.white60, marginTop: 2 },

  tiktokContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  tiktokImageContainer: { paddingTop: 4, marginRight: 2 },
  tiktokLabel: {
    ...Fonts.xsmallBold,
    color: Colors.actionGreen,
    marginTop: 4,
  },
});
