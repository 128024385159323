import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { RootState, useAppDispatch } from '../../App/store';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { setLeaveTestPopup } from '../../Popup/services/slices';
import { renderFeedbackButton } from '../../SAT/components';

import { TestPrepTimer } from './TestPrepTimer';

type Props = {
  questionIndex: number;
  totalQuestions: number;
  handleTimerEnd: () => void;
  isFromReview?: boolean;
  onReviewBackPress?: () => void;
};

export const TestPrepHeader = ({
  questionIndex,
  handleTimerEnd,
  totalQuestions,
  isFromReview,
  onReviewBackPress,
}: Props): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();
  const dispatch = useAppDispatch();

  const currentTest = useAppSelector(
    (state: RootState) => state.testPrep.currentTest,
  );

  const currentQuestionIndex = useAppSelector(
    state => state.testPrep.currentTest?.currentQuestionIndex ?? 0,
  );

  const currentQuestion = useMemo(() => {
    return currentTest?.questions?.[currentQuestionIndex];
  }, [currentQuestionIndex, currentTest?.questions]);

  const headerContainerStyle = useMemo(
    () => [
      styles.headerContainer,
      {
        paddingTop: safeAreaTop,
        height: safeAreaTop + 40,
      },
    ],
    [safeAreaTop],
  );

  const handleBackPress = useCallback(() => {
    if (isFromReview && onReviewBackPress) {
      onReviewBackPress();
    } else if (!isFromReview) {
      dispatch(setLeaveTestPopup({ showPopup: true }));
    }
  }, [dispatch, onReviewBackPress, isFromReview]);

  const renderQuestionCount = () =>
    locale.testPrep.question_count
      .replace('${CURRENT_INDEX}', questionIndex.toString())
      .replace('${TOTAL_QUESTION}', totalQuestions.toString());

  return (
    <LinearGradient
      colors={Colors.topicCompletionHeaderGradient}
      start={{ x: 0.5, y: 0.65 }}
      style={headerContainerStyle}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <AssetImage
            asset={Assets.common.backArrowButtonIcon}
            height={Sizes.large}
          />
        </TouchableOpacity>
        {renderFeedbackButton({
          currentTest,
          currentQuestion,
        })}
        <View style={styles.expanded} />
        <View style={styles.questionCountContainer}>
          <Text style={styles.questionCountTitle}>{renderQuestionCount()}</Text>
          {!isFromReview && <TestPrepTimer handleTimerEnd={handleTimerEnd} />}
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    width: '100%',
    alignSelf: 'center',
    alignItems: 'center',
  },
  backToCourseText: {
    ...Fonts.small,
  },
  backButton: {
    flexDirection: 'row',
    paddingHorizontal: Sizes.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanded: {
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Sizes.normal,
  },
  questionCountContainer: {
    flexDirection: 'row',
    columnGap: 5,
    marginHorizontal: Sizes.normal,
    alignItems: 'center',
  },
  questionCountTitle: {
    ...Fonts.normalize(Fonts.smallBold),
    color: Colors.white60,
  },
});
