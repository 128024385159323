import { Sizes } from '../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../assets';

export const TUTOR_BUBBLES = [
  {
    id: '1',
    asset: OnboardingAssets.avatars.AlbertEinstein,
    style: {
      left: Sizes.normalizeIP14PM(10),
      top: Sizes.normalizeIP14PM(200),
      transform: [{ rotate: '-12.95deg' }],
    },
    size: Sizes.normalizeIP14PM(70),
  },
  {
    id: '2',
    asset: OnboardingAssets.avatars.AbrahamLincoln,
    style: {
      left: Sizes.normalizeIP14PM(45),
      top: Sizes.normalizeIP14PM(340),
      transform: [{ rotate: '-7.57deg' }],
    },
    size: Sizes.normalizeIP14PM(50),
  },
  {
    id: '3',
    asset: OnboardingAssets.avatars.MarieCurie,
    style: {
      left: Sizes.normalizeIP14PM(5),
      bottom: Sizes.normalizeIP14PM(160),
      transform: [{ rotate: '11.07deg' }],
    },
    size: Sizes.normalizeIP14PM(70),
  },
  {
    id: '4',
    asset: OnboardingAssets.avatars.Cleopatra,
    style: {
      right: Sizes.normalizeIP14PM(50),
      top: Sizes.normalizeIP14PM(160),
      transform: [{ rotate: '13.07deg' }],
    },
    size: Sizes.normalizeIP14PM(70),
  },
  {
    id: '5',
    asset: OnboardingAssets.avatars.MartinLutherKing,
    style: {
      right: Sizes.normalizeIP14PM(45),
      bottom: Sizes.normalizeIP14PM(270),
      transform: [{ rotate: '9.09deg' }],
    },
    size: Sizes.normalizeIP14PM(65),
  },
  {
    id: '6',
    asset: OnboardingAssets.avatars.WilliamShakespeare,
    style: {
      right: Sizes.normalizeIP14PM(20),
      bottom: Sizes.normalizeIP14PM(120),
      transform: [{ rotate: '13.07deg' }],
    },
    size: Sizes.normalizeIP14PM(70),
  },
];
