import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from 'react-native-iap';
import { PersistConfig, persistReducer } from 'redux-persist';

import { Course, PaywallEntryPoint } from '../../../Common/entities';

import {
  fetchProductsDataExtraReducers,
  purchaseProductExtraReducers,
} from './PurchaseSliceActions';

export const PURCHASE_SLICE_NAME = 'PurchasesSlice';

export type PurchasesSliceState = {
  listenersInitialized: boolean;
  productsData: Record<string, Product>;
  currentCoursePaywall?: Course;
  isLoading: boolean;
  highSchoolCourseEntryPoint?: PaywallEntryPoint;
};

const persistConfig = {
  key: PURCHASE_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: [
    'currentCoursePaywall',
    'productsData',
    'highSchoolCourseEntryPoint',
  ],
} as PersistConfig<PurchasesSliceState>;

const initialState: PurchasesSliceState = {
  listenersInitialized: false,
  productsData: {},
  isLoading: false,
  highSchoolCourseEntryPoint: undefined,
};

const slice = createSlice({
  name: PURCHASE_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetHighSchoolCoursePurchaseData: state => {
      state.highSchoolCourseEntryPoint = undefined;
      state.currentCoursePaywall = undefined;
    },
    setListenersInitialized: (state, action: PayloadAction<boolean>) => {
      state.listenersInitialized = action.payload;
    },
    setCurrentCoursePaywall: (
      state,
      action: { payload: { course: Course } },
    ) => {
      state.currentCoursePaywall = action.payload.course;
    },
    setHighSchoolCourseEntryPoint: (
      state,
      action: PayloadAction<PaywallEntryPoint>,
    ) => {
      state.highSchoolCourseEntryPoint = action.payload;
    },
  },
  extraReducers: builder => {
    fetchProductsDataExtraReducers(builder);
    purchaseProductExtraReducers(builder);
  },
});

export const {
  setCurrentCoursePaywall,
  setListenersInitialized,
  setHighSchoolCourseEntryPoint,
  resetHighSchoolCoursePurchaseData,
} = slice.actions;

export default persistReducer(persistConfig, slice.reducer);
