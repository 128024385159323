import React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import { isWebPlatform } from '../../Common/services/utils/AppConstants';

import { Asset } from './index';

type Props = {
  asset: Asset;
  width?: number;
  height?: number;
  fill?: string;
  fillOpacity?: number;
  stroke?: string;
  containerStyle?: StyleProp<ViewStyle>;
  imageStyle?: ImageStyle | React.CSSProperties;
};

const Icon = ({ children }: any) => {
  if (isWebPlatform) {
    return <img src={children.type} {...children.props} />;
  }
  return children;
};

export const AssetImage = ({
  asset,
  width,
  height,
  fill = 'none',
  fillOpacity,
  stroke,
  containerStyle,
  imageStyle,
}: Props): React.ReactElement => {
  switch (asset.type) {
    case 'vector':
      return (
        <View style={[styles.container, containerStyle]}>
          <Icon>
            <asset.path
              width={width ?? height}
              height={height ?? width}
              fill={fill}
              fillOpacity={fillOpacity}
              stroke={stroke}
              style={imageStyle as React.CSSProperties}
            />
          </Icon>
        </View>
      );

    case 'bitmap':
      return (
        <View style={[styles.container, containerStyle]}>
          <Image
            style={[styles.imageStyle, imageStyle] as ImageStyle}
            source={asset.path as ImageSourcePropType}
          />
        </View>
      );
  }
  // }
  return <></>;
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: Colors.GREEN,
  },
  imageStyle: {
    height: '100%',
    resizeMode: 'contain',
    width: '100%',
  },
});
