import moment from 'moment/moment';

import { CourseGoalKeys } from '../../../Common/entities';
import { DEFAULT_EARLIEST_EXAM_DATE } from '../../data';
import { GetIsQualifiedCourseForApResultsProps } from '../../entities';

export const getIsCourseQualifiedForApResults = (
  props: GetIsQualifiedCourseForApResultsProps,
): boolean => {
  if (!props?.course || !props?.courseExamDate || !props?.courseEnrolledOn) {
    return false;
  }

  const courseExamDate = props.courseExamDate;
  const previousExamDate = props.previousExamDate;

  const enrolledOnMoment = moment(props.courseEnrolledOn);
  const courseExamMoment = moment(courseExamDate, 'YYYY-MM-DD');
  const previousExamMoment = moment(
    previousExamDate || DEFAULT_EARLIEST_EXAM_DATE,
    'YYYY-MM-DD',
  );

  const differenceWithCurrentCourseExam = enrolledOnMoment.diff(
    courseExamMoment,
    'days',
  );
  const isEnrolledOnBeforeCourseExam = differenceWithCurrentCourseExam < 0;

  const differenceWithPreviousCourseExam = enrolledOnMoment.diff(
    previousExamMoment,
    'days',
  );
  const isEnrolledOnAfterPreviousCourseExam =
    differenceWithPreviousCourseExam > 0;

  return (
    isEnrolledOnAfterPreviousCourseExam &&
    isEnrolledOnBeforeCourseExam &&
    props.goalKey === CourseGoalKeys.CRUSH_AP_TEST
  );
};
