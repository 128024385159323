import React, { useCallback, useEffect, useRef } from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import BoxedTextContainer from '../../../../Common/components/common/BoxedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import ContentPointsView from '../../../../Common/components/home/ContentPointsView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { VideoFrameView } from '../../../../Common/components/home/VideoFrameView';
import { TruthOrLieCard } from '../../../../Common/entities';
import {
  useCardHintVideoVisibility,
  useCardState,
  useCardTimeRecorder,
  useTimedToggle,
} from '../../../../Common/services/hooks';
import {
  getQuestionStyle,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Durations,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { useFetchRaiseHandTutor } from '../../../../RaiseHand/services/hooks';
import HintButton from '../../../components/contents/HintButton';
import Animations from '../../../components/courses/CoinsAnimation';
import {
  getCardAttributes,
  getQuestionPoints,
  setExplanationShown,
} from '../../../services/slices';

import BaseContentCard from './BaseContentCard';
import { Layout } from './MCQItemView';
import homeStyles from './styles';
import TruthOrLieOptionView from './TruthOrLieOptionView';

type Props = {
  item: TruthOrLieCard;
  isVisible: boolean;
  showCoinsAnimation: boolean;
};

let questionWidthVar: number | null = null;

export const SPEAKER_TEXT_OFFSET = 90;

export default ({
  item,
  isVisible,
  showCoinsAnimation,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [showVideo, setShowVideo] = useCardHintVideoVisibility(item);
  const cardTimeRecorder = useCardTimeRecorder(item);
  const [blink, setBlink, blinkControls] = useTimedToggle({
    toggleDuration: Durations.blinkDelay,
  });
  const preventBlinkRef = useRef(showVideo);

  const points = useAppSelector(
    state => getQuestionPoints(state.answer, item.uniqueId),
    (prev, next) => prev === next,
  );

  const [animateTryMe, setAnimateTryMe] = useCardState(item, false);

  const [isAnswerCorrect, setIsAnswerCorrect] = useCardState(item, false);
  const [isAnswered, setIsAnswered] = useCardState(item, false);
  const [contentPointLayout, setContentPointLayout] = useCardState<Layout>(
    item,
    { x: 0, y: 0, width: 0, height: 0 },
  );
  const [parentY, setParentY] = useCardState(item, 0);

  const { raiseHandTutor } = useFetchRaiseHandTutor(item.character.name);

  const handleStopBlink = useCallback(() => {
    blinkControls.cancel();
    setBlink(false);
    preventBlinkRef.current = true;
  }, [blinkControls, setBlink]);

  useEffect(() => {
    if (showVideo) {
      dispatch(
        setExplanationShown({
          item,
        }),
      );

      handleStopBlink();
    }
  }, [showVideo, dispatch, item, handleStopBlink]);

  useEffect(() => {
    if (!preventBlinkRef.current && !blinkControls.ref.current) {
      if (!isVisible) {
        blinkControls.cancel();
        setBlink(false);
      } else {
        blinkControls.trigger();
      }
    }
  }, [isVisible, setBlink, blinkControls]);

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const onLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;

    questionWidthVar = width;
  };

  const recordFinishedReadingExplanation = (answeredQuestion = false) => {
    if (showVideo || answeredQuestion) {
      cardTimeRecorder.recordFinishedReadingExplanation();
    }
  };

  const onHintPress = () => {
    handleStopBlink();

    if (isWebPlatform) {
      if (!showVideo) {
        setShowVideo(true);
      } else {
        redirectToAppstore();
      }
    } else {
      cardTimeRecorder.recordHelpShown();
      recordFinishedReadingExplanation();
      setShowVideo(!showVideo);
    }
  };

  useEffect(() => {
    if (visibilityStyle === 'no-text') {
      setShowVideo(false);
    }
  }, [setShowVideo, visibilityStyle]);

  const renderQuestionText = () => {
    const textStyling = getQuestionStyle(item.generatedContentId);
    return (
      <TouchableOpacity activeOpacity={1}>
        <BoxedTextContainer
          style={styles.wrappedTextContainer}
          textStyle={{
            ...(showVideo ? styles.questionTextSmall : homeStyles.questionText),
            color: textStyling.textColor,
          }}
          text={item.statement}
          width={
            showVideo
              ? questionWidthVar! - SPEAKER_TEXT_OFFSET
              : questionWidthVar!
          }
          outlineColor={textStyling.outlinedColor}
          color={textStyling.backgroundColor}
        />
      </TouchableOpacity>
    );
  };

  const onAnswered = (isCorrect: boolean) => {
    !animateTryMe && setAnimateTryMe(!isCorrect);
    setIsAnswerCorrect(isCorrect);
    setIsAnswered(true);
    recordFinishedReadingExplanation(true);
    handleStopBlink();
    setShowVideo(true);
    setTimeout(() => {
      setIsAnswered(false);
    }, 3000);
  };

  const handleContentPointLayoutChange = (layout: Layout) => {
    setContentPointLayout(layout);
  };

  const onCardTap = () => {
    if (isWebPlatform) {
      onHintPress();
      return true;
    }
    return false;
  };

  const handleRaiseHandPress = () => {
    setAnimateTryMe(false);
  };

  return (
    <BaseContentCard item={item} onCardTap={onCardTap}>
      <View style={styles.contentContainer}>
        <View
          style={styles.textContainer}
          onLayout={!questionWidthVar ? onLayout : undefined}>
          {visibilityStyle !== 'no-text' && (
            <View
              style={homeStyles.contentTypeContainer}
              onLayout={event => {
                const { y } = event.nativeEvent.layout;
                setParentY(y);
              }}>
              <ContentTypeView
                contentType={item.type}
                typeName={item.typeName}
              />
              {points !== undefined && (
                <ContentPointsView
                  points={points}
                  isPositive={isAnswerCorrect}
                  isAnswered={isAnswered}
                  onLayoutChange={handleContentPointLayoutChange}
                  parentY={parentY}
                />
              )}
            </View>
          )}
          {renderQuestionText()}
          {visibilityStyle !== 'no-text' && (
            <HintButton onHintPress={onHintPress} blinkHighlight={blink} />
          )}
        </View>
        <View style={styles.expanded}>
          <TruthOrLieOptionView
            item={item}
            onAnswered={onAnswered}
            showStats={showVideo && !isWebPlatform}
          />
        </View>
      </View>
      <ActionButtonsView
        item={item}
        forceMutable
        animateHand={animateTryMe}
        handleRaiseHand={handleRaiseHandPress}
        speakerCharacter={raiseHandTutor}
      />
      {visibilityStyle !== 'no-text' && (
        <VideoFrameView
          card={item}
          videoUrl={item.character.videoUrl}
          subtitleUrl={item.character.subtitleUrl}
          isVisible={isVisible}
          name={item.character.name}
          title={item.character.title}
          showVideo={showVideo}
          shouldRestart={!showVideo}
          hideVideo={() => setShowVideo(false)}
        />
      )}
      {showCoinsAnimation && contentPointLayout && isAnswerCorrect && (
        <Animations startPosition={contentPointLayout} />
      )}
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
  textContainer: {
    flexGrow: 20,
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  wrappedTextContainer: {
    marginEnd: 0,
    opacity: 0.9,
  },
  questionTextSmall: {
    ...Fonts.normalize(Fonts.large),
  },
});
