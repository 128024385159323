import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { useAiFigureCharacterNavigator } from '../../AiFigure/services/hooks';
import Assets, { Asset } from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import Avatar from '../../Common/components/common/Avatar';
import { ContentCard, DmsFromDeadCard } from '../../Common/entities';
import {
  getQuestionShareOptions,
  HapticUtil,
  redirectToAppstore,
} from '../../Common/services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { Share } from '../../Common/services/utils/polyfills';
import {
  bookmarkContent,
  likeContent,
  shareContent,
} from '../../Feedback/services/slices';
import { getCardAttributes } from '../../Learn/services/slices';
import {
  setCardFeedbackPopup,
  setCommentsPopup,
} from '../../Popup/services/slices';

type SocialButtonProps = {
  icon: Asset;
  text?: string;
  width?: number;
  onPress?: () => void;
  isSelected?: boolean;
  selectedColor?: string;
};

const SocialButton = ({
  icon,
  text,
  onPress,
  isSelected = false,
  selectedColor,
  width = Sizes.normalizeIP14PM(35),
}: SocialButtonProps) => (
  <TouchableOpacity style={styles.socialButtonContainer} onPress={onPress}>
    <AssetImage
      asset={icon}
      height={width}
      fill={isSelected ? selectedColor : 'white'}
      imageStyle={styles.icon}
    />
    {text && <Text style={styles.socialButtonText}>{text}</Text>}
  </TouchableOpacity>
);

type Props = {
  item: DmsFromDeadCard;
  onMediaMute: () => void;
  mediaMuted: boolean;
};

export const SpeakerActionButtonsView = (props: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const handleCharacterPress = useAiFigureCharacterNavigator();

  const isBookmarked = useAppSelector(
    state => getCardAttributes(state.questions, props.item).isContentBookmarked,
  );

  const isLiked = useAppSelector(
    state => getCardAttributes(state.questions, props.item).isContentLiked,
  );

  const isDisliked = useAppSelector(
    state => state.feedback.dislikes[props.item.generatedContentId],
  );

  const comments = useAppSelector(
    state => state.comments.comments[props.item.generatedContentId] ?? [],
  );

  const totalLikes = props.item.totalLikes;
  const totalComments = props.item.totalComments;
  const totalBookmarks = props.item.totalBookmarks;
  const totalShares = props.item.totalShares;

  const onShare = async (): Promise<void> => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    try {
      await Share.open(getQuestionShareOptions(props.item as ContentCard));
      dispatch(
        shareContent({
          content: props.item,
          isFromDM: true,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onPressBookmark = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    if (!isBookmarked) {
      HapticUtil.triggerLight();
    }

    dispatch(
      bookmarkContent({
        content: props.item,
        value: !isBookmarked,
        isFromDM: true,
      }),
    );
  };

  const onPressDislike = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    HapticUtil.triggerMedium();
    dispatch(
      setCardFeedbackPopup({
        item: props.item,
        isFromDM: true,
      }),
    );
  };

  const onPressLike = async (): Promise<void> => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    if (!isLiked) {
      HapticUtil.triggerLight();
    }

    dispatch(
      likeContent({
        content: props.item,
        value: !isLiked,
        isFromDM: true,
      }),
    );
  };

  const onPressComment = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    dispatch(
      setCommentsPopup({
        item: props.item,
        isFromDM: true,
      }),
    );
  };

  return (
    <View style={styles.container}>
      <View style={styles.avatarMargin}>
        <TouchableOpacity
          onPress={() => handleCharacterPress(props.item.character.name)}>
          <Avatar
            name={props.item.character.name}
            image={props.item.character.avatarUrl}
            size={Sizes.normalizeIP14PM(50)}
          />
        </TouchableOpacity>
      </View>
      <SocialButton
        icon={
          props.mediaMuted
            ? Assets.home.volumeOffButtonIcon
            : Assets.home.volumeOnButtonIcon
        }
        width={Sizes.normalizeIP14PM(30)}
        onPress={props.onMediaMute}
      />
      <SocialButton
        icon={Assets.home.likeIcon}
        isSelected={isLiked}
        selectedColor={Colors.additional.red[100]}
        onPress={onPressLike}
        text={`${totalLikes}`}
      />
      {!isWebPlatform && (
        <SocialButton
          icon={Assets.home.commentsIcon}
          onPress={onPressComment}
          text={`${comments.length > 0 ? comments.length : totalComments}`}
        />
      )}
      <SocialButton
        icon={Assets.home.bookmarkIcon}
        selectedColor={Colors.actionGreen}
        isSelected={isBookmarked}
        onPress={onPressBookmark}
        text={`${totalBookmarks}`}
      />
      <SocialButton
        icon={Assets.home.shareIcon}
        onPress={onShare}
        text={`${totalShares}`}
      />
      <SocialButton
        width={Sizes.normalizeIP14PM(24)}
        icon={Assets.home.thumbDownFilledIcon}
        selectedColor={Colors.answerWrong}
        isSelected={isDisliked}
        onPress={onPressDislike}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  avatarMargin: {
    marginBottom: 20,
    justifyContent: 'center',
    alignSelf: 'center',
    rowGap: 2,
  },
  avatarPlusIcon: {
    bottom: -10,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginEnd: 6,
    marginStart: Sizes.normalizeIP14PM(12),
    maxWidth: 50,
  },
  icon: {
    shadowColor: 'rgba(0, 0, 0, 1)',
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  socialButtonContainer: {
    alignItems: 'center',
    rowGap: 2,
    paddingBottom: 18,
  },
  socialButtonText: {
    ...Fonts.smallBold,
    fontSize: Fonts.smallBold.fontSize - 1,
    marginTop: 0,
  },
});
