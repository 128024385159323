import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { CreateCommentRequest } from '../../entities';

export const createCommentGraphQLCall = async (
  request: CreateCommentRequest,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        generatedContentId: request.generatedContentId,
        comment: encodeURIComponent(request.comment),
        parentCommentId: request.parentCommentId,
      },
      query: `
        mutation createComment($generatedContentId: ID!, $comment: String!, $parentCommentId: ID) {
          createGeneratedContentComment(input:
            { generatedContentId: $generatedContentId, parentCommentId: $parentCommentId,
              comment: $comment }) {
            commentId
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const deleteCommentGraphQLCall = async (
  commentId: string,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        commentId,
      },
      query: `
      mutation deleteComment(
        $commentId: ID!
      ) {
        deleteGeneratedContentComment(input: {commentId: $commentId}) {
          commentId
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};
