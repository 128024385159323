import { createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';
import { PurchaseError } from 'react-native-iap';

import locale from '../../../../App/locale';
import { handlePaywallErrorSilently } from '../../../../App/services/utils';
import { PurchaseBootcampParams } from '../../../entities/PaywallPurchasesActionTypes';
import { PURCHASABLE_ITEMS } from '../../utils/PaywallUtils';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

import { purchaseProduct } from './PurchaseProductAction';

export const purchaseBootcamp = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/purchaseBootcamp`,
  async (params: PurchaseBootcampParams, thunkApi) => {
    try {
      await thunkApi
        .dispatch(purchaseProduct(PURCHASABLE_ITEMS[params.item]))
        .unwrap();
    } catch (e) {
      const error = e as PurchaseError;
      if (error.code !== 'E_USER_CANCELLED') {
        showMessage({
          message: locale.errors.purchase_error,
          type: 'danger',
        });
      } else {
        handlePaywallErrorSilently(e as Error);
      }
      return thunkApi.rejectWithValue(e);
    }
  },
);
