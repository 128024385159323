import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer, REHYDRATE } from 'redux-persist';

const persistConfig = {
  key: 'timer',
  storage: AsyncStorage,
};

type State = {
  seconds: number;
  date: string;
};

const initialState: State = {
  seconds: 0,
  date: new Date().toDateString(),
};

const timerSlice = createSlice({
  name: 'TimerSlice',
  initialState: initialState,
  reducers: {
    addSeconds: (state: State, action) => {
      if (state.date !== new Date().toDateString()) {
        state.seconds = 0;
        state.date = new Date().toDateString();
      }
      state.seconds += action.payload;
    },
  },
  extraReducers: builder => {
    // reset timer on rehydrate if date is different
    builder.addCase(REHYDRATE, (state, action: any) => {
      if (action.payload) {
        const payload = action.payload as State;
        if (payload.date !== new Date().toDateString()) {
          payload.seconds = 0;
          payload.date = new Date().toDateString();
        }
      }
    });
  },
});

export const { addSeconds } = timerSlice.actions;

export const TimerSlice = persistReducer(persistConfig, timerSlice.reducer);
