import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  CurriculumResponse,
  GetLearningAppProgressRequest,
  GetLearningAppProgressResponse,
  GetMaxGradeRequest,
  GetMaxGradeResponse,
} from '../../entities';

export const getCurriculumGraphQLCall =
  async (): Promise<CurriculumResponse> => {
    const authHeader = (await getAuthHeader()) as any;
    if (Object.keys(authHeader).length > 0) {
      authHeader['x-api-key'] = undefined;
    }
    const response = await graphqlApi.post(
      '',
      {
        query: `
        query TeachTapGetCurriculum {
        teachTapGetCurriculum {
          subjects {
            id
            name
            image
            courses {
              id
              name
              grade {
                grade
                name
              }
              mainImageUrl
              thumbnailUrl
              learningOrder
              hasTestPrep
              hasLearnMode
              units {
                id
                name
                learningOrder
                status
                hasTestPrep
                hasLearnMode
                category
                topics {
                  id
                  learningOrder
                  name
                  estimatedTimeToCompletion {
                    min
                    max
                  }
                }
              }
            }
          }
        }
      }`,
        variables: {},
      },
      {
        headers: {
          ...authHeader,
        },
      },
    );

    handleGraphQLError(response);

    return response.data.data?.teachTapGetCurriculum;
  };

export const getLearningAppProgressGraphQLCall = async (
  request: GetLearningAppProgressRequest,
): Promise<GetLearningAppProgressResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetUserProgress($input: TeachTapGetUserProgressInput!) {
          teachTapGetUserProgress(input: $input) {
            course {
              id
              currentUnitId
              units {
                id
                studentGrade
                currentTopicId
                topics {
                  id
                  studentGrade
                  nextGradeProgress
                }
              }
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetUserProgress;
};

export const getMaxGradeGraphQLCall = async (
  request: GetMaxGradeRequest,
): Promise<GetMaxGradeResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        query TeachTapGetMaxGrade($input: TeachTapGetMaxGradeInput!) {
          teachTapGetMaxGrade(input: $input) {
            courseId
            topics {
              id
              maxGrade
            }
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetMaxGrade;
};
