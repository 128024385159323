import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

import Assets, { Asset } from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';

type ImageComponentProps = {
  height: number;
  width?: number;
  asset: Asset;
  alignStyle: StyleProp<ViewStyle>;
};

const ImageComponent: React.FC<ImageComponentProps> = ({
  height,
  width,
  asset,
  alignStyle,
}) => (
  <AssetImage
    width={width ?? height}
    height={height}
    asset={asset}
    containerStyle={alignStyle}
  />
);

export const DmsFromTheDeadHeader: React.FC = () => {
  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <ImageComponent
          height={72}
          asset={Assets.message.abraham}
          alignStyle={styles.alignCenter}
        />
        <ImageComponent
          height={44}
          asset={Assets.message.issacNewton}
          alignStyle={styles.alignLeft}
        />
        <ImageComponent
          height={32}
          asset={Assets.message.elvisPresley}
          alignStyle={styles.alignRight}
        />
        <ImageComponent
          height={56}
          asset={Assets.message.mahatmaGandhi}
          alignStyle={styles.alignCenter}
        />
      </View>
      <View style={styles.centerContainer}>
        <ImageComponent
          height={44}
          asset={Assets.message.aristotle}
          alignStyle={styles.alignCenter}
        />
        <ImageComponent
          width={75}
          height={60}
          asset={Assets.message.skullCommentIcon}
          alignStyle={styles.alignCenter}
        />
        <ImageComponent
          height={36}
          asset={Assets.message.theodoreRoosevelt}
          alignStyle={styles.alignCenter}
        />
      </View>
      <View style={styles.rightContainer}>
        <ImageComponent
          height={56}
          asset={Assets.message.albertEinsten}
          alignStyle={styles.alignCenter}
        />
        <ImageComponent
          height={36}
          asset={Assets.message.winstonChurchill}
          alignStyle={styles.alignLeft}
        />
        <ImageComponent
          height={56}
          asset={Assets.message.martinLuther}
          alignStyle={styles.alignRight}
        />
        <ImageComponent
          height={44}
          asset={Assets.message.georgeWashington}
          alignStyle={styles.alignCenter}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
  leftContainer: {
    flex: 0.4,
    justifyContent: 'space-around',
    paddingVertical: 5,
  },
  centerContainer: {
    flex: 0.4,
    justifyContent: 'space-evenly',
    paddingVertical: 5,
  },
  rightContainer: {
    flex: 0.4,
    justifyContent: 'space-around',
    paddingVertical: 5,
  },
  alignLeft: {
    alignSelf: 'flex-start',
  },
  alignCenter: {
    alignSelf: 'center',
  },
  alignRight: {
    alignSelf: 'flex-end',
  },
});
