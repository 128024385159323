import { useCallback, useMemo } from 'react';

import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { useBottomTabNavigator } from '../../../Common/services/hooks';
import { BootcampEntryPoint } from '../../entities';
import { mapIsBootcampFromCoursePickerStatus } from '../mappers';
import {
  closeOnboardingScreens,
  resetExamInputData,
  setShowEntitlementScreen,
} from '../slices';

type ReturnType = {
  bootcampEntryPoint?: BootcampEntryPoint;
  handleCloseButtonPress: () => void;
  isBootcampFromCoursePicker: boolean;
  handleResetBootcampCoursePickerFlow: () => void;
};

export const useBootcampCoursePicker = (): ReturnType => {
  const dispatch = useAppDispatch();
  const bottomTabNavigator = useBottomTabNavigator();

  const bootcampEntryPoint = useAppSelector(
    state => state.onboarding.bootcampEntryPoint,
    (prev, next) => prev === next,
  );

  const isBootcampFromCoursePicker = useMemo(
    () => mapIsBootcampFromCoursePickerStatus(bootcampEntryPoint),
    [bootcampEntryPoint],
  );

  const handleResetBootcampCoursePickerFlow = useCallback(() => {
    dispatch(setShowEntitlementScreen(false));
    dispatch(resetExamInputData());
  }, [dispatch]);

  const handleCloseButtonPress = useCallback(() => {
    handleResetBootcampCoursePickerFlow();
    const result = dispatch(closeOnboardingScreens()).unwrap();

    if (!result) {
      bottomTabNavigator.navigate();
    }
  }, [handleResetBootcampCoursePickerFlow, dispatch, bottomTabNavigator]);

  return {
    bootcampEntryPoint,
    handleCloseButtonPress,
    isBootcampFromCoursePicker,
    handleResetBootcampCoursePickerFlow,
  };
};
