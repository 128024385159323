import React from 'react';
import {
  ImageSourcePropType,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import { CommonCloseButton, CommonGifAnimation } from '../../Common/components';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import ProgressBar from '../components/loading-screen/ProgressBar';

interface OnboardingLoadingScreenProps {
  heading: string;
  gifSource: ImageSourcePropType;
  onLoadingComplete: () => void;
  hints?: string[];
  onCloseButtonPress?: () => void;
  showCloseButton?: boolean;
}

export const OnboardingLoadingScreen = ({
  heading,
  gifSource,
  hints,
  onLoadingComplete,
  onCloseButtonPress,
  showCloseButton = false,
}: OnboardingLoadingScreenProps): React.ReactElement => {
  return (
    <View style={styles.container}>
      <SafeAreaView>
        <View style={styles.contentContainer}>
          <Text style={styles.headingText}>{heading}</Text>
          <CommonGifAnimation
            source={gifSource}
            containerStyle={styles.gifContainer}
            style={styles.gif}
          />
          <View style={styles.progressBarContainer}>
            <ProgressBar onComplete={onLoadingComplete} />
            {hints && (
              <View style={styles.hintContainer}>
                {hints.map(hint => (
                  <Text key={hint} style={styles.hintText}>
                    {hint}
                  </Text>
                ))}
              </View>
            )}
          </View>
        </View>

        {showCloseButton && onCloseButtonPress && (
          <CommonCloseButton
            onPress={onCloseButtonPress}
            size={Sizes.medium}
            mainContainerStyle={styles.closeButtonContainer}
          />
        )}
      </SafeAreaView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.prussianBlue,
  },
  contentContainer: {
    padding: Sizes.medium,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.large,
  },
  headingText: {
    ...Fonts.xxxlargeBold,
    paddingHorizontal: Sizes.small,
    color: 'white',
    textAlign: 'center',
  },
  gifContainer: {
    width: Sizes.normalizeIP14PM(200),
    height: Sizes.normalizeIP14PM(138),
  },
  gif: {
    transform: [{ scale: 1.5 }],
  },
  progressBarContainer: {
    alignItems: 'stretch',
    width: '100%',
    gap: Sizes.small,
  },
  hintContainer: {
    alignItems: 'center',
  },
  hintText: {
    ...Fonts.small,
    textAlign: 'center',
  },
  closeButtonContainer: {
    right: Sizes.medium,
  },
});
