import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import { TestPrepQuestionDuration, TestType } from '../../entities';

export const getTestDurationMultiplier = (
  testType?: TestType,
  unitCategory?: string | null,
): number => {
  if (testType === TestType.SAT_TEST) {
    return unitCategory === SAT_UNIT_CATEGORY.MATH
      ? TestPrepQuestionDuration.SAT_MATH
      : TestPrepQuestionDuration.SAT_READING;
  }

  return TestPrepQuestionDuration.DEFAULT;
};

export const roundToNearestTenth = (score: number): number => {
  return Math.round(score / 10) * 10;
};
