import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import CommonCircularProgress from '../../Common/components/common/CircularProgress';
import { CommonPeriodType } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { mapApProgramCourseDetailsById } from '../services/mappers';

type Props = {
  courseId?: string;
};

export const APProgramCommitment = ({
  courseId,
}: Props): React.ReactElement | null => {
  const courseProgram = useAppSelector(state =>
    mapApProgramCourseDetailsById(state, courseId),
  );

  const label = useMemo(() => {
    if (!courseProgram?.targetTimeCommitmentPeriod) {
      return '';
    }

    const selectedCommitmentWord =
      courseProgram?.targetTimeCommitmentPeriod === CommonPeriodType.WEEK
        ? locale.sat.weekly
        : locale.sat.daily;

    return locale.sat.commitment_label.replace(
      '${PERIOD}',
      selectedCommitmentWord,
    );
  }, [courseProgram]);

  const committedHours = useMemo(() => {
    if (!courseProgram?.timeSpentMinutes) {
      return '0';
    }

    const hours = courseProgram?.timeSpentMinutes / 60;

    // Floor the hours to 1 decimal place, because this is a "committed" time
    return (Math.floor(hours * 10) / 10).toString();
  }, [courseProgram?.timeSpentMinutes]);

  const commitmentTargetHours = useMemo(() => {
    return locale.sat.commitment_label_hours.replace(
      '${COMMITMENT}',
      `${courseProgram?.targetTimeCommitmentHours || 'N'}`,
    );
  }, [courseProgram?.targetTimeCommitmentHours]);

  const commitmentPercentage = useMemo(() => {
    if (!courseProgram?.targetTimeCommitmentHours) {
      return 0;
    }

    const timeSpentInHours = (courseProgram?.timeSpentMinutes || 0) / 60;

    const percentage =
      (timeSpentInHours / courseProgram?.targetTimeCommitmentHours) * 100;

    return Math.min(100, Math.max(0, percentage));
  }, [
    courseProgram?.timeSpentMinutes,
    courseProgram?.targetTimeCommitmentHours,
  ]);

  if (!courseProgram?.targetGrade) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>

      <View style={styles.commitmentContainer}>
        <CommonCircularProgress
          radius={10}
          color="white"
          strokeWidth={5}
          percentage={commitmentPercentage}
          strokeOpacity="100%"
          hideText
        />

        <View style={styles.commitmentTextContainer}>
          <Text style={styles.commitmentHours}>{committedHours}</Text>
          <Text style={styles.commitmentTargetHours}>
            {commitmentTargetHours}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: Sizes.medium,
    paddingVertical: Sizes.small,
    backgroundColor: Colors.lightBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  label: {
    ...Fonts.small,
    color: Colors.white90,
  },

  commitmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    marginLeft: Sizes.small,
  },

  commitmentTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  commitmentHours: {
    ...Fonts.mediumBold,
    color: 'white',
  },
  commitmentTargetHours: {
    ...Fonts.small,
    color: 'white',
  },
});
