import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';

type Props = {
  courseName?: string;
};

export const ApProgramUpsellOverlayHeader = (
  props: Props,
): React.ReactElement => {
  const managedText = useMemo(() => {
    return {
      title: locale.onboarding.ap_upsell_overlay_title.replace(
        '{COURSE_NAME}',
        props.courseName || '',
      ),
      description: locale.onboarding.ap_upsell_overlay_description.replace(
        '{COURSE_NAME}',
        props.courseName || '',
      ),
    };
  }, [props]);

  return (
    <View style={styles.headerContainer}>
      <View style={styles.lockIconContainer}>
        <AssetImage width={20} asset={OnboardingAssets.LockIcon} />
      </View>

      <Text style={styles.titleText}>{managedText.title}</Text>
      <Text style={styles.descriptionText}>{managedText.description}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    gap: Sizes.small,
  },

  lockIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  titleText: {
    ...Fonts.semixlLargeBold,
    textAlign: 'center',
  },
  descriptionText: {
    ...Fonts.caption,
    color: Colors.white60,
    textAlign: 'center',
  },
});
