import React, { useMemo } from 'react';
import { StyleSheet, ViewStyle, View, Text } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { OnboardingAssets } from '../../Onboarding/assets';
import { useLinearProgressUIHandler } from '../services/hooks';
import {
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../services/utils/AppConstants';
import {
  Animated,
  useAnimatedStyle,
  Easing,
  withTiming,
  EasingFunction,
  EasingFunctionFactory,
} from '../services/utils/polyfills';

type Props = {
  backgroundStyle?: ViewStyle;
  style?: ViewStyle;

  // 0 to 100
  progressPercentage: number;
  easing?: EasingFunction | EasingFunctionFactory;

  // 0 to 100
  targetPercentage?: number;
  target?: number;
};

export const CommonLinearProgress = (props: Props): React.ReactElement => {
  const percent = useMemo(
    () => Math.min(100, Math.max(0, props.progressPercentage || 0)),
    [props.progressPercentage],
  );

  const shouldShowTarget = useMemo(() => {
    return props.targetPercentage !== undefined && props.targetPercentage >= 0;
  }, [props.targetPercentage]);

  const targetPercent = useMemo(() => {
    return Math.min(100, Math.max(0, props.targetPercentage || 0));
  }, [props.targetPercentage]);

  const wrapperStyle = useMemo(() => {
    if (!shouldShowTarget) {
      return styles.wrapper;
    }

    return [styles.wrapper, { paddingTop: Sizes.small, paddingBottom: 28 }];
  }, [shouldShowTarget]);

  const progressStyle = useAnimatedStyle(() => {
    return {
      width: withTiming(`${percent}%`, {
        easing: props.easing ?? Easing.inOut(Easing.quad),
        duration: Durations.quickTransition,
      }),
    };
  }, [percent, props.easing]);

  const {
    progressRef,
    targetContainerStyle,
    handleTargetLineLayout,
    handleTargetLayout,
  } = useLinearProgressUIHandler({ targetPercentage: targetPercent });

  return (
    <View style={wrapperStyle}>
      <View style={styles.mainContainer}>
        <View
          ref={progressRef as React.MutableRefObject<View>}
          style={[styles.progrssContainer, props.backgroundStyle]}>
          <Animated.View
            style={[styles.progress, props.style, progressStyle]}
          />
        </View>

        {shouldShowTarget && (
          <>
            <View
              style={[styles.targetLine, { left: `${targetPercent}%` }]}
              onLayout={handleTargetLineLayout}
            />

            <View
              onLayout={handleTargetLayout}
              style={[styles.targetContainer, targetContainerStyle]}>
              <AssetImage
                asset={OnboardingAssets.SATExamTargetScoreIcon}
                height={Sizes.semiMedium}
              />
              <Text style={styles.targetText}>{props.target}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    overflow: 'visible',
  },
  progrssContainer: {
    width: '100%',
    backgroundColor: Colors.white12,
    height: Sizes.small,
    borderRadius: Sizes.small,
    overflow: 'hidden',
  },
  progress: {
    backgroundColor: Colors.emeraldGreen,
    borderRadius: Sizes.small,
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },

  targetLine: {
    width: 1,
    height: 20,
    backgroundColor: Colors.mediumGray2,
    position: 'absolute',
    top: -5,
  },

  targetContainer: {
    position: 'absolute',
    top: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: Sizes.small,
    paddingHorizontal: 6,
    gap: Sizes.xsmall,
    paddingVertical: Sizes.xsmall,
    backgroundColor: Colors.emeraldGreen20,
    transform: [{ translateY: 5 }, { translateX: -30 }],
  },

  targetText: {
    ...Fonts.smallBold,
    color: Colors.emeraldGreen,
  },
});
