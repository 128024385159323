import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, ViewStyle } from 'react-native';

import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { setVideoRate } from '../../../Learn/services/slices';
import { Fonts } from '../../services/utils/AppConstants';

const ALLOWED_RATES = [1, 1.5, 2, 0.8];

type Props = {
  style?: ViewStyle;
  isFromTestPrep?: boolean;
  handleRatePressed?: (rate: number) => void;
  tutorVideoRate?: number;
};

export default (props: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const currentVideoRate = useAppSelector(state => state.questions.videoRate);

  const fontStyle = useMemo(() => {
    if (currentVideoRate.toString().length === 1) {
      return { ...Fonts.xxxlargeBold };
    } else if (currentVideoRate.toString().length > 1) {
      return { ...Fonts.xxlargeBold };
    }
  }, [currentVideoRate]);

  const getVideoRate = useMemo(() => {
    return props.isFromTestPrep ? props.tutorVideoRate ?? 1 : currentVideoRate;
  }, [currentVideoRate, props.isFromTestPrep, props.tutorVideoRate]);

  const onPress = () => {
    const currentIndex = ALLOWED_RATES.findIndex(rate => rate === getVideoRate);
    const nextIndex = currentIndex + 1;
    const nextRate = ALLOWED_RATES[nextIndex % ALLOWED_RATES.length];
    if (props.isFromTestPrep) {
      props.handleRatePressed && props.handleRatePressed(nextRate);
    } else {
      dispatch(setVideoRate(nextRate));
    }
  };

  return (
    <TouchableOpacity style={[styles.container, props.style]} onPress={onPress}>
      <Text>
        <Text style={fontStyle}>{getVideoRate}</Text>
        <Text style={styles.text}>x</Text>
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
  },
  text: {
    ...Fonts.mediumBold,
  },
});
