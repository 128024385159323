import { RootState } from '../../../App/store';
import { CommonAppGoalKeys, CommonExamType } from '../../../Common/entities';
import {
  MapSignUpDataToUserSettingsInput,
  TeachTapGrade,
  UpsertUserSettingsRequest,
} from '../../entities';

export const mapStudentGradeToTeachTapGrade = (
  studentGrade?: string,
): TeachTapGrade | undefined => {
  switch (studentGrade) {
    case '9':
      return TeachTapGrade.FRESHMAN;
    case '10':
      return TeachTapGrade.SOPHOMORE;
    case '11':
      return TeachTapGrade.JUNIOR;
    case '12':
      return TeachTapGrade.SENIOR;
    case '13':
      return TeachTapGrade.POST_12TH_GRADE;
    default:
      return undefined;
  }
};

export const mapSignUpDataToUserSettings = (
  props: MapSignUpDataToUserSettingsInput,
): UpsertUserSettingsRequest => {
  const { examType, emailOptIn, studentGrade } = props;

  let goal: CommonAppGoalKeys | undefined;

  if (examType) {
    goal =
      examType === CommonExamType.SAT
        ? CommonAppGoalKeys.CRUSH_SAT
        : CommonAppGoalKeys.CRUSH_AP_TEST;
  }

  return {
    goal,
    examType,
    grade: mapStudentGradeToTeachTapGrade(studentGrade),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    emailOptIn,
  };
};

export const mapPreferredNameToUserName = (state: RootState): string => {
  const loggedInUser = state.auth.authUser;
  if (!loggedInUser) {
    return '';
  }
  const { preferredName, email } = loggedInUser;
  return preferredName && preferredName.length > 0
    ? preferredName
    : email || '';
};
