import { getRootState } from '../../../App/services/utils';
import { Course } from '../../../Common/entities';
import { mapCourseDataById, mapSATCourseData } from '../mappers';

export const getSATCourse = (): Course | undefined => {
  return mapSATCourseData(getRootState());
};

export const getCourseDataById = (courseId?: string): Course | undefined => {
  return mapCourseDataById(getRootState(), courseId);
};
