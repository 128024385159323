import { AxiosResponse } from 'axios';

import {
  googleFormApi,
  handleRequestError,
} from '../../../App/services/requests';
import { ENV_KEYS, environment } from '../../../Common/services/utils';
import { ExamResultsFeedbackRequest } from '../../entities';

export const submitExamResultsFeedbackCall = async (
  params: ExamResultsFeedbackRequest,
): Promise<AxiosResponse> => {
  const EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT = environment.getValue(
    ENV_KEYS.EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT,
  );

  const formBody = new URLSearchParams(params).toString();
  const response = await googleFormApi.post(
    EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT,
    formBody,
  );

  handleRequestError(response);
  return response;
};
