import { Platform } from 'react-native';
import { NativeModules } from 'react-native';

const { AdServiceModule } = NativeModules;

export const getAttributionToken = async (): Promise<string> => {
  if (Platform.OS === 'ios') {
    try {
      const token = await AdServiceModule.getAttributionToken();
      return token;
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error('AdServices is only available on iOS');
  }
};
