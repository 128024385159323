import React, { useCallback, useMemo } from 'react';
import { RefreshControl, StyleSheet } from 'react-native';

import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { useRefWithSubscribe } from '../../Common/services/hooks';
import { isSATCourse } from '../../Common/services/utils';
import { Colors, Sizes } from '../../Common/services/utils/AppConstants';
import { selectCourseByCourseId } from '../../Learn/services/slices';
import {
  getSATTestPrepHistory,
  getTestPrepHistory,
  isTestHistoryLoadingSelector,
} from '../services/slices';

type Props = {
  courseId?: string;
  disabled?: boolean;
};

export const TestPrepRefreshHistoryControl = React.memo(
  ({ courseId, disabled }: Props): React.ReactElement | null => {
    const dispatch = useAppDispatch();

    const courseDetail = useAppSelector(
      state => selectCourseByCourseId(state.courses, courseId ?? ''),
      (prev, next) => prev?.id === next?.id,
    );

    const isTestHistoryLoading = useAppSelector(
      state => isTestHistoryLoadingSelector(state.testPrep, courseId ?? ''),
      (prev, next) => prev === next,
    );
    const isTestHistoryLoadingRef = useRefWithSubscribe(isTestHistoryLoading);

    const progressLoaderOffset = useMemo(() => {
      return Sizes.safeAreaInsetsTop + Sizes.semiMedium;
    }, []);

    const handleRefreshTestHistory = useCallback(() => {
      if (courseDetail?.id && !isTestHistoryLoadingRef.current) {
        if (isSATCourse(courseDetail)) {
          dispatch(
            getSATTestPrepHistory({ course: courseDetail, enforce: true }),
          );
        } else {
          dispatch(getTestPrepHistory({ course: courseDetail, enforce: true }));
        }
      }
    }, [dispatch, courseDetail, isTestHistoryLoadingRef]);

    if (!courseDetail?.id || disabled) {
      return null;
    }

    return (
      <RefreshControl
        refreshing={isTestHistoryLoading}
        onRefresh={handleRefreshTestHistory}
        tintColor={Colors.white99}
        style={styles.refreshControl}
        progressViewOffset={progressLoaderOffset}
      />
    );
  },
);

const styles = StyleSheet.create({
  refreshControl: {
    zIndex: 50,
  },
});
