import { GetHistoricalFigureResponse } from '../../../AiFigure/entities';
import { AIContentType, ContentAttribute } from '../../../Common/entities';
import {
  PrefillThreshold,
  DMSpeaker,
  MessageSpeakers,
  AskDMFigureRequest,
} from '../../entities';

export const mapMessageSpeakers = (
  response: GetHistoricalFigureResponse[],
): MessageSpeakers => {
  const speakers = response.map(speaker => {
    return {
      name: speaker.name,
      username: speaker.properties?.handle || '',
      avatarUrl: speaker?.thumbnailUrl || speaker?.imageUrl || '',
      title: speaker.properties?.bio || '',
      biography: speaker.properties?.socialBio || '',
      platformId: speaker.platformId,
    };
  });

  return { speakers };
};

export const mapAskDMFigureRequest = (
  speaker: DMSpeaker,
  question: string,
  contentType: AIContentType,
  prefillThreshold: PrefillThreshold,
  syncGeneration?: boolean,
): AskDMFigureRequest => {
  const attributes: ContentAttribute[] = [
    {
      attributeName: 'dmQuestion',
      attributeValue: question,
    },
  ];
  return {
    allowFallbackContent: false,
    contentGeneratorId: contentType,
    contentTypeId: contentType,
    courseId: 'c5492c0a-6c04-11ee-b2e6-afc1a3dc8172', // course id for 'High School Biology Honors'
    levelId: 'c5544f97-6c04-11ee-b2e6-afc1a3dc8172', // level id for 'GEN 5.1.1'
    attributes: attributes,
    isSyncGeneration: syncGeneration,
    prefillThreshold,
    extendedAttributes: [
      {
        platformId: speaker.platformId,
      },
    ],
  };
};
