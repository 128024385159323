import React, { useCallback } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { getGradeStyle, mapGradeToNumber } from '../../Learn/services/utils';

type Props = {
  attempts?: string[];
};

export const TestPrepListAttempts = ({
  attempts,
}: Props): React.ReactElement | null => {
  const handleGetAttemptStyles = useCallback((attempt: string): ViewStyle[] => {
    const backgroundColor = getGradeStyle(attempt).backgroundColor;
    const numericAttempt = mapGradeToNumber(attempt);

    return [
      styles.attempt,
      {
        backgroundColor,
        height: numericAttempt * 25,
      },
    ];
  }, []);

  if (!attempts?.length) {
    return null;
  }

  return (
    <View style={styles.attemptsContainer}>
      {attempts.map((attempt, index) => (
        <View key={index} style={handleGetAttemptStyles(attempt)} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  attemptsContainer: {
    width: 38,
    height: 25,
    gap: 2,
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  attempt: {
    borderRadius: 2,
    flex: 1,
    maxWidth: 20,
  },
});
