import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  isAppStoreBuild,
  isProductionEnv,
  syncAnalyticsIdsWithMixpanel,
} from '../../../../Common/services/utils';
import { getAppsFlyerTracker } from '../../trackers';
import { APP_SLICE_NAME } from '../AppSlice';

export const syncAnalyticsAndStartAppsFlyer = createAsyncThunk(
  `${APP_SLICE_NAME}/syncAnalyticsAndStartAppsFlyer`,
  async () => {
    try {
      if (!isProductionEnv() || !isAppStoreBuild()) {
        return;
      }

      await syncAnalyticsIdsWithMixpanel();
      getAppsFlyerTracker().startSdk();
    } catch (error) {
      console.error('Failed to sync analytics and start AppsFlyer:', error);
    }
  },
);
