import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ImageSourcePropType,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../../App/assets';
import locale from '../../../App/locale';
import { StudyNavigationProps } from '../../../App/navigation/bottomTabs/StudyStackNavigator';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { useSafeAreaCustomInsets } from '../../../Common/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
  StudyStack,
} from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';
import { LearnAssets } from '../../../Learn/assets';
import Animations from '../../../Learn/components/courses/CoinsAnimation';
import TopicFeedHeader from '../../../Learn/components/courses/TopicFeedHeader';
import GradeIndicator from '../../../Learn/components/study/GradeIndicator';
import { Layout } from '../../../Learn/screens/home/content/MCQItemView';
import { mapGradeToNumber } from '../../../Learn/services/utils';
import { useCourseLockStatusGetter } from '../../../UserAccess/services/hooks';
import { TimesUpTestPrepBanner } from '../../components';
import { AnswersStatsView } from '../../components/AnswersStatsView';
import { TestPrepData } from '../../entities';
import { useTestDetailNavigator } from '../../services/hooks';
import {
  setCurrentTestResultsSeen,
  setFirstTestCompleted,
} from '../../services/slices';
import { TestPreviewOrigin } from '../TestPreviewScreen';

const TopicAnimation = React.memo(() => {
  return (
    <View style={styles.gifContainer}>
      <Image
        style={styles.gif}
        resizeMode={'cover'}
        source={LearnAssets.topicCompletionGif.path as ImageSourcePropType}
      />
    </View>
  );
});

type Props = {
  currentTest: TestPrepData;
};

export const APTestCompletionScreenView = (
  props: Props,
): React.ReactElement => {
  const { currentTest } = props;

  const { course, unit } = currentTest;

  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const dispatch = useAppDispatch();

  const firstTestCompleted = useAppSelector(
    state => state.testPrep.firstTestCompleted,
    (prev, next) => prev === next,
  );
  const { isCourseLocked } = useCourseLockStatusGetter({
    courseId: course.id,
  });

  const navigation =
    useNavigation<StudyNavigationProps<StudyStack.TOPIC_COMPLETION_SCREEN>>();

  const {
    handleOpenTestScreen,
    handleOpenPaywallScreenForUpselling,
    handleOpenTestReviewQuestionFlowScreen,
  } = useTestDetailNavigator();

  const numericGrade = useMemo(() => {
    return currentTest?.grade ? mapGradeToNumber(currentTest.grade) : undefined;
  }, [currentTest]);

  const [contentPointLayout, setContentPointLayout] = useState<Layout>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const [showAnimation, setShowAnimation] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 1200);
    return () => {
      setShowAnimation(false);
    };
  }, [currentTest.grade]);

  const headerTitle = useMemo(() => {
    if (!numericGrade) {
      return locale.testPrep.keep_trying_title;
    }
    if (numericGrade <= 0.5) {
      return locale.testPrep.solid_effort_title;
    } else if (numericGrade < 1) {
      return locale.testPrep.well_done_title;
    } else {
      return locale.testPrep.ap_grade_5_heading;
    }
  }, [numericGrade]);

  const completionMessage = useMemo(() => {
    let completionMessage = locale.testPrep.ap_grade_5_message;
    const correctAnswers = currentTest.questions.filter(
      question => question.chosenOption?.correct,
    );

    if (numericGrade && numericGrade <= 0.5) {
      completionMessage = locale.testPrep.ap_grade_3_message;
    } else if (numericGrade && numericGrade < 1) {
      completionMessage = locale.testPrep.ap_grade_4_message;
    } else if (numericGrade) {
      completionMessage = locale.testPrep.ap_grade_5_message;
    } else {
      completionMessage = locale.testPrep.ap_grade_1_message;
    }

    return completionMessage
      .replace('${GRADE}', currentTest.grade ?? '')
      .replace('${UNIT_NAME}', `Unit ${unit.number}: ${unit.name}`)
      .replace('${CORRECT_ANSWER}', correctAnswers.length.toString())
      .replace('${TOTAL_QUESTIONS}', currentTest.questions.length.toString())
      .split('<split>');
  }, [numericGrade, currentTest, unit]);

  const showBonusPoints = useMemo(() => {
    return !!currentTest.pointsAwarded;
  }, [currentTest.pointsAwarded]);

  const bonusPoints = useMemo(() => {
    return currentTest.pointsAwarded ?? 0;
  }, [currentTest.pointsAwarded]);

  const onBackToCoursePress = useCallback(() => {
    if (!firstTestCompleted && isCourseLocked) {
      dispatch(setFirstTestCompleted());
    }
    navigation.popToTop();
  }, [dispatch, firstTestCompleted, navigation, isCourseLocked]);

  const onTakeAnotherTestPress = useCallback(() => {
    handleOpenTestScreen(course, unit, TestPreviewOrigin.TEST_PREP_COMPLETION);
  }, [course, handleOpenTestScreen, unit]);

  const onLayout = (event: LayoutChangeEvent) => {
    const { x, y, width, height } = event.nativeEvent.layout;
    setContentPointLayout({ x: x, y: y, width, height });
  };

  const onAccessAllUnitsPracticeTests = useCallback(() => {
    handleOpenPaywallScreenForUpselling(course);
  }, [course, handleOpenPaywallScreenForUpselling]);

  useEffect(() => {
    dispatch(setCurrentTestResultsSeen(true));
  }, [dispatch]);

  return (
    <LinearGradient
      style={styles.contentContainer}
      colors={Colors.screenGradient}>
      {currentTest.endedDueToTimeout && (
        <View style={styles.bannerContainer}>
          <TimesUpTestPrepBanner />
        </View>
      )}
      <View style={styles.subContainer}>
        <View style={styles.gradeIndicator}>
          <GradeIndicator
            viewStyle={styles.gradeIndicator}
            studentGrade={currentTest.grade}
            textStyle={styles.gradeIndicatorText}
            showDash
          />
          {!currentTest.grade && (
            <Image
              style={styles.emojiContainer}
              resizeMode={'cover'}
              source={
                Assets.test.cuteDisappointedBun.path as ImageSourcePropType
              }
            />
          )}
        </View>
        <View style={styles.messageContainer}>
          <Text style={styles.completionHeading}>{headerTitle}</Text>
          <Text style={styles.completionMessageText}>{completionMessage}</Text>
        </View>
        <AnswersStatsView
          questions={currentTest.questions}
          style={styles.answerStatusContainer}
          onQuestionPress={handleOpenTestReviewQuestionFlowScreen}
        />
        {showBonusPoints && (
          <Text style={styles.bonusPoints} onLayout={onLayout}>
            {locale.testPrep.bonus_points.replace(
              '${POINTS}',
              bonusPoints.toString(),
            )}
          </Text>
        )}
        {currentTest.grade && <TopicAnimation />}
      </View>

      {isCourseLocked ? (
        <View
          style={[
            styles.actionButtonContainer,
            { marginBottom: safeAreaBottom },
          ]}>
          <Text style={styles.upsellUnlockHeading}>
            {locale.testPrep.upsell_unlock_full_course_heading}
          </Text>
          <Text style={styles.description}>
            {locale.testPrep.upsell_unlock_full_course_description}
          </Text>
          <TouchableOpacity
            style={styles.accessAllUnitsContainer}
            onPress={onAccessAllUnitsPracticeTests}>
            <Text style={styles.takeAnotherTestText}>
              {locale.testPrep.access_all_unit_practice_tests}
            </Text>
          </TouchableOpacity>
        </View>
      ) : (
        <View style={styles.actionButtonContainer}>
          <TouchableOpacity
            style={styles.takeAnotherTestContainer}
            onPress={onTakeAnotherTestPress}>
            <Text style={styles.takeAnotherTestText}>
              {locale.testPrep.retake_practice_test.replace(
                '${UNIT_NUMBER}',
                unit?.number.toString(),
              )}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              styles.backToCourseContainer,
              { marginBottom: safeAreaBottom },
            ]}
            onPress={onBackToCoursePress}>
            <Text style={styles.keepStudyingText}>
              {locale.testPrep.back_to_course}
            </Text>
          </TouchableOpacity>
        </View>
      )}
      <TopicFeedHeader
        course={course}
        unit={unit}
        isForTopicCompletion={true}
        isForTestCompletion={true}
      />
      {showBonusPoints && showAnimation ? (
        <Animations
          startPosition={contentPointLayout}
          isFromTestCompletionScreen
        />
      ) : null}
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  bannerContainer: {
    position: 'absolute',
    top: Sizes.safeAreaInsetsTop + Sizes.normalizeIP14PM(64),
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Sizes.normalizeIP14PM(10),
    justifyContent: 'space-between',
  },
  keepStudyingText: {
    ...Fonts.large,
  },
  completionMessageHighlight: {
    ...Fonts.normalize(Fonts.captionBold),
    color: Colors.white70,
    textAlign: 'center',
  },
  completionHeading: {
    ...Fonts.normalize(Fonts.xxxxlargeBold),
  },
  completionMessageText: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    marginBottom: Sizes.normalizeIP14PM(10),
    marginHorizontal: Sizes.normalizeIP14PM(20),
    textAlign: 'center',
  },
  practiceHintMessageText: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    marginBottom: 12,
    marginHorizontal: 16,
    textAlign: 'center',
  },
  subContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    top: Sizes.normalizeIP14PM(50),
  },
  messageContainer: {
    paddingVertical: Sizes.normalizeIP14PM(10),
    rowGap: Sizes.normalizeIP14PM(5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonContainer: {
    width: '95%',
    justifyContent: 'flex-end',
    rowGap: Sizes.normalizeIP14PM(12),
  },
  takeAnotherTestContainer: {
    backgroundColor: Colors.lightBlue,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  accessAllUnitsContainer: {
    backgroundColor: Colors.actionGreen,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  startTargetedPracticeButton: {
    backgroundColor: Colors.additional.orange[100],
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    flexDirection: 'row',
    columnGap: 8,
    marginBottom: 16,
  },
  takeAnotherTestText: {
    ...Fonts.large,
  },
  backToCourseContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    borderWidth: 1,
    borderColor: Colors.white12,
  },
  description: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    textAlign: 'center',
    marginBottom: Sizes.normalizeIP14PM(5),
    paddingHorizontal: Sizes.normalizeIP14PM(8),
  },
  gifContainer: {
    width: '100%',
    height: '60%',
    position: 'absolute',
    top: 0,
  },
  gif: {
    width: '100%',
    height: '100%',
  },
  answerStatusContainer: {
    width: '95%',
    paddingVertical: Sizes.normalizeIP14PM(5),
    paddingHorizontal: Sizes.normalizeIP14PM(10),
  },
  questionStatus: {
    alignItems: 'center',
    justifyContent: 'center',
    width: Sizes.normalizeIP14PM(33),
    height: Sizes.normalizeIP14PM(40),
    borderRadius: 20,
    margin: Sizes.normalizeIP14PM(5),
    rowGap: Sizes.normalizeIP14PM(2),
  },
  questionNumber: {
    ...Fonts.normalize(Fonts.xsmallBold),
    color: Colors.white60,
  },
  bonusPoints: {
    ...Fonts.normalize(Fonts.semiMediumBold),
    color: Colors.emeraldGreen,
    marginVertical: Sizes.normalizeIP14PM(8),
  },
  gradeIndicator: {
    width: Sizes.normalizeIP14PM(80),
    height: Sizes.normalizeIP14PM(80),
    borderRadius: Sizes.normalizeIP14PM(40),
  },
  gradeIndicatorText: {
    ...Fonts.normalize(Fonts.plusPlusSize),
  },
  emojiContainer: {
    position: 'absolute',
    width: 35,
    height: 35,
    bottom: 0,
    end: 0,
  },
  upsellUnlockHeading: {
    ...Fonts.normalize(Fonts.captionBold),
    textAlign: 'center',
  },
});
