import { GestureResponderEvent } from 'react-native';

const TIME_TO_DOUBLE_TAP = 250;

type DoubleTapActionUtil = (event: GestureResponderEvent) => void;

export const createDoubleTapAction = (
  onSingleTap: (event: GestureResponderEvent) => void,
  onDoubleTap: (event: GestureResponderEvent) => void,
): DoubleTapActionUtil => {
  let lastTapTime = 0;
  return (event: GestureResponderEvent): void => {
    if (event.persist) {
      event.persist();
    }
    const now = new Date().getTime();
    const isDoubleTap = (time: number) =>
      time - lastTapTime < TIME_TO_DOUBLE_TAP;

    if (lastTapTime > 0 && isDoubleTap(now)) {
      onDoubleTap(event);

      lastTapTime = 0;
    } else {
      setTimeout(() => {
        if (lastTapTime > 0) {
          onSingleTap(event);
        }
        lastTapTime = 0;
      }, TIME_TO_DOUBLE_TAP);
      lastTapTime = now;
    }
  };
};
