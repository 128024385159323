import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, REHYDRATE, RehydrateAction } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { Course, Topic, Unit } from '../../../Common/entities';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  AppMajorVersionPatch,
  ScreenNames,
} from '../../../Common/services/utils/AppConstants';
import { PaywallCharacterResponse } from '../../../Paywall/entities';
import { fetchCoursePaywallCharactersCall } from '../../../Paywall/services/requests';
import {
  incrementAwardedPoints,
  incrementCorrectAnswers,
} from '../../../Profile/services/slices';
import { ProgressCalculationAlgorithm } from '../../algorithms';
import {
  CourseUnitProgress,
  GetLearningAppProgressResponse,
  RecordContentAnswerResponse,
  RecordMasteryAction,
  SetCourseStartedAction,
  SetMasteredCourseUnitAction,
} from '../../entities';
import {
  getCurriculumGraphQLCall,
  getLearningAppProgressGraphQLCall,
  recordContentAnswerGraphQLCall,
} from '../../graphql';
import {
  mapCourseResponseToCourse,
  mapMaxGradeForCourseTopic,
  mapNewCourseWithOldCourseWhileRetainingProgress,
} from '../mappers';
import {
  isHighestGrade,
  mapGradeToNumber,
  redirectToMilestoneAchievedFromLearn,
  redirectToTopicCompletionFromLearn,
  redirectToTopicMasterDropFromLearn,
} from '../utils';

import {
  awardMilestoneAchievedPoints,
  getMaxCourseTopicGrade,
  getMaxCourseTopicGradeExtraReducers,
} from './CoursesSliceActions';

export const COURSES_SLICE_NAME = 'CoursesSlice';

const COURSE_PROGRESS_COOLDOWN = 60 * 60 * 1000; // 1 hour

const persistConfig = {
  key: COURSES_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: [
    'coursesMap',
    'majorVersionPatch',
    'paywallCharacters',
    'courseCharacterVideos',
    'hasCourseStarted',
    'masteredCourseUnit',
    'courseProgressLastFetchedOn',
    'maxCourseTopicMasteryGrade',
  ],
} as PersistConfig<CoursesSliceState>;

export type CoursesSliceState = {
  coursesMap: Record<string, Course>;
  loading: boolean;
  error: string | null;
  nextUnitForCourse: Record<string, string>;

  nextTopicForCourseUnit: Record<string, Record<string, string>>; // {[courseId]: {[unitId]: topicId}}

  maxCourseTopicMasteryGrade: Record<string, Record<string, string>>; // {[courseId}: {[topicId]: grade}}

  majorVersionPatch?: AppMajorVersionPatch;

  paywallCharacters?: PaywallCharacterResponse[];

  // {[courseId]: boolean}
  hasCourseStarted: Record<string, boolean>;

  // {[courseId]: {[unitId]: boolean}}
  masteredCourseUnit: Record<string, Record<string, boolean>>;

  // {[courseId]: last fetched - ISO string}
  courseProgressLastFetchedOn: Record<string, string>;
  courseProgressLoading: Record<string, boolean>;
};

const initialState: CoursesSliceState = {
  coursesMap: {},
  loading: false,
  error: null,
  nextUnitForCourse: {},
  nextTopicForCourseUnit: {},

  maxCourseTopicMasteryGrade: {},

  majorVersionPatch: undefined,
  hasCourseStarted: {},
  paywallCharacters: [],
  masteredCourseUnit: {},

  courseProgressLastFetchedOn: {},
  courseProgressLoading: {},
};

export const getCourseProgressOnSessionInit = createAsyncThunk(
  `${COURSES_SLICE_NAME}/getCourseProgressOnSessionInit`,
  async (_, thunkApi) => {
    const rootState = thunkApi.getState() as RootState;
    const courseEnrollmentsState = rootState.courseEnrollments;
    const courseState = rootState.courses;

    for (const course of courseEnrollmentsState.following || []) {
      const courseData = courseState.coursesMap[course.id];
      if (courseData) {
        thunkApi.dispatch(getCourseProgress({ courseId: courseData.id }));
      }
    }
  },
);

export const recordMastery = createAsyncThunk(
  `${COURSES_SLICE_NAME}/recordMastery`,
  async (action: RecordMasteryAction, thunkApi) => {
    if (
      !action.card.levelId ||
      !action.card.subjectId ||
      !action.card.unit ||
      !action.card.topic
    ) {
      return thunkApi.rejectWithValue(locale.errors.unknown_error);
    }

    let state = thunkApi.getState() as RootState;
    let course = state.courses.coursesMap[action.card.course.id];

    try {
      const response = await recordContentAnswerGraphQLCall({
        subject: {
          id: course.subjectId,
          name: course.subject,
        },
        course: {
          id: course.id,
          name: course.name,
        },
        unit: {
          id: action.card.unit.id,
          grade: action.card.unit.grade || null,
        },
        topic: {
          id: action.card.topic.id,
          grade: action.card.topic.grade || null,
        },
        levelId: action.card.levelId,
        activity: {
          id: action.card.generatedContentId,
          correct: action.correct,
          shownAt: action.shownAt,
          helpShownAt: action.helpShownAt,
          answeredAt: action.answeredAt,
          explanationShownAt: action.explanationShownAt,
          finishedReadingExplanationAt: action.finishedReadingExplanationAt,
          numberOfAttempts: action.numberOfAttempts,
          pointsAwarded: action.pointsAwarded,
        },
      });

      // Renew the state after the request, because the state could have changed
      state = thunkApi.getState() as RootState;
      const localState = (thunkApi.getState() as RootState).courses;
      course = state.courses.coursesMap[action.card.course.id];

      const unit = course.units.find(unit => unit.id === action.card.unit?.id);
      const topic = unit?.topics.find(
        topic => topic.id === action.card.topic?.id,
      );

      const isGradeProgressing =
        ProgressCalculationAlgorithm.isGradeProgressing(
          topic?.grade,
          response.topicProgress.studentGrade,
        );

      const wasGradeLessThanMaximumGrade =
        ProgressCalculationAlgorithm.isGradeDecreasing(
          mapMaxGradeForCourseTopic(localState, {
            courseId: course.id,
            topicId: topic?.id,
          }),
          topic?.grade,
        );

      const isGradeDecreasing = ProgressCalculationAlgorithm.isGradeDecreasing(
        topic?.grade,
        response.topicProgress.studentGrade,
      );

      if (isGradeProgressing || isGradeDecreasing) {
        await thunkApi
          .dispatch(
            checkUnitMastery({
              courseId: course.id,
              unit,
              unitProgress: response.unitProgress,
            }),
          )
          .unwrap();

        if (RootNavigatorRef.isReady()) {
          const isFromStudyFeedScreen =
            RootNavigatorRef.getCurrentRoute()?.name ===
            ScreenNames.StudyStack.FEED_SCREEN;

          if (isFromStudyFeedScreen) {
            if (isGradeProgressing) {
              trackAnalyticsEvent(Analytics.masteryLevelGained, {
                courseId: course.id,
                courseName: course.name,
                topicId: topic?.id,
                topicName: topic?.name,
                fromLevel: topic?.grade,
                toLevel: response.topicProgress.studentGrade,
                isRegain: wasGradeLessThanMaximumGrade,
              });

              if (wasGradeLessThanMaximumGrade) {
                redirectToTopicCompletionFromLearn({
                  course,
                  unit,
                  topic,
                  hasMasteryRegained: true,
                });
              } else {
                thunkApi.dispatch(
                  awardMilestoneAchievedPoints({
                    subjectId: course.subjectId,
                    courseId: course.id,
                    unitId: unit?.id,
                    topicId: topic?.id,
                  }),
                );

                redirectToMilestoneAchievedFromLearn({
                  course,
                  unit,
                  topic,
                });
              }
            } else if (isGradeDecreasing) {
              trackAnalyticsEvent(Analytics.masteryLevelDropped, {
                courseId: course.id,
                courseName: course.name,
                topicId: topic?.id,
                topicName: topic?.name,
                fromLevel: topic?.grade,
                toLevel: response.topicProgress.studentGrade,
              });

              redirectToTopicMasterDropFromLearn({
                course,
                unit,
                topic,
              });
            }
          }
        }

        trackAnalyticsEvent(Analytics.completeTopic, {
          course: action.card.course,
          unit: action.card.unit,
          topic: action.card.topic,
        });
      }

      if (action.correct) {
        thunkApi.dispatch(incrementAwardedPoints(action?.pointsAwarded ?? 0));
        thunkApi.dispatch(incrementCorrectAnswers(1));
      }

      return response;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(e);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const getPaywallCharacters = createAsyncThunk(
  `${COURSES_SLICE_NAME}/getPaywallCharacters`,
  async (_, thunkApi) => {
    try {
      return await fetchCoursePaywallCharactersCall();
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const getAllCourses = createAsyncThunk(
  `${COURSES_SLICE_NAME}/getAllCourses`,
  async (_, thunkApi) => {
    try {
      const curriculumResponse = await getCurriculumGraphQLCall();

      return curriculumResponse.subjects.flatMap(subject =>
        subject.courses.map(course =>
          mapCourseResponseToCourse(course, subject),
        ),
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

type GetCourseProgressAction = {
  courseId: string;
  enforce?: boolean;
};

export const getCourseProgress = createAsyncThunk(
  `${COURSES_SLICE_NAME}/getCourseProgress`,
  async (params: GetCourseProgressAction, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.courses;

      if (
        state.courseProgressLastFetchedOn?.[params.courseId] &&
        !params.enforce
      ) {
        const lastFetchedOn = new Date(
          state.courseProgressLastFetchedOn[params.courseId],
        );
        const currentTime = new Date();

        if (
          currentTime.getTime() - lastFetchedOn.getTime() <=
          COURSE_PROGRESS_COOLDOWN
        ) {
          return { result: null, skip: true };
        }
      }

      thunkApi.dispatch(getMaxCourseTopicGrade({ courseId: params.courseId }));
      const result = await getLearningAppProgressGraphQLCall({
        courseId: params.courseId,
      });

      return { result, skip: false };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

type GetCourseProgressAndCheckUnitMasteryPayload = {
  courseId: string;
  unit?: Unit;
  unitProgress: CourseUnitProgress;
};

export const checkUnitMastery = createAsyncThunk(
  `${COURSES_SLICE_NAME}/checkUnitMastery`,
  async (params: GetCourseProgressAndCheckUnitMasteryPayload, thunkApi) => {
    try {
      const currentUnit = { ...params.unit } as Unit;
      const { unitProgress } = params;

      const state = thunkApi.getState() as RootState;
      const course = state.courses.coursesMap[params.courseId];

      const selectedUnit = course.units.find(
        unit => unit.id === currentUnit?.id,
      );

      if (!selectedUnit || !currentUnit?.id) {
        return;
      }

      const isUnitMastered =
        state.courses.masteredCourseUnit[course.id]?.[selectedUnit.id];
      if (isUnitMastered) {
        return;
      }

      const isUnitGradeProgressing =
        ProgressCalculationAlgorithm.isGradeProgressing(
          currentUnit?.grade,
          unitProgress?.studentGrade,
        );

      if (!isUnitGradeProgressing) {
        return;
      }

      const isRecentUnitMastered = isHighestGrade(unitProgress?.studentGrade);
      if (!isRecentUnitMastered) {
        return;
      }

      // intended using this so we are not overwriting the state
      trackAnalyticsEvent(Analytics.completeUnit, {
        courseId: course.id,
        courseName: course.name,
        unitId: selectedUnit.id,
        unitName: selectedUnit.name,
        grade: unitProgress.studentGrade,
      });

      thunkApi.dispatch(
        setMasteredCourseUnit({ courseId: course.id, unitId: selectedUnit.id }),
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

const slice = createSlice({
  name: COURSES_SLICE_NAME,
  initialState,
  reducers: {
    resetProgress: (state: CoursesSliceState) => {
      state.masteredCourseUnit = {};
      state.courseProgressLastFetchedOn = {};
      state.courseProgressLoading = {};
      state.maxCourseTopicMasteryGrade = {};

      Object.values(state.coursesMap).forEach(course => {
        course.units?.forEach(unit => {
          unit.grade = undefined;
          unit.topics?.forEach(topic => {
            topic.progressIndicator = 0;
            topic.progress = 0;
            topic.grade = undefined;
          });
        });
      });
    },
    setCourseStarted: (state, action: SetCourseStartedAction) => {
      const { courseId, hasStarted } = action.payload;
      state.hasCourseStarted[courseId] = hasStarted;
    },
    resetCourseStarted: state => {
      state.hasCourseStarted = {};
    },
    setMasteredCourseUnit: (state, action: SetMasteredCourseUnitAction) => {
      const { courseId, unitId } = action.payload;
      if (!state.masteredCourseUnit[courseId]) {
        state.masteredCourseUnit[courseId] = {};
      }

      state.masteredCourseUnit[courseId][unitId] = true;
    },
  },
  extraReducers: builder => {
    getMaxCourseTopicGradeExtraReducers(builder);

    builder.addCase(getAllCourses.pending, (state: CoursesSliceState) => {
      if (Object.keys(state.coursesMap).length === 0) {
        state.loading = true;
      }
      state.error = null;
    });

    builder.addCase(
      getAllCourses.fulfilled,
      (state: CoursesSliceState, action: { payload: Course[] }) => {
        state.loading = false;
        const coursesMap = {
          ...state.coursesMap,
        };

        state.coursesMap = { ...coursesMap };

        action.payload.forEach(course => {
          state.coursesMap[course.id] =
            mapNewCourseWithOldCourseWhileRetainingProgress(
              course,
              coursesMap[course.id],
            );
        });

        Object.keys(state.coursesMap).forEach(courseId => {
          const isCourseReturned = action.payload.find(
            course => course.id === courseId,
          );

          if (!isCourseReturned) {
            delete state.coursesMap[courseId];
          }
        });
      },
    );

    builder.addCase(
      getAllCourses.rejected,
      (state: CoursesSliceState, action: PayloadAction<unknown>) => {
        state.loading = false;
        state.error = action.payload as string | null;
      },
    );

    builder.addCase(
      recordMastery.pending,
      (
        state: CoursesSliceState,
        action: { meta: { arg: RecordMasteryAction } },
      ) => {
        const args = action.meta.arg as RecordMasteryAction;

        const negativePoint =
          args.explanationShownAt || args.helpShownAt ? 0 : -1;

        if (args.card.unit && args.card.topic) {
          const course = state.coursesMap[args.card.course.id];

          const unit = course.units.find(
            unit => unit.id === args.card.unit!.id,
          );

          const topic = unit?.topics.find(
            topic => topic.id === args.card.topic!.id,
          );

          if (topic) {
            if (topic.progress < 100) {
              topic.progressIndicator = args.correct ? 1 : negativePoint;
            } else {
              topic.progressIndicator = 0;
            }
          }
        }
      },
    );

    builder.addCase(
      recordMastery.rejected,
      (
        state: CoursesSliceState,
        action: { meta: { arg: RecordMasteryAction } },
      ) => {
        const args = action.meta.arg as RecordMasteryAction;

        if (args.card.unit && args.card.topic) {
          const course = state.coursesMap[args.card.course.id];

          const unit = course.units.find(
            unit => unit.id === args.card.unit!.id,
          );

          const topic = unit?.topics.find(
            topic => topic.id === args.card.topic!.id,
          );

          if (topic) {
            topic.progressIndicator = 0;
          }
        }
      },
    );

    builder.addCase(
      recordMastery.fulfilled,
      (
        state: CoursesSliceState,
        action: {
          meta: {
            arg: RecordMasteryAction;
          };
          payload: RecordContentAnswerResponse;
        },
      ) => {
        const args = action.meta.arg as RecordMasteryAction;

        if (args.card.unit && args.card.topic) {
          const course = state.coursesMap[args.card.course.id];

          const unit = course.units.find(
            unit => unit.id === args.card.unit!.id,
          );

          const courseId = args.card.course.id;
          state.nextUnitForCourse[courseId] = action.payload.currentUnitId;

          if (!state.nextTopicForCourseUnit[courseId]) {
            state.nextTopicForCourseUnit[courseId] = {};
          }
          state.nextTopicForCourseUnit[courseId][action.payload.currentUnitId] =
            action.payload.currentTopicId;

          if (unit) {
            unit.grade = action.payload.unitProgress.studentGrade;

            const topic = unit?.topics.find(
              topic => topic.id === args.card.topic!.id,
            );

            if (topic) {
              if (!state.maxCourseTopicMasteryGrade[courseId]) {
                state.maxCourseTopicMasteryGrade[courseId] = {};
              }

              topic.grade = action.payload.topicProgress.studentGrade;
              topic.progress =
                action.payload.topicProgress.nextGradeProgress || 0;
              topic.progressIndicator = 0;

              if (topic.grade) {
                if (!state.maxCourseTopicMasteryGrade[courseId]) {
                  state.maxCourseTopicMasteryGrade[courseId] = {};
                }

                const oldGrade =
                  state.maxCourseTopicMasteryGrade?.[courseId]?.[topic.id];
                if (oldGrade) {
                  const oldMaxNumericGrade = mapGradeToNumber(oldGrade);
                  const newNumericGrade = mapGradeToNumber(topic.grade);

                  if (newNumericGrade > oldMaxNumericGrade) {
                    state.maxCourseTopicMasteryGrade[courseId][topic.id] =
                      topic.grade;
                  }
                } else {
                  state.maxCourseTopicMasteryGrade[courseId][topic.id] =
                    topic.grade;
                }
              }
            }
          }
        }
      },
    );

    builder.addCase(
      getCourseProgress.pending,
      (state: CoursesSliceState, action) => {
        const args = action.meta.arg;
        state.courseProgressLoading[args.courseId] = true;
      },
    );

    builder.addCase(
      getCourseProgress.rejected,
      (state: CoursesSliceState, action) => {
        const args = action.meta.arg;
        state.courseProgressLoading[args.courseId] = false;
      },
    );

    builder.addCase(
      getCourseProgress.fulfilled,
      (state: CoursesSliceState, action) => {
        const args = action.meta.arg;
        const course = state.coursesMap[args.courseId];

        state.courseProgressLoading[args.courseId] = false;

        const { result, skip } = action.payload;
        if (skip) {
          return;
        }

        const userProgress = result as GetLearningAppProgressResponse;
        state.courseProgressLastFetchedOn[args.courseId] =
          new Date().toISOString();

        const courseId = args.courseId;
        state.nextUnitForCourse[courseId] = userProgress.course.currentUnitId;

        userProgress.course?.units?.forEach(unit => {
          if (!state.nextTopicForCourseUnit[courseId]) {
            state.nextTopicForCourseUnit[courseId] = {};
          }

          state.nextTopicForCourseUnit[courseId][unit.id] = unit.currentTopicId;
        });

        course.units.forEach(unit => {
          const unitProgress = userProgress.course.units.find(
            item => item.id === unit.id,
          );
          if (unitProgress) {
            unit.grade = unitProgress.studentGrade;
            unit.topics.forEach(topic => {
              const topicProgress = unitProgress.topics.find(
                item => item.id === topic.id,
              );
              if (topicProgress) {
                topic.grade = topicProgress.studentGrade;
                topic.progress = topicProgress.nextGradeProgress;
              }
            });
          }
        });
      },
    );

    builder.addCase(
      getPaywallCharacters.fulfilled,
      (
        state: CoursesSliceState,
        action: { payload: PaywallCharacterResponse[] },
      ) => {
        state.paywallCharacters = action.payload;
      },
    );

    builder.addCase(REHYDRATE, (state, action: RehydrateAction) => {
      if (action.key === COURSES_SLICE_NAME) {
        const payload = action.payload as CoursesSliceState | undefined;

        // Use the majorVersionPatch to determine if we should reset the progress,
        // or if we have some specific actions to do
        if (payload?.majorVersionPatch !== AppMajorVersionPatch.ProgressV2) {
          state.majorVersionPatch = AppMajorVersionPatch.ProgressV2;
          state.coursesMap = {};
        }
      }
    });
  },
});

export const getTopicDataSelector = (
  state: RootState,
  course: Course,
  unit: Unit,
  topic: Topic,
): Topic | undefined => {
  const courseData = state.courses.coursesMap[course.id];
  const unitData = courseData.units.find(item => item.id === unit.id);
  return unitData?.topics.find(item => item.id === topic.id);
};

export const getNextUnitAndTopicForCourseSelector = (
  state: RootState,
  course?: Course,
): { unit?: Unit; topic?: Topic } => {
  if (!course) {
    return {};
  }
  const nextUnitId = state.courses.nextUnitForCourse[course.id];
  if (!nextUnitId) {
    return {};
  }

  const nextTopicId =
    state.courses.nextTopicForCourseUnit?.[course.id]?.[nextUnitId];

  const unit = course?.units?.find(unit => unit.id === nextUnitId);
  const topic = unit?.topics?.find(topic => topic.id === nextTopicId);
  return { unit, topic };
};

export const isCourseStartedSelector = (
  state: CoursesSliceState,
  courseId?: string,
): boolean => {
  if (!courseId) {
    return false;
  }

  return state.hasCourseStarted[courseId] ?? false;
};

export const isCourseProgressLoadingSelector = (
  state: CoursesSliceState,
  courseId?: string,
): boolean => {
  if (!courseId) {
    return false;
  }

  return state.courseProgressLoading[courseId] ?? false;
};

export const selectCourseByCourseId = (
  state: CoursesSliceState,
  courseId: string,
): Course | undefined => {
  if (!courseId) {
    return undefined;
  }

  return state.coursesMap[courseId];
};

export const {
  resetProgress,
  setCourseStarted,
  resetCourseStarted,
  setMasteredCourseUnit,
} = slice.actions;

export const CoursesSlice = persistReducer(persistConfig, slice.reducer);
