import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ENV_KEYS, environment } from '../../../Common/services/utils';

export class AppBucketApi {
  private static instance: AppBucketApi;
  private apiInstance: AxiosInstance;

  private constructor() {
    this.apiInstance = this.createApiInstance();
  }

  public static getInstance(): AppBucketApi {
    if (!AppBucketApi.instance) {
      AppBucketApi.instance = new AppBucketApi();
    }
    return AppBucketApi.instance;
  }

  private createApiInstance(): AxiosInstance {
    return axios.create({
      baseURL: environment.getValue(ENV_KEYS.APP_BUCKET_URL),
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  public async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.apiInstance.post(url, data, config);
  }

  public async get<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return this.apiInstance.get(url, config);
  }
}
