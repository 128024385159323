import { useIsFocused } from '@react-navigation/core';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Pressable, StyleSheet } from 'react-native';

import { CommonExamType } from '../../Common/entities';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import { Analytics } from '../../Common/services/utils/AppConstants';
import { RNVideo } from '../../Common/services/utils/polyfills';
import { BootcampEntryPoint } from '../entities';

import { OnboardingIntroVideoControl } from './OnboardingIntroVideoControl';

const AP_INTRO_VIDEO = require('../../../assets/video/ap_intro_video.mp4');
const SAT_INTRO_VIDEO = require('../../../assets/video/sat_intro_video.mp4');

type Props = {
  examType: CommonExamType;
  onProceedNextScreen: () => void;
};

export type OnboardingIntroVideoRef = {
  restartVideo: () => void;
};

export const OnboardingIntroVideo = forwardRef<OnboardingIntroVideoRef, Props>(
  (props: Props, ref): React.ReactElement => {
    const videoRef = useRef<RNVideo>(null);
    const [isMediaPaused, setIsMediaPaused] = useState<boolean>(false);

    const isFocused = useIsFocused();

    const handleVideoPressed = useCallback(() => {
      setIsMediaPaused(prev => !prev);
    }, []);

    const handleVideoEnd = useCallback(() => {
      trackAnalyticsEvent(Analytics.videoFinished, {
        from: BootcampEntryPoint.INTRO,
        examType: props?.examType,
      });
      props.onProceedNextScreen();
      // Use this eslint-disable-next-line to ignore the exhaustive-deps warning.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onProceedNextScreen]);

    const videoSource = useMemo(() => {
      return props.examType === CommonExamType.SAT
        ? SAT_INTRO_VIDEO
        : AP_INTRO_VIDEO;
    }, [props.examType]);

    useImperativeHandle(ref, () => ({
      restartVideo: () => {
        videoRef.current?.seek(0);
        setIsMediaPaused(false);
      },
    }));

    return (
      <Pressable style={styles.mediaContainer} onPress={handleVideoPressed}>
        <RNVideo
          ref={videoRef}
          source={videoSource}
          style={styles.media}
          paused={isMediaPaused || !isFocused}
          resizeMode="contain"
          repeat={false}
          onEnd={handleVideoEnd}
          ignoreSilentSwitch="ignore"
          muted={false}
        />

        <OnboardingIntroVideoControl isMediaPaused={isMediaPaused} />
      </Pressable>
    );
  },
);

const styles = StyleSheet.create({
  mediaContainer: {
    flex: 1,
    backgroundColor: 'black',
    zIndex: 0,
  },
  media: {
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
});
