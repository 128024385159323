import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { CommonAssets } from '../assets';
import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

type Props = {
  placeholder: string;
  onPress: () => void;
  value?: string;
  disabled?: boolean;
  error?: string;
};

export const CommonDropdownInputField = (props: Props): React.ReactElement => {
  const textStyles = useMemo(() => {
    return [styles.text, Boolean(props.value) && styles.textActive];
  }, [props.value]);

  const containerStyle = useMemo(() => {
    return [styles.container, Boolean(props.error) && { borderColor: 'red' }];
  }, [props.error]);

  return (
    <View>
      <TouchableOpacity
        style={containerStyle}
        onPress={props.onPress}
        disabled={props.disabled}>
        <Text style={textStyles}>{props.value || props.placeholder}</Text>
        <AssetImage asset={CommonAssets.InputArrowDown} height={18} />
      </TouchableOpacity>

      {props.error && <Text style={styles.errorText}>{props.error}</Text>}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.white10,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white12,
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  text: {
    ...Fonts.caption,
    color: Colors.white40,
  },
  textActive: {
    color: 'white',
  },

  errorText: {
    ...Fonts.caption,
    color: 'white',
    marginTop: Sizes.xsmall,
  },
});
