import {
  DMSpeaker,
  MessageItemData,
  MessageItemType,
  StreamingMessageDetails,
  UserType,
} from '../../../Messages/entities';

export const mapStreamingToChat = (
  aiStreamedMessage: StreamingMessageDetails,
  speaker: DMSpeaker,
): MessageItemData => {
  return {
    type: MessageItemType.Message,
    data: {
      showDate: false,
      id: aiStreamedMessage.requestId,
      speakerUsername: speaker.name,
      isNewConversation: false,
      message: aiStreamedMessage.message,
      sender: UserType.dmFigure,
      date: aiStreamedMessage.createdAt,
      isStreaming: aiStreamedMessage.streaming,
    },
  };
};
