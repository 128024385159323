import React, { useMemo } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

type Props = {
  onPress: () => void;
  children?: React.ReactElement;
  text?: string;
  disabled?: boolean;
  style?: ViewStyle;
  textStyle?: TextStyle;
  isLoading?: boolean;
};

export const CommonActionButton = (props: Props): React.ReactElement => {
  const buttonStyles = useMemo(() => {
    if (!props.disabled && !props.isLoading) {
      return {
        container: [styles.button, props.style],
        text: [styles.buttonText, props.textStyle],
      };
    }

    return {
      container: [styles.button, styles.buttonDisabled, props.style],
      text: [styles.buttonText, styles.buttonTextDisabled, props.textStyle],
    };
  }, [props.disabled, props.style, props.textStyle, props.isLoading]);

  const buttonContentState = useMemo(() => {
    return {
      showLoading: props.isLoading,
      showText: !props.isLoading && !props.children && props.text,
      showChildren: !props.isLoading && props.children,
    };
  }, [props.children, props.isLoading, props.text]);

  return (
    <TouchableOpacity
      disabled={props.disabled || props.isLoading}
      onPress={props.onPress}
      style={buttonStyles.container}>
      {buttonContentState.showLoading && <ActivityIndicator color="white" />}

      {buttonContentState.showText && (
        <Text style={buttonStyles.text}>{props.text}</Text>
      )}

      {buttonContentState.showChildren && props.children}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  button: {
    paddingVertical: Sizes.semiMedium,
    paddingHorizontal: Sizes.medium,
    borderRadius: Sizes.small,
    backgroundColor: Colors.actionGreen,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisabled: {
    backgroundColor: Colors.white12,
  },
  buttonText: {
    ...Fonts.large,
    color: 'white',
    textAlign: 'center',
  },
  buttonTextDisabled: {
    color: Colors.white30,
  },
});
