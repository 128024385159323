import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';

export const SATBootcampTutorial4 = (): React.ReactElement => {
  return (
    <View style={styles.container}>
      <AssetImage asset={OnboardingAssets.MoneyBackBadgeIcon} height={80} />
      <View style={styles.moneyBackTextContainer}>
        <View style={styles.guaranteedResultsContainer}>
          <Text style={styles.labelText}>
            {locale.onboarding.guaranteed_results_or_refund}
          </Text>
          <AssetImage
            asset={OnboardingAssets.ShortUnderline}
            containerStyle={styles.zigZagLine}
            height={10}
            width={Sizes.normalizeIP14PM(95)}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  moneyBackTextContainer: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  labelText: {
    ...Fonts.largeGochiHand,
    color: Colors.actionGreen,
  },
  zigZagLine: {
    alignSelf: 'flex-start',
    bottom: -5,
    left: Sizes.normalizeIP14PM(5),
    position: 'absolute',
  },
  guaranteedResultsContainer: {
    position: 'absolute',
    transform: [{ rotate: '-3deg' }],
    bottom: Sizes.normal,
  },
});
