import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { RootState } from '../../../../App/store';
import {
  MainStack,
  OnboardingStack,
} from '../../../../Common/services/utils/AppConstants';
import { OpenSATBootcampFlowParams } from '../../../entities';
import {
  ONBOARDING_SLICE_NAME,
  setBootcampEntryPoint,
} from '../OnboardingSlice';

import { checkIfUserHasEntitlement } from './CheckIfUserHasEntitlementAction';

export const openSATBootcampFlow = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/openSATBootcampFlow`,
  async (params: OpenSATBootcampFlowParams, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const localState = rootState.onboarding;

    thunkAPI.dispatch(setBootcampEntryPoint(params.entrypoint));
    const hasEntitlement = await thunkAPI
      .dispatch(checkIfUserHasEntitlement(params.entrypoint))
      .unwrap();

    if (!RootNavigatorRef?.current?.isReady()) {
      return;
    }

    const shouldNavigateToEntitlement =
      hasEntitlement && !localState.doOnboardingFlowAfterSignup;

    if (shouldNavigateToEntitlement) {
      RootNavigatorRef.current?.navigate(MainStack.BOOTCAMP_ENTITLEMENT_SCREEN);
      return;
    }

    RootNavigatorRef.current?.navigate({
      name: MainStack.ONBOARDING_STACK,
      params: {
        screen: OnboardingStack.EXAM_INPUTS,
      },
    });
  },
);
