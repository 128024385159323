import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { ContentCard } from '../../entities';
import { useSafeAreaCustomInsets } from '../../services/hooks';
import { Fonts, isAndroidPlatform } from '../../services/utils/AppConstants';
import CircularProgress from '../common/CircularProgress';

type Props = {
  item: ContentCard;
  onPlaylistPress?: () => void;
  percentage?: number;
};

export const PLAYLIST_HEIGHT = 0;

export default ({
  item,
  onPlaylistPress,
  percentage,
}: Props): React.ReactElement => {
  const { tabBarHeightWithSafeArea } = useSafeAreaCustomInsets();

  return (
    <Pressable
      style={[styles.container, { height: tabBarHeightWithSafeArea }]}
      onPress={onPlaylistPress}>
      <View style={styles.contentContainer}>
        <View style={styles.leftContent}>
          <AssetImage asset={Assets.home.playlistIcon} height={16} />
          <Text style={styles.labelText}>
            {locale.home_screen_card.playlist_title} • {item.topic?.name}
          </Text>
          <CircularProgress
            percentage={percentage}
            radius={9}
            strokeWidth={3}
            strokeOpacity={'1'}
          />
        </View>
        <View style={styles.rightContent}>
          <AssetImage asset={Assets.home.rightChevronIcon} height={14} />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#161616',
    width: '100%',
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1,
  },
  leftContent: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 2,
  },
  rightContent: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    columnGap: 2,
  },
  labelText: {
    marginStart: 4,
    ...Fonts.normalize(Fonts.small),
    textAlignVertical: 'center',
    lineHeight: isAndroidPlatform ? 14 : undefined,
  },
});
