import React from 'react';

import { ChatItem, ChatItemDate } from '../../Messages/components';
import {
  DMSpeaker,
  MessageItem,
  MessageItemData,
  MessageItemType,
} from '../../Messages/entities';
import { RaiseHandScreenTypes } from '../entities';

type RaiseHandChatItemProps = {
  item: MessageItemData;
  index: number;
  speaker: DMSpeaker;
  isFromTestPrep: boolean;
  isFromMathTest?: boolean;
};

export const RaiseHandChatItem = ({
  item,
  index,
  speaker,
  isFromTestPrep,
  isFromMathTest,
}: RaiseHandChatItemProps): React.ReactElement => {
  if (item.type === MessageItemType.Date) {
    // need all cast below to avoid TS error
    return (
      <ChatItemDate
        timestamp={item.timestamp as string}
        renderedTimestamp={item.renderedTimestamp}
      />
    );
  }

  return (
    <ChatItem
      speaker={speaker}
      index={index}
      item={item.data as MessageItem}
      onVideoPress={_message => {}}
      showChatFeedback={true}
      fromScreen={
        isFromTestPrep
          ? RaiseHandScreenTypes.UnitTestQuestion
          : RaiseHandScreenTypes.StudyFeed
      }
      isFromMathTest={isFromMathTest}
    />
  );
};
