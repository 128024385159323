import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import {
  CommonApCourseGrade,
  CommonApExamsTestimonialsConfigs,
} from '../../../Common/entities';
import { APProgramDetails } from '../../entities';

import {
  loadTargetCommitmentActionExtraReducers,
  openApProgramFlowExtraReducers,
  selectApCourseTargetGradeActionExtraReducers,
  toggleApCourseAndTrackActionExtraReducers,
} from './ApOnboardingSliceActions';

const AP_ONBOARDING_SLICE_PERSIST_KEY = 'ApOnboarding';
export const AP_ONBOARDING_SLICE_NAME = 'ApOnboardingSlice';

export type ApOnboardingSliceState = {
  selectedCourses: string[]; // array of course-name
  apCourseTargetGrade: Record<string, CommonApCourseGrade>; // {[courseName}: CommonApCourseGrade}
  apProgramDetails: APProgramDetails | undefined;
  apProgramDetailsError: boolean;
  apExamTestimonials: CommonApExamsTestimonialsConfigs[];
};

export const ApOnboardingSliceInitialState: ApOnboardingSliceState = {
  selectedCourses: [],
  apCourseTargetGrade: {},
  apProgramDetails: undefined,
  apProgramDetailsError: false,
  apExamTestimonials: [],
};

const persistConfig = {
  key: AP_ONBOARDING_SLICE_PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'selectedCourses',
    'targetCommitment',
    'apCourseTargetGrade',
    'apExamTestimonials',
    'apProgramDetails',
  ],
  blacklist: [],
} as PersistConfig<ApOnboardingSliceState>;

const apOnboardingSlice = createSlice({
  name: AP_ONBOARDING_SLICE_NAME,
  initialState: ApOnboardingSliceInitialState,
  reducers: {
    resetApOnboardingState: state => {
      state.selectedCourses = [];
      state.apCourseTargetGrade = {};
      state.apProgramDetails = undefined;
      state.apProgramDetailsError = false;
    },
    setApExamTestimonials: (
      state,
      action: PayloadAction<CommonApExamsTestimonialsConfigs[] | undefined>,
    ) => {
      state.apExamTestimonials = action.payload || [];
    },
  },
  extraReducers: builder => {
    toggleApCourseAndTrackActionExtraReducers(builder);
    selectApCourseTargetGradeActionExtraReducers(builder);
    loadTargetCommitmentActionExtraReducers(builder);
    openApProgramFlowExtraReducers(builder);
  },
});

export const { resetApOnboardingState, setApExamTestimonials } =
  apOnboardingSlice.actions;

export const ApOnboardingSlice = persistReducer(
  persistConfig,
  apOnboardingSlice.reducer,
);
