import { createAsyncThunk } from '@reduxjs/toolkit';

import { incrementApLearnModeTimeSpent } from '../../../../AP/services/slices';
import { RootState } from '../../../../App/store';
import { isAPCourse } from '../../../../Common/services/utils';
import { mapCourseDataById } from '../../mappers';
import { FEED_SLICE_NAME } from '../FeedSlice';

export const countLearnModeTimeSpent = createAsyncThunk(
  `${FEED_SLICE_NAME}/countLearnModeTimeSpent`,
  async (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const courseData = mapCourseDataById(rootState, courseId);

    if (!courseData?.id) {
      return;
    }

    if (isAPCourse(courseData)) {
      thunkAPI.dispatch(incrementApLearnModeTimeSpent(courseData.id));
      return;
    }
  },
);
