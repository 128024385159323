import { AiFigureModel, GetHistoricalFigureResponse } from '../../entities';

export const mapHistoricalFigure = (
  data: GetHistoricalFigureResponse,
): AiFigureModel => {
  const bioArray = [];

  if (data.properties?.birthInformation) {
    bioArray.push(data.properties?.birthInformation);
  }

  if (data.properties?.deathInformation) {
    bioArray.push(data.properties?.deathInformation);
  }

  if (bioArray.length > 0) {
    bioArray.push('');
  }

  if (data.properties?.biography) {
    bioArray.push(data.properties?.biography);
  }

  const nameArray = [data.name];
  if (data.properties?.flags) {
    nameArray.push(data.properties.flags);
  }

  return {
    name: data.name,
    displayName: nameArray.join(' '),
    handle: data.properties?.handle || '',
    tiktokLink: data?.properties?.tiktokProfileUrl || undefined,
    platformId: data?.platformId || '',
    avatarUrl: data?.thumbnailUrl || data?.imageUrl || '',
    biography: bioArray.join('\n'),
    bio: data.properties?.bio || '',
    socialBio: data.properties?.socialBio || '',
    famousQuotes: data.properties?.famousQuotes || [],
    interests: data.properties?.interests || [],
    notableContributions: data.properties?.notableContributions || [],
    subjectsITeach: data.subjectsITeach,

    interactions: 0,
    questionsAsked: 0,
  };
};
