import { AIContentType } from '../../Common/entities';

export type CourseTopicProgress = {
  topicId: string;
  studentGrade?: string;
  nextGradeProgress?: number;
};

export type CourseUnitProgress = {
  unitId: string;
  studentGrade?: string;
};

export type RecordContentAnswerResponse = {
  currentUnitId: string;
  currentTopicId: string;
  topicProgress: CourseTopicProgress;
  unitProgress: CourseUnitProgress;
};

export type RecordContentAnswerResponseWithStandardId = {
  standardId: string;
} & RecordContentAnswerResponse;

type GetLearningAppProgressTopic = {
  id: string;
  studentGrade: string;
  nextGradeProgress: number;
};

type GetLearningAppProgressUnit = {
  id: string;
  studentGrade: string;
  currentTopicId: string;
  topics: GetLearningAppProgressTopic[];
};

type GetLearningAppProgressCourse = {
  id: string;
  currentUnitId: string;
  units: GetLearningAppProgressUnit[];
};

export type GetLearningAppProgressResponse = {
  course: GetLearningAppProgressCourse;
};

export type GetLearningAppProgressRequest = {
  courseId: string;
};

export type RecordContentAnswerRequest = {
  subject: {
    id: string;
    name: string;
  };
  course: {
    id: string;
    name: string;
  };
  unit: {
    id: string;
    grade: string | null;
  };
  topic: {
    id: string;
    grade: string | null;
  };
  levelId: string;
  activity: {
    id: string;
    correct: boolean;
    shownAt: string;
    helpShownAt?: string;
    answeredAt?: string;
    explanationShownAt?: string;
    finishedReadingExplanationAt?: string;
    numberOfAttempts: number;
    pointsAwarded?: number;
  };
};

export type TopicResponse = {
  id: string;
  name: string;
  learningOrder: number;
  progress: number;
  estimatedTimeToCompletion: {
    min: number;
    max: number;
  };
};

export type UnitResponse = {
  id: string;
  name: string;
  learningOrder: number;
  status: string;
  topics: TopicResponse[];
  hasTestPrep: boolean;
  hasLearnMode: boolean;
  category: string | null;
};

export type CourseResponse = {
  id: string;
  name: string;
  grade: {
    grade: string;
    name: string;
  };
  mainImageUrl: string;
  thumbnailUrl: string;
  learningOrder: number;
  hasTestPrep: boolean;
  hasLearnMode: boolean;
  units: UnitResponse[];
};

export type SubjectResponse = {
  id: string;
  name: string;
  image: string;
  courses: CourseResponse[];
};

export type CurriculumResponse = {
  subjects: SubjectResponse[];
};

export type GetMaxGradeRequest = {
  courseId: string;
};

export type GetMaxGradeTopic = {
  id: string;
  maxGrade: string | null;
};

export type GetMaxGradeResponse = {
  courseId: string;
  topics: GetMaxGradeTopic[];
};

export const LearningContentItemOutputGQLSchema = `
  contentId
  contentTypeId
  contentTypeName
  content
  subjectId
  courseId
  unitId
  topicId
  levelId
  difficulty

  totalLikes
  totalViews
  totalComments
  totalBookmarks
  totalShares
  totalReports

  isLikedByUser
  isSharedByUser
  isBookmarkedByUser
  isCommentedByUser
`;

export type GenerateQuestionRequest = {
  topicId: string;
  courseId: string;
  contentTypeId?: AIContentType;
  batchSize?: number;
  retryCount?: number;
};

export type GenerateQuestionResponse = {
  contentId: string;
  contentTypeId: string;
  contentTypeName: string;
  content: string;
  subjectId: string;
  courseId: string;
  unitId: string;
  topicId: string;
  levelId: string;
  difficulty: number;

  totalLikes: number;
  totalViews: number;
  totalComments: number;
  totalBookmarks: number;
  totalShares: number;
  totalReports: number;

  isLikedByUser: boolean;
  isSharedByUser: boolean;
  isBookmarkedByUser: boolean;
  isCommentedByUser: boolean;
};
