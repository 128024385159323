import { Route } from '@react-navigation/core';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { MainStack } from '../../../../Common/services/utils/AppConstants';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const closeOnboardingScreens = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/closeOnboardingScreens`,
  async () => {
    const navigation = RootNavigatorRef.current;

    if (!navigation?.isReady()) {
      return;
    }

    const earliestIndex = navigation?.getState().routes.findIndex(item => {
      return item.name === MainStack.ONBOARDING_STACK;
    });
    const targetIndex = earliestIndex - 1;

    if (targetIndex < 0) {
      return false;
    }

    navigation?.resetRoot({
      index: targetIndex,
      routes: navigation
        ?.getState()
        .routes.slice(0, targetIndex + 1) as Route<string>[],
    });

    return true;
  },
);
