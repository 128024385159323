export enum CommonApCourseGrade {
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
}

export enum CommonTeachTapCourseStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}
