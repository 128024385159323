import React from 'react';
import { StyleSheet, View } from 'react-native';

import locale from '../../../App/locale';
import { CommonActionButton } from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';

type Props = {
  onPress: () => void;
  onLaterPress: () => void;
  showLaterButton?: boolean;
};

export const EntitlementConfirmationContinue = React.memo(
  ({ onPress, showLaterButton, onLaterPress }: Props): React.ReactElement => {
    return (
      <View style={styles.container}>
        <CommonActionButton
          style={styles.actionButton}
          text={locale.common.get_started}
          onPress={onPress}
        />

        {showLaterButton && (
          <CommonActionButton
            style={styles.laterButton}
            text={locale.common.maybe_later}
            onPress={onLaterPress}
          />
        )}
      </View>
    );
  },
);

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    gap: 12,
    width: '100%',
  },
  actionButton: {
    ...Fonts.semiMedium500,
    width: '100%',
    borderRadius: Sizes.semiMedium,
    fontSize: Fonts.large.fontSize,
  },
  laterButton: {
    ...Fonts.semiMedium500,
    width: '100%',
    borderRadius: Sizes.semiMedium,
    fontSize: Fonts.large.fontSize,
    backgroundColor: Colors.white10,
    borderWidth: 1,
    borderColor: Colors.white12,
  },
});
