import { ShareOptions } from 'react-native-share';

import { pr_number } from '../../../../pr-info.json';
import locale from '../../../App/locale';
import {
  Card,
  CardAttributes,
  CardType,
  ContentCard,
  FeedType,
  QuestionStyle,
} from '../../entities';

import { isQcEnv } from './CommonUtil';
import { ENV_KEYS, environment } from './DynamicEnvironment';

const cachedIds: Record<string, boolean> = {};
const QUESTION_STYLES: QuestionStyle[] = [
  {
    backgroundColor: 'black',
    outlined: false,
    textColor: 'white',
  },
];

export const generateUniqueId = (type: CardType, id = 'none'): string => {
  const randomFactor = `${Date.now()}-${Math.round(Math.random() * 1000000)}`;
  const selectedId = `${type}-${id}-${randomFactor}`;

  if (cachedIds[selectedId]) {
    return generateUniqueId(type, id);
  }

  cachedIds[selectedId] = true;
  return selectedId;
};

export const getShuffledArray = (array: any[]): any[] => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));

    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
};

export const getLoadingCard = (feedId: FeedType): Card => ({
  feedId,
  uniqueId: generateUniqueId(CardType.EMPTY_LOADING_ITEM),
  type: CardType.EMPTY_LOADING_ITEM,
});

const getStringHash = (str: string): number => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return Math.abs(hash);
};

export const getQuestionStyle = (text: string): QuestionStyle => {
  return QUESTION_STYLES[getStringHash(text) % QUESTION_STYLES.length];
};

export const getDefaultCardAttributes = (card: Card): CardAttributes => {
  if (card.generatedContentId) {
    const contentCard = card as ContentCard;

    return {
      tapToRevealShown: false,
      visibilityStyle:
        contentCard.visibilityStyleSwitch !== undefined &&
        contentCard.showTapToReveal
          ? 'no-text'
          : 'eye',
      isMediaPaused: false,
      isContentLiked: contentCard.isLiked,
      isContentBookmarked: contentCard.isBookmarked,
      contentPaddingBottom: 0,
    };
  }

  return {
    tapToRevealShown: false,
    visibilityStyle: 'no-text',
    isMediaPaused: false,
    contentPaddingBottom: 0,
  };
};

export const getWebLink = (): string => {
  const { WEB_APP_LINK, WEB_APP_PREVIEW_LINK } = environment.getValues([
    ENV_KEYS.WEB_APP_LINK,
    ENV_KEYS.WEB_APP_PREVIEW_LINK,
  ]);
  if (isQcEnv()) {
    return WEB_APP_PREVIEW_LINK.replace('{PR_NUMBER}', pr_number);
  } else {
    return WEB_APP_LINK;
  }
};

export const getCardShareLink = (card: Card): string => {
  return (
    getWebLink() +
    (card.generatedContentId ? '/cid/' + card.generatedContentId : '')
  );
};

export const getQuestionShareOptions = (
  question: ContentCard,
): ShareOptions => {
  const url = getCardShareLink(question);

  return {
    subject: `${locale.app_name}: ${question.topic!.name}`,
    message: `${locale.home_screen_card.content_share_body.replace(
      '$CONTENT_NAME',
      question.topic!.name,
    )} ${locale.app_name}: ${url}`,
    title: question.topic!.name,
  };
};
