import { DMSpeaker, UserType } from '../../Messages/entities';
import { TestPrepQuestion } from '../../TestPrep/entities';

import { CardType } from './CommonRequestsTypes';

export enum VideoSubtitleType {
  SRT = 'SRT',
}

export type VideoSubtitles = {
  url: string;
  type: VideoSubtitleType;
};

export type Unit = {
  id: string;
  number: number;
  name: string;
  grade?: string;
  background?: string;
  isAvailable: boolean;
  learningOrder: number;
  topics: Topic[];
  hasTestPrep: boolean;
  hasLearnMode: boolean;
  category: string | null;
};

export type Topic = {
  id: string;
  name: string;
  grade?: string;
  progress: number;
  progressIndicator: number;
  learningOrder: number;
  estimatedTimeToCompletion: {
    min: number;
    max: number;
  };
};

export type Course = {
  id: string;
  name: string;
  username: string;
  units: Unit[];
  avatar: string;
  subject: string;
  subjectId: string;
  background?: string;
  grade: string;
  available: boolean;
  followedOn?: string;
  hasTestPrep: boolean;
  hasLearnMode: boolean;
};

export type QuestionStyle = {
  backgroundColor: string;
  outlined: boolean;
  textColor: string;
  outlinedColor?: string;
};

export type CardImage = {
  url: string;
};

export enum FeedType {
  Topic = 'topic',
  TestPractice = 'targetedPractice',
  Messages = 'messages',
  Favorites = 'favorites',
  TestPrep = 'testPrep',
}

export type Card = {
  feedId: FeedType;
  uniqueId: string;
  type: CardType;
  aiContentType?: string;
  typeName?: string;
  image?: CardImage;
  blurredImage?: CardImage;
  audioUrl?: string;
  ommitHeader?: boolean;
  generatedContentId?: string;
  videoUrl?: string;
  subtitleUrl?: string;
};

export type CongratulationsCard = {
  course: Course;
  unit?: Unit;
} & Card;

export type LevelMasteredCard = {
  course: Course;
  unit: Unit;
} & Card;

export type UnitWithStandard = {
  unit?: Unit;
};

export type ContentCardThumbnail = {
  id: string;
  uniqueId: string;
  type: CardType;
  typeName?: string;
  views: number;
  backgroundUrl?: string;
  text?: string;
  collageImageUrls?: string[];
  avatarUrls?: string[];
};

export type ContentCard = {
  course: Course;
  unit?: Unit;
  topic?: Topic;
  levelId?: string;
  subjectId?: string;
  generatedContentId: string;
  visibilityStyleSwitch?: boolean;
  showTapToReveal?: boolean;
  totalLikes: number;
  totalViews: number;
  totalComments: number;
  totalBookmarks: number;
  totalShares: number;
  isLiked: boolean;
  isBookmarked: boolean;
  points?: number;
  difficulty?: number;
} & Card;

export type MultipleChoiceOption = {
  id: string;
  answer: string;
  explanation: string;
};

export type MultipleChoiceQuestion = {
  options: Array<MultipleChoiceOption>;
  answer?: MultipleChoiceAnswer;
  question: string;
  question_style?: QuestionStyle;
} & ContentCard;

export type ExplanationCharacter = {
  videoUrl: string;
  subtitleUrl?: string;
  name: string;
  title: string;
};

export type CharacterInfo = {
  name: string;
  title: string;
  avatarUrl: string;
};

export type MultipleChoiceQuestionV2 = {
  options: Array<MultipleChoiceOption>;
  answer: MultipleChoiceAnswer;
  question: string;
  question_style?: QuestionStyle;
  character: ExplanationCharacter;
} & ContentCard;

export type MatchingPairsAnswerSelector = {
  left?: MatchingPairsOptions;
  right?: MatchingPairsOptions;
};

// This act as a source of truth for the ANSWER
export type MatchingPairsAnswerOptions = {
  leftOptions: MatchingPairsOptions[];
  rightOptions: MatchingPairsOptions[];
};

// Single option for each side
export type MatchingPairsOptions = {
  uniqueId: string;
  text: string;
  pairedAt?: number;
};

export type MatchingPairsAnswer = {
  answerUniqueId: string;
};

export type MatchingPairsCard = {
  title: string;

  matchFrom: string;
  matchTo: string;

  // This act as a source of truth for the CARD DETAILS
  leftOptions: MatchingPairsAnswerOptions['leftOptions'];
  rightOptions: MatchingPairsAnswerOptions['rightOptions'];

  pairsFromLeftOptions: Record<string, MatchingPairsAnswer>;
  charactersFromLeftOptions: Record<string, ExplanationCharacter>;
} & ContentCard;

export type DoubleMemeTextCard = {
  images: string[];
  texts: string[];
} & ContentCard;

export type DmsFromDeadCard = {
  character: DMSpeaker;
  question: string;
  appropriate: boolean;
  languageStyle: string;
  excuse: string;
  transcript: string;
  video: string;
  sender: UserType;
  timestamp: string;
} & ContentCard;

export type DmsFromDeadV2Card = {
  question: string;
  questionTimestamp: string;
  response: string;
  responseTimestamp: string;
} & ContentCard;

export type FillGapsTextCard = {
  text: string;
  gaps: Record<string, string>;
  hint: string;
} & ContentCard;

export type FillGapsWithHelpCard = {
  text: string;
  gaps: Record<string, string>;
  possibleAnswers: string[];
  hint: string;
  character: ExplanationCharacter;
} & ContentCard;

export type DidYouKnowCard = {
  text: string;
  character: CharacterInfo;
} & ContentCard;

export type MCQForDSATCard = {
  questionContent: TestPrepQuestion;
} & ContentCard;

export type MultipleChoiceAnswer = {
  id: number;
  correct_options: Array<MultipleChoiceOption>;
};

export type ContentVisibilityStyle = 'eye' | 'no-text';

export type CardAttributes = {
  tapToRevealShown: boolean;
  visibilityStyle: ContentVisibilityStyle;
  isMediaPaused: boolean;
  showHintVideo?: boolean;
  collapsedQuestion?: boolean;
  isMediaMuted?: boolean;
  isContentLiked?: boolean;
  isContentBookmarked?: boolean;
  contentPaddingBottom: number;

  // For matching pairs
  selectedCharacterFromLeftOptions?: string;
  shouldPlayAllCharacters?: boolean;
};

export type ControversyCharacter = {
  debaters: Array<CharacterInfo>;
  moderator?: CharacterInfo;
};

type ControversyTranscript = {
  speaker: CharacterInfo;
  dialogue: string;
};

export type ControversyVideo = {
  url: string;
  subtitles?: VideoSubtitles;
  transcript: Array<ControversyTranscript>;
};

export type ControversialConversationCard = {
  controversy: string;
  speakers: ControversyCharacter;
  video: ControversyVideo;
} & ContentCard;

export type TruthOrLieAnswer = {
  value: string;
  selection_rate: number;
};

export type TruthOrLieOption = {
  emoji: string;
  id: number;
  isCorrect: boolean;
  value: string;
  selection_rate: number;
};

export type TruthOrLieCard = {
  statement: string;
  answer: TruthOrLieAnswer;
  character: ExplanationCharacter;
  options: Array<TruthOrLieOption>;
} & ContentCard;

export type GoalItem = {
  key: string;
  value: string;
};

export enum ExamsGoals {
  CRUSH_SAT = 'I want to crush the SAT',
  CRUSH_AP_TEST = 'I want to crush the AP exam',
  JUST_EXPLORING = 'Neither, I’m just exploring',
}

export enum APCourseGoals {
  CRUSH_AP_TEST = 'I want to crush the AP test in May',
  A_IN_CLASS = 'I want an A in class',
  JUST_EXPLORING = 'Neither, I’m just exploring',
}

export enum SATCourseGoals {
  SUMMER = 'Summer',
  FALL = 'Fall',
  NEXT_YEAR = 'Next year',
  IM_NOT_SURE = "I'm not sure",
}

export enum HighSchoolCourseGoals {
  CRUSH_UPCOMING_TEST = 'I want to crush an upcoming test',
  MAYBE_TEST = 'I might take a test in the future',
  JUST_EXPLORING = 'Neither, I’m just exploring',
}

export enum CourseGoalKeys {
  CRUSH_AP_TEST = 'CRUSH_AP_TEST',
  A_IN_CLASS = 'A_IN_CLASS',
  JUST_EXPLORING = 'JUST_EXPLORING',
  CRUSH_UPCOMING_TEST = 'CRUSH_UPCOMING_TEST',
  MAYBE_TEST = 'MAYBE_TEST',
  SUMMER = 'SUMMER',
  FALL = 'FALL',
  NEXT_YEAR = 'NEXT_YEAR',
  IM_NOT_SURE = 'IM_NOT_SURE',
}

export type CommonTutorType = {
  name: string;
  biography_short: string;
  image: {
    url: string;
  };
};

export enum BetaFeedbackSource {
  SAT_TEST_PREP = 'SAT Test Prep',
  PROFILE = 'Profile',
  AP_TEST_PREP = 'AP Test Prep',
}
