import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Platform } from 'react-native';

import locale from '../../../App/locale';
import { getS3Service } from '../../../App/services/amplify/S3Service';
import { handleNetworkActionError } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { ExamsGoals } from '../../../Common/entities';
import { DeviceInfo } from '../../../Common/services/utils/polyfills';
import {
  AppFeedbackData,
  ExamResultsFeedbackData,
  ExamResultsFeedbackDetails,
} from '../../entities';
import {
  mapExamResultsFeedbackDataToFormRequest,
  mapFeedbackDataToFormPayload,
} from '../mappers';
import {
  submitAppFeedbackCall,
  submitExamResultsFeedbackCall,
} from '../requests';

const SLICE_NAME = 'FeedbackFormSlice';

type State = {
  loading: boolean;
  error: string | null;
  message: string | null;
  success: boolean;
};

const initialState: State = {
  loading: false,
  error: null,
  message: null,
  success: false,
};

export const submitAppFeedbackForm = createAsyncThunk(
  `${SLICE_NAME}/submitAppFeedbackForm`,
  async (formData: AppFeedbackData, thunkAPI) => {
    const mediaLink = formData.isMediaAttached
      ? await getS3Service().uploadFeedbackMedia(formData.imageVideoLink!)
      : '';
    const formBody = mapFeedbackDataToFormPayload({
      ...formData,
      imageVideoLink: mediaLink ?? '',
    });
    try {
      const response = await submitAppFeedbackCall(formBody);

      if (!response || !response.data) {
        throw new Error(locale.errors.no_data_error);
      }
      return { message: locale.feedback_screen.form_submitted };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkAPI.rejectWithValue(error?.message);
      }
    }
  },
);

export const submitExamResultsFeedbackForm = createAsyncThunk(
  `${SLICE_NAME}/submitExamResultsFeedbackForm`,
  async (props: ExamResultsFeedbackDetails, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const loggedInUser = rootState.auth.authUser;
    const userGoal = rootState.lifecycle.userGoal;

    const formData: ExamResultsFeedbackData = {
      feedback: props.feedback || '',
      zoomConsent: props.zoomConsent,

      appVersion: DeviceInfo.getVersion(),
      deviceType: DeviceInfo.getDeviceType(),
      deviceModel: DeviceInfo.getModel(),
      deviceOS: `${Platform.OS} ${DeviceInfo.getSystemVersion()}`,

      userName: loggedInUser?.preferredName || '',
      email: loggedInUser?.email || '',
      appGoal: ExamsGoals[userGoal.goal as keyof typeof ExamsGoals] || '',
    };

    const formBody = mapExamResultsFeedbackDataToFormRequest(formData);
    try {
      const response = await submitExamResultsFeedbackCall(formBody);

      if (!response || !response.data) {
        throw new Error(locale.errors.no_data_error);
      }
      return { message: locale.feedback_screen.form_submitted };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkAPI.rejectWithValue(error?.message);
      }
    }
  },
);

const feedbackFormSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetFeedbackFormState(state: State) {
      state.loading = false;
      state.error = null;
      state.message = null;
      state.success = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(submitAppFeedbackForm.pending, state => {
        state.loading = true;
        state.error = null;
        state.message = null;
        state.success = false;
      })
      .addCase(submitAppFeedbackForm.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.message = action.payload?.message ?? null;
      })
      .addCase(submitAppFeedbackForm.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export const { resetFeedbackFormState } = feedbackFormSlice.actions;

export const FeedbackFormSlice = feedbackFormSlice.reducer;
