import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { throttle } from '../../../Common/services/utils';
import { TrackUserEventRequest } from '../../entities';

export const trackUserEventGraphQLCall = async (
  request: TrackUserEventRequest,
): Promise<void> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
      mutation TeachTapTrackUserEvent($input: TeachTapTrackUserEventInput!) {
        teachTapTrackUserEvent(input: $input) {
          success
        }
      }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );

  handleGraphQLError(response);
  return response.data?.data;
};

export const throttledTrackUserEventGraphQLCall = throttle(
  trackUserEventGraphQLCall,
  30000,
);
