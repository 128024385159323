import React, { useMemo } from 'react';
import { StyleSheet, TextStyle, View, ViewStyle } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import { ProgressCalculationAlgorithm } from '../../algorithms';
import { LearnAssets } from '../../assets';

type Props = {
  backgroundColor: string;
  color: string;
  starHeight?: number;
  studentGrade?: string;
  viewStyle?: ViewStyle;
  textStyle?: TextStyle;
};

export const GradeWithStars = (props: Props): React.ReactElement => {
  const { backgroundColor, viewStyle, studentGrade, starHeight } = props;

  const selectedAssets = useMemo(() => {
    const { stars } = ProgressCalculationAlgorithm.getStudyGradeFromStep(
      studentGrade ?? '',
    );

    if (stars === 2) {
      return LearnAssets.star2;
    } else if (stars === 3) {
      return LearnAssets.star3;
    }

    return LearnAssets.star1;
  }, [studentGrade]);

  return (
    <View style={[styles.circle, { backgroundColor }, viewStyle]}>
      <AssetImage asset={selectedAssets} height={starHeight} />
    </View>
  );
};

const styles = StyleSheet.create({
  circle: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
