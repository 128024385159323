import LogRocket from '@logrocket/react-native';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react-native';

import { getAuthService } from '../../../../App/services/amplify/AuthService';
import { syncAnalyticsAndStartAppsFlyer } from '../../../../App/services/slices';
import { getMixpanelTracker } from '../../../../App/services/trackers';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  trackAnalyticsEvent,
  updateUserProperties,
} from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { UpdateUserIdentifierPayload } from '../../../entities';
import { mapUserSignupData } from '../../mappers';
import { AUTH_SLICE_NAME } from '../AuthSlice';

import { updateMixpanelUserProfile } from './UpdateMixpanelUserProfileAction';

export const updateUserIdentifier = createAsyncThunk(
  `${AUTH_SLICE_NAME}/updateUserIdentifier`,
  async (props: UpdateUserIdentifierPayload, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const onboardingState = rootState.onboarding;
      const signupState = rootState.signup;
      const profileState = rootState.profile;

      const userSignupData = mapUserSignupData({
        examType: onboardingState.examType,
        emailOptIn: profileState.marketingConsent,
        studentGrade: signupState.grade,
        SATProgramDetails: onboardingState.SATProgramDetails,
      });

      if (props.isFromSignUp) {
        // This only happens when user signs-up, and they successfully authenticated.
        // At this stage, we can safely assume the user is authenticated already
        // reason: we want to track if the user signed-up ASAP
        trackAnalyticsEvent(Analytics.signUp, userSignupData);
      }

      const userId = await getAuthService().getCurrentUserId();
      const user = (thunkApi.getState() as RootState).auth.authUser;

      if (user) {
        Sentry.setUser({
          id: userId,
          email: user.email,
          username: user.preferredName,
        });

        if (props.updateAlias) {
          LogRocket.identify(userId, {
            email: user.email,
          });
        }

        getMixpanelTracker()
          .identify(userId)
          .then(() => thunkApi.dispatch(syncAnalyticsAndStartAppsFlyer()));
        updateUserProperties({
          email: user.email,
          name: user.preferredName,
        });

        thunkApi.dispatch(updateMixpanelUserProfile());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);
