export type SATBootcampAnalytics = {
  examDate?: string;
  bootcampName?: string;
  bootcampPeriod?: string;
  bootcampPeriodCount?: number;
  bootcampDuration?: string;
  currentScore?: number;
  targetScore?: number;
  timeCommitment?: string;
  studyCommitmentHours?: number;
};

export type SATBootcampAnalyticsWithTimeTrack = SATBootcampAnalytics & {
  currentBootcampPeriod: number;
  studyHoursSpent: number;
};

export enum EntitlementAnalyticsType {
  PURCHASE = 'purchase',
  FREE_TRIAL = 'free_trial',
}
