import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useMemo } from 'react';
import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import locale from '../../App/locale';
import { StudyNavigationProps } from '../../App/navigation/bottomTabs/StudyStackNavigator';
import { Course, Unit } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
  StudyStack,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { LearnAssets } from '../../Learn/assets';
import TopicFeedHeader from '../../Learn/components/courses/TopicFeedHeader';
import { useTestDetailNavigator } from '../services/hooks';

import { TestPreviewOrigin } from './TestPreviewScreen';

type CustomAnimationProps = {
  containerStyle?: StyleProp<any>;
  imageStyle?: StyleProp<any>;
  image: ImageSourcePropType;
  isImageContained?: boolean;
};

const CustomAnimation = (props: CustomAnimationProps) => {
  return (
    <View style={props.containerStyle}>
      <Image
        style={props.imageStyle}
        resizeMode={props.isImageContained ? 'contain' : 'cover'}
        source={props.image}
      />
    </View>
  );
};

export type TestPracticeGapsBridgedScreenProps = {
  course: Course;
  unit: Unit;
};

type ScreenProps = {
  route: { params: TestPracticeGapsBridgedScreenProps };
};

export const TestPracticeGapsBridgedScreen = (
  props: ScreenProps,
): React.ReactElement => {
  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const { course, unit } = props.route.params;

  const navigation =
    useNavigation<StudyNavigationProps<StudyStack.TOPIC_COMPLETION_SCREEN>>();

  const { handleOpenTestScreen } = useTestDetailNavigator();

  const completionMessage = useMemo(() => {
    return locale.testPrep.practice_completion_message.replace(
      '${UNIT_NAME}',
      `Unit ${unit.number}: ${unit.name}`,
    );
  }, [unit]);

  const onBackToCoursePress = useCallback(() => {
    navigation.popToTop();
  }, [navigation]);

  const onTakeAnotherTestPress = useCallback(() => {
    handleOpenTestScreen(
      course,
      unit,
      TestPreviewOrigin.TARGETED_PRACTICE_COMPLETION,
    );
  }, [course, handleOpenTestScreen, unit]);

  const TopicAnimation = React.useCallback(() => {
    return (
      <CustomAnimation
        containerStyle={styles.gifContainer}
        imageStyle={styles.gif}
        image={LearnAssets.topicCompletionGif.path as ImageSourcePropType}
      />
    );
  }, []);

  const BullseyeArrowAnimation = React.useCallback(() => {
    return (
      <CustomAnimation
        containerStyle={styles.bullseyeContainer}
        imageStyle={styles.bullseyeGif}
        image={Assets.test.bullseyeArrowGif.path as ImageSourcePropType}
        isImageContained
      />
    );
  }, []);

  return (
    <LinearGradient
      style={styles.contentContainer}
      colors={Colors.screenGradient}>
      <View style={styles.subContainer}>
        <View style={styles.messageContainer}>
          <Text style={styles.completionHeading}>
            {locale.testPrep.gaps_bridged}
          </Text>
          <Text style={styles.completionMessageText}>
            <Text style={styles.completionMessageHighlight}>
              {completionMessage}
            </Text>
          </Text>
        </View>
        <TopicAnimation />
        <BullseyeArrowAnimation />
      </View>
      <View style={styles.actionButtonContainer}>
        <TouchableOpacity
          style={styles.takeAnotherTestContainer}
          onPress={onTakeAnotherTestPress}>
          <Text style={styles.takeAnotherTestText}>
            {locale.testPrep.take_another_practice_test}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            styles.backToCourseContainer,
            { marginBottom: safeAreaBottom },
          ]}
          onPress={onBackToCoursePress}>
          <Text style={styles.keepStudyingText}>
            {locale.testPrep.back_to_course}
          </Text>
        </TouchableOpacity>
      </View>
      <TopicFeedHeader
        course={course}
        unit={unit}
        isForTopicCompletion={true}
        isForTestCompletion={true}
      />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  bullseyeContainer: {
    width: '100%',
    height: '65%',
    position: 'absolute',
    top: 0,
    alignItems: 'center',
  },
  bullseyeGif: {
    width: '60%',
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: Sizes.normalizeIP14PM(10),
    justifyContent: 'space-between',
  },
  keepStudyingText: {
    ...Fonts.large,
  },
  completionMessageHighlight: {
    ...Fonts.normalize(Fonts.captionBold),
    color: Colors.white70,
    textAlign: 'center',
  },
  completionHeading: {
    ...Fonts.normalize(Fonts.xxxxlargeBold),
  },
  completionMessageText: {
    ...Fonts.normalize(Fonts.caption),
    color: Colors.white70,
    marginBottom: Sizes.normalizeIP14PM(10),
    marginHorizontal: Sizes.normalizeIP14PM(20),
    textAlign: 'center',
  },
  subContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    top: Sizes.normalizeIP14PM(50),
  },
  messageContainer: {
    paddingVertical: Sizes.normalizeIP14PM(10),
    rowGap: Sizes.normalizeIP14PM(5),
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonContainer: {
    width: '95%',
    justifyContent: 'flex-end',
    rowGap: Sizes.normalizeIP14PM(12),
  },
  takeAnotherTestContainer: {
    backgroundColor: Colors.lightBlue,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
  },
  takeAnotherTestText: {
    ...Fonts.large,
  },
  backToCourseContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    height: 44,
    borderWidth: 1,
    borderColor: Colors.white12,
  },
  gifContainer: {
    width: '100%',
    height: '60%',
    position: 'absolute',
    top: 0,
  },
  gif: {
    width: '100%',
    height: '100%',
  },
});
