import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { AP_PAYWALL_FEATURES, SAT_BOOTCAMP_PAYWALL_FEATURES } from '../../data';
import { PaywallFeatureType } from '../../entities';

type Props = {
  paywallFeatureType: PaywallFeatureType;
};

export const BootcampPaywallFeatures = ({
  paywallFeatureType,
}: Props): React.ReactElement => {
  const features =
    paywallFeatureType === PaywallFeatureType.AP
      ? AP_PAYWALL_FEATURES
      : SAT_BOOTCAMP_PAYWALL_FEATURES;
  return (
    <View style={styles.featuresSection}>
      {features.map((feature, index) => (
        <View style={styles.feature} key={index}>
          <View style={styles.featureCheckContainer}>
            <View style={styles.checkBoxFill} />
            <AssetImage
              asset={Assets.courses.progressTick}
              height={20}
              fill="white"
            />
          </View>

          <Text style={styles.featureText}>{feature}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  featuresSection: {
    backgroundColor: Colors.white10,
    padding: Sizes.semiMedium,
    borderRadius: 10,
    rowGap: Sizes.medium,
  },
  feature: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  featureCheckContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  featureText: {
    ...Fonts.semiMedium500,
    marginLeft: 4,
    flex: 1,
  },

  checkBoxFill: {
    backgroundColor: 'white',
    width: 18,
    height: 18,
    borderRadius: 10,
    position: 'absolute',
    left: 1,
  },
});
