import { TouchableOpacity } from '@gorhom/bottom-sheet';
import React, { useMemo } from 'react';
import { Text, StyleSheet, ActivityIndicator } from 'react-native';

import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { TutorialNextButtonType } from '../entities';

type NextButtonProps = {
  isFullWidth?: boolean;
  onPress: () => void;
  loading?: boolean;
  type?: TutorialNextButtonType;
};

export const TutorialNextButton = ({
  onPress,
  isFullWidth,
  loading,
  type = TutorialNextButtonType.NEXT,
}: NextButtonProps): React.ReactElement => {
  const buttonText = useMemo(() => {
    return type === TutorialNextButtonType.NEXT
      ? locale.common.next
      : locale.common.get_started;
  }, [type]);

  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={loading}
      style={[styles.buttonContainer, isFullWidth && styles.fullWidth]}>
      {loading ? (
        <ActivityIndicator color="white" />
      ) : (
        <Text style={styles.buttonText}>{buttonText}</Text>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    right: Sizes.large,
    bottom: Sizes.large,
    paddingVertical: Sizes.small,
    paddingHorizontal: Sizes.semiMedium,
    minWidth: Sizes.normalizeIP14PM(80),
    backgroundColor: Colors.buttonGreen,
    borderRadius: Sizes.small,
    height: Sizes.normalizeIP14PM(40),
  },
  buttonText: {
    ...Fonts.caption,
    color: Colors.grayscale[0],
  },
  fullWidth: {
    width: '90%',
  },
});
