import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { CommonExamType } from '../../Common/entities';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Durations,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { setExamType } from '../services/slices';

type Props = {
  onExamSelected: (examType: CommonExamType) => void;
};

export const OnboardingExamItems = (props: Props): React.ReactElement => {
  const { onExamSelected } = props;
  const dispatch = useAppDispatch();

  const selectedExamType = useAppSelector(state => state.onboarding.examType);

  const handlePressItem = useCallback(
    async (examType: CommonExamType) => {
      dispatch(setExamType(examType));
      trackAnalyticsEvent(Analytics.examSelected, {
        examType,
      });

      await new Promise(resolve =>
        setTimeout(resolve, Durations.quickTransition),
      );
      onExamSelected(examType);
    },
    [dispatch, onExamSelected],
  );

  const buttonStyles = useMemo(() => {
    return {
      AP: [
        styles.itemContainer,
        selectedExamType === CommonExamType.AP && styles.itemSelectedContainer,
      ],
      SAT: [
        styles.itemContainer,
        selectedExamType === CommonExamType.SAT && styles.itemSelectedContainer,
      ],
    };
  }, [selectedExamType]);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={buttonStyles.SAT}
        onPress={() => handlePressItem(CommonExamType.SAT)}>
        <Text style={styles.itemText}>{CommonExamType.SAT}</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={buttonStyles.AP}
        onPress={() => handlePressItem(CommonExamType.AP)}>
        <Text style={styles.itemText}>{CommonExamType.AP}</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    gap: Sizes.medium,
    maxWidth: 360,
    flexDirection: 'row',
  },

  itemContainer: {
    height: 160,
    backgroundColor: Colors.white08,
    borderRadius: Sizes.semiMedium,
    borderWidth: 1,
    flex: 1,
    borderColor: Colors.white16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemSelectedContainer: {
    backgroundColor: Colors.actionGreen30,
  },
  itemText: {
    ...Fonts.xlargeBold,
    color: 'white',
    textAlign: 'center',
  },
});
