import React, { useEffect } from 'react';
import { Dimensions, Platform, StyleSheet, View } from 'react-native';
import { requestTrackingPermission } from 'react-native-tracking-transparency';

import { useAppSelector } from '../../../App/services/hooks';
import { addSeconds } from '../../../App/services/slices/TimerSlice';
import { useAppDispatch } from '../../../App/store';
import { FeedType } from '../../../Common/entities';
import { useEffectOnForeground } from '../../../Common/services/hooks';
import { setTrackingPermission } from '../../../Common/services/slices/SettingsSlice';
import { Colors, Sizes } from '../../../Common/services/utils/AppConstants';
import { useIsFocused } from '../../../Common/services/utils/polyfills';
import { setSearchMode } from '../../services/slices';

import FeedPage from './page/FeedPage';
import SliderHeader from './SliderHeader';

export const HomeScreenWeb = (): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [tab] = React.useState<number>(1);

  const isFocused = useIsFocused();

  const searchVisible = useAppSelector(state => state.questions.searchMode);

  const following = useAppSelector(state => state.courseEnrollments.following);

  const feed = useAppSelector(state => state.questions.feeds[FeedType.Topic]);

  const trackingResult = useAppSelector(
    state => state.settings.trackingPermission,
  );

  useEffect(() => {
    if (trackingResult === undefined && Platform.OS === 'ios') {
      requestTrackingPermission().then(result => {
        dispatch(setTrackingPermission(result));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feed]);

  useEffectOnForeground(() => {
    const interval = setInterval(() => {
      dispatch(addSeconds(1));
    }, 1000);
    return () => clearInterval(interval);
  }, [dispatch]);

  const onSearchPress = (): void => {
    if (!searchVisible || following.length > 0) {
      dispatch(setSearchMode(!searchVisible));
    }
  };

  return (
    <View
      style={styles.container}
      testID="app-root"
      accessibilityLabel="app-root">
      <FeedPage
        tabTag={FeedType.Topic}
        isVisible={isFocused}
        isSearchVisible={searchVisible}
        onSearchPress={onSearchPress}
        itemHeight={Dimensions.get('window').height - Sizes.tabs.height!}
      />
      <SliderHeader
        tab={tab}
        onSearchPress={onSearchPress}
        onTabChange={() => {}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.appBackground,
    flex: 1,
    width: Sizes.getAppWindowWidth(),
  },
});
