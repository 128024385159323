import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ImageSourcePropType,
  ScrollView,
  Text,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import Assets from '../../../../App/assets';
import { useAppSelector } from '../../../../App/services/hooks';
import {
  MatchingPairsCard,
  MatchingPairsOptions,
} from '../../../../Common/entities';
import { getMatchingAnswer } from '../../../services/slices';
import SmallHintButton from '../SmallHintButton';

import {
  InitialLayoutHandlerOutput,
  useAnimationsState,
  useMatchingPairsOptionsHandler,
  useMatchingPairsOptionsInitialLayoutHandler,
} from './hooks';
import { MatchingStyles, styles } from './styles';

type Props = {
  item: MatchingPairsCard;
  currentPlayedVideo: string | null;
  onHintPress: (characterUniqueId: string) => void;
  onAnswer: (option: string, isCorrect: boolean) => void;
  onAllOptionsPaired: () => void;
};

type FeedbackAnimationProps = {
  showFeedback: boolean; // show feedback animation
  correct: boolean; // correct answer
  isSelected: boolean; // selected option
  maxHeight: number; // max height of the option
};

const FeedbackAnimation = ({
  showFeedback,
  correct,
  isSelected,
  maxHeight,
}: FeedbackAnimationProps) => {
  const [showThumbs, setShowThumbs] = useState(false);
  const timeoutRef = React.useRef<number | NodeJS.Timeout | null>(null);

  const showThumbsUp = useMemo(() => {
    return showThumbs && correct;
  }, [showThumbs, correct]);

  const showThumbsDown = useMemo(() => {
    return showThumbs && !correct;
  }, [showThumbs, correct]);

  const handleShowThumbs = useCallback((shown: boolean) => {
    if (shown) {
      timeoutRef.current = setTimeout(() => {
        setShowThumbs(true);
      }, 400);
    } else {
      clearTimeout(timeoutRef.current as number);
      setShowThumbs(false);
    }
  }, []);

  useEffect(() => {
    handleShowThumbs(showFeedback);
  }, [showFeedback, handleShowThumbs]);

  if (!showFeedback || !isSelected) {
    return null;
  }

  return (
    <Animated.View
      entering={FadeIn.duration(300)}
      exiting={FadeOut.duration(400)}
      style={[
        styles.overlayContainer,
        !correct ? styles.incorrectOverlayContainer : null,
      ]}>
      {showThumbsUp ? (
        <Animated.Image
          entering={FadeIn.duration(300)}
          exiting={FadeOut.duration(400)}
          source={Assets.home.correctAnswerGif.path as ImageSourcePropType}
          resizeMode="cover"
          style={MatchingStyles.thumbs(maxHeight)}
        />
      ) : null}
      {showThumbsDown ? (
        <Animated.Image
          entering={FadeIn.duration(300)}
          exiting={FadeOut.duration(400)}
          source={Assets.home.wrongAnswerGif.path as ImageSourcePropType}
          resizeMode="cover"
          style={[
            MatchingStyles.thumbs(maxHeight),
            MatchingStyles.thumbsDown(maxHeight),
          ]}
        />
      ) : null}
    </Animated.View>
  );
};

type ItemOptionProps = {
  onSelectOption: (options: MatchingPairsOptions) => void;
  onHintPress: (characterUniqueId: string) => void;
  onItemLayout: InitialLayoutHandlerOutput['handleOnItemLayout'];
  isLeftOption: boolean;
  currentPlayedVideo: string | null;
  data: MatchingPairsOptions;
  selectedOption?: MatchingPairsOptions;
  showAnimation: boolean;
  correctAnimation: boolean;
  maxHeight?: number;
  animationStyle: ViewStyle;
};

const ItemOption = ({
  onSelectOption,
  isLeftOption,
  onHintPress,
  currentPlayedVideo,
  selectedOption,
  showAnimation,
  correctAnimation,
  onItemLayout,
  maxHeight,
  data,
  animationStyle,
}: ItemOptionProps) => {
  const optionUniqueId = useMemo(() => {
    return `${isLeftOption ? 'left' : 'right'}-${data.uniqueId}`;
  }, [data.uniqueId, isLeftOption]);

  const itemTextContainerStyle = useMemo(() => {
    return isLeftOption
      ? styles.itemTextContainer
      : styles.itemRightTextContainer;
  }, [isLeftOption]);

  return (
    <TouchableWithoutFeedback onPress={() => onSelectOption(data)}>
      <Animated.View
        onLayout={e => onItemLayout(e, optionUniqueId)}
        style={[
          styles.itemContainer,
          MatchingStyles.heightHandler(maxHeight),
          animationStyle,
        ]}>
        {isLeftOption && (
          <View style={styles.helpContainer}>
            <SmallHintButton
              onHintPress={() => onHintPress(data.uniqueId)}
              blinkHighlight={data.uniqueId === currentPlayedVideo}
            />
          </View>
        )}

        <FeedbackAnimation
          showFeedback={showAnimation}
          correct={correctAnimation}
          isSelected={selectedOption?.uniqueId === data.uniqueId}
          maxHeight={maxHeight || 0}
        />

        <View style={itemTextContainerStyle}>
          <Text style={styles.itemText}>{data.text}</Text>
        </View>
      </Animated.View>
    </TouchableWithoutFeedback>
  );
};

export default ({
  item,
  currentPlayedVideo,
  onHintPress,
  onAnswer,
  onAllOptionsPaired,
}: Props): React.ReactElement => {
  const answers = useAppSelector(state => {
    return (
      getMatchingAnswer(state.answer, item) || {
        leftOptions: [],
        rightOptions: [],
      }
    );
  });

  const { handleOnItemLayout, maxHeight, scrollViewRef } =
    useMatchingPairsOptionsInitialLayoutHandler();

  const {
    left,
    right,
    showAnimation,
    correctAnimation,
    handleSelectRightOption,
    handleSelectLeftOption,
  } = useMatchingPairsOptionsHandler({
    onAnswer,
    answers,
    item,
  });

  useEffect(() => {
    const isAllLeftOptionsPaired = answers.leftOptions.every(
      option => option.pairedAt,
    );
    const isAllRightOptionsPaired = answers.rightOptions.every(
      option => option.pairedAt,
    );

    if (isAllLeftOptionsPaired && isAllRightOptionsPaired) {
      onAllOptionsPaired();
    }
  }, [answers, onAllOptionsPaired]);

  const leftAnimations = answers.leftOptions.map(option =>
    useAnimationsState(
      !!left?.uniqueId && left?.uniqueId === option?.uniqueId,
      showAnimation,
      !!option.pairedAt,
    ),
  );

  const rightAnimations = answers.rightOptions.map(option =>
    useAnimationsState(
      !!right?.uniqueId && right?.uniqueId === option?.uniqueId,
      showAnimation,
      !!option.pairedAt,
    ),
  );

  return (
    <ScrollView
      scrollEnabled
      ref={scrollViewRef}
      style={styles.container}
      bounces={false}>
      <View style={styles.pairsContainer}>
        <View style={styles.itemsContainer}>
          {answers.leftOptions.map((option, index) => {
            return (
              <ItemOption
                key={`left-${option.uniqueId}`}
                onSelectOption={handleSelectLeftOption}
                onHintPress={onHintPress}
                onItemLayout={handleOnItemLayout}
                isLeftOption
                currentPlayedVideo={currentPlayedVideo}
                data={option}
                showAnimation={showAnimation}
                correctAnimation={correctAnimation}
                maxHeight={maxHeight}
                animationStyle={leftAnimations[index]}
                selectedOption={left}
              />
            );
          })}
        </View>

        <View style={styles.itemsContainer}>
          {answers.rightOptions.map((option, index) => {
            return (
              <ItemOption
                key={`right-${option.uniqueId}`}
                onSelectOption={handleSelectRightOption}
                onHintPress={onHintPress}
                onItemLayout={handleOnItemLayout}
                isLeftOption={false}
                currentPlayedVideo={currentPlayedVideo}
                data={option}
                showAnimation={showAnimation}
                correctAnimation={correctAnimation}
                maxHeight={maxHeight}
                animationStyle={rightAnimations[index]}
                selectedOption={right}
              />
            );
          })}
        </View>
      </View>
    </ScrollView>
  );
};
