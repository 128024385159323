import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import { getAppDispatch } from '../../../App/services/utils';
import { mapDataToUnitTestPayload } from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  ScreenNames,
  TabNames,
} from '../../../Common/services/utils/AppConstants';
import { RedirectToCompletionScreensFromTestPrepParams } from '../../entities';
import { setCurrentTestResultsSeen } from '../slices';

export const redirectToMilestoneAchievedFromTestPrep = (
  params: RedirectToCompletionScreensFromTestPrepParams,
): void => {
  const { course, unit } = params;
  if (!RootNavigatorRef.current?.isReady()) {
    return;
  }

  if (!course?.id || !unit?.id) {
    return;
  }

  RootNavigatorRef.current?.reset({
    index: 1,
    routes: [
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.TEST,
          params: {
            screen: ScreenNames.TestStack.TEST_PREP_LIST,
          },
        },
      },
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.TEST,
          params: {
            screen: ScreenNames.TestStack.MILESTONE_ACHIEVED_TEST_SCREEN,
            params: {
              course,
              unit,
              isFromPracticeTest: true,
            },
          },
        },
      },
    ],
  });
};

export const redirectToTestPracticeCompletion = (
  params: RedirectToCompletionScreensFromTestPrepParams,
): void => {
  const { course, unit } = params;
  if (!RootNavigatorRef.current?.isReady()) {
    return;
  }

  if (!course?.id || !unit?.id) {
    return;
  }

  getAppDispatch()(setCurrentTestResultsSeen(true));
  trackAnalyticsEvent(Analytics.completeTargetedPractice, {
    ...mapDataToUnitTestPayload(course, unit),
  });

  RootNavigatorRef.current?.reset({
    index: 1,
    routes: [
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.TEST,
          params: {
            screen: ScreenNames.TestStack.TEST_PREP_LIST,
          },
        },
      },
      {
        name: ScreenNames.MainStack.TEST_PRACTICE_GAPS_BRIDGED_SCREEN,
        params: {
          course,
          unit,
        },
      },
    ],
  });
};
