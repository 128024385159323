import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { getIsAppActive } from '../../../../Common/services/utils';
import { mapApProgramDetailsAvailableStatus } from '../../mappers';
import { AP_SLICE_NAME, setApTestPrepTimeSpentSeconds } from '../APSlice';

export const incrementApTestPrepTimeSpent = createAsyncThunk(
  `${AP_SLICE_NAME}/incrementApTestPrepTimeSpent`,
  (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const apState = rootState.AP;

    const isProgramAvailable = mapApProgramDetailsAvailableStatus(rootState);
    const isAppActive = getIsAppActive();

    if (!isProgramAvailable || !courseId || !isAppActive) {
      return;
    }

    const nextTimeSpent = (apState.testPrepTimeSpentSeconds[courseId] || 0) + 1;

    thunkAPI.dispatch(
      setApTestPrepTimeSpentSeconds({
        courseId,
        timeSpentSeconds: nextTimeSpent,
      }),
    );
  },
);
