import { useCallback } from 'react';

import { useAppNavigation } from '../../../App/services/hooks';
import { useTestPrepDefaultTabSelector } from '../../../TestPrep/services/hooks';
import { BottomTabNavigatorType } from '../../entities';
import { MainStack, TabNames } from '../utils/AppConstants';

export const useBottomTabNavigator = (): BottomTabNavigatorType => {
  const navigation = useAppNavigation();
  const shouldSelectTestPrepTab = useTestPrepDefaultTabSelector();

  const navigate = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: MainStack.BOTTOM_TABS,
          params: {
            screen: shouldSelectTestPrepTab ? TabNames.TEST : TabNames.HOME,
          },
        },
      ],
    });
  }, [navigation, shouldSelectTestPrepTab]);

  return { navigate };
};
