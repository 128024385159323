import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Dimensions,
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

const TIME_TO_NEXT_SLIDE = 3000;

const ITEM_WIDTH = Dimensions.get('window').width - Sizes.normalizeIP14PM(40);

export const PaywallFeatureList = (): React.ReactElement => {
  const [isRetracted, setIsRetracted] = useState(false);
  const [scrollViewTouchedAt, setScrollViewTouchedAt] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);

  const scrollViewRef = useRef<ScrollView>(null);

  const PAYWALL_FEATURES = useMemo(
    () => [
      {
        key: 'studyGuides',
        title: locale.paywall.paywall_heading_1,
        description: locale.paywall.paywall_description_1_hs,
      },
      {
        key: 'aiTutors',
        title: locale.paywall.paywall_heading_2,
        description: locale.paywall.paywall_description_2,
      },
      {
        key: 'bookPrice',
        title: locale.paywall.paywall_heading_3,
        description: locale.paywall.paywall_description_3,
      },
    ],
    [],
  );

  const onLayout = (event: LayoutChangeEvent) => {
    const height = event.nativeEvent.layout.height;

    if (height / Dimensions.get('window').height > 0.38) {
      setIsRetracted(true);
    }
  };

  useEffect(() => {
    if (isRetracted) {
      const interval = setInterval(() => {
        if (!scrollViewTouchedAt) {
          const indexToSet = (currentItem + 1) % (PAYWALL_FEATURES.length + 1);

          setCurrentItem(indexToSet);

          if (indexToSet < PAYWALL_FEATURES.length) {
            scrollViewRef.current?.scrollTo({
              x: indexToSet * (ITEM_WIDTH + Sizes.normalizeIP14PM(20)),
              animated: true,
            });
          }
        }
      }, TIME_TO_NEXT_SLIDE);
      return () => clearInterval(interval);
    }
  }, [currentItem, isRetracted, scrollViewTouchedAt, PAYWALL_FEATURES]);

  if (isRetracted) {
    return (
      <ScrollView
        ref={scrollViewRef}
        onTouchStart={() => setScrollViewTouchedAt(Date.now())}
        snapToInterval={ITEM_WIDTH + Sizes.normalizeIP14PM(20)}
        decelerationRate={0}
        snapToAlignment={'center'}
        contentContainerStyle={styles.retractedScrollContainer}
        showsHorizontalScrollIndicator={false}
        horizontal>
        {PAYWALL_FEATURES.map(feature => (
          <View style={styles.featureListContainer} key={feature.key}>
            <View key={feature.key} style={styles.featureItem}>
              <View style={styles.featureIconContainer}>
                <View style={styles.tickBackground} />
                <AssetImage
                  asset={Assets.courses.progressTick}
                  height={20}
                  fill="white"
                />
              </View>
              <View style={styles.featureTexts}>
                <Text style={styles.featureTitle}>{feature.title}</Text>
                <Text style={styles.featureDescription}>
                  {feature.description}
                </Text>
              </View>
            </View>
          </View>
        ))}
      </ScrollView>
    );
  }

  return (
    <View style={styles.featureListContainer} onLayout={onLayout}>
      {PAYWALL_FEATURES.map(feature => (
        <View key={feature.key} style={styles.featureItem}>
          <View style={styles.featureIconContainer}>
            <View style={styles.tickBackground} />
            <AssetImage
              asset={Assets.courses.progressTick}
              height={20}
              fill="white"
            />
          </View>
          <View style={styles.featureTexts}>
            <Text style={styles.featureTitle}>{feature.title}</Text>
            <Text style={styles.featureDescription}>{feature.description}</Text>
          </View>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  retractedScrollContainer: {
    flexDirection: 'row',
    columnGap: Sizes.normalizeIP14PM(20),
    paddingHorizontal: Sizes.normalizeIP14PM(20),
    height: Sizes.normalizeIP14PM(200),
  },
  featureListContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.white12,
    paddingVertical: Sizes.normalizeIP14PM(10),
    marginTop: 20,
    width: ITEM_WIDTH,
  },
  featureItem: {
    flexDirection: 'row',
    paddingHorizontal: Sizes.normalizeIP14PM(10),
    paddingVertical: Sizes.normalizeIP14PM(8),
  },
  tickBackground: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: 100,
    width: 18,
    height: 18,
  },
  featureIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 24,
    height: 24,
  },
  featureTexts: {
    marginStart: 4,
    paddingEnd: 10,
    flex: 1,
  },
  featureTitle: {
    ...Fonts.mediumBold,
    marginBottom: 4,
  },
  featureDescription: {
    ...Fonts.caption,
    color: Colors.white80,
  },
});
