import { isWebPlatform } from './AppConstants';
import { isProductionEnv } from './CommonUtil';
import { Share } from './polyfills';

interface LogContext {
  screenName: string;
  componentName: string;
}

enum LogLevel {
  LOG = 'log',
  ERROR = 'error',
  WARN = 'warn',
  DEBUG = 'debug',
}

interface Logger {
  log(message: string, context?: LogContext, level?: LogLevel): Promise<void>;
  debug(message: string, context?: LogContext): Promise<void>;
  error(message: string, context?: LogContext): Promise<void>;
  warn(message: string, context?: LogContext): Promise<void>;
  clear(): Promise<void>;
  download(): Promise<void>;
}

class NoopLogger implements Logger {
  async log(_: string, __?: LogContext, ___?: LogLevel): Promise<void> {}
  async debug(_: string, __?: LogContext): Promise<void> {}
  async error(_: string, __?: LogContext): Promise<void> {}
  async warn(_: string, __?: LogContext): Promise<void> {}
  async clear(): Promise<void> {}
  async download(): Promise<void> {}
}

class FileLogger implements Logger {
  private async importRNFS(): Promise<typeof import('react-native-fs')> {
    return await import('react-native-fs');
  }

  private async getLogFilePath(): Promise<string> {
    const RNFS = await this.importRNFS();
    return `${RNFS.TemporaryDirectoryPath}/TeachTap.log`;
  }

  async clear(): Promise<void> {
    const RNFS = await this.importRNFS();
    await RNFS.unlink(await this.getLogFilePath());
  }

  async log(
    message: string,
    context?: LogContext,
    level: LogLevel = LogLevel.LOG,
  ): Promise<void> {
    try {
      const logMessage = this.formatLogMessage(message, context);
      console[level](logMessage);
      await this.storeLogMessage(logMessage, level);
    } catch (err) {
      console.error('Failed to write to log file:', err);
    }
  }

  async debug(message: string, context?: LogContext): Promise<void> {
    return this.log(message, context, LogLevel.DEBUG);
  }

  async error(message: string, context?: LogContext): Promise<void> {
    return this.log(message, context, LogLevel.ERROR);
  }

  async warn(message: string, context?: LogContext): Promise<void> {
    return this.log(message, context, LogLevel.WARN);
  }

  async download(): Promise<void> {
    try {
      const RNFS = await this.importRNFS();
      const exists = await RNFS.exists(await this.getLogFilePath());
      if (!exists) {
        console.error('Log file does not exist');
        return;
      }

      const options = {
        title: 'Share Log File',
        url: `file://${await this.getLogFilePath()}`,
        type: 'text/plain',
      };

      await Share.open(options);
      console.log('Log file shared successfully');
      await this.clear();
    } catch (error) {
      console.error(
        'Failed to share log file:',
        error,
        JSON.stringify(error),
        await this.getLogFilePath(),
      );
    }
  }

  private formatContext(context?: LogContext): string {
    return context ? `[${context.screenName} - ${context.componentName}]` : '';
  }

  private formatLogMessage(message: string, context?: LogContext): string {
    return `${new Date().toISOString()} ${this.formatContext(
      context,
    )}: ${message}`;
  }

  private async storeLogMessage(
    logMessage: string,
    level: LogLevel = LogLevel.LOG,
  ): Promise<void> {
    const RNFS = await this.importRNFS();
    return RNFS.appendFile(
      await this.getLogFilePath(),
      `[${level.toUpperCase()}] ${logMessage}\n`,
      'utf8',
    );
  }
}

class LoggerFactory {
  private static instance: Logger;

  static getInstance(): Logger {
    if (!LoggerFactory.instance) {
      if (isWebPlatform || isProductionEnv()) {
        LoggerFactory.instance = new NoopLogger();
      } else {
        LoggerFactory.instance = new FileLogger();
      }
    }
    return LoggerFactory.instance;
  }
}

export const logger = LoggerFactory.getInstance();
