import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';

import { useAppDispatch } from '../../../../App/store';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { VideoFrameView } from '../../../../Common/components/home/VideoFrameView';
import { MCQForDSATCard } from '../../../../Common/entities';
import { Fonts } from '../../../../Common/services/utils/AppConstants';
import {
  TestPrepFlowAnswers,
  TestPrepFlowQuestion,
} from '../../../../TestPrep/components';
import { TestPrepOption } from '../../../../TestPrep/entities';
import { setAnswerChoosenForTheCurrentTest } from '../../../../TestPrep/services/slices';

import BaseContentCard from './BaseContentCard';
import homeStyles from './styles';

type Props = {
  item: MCQForDSATCard;
  isVisible: boolean;
};

export type Layout = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export default ({ item }: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const handleAnswerSelect = useCallback(
    (option: TestPrepOption) => {
      dispatch(
        setAnswerChoosenForTheCurrentTest({
          answerChoosen: option,
          questionId: item.questionContent.id,
        }),
      );
    },
    [dispatch, item],
  );

  const tutorData = useMemo(() => {
    return item.questionContent?.tutor;
  }, [item.questionContent?.tutor]);

  return (
    <BaseContentCard item={item}>
      {tutorData?.videoURL && (
        <View style={styles.helpVideoContainer}>
          <VideoFrameView
            card={item}
            isVisible
            showVideo
            videoUrl={tutorData?.videoURL || ''}
            subtitleUrl={tutorData?.subtitleURL}
            title={tutorData?.title || ''}
            name={tutorData?.name || ''}
            shouldRestart={false}
          />
        </View>
      )}

      <View style={styles.contentContainer}>
        <View style={styles.textContainer}>
          <View
            style={[
              homeStyles.contentTypeContainer,
              styles.contentTypePadding,
            ]}>
            <ContentTypeView contentType={item.type} typeName={item.typeName} />
          </View>
          <TestPrepFlowQuestion
            currentQuestion={item.questionContent}
            shouldUseMarkdownLatex={true}
          />
        </View>
        <View style={styles.expanded}>
          <TestPrepFlowAnswers
            isFromReview={false}
            currentQuestion={item.questionContent}
            onAnswerSelect={handleAnswerSelect}
            shouldUseMarkdownLatex={true}
          />
        </View>
      </View>
      <View />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
  expanded: {
    flexShrink: 1,
  },
  textContainer: {
    flexGrow: 20,
    justifyContent: 'flex-end',
    paddingBottom: 8,
  },
  wrappedTextContainer: {
    marginEnd: 0,
    opacity: 0.9,
  },
  questionTextSmall: {
    ...Fonts.normalize(Fonts.large),
  },
  contentTypePadding: {
    paddingBottom: 8,
  },
  helpVideoContainer: {
    zIndex: 50,
  },
});
