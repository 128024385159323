import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GenerateQuestionResponse,
  LearningContentItemOutputGQLSchema,
} from '../../../Learn/entities';
import { AskDMFigureRequest } from '../../entities';

export const askDMFigureGraphQLCall = async (
  request: AskDMFigureRequest,
): Promise<GenerateQuestionResponse> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
      mutation AskDMFigure($input: TeachTapGenerateLearningContentItemInput!) {
        teachTapGenerateLearningContentItem(input: $input) {
          ${LearningContentItemOutputGQLSchema}
        }
      }
      `,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data.teachTapGenerateLearningContentItem;
};
