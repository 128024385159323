import React, { useEffect, useRef } from 'react';
import { FlatList, StyleSheet } from 'react-native';

import { Props } from '.';
import { emitVisibilityChangeEvent } from '../../../events';
import { isAndroidPlatform } from '../../../services/utils/AppConstants';

export default ({
  data,
  holderTag,
  ItemView,
  itemHeight,
  onEndReached,
  isVisible,
  onEndReachedThreshold,
  scrollToCardID,
  showCoinsAnimation = false,
}: Props): React.ReactElement => {
  const indexRef = useRef(0);

  const listRef = useRef<any>();

  useEffect(() => {
    if (scrollToCardID) {
      const index = data.findIndex(
        item => item.uniqueId === scrollToCardID.uniqueId,
      );
      if (index >= 0) {
        listRef.current.scrollToIndex({
          index,
          animated: scrollToCardID.animated,
        });
      }
    }
  }, [data, scrollToCardID]);

  useEffect(() => {
    if (isVisible) {
      emitVisibilityChangeEvent({
        visibleItemIndex: indexRef.current,
        holderTag,
      });
    } else {
      emitVisibilityChangeEvent({
        visibleItemIndex: undefined,
        holderTag,
      });
    }
  }, [holderTag, isVisible]);

  const preserveListItemIndex = (contentOffset_y: number): void => {
    const index = Math.round(contentOffset_y / itemHeight);
    if (index !== indexRef.current) {
      indexRef.current = index;

      emitVisibilityChangeEvent({
        visibleItemIndex: index,
        holderTag,
      });
    }
  };

  const scrollHandler = (e: any): void => {
    preserveListItemIndex(e.nativeEvent.contentOffset.y);
  };

  const renderItem = ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }): React.ReactElement => {
    return (
      <ItemView
        item={item}
        index={index}
        holderTag={holderTag}
        itemHeight={itemHeight}
        showCoinsAnimation={showCoinsAnimation}
      />
    );
  };

  return (
    <FlatList
      keyboardDismissMode="on-drag"
      keyboardShouldPersistTaps="always"
      disableIntervalMomentum={!isAndroidPlatform}
      removeClippedSubviews
      nestedScrollEnabled
      pagingEnabled={!isAndroidPlatform}
      ref={listRef}
      showsVerticalScrollIndicator={false}
      style={styles.listContainer}
      data={data}
      renderItem={renderItem}
      keyExtractor={item => item.uniqueId}
      snapToAlignment="start"
      decelerationRate={'fast'}
      maxToRenderPerBatch={3}
      initialNumToRender={3}
      windowSize={3}
      snapToInterval={itemHeight}
      showsHorizontalScrollIndicator={false}
      bounces={false}
      onScroll={scrollHandler}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
    />
  );
};

const styles = StyleSheet.create({
  listContainer: {},
});
