import moment from 'moment';

import { MessageItem, UserType } from '../entities';
import { DUPLICATE_MESSAGE_THRESHOLD_IN_SECONDS } from '../services/utils';

export class MessagesAlgorithm {
  static getPotentialDuplicatedErrorMessages = (
    messages: MessageItem[],
  ): MessageItem[] => {
    const currentMessages = [...messages];

    return currentMessages.filter((message, i) => {
      // 1. If the message is a new conversation, it's not a duplicated error message
      if (message.isNewConversation) {
        return false;
      }

      const isCurrentMessageIsAnError = message.id.includes('error');
      if (isCurrentMessageIsAnError && message.sender === UserType.user) {
        // 2. Compare the current message with the previous and next messages
        // Only if the previous or next message is from the user, and the gap between the messages is less than 10 seconds, then it's a duplicated error message

        const prevMessage = currentMessages[i - 1];
        const nextMessage = currentMessages[i + 1];
        let messageToCompare = prevMessage;

        if (!prevMessage || prevMessage?.sender !== UserType.user) {
          messageToCompare = nextMessage;

          if (!nextMessage || nextMessage?.sender !== UserType.user) {
            return false;
          }
        }

        const isNextUserMessage = messageToCompare.sender === UserType.user;
        const nextMessageGapLessThan10Seconds =
          Math.abs(
            new Date(messageToCompare.date).getTime() -
              new Date(message.date).getTime(),
          ) < 10000;

        if (
          isCurrentMessageIsAnError &&
          isNextUserMessage &&
          nextMessageGapLessThan10Seconds
        ) {
          return true;
        }
      }

      if (isCurrentMessageIsAnError && message.sender === UserType.error) {
        // 3. Compare the current message with the previous and next messages
        // Only if the previous or next message is from the dmFigure, then it's a duplicated error message
        const prevMessage = currentMessages[i - 1];
        const nextMessage = currentMessages[i + 1];
        let messageToCompare = prevMessage;

        if (!prevMessage || prevMessage?.sender !== UserType.dmFigure) {
          messageToCompare = nextMessage;

          if (!nextMessage || nextMessage?.sender !== UserType.dmFigure) {
            return false;
          }
        }

        const isNextDmFigureMessage =
          messageToCompare?.sender === UserType.dmFigure;

        return isCurrentMessageIsAnError && isNextDmFigureMessage;
      }

      return false;
    });
  };

  static excludeFistUserMessageForRaiseHand = (
    message: MessageItem[],
  ): MessageItem[] => {
    const currentMessages = [...message].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
    );

    return currentMessages.filter((message, i) => {
      if (message.sender === UserType.user && i === 0) {
        return false;
      }

      return !(message.sender === UserType.error && i === 1);
    });
  };

  static removeConsecutiveDuplicateMessages = (
    messages: MessageItem[],
    thresholdInSeconds: number = DUPLICATE_MESSAGE_THRESHOLD_IN_SECONDS,
  ): MessageItem[] => {
    return messages.filter((message, index, array) => {
      if (index === 0) {
        return true;
      }

      const previousMessage = array[index - 1];
      return !(
        message.sender === previousMessage.sender &&
        message.message === previousMessage.message &&
        moment(message.date).diff(previousMessage.date, 'seconds') <=
          thresholdInSeconds
      );
    });
  };
}
