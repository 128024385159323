import { RootState } from '../../../App/store';
import { SelectedApCoursesWithGroupingType } from '../../entities';

import { mapApOnboardingCourseList } from './ApOnboardingCourseMapper';

export const mapSelectedApCoursesWithGrouping = (
  state: RootState,
): SelectedApCoursesWithGroupingType => {
  const { availableCourses, nonAvailableCourses } =
    mapApOnboardingCourseList(state);
  const selectedCourses = state.apOnboarding.selectedCourses;

  const selectedAvailableCourses = availableCourses.filter(course =>
    selectedCourses.includes(course.name),
  );
  const selectedNonAvailableCourses = nonAvailableCourses.filter(course =>
    selectedCourses.includes(course.name),
  );

  return {
    selectedAvailableCourses,
    selectedNonAvailableCourses,
  };
};

export const mapApCoursesTargetGradeSelectionStatus = (
  state: RootState,
): boolean => {
  const { selectedAvailableCourses } = mapSelectedApCoursesWithGrouping(state);
  const apCourseTargetGrade = state.apOnboarding.apCourseTargetGrade;

  // If the selectedAvailableCourses is empty, return false because should not be able to proceed
  if (!selectedAvailableCourses?.length) {
    return false;
  }

  return selectedAvailableCourses.every(course =>
    Boolean(apCourseTargetGrade[course.name]),
  );
};
