import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  ReportStudyTimeRequest,
  ReportStudyTimeResponse,
} from '../../entities';

export const reportStudyTimeGraphQLCall = async (
  input: ReportStudyTimeRequest,
): Promise<ReportStudyTimeResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input,
      },
      query: `
        mutation TeachTapReportStudyTime($input: ReportStudyTimeInput!) {
          teachTapReportStudyTime(input: $input) {
            success
          }
        }`,
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapReportStudyTime;
};
