import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { CommonExamType } from '../../../../Common/entities';
import {
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { setShowTutorialScreen } from '../../../../Tutorial/services/slices';
import { BootcampEntryPoint } from '../../../entities';
import { isComingFromSATBootcampFlow } from '../../mappers';
import {
  ONBOARDING_SLICE_NAME,
  setBootcampEntryPoint,
  setSignUpExamType,
} from '../OnboardingSlice';

import { checkIfUserHasEntitlement } from './CheckIfUserHasEntitlementAction';

export const proceedSATBootcampFlowOnSignupSuccess = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedSATBootcampFlowOnSignupSuccess`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;

      if (!isComingFromSATBootcampFlow(state)) {
        return;
      }

      thunkApi.dispatch(
        setBootcampEntryPoint(BootcampEntryPoint.SAT_BOOTCAMP_INTRO),
      );
      thunkApi.dispatch(setSignUpExamType(CommonExamType.SAT));
      thunkApi.dispatch(setShowTutorialScreen(false));
      thunkApi.dispatch(setShowCourseListOnboarding(false));
      thunkApi.dispatch(setShowCourseListStudyTab(false));
      thunkApi.dispatch(
        checkIfUserHasEntitlement(BootcampEntryPoint.SAT_BOOTCAMP_INTRO),
      );
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
