import React, { useEffect, useRef } from 'react';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { Sizes } from '../../Common/services/utils/AppConstants';
import { TestPrepQuestionEntryPoint } from '../entities';
import { setCurrentTestQuestionIndex } from '../services/slices';

import { TestPrepQuestionSelectorButton } from './TestPrepQuestionSelectorButton';

export const TestPrepQuestionSelector = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const currentQuestionIndex = useAppSelector(
    state => state.testPrep.currentTest?.currentQuestionIndex ?? 0,
  );
  const questions = useAppSelector(
    state => state.testPrep.currentTest?.questions ?? [],
  );
  const highestSeenQuestionIndex = useAppSelector(
    state => state.testPrep.currentTest?.highestSeenQuestionIndex ?? 0,
  );
  const scrollRef = useRef<ScrollView>(null);
  const size = useWindowDimensions();

  useEffect(() => {
    if (scrollRef.current) {
      const elementWidth = 32;
      const elementSpacing = 6;
      const elementWidthWithSpacing = elementWidth + elementSpacing;
      const scrollToX =
        elementWidthWithSpacing * currentQuestionIndex -
        (size.width / 2 - Sizes.medium) +
        elementWidth / 2;
      const tid = setTimeout(() => {
        scrollRef.current?.scrollTo({
          x: scrollToX,
          animated: true,
        });
      }, 100);
      return () => clearTimeout(tid);
    }
  }, [currentQuestionIndex, size.width]);

  const resultsSeen = useAppSelector(
    state => state.testPrep.currentTest?.resultsSeen ?? false,
  );
  const isReviewMode = resultsSeen;

  return (
    <ScrollView
      ref={scrollRef}
      horizontal={true}
      style={styles.scrollView}
      showsHorizontalScrollIndicator={false}>
      <View style={styles.container}>
        {questions?.map((question, index) => (
          <TestPrepQuestionSelectorButton
            key={question.id}
            index={index}
            question={question}
            isActiveQuestion={index === currentQuestionIndex}
            onPress={() =>
              dispatch(
                setCurrentTestQuestionIndex({
                  index,
                  from: TestPrepQuestionEntryPoint.TOP_NAVIGATION,
                }),
              )
            }
            disabled={index > highestSeenQuestionIndex && !resultsSeen}
            isReviewMode={isReviewMode}
          />
        ))}
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  scrollView: {
    height: 64,
    flex: 0,
    flexGrow: 0,
    flexShrink: 0,
    marginTop: 10,
  },
  container: {
    flexDirection: 'row',
    paddingVertical: Sizes.semiMedium,
    paddingHorizontal: Sizes.medium,
    gap: 6,
  },
});
