import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppDispatch } from '../../../App/store';
import { CommonActionButton } from '../../../Common/components';
import { formatLocalizedPrice } from '../../../Common/services/utils';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { useAPProgramPaywallData } from '../../../Paywall/services/hooks/APProgramPaywallData';
import { BootcampEntryPoint } from '../../entities';
import { openApProgramFlow } from '../../services/slices';

type Props = {
  courseName?: string;
};

export const ApProgramUpsellOverlayAction = (
  props: Props,
): React.ReactElement => {
  const { userProductData, userProductDataDiscount, discountPercentage } =
    useAPProgramPaywallData();

  const dispatch = useAppDispatch();
  const handleGetAccessPress = useCallback(() => {
    if (!props.courseName) {
      return;
    }

    dispatch(
      openApProgramFlow({
        courseName: props.courseName,
        entrypoint: BootcampEntryPoint.LOCKED_AP_COURSE,
      }),
    );
  }, [dispatch, props.courseName]);

  const managedText = useMemo(() => {
    const regularPriceString = userProductData?.localizedPrice
      ? formatLocalizedPrice(userProductData.localizedPrice)
      : 'N';

    const discountPriceString = userProductDataDiscount?.localizedPrice
      ? formatLocalizedPrice(userProductDataDiscount.localizedPrice)
      : 'N';

    const fullAccessTexts = locale.onboarding.ap_upsell_overlay_full_access
      .replace('{REGULAR_PRICE}', regularPriceString ?? '')
      .replace('{DISCOUNT_PRICE}', discountPriceString ?? '')
      .split('<split>');

    const limitedOfferText =
      locale.bootcamp_paywall.limited_time_offer_save.replace(
        '{PERCENT}',
        discountPercentage.toString(),
      );

    return {
      fullAccessTexts,
      limitedOfferText,
    };
  }, [discountPercentage, userProductData, userProductDataDiscount]);

  return (
    <View>
      <Text style={styles.fullAccessText}>
        {managedText.fullAccessTexts[0]}
        <Text style={styles.fullAccessRegularPriceText}>
          {managedText.fullAccessTexts[1]}
        </Text>
        {managedText.fullAccessTexts[2]}
      </Text>

      <Text style={styles.limitedOfferText}>
        {managedText.limitedOfferText}
      </Text>

      <CommonActionButton
        style={styles.buttonContainer}
        onPress={handleGetAccessPress}
        text={locale.common.get_access}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  fullAccessText: {
    ...Fonts.semixlLargeBold,
    textAlign: 'center',
  },
  fullAccessRegularPriceText: {
    ...Fonts.semixlarge,
    color: Colors.white60,
    textDecorationLine: 'line-through',
  },

  limitedOfferText: {
    ...Fonts.xsmallBold,
    color: Colors.actionGreen,
    marginTop: 2,
    textAlign: 'center',
  },

  buttonContainer: {
    marginTop: Sizes.xsmall,
  },
});
