const awsmobile = {
  "aws_env": "pr7314",
  "aws_project_region": "us-east-1",
  "aws_cognito_region": "us-east-1",
  "aws_appsync_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:fea00478-7b6d-4cd9-842d-b4df47ec31ab",
  "aws_user_pools_id": "us-east-1_sGyjy83RJ",
  "aws_user_pools_web_client_id": "5uo2r8igqr3mm1pmq624bshste",
  "aws_appsync_graphqlEndpoint": "https://v2grxjctdvchlctfimqdhn3ifm.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_apiKey": "da2-zvqno3yk7bbifaa6dqvhzt3awu",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};
export default awsmobile;
