import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import { logger } from '../../../Common/services/utils';
import { submitQueuedAnswers } from '../../../TestPrep/services/slices';

export enum NetworkStatus {
  Online = 'ONLINE',
  Offline = 'OFFLINE',
}

type NetworkState = {
  isOnline: boolean;
};

const PERSIST_KEY = 'network';
const SLICE_NAME = 'NetworkSlice';

const initialState: NetworkState = {
  isOnline: true,
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['isOnline'],
} as PersistConfig<NetworkState>;

export const handleReconnection = createAsyncThunk(
  `${SLICE_NAME}/handleReconnection`,
  async (_, thunkApi) => {
    logger.debug('Handling Reconnection');
    const state = thunkApi.getState() as { network: NetworkState };
    if (state.network.isOnline) {
      thunkApi.dispatch(submitQueuedAnswers());
    }
  },
);

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setNetworkStatus: (state, action: PayloadAction<NetworkStatus>) => {
      state.isOnline = action.payload === NetworkStatus.Online;
    },
  },
});

export const { setNetworkStatus } = slice.actions;

export const NetworkSlice = persistReducer(persistConfig, slice.reducer);
