import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import PieChart from 'react-native-pie-chart';

import {
  mapApProgramDetailsAvailableStatus,
  mapApProgramTargetGradeByCourseId,
} from '../../AP/services/mappers';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { CommonRichText, CommonStartFeedHeader } from '../../Common/components';
import { Course } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { CourseFeedHeader } from '../../Learn/components/study/CourseFeedHeader';
import { GetTopicsPercentagePerGrade } from '../../Learn/entities';
import { TestInfo } from '../entities';

interface TestPrepFeedHeaderProps {
  selectedCourse: Course;
  chartLabels: GetTopicsPercentagePerGrade[];
  managedSliceColor: string[];
  attempts: Record<string, TestInfo>;
  title: string;
  description: string;
}

export const TestPrepFeedHeader = ({
  selectedCourse,
  chartLabels,
  managedSliceColor,
  attempts,
  title,
  description,
}: TestPrepFeedHeaderProps): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();
  const targetGrade = useAppSelector(state =>
    mapApProgramTargetGradeByCourseId(state, selectedCourse?.id),
  );
  const isUserHasApProgram = useAppSelector(mapApProgramDetailsAvailableStatus);

  const shouldShowChart = useMemo(() => {
    if (!selectedCourse?.units?.length) {
      return false;
    }

    for (const unit of selectedCourse.units) {
      if (attempts?.[unit.id]?.attempts?.length) {
        return true;
      }
    }

    return false;
  }, [attempts, selectedCourse]);

  const shouldShowTargetGradeDetail = useMemo(
    () => targetGrade && isUserHasApProgram,
    [targetGrade, isUserHasApProgram],
  );

  const contentContainerStyle = useMemo(() => {
    return [styles.contentContainer, { paddingTop: safeAreaTop }];
  }, [safeAreaTop]);

  const targetInfoText = useMemo(() => {
    return locale.ap.ap_test_prep_description
      .replace('{TARGET_SCORE}', targetGrade || 'N')
      .replace('{REQUIRED_SCORE}', targetGrade || 'N');
  }, [targetGrade]);

  return (
    <View>
      <View style={styles.topHeaderFiller} />

      <View style={contentContainerStyle}>
        <CourseFeedHeader
          course={selectedCourse}
          isFromTestPrep
          hideFollowingText
        />

        {!shouldShowChart && (
          <View style={styles.startContainer}>
            <CommonStartFeedHeader title={title} description={description} />
          </View>
        )}

        {shouldShowChart && (
          <>
            <View style={styles.chartContainer}>
              <Text style={styles.chartTitle}>
                {locale.study.test_prep_heading}
              </Text>

              <View style={styles.chartWithLegendContainer}>
                <PieChart
                  widthAndHeight={120}
                  series={chartLabels.map(labelData => labelData.percentage)}
                  sliceColor={managedSliceColor}
                />

                <View style={styles.legendContainer}>
                  {chartLabels.map((labelData, index) => (
                    <View key={index} style={styles.legendItem}>
                      <View
                        style={[
                          styles.legendIcon,
                          { backgroundColor: managedSliceColor[index] },
                        ]}
                      />
                      <Text style={styles.legendText}>{labelData.label}</Text>
                    </View>
                  ))}
                </View>
              </View>
            </View>

            {shouldShowTargetGradeDetail && (
              <CommonRichText style={styles.targetInfoText}>
                {targetInfoText}
              </CommonRichText>
            )}
          </>
        )}
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  startContainer: {
    marginTop: Sizes.medium,
    paddingHorizontal: Sizes.small,
    paddingTop: Sizes.medium,
  },
  topHeaderFiller: {
    position: 'absolute',
    top: -300,
    left: 0,
    width: '100%',
    height: 300,
    backgroundColor: Colors.black80,
  },
  examDateContainer: {
    marginTop: Sizes.semiMedium,
  },
  contentContainer: {
    flex: 1,
    paddingHorizontal: Sizes.medium,
    paddingBottom: Sizes.semiMedium,
  },
  chartContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: Sizes.medium,
    padding: Sizes.semiMedium,
    paddingBottom: Sizes.small,
  },
  chartTitle: {
    ...Fonts.small,
    textAlign: 'center',
  },
  legendItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginRight: Sizes.semiMedium,
    marginTop: Sizes.xsmall,
  },
  legendIcon: {
    marginTop: 3,
    width: 10,
    height: 10,
    borderRadius: 5,
    marginRight: 5,
  },
  chartWithLegendContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingLeft: Sizes.medium,
    paddingTop: Sizes.medium,
  },
  legendContainer: {
    justifyContent: 'flex-start',
    marginLeft: Sizes.medium,
    marginRight: Sizes.medium,
  },
  legendText: {
    ...Fonts.small,
    color: Colors.white80,
    maxWidth: '100%',
  },
  targetInfoText: {
    ...Fonts.small,
    color: Colors.white70,
    textAlign: 'center',
    paddingHorizontal: Sizes.medium,
    marginVertical: Sizes.small,
  },
});
