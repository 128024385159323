import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import CircularProgress from '../../../Common/components/common/CircularProgress';
import { Topic } from '../../../Common/entities';
import { Colors, Fonts } from '../../../Common/services/utils/AppConstants';
import {
  getProgressBorderColor,
  mapGradeToNumberIndex,
} from '../../services/utils';
import GradeIndicator from '../study/GradeIndicator';

type ScreenProps = {
  topic: Topic;
  shouldShowStars?: boolean;
};

const getStarsHeight = (grade?: string): number => {
  const numericGrade = mapGradeToNumberIndex(grade ?? '');
  if (numericGrade === 2) {
    return 10;
  } else if (numericGrade === 3) {
    return 20;
  } else if (numericGrade === 4) {
    return 20;
  }

  return 0;
};

export default ({
  topic,
  shouldShowStars,
}: ScreenProps): React.ReactElement => {
  return (
    <View style={styles.container}>
      <CircularProgress
        percentage={topic.progress}
        radius={18}
        color={getProgressBorderColor(topic.grade)}
        containerStyle={styles.gradeContainer}>
        <GradeIndicator
          studentGrade={topic.grade}
          viewStyle={styles.gradeContainer}
          shouldShowStars={shouldShowStars}
          starHeight={getStarsHeight(topic.grade)}
        />
      </CircularProgress>
      <Text style={styles.topic} numberOfLines={2}>
        {topic.name}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  gradeContainer: {
    width: 28,
    height: 28,
    borderRadius: 14,
    marginStart: 2,
  },
  container: {
    top: 5,
    backgroundColor: Colors.white10,
    width: '95%',
    alignSelf: 'center',
    borderRadius: 100,
    padding: 5,
    flexDirection: 'row',
    columnGap: 10,
    alignItems: 'center',
    minHeight: 44,
  },
  topic: {
    ...Fonts.smallBold,
    flex: 1,
    marginEnd: 5,
  },
});
