import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { PaywallEntryPoint, SubmitDataType } from '../../../../Common/entities';
import { submitDataGraphQLCall } from '../../../../Common/graphql/mutations';
import { SelectedCourseEntryPoint } from '../../../../CourseEnrollment/entities';
import {
  enrollUserToCourse,
  setCurrentSelectedCourse,
} from '../../../../CourseEnrollment/services/slices';
import { getCourseDataById } from '../../../../Learn/services/utils';
import { SubmitApCourseTargetGradeParams } from '../../../entities';
import { AP_SLICE_NAME, APSliceState } from '../APSlice';

import { getUserApProgramDetails } from './GetUserApProgramDetailsAction';

export const submitAndEnrollApCourseTargetGrade = createAsyncThunk(
  `${AP_SLICE_NAME}/submitAndEnrollApCourseTargetGrade`,
  async (params: SubmitApCourseTargetGradeParams, thunkAPI) => {
    const { courseId, targetGrade, isFromCoursePicker, entryPoint } = params;
    const courseData = getCourseDataById(courseId);

    if (!courseData?.id || !targetGrade) {
      return;
    }

    thunkAPI.dispatch(
      enrollUserToCourse({
        course: courseData,
        entryPoint: entryPoint,
        isFromCoursePicker: isFromCoursePicker ?? false,
        skipRedirectToPaywall: true,
      }),
    );

    thunkAPI.dispatch(
      setCurrentSelectedCourse({
        id: courseData.id,
        tab:
          entryPoint === PaywallEntryPoint.TEST_PREP
            ? SelectedCourseEntryPoint.TEST_PREP
            : SelectedCourseEntryPoint.STUDY,
      }),
    );

    await submitDataGraphQLCall({
      type: SubmitDataType.AP_COURSE_TARGET_GRADE,
      metadata: {
        courseId: courseData.id,
        subjectId: courseData.subjectId,
        targetGrade,
      },
    });

    await thunkAPI.dispatch(getUserApProgramDetails()).unwrap();
  },
);

export const submitAndEnrollApCourseTargetGradeExtraReducers = (
  builder: ActionReducerMapBuilder<APSliceState>,
): void => {
  builder.addCase(
    submitAndEnrollApCourseTargetGrade.pending,
    (state, action) => {
      const args = action.meta.arg;
      state.submitAndEnrollApCourseLoading[args.courseId] = true;
      state.tempCourseTargetGrade[args.courseId] = `${args.targetGrade}`;
    },
  );

  builder.addCase(
    submitAndEnrollApCourseTargetGrade.fulfilled,
    (state, action) => {
      state.submitAndEnrollApCourseLoading[action.meta.arg.courseId] = false;
    },
  );

  builder.addCase(
    submitAndEnrollApCourseTargetGrade.rejected,
    (state, action) => {
      state.submitAndEnrollApCourseLoading[action.meta.arg.courseId] = false;
    },
  );
};
