import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { CommonExamType } from '../../../../Common/entities';
import {
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { resetApProgramPaywall } from '../../../../Paywall/services/slices';
import { setShowTutorialScreen } from '../../../../Tutorial/services/slices';
import { BootcampEntryPoint } from '../../../entities';
import { isComingFromAPProgramFlow } from '../../mappers';
import { AP_ONBOARDING_SLICE_NAME } from '../ApOnboardingSlice';
import { setBootcampEntryPoint, setSignUpExamType } from '../OnboardingSlice';
import { checkIfUserHasEntitlement } from '../OnboardingSliceActions';

export const proceedAPProgramFlowOnSignupSuccess = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/proceedAPProgramFlowOnSignupSuccess`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const state = rootState.onboarding;

      if (!isComingFromAPProgramFlow(state)) {
        return;
      }

      thunkApi.dispatch(
        setBootcampEntryPoint(BootcampEntryPoint.AP_ONBOARDING),
      );
      thunkApi.dispatch(setSignUpExamType(CommonExamType.AP));
      thunkApi.dispatch(setShowTutorialScreen(false));
      thunkApi.dispatch(setShowCourseListOnboarding(false));
      thunkApi.dispatch(setShowCourseListStudyTab(false));
      thunkApi.dispatch(resetApProgramPaywall());

      thunkApi.dispatch(
        checkIfUserHasEntitlement(BootcampEntryPoint.UNCLAIMED_ENTITLEMENT),
      );
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
