import { graphqlApi, handleGraphQLError } from '../../../App/services/requests';
import {
  CheckVersionCompatibilityRequest,
  CheckVersionCompatibilityResponse,
} from '../../entities';

export const getAppVersionCompatibilityGraphQLCall = async (
  request: CheckVersionCompatibilityRequest,
): Promise<CheckVersionCompatibilityResponse> => {
  const response = await graphqlApi.post('', {
    variables: {
      frontEndProduct: request.product,
      frontEndVersion: request.version,
    },
    query: `
      query getVersion($frontEndProduct: String!, $frontEndVersion: String!) {
        checkVersionCompatibility(frontEndProduct: $frontEndProduct, frontEndVersion: $frontEndVersion) {
          status
          message
          androidUpgradeUrl
          iosUpgradeUrl
        }
      }`,
  });

  handleGraphQLError(response);
  return response.data?.data.checkVersionCompatibility;
};
