import React, { useMemo } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { renderSATTutorials } from '../../Onboarding/components/tutorials';
import { Tutorial, TutorialType } from '../entities';

type Props = {
  tutorial: Tutorial;
  isFromSATOnboarding?: boolean;
};

export const TutorialSlide = ({
  tutorial,
  isFromSATOnboarding,
}: Props): React.ReactElement => {
  const mainContainerStyle = useMemo(() => {
    return [
      styles.slideFlexBottomContainer,
      !tutorial.icon && styles.slideFlexBottomContainerNoPaddingTop,
    ];
  }, [tutorial.icon]);

  const screenImageStyle = useMemo(() => {
    if (isFromSATOnboarding) {
      return {
        ...styles.slideScreenImageSAT,
        height:
          tutorial.tutorialKey === TutorialType.SATBootcampTutorial1
            ? '95%'
            : '85%',
      };
    }
    return styles.slideScreenImage;
  }, [isFromSATOnboarding, tutorial.tutorialKey]);

  return (
    <View key={tutorial.tutorialKey} style={styles.slide}>
      <View style={mainContainerStyle}>
        {tutorial.icon ? (
          <View style={styles.slideIconContainer}>
            <AssetImage
              asset={tutorial.icon}
              imageStyle={styles.slideIcon}
              width={40}
              height={40}
            />
          </View>
        ) : (
          renderSATTutorials({ tutorialKey: tutorial.tutorialKey })
        )}
        <Text style={styles.slideTitle}>{tutorial.title}</Text>
        {tutorial.description && (
          <Text style={styles.slideDescription}>{tutorial.description}</Text>
        )}
        <View
          style={[
            styles.slideScreenContainer,
            isFromSATOnboarding && styles.slideScreenContainerSAT,
          ]}>
          {tutorial.screenImage ? (
            <AssetImage
              asset={tutorial.screenImage}
              imageStyle={screenImageStyle}
            />
          ) : (
            tutorial.component
          )}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  slide: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: Sizes.getAppWindowWidth(),
    zIndex: 10,
  },
  slideFlexBottomContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: Sizes.xlarge,
    paddingHorizontal: Sizes.large,
  },
  slideFlexBottomContainerNoPaddingTop: {
    paddingTop: 0,
  },
  slideIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
  },
  slideIcon: {
    height: '100%',
    width: '100%',
  },
  slideTitle: {
    ...Fonts.normalize(Fonts.xlargeBold),
    textAlign: 'center',
    marginTop: Sizes.semiMedium,
    color: Colors.grayscale[0],
  },
  slideDescription: {
    ...Fonts.small,
    textAlign: 'center',
    marginTop: Sizes.xsmall,
    color: Colors.white60,
  },
  slideScreenContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    marginTop: 20,
  },
  slideScreenContainerSAT: {
    marginTop: 0,
  },
  slideScreenImage: {
    width: Sizes.getAppWindowWidth(),
    height: '95%',
  },
  slideScreenImageSAT: {
    width: Sizes.getAppWindowWidth(),
    height: '85%',
  },
  gradient: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: Dimensions.get('screen').height / 2.5,
    zIndex: 20,
  },
});

export const renderTutorialSlide = (
  tutorial: Tutorial,
  isFromSATOnboarding?: boolean,
): React.ReactElement => (
  <TutorialSlide
    tutorial={tutorial}
    key={tutorial.tutorialKey}
    isFromSATOnboarding={isFromSATOnboarding}
  />
);
