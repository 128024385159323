import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { CommonAssets } from '../assets';
import { Fonts, Sizes } from '../services/utils/AppConstants';

type Props = {
  feature: string;
};

export const CommonUpsellFeatureListItem = ({
  feature,
}: Props): React.ReactElement => (
  <View style={styles.featureContainer}>
    <AssetImage
      asset={CommonAssets.UpsellCheck}
      containerStyle={styles.featureCheckContainer}
    />
    <Text style={styles.featureText}>{feature}</Text>
  </View>
);

const styles = StyleSheet.create({
  featureContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.xsmall,
  },
  featureCheckContainer: {
    width: 19,
    height: 18,
  },
  featureText: {
    ...Fonts.semiMedium500,
    color: 'white',
    flex: 1,
    flexWrap: 'wrap',
  },
});
