import { UserType } from '../../../Messages/entities';
import {
  GetRaiseHandContentGeneratorIdParams,
  RaiseHandMessageType,
} from '../../entities';

export const generateRaiseHandMessageId = (
  generatedContentId: string,
  senderUserId: UserType,
  timestamp: string,
  isNewConversation = false,
  isError = false,
): string => {
  const idArray = [generatedContentId, senderUserId.toString(), timestamp];

  if (isNewConversation) {
    idArray.push('new-conversation');
  }

  if (isError) {
    idArray.push('error');
  }

  return idArray.join('-');
};

export const checkIfContentGeneratorIsInitial = (
  contentGeneratorId: RaiseHandMessageType,
): boolean => {
  return [
    RaiseHandMessageType.StartConversation,
    RaiseHandMessageType.SATTestPrepReadWriteInitial,
    RaiseHandMessageType.TestPrepTutorInteractionInitial,
  ].includes(contentGeneratorId);
};

export const getRaiseHandContentGeneratorId = (
  params: GetRaiseHandContentGeneratorIdParams,
): RaiseHandMessageType => {
  const { isFromTestPrep, isSAT, isMathQuestion, isInitial } = params;

  if (isFromTestPrep) {
    if (isSAT && isMathQuestion) {
      return RaiseHandMessageType.SATTestPrepMath;
    } else if (isSAT) {
      return isInitial
        ? RaiseHandMessageType.SATTestPrepReadWriteInitial
        : RaiseHandMessageType.SATTestPrepReadWrite;
    }

    return isInitial
      ? RaiseHandMessageType.TestPrepTutorInteractionInitial
      : RaiseHandMessageType.TestPrepTutorInteraction;
  }

  return isInitial
    ? RaiseHandMessageType.StartConversation
    : RaiseHandMessageType.ContinueConversation;
};

export const isAskingFollowUpQuestion = (
  contentGeneratorId: RaiseHandMessageType,
): boolean => {
  return [
    RaiseHandMessageType.ContinueConversation,
    RaiseHandMessageType.TestPrepTutorInteraction,
    RaiseHandMessageType.SATTestPrepReadWrite,
    RaiseHandMessageType.SATTestPrepMath,
  ].includes(contentGeneratorId);
};

export const generateRequestId = (generatedContentId: string): string => {
  return `${generatedContentId}-${Date.now()}`;
};
