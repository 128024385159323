import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  LeaveTestSheetProps,
  SubmitIncompleteTestSheetProps,
} from '../../../TestPrep/screens/sheets';
import {
  CommentsSheetProps,
  ContentFeedbackSheetProps,
  FollowGoalSheetProps,
  PickerSheetProps,
  SelectAPGoalScoreSheetProps,
} from '../../screens/sheets';

const SLICE_NAME = 'PopupSlice';

type State = {
  commentsPopup: CommentsSheetProps | null;
  cardFeedbackPopup: ContentFeedbackSheetProps | null;
  pickerPopup: PickerSheetProps | null;
  followGoalPopup: FollowGoalSheetProps | null;
  selectAPGoalScorePopup: SelectAPGoalScoreSheetProps | null;
  leaveTestPopup: LeaveTestSheetProps | null;
  submitIncompleteTestPopup: SubmitIncompleteTestSheetProps | null;
};

const initialState: State = {
  commentsPopup: null,
  cardFeedbackPopup: null,
  pickerPopup: null,
  followGoalPopup: null,
  selectAPGoalScorePopup: null,
  leaveTestPopup: null,
  submitIncompleteTestPopup: null,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    setCommentsPopup: (
      state,
      action: PayloadAction<CommentsSheetProps | null>,
    ) => {
      state.commentsPopup = action.payload;
    },
    setCardFeedbackPopup: (
      state,
      action: PayloadAction<ContentFeedbackSheetProps | null>,
    ) => {
      state.cardFeedbackPopup = action.payload;
    },
    setPickerPopup: (state, action: PayloadAction<PickerSheetProps | null>) => {
      state.pickerPopup = action.payload;
    },
    setFollowGoalPopup: (
      state,
      action: PayloadAction<FollowGoalSheetProps | null>,
    ) => {
      state.followGoalPopup = action.payload;
    },
    setSelectAPGoalScorePopup: (
      state,
      action: PayloadAction<SelectAPGoalScoreSheetProps | null>,
    ) => {
      state.selectAPGoalScorePopup = action.payload;
    },

    setLeaveTestPopup: (
      state,
      action: PayloadAction<LeaveTestSheetProps | null>,
    ) => {
      state.leaveTestPopup = action.payload;
    },
    setSubmitIncompleteTestPopup: (
      state,
      action: PayloadAction<SubmitIncompleteTestSheetProps | null>,
    ) => {
      state.submitIncompleteTestPopup = action.payload;
    },
    clearPopups: () => initialState,
  },
});

export const {
  setPickerPopup,
  setCommentsPopup,
  setCardFeedbackPopup,
  setFollowGoalPopup,
  setSelectAPGoalScorePopup,
  setLeaveTestPopup,
  setSubmitIncompleteTestPopup,
  clearPopups,
} = slice.actions;
export const PopupSlice = slice.reducer;
