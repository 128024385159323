import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';
import { SvgUri } from 'react-native-svg';

import locale from '../../App/locale';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { AiFigureModel } from '../entities';

import {
  AiFigureProfileEmptyList,
  AiFigureProfileList,
  AiFigureProfileListContainer,
} from './AiFigureProfileListContainer';
import { AiFigureProfileLoading } from './AiFigureProfileListLoading';

type Props = {
  isLoading: boolean;
  data: AiFigureModel | undefined;
};

export const AiFigureProfileFamousQuoteList = ({
  data,
  isLoading,
}: Props): React.ReactElement => {
  const isEmpty = useMemo(() => {
    return !isLoading && !data?.famousQuotes?.length;
  }, [isLoading, data?.famousQuotes]);

  return (
    <AiFigureProfileListContainer title={locale.ai_profile.famous_quotes}>
      {!isLoading && (
        <AiFigureProfileList data={data?.famousQuotes || []}>
          {({ item }) => (
            <View style={[styles.container, styles.contributionTextContainer]}>
              <Text style={styles.descriptionText}>"{item}"</Text>
            </View>
          )}
        </AiFigureProfileList>
      )}

      {isEmpty && <AiFigureProfileEmptyList height={68} />}

      {isLoading && (
        <AiFigureProfileLoading
          itemWidth={280}
          itemHeight={68}
          itemNumber={3}
        />
      )}
    </AiFigureProfileListContainer>
  );
};

export const AiFigureProfileNotableContributionList = ({
  data,
  isLoading,
}: Props): React.ReactElement => {
  const isEmpty = useMemo(() => {
    return !isLoading && !data?.notableContributions?.length;
  }, [isLoading, data?.notableContributions]);

  return (
    <AiFigureProfileListContainer
      title={locale.ai_profile.notable_contributions}>
      {!isLoading && (
        <AiFigureProfileList
          data={data?.notableContributions || []}
          keyExtractor={item => item.title}>
          {({ item }) => (
            <View style={styles.container}>
              <Text style={[styles.titleText]}>{item.title}</Text>
              <Text style={styles.descriptionText}>{item.description}</Text>
            </View>
          )}
        </AiFigureProfileList>
      )}

      {isEmpty && <AiFigureProfileEmptyList height={68} />}

      {isLoading && (
        <AiFigureProfileLoading
          itemWidth={240}
          itemHeight={68}
          itemNumber={2}
        />
      )}
    </AiFigureProfileListContainer>
  );
};

export const AiFigureProfileInterestList = ({
  data,
  isLoading,
}: Props): React.ReactElement => {
  const isEmpty = useMemo(() => {
    return !isLoading && !data?.interests?.length;
  }, [isLoading, data?.interests]);

  return (
    <AiFigureProfileListContainer title={locale.ai_profile.interests}>
      {!isLoading && (
        <AiFigureProfileList
          data={data?.interests || []}
          keyExtractor={item => item}>
          {({ item }) => (
            <View style={styles.container}>
              <Text style={styles.text}>{item}</Text>
            </View>
          )}
        </AiFigureProfileList>
      )}

      {isEmpty && <AiFigureProfileEmptyList height={32} />}

      {isLoading && (
        <AiFigureProfileLoading
          itemWidth={120}
          itemHeight={28}
          itemNumber={3}
        />
      )}
    </AiFigureProfileListContainer>
  );
};

export const AiFigureProfileSubjectList = ({
  data,
  isLoading,
}: Props): React.ReactElement => {
  const isEmpty = useMemo(() => {
    return !isLoading && !data?.subjectsITeach?.length;
  }, [isLoading, data?.subjectsITeach]);

  const handleCheckIsImageSvg = useCallback((imageUrl: string) => {
    return imageUrl.includes('.svg');
  }, []);

  return (
    <AiFigureProfileListContainer title={locale.ai_profile.subjects_teach}>
      {!isLoading && (
        <AiFigureProfileList
          data={data?.subjectsITeach || []}
          keyExtractor={item => item.name}>
          {({ item }) => (
            <View style={[styles.container, styles.subjectContainer]}>
              {!handleCheckIsImageSvg(item.image || '') && (
                <FastImage source={{ uri: item.image }} style={styles.image} />
              )}

              {handleCheckIsImageSvg(item.image || '') && (
                <View style={styles.svgImageContainer}>
                  {isWebPlatform ? (
                    <img
                      src={item?.image}
                      alt={item.name}
                      style={styles.image}
                    />
                  ) : (
                    <SvgUri
                      uri={item?.image}
                      style={styles.image}
                      height={Sizes.medium}
                      width={Sizes.medium}
                    />
                  )}
                </View>
              )}

              <Text style={styles.text}>{item.name}</Text>
            </View>
          )}
        </AiFigureProfileList>
      )}

      {isEmpty && <AiFigureProfileEmptyList height={32} />}

      {isLoading && (
        <AiFigureProfileLoading
          itemWidth={120}
          itemHeight={28}
          itemNumber={3}
        />
      )}
    </AiFigureProfileListContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    marginRight: Sizes.small,
    paddingVertical: Sizes.small,
    paddingHorizontal: Sizes.semiMedium,
    borderRadius: Sizes.small,
    backgroundColor: Colors.white12,
  },
  subjectContainer: {
    flexDirection: 'row',
    paddingHorizontal: Sizes.small,
    columnGap: Sizes.small,
  },
  contributionTextContainer: { width: 280, minHeight: 80 },

  svgImageContainer: { borderRadius: Sizes.medium, overflow: 'hidden' },
  image: {
    width: Sizes.medium,
    height: Sizes.medium,
    backgroundColor: 'white',
    borderRadius: Sizes.small,
  },

  titleText: { ...Fonts.semiMediumBold, color: Colors.grayscale[0] },
  text: { ...Fonts.small, color: Colors.grayscale[0] },
  descriptionText: {
    ...Fonts.small,
    color: Colors.white60,
    marginTop: 2,
    width: 240,
  },
});
