import { RootState } from '../../../App/store';
import { ProgramAccessType } from '../../../Common/entities';
import {
  isAPCourse,
  isHighSchoolCourse,
  isSATCourse,
} from '../../../Common/services/utils';

export const mapCourseLockedStatus = (
  state?: RootState,
  courseId?: string,
): boolean => {
  if (!courseId || !state) {
    return true;
  }

  const { userAccess, courses, SAT } = state;
  const userHasAccessToAPProgram =
    state.AP.program?.accessDetails.accessType ===
    ProgramAccessType.FULL_ACCESS;

  const course = courses.coursesMap[courseId];

  if (!course?.id) {
    return true;
  }

  if (userAccess.accessibleCourses[course.id]) {
    return false;
  }

  if (isAPCourse(course)) {
    if (userHasAccessToAPProgram || userAccess.hasUltimateAccess) {
      return false;
    }
  }

  if (
    isSATCourse(course) &&
    (SAT.bootcamp?.details?.programType || userAccess.hasSATPrepAccess)
  ) {
    return false;
  }

  return true;
};

export const mapCourseUnitLockedStatus = (
  state?: RootState,
  courseId?: string,
  unitId?: string,
): boolean => {
  if (!courseId || !unitId || !state) {
    return true;
  }

  const { courses } = state;

  const course = courses.coursesMap[courseId];
  if (!course) {
    return true;
  }

  const isCourseLocked = mapCourseLockedStatus(state, courseId);
  if (!isCourseLocked) {
    return false;
  }

  if (!isHighSchoolCourse(course)) {
    return true;
  }

  const unit = course.units.find(unit => unit.id === unitId);
  if (!unit) {
    return true;
  }

  return unit.number !== 1;
};
