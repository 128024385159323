import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { APProgramCourseDetailWithAvatar } from '../../entities';

type Props = {
  course: APProgramCourseDetailWithAvatar;
};

export const ApCourseCommitmentCard = ({
  course,
}: Props): React.ReactElement => {
  const description = useMemo(() => {
    const baseText =
      course.studyCommitmentHours > 1
        ? locale.onboarding.ap_program_overview_course_time_commitment_plural
        : locale.onboarding.ap_program_overview_course_time_commitment_singular;

    const resolvedText = baseText
      .replace('{SCORE}', course.targetGrade.toString())
      .replace('{MONTH}', moment(course.examDate).format('MMMM'))
      .replace('{HOURS}', course.studyCommitmentHours.toString());

    return resolvedText.split('<split>');
  }, [course]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <FastImage source={{ uri: course.avatar }} style={styles.avatar} />

        <Text style={styles.title}>{course.name}</Text>
      </View>
      <Text>
        <Text style={styles.description}>{description[0]}</Text>
        <Text style={styles.bold}>{description[1]}</Text>
        <Text style={styles.description}>{description[2]}</Text>
        <Text style={styles.bold}>{description[3]}</Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: Sizes.semiMedium,
    borderRadius: Sizes.medium,
    gap: Sizes.small,
    backgroundColor: Colors.white10,
    alignItems: 'center',
    textAlign: 'center',
  },
  header: {
    gap: Sizes.small,
    alignItems: 'center',
  },
  avatar: {
    width: 28,
    height: 28,
    borderRadius: 28,
    borderWidth: 1,
    borderColor: Colors.white100,
  },
  title: {
    ...Fonts.semiLarge600,
  },
  description: {
    ...Fonts.caption,
    color: Colors.white60,
    textAlign: 'center',
  },
  bold: {
    ...Fonts.caption800,
  },
});
