import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { CommonAssets } from '../assets';
import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

import { CommonRichText } from './CommonRichText';

type Props = {
  title: string;
  description: string;
};

export const CommonStartFeedHeader = ({
  title,
  description,
}: Props): React.ReactElement => {
  return (
    <View>
      <Text style={styles.startTitle}>{title}</Text>
      <CommonRichText style={styles.startDescription}>
        {description}
      </CommonRichText>

      <View style={styles.imageWrapper}>
        <AssetImage
          asset={CommonAssets.arrowAnimation}
          containerStyle={styles.imageContainer}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  startTitle: {
    ...Fonts.semixlLargeBold,
    textAlign: 'center',
  },
  startDescription: {
    ...Fonts.caption,
    color: Colors.white70,
    marginTop: Sizes.xsmall,
    textAlign: 'center',
  },

  imageWrapper: {
    paddingTop: Sizes.medium,
  },
  imageContainer: {
    width: 56,
    height: 56,
    alignSelf: 'center',
    transform: [{ rotate: '230deg' }],
  },
});
