import ReactNativeHapticFeedback, {
  HapticFeedbackTypes,
} from 'react-native-haptic-feedback';

export const HapticUtil = {
  triggerLight: (): void => {
    ReactNativeHapticFeedback.trigger(HapticFeedbackTypes.impactLight, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  },
  triggerMedium: (): void => {
    ReactNativeHapticFeedback.trigger(HapticFeedbackTypes.impactMedium, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  },
  triggerHeavy: (): void => {
    ReactNativeHapticFeedback.trigger(HapticFeedbackTypes.impactHeavy, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  },
  effectDoubleClick: (): void => {
    ReactNativeHapticFeedback.trigger(HapticFeedbackTypes.effectDoubleClick, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  },
  effectHeavyClick: (): void => {
    ReactNativeHapticFeedback.trigger(HapticFeedbackTypes.effectHeavyClick, {
      enableVibrateFallback: true,
      ignoreAndroidSystemSettings: false,
    });
  },
};
