import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  InputAccessoryView,
  Keyboard,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { setAdjustPan, setAdjustResize } from 'rn-android-keyboard-adjust';

import Assets from '../../../../App/assets';
import { AssetImage } from '../../../../App/assets/AssetImage';
import locale from '../../../../App/locale';
import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import BoxedTextContainer from '../../../../Common/components/common/BoxedTextContainer';
import { OutlinedTextContainer } from '../../../../Common/components/common/OutlinedTextContainer';
import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import ContentPointsView from '../../../../Common/components/home/ContentPointsView';
import ContentTypeView from '../../../../Common/components/home/ContentTypeView';
import { VideoFrameView } from '../../../../Common/components/home/VideoFrameView';
import { FillGapsWithHelpCard } from '../../../../Common/entities';
import {
  useCardHintVideoVisibility,
  useCardState,
  useCardTimeRecorder,
  useTimedToggle,
} from '../../../../Common/services/hooks';
import {
  getQuestionStyle,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Colors,
  Durations,
  Fonts,
  isWebPlatform,
} from '../../../../Common/services/utils/AppConstants';
import { useFetchRaiseHandTutor } from '../../../../RaiseHand/services/hooks';
import HintButton from '../../../components/contents/HintButton';
import Animations from '../../../components/courses/CoinsAnimation';
import {
  getCardAttributes,
  getGapAnswer,
  getQuestionPoints,
  setExplanationShown,
  setGapsAnswer,
} from '../../../services/slices';

import BaseContentCard from './BaseContentCard';
import { Layout } from './MCQItemView';
import homeStyles from './styles';

let questionWidthVar: number | null = null;

const TEXT_OFFSET = 90;
const WORD_LIMIT = 15;

type Props = {
  item: FillGapsWithHelpCard;
  isVisible: boolean;
  showCoinsAnimation: boolean;
};

type OptionProps = {
  key: string;
  text: string;
  isCorrect: boolean;
  showIcon: boolean;
};

type AnswerProperties = {
  maxWordsLength: number;
  maxWordLength: number;
};

export default ({
  isVisible,
  item,
  showCoinsAnimation,
}: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const [showVideo, setShowVideo] = useCardHintVideoVisibility(item);
  const cardTimeRecorder = useCardTimeRecorder(item);

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const collapsedQuestion = useAppSelector(
    state => getCardAttributes(state.questions, item).collapsedQuestion,
  );
  const points = useAppSelector(
    state => getQuestionPoints(state.answer, item.uniqueId),
    (prev, next) => prev === next,
  );

  const gapAnswer = useAppSelector(state => getGapAnswer(state.answer, item));

  const textInputRef = useRef<TextInput>(null);

  const [inputText, setInputText] = useCardState<string>(item, '');
  const inputAccessoryViewID = useMemo(
    () => item.generatedContentId,
    [item.generatedContentId],
  );

  const [blink, setBlink, blinkControls] = useTimedToggle({
    toggleDuration: Durations.blinkDelay,
  });
  const preventBlinkRef = useRef(showVideo);

  const isTextVisible = useMemo(
    () => visibilityStyle !== 'no-text',
    [visibilityStyle],
  );

  const correctGapAnswer = useMemo(() => {
    if (gapAnswer?.answer) {
      return gapAnswer.answer;
    }

    return item.possibleAnswers[0];
  }, [gapAnswer, item.possibleAnswers]);

  const answerProperties = useMemo((): AnswerProperties => {
    if (!item.possibleAnswers) {
      return { maxWordsLength: 0, maxWordLength: 0 };
    }

    let maxWordsLength = 0;
    let maxWordLength = 0;

    item.possibleAnswers.forEach(answer => {
      const words = answer.split(' ');
      maxWordsLength = Math.max(maxWordsLength, words.length);
      words.forEach(word => {
        maxWordLength = Math.max(maxWordLength, word.length);
      });
    });

    return { maxWordsLength, maxWordLength };
  }, [item.possibleAnswers]);

  const [showInputField, setShowInputField] = useCardState<boolean>(
    item,
    false,
  );

  const amendedPointerEvents = 'box-none';
  const [showAnswerConfirmed, setShowAnswerConfirmed] = useCardState(
    item,
    false,
  );

  const [animateTryMe, setAnimateTryMe] = useCardState(item, false);

  const [isAnswered, setIsAnswered] = useCardState(item, false);
  const [contentPointLayout, setContentPointLayout] = useCardState<Layout>(
    item,
    { x: 0, y: 0, width: 0, height: 0 },
  );
  const [parentY, setParentY] = useCardState(item, 0);
  const { raiseHandTutor } = useFetchRaiseHandTutor(item.character.name);

  const handleStopBlink = useCallback(() => {
    blinkControls.cancel();
    setBlink(false);
    preventBlinkRef.current = true;
  }, [blinkControls, setBlink]);

  useEffect(() => {
    if (!preventBlinkRef.current && !blinkControls.ref.current) {
      if (!isVisible) {
        blinkControls.cancel();
        setBlink(false);
      } else {
        blinkControls.trigger();
      }
    }
  }, [isVisible, setBlink, blinkControls]);

  useEffect(() => {
    if (showVideo) {
      dispatch(
        setExplanationShown({
          item,
        }),
      );

      handleStopBlink();
    }
  }, [showVideo, dispatch, item, handleStopBlink]);

  useEffect(() => {
    if (showAnswerConfirmed) {
      setInputText(correctGapAnswer);
    }
  }, [correctGapAnswer, setInputText, showAnswerConfirmed]);

  useEffect(() => {
    if (!isTextVisible) {
      setShowVideo(false);
    }
  }, [isTextVisible, setShowVideo]);

  useEffect(() => {
    const _keyboardDidHide = (): void => {
      setShowInputField(false);
    };

    const _keyboardDidShow = (): void => {};
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      _keyboardDidHide,
    );
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      _keyboardDidShow,
    );
    return () => {
      keyboardDidShowListener.remove();
      keyboardDidHideListener.remove();
    };
  }, [setShowInputField]);

  useEffect(() => {
    setAdjustPan();
    return () => {
      setAdjustResize();
    };
  }, []);

  const recordFinishedReadingExplanation = (answeredQuestion = false) => {
    if (showVideo || answeredQuestion) {
      cardTimeRecorder.recordFinishedReadingExplanation();
    }
  };

  const onLayout = (event: LayoutChangeEvent): void => {
    const { width } = event.nativeEvent.layout;

    questionWidthVar = width;
  };

  const onButtonPress = (): void => {
    if (!isWebPlatform) {
      setShowInputField(true);
      textInputRef.current?.focus();
    } else {
      if (!showVideo) {
        onHintPress();
      } else {
        redirectToAppstore();
      }
    }
  };

  const onHintPress = () => {
    handleStopBlink();

    if (isWebPlatform) {
      if (!showVideo) {
        setShowVideo(true);
      } else {
        redirectToAppstore();
      }
    } else {
      cardTimeRecorder.recordHelpShown();
      recordFinishedReadingExplanation();
      setShowVideo(!showVideo);
    }
  };

  const onSubmitText = (): void => {
    // to avoid submitting empty answer
    if (inputText.length !== 0) {
      onAnswered();
      setShowInputField(false);
      Keyboard.dismiss();
      dispatch(
        setGapsAnswer({
          item,
          value: inputText,
        }),
      );
    }
  };

  const isAnswerCorrect = useMemo(() => {
    return gapAnswer?.isCorrect || false;
  }, [gapAnswer]);

  let text = item.text;

  if (gapAnswer?.submittedAnswer) {
    if (isAnswerCorrect || (!isAnswerCorrect && showAnswerConfirmed)) {
      text = text.replace(
        Object.keys(item.gaps)[0],
        correctGapAnswer
          .split(' ')
          .map(word => `<correct_text>${word.trim()}</correct_text>`)
          .join(''),
      );
    } else {
      text = text.replace(
        Object.keys(item.gaps)[0],
        gapAnswer?.submittedAnswer
          .split(' ')
          .map(word => `<wrong_text>${word.trim()}</wrong_text>`)
          .join(''),
      );
    }
  } else {
    if (inputText) {
      text = text.replace(
        Object.keys(item.gaps)[0],
        inputText
          .split(' ')
          .map(word => `<pending_text>${word.trim()}</pending_text>`)
          .join(' '),
      );
    } else {
      text = text.replace(
        Object.keys(item.gaps)[0],
        correctGapAnswer
          .split(' ')
          .map(word => `<blink_button>${word.trim()}</blink_button>`)
          .join(' '),
      );
    }
  }

  const renderQuestionText = (collapsed: boolean): React.ReactElement => {
    const textStyling = getQuestionStyle(item.generatedContentId);
    return (
      // This to make sure that touches doesn not go through the text container
      <TouchableOpacity activeOpacity={1}>
        <BoxedTextContainer
          style={[
            styles.wrappedTextContainer,
            collapsed && styles.questionCollapsedHeight,
          ]}
          textStyle={{
            ...(showVideo ? styles.questionTextSmall : homeStyles.questionText),
            color: textStyling.textColor,
          }}
          text={text}
          width={
            showVideo ? questionWidthVar! - TEXT_OFFSET : questionWidthVar!
          }
          outlineColor={textStyling.outlinedColor}
          color={textStyling.backgroundColor}
          opacity={0.7}
          onButtonPress={onButtonPress}
        />
      </TouchableOpacity>
    );
  };

  const onAnswered = () => {
    setIsAnswered(true);
    recordFinishedReadingExplanation(true);
    handleStopBlink();
    setShowVideo(true);
    !animateTryMe && setAnimateTryMe(!isAnswerCorrect);
    if (!isAnswerCorrect) {
      setTimeout(() => {
        setIsAnswered(false);
      }, 3000);
    }
  };

  const handleContentPointLayoutChange = (layout: Layout) => {
    setContentPointLayout(layout);
  };

  const handleRaiseHandPress = () => {
    setAnimateTryMe(false);
  };

  const renderOption = (props: OptionProps): React.ReactElement => {
    return (
      <View
        style={[
          styles.itemContainer,
          props.isCorrect ? styles.optionRight : styles.optionWrong,
        ]}
        key={props.key}>
        <View style={styles.textWrapper}>
          <OutlinedTextContainer hq stroke={1} color={'#000000'}>
            <Text style={styles.optionText}>{props.text}</Text>
          </OutlinedTextContainer>
        </View>
        {props.showIcon && (
          <View style={styles.statusIcon}>
            <AssetImage
              asset={
                props.isCorrect ? Assets.home.tickIcon : Assets.home.crossIcon
              }
              height={24}
            />
          </View>
        )}
      </View>
    );
  };

  const shouldShowAnswerView = () => {
    return isTextVisible && !collapsedQuestion && gapAnswer;
  };

  const onTryAgainPress = () => {
    setShowAnswerConfirmed(false);

    setInputText('');

    dispatch(
      setGapsAnswer({
        item,
        value: '',
        isReset: true,
      }),
    );
  };

  const renderAnswerFlow = () => {
    if (showAnswerConfirmed || isAnswerCorrect) {
      return (
        <View style={styles.optionsContainer}>
          {renderOption({
            text: correctGapAnswer,
            key: 'answer',
            isCorrect: true,
            showIcon: isAnswerCorrect,
          })}
          {!isAnswerCorrect &&
            renderOption({
              text: gapAnswer?.submittedAnswer!,
              key: 'input',
              isCorrect: false,
              showIcon: true,
            })}
        </View>
      );
    } else {
      return (
        <View style={styles.flowButtonsContainer}>
          <TouchableOpacity
            style={styles.tryAgainButton}
            onPress={onTryAgainPress}>
            <Text style={styles.buttonText}>{locale.common.try_again}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.showAnswerButton}
            onPress={() => setShowAnswerConfirmed(true)}>
            <Text style={styles.buttonText}>
              {locale.home_screen_card.reveal_answer}
            </Text>
          </TouchableOpacity>
        </View>
      );
    }
  };

  const onCardTap = () => {
    if (showInputField) {
      Keyboard.dismiss();
    }
    if (isWebPlatform) {
      onHintPress();
      return true;
    }
    return false;
  };

  const onChangeText = (value: string) => {
    const userInputWords = value.split(' ');
    if (userInputWords.length > answerProperties.maxWordsLength) {
      return;
    }

    for (let i = 0; i < userInputWords.length; i++) {
      const maxAllowedLength = Math.max(
        answerProperties.maxWordLength,
        WORD_LIMIT,
      );
      if (userInputWords[i].length > maxAllowedLength) {
        return;
      }
    }

    setInputText(value);
  };

  return (
    <BaseContentCard item={item} onCardTap={onCardTap}>
      <View style={styles.contentContainer}>
        <View
          style={
            showInputField ? styles.textContainerUp : styles.textContainerMiddle
          }
          onLayout={!questionWidthVar ? onLayout : undefined}>
          <>
            {isTextVisible && !collapsedQuestion && (
              <View
                style={homeStyles.contentTypeContainer}
                onLayout={event => {
                  const { y } = event.nativeEvent.layout;
                  setParentY(y);
                }}>
                <ContentTypeView
                  contentType={item.type}
                  typeName={item.typeName}
                />
                {points !== undefined && (
                  <ContentPointsView
                    points={points}
                    isPositive={isAnswerCorrect}
                    isAnswered={isAnswered}
                    onLayoutChange={handleContentPointLayoutChange}
                    parentY={parentY}
                  />
                )}
              </View>
            )}
            {renderQuestionText(collapsedQuestion ?? false)}
            {isTextVisible && !collapsedQuestion && (
              <HintButton onHintPress={onHintPress} blinkHighlight={blink} />
            )}
          </>
        </View>
        <View style={styles.expanded}>
          {shouldShowAnswerView() && renderAnswerFlow()}
        </View>
      </View>

      <ActionButtonsView
        item={item}
        animateHand={animateTryMe}
        handleRaiseHand={handleRaiseHandPress}
        speakerCharacter={raiseHandTutor}
      />
      {showInputField && (
        <View
          style={styles.keyboardHandler}
          pointerEvents={amendedPointerEvents}>
          <View style={styles.inputFieldContainer}>
            <TextInput
              autoFocus
              ref={textInputRef}
              defaultValue={inputText}
              style={styles.textInput}
              autoCapitalize="none"
              returnKeyType="done"
              placeholder={locale.home_screen_card.input_answer_placeholder}
              onChangeText={onChangeText}
              blurOnSubmit={false}
              onSubmitEditing={onSubmitText}
              inputAccessoryViewID={inputAccessoryViewID}
            />
          </View>
          <InputAccessoryView nativeID={inputAccessoryViewID}>
            <TouchableOpacity
              style={styles.submitContainer}
              onPress={() => onSubmitText()}>
              <Text style={styles.submitText}>{locale.common.submit}</Text>
            </TouchableOpacity>
          </InputAccessoryView>
        </View>
      )}
      {isTextVisible && (
        <VideoFrameView
          card={item}
          videoUrl={item.character.videoUrl}
          subtitleUrl={item.character.subtitleUrl}
          isVisible={isVisible}
          name={item.character.name}
          title={item.character.title}
          showVideo={showVideo}
          shouldRestart={!showVideo}
          hideVideo={() => setShowVideo(false)}
        />
      )}
      {showCoinsAnimation &&
        contentPointLayout &&
        isAnswerCorrect &&
        !showInputField && <Animations startPosition={contentPointLayout} />}
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  contentTypeTitle: {
    ...Fonts.mediumBold,
    color: '#28B18F',
    marginStart: 6,
  },
  expanded: {
    flexShrink: 1,
  },
  inputFieldContainer: {
    width: '100%',
    backgroundColor: 'white',
    height: 0,
    alignSelf: 'flex-end',
    paddingHorizontal: 16,
  },
  itemContainer: {
    borderRadius: 8,
    flexDirection: 'row',
    marginTop: 8,
    maxWidth: '100%',
    minHeight: 50,
    paddingHorizontal: 12,
    paddingVertical: 6,
    width: '100%',
  },
  keyboardHandler: {
    alignSelf: 'flex-end',
    bottom: 0,
    height: '100%',
    justifyContent: 'flex-end',
    position: 'absolute',
    width: '100%',
  },
  optionRight: {
    backgroundColor: Colors.answerRight,
  },
  optionText: {
    ...Fonts.normalize(Fonts.medium),
    paddingEnd: 2,
    paddingStart: 2,
  },
  optionWrong: {
    backgroundColor: Colors.answerWrong,
  },
  optionsContainer: {
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 10,
    marginBottom: 16,
  },
  questionTextContainerCollapsed: {
    height: 0,
  },
  questionTextContainerHidden: {
    opacity: 0,
  },
  revealBackground: {
    backgroundColor: 'grey',
    borderRadius: 10,
  },
  revealButton: {
    backgroundColor: 'white',
    borderRadius: 10,
  },
  buttonText: {
    ...Fonts.large,
  },
  flowButtonsContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  tryAgainButton: {
    width: 250,
    backgroundColor: Colors.actionYellow,
    borderRadius: 50,
    alignItems: 'center',
    paddingVertical: 10,
    marginBottom: 10,
  },
  showAnswerButton: {
    width: 250,
    backgroundColor: '#FFFFFF55',
    borderRadius: 50,
    alignItems: 'center',
    paddingVertical: 10,
  },
  revealButtonSuccess: {
    backgroundColor: Colors.answerRight,
    borderRadius: 10,
  },
  revealButtonWrong: {
    backgroundColor: Colors.answerWrong,
    borderRadius: 10,
  },
  statusIcon: {
    alignItems: 'flex-end',
    flexGrow: 1,
    justifyContent: 'center',
    minWidth: 24,
    paddingStart: 16,
  },
  textContainerMiddle: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingBottom: 8,
  },
  textContainerUp: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 8,
  },
  textInput: {
    ...Fonts.large,
    color: 'black',
    flex: 1,
  },
  textWrapper: {
    alignSelf: 'center',
    flexShrink: 1,
    width: '100%',
  },
  wrappedTextContainer: {
    marginEnd: 0,
    opacity: 0.9,
  },
  questionTextSmall: {
    ...Fonts.normalize(Fonts.large),
  },
  questionCollapsedHeight: {
    height: 0,
  },
  submitText: {
    ...Fonts.mediumBold,
  },
  submitContainer: {
    backgroundColor: Colors.actionYellow,
    height: 48,
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    shadowColor: Colors.black40,
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
});
