import AsyncStorage from '@react-native-async-storage/async-storage';

export const useNavigation = <T>(): T => {
  return {
    navigate: () => {},
  } as unknown as T;
};

export const useIsFocused = (): boolean => {
  return true;
};

export const useSharedValue = (): void => {};
export const useAnimatedStyle = (): void => {};
export const withTiming = (): void => {};
export const withSequence = (): void => {};
export const withDelay = (): void => {};

export const normalize = (size: number): number => {
  return size;
};

export const StaticSafeAreaInsets = {
  safeAreaInsetsTop: 0,
  safeAreaInsetsBottom: 0,
  safeAreaInsetsLeft: 0,
  safeAreaInsetsRight: 0,
  getSafeAreaInsets: (): {
    safeAreaInsetsTop: number;
    safeAreaInsetsBottom: number;
    safeAreaInsetsLeft: number;
    safeAreaInsetsRight: number;
  } => {
    return {
      safeAreaInsetsTop: 0,
      safeAreaInsetsBottom: 0,
      safeAreaInsetsLeft: 0,
      safeAreaInsetsRight: 0,
    };
  },
};

export const Share = {
  open: (): void => {},
};

export { AsyncStorage as EncryptedStorage };
