import BottomSheet from '@gorhom/bottom-sheet';
import { Portal } from '@gorhom/portal';
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, View } from 'react-native';

import {
  CommonCloseButton,
  renderCommonSheetBackdrop,
} from '../../Common/components';
import { Course, Unit } from '../../Common/entities';
import {
  useKeyboardListener,
  useSafeAreaCustomInsets,
} from '../../Common/services/hooks';
import { Colors, Sizes } from '../../Common/services/utils/AppConstants';
import { DMSpeaker } from '../../Messages/entities';
import { RaiseHandMessageScreen } from '../../RaiseHand/screens';

export type TestPrepHelpPanelChatContainerRef = {
  open: () => void;
  close: () => void;
};

export type TestPrepHelpPanelChatContainerProps = {
  speaker: DMSpeaker;
  videoUrl?: string;
  generatedContentId: string;
  currentQuestionLevelId: string;
  isSAT: boolean;
  isMathQuestion: boolean;
  unit: Unit;
  course: Course;

  defaultHeight?: number;
  onClose?: () => void;
};

export const TestPrepHelpPanelChatContainer = forwardRef<
  TestPrepHelpPanelChatContainerRef,
  TestPrepHelpPanelChatContainerProps
>((props, ref): React.ReactElement => {
  const bottomSheetRef = useRef<BottomSheet>(null);

  const [sheetIndex, setSheetIndex] = useState(-1);
  const [hasOpened, setHasOpened] = useState(false);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const { safeAreaBottom, safeAreaTop } = useSafeAreaCustomInsets();

  const isSheetVisible = useMemo(() => sheetIndex > -1, [sheetIndex]);
  const extraBottomPadding = useMemo(() => sheetIndex === 0, [sheetIndex]);

  const snapPoints = useMemo(() => {
    const windowHeight = Sizes.getAppWindowHeight();
    const windowHeight50 = windowHeight * 0.5;

    return [
      safeAreaBottom + windowHeight50 + safeAreaTop,
      windowHeight - safeAreaTop - 120,
    ];
  }, [safeAreaBottom, safeAreaTop]);

  const componentMaxHeight = useMemo(() => {
    return sheetIndex >= 0 ? snapPoints[sheetIndex] : undefined;
  }, [snapPoints, sheetIndex]);

  const contentStyle = useMemo(() => {
    return [styles.contentContainer, { maxHeight: componentMaxHeight }];
  }, [componentMaxHeight]);

  const raiseHandParams = useMemo(() => {
    return {
      params: {
        speaker: props.speaker,
        generatedContentId: props.generatedContentId,
        isFromTestPrep: true,
        isSAT: props.isSAT,
        isMathQuestion: props.isMathQuestion,
        currentQuestionLevelId: props.currentQuestionLevelId,
        extraPaddingBottom: extraBottomPadding,
      },
    };
  }, [
    props.speaker,
    props.generatedContentId,
    props.isSAT,
    props.isMathQuestion,
    props.currentQuestionLevelId,
    extraBottomPadding,
  ]);

  const handleOpen = useCallback(() => {
    setSheetIndex(0);
    setHasOpened(true);
    bottomSheetRef.current?.expand();
  }, []);

  const handleClose = useCallback(() => {
    setSheetIndex(-1);
    bottomSheetRef.current?.close();

    if (props.onClose) {
      props.onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onClose]);

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpen,
      close: handleClose,
    }),
    [handleOpen, handleClose],
  );

  useKeyboardListener({
    onKeyboardWillShow: () => {
      setIsKeyboardVisible(true);
      bottomSheetRef.current?.snapToIndex(1, {
        duration: 50,
      });
    },
    onKeyboardDidHide: () => setIsKeyboardVisible(false),
  });

  return (
    <Portal>
      <BottomSheet
        ref={bottomSheetRef}
        enablePanDownToClose
        index={sheetIndex}
        backgroundStyle={styles.background}
        handleIndicatorStyle={styles.handle}
        enableHandlePanningGesture={!isKeyboardVisible}
        snapPoints={snapPoints}
        animateOnMount={false}
        onChange={setSheetIndex}
        backdropComponent={() =>
          renderCommonSheetBackdrop({
            onPress: handleClose,
            isVisible: isSheetVisible,
            style: styles.backdrop,
          })
        }>
        <View style={styles.mainContainer}>
          <CommonCloseButton
            onPress={handleClose}
            mainContainerStyle={styles.closeButtonContainer}
            size={Sizes.medium}
          />

          <View style={contentStyle}>
            {hasOpened && <RaiseHandMessageScreen route={raiseHandParams} />}
          </View>
        </View>
      </BottomSheet>
    </Portal>
  );
});

const styles = StyleSheet.create({
  background: {
    backgroundColor: 'transparent',
  },
  backdrop: {
    backgroundColor: Colors.black20,
    shadowOpacity: 1,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
  },
  handle: {
    backgroundColor: 'white',
    width: Sizes.xxlarge,
    transform: [{ translateY: Sizes.xsmall }],
  },

  closeButtonContainer: {
    zIndex: 30,
    top: Sizes.medium,
    right: Sizes.medium,
  },
  mainContainer: {
    flex: 1,
    borderTopRightRadius: Sizes.medium,
    borderTopLeftRadius: Sizes.medium,
    backgroundColor: Colors.darkBlueB,
  },
  contentContainer: {
    flex: 1,
    overflow: 'hidden',
    borderTopRightRadius: Sizes.medium,
    borderTopLeftRadius: Sizes.medium,
  },
});
