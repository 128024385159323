import { Course } from '../../../Common/entities';
import {
  ApResultsYesNoEnum,
  ApResultsInputsAnalytics,
  ApResultsScoreEnum,
} from '../../entities';

export const mapApResultsInputForAnalytics = (
  courses?: Course[],
  examResults?: Record<string, ApResultsScoreEnum | undefined>,
  isHappyWithResults?: Record<string, ApResultsYesNoEnum | undefined>,
): ApResultsInputsAnalytics => {
  const examResultsAnalytics: Record<string, string | number> = {};
  const isHappyWithResultsAnalytics: Record<string, string> = {};

  (courses || []).forEach(course => {
    const courseName = course.name;
    const shouldShowScore =
      examResults?.[courseName] !== undefined &&
      examResults?.[courseName] !== ApResultsScoreEnum.NO_TEST;

    const score = shouldShowScore ? `${examResults?.[courseName] ?? ''}` : '';
    let happyValidation = `${isHappyWithResults?.[courseName] ?? ''}`;

    if (examResults?.[courseName] === ApResultsScoreEnum.FIVE) {
      happyValidation = ApResultsYesNoEnum.YES;
    } else if (
      examResults?.[courseName] === ApResultsScoreEnum.ONE ||
      examResults?.[courseName] === ApResultsScoreEnum.TWO
    ) {
      happyValidation = ApResultsYesNoEnum.NO;
    }

    examResultsAnalytics[courseName] = score;
    isHappyWithResultsAnalytics[courseName] = happyValidation;
  });

  return {
    examResults: examResultsAnalytics,
    isHappyWithResults: isHappyWithResultsAnalytics,
  };
};
