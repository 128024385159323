import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import normalize from 'react-native-normalize';

import locale from '../../../App/locale';
import { useSafeAreaCustomInsets } from '../../../Common/services/hooks';
import { Colors, Fonts } from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';

type Props = {
  tab: number;
  onSearchPress: () => void;
  onTabChange: (tab: number) => void;
};

export default ({ tab, onTabChange }: Props): React.ReactElement => {
  const { topHeaderSafeAreaHeight, safeAreaTop } = useSafeAreaCustomInsets();

  return (
    <View style={[styles.container, { height: safeAreaTop }]}>
      <LinearGradient
        style={[styles.topAlphaGradient, { height: topHeaderSafeAreaHeight }]}
        colors={Colors.topAlphaGradient}
      />
      <View style={[styles.innerContainer, { marginTop: safeAreaTop }]}>
        <View style={styles.centerContainer}>
          <TouchableOpacity
            style={styles.tabFollowingContainer}
            disabled
            onPress={() => onTabChange(1)}>
            <Text style={tab === 1 ? styles.tabSelectedText : styles.tabText}>
              {locale.home_screen_tabs.following}
            </Text>
            <View
              style={
                tab === 1
                  ? styles.tabSelectedUnderline
                  : styles.tabUnselectedUnderline
              }
            />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  centerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  container: {
    backgroundColor: 'transparent',
    flexDirection: 'row',
    position: 'absolute',
    width: '100%',
  },

  innerContainer: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    flexDirection: 'row',
    height: 50,
    position: 'absolute',
    width: '100%',
  },

  leftContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    left: 10,
  },
  rightContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    right: 16,
  },
  tabFollowingContainer: {
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 11,
  },
  tabForYouContainer: {
    alignItems: 'center',
    marginLeft: 11,
  },
  tabSelectedText: {
    ...Fonts.normalize(Fonts.mediumBold),
  },
  tabSelectedUnderline: {
    backgroundColor: '#fff',
    height: 3,
    marginTop: normalize(4),
    width: 30,
  },
  tabText: {
    ...Fonts.normalize(Fonts.mediumBold),
    color: Colors.lightGrey,
  },
  tabUnselectedUnderline: {
    backgroundColor: '#fff00',
    height: 3,
    marginTop: normalize(4),
    width: 0,
  },
  timerText: {
    ...Fonts.normalize(Fonts.caption),
    marginLeft: 4,
    top: 1,
  },
  topAlphaGradient: {
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  kidsLeftContainer: {
    opacity: 0,
  },
});
