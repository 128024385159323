import React, { useCallback } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { useAiFigureCharacterNavigator } from '../../AiFigure/services/hooks';
import { Fonts } from '../../Common/services/utils/AppConstants';
import { DMSpeaker } from '../entities';

type Props = {
  speaker: DMSpeaker;
  isFromTestPrep?: boolean;
};

export const MessageCustomHeader = ({
  speaker,
  isFromTestPrep,
}: Props): React.ReactElement => {
  const handleCharacterPress = useAiFigureCharacterNavigator();

  const handleOpenCharacterProfile = useCallback(() => {
    if (isFromTestPrep) {
      return;
    }

    handleCharacterPress(speaker.name);
  }, [isFromTestPrep, speaker?.name, handleCharacterPress]);

  return (
    <TouchableOpacity
      disabled={isFromTestPrep}
      onPress={handleOpenCharacterProfile}>
      <View style={styles.headerContainer}>
        <FastImage source={{ uri: speaker.avatarUrl }} style={styles.avatar} />
        <Text style={styles.headerTitle}>{speaker.name}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    ...Fonts.captionBold,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 5,
    marginHorizontal: 5,
    left: -25,
  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
});

export const renderMessageCustomHeader = (
  speaker: DMSpeaker,
  isFromTestPrep: boolean,
): React.ReactElement => (
  <MessageCustomHeader speaker={speaker} isFromTestPrep={isFromTestPrep} />
);
