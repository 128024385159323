import * as React from 'react';
import { useEffect, useMemo } from 'react';
import {
  Animated,
  Easing,
  StyleSheet,
  TextInput,
  View,
  ViewStyle,
} from 'react-native';
import Svg, { Circle, G } from 'react-native-svg';

import { roundToTenth } from '../../services/utils';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
} from '../../services/utils/AppConstants';

const AnimatedTextInput = Animated.createAnimatedComponent(TextInput);

const PROGRESS_ANIMATION_DELAY = 100;
const PROGRESS_ANIMATION_DURATION = 1000;

type Props = {
  percentage?: number;
  radius?: number;
  strokeWidth?: number;
  duration?: number;
  color?: string;
  textColor?: string;
  max?: number;
  strokeOpacity?: string;
  showFeedback?: boolean;
  progressIndicator?: number;
  containerStyle?: ViewStyle;
  children?: React.ReactNode;
  hideText?: boolean;
};

export default ({
  percentage = 0,
  radius = 53,
  strokeWidth = 5,
  duration = 500,
  color = Colors.buttonGreen,
  max = 100,
  children,
  strokeOpacity = '0.1',
  showFeedback = false,
  progressIndicator = 0,
  containerStyle,
  hideText,
}: Props): React.ReactElement => {
  const animated = React.useRef(new Animated.Value(0)).current;
  const progressAnimated = React.useRef(new Animated.Value(1)).current;
  const circleRef = React.useRef<any>();
  const progressOverlayRef = React.useRef<any>();
  const inputRef = React.useRef<TextInput>();
  const circumference = 2 * Math.PI * radius;
  const halfCircle = radius + strokeWidth;
  const [isProgressPositive, setIsProgressPositive] = React.useState(false);

  const progressAnimation = useMemo(() => {
    return Animated.timing(progressAnimated, {
      toValue: 1,
      delay: PROGRESS_ANIMATION_DELAY,
      duration: PROGRESS_ANIMATION_DURATION,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    });
  }, [progressAnimated]);

  const animation = (toValue: number) => {
    return Animated.timing(animated, {
      toValue,
      duration,
      useNativeDriver: true,
      easing: Easing.out(Easing.ease),
    }).start();
  };

  React.useEffect(() => {
    animated.addListener(v => {
      const maxPerc = (100 * v.value) / max;
      const strokeDashoffset = circumference - (circumference * maxPerc) / 100;
      if (inputRef?.current) {
        inputRef.current?.setNativeProps({
          text: `${roundToTenth(v.value)}%`,
        });
      }
      if (circleRef?.current) {
        circleRef.current?.setNativeProps({
          strokeDashoffset,
        });
      }
    });

    progressAnimated.addListener(v => {
      if (progressOverlayRef?.current) {
        progressOverlayRef.current?.setNativeProps({
          r: (1 - v.value) * (radius - strokeWidth),
        });
      }
    });

    if (duration > 0) {
      animation(percentage);
    } else {
      animated.setValue(percentage);
    }

    return () => {
      animated.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage]);

  useEffect(() => {
    if (showFeedback && progressIndicator !== 0) {
      progressAnimation.reset();
      progressAnimated.setValue(0);
      progressAnimation.start();
      setIsProgressPositive(progressIndicator > 0);
    }
  }, [showFeedback, progressIndicator, progressAnimated, progressAnimation]);

  const renderText = () => {
    if (hideText) {
      return null;
    }

    return (
      <AnimatedTextInput
        ref={inputRef}
        underlineColorAndroid="transparent"
        editable={false}
        defaultValue="0"
        style={[
          StyleSheet.absoluteFillObject,
          isAndroidPlatform ? styles.progressTextAndroid : styles.progressText,
        ]}
      />
    );
  };

  return (
    <View
      style={[
        styles.container,
        {
          width: radius * 2,
          height: radius * 2,
        },
        containerStyle,
      ]}>
      {children ? children : renderText()}
      <Svg
        style={styles.svgContainer}
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${halfCircle * 2} ${halfCircle * 2}`}>
        <G rotation="-90" origin={`${halfCircle}, ${halfCircle}`}>
          <Circle
            ref={circleRef}
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={color}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeDashoffset={circumference}
            strokeDasharray={circumference}
          />
          <Circle
            cx="50%"
            cy="50%"
            r={radius}
            fill="transparent"
            stroke={'#FFFFFF3D'}
            strokeWidth={strokeWidth}
            strokeLinejoin="round"
            strokeOpacity={strokeOpacity}
          />
          <Circle
            ref={progressOverlayRef}
            cx="50%"
            cy="50%"
            r={0}
            fill={isProgressPositive ? Colors.answerRight : Colors.answerWrong}
          />
        </G>
      </Svg>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressTextAndroid: {
    ...Fonts.xsmallBold,
    textAlign: 'center',
    lineHeight: 9,
    fontSize: 7.5,
  },
  progressText: {
    ...Fonts.xsmallBold,
    textAlign: 'center',
    fontSize: 8,
  },
  svgContainer: {
    position: 'absolute',
  },
});
