/**
 * Retries a function until the predicate is true or the maximum number of retries is reached.
 * @param {Function} fn - The function to be retried.
 * @param {Function} predicate - The predicate function to test the result.
 * @param {number} retries - The maximum number of retries.
 * @param {number} delay - The delay between retries in milliseconds.
 * @param {T} defaultResponse - The default response to return if the predicate is not satisfied within the maximum number of retries.
 * @returns {Promise<T>} - The result of the function if the predicate is true.
 * @throws {Error} - Throws an error if the predicate is not satisfied within the maximum number of retries.
 */
export const retryUntil = async <T>(
  fn: () => Promise<T>,
  predicate: (result: T) => boolean,
  retries: number,
  delay: number,
  defaultResponse: T,
): Promise<T> => {
  if (retries === 0) {
    return defaultResponse;
  }
  const result = await fn();
  if (predicate(result)) {
    return result;
  }
  await new Promise(resolve => setTimeout(resolve, delay));
  return retryUntil(fn, predicate, retries - 1, delay, defaultResponse);
};
