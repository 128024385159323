import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';

export const SATFreeTrialHeaderView = (): React.ReactElement => {
  return (
    <View style={styles.header}>
      <Text style={styles.title}>
        {locale.bootcamp_paywall.sat_free_trial_end_heading}
      </Text>
      <Text style={styles.mainHeading}>
        {locale.bootcamp_paywall.sat_free_trial_end_headline}
      </Text>
      <Text style={styles.subheading}>
        {locale.bootcamp_paywall.sat_free_trial_end_description}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    alignItems: 'center',
    padding: 5,
  },
  title: {
    ...Fonts.smallBold,
    color: Colors.emeraldGreen,
    marginTop: 10,
  },
  mainHeading: {
    ...Fonts.xxlargeBold,
    marginTop: 5,
  },
  subheading: {
    ...Fonts.caption,
    textAlign: 'center',
    marginVertical: 5,
    color: Colors.white60,
  },
});
