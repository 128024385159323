import { Platform } from 'react-native';

import {
  ATTRIBUTION_PROD_SERVER_URL,
  ATTRIBUTION_TEST_SERVER_URL,
  ATTRIBUTION_SECRET_KEY,
} from '../../../../environment';
import {
  isAppStoreBuild,
  isProductionEnv,
} from '../../../Common/services/utils';

import { getAttributionToken } from './AdServices';

const getAttributionUrl = (): string => {
  return isProductionEnv() && isAppStoreBuild()
    ? ATTRIBUTION_PROD_SERVER_URL
    : ATTRIBUTION_TEST_SERVER_URL;
};

const sendTokenToServer = async (url: string, token: string): Promise<void> => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'x-secret-key': ATTRIBUTION_SECRET_KEY,
    },
    body: token,
  });

  if (!response.ok) {
    throw new Error('Failed to send attribution token');
  }
};

export const sendAttributionToken = async (): Promise<void> => {
  if (Platform.OS !== 'ios') {
    return;
  }

  try {
    const token = await getAttributionToken();
    const url = getAttributionUrl();
    await sendTokenToServer(url, token);
  } catch (error) {
    console.error('Error sending attribution token:', error);
  }
};
