import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import { updateMixpanelUserProfile } from '../../../../Auth/services/slices';
import {
  CommonAppGoalKeys,
  CommonExamType,
  SubmitDataType,
} from '../../../../Common/entities';
import { submitDataGraphQLCall } from '../../../../Common/graphql/mutations';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  enrollUserToCourse,
  setCurrentSelectedCourse,
  setShowCourseListOnboarding,
  setShowCourseListStudyTab,
} from '../../../../CourseEnrollment/services/slices';
import { mapSATCourseData } from '../../../../Learn/services/mappers';
import { mapSATPaywallWithApAddonSelectionStatus } from '../../../../Paywall/services/mappers';
import {
  resetBootcampPaywallSlice,
  setBootcampPaywallLoading,
} from '../../../../Paywall/services/slices';
import {
  getUserSATProgramDetails,
  setSATFreeTrialEnded,
} from '../../../../SAT/services/slices';
import { setShowTutorialScreen } from '../../../../Tutorial/services/slices';
import { getUserAccess } from '../../../../UserAccess/services/slices';
import {
  BootcampEntryPoint,
  ProceedSATBootcampFlowAfterPurchaseParams,
} from '../../../entities';
import {
  mapSatBootcampEntitlementStatus,
  mapSatBootcampSubmitData,
} from '../../mappers';
import {
  ONBOARDING_SLICE_NAME,
  OnboardingSliceState,
  resetExamInputData,
  setBootcampEntryPoint,
  setDoOnboardingFlowAfterSignup,
  setExamType,
  setIsOnboardingCompleted,
  setShouldOpenSatBootcampFlowForUpsell,
  setShouldPreventScreenListenerReset,
  setShouldUseSatBootcampDiscount,
  setShowEntitlementScreen,
  setSignUpExamType,
} from '../OnboardingSlice';

import { proceedRedirectionAfterSATBootcampPurchase } from './ProceedRedirectionAfterSATBootcampPurchaseAction';
import { trackEntitlementRedemption } from './TrackEntitlementRedemptionAction';

export const proceedSATBootcampFlowAfterPurchase = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedSATBootcampFlowAfterPurchase`,
  async (
    props: ProceedSATBootcampFlowAfterPurchaseParams | undefined,
    thunkApi,
  ) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const courseEnrollmentState = rootState.courseEnrollments;
      const entitlementType = mapSatBootcampEntitlementStatus(rootState);
      const from = rootState.onboarding.bootcampEntryPoint;

      let blockingPromises: Promise<unknown>[] = [];

      const SATCourse = mapSATCourseData(rootState);
      if (SATCourse) {
        const followedSATCourse = courseEnrollmentState?.following?.find(
          f => f.id === SATCourse.id,
        );

        if (!followedSATCourse) {
          blockingPromises.push(
            thunkApi
              .dispatch(
                enrollUserToCourse({
                  course: SATCourse,
                  goal: CommonAppGoalKeys.CRUSH_UPCOMING_TEST,
                  isFromCoursePicker: false,
                  skipRedirectToPaywall: true,
                }),
              )
              .unwrap(),
          );

          trackAnalyticsEvent(Analytics.followCourseSatBootcamp, {
            from,
          });
        }
      }

      thunkApi.dispatch(setShowTutorialScreen(false));
      thunkApi.dispatch(setShowCourseListOnboarding(false));
      thunkApi.dispatch(setShowCourseListStudyTab(false));
      thunkApi.dispatch(setExamType(CommonExamType.SAT));

      if (props?.resetUpsellState) {
        thunkApi.dispatch(setBootcampEntryPoint(undefined));

        thunkApi.dispatch(setShouldOpenSatBootcampFlowForUpsell(false));
        thunkApi.dispatch(setShouldUseSatBootcampDiscount(false));
        thunkApi.dispatch(setShouldPreventScreenListenerReset(false));
        thunkApi.dispatch(resetExamInputData());
        thunkApi.dispatch(setSATFreeTrialEnded(false));
      }

      const optedForAddon = mapSATPaywallWithApAddonSelectionStatus(rootState);
      blockingPromises.push(
        submitDataGraphQLCall({
          type: SubmitDataType.SAT_BOOTCAMP_ONBOARDING,
          metadata: mapSatBootcampSubmitData({
            SATProgramDetails: rootState.onboarding.SATProgramDetails,
            optedForAddon,
          }),
        }).catch(() => undefined),
      );

      await Promise.all(blockingPromises);
      await thunkApi.dispatch(getUserAccess()).unwrap();

      if (SATCourse) {
        thunkApi.dispatch(
          setCurrentSelectedCourse({
            id: SATCourse.id,
            tab: null,
          }),
        );
      }

      if (props?.fetchUserProgramDetails) {
        await thunkApi.dispatch(getUserSATProgramDetails()).unwrap();
      }

      thunkApi.dispatch(updateMixpanelUserProfile());
      trackAnalyticsEvent(Analytics.purchasedSATBootcamp, {});
      thunkApi.dispatch(setDoOnboardingFlowAfterSignup(false));
      thunkApi.dispatch(setShowEntitlementScreen(false));

      if (entitlementType && props?.shouldSendEntitlementCheck) {
        thunkApi.dispatch(
          trackEntitlementRedemption({
            from: from || BootcampEntryPoint.SAT_BOOTCAMP_INTRO,
            entitlementType: {
              sat: entitlementType,
            },
          }),
        );
      }

      if (props?.redirectAfterPurchase) {
        thunkApi.dispatch(setIsOnboardingCompleted(true));
        thunkApi.dispatch(proceedRedirectionAfterSATBootcampPurchase());
      }

      thunkApi.dispatch(setBootcampPaywallLoading(false));
      thunkApi.dispatch(resetBootcampPaywallSlice());
      thunkApi.dispatch(setSignUpExamType(undefined));
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const proceedSATBootcampFlowAfterPurchaseExtraReducers = (
  builder: ActionReducerMapBuilder<OnboardingSliceState>,
): void => {
  builder.addCase(proceedSATBootcampFlowAfterPurchase.pending, state => {
    state.isLoading = true;
  });
  builder.addCase(proceedSATBootcampFlowAfterPurchase.rejected, state => {
    state.isLoading = false;
  });
  builder.addCase(proceedSATBootcampFlowAfterPurchase.fulfilled, state => {
    state.isLoading = false;
  });
};
