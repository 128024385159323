import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  ImageBackground,
  Linking,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { showMessage } from 'react-native-flash-message';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { ContentCard, Course, Unit } from '../../Common/entities';
import {
  useRefWithSubscribe,
  useSafeAreaCustomInsets,
} from '../../Common/services/hooks';
import { mapDataToUnitTestPayload } from '../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { addUpdateCourseUnitEventListener } from '../../Notification/events/NotificationEmitters';
import { TrackedUserEventType } from '../../UserEvent/entities';
import { trackUserEvent } from '../../UserEvent/services/slices';
import { TestFeedHeader, TestPreviewInfoView } from '../components';
import { TestAttempt, TestType } from '../entities';
import { useTestDetailNavigator } from '../services/hooks';
import {
  isFetchingTestForUnitSelector,
  isFrqLoadingSelector,
  resetCurrentTestLastFetchedOn,
  shareFrqEmailLinkAndOpenBrowser,
  startNewTest,
} from '../services/slices';

const TEST_PREP_IMAGE_SOURCE =
  'https://teachtok-images.s3.amazonaws.com/test-prep-background.png';

export enum TestPreviewOrigin {
  TEST_PREP_TAB = 'testPrepTab',
  TEST_PREP_COMPLETION = 'testPrepCompletion',
  TARGETED_PRACTICE_COMPLETION = 'targetedPracticeCompletion',
}

export type TestPreviewProps = {
  course: Course;
  unit: Unit;
  contentCard?: ContentCard;
  origin: TestPreviewOrigin;
};

export type TestScreenProps = {
  route: { params: TestPreviewProps };
};

export type LoadingReplacerElementProps = {
  isLoading: boolean;
  children: React.ReactElement;
};

const bestPerformance = (bestAttempt: TestAttempt) => (
  <View style={styles.testInfoRow}>
    <AssetImage
      asset={Assets.test.yellowStar}
      height={28}
      width={28}
      fill="white"
    />
    <Text style={styles.bestAttemptText}>
      {locale.testPrep.best_performance}
      <Text style={styles.bestAttemptTextBold}>
        {locale.testPrep.best_performance_ap.replace(
          '${GRADE}',
          bestAttempt.grade,
        )}
      </Text>
    </Text>
  </View>
);

const LoadingReplacerElement = (
  props: LoadingReplacerElementProps,
): React.ReactElement => {
  if (props.isLoading) {
    return <ActivityIndicator size="small" color={Colors.white80} />;
  }

  return props.children;
};

export const TestPreviewScreen = (
  props: TestScreenProps,
): React.ReactElement => {
  const [course, setCourse] = useState<Course>(props.route.params.course);

  const [unit, setUnit] = useState<Unit>(props.route.params.unit);

  const bestAttempt = useAppSelector(
    state => state.testPrep.bestAttempts[unit.id],
  );

  const attemptsHistory = useAppSelector(
    state => state.testPrep.attemptsHistory[unit.id],
  );

  const { safeAreaBottom } = useSafeAreaCustomInsets();

  const dispatch = useAppDispatch();

  const { handleOpenTestFlowScreen } = useTestDetailNavigator();

  const isLoading = useAppSelector(state => state.testPrep.isLoading);
  const frqLoading = useAppSelector(state =>
    isFrqLoadingSelector(state.testPrep, unit.id),
  );

  const isFetchingTestForUnit = useAppSelector(
    state => isFetchingTestForUnitSelector(state.testPrep, unit.id),
    (prev, next) => prev === next,
  );
  const isFetchingTestForUnitRef = useRefWithSubscribe(isFetchingTestForUnit);

  const currentTest = useAppSelector(state => state.testPrep.currentTest);

  const testInfoRowData = useMemo(() => {
    const numberOfQuestions = currentTest?.questions.length.toString();
    return [
      {
        image: Assets.test.timerClock,
        text: locale.testPrep.first_test_info_row
          .replace('${N}', numberOfQuestions ?? 'N')
          .replace('${N}', numberOfQuestions ?? 'N')
          .replace('${N}', numberOfQuestions ?? 'N')
          .replace('${X}', unit.number.toString() ?? 'X'),
        size: 28,
      },
      {
        image: Assets.test.avatar,
        text: locale.testPrep.second_test_info_row,
        size: 28,
      },
      {
        image: Assets.test.bookMark,
        text: locale.testPrep.third_test_info_row,
        size: 28,
      },
    ];
  }, [currentTest, unit.number]);

  const frqInfoRowData = useMemo(
    () => [
      {
        image: Assets.test.notes,
        text: locale.testPrep.frq_first_test_info_row,
        size: 28,
      },
      {
        image: Assets.test.chat,
        text: locale.testPrep.frq_second_test_info_row,
        size: 28,
      },
      {
        image: Assets.test.laptop,
        text: locale.testPrep.frq_third_test_info_row,
        size: 28,
      },
    ],
    [],
  );

  const frqButtonLoading = useMemo(
    () => isLoading || frqLoading,
    [isLoading, frqLoading],
  );

  useEffect(() => {
    if (!isFetchingTestForUnitRef.current) {
      dispatch(
        startNewTest({
          course: course,
          unit: unit,
          testType: TestType.AP_TEST,
        }),
      );
    }

    const nextTestNumber = (attemptsHistory?.totalAttempts || 0) + 1;
    trackAnalyticsEvent(Analytics.unitTestOpened, {
      ...mapDataToUnitTestPayload(course, unit, nextTestNumber),
      from: props.route.params?.origin,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, props.route.params?.origin, unit, isFetchingTestForUnitRef]);

  const handleStartTestPress = () => {
    if (!currentTest || isLoading) {
      return;
    }
    trackAnalyticsEvent(Analytics.unitTestStarted, {
      ...mapDataToUnitTestPayload(course, unit, currentTest.testNumber),
      from: props.route.params?.origin,
    });

    dispatch(resetCurrentTestLastFetchedOn({ unitId: unit.id }));
    handleOpenTestFlowScreen(course, unit);
  };

  const handleOpenBrowser = useCallback(async () => {
    if (!currentTest?.id || frqButtonLoading) {
      return;
    } else if (!currentTest?.externalUrl) {
      dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.FrqTestInteracted,
          courseId: currentTest.course.id,
          unitId: currentTest.unit.id,
        }),
      );
      trackAnalyticsEvent(Analytics.frqLinkMissing, {
        course: currentTest.course.name,
      });
      showMessage({
        message: locale.testPrep.frq_tutor_missing,
        duration: 5000,
      });
      return;
    } else {
      dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.FrqTestInteracted,
          courseId: currentTest.course.id,
          unitId: currentTest.unit.id,
        }),
      );
      trackAnalyticsEvent(Analytics.frqOpenLinkLocalRequest, {
        course: currentTest.course.name,
      });

      try {
        const canOpenUrl = await Linking.canOpenURL(currentTest.externalUrl);

        if (canOpenUrl) {
          trackAnalyticsEvent(Analytics.frqOpenLinkLocal, {
            course: currentTest.course.name,
          });

          dispatch(shareFrqEmailLinkAndOpenBrowser());
        } else {
          trackAnalyticsEvent(Analytics.frqOpenLinkLocalFail, {
            course: currentTest.course.name,
            reason: locale.testPrep.cannot_url_open,
            urlToOpen: currentTest.externalUrl,
          });

          showMessage({
            type: 'danger',
            message: locale.testPrep.frq_link_cannot_open,
            duration: 5000,
          });
        }
      } catch (e) {
        trackAnalyticsEvent(Analytics.frqOpenLinkLocalFail, {
          course: currentTest.course.name,
          reason: locale.common.error,
          urlToOpen: currentTest.externalUrl,
        });

        showMessage({
          type: 'danger',
          message: locale.testPrep.frq_link_cannot_open,
          duration: 5000,
        });
      }
    }
  }, [currentTest, frqButtonLoading, dispatch]);

  useEffect(() => {
    const unsubscribe = addUpdateCourseUnitEventListener(event => {
      if (event.course !== course && event.unit !== unit) {
        setCourse(event.course);
        setUnit(event.unit);
      }
    });
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <ScrollView
      style={[styles.container]}
      showsVerticalScrollIndicator={true}
      contentContainerStyle={[
        styles.contentContainer,
        {
          paddingBottom: safeAreaBottom + 16,
          paddingTop: Sizes.small,
        },
      ]}>
      <ImageBackground
        source={{
          uri: TEST_PREP_IMAGE_SOURCE,
        }}
        style={styles.backgroundImage}
      />

      <View style={styles.bottomGradientContainer}>
        <LinearGradient
          colors={Colors.bottomGradientAppColor}
          style={styles.bottomGradient}
        />
        <View style={styles.bottomGradientView} />
      </View>

      <View style={[styles.previewDataContainer]}>
        {unit && (
          <View style={styles.titleContainer}>
            <Text style={styles.titleText} numberOfLines={1}>
              {locale.courses.unit} {unit.number}{' '}
              {locale.testPrep.practise_test}
            </Text>
            <Text style={styles.descriptionText}>
              {locale.testPrep.test_your_mastery} {locale.courses.unit}{' '}
              {unit.number}
              {':'} <Text style={styles.descriptionTextBold}>{unit.name}</Text>
            </Text>
          </View>
        )}

        <View style={styles.subHeadingContainer}>
          <Text style={styles.subHeadingContainerText} numberOfLines={1}>
            {locale.testPrep.multiple_choice_question}
          </Text>
        </View>

        <TestPreviewInfoView rowData={testInfoRowData} />
      </View>

      <View style={styles.contentPadding}>
        {bestAttempt && (
          <View style={[styles.bestAttemptInfoContainer, styles.gap20]}>
            {bestPerformance(bestAttempt)}
          </View>
        )}
        <TouchableOpacity
          style={styles.continueButton}
          disabled={isLoading}
          onPress={() => handleStartTestPress()}>
          {!isLoading ? (
            <Text style={styles.continueButtonText}>
              {locale.testPrep.start_test}
            </Text>
          ) : (
            <ActivityIndicator size="small" color={Colors.white80} />
          )}
        </TouchableOpacity>
      </View>

      <View style={styles.frqDataContainer}>
        <View style={[styles.titleContainer, styles.frqTitleContainer]}>
          <Text style={styles.subHeadingContainerText} numberOfLines={1}>
            {locale.testPrep.frq_test_title}
          </Text>
        </View>

        <TestPreviewInfoView rowData={frqInfoRowData} />

        <View style={styles.buttonsContainer}>
          <TouchableOpacity
            style={styles.buttonEmailFrq}
            disabled={frqButtonLoading}
            onPress={handleOpenBrowser}>
            <LoadingReplacerElement isLoading={frqButtonLoading}>
              <Text style={styles.continueButtonText}>
                {locale.testPrep.start_frq_tutor}
              </Text>
            </LoadingReplacerElement>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.topGradientContainer}>
        <LinearGradient
          colors={Colors.topicCompletionHeaderGradient}
          start={{ x: 0.5, y: 0.25 }}
          style={styles.topGradient}
        />
      </View>

      <View style={styles.bottomButtonGradientContainer}>
        <LinearGradient
          colors={Colors.bottomGradientTestPrepColor}
          style={styles.bottomButtonGradient}
        />
      </View>

      {unit && <TestFeedHeader />}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  titleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  unitHeading: {
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  frqTitleContainer: {
    paddingTop: Sizes.normalizeIP14PM(20),
  },
  bottomGradientContainer: {
    top: 200,
    width: '100%',
    position: 'absolute',
    zIndex: 20,
  },
  bottomGradient: {
    height: 100,
  },
  testInfoRow: {
    flexDirection: 'row',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  gap20: {
    gap: 20,
  },
  continueButton: {
    backgroundColor: Colors.lightBlue,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 12,
    marginTop: 12,
  },

  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
  },
  buttonEmailFrq: {
    flexGrow: 1,
    minHeight: 44,
    alignContent: 'center',
    backgroundColor: Colors.lightGreen,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: Sizes.small,
    paddingHorizontal: Sizes.small,
  },
  buttonFrqIcon: {
    height: 44,
    width: 44,
    backgroundColor: Colors.white10,
    borderRadius: Sizes.small,
    marginLeft: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  continueButtonText: {
    ...Fonts.semiLarge,
  },
  bestAttemptTextBold: {
    ...Fonts.captionBold,
    color: Colors.white80,
    flex: 1,
  },
  previewDataContainer: {
    paddingTop: 84,
    paddingHorizontal: 16,
    width: '100%',
    gap: Sizes.normalizeIP14PM(12),
    zIndex: 30,
  },
  frqDataContainer: {
    paddingHorizontal: 16,
    marginTop: Sizes.semiMedium,
    width: '100%',
    gap: 12,
    zIndex: 30,
  },
  contentPadding: {
    paddingHorizontal: 16,
    gap: Sizes.normalizeIP14PM(5),
    zIndex: 30,
  },
  contentContainer: {
    justifyContent: 'flex-end',
    backgroundColor: Colors.darkBlue,
    minHeight: '100%',
  },
  container: {
    flex: 1,
    backgroundColor: Colors.black100,
    paddingBottom: 50,
  },
  titleText: {
    ...Fonts.xxxlargeBold,
    marginBottom: 10,
    textAlign: 'center',
  },
  descriptionText: {
    ...Fonts.medium,
    color: Colors.white70,
    textAlign: 'center',
    paddingBottom: Sizes.medium,
  },
  descriptionTextBold: {
    ...Fonts.mediumBold,
    color: Colors.white70,
    textAlign: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    width: '100%',
    height: Sizes.normalizeIP14PM(400),
    top: 0,
    opacity: 0.2,
  },
  bottomGradientView: {
    backgroundColor: Colors.bottomGradientAppColor[1],
    width: '100%',
    height: Dimensions.get('window').height / 2,
  },
  testInfoAssetContainer: {
    width: Sizes.normalizeIP14PM(34),
    alignItems: 'center',
    alignSelf: 'center',
  },
  bestAttemptInfoContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 14,
    borderColor: Colors.white12,
    marginTop: Sizes.normalizeIP14PM(20),
  },
  bestAttemptText: {
    ...Fonts.caption,
    color: Colors.white80,
    flex: 1,
  },
  subHeadingContainerText: {
    ...Fonts.normalize(Fonts.captionBold),
  },
  subHeadingContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },

  topGradientContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: 200,
    zIndex: 20,
  },
  topGradient: {
    height: 200,
  },

  bottomButtonGradientContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: 500,
    zIndex: 20,
  },
  bottomButtonGradient: {
    height: 500,
  },
});
