import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  MainStack,
  TabNames,
} from '../../../../Common/services/utils/AppConstants';
import { setSATFreeTrialEnded } from '../../../../SAT/services/slices';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const proceedRedirectionAfterSATBootcampPurchase = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedRedirectionAfterSATBootcampPurchase`,
  async (_params, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const isAuthenticated = Boolean(rootState.auth.authUser?.id);
      const navigation = RootNavigatorRef.current;

      if (!navigation?.isReady() || !isAuthenticated) {
        return;
      }

      thunkApi.dispatch(setSATFreeTrialEnded(false));

      navigation?.reset({
        index: 0,
        routes: [
          {
            name: MainStack.BOTTOM_TABS,
            params: {
              screen: TabNames.TEST,
            },
          },
        ],
      });
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
