export const API_KEY = 'da2-evc52nvf4zabjaoidivjftjz4y';
export const AWS_REGION = 'us-east-1';
export const AWS_USER_POOL_ID = 'us-east-1_aDPC6WHl1';
export const AWS_POOL_WEB_CLIENT_ID = '3n86afr001ql68umpp319pts4n';
export const AWS_AUTHENTICATION_TYPE = 'AMAZON_COGNITO_USER_POOLS';
export const SENTRY_DSN =
  'https://aa677d86146b4070bffd74bb6e788215@o4504227135291392.ingest.sentry.io/4505549745815552';
export const IDENTITY_POOL_ID =
  'us-east-1:5ff921ca-fc91-4e95-b285-ad1d79e92e69';
export const AWS_BUCKET_NAME = 'alpha-coach-bot-students-assets';
export const AWS_CUSTOM_ENV_URL_PREFIX =
  'https://s3.amazonaws.com/assets.gt.school/';
export const CONTENT_URL_PREFIX =
  'https://alpha-coach-bot-students-assets.s3.amazonaws.com/';

export const GRAPHQL_ENDPOINT =
  'https://wopirgliozbidi3hbg6ytb7qwi.appsync-api.us-east-1.amazonaws.com/graphql';

export const WEB_APP_LINK = 'https://app.teachtap.com';
export const WEB_APP_PREVIEW_LINK =
  'https://teachtok-pr{PR_NUMBER}.rp.devfactory.com';

export const WEB_APP_REDIRECT_LINK =
  'https://teachtap.onelink.me/AGu6/oeklzf3j';

export const FEEDBACK_GOOGLE_FORM_ENDPOINT =
  'https://docs.google.com/forms/u/0/d/e/1FAIpQLSf-GX3207iMT1wgsCfsHlhvwm9jz9O7iP_Y0Y3BEcUEmuClTw/formResponse';
export const EXAM_RESULTS_FEEDBACK_GOOGLE_FORM_ENDPOINT =
  'https://docs.google.com/forms/d/e/1FAIpQLSfBeI3Tos59HQZGNfoFQxxtDx-mUOd9ebODvmNUb8YriwPpjg/formResponse';

export const SEGMENT_API_KEY = 'yUAehLFEaQ8auqTHzmbhx0ANlcg1al7T';
export const SEGMENT_API_DEV_KEY = 'OcDq9YlIGEwuz7IT0LD2uBfmIrW6y4Eo';

export const PLATFORM_NAME = 'TeachTap';

export const APP_BUCKET_URL = 'https://teachtok-images.s3.amazonaws.com/';

export const APPSFLYER_DEV_KEY = 'e6LVrVn4ixnDFdvkF76nVj';

export const APPSFLYER_APPID = '6449712355';

export const MIXPANEL_DEV_TOKEN = '4ed203b16fb3b1acb6a62be2bd8aeadc';
export const MIXPANEL_TEACHTAP_PROD_TOKEN = '012d601c422e5e8e1ecbd4493adbc723';

export const ENVIRONMENT_CONFIG_S3_URL =
  'https://s3.amazonaws.com/assets.teachtap.com/app-configs/production/environment.json';

export const ATTRIBUTION_PROD_SERVER_URL =
  'http://34.28.99.54:8083/attribution';

export const ATTRIBUTION_TEST_SERVER_URL =
  'http://34.28.99.54:8083/test-attribution';

export const ATTRIBUTION_SECRET_KEY = 'ee631250a0fd76a7b8b5867f21baa6d8';
