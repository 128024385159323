import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment/moment';

import {
  RegisterPurchaseSource,
  RegisterPurchaseType,
} from '../../../entities';
import { HandleProductPurchaseParams } from '../../../entities/PaywallPurchasesActionTypes';
import { registerPurchaseGraphQLCall } from '../../../graphql/mutations/PaywallGraphQLMutation';
import { mapPurchaseSourceBasedOnAppBuild } from '../../mappers';
import { PURCHASE_SLICE_NAME } from '../PurchasesSlice';

export const handleCoursePurchase = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/handleCoursePurchase`,
  async ({ purchase, course, receipt }: HandleProductPurchaseParams) => {
    if (!course?.id) {
      return false;
    }

    const rsp = await registerPurchaseGraphQLCall({
      transactionId: purchase.transactionId ?? 'null',
      receiptData: receipt,
      purchasedAt: moment(Math.round(purchase.transactionDate)).toISOString(),
      purchaseType: RegisterPurchaseType.COURSE,
      purchaseSource: mapPurchaseSourceBasedOnAppBuild(
        RegisterPurchaseSource.PURCHASED,
      ),
      metadata: {
        subjectId: course.subjectId,
        courseId: course.id,
      },
    });
    return rsp.success;
  },
);
