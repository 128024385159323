import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { StudyNavigationProps } from '../../App/navigation/bottomTabs/StudyStackNavigator';
import { useAppSelector } from '../../App/services/hooks';
import Avatar from '../../Common/components/common/Avatar';
import { Course, Unit } from '../../Common/entities';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { formatNumber } from '../../Common/services/utils';
import {
  Colors,
  Fonts,
  StudyStack,
} from '../../Common/services/utils/AppConstants';
import {
  LinearGradient,
  useNavigation,
} from '../../Common/services/utils/polyfills';

import { TestPracticeProgressHeader } from './TestPracticeProgressHeader';

type Props = {
  course: Course;
  unit: Unit;
  isForTopicCompletion?: boolean;
  isForTestCompletion?: boolean;
};

const PROFILE_IMAGE_SIZE = 40;

export const TestPracticeFeedHeader = ({
  course,
  unit,
  isForTopicCompletion,
  isForTestCompletion,
}: Props): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();
  const navigation =
    useNavigation<StudyNavigationProps<StudyStack.TOPIC_COMPLETION_SCREEN>>();

  const totalPoints = useAppSelector(
    state => state.profile.totalAwardedPoints,
    (prev, next) => prev === next,
  );

  const progress = useAppSelector(
    state => state.testPrep.targetedPractices[unit.id]?.progress ?? 0,
  );

  const formattedTotalPoints = useMemo(
    () => formatNumber(totalPoints),
    [totalPoints],
  );

  const showTopicCompletionHeader = useMemo(
    () => isForTopicCompletion || false,
    [isForTopicCompletion],
  );

  const gradientColors = useMemo(() => {
    return showTopicCompletionHeader
      ? Colors.topicCompletionHeaderGradient
      : Colors.topAlphaGradientStrong;
  }, [showTopicCompletionHeader]);

  const headerContainerStyle = useMemo(
    () => [
      styles.headerContainer,
      {
        paddingTop: safeAreaTop,
        height: safeAreaTop + (!showTopicCompletionHeader ? 100 : 40),
      },
    ],
    [safeAreaTop, showTopicCompletionHeader],
  );

  const handleBackPress = useCallback(() => {
    if (isForTestCompletion) {
      navigation.popToTop();
    } else {
      navigation.goBack();
    }
  }, [isForTestCompletion, navigation]);

  return (
    <LinearGradient
      colors={gradientColors}
      start={{ x: 0.5, y: 0.65 }}
      style={headerContainerStyle}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <AssetImage asset={Assets.common.backArrowButtonIcon} height={24} />
          {showTopicCompletionHeader && (
            <Text style={styles.backToCourseText}>
              {locale.testPrep.back_to_course}
            </Text>
          )}
        </TouchableOpacity>

        {!showTopicCompletionHeader && (
          <>
            <TouchableOpacity onPress={handleBackPress}>
              <Avatar image={course.avatar} size={PROFILE_IMAGE_SIZE} />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={handleBackPress}
              style={styles.courseTextContainer}>
              <Text style={styles.courseTitle} numberOfLines={1}>
                {course.name}
              </Text>

              {unit && (
                <Text style={styles.unitTitle} numberOfLines={1}>
                  {locale.courses.unit} {unit.number}・{unit.name}
                </Text>
              )}
            </TouchableOpacity>
          </>
        )}

        <View style={styles.expanded} />

        <View style={styles.ratingContainer}>
          <AssetImage asset={Assets.profile.coinPileIcon} height={20} />
          <Text style={styles.ratingText}>{formattedTotalPoints}</Text>
        </View>
      </View>

      <TestPracticeProgressHeader progress={progress} />
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    alignItems: 'center',
  },
  backToCourseText: {
    ...Fonts.small,
  },
  backButton: {
    flexDirection: 'row',
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanded: {
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  courseTextContainer: {
    marginStart: 8,
    flexShrink: 1,
    marginEnd: 8,
  },
  courseTitle: {
    ...Fonts.caption,
  },
  unitTitle: {
    ...Fonts.caption,
  },
  ratingContainer: {
    flexDirection: 'row',
    paddingVertical: 0,
    paddingHorizontal: 6,
    backgroundColor: Colors.white30,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    marginEnd: 16,
    height: 32,
    columnGap: 4,
  },
  ratingText: {
    ...Fonts.medium,
  },
});
