import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { handleNetworkActionError } from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { mapCardToAnalyticsPayload } from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import {
  Comment,
  DeleteCommentArgs,
  GetCommentsForCardArgs,
  GetCommentsForParentCommentArgs,
  PostCommentArgs,
  PostLocalCommentAction,
  UpdateCommentAction,
} from '../../entities';
import {
  createCommentGraphQLCall,
  deleteCommentGraphQLCall,
  getCommentsGraphQLCall,
} from '../../graphql';
import { mapCommentsResponseToComments, mapTextToComment } from '../mappers';

const SLICE_NAME = 'CommentsSlice';

export const getCommentsForCard = createAsyncThunk(
  `${SLICE_NAME}/getCommentsForCard`,
  async (params: GetCommentsForCardArgs, thunkApi) => {
    try {
      const response = await getCommentsGraphQLCall({
        generatedContentId: params.card.generatedContentId,
      });

      return mapCommentsResponseToComments(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const getCommentsReplies = createAsyncThunk(
  `${SLICE_NAME}/getCommentsForParentComment`,
  async (params: GetCommentsForParentCommentArgs, thunkApi) => {
    try {
      const response = await getCommentsGraphQLCall({
        parentCommentId: params.parentCommentId,
        generatedContentId: params.card.generatedContentId,
      });

      return mapCommentsResponseToComments(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const deleteComment = createAsyncThunk(
  `${SLICE_NAME}/deleteComment`,
  async (params: DeleteCommentArgs, thunkApi) => {
    try {
      await deleteCommentGraphQLCall(params.comment.id);
      if (params.parentCommentId) {
        await thunkApi
          .dispatch(
            getCommentsReplies({
              card: params.card,
              parentCommentId: params.parentCommentId,
              hideLoading: true,
            }),
          )
          .unwrap();
      }
      thunkApi.dispatch(
        getCommentsForCard({ card: params.card, silent: true }),
      );
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        thunkApi.dispatch(
          getCommentsForCard({ card: params.card, silent: true }),
        );
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const postComment = createAsyncThunk(
  `${SLICE_NAME}/postComment`,
  async (params: PostCommentArgs, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const loggedinUser = state.auth.authUser!;

    thunkApi.dispatch(
      postLocalComment({
        text: params.text,
        author: loggedinUser,
        card: params.card,
        parentCommentId: params.parentCommentId,
      }),
    );

    try {
      await createCommentGraphQLCall({
        generatedContentId: params.card.generatedContentId,
        comment: params.text,
        parentCommentId: params.parentCommentId,
      });

      if (params.parentCommentId) {
        await thunkApi
          .dispatch(
            getCommentsReplies({
              card: params.card,
              parentCommentId: params.parentCommentId,
              hideLoading: true,
            }),
          )
          .unwrap();
      }

      const response = await getCommentsGraphQLCall({
        generatedContentId: params.card.generatedContentId,
      });

      if (params.isFromDM) {
        trackAnalyticsEvent(Analytics.dmAnswerPostComment, {
          ...mapCardToAnalyticsPayload(params.card),
          comment: params.text,
        });
      } else {
        trackAnalyticsEvent(Analytics.postComment, {
          ...mapCardToAnalyticsPayload(params.card),
          comment: params.text,
        });
      }

      return mapCommentsResponseToComments(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        thunkApi.dispatch(getCommentsForCard({ card: params.card }));
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

type State = {
  comments: Record<string, Comment[]>;
  replies: Record<string, Comment[]>;
  repliesLoading: Record<string, boolean>;
  isLoading: boolean;
  error: string | null;
};

const initialState: State = {
  comments: {},
  replies: {},
  repliesLoading: {},
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    postLocalComment(state: State, action: PostLocalCommentAction) {
      const prevComments =
        state.comments[action.payload.card.generatedContentId] ?? [];
      const { text, author } = action.payload;

      const newComment = mapTextToComment(author, text);

      if (action.payload.parentCommentId) {
        state.replies[action.payload.parentCommentId] = [
          newComment,
          ...(state.replies[action.payload.parentCommentId] ?? []),
        ];

        state.comments[action.payload.card.generatedContentId].forEach(
          comment => {
            if (comment.id === action.payload.parentCommentId) {
              comment.replies_count += 1;
            }
          },
        );
      } else {
        // push it to the front of the array
        state.comments[action.payload.card.generatedContentId] = [
          newComment,
          ...prevComments,
        ];
      }
    },
    updateCommentLocally(
      state: State,
      action: PayloadAction<UpdateCommentAction>,
    ) {
      const { card, commentId, fields } = action.payload;
      let prevComments;

      if (action.payload.parentCommentId) {
        prevComments = state.replies[action.payload.parentCommentId] ?? [];
      } else {
        prevComments = state.comments[card.generatedContentId] ?? [];
      }

      const commentIndex = prevComments.findIndex(
        comment => comment.id === commentId,
      );

      if (commentIndex === -1) {
        return;
      }

      const prevComment = prevComments[commentIndex];
      const updatedComment = {
        ...prevComment,
        ...fields,
      };

      if (action.payload.parentCommentId) {
        state.replies[action.payload.parentCommentId] = [
          ...prevComments.slice(0, commentIndex),
          updatedComment,
          ...prevComments.slice(commentIndex + 1),
        ];
      } else {
        state.comments[card.generatedContentId] = [
          ...prevComments.slice(0, commentIndex),
          updatedComment,
          ...prevComments.slice(commentIndex + 1),
        ];
      }
    },
    clearReplyComments(state: State, action: PayloadAction<Comment>) {
      delete state.replies[action.payload.id];
    },
    clearAllReplyComments(state: State) {
      state.replies = {};
    },
  },
  extraReducers: (builder: any) => {
    builder.addCase(getCommentsForCard.pending, (state: State, action: any) => {
      const arg = action.meta.arg as GetCommentsForCardArgs;
      if (!arg.silent) {
        state.isLoading = true;
      }

      state.error = null;
    });

    builder.addCase(
      getCommentsForCard.rejected,
      (state: State, action: any) => {
        const arg = action.meta.arg as GetCommentsForCardArgs;
        if (!arg.silent) {
          state.isLoading = false;
        }
        state.error = action.payload as string;
      },
    );

    builder.addCase(
      getCommentsForCard.fulfilled,
      (state: State, action: any) => {
        const arg = action.meta.arg as GetCommentsForCardArgs;
        state.comments[arg.card.generatedContentId] = action.payload;

        if (!arg.silent) {
          state.isLoading = false;
        }
      },
    );

    builder.addCase(postComment.fulfilled, (state: State, action: any) => {
      const arg = action.meta.arg as PostCommentArgs;
      state.isLoading = false;
      state.comments[arg.card.generatedContentId] = action.payload;
    });

    builder.addCase(deleteComment.pending, (state: State, action: any) => {
      const arg = action.meta.arg as DeleteCommentArgs;
      if (arg.parentCommentId) {
        const parentComment = state.comments[arg.card.generatedContentId].find(
          comment => comment.id === arg.parentCommentId,
        );
        if (parentComment) {
          parentComment.replies_count -= 1;
        }
        state.replies[arg.parentCommentId] = state.replies[
          arg.parentCommentId
        ].filter(comment => comment.id !== action.meta.arg.comment.id);
      } else {
        state.comments[arg.card.generatedContentId] = state.comments[
          arg.card.generatedContentId
        ].filter(comment => comment.id !== action.meta.arg.comment.id);
      }
    });

    builder.addCase(getCommentsReplies.pending, (state: State, action: any) => {
      const arg = action.meta.arg as GetCommentsForParentCommentArgs;
      if (!arg.hideLoading) {
        state.repliesLoading[arg.parentCommentId] = true;
      }
    });

    builder.addCase(
      getCommentsReplies.fulfilled,
      (state: State, action: any) => {
        const arg = action.meta.arg as GetCommentsForParentCommentArgs;
        state.replies[arg.parentCommentId] = action.payload;
        state.repliesLoading[arg.parentCommentId] = false;
      },
    );

    builder.addCase(
      getCommentsReplies.rejected,
      (state: State, action: any) => {
        const arg = action.meta.arg as GetCommentsForParentCommentArgs;
        state.repliesLoading[arg.parentCommentId] = false;
      },
    );
  },
});

export const {
  postLocalComment,
  updateCommentLocally,
  clearReplyComments,
  clearAllReplyComments,
} = slice.actions;

export const CommentsSlice = slice.reducer;
