import { useIsFocused } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FlatList,
  KeyboardAvoidingView,
  NativeSyntheticEvent,
  StyleSheet,
  View,
} from 'react-native';
import { NativeScrollEvent } from 'react-native/Libraries/Components/ScrollView/ScrollView';

import { MessageNavigationProp } from '../../App/navigation/bottomTabs/MessageStackNavigator';
import { useAppSelector } from '../../App/services/hooks';
import { useKeyboardListener } from '../../Common/services/hooks';
import {
  Colors,
  isIOSPlatform,
  MessageStack,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import {
  LinearGradient,
  useNavigation,
} from '../../Common/services/utils/polyfills';
import { useFeedTimeSpentCounter } from '../../Learn/services/hooks';
import {
  MessageTextInputContainer,
  renderMessageCustomHeader,
} from '../../Messages/components';
import { DMSpeaker } from '../../Messages/entities';
import { getChatItemKey } from '../../Messages/services/utils';
import { RaiseHandChatItem } from '../components';
import { useRaiseHandMessagingService } from '../services/hooks';
import { mapStreamingToChat } from '../services/mappers';

export type RaiseHandScreenProps = {
  speaker: DMSpeaker;
  generatedContentId: string;
  courseId?: string;
  selectedOption?: string;
  isCorrect?: boolean;
  isFromTestPrep?: boolean;
  currentQuestionLevelId?: string;
  isMathQuestion?: boolean;
  isSAT?: boolean;
  extraPaddingBottom?: boolean;
};

type ScreenProps = {
  route: { params: RaiseHandScreenProps };
};

const KEYBOARD_VERTICAL_OFFSET = 80;

export const RaiseHandMessageScreen = (
  props: ScreenProps,
): React.ReactElement => {
  const isFocused = useIsFocused();
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useKeyboardListener({
    onKeyboardDidShow: event => setKeyboardHeight(event.endCoordinates.height),
    onKeyboardDidHide: () => setKeyboardHeight(0),
  });
  const speaker = useMemo(
    () => props.route?.params.speaker,
    [props.route?.params?.speaker],
  );

  const isFromTestPrep = useMemo(
    () => props.route.params.isFromTestPrep ?? false,
    [props.route.params.isFromTestPrep],
  );

  const courseId = useMemo(
    () => props.route.params.courseId,
    [props.route.params.courseId],
  );

  const courseIdForFeedTimeSpent = useMemo(() => {
    return isFromTestPrep ? undefined : courseId;
  }, [isFromTestPrep, courseId]);

  const isMathQuestion = useMemo(
    () => props.route.params.isMathQuestion ?? false,
    [props.route.params.isMathQuestion],
  );
  const isSAT = useMemo(
    () => props.route.params.isSAT ?? false,
    [props.route.params.isSAT],
  );

  const currentQuestionLevelId = useMemo(
    () => props.route.params.currentQuestionLevelId,
    [props.route.params.currentQuestionLevelId],
  );

  const extraBottomPadding = useMemo(() => {
    return props.route.params.extraPaddingBottom ?? false;
  }, [props.route.params.extraPaddingBottom]);

  const generatedContentId = useMemo(
    () => props.route?.params.generatedContentId,
    [props.route?.params?.generatedContentId],
  );

  useFeedTimeSpentCounter(courseIdForFeedTimeSpent);

  const {
    messages,
    isFigureTyping,
    handleSendMessage,
    handleGetChatHistory,
    handleSetContentScreen,
  } = useRaiseHandMessagingService(
    speaker,
    generatedContentId,
    isFromTestPrep,
    isSAT,
    isMathQuestion,
    currentQuestionLevelId,
  );

  const navigation =
    useNavigation<MessageNavigationProp<MessageStack.MESSAGE_SCREEN>>();

  const handleMomentumScrollEnd = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      const scrollOffset = event.nativeEvent.contentOffset.y;
      const contentHeight = event.nativeEvent.contentSize.height;
      const layoutHeight = event.nativeEvent.layoutMeasurement.height;

      const hasOffset = contentHeight - layoutHeight > 0;

      // if we have offset and the scrollOffset > 1, we are at the top of the list
      if (hasOffset && scrollOffset > 1) {
        handleGetChatHistory(false);
      }
    },
    [handleGetChatHistory],
  );

  useEffect(() => {
    if (!isFromTestPrep) {
      navigation.setOptions({
        headerTitle: () => renderMessageCustomHeader(speaker, isFromTestPrep),
        headerTitleAlign: 'left',
      });
    }
  }, [isFromTestPrep, navigation, speaker, isMathQuestion]);

  useEffect(() => {
    handleGetChatHistory(true);
  }, [handleGetChatHistory]);

  useEffect(() => {
    if (isFocused) {
      handleSetContentScreen(generatedContentId);
    } else {
      handleSetContentScreen();
    }
  }, [isFocused, generatedContentId, handleSetContentScreen]);

  const keyboardOffset = useMemo(() => {
    if (!isIOSPlatform) {
      return 0;
    }

    return KEYBOARD_VERTICAL_OFFSET;
  }, []);

  const containerStyles = useMemo(() => {
    if (extraBottomPadding) {
      return [styles.container, { paddingBottom: Sizes.large }];
    }

    return styles.container;
  }, [extraBottomPadding]);

  const keyboardAdditionStyle = useMemo(() => {
    return {
      height:
        keyboardHeight > 0 ? keyboardHeight - Sizes.semiMedium : keyboardHeight,
    };
  }, [keyboardHeight]);

  const aiStreamedMessage = useAppSelector(
    state => state.streaming.aiStreamedMessages[generatedContentId],
  );

  const allMessages = useMemo(() => {
    if (aiStreamedMessage && aiStreamedMessage.streaming) {
      return [mapStreamingToChat(aiStreamedMessage, speaker), ...messages];
    }
    return messages;
  }, [aiStreamedMessage, messages, speaker]);

  return (
    <LinearGradient colors={Colors.screenGradient} style={containerStyles}>
      <FlatList
        keyExtractor={getChatItemKey}
        nestedScrollEnabled
        inverted
        scrollEnabled
        data={allMessages}
        onMomentumScrollEnd={handleMomentumScrollEnd}
        onEndReachedThreshold={0.3}
        onEndReached={() => handleGetChatHistory(false)}
        scrollEventThrottle={500}
        contentContainerStyle={styles.messageList}
        renderItem={({ item, index }) => (
          <RaiseHandChatItem
            item={item}
            index={index}
            speaker={speaker}
            isFromTestPrep={isFromTestPrep}
            isFromMathTest={isMathQuestion}
          />
        )}
      />
      <KeyboardAvoidingView
        behavior={isIOSPlatform ? 'padding' : 'height'}
        style={styles.keyboardContainer}
        keyboardVerticalOffset={keyboardOffset}
        enabled={!isFromTestPrep}>
        <MessageTextInputContainer
          onSendPress={message => handleSendMessage(speaker, message)}
          isEditable={!isFigureTyping && !aiStreamedMessage?.streaming}
          speakerPlatformId={speaker.platformId}
          speakerName={speaker.name}
          generatedContentId={generatedContentId}
        />

        {isFromTestPrep && <View style={keyboardAdditionStyle} />}
      </KeyboardAvoidingView>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  messageList: {
    paddingTop: Sizes.small,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  headerContainer: {
    marginTop: Sizes.large,
    marginBottom: Sizes.semiMedium,
  },
  keyboardContainer: {
    paddingTop: Sizes.xsmall,
  },
  curvedContainer: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
});
