import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  SubmitDataInput,
  SubmitDataOutput,
} from '../../entities/CommonRequestsTypes';

export const submitDataGraphQLCall = async (
  input: SubmitDataInput,
): Promise<SubmitDataOutput> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      query: `
        mutation TeachTapSubmitData($input: TeachTapSubmitDataInput!) {
          teachTapSubmitData(input: $input) {
            success
          }
        }
      `,
      variables: {
        input: {
          ...input,
          metadata: JSON.stringify(input.metadata),
        },
      },
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);
  return response.data.data?.teachTapSubmitData;
};
