import moment from 'moment/moment';

import { CommonAppGoalKeys, CommonExamType } from '../../../Common/entities';
import {
  APProgramDetails,
  SATBootcampProgramDetails,
} from '../../../Onboarding/entities';
import { mapAPProgramDetailsToAnalytics } from '../../../Onboarding/services/mappers';
import { getSATBootcampProgramNameDetails } from '../../../Onboarding/services/utils';
import {
  EmailOptInType,
  UserSignupDataInput,
  UserSignupDataOutput,
} from '../../entities';

const handleSATSignupData = (
  SATProgramDetails?: SATBootcampProgramDetails,
): Partial<UserSignupDataOutput> => {
  if (!SATProgramDetails) {
    return {};
  }

  const userLocalDate = moment(
    SATProgramDetails.userLocalTimestamp,
    'YYYY-MM-DDTHH:mm:ss',
  ).format('YYYY-MM-DD');
  const managedExamDate = moment(SATProgramDetails.examDate, 'YYYY-MM-DD')
    .subtract(1, 'days')
    .format('YYYY-MM-DD');

  const bootcampDetails = getSATBootcampProgramNameDetails(
    SATProgramDetails.programName,
  );

  return {
    examDate: SATProgramDetails.examDate,
    bootcampName: SATProgramDetails.programName,
    bootcampPeriod: bootcampDetails?.period,
    bootcampPeriodCount: bootcampDetails?.count,
    bootcampDuration: `${userLocalDate} - ${managedExamDate}`,
    currentScore: SATProgramDetails.currentScore,
    targetScore: SATProgramDetails.targetScore,
    timeCommitment: SATProgramDetails.studyCommitmentTitle,
    studyCommitmentHours: SATProgramDetails.studyCommitmentHours,
  };
};

export const handleAPSignupData = (
  apProgramDetails?: APProgramDetails,
): Partial<UserSignupDataOutput> => {
  if (!apProgramDetails) {
    return {};
  }
  const analyticsData = mapAPProgramDetailsToAnalytics(apProgramDetails);
  return {
    APTargetGrades: analyticsData.targetGrades,
    programDuration: analyticsData.programDuration,
    timeCommitment: analyticsData.timeCommitment,
    studyCommitmentHours: analyticsData.studyCommitmentHours,
  };
};

export const mapUserSignupData = (
  props: UserSignupDataInput,
): UserSignupDataOutput => {
  const {
    emailOptIn,
    examType,
    studentGrade,
    SATProgramDetails,
    apProgramDetails,
  } = props;

  let appGoal: CommonAppGoalKeys | undefined;
  if (examType) {
    appGoal =
      examType === CommonExamType.SAT
        ? CommonAppGoalKeys.CRUSH_SAT
        : CommonAppGoalKeys.CRUSH_AP_TEST;
  }

  return {
    emailOptIn: emailOptIn ? EmailOptInType.TRUE : EmailOptInType.FALSE,
    appGoal,
    studentGrade,
    examType,
    ...handleSATSignupData(SATProgramDetails),
    ...handleAPSignupData(apProgramDetails),
  };
};
