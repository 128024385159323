import { createAsyncThunk } from '@reduxjs/toolkit';

import { mapApProgramDetailsAvailableStatus } from '../../../../AP/services/mappers';
import { getAPProgramCourseTargetGrade } from '../../../../AP/services/utils';
import { RootState } from '../../../../App/store';
import { openApProgramFlow } from '../../../../Onboarding/services/slices';
import { setSelectAPGoalScorePopup } from '../../../../Popup/services/slices';
import { mapCourseLockedStatus } from '../../../../UserAccess/services/mappers';
import { EnrollUserToAPCourseParams } from '../../../entities';
import { getBootcampEntryPointForPaywall } from '../../utils';
import {
  COURSE_ENROLLMENTS_SLICE_NAME,
  enrollUserToCourse,
} from '../CourseEnrollmentsSlice';

export const enrollUserToAPCourse = createAsyncThunk(
  `${COURSE_ENROLLMENTS_SLICE_NAME}/enrollUserToAPCourse`,
  async (params: EnrollUserToAPCourseParams, thunkAPI) => {
    if (!params.course?.id) {
      return false;
    }

    const rootState = thunkAPI.getState() as RootState;
    const isCourseLocked = mapCourseLockedStatus(rootState, params.course.id);

    if (isCourseLocked) {
      const bootcampEntryPoint = getBootcampEntryPointForPaywall(
        params.paywallEntrypoint,
      );

      thunkAPI.dispatch(
        openApProgramFlow({
          entrypoint: bootcampEntryPoint,
          courseName: params.course.name,
        }),
      );

      return false;
    }

    const targetGrade = getAPProgramCourseTargetGrade(params.course.id);
    const isUserHasApProgram = mapApProgramDetailsAvailableStatus(rootState);
    if (!targetGrade && isUserHasApProgram) {
      thunkAPI.dispatch(
        setSelectAPGoalScorePopup({
          course: params.course,
          entryPoint: params.paywallEntrypoint,
          isFromCoursePicker: params.isFromCoursePicker,
        }),
      );
      return false;
    }

    thunkAPI.dispatch(
      enrollUserToCourse({
        course: params.course,
        entryPoint: params.paywallEntrypoint,
        isFromCoursePicker: params.isFromCoursePicker,
        skipRedirectToPaywall: true,
      }),
    );

    return true;
  },
);
