import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { ProductPurchase, requestPurchase } from 'react-native-iap';

import { PurchasableItem } from '../../../entities';
import { PURCHASE_SLICE_NAME, PurchasesSliceState } from '../PurchasesSlice';

export const purchaseProduct = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/purchaseProduct`,
  async (product: PurchasableItem, thunkApi) => {
    try {
      const purchase = (await requestPurchase({
        sku: product.sku,
        andDangerouslyFinishTransactionAutomaticallyIOS: false,
      })) as ProductPurchase;
      return purchase;
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const purchaseProductExtraReducers = (
  builder: ActionReducerMapBuilder<PurchasesSliceState>,
): void => {
  builder.addCase(purchaseProduct.pending, state => {
    state.isLoading = true;
  });

  builder.addCase(purchaseProduct.fulfilled, state => {
    state.isLoading = false;
  });

  builder.addCase(purchaseProduct.rejected, state => {
    state.isLoading = false;
  });
};
