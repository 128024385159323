import React from 'react';
import { StyleSheet, View } from 'react-native';

import { CommonUpsellFeatureListItem } from '../../../Common/components';
import { Colors, Sizes } from '../../../Common/services/utils/AppConstants';
import { AP_PROGRAM_UPSELL_FEATURE_LIST } from '../../data';

export const ApProgramUpsellOverlayFeatures = (): React.ReactElement => {
  return (
    <View style={styles.featureListContainer}>
      {AP_PROGRAM_UPSELL_FEATURE_LIST.map(feature => (
        <CommonUpsellFeatureListItem key={feature.key} feature={feature.text} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  featureListContainer: {
    flexDirection: 'column',
    width: '100%',
    gap: Sizes.medium,
    padding: Sizes.semiMedium,
    backgroundColor: Colors.white10,
    borderRadius: Sizes.small,
  },
});
