import React, { useMemo } from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';

import { Colors, Sizes } from '../services/utils/AppConstants';

import CircularProgress from './common/CircularProgress';

type Props = {
  percentage: number;
  backgroundColor?: string;
  strokeWidth?: number;
  strokeColor?: string;
  radius?: number;
};

export const CommonProgressIndicator = (props: Props): React.ReactElement => {
  const { backgroundColor, strokeWidth, radius, strokeColor, percentage } =
    props;

  const managedPercentage = useMemo(() => {
    if (isNaN(percentage)) {
      return 0;
    }

    return Math.min(100, Math.max(0, percentage));
  }, [percentage]);

  const viewStyle = useMemo<ViewStyle>(() => {
    const diameter = radius ? radius * 2 : Sizes.xlarge;
    const selectedStrokeWidth = strokeWidth ? strokeWidth : Sizes.small;
    const halfStrokeWidth = selectedStrokeWidth / 2;

    return {
      backgroundColor: backgroundColor ?? Colors.emeraldGreen20,
      borderRadius: diameter,
      height: diameter - Sizes.xsmall - halfStrokeWidth,
      width: diameter - Sizes.xsmall - halfStrokeWidth,
    };
  }, [backgroundColor, radius, strokeWidth]);

  return (
    <CircularProgress
      percentage={managedPercentage}
      radius={radius ?? Sizes.xlarge / 2}
      color={strokeColor ?? Colors.emeraldGreen}
      strokeWidth={strokeWidth ?? Sizes.small}>
      <View style={[styles.circle, viewStyle]} />
    </CircularProgress>
  );
};

const styles = StyleSheet.create({
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#1D4349',
  },
});
