import Assets from '../../App/assets';

import CheckStar from './check_star.svg';
import DiscountIcon from './discount_icon.svg';
import LockIcon from './lock.svg';

export const PaywallAssets = {
  paywallBackground: Assets.getBitmap(require('./paywall_background.png')),
  discountIcon: Assets.getVector(DiscountIcon),
  lockIcon: Assets.getVector(LockIcon),
  ultimatePaywallBackground: Assets.getBitmap(
    require('./ultimate_paywall_background.png'),
  ),
  bestValue: Assets.getBitmap(require('./best_value.png')),
  checkStar: Assets.getVector(CheckStar),
};
