import locale from '../../../App/locale';
import { RootState } from '../../../App/store';
import { ProgramType, Unit } from '../../../Common/entities';
import { SAT_UNIT_CATEGORY } from '../../../Learn/entities';
import { TestScopeType } from '../../../TestPrep/entities';
import { roundToNearestTenth } from '../../../TestPrep/services/utils';
import { SAT_MAX_CATEGORY_SCORE, SAT_MAX_TOTAL_SCORE } from '../../data';
import {
  SATHistoryData,
  SATHistoryDataSummary,
  SATHistoryResponseSummary,
  SATMaxScores,
  SATUnitTestHistoryUnit,
  SATUserPercentileType,
} from '../../entities';

export const mapSATHistoryResponseSummary = (
  SATUnits?: Unit[],
  historyUnitsResponse?: SATUnitTestHistoryUnit[],
): SATHistoryResponseSummary => {
  if (!SATUnits?.length || !historyUnitsResponse?.length) {
    return {
      readingWritingScore: 0,
      mathScore: 0,
      totalScore: 0,
      isAllUnitsTaken: false,
    };
  }

  const sortedUnits = [...SATUnits].sort((a, b) => a.number - b.number);

  const mathUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.MATH,
  );
  const readingWritingUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.READING,
  );

  const readingWritingScore = readingWritingUnits.reduce(
    (acc, unit) =>
      acc + (historyUnitsResponse.find(u => u.id === unit.id)?.bestScore ?? 0),
    0,
  );
  const roundedWritingScore = roundToNearestTenth(readingWritingScore);

  const mathScore = mathUnits.reduce(
    (acc, unit) =>
      acc + (historyUnitsResponse.find(u => u.id === unit.id)?.bestScore ?? 0),
    0,
  );
  const roundedMathScore = roundToNearestTenth(mathScore);

  const allUnitsTaken = SATUnits.every(
    unit =>
      (historyUnitsResponse.find(u => u.id === unit.id)?.totalTestsTaken ?? 0) >
      0,
  );

  return {
    readingWritingScore: roundedWritingScore,
    mathScore: roundedMathScore,
    totalScore: roundedMathScore + roundedWritingScore,
    isAllUnitsTaken: allUnitsTaken,
  };
};

export const mapSATHistorySummary = (
  SATHistory: SATHistoryData,
  SATUserPercentile?: SATUserPercentileType,
  SATUnits?: Unit[],
): SATHistoryDataSummary => {
  if (!SATUnits?.length) {
    return {
      readingWritingScore: 0,
      mathScore: 0,
      totalScore: 0,
      showReadingWriting: false,
      showMath: false,
      showTotal: false,
      anyTestTaken: false,

      maxTotalScore: SAT_MAX_TOTAL_SCORE,
      maxReadingWritingScore: SAT_MAX_CATEGORY_SCORE,
      maxMathScore: SAT_MAX_CATEGORY_SCORE,

      mathUnitsIndex: { first: 0, last: 0 },
      totalUnitsIndex: { first: 0, last: 0 },
      readingWritingUnitsIndex: { first: 0, last: 0 },

      totalPercentile: SATUserPercentile?.[TestScopeType.TOTAL],
    };
  }

  const sortedUnits = [...SATUnits].sort((a, b) => a.number - b.number);
  const readingWritingUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.READING,
  );
  const mathUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.MATH,
  );

  const readingWritingScore = readingWritingUnits.reduce(
    (acc, unit) => acc + (SATHistory[unit.id]?.bestScore ?? 0),
    0,
  );
  const roundedReadingWritingScore = roundToNearestTenth(readingWritingScore);

  const mathScore = mathUnits.reduce(
    (acc, unit) => acc + (SATHistory[unit.id]?.bestScore ?? 0),
    0,
  );
  const roundedMathScore = roundToNearestTenth(mathScore);

  // Just in case the score is 0, we don't want to divide by 0
  const readingWritingTotalScore =
    readingWritingUnits.reduce(
      (acc, unit) => acc + (SATHistory[unit.id]?.totalScore ?? 0),
      0,
    ) || 1;
  const mathTotalScore =
    mathUnits.reduce(
      (acc, unit) => acc + (SATHistory[unit.id]?.totalScore ?? 0),
      0,
    ) || 1;

  const readingWritingUnitsTakenCount = readingWritingUnits.filter(
    u => SATHistory[u.id]?.totalTestsTaken > 0,
  ).length;
  const mathTakenCount = mathUnits.filter(
    u => SATHistory[u.id]?.totalTestsTaken > 0,
  ).length;

  const anyTestTaken = readingWritingUnitsTakenCount + mathTakenCount > 0;

  const shouldShowReadingWriting =
    readingWritingUnitsTakenCount === readingWritingUnits.length;
  const shouldShowMath = mathTakenCount === mathUnits.length;

  return {
    readingWritingScore: roundedReadingWritingScore,
    mathScore: roundedMathScore,
    totalScore: roundedMathScore + roundedReadingWritingScore,

    showReadingWriting: shouldShowReadingWriting,
    showMath: shouldShowMath,
    showTotal: shouldShowReadingWriting && shouldShowMath,
    anyTestTaken,

    maxTotalScore: readingWritingTotalScore + mathTotalScore,
    maxReadingWritingScore: readingWritingTotalScore,
    maxMathScore: mathTotalScore,

    mathUnitsIndex: {
      first: mathUnits[0].number,
      last: mathUnits[mathUnits.length - 1].number,
    },
    totalUnitsIndex: {
      first: sortedUnits[0].number,
      last: sortedUnits[sortedUnits.length - 1].number,
    },
    readingWritingUnitsIndex: {
      first: readingWritingUnits[0].number,
      last: readingWritingUnits[readingWritingUnits.length - 1].number,
    },

    totalPercentile: SATUserPercentile?.[TestScopeType.TOTAL],
  };
};

export const mapSATMaxScoresFromRawHistory = (
  SATRawHistoryUnits?: SATUnitTestHistoryUnit[],
  units?: Unit[],
): SATMaxScores => {
  if (!units?.length || !SATRawHistoryUnits?.length) {
    return {
      readingWriting: 0,
      math: 0,
      total: 0,
    };
  }

  const sortedUnits = [...units].sort((a, b) => a.number - b.number);

  const readingWritingUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.READING,
  );
  const mathUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.MATH,
  );

  const readingWritingTotalScore = readingWritingUnits.reduce((acc, unit) => {
    const rawHistoryUnit = SATRawHistoryUnits.find(u => u.id === unit.id);
    return acc + (rawHistoryUnit?.totalScore ?? 0);
  }, 0);

  const mathTotalScore = mathUnits.reduce((acc, unit) => {
    const rawHistoryUnit = SATRawHistoryUnits.find(u => u.id === unit.id);
    return acc + (rawHistoryUnit?.totalScore ?? 0);
  }, 0);

  return {
    readingWriting: readingWritingTotalScore,
    math: mathTotalScore,
    total: readingWritingTotalScore + mathTotalScore,
  };
};

export const mapSATMaxScores = (
  SATHistory: SATHistoryData,
  units?: Unit[],
): SATMaxScores => {
  if (!units?.length) {
    return {
      readingWriting: 0,
      math: 0,
      total: 0,
    };
  }

  const sortedUnits = [...units].sort((a, b) => a.number - b.number);
  const readingWritingUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.READING,
  );
  const mathUnits = sortedUnits.filter(
    u => u.category === SAT_UNIT_CATEGORY.MATH,
  );

  const readingWritingTotalScore = readingWritingUnits.reduce(
    (acc, unit) => acc + (SATHistory[unit.id]?.totalScore ?? 0),
    0,
  );
  const mathTotalScore = mathUnits.reduce(
    (acc, unit) => acc + (SATHistory[unit.id]?.totalScore ?? 0),
    0,
  );

  return {
    readingWriting: readingWritingTotalScore,
    math: mathTotalScore,
    total: readingWritingTotalScore + mathTotalScore,
  };
};

export const getSATBootcampCourseName = (state: RootState): string => {
  const { SAT, userAccess } = state;

  if (userAccess.hasSATPrepAccess) {
    return locale.sat.sat_prep;
  }

  if (!SAT.bootcamp?.details) {
    return locale.sat.digital_bootcamp;
  }

  return SAT.bootcamp?.details?.programType === ProgramType.CRASH_COURSE
    ? locale.sat.digital_crash_curse
    : locale.sat.digital_bootcamp;
};

export const mapSATUserProgramDetailsAvailableStatus = (
  state: RootState,
): boolean => {
  const bootcampData = state.SAT.bootcamp;
  if (!bootcampData?.accessDetails?.accessType) {
    return false;
  }

  return Boolean(
    bootcampData?.details?.examDate || !bootcampData?.accessDetails?.expiresAt,
  );
};
