export const removeSpaceBetweenDollarSignsInLatex = (
  content?: string,
): string => {
  if (!content) {
    return '';
  }

  const blockSignSplits = content.split('$$');
  const manipulatedBlockSign = blockSignSplits.map(content => {
    let initialContent = content;
    const matches = content.match(/\$(.*?)\$/g);

    if (!matches?.length) {
      return initialContent;
    }

    matches.forEach(match => {
      if (match !== '$\\$$') {
        const matchSplits = match.split('$');
        matchSplits[1] = matchSplits[1].trim();
        initialContent = initialContent.replace(match, matchSplits.join('$'));
      }
    });

    return initialContent;
  });

  return manipulatedBlockSign.join('$$');
};

export const adjustDollarRenderingFormat = (content?: string): string => {
  if (!content) {
    return '';
  }

  // Replace the following:
  // * $\$$300 to $300
  return content.replace(/\$\\\$\$(\d+)?/g, '\\$$$1');
};

export const determineLikelyHeightForMarkdownLatex = (
  content: string,
  fontSize: number,
): number => {
  const countNewLines = Math.ceil((content.split('\n').length - 1) / 2);
  const countLinesFromCharacters = Math.ceil(content.length / 47);
  return fontSize * 1.25 * (countNewLines + countLinesFromCharacters);
};
