import { CommonApCourseGrade } from '../../Common/entities';

export const AP_COURSES_GRADE_LIST = [
  {
    key: `ap-grade-${CommonApCourseGrade.THREE}`,
    value: CommonApCourseGrade.THREE,
  },
  {
    key: `ap-grade-${CommonApCourseGrade.FOUR}`,
    value: CommonApCourseGrade.FOUR,
  },
  {
    key: `ap-grade-${CommonApCourseGrade.FIVE}`,
    value: CommonApCourseGrade.FIVE,
  },
];
