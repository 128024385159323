import locale from '../../../App/locale';
import { GenerateQuestionResponse } from '../../../Learn/entities';
import { DMSpeaker, UserType } from '../../../Messages/entities';
import { SATUnitTestContent } from '../../../SAT/entities';
import { APUnitTestContent } from '../../../TestPrep/entities';
import {
  AIContentType,
  Card,
  CardType,
  CharacterInfo,
  ContentCard,
  ContentCardThumbnail,
  ControversialCardPayload,
  ControversialConversationCard,
  Course,
  DidYouKnowCard,
  DidYouKnowPayload,
  DmsFromDeadCard,
  DmsFromDeadV2Card,
  DMsFromTheDeadPayload,
  DMsFromTheDeadV2,
  DoubleMemeTextCard,
  FeedType,
  FillGapsWithHelpCard,
  FillGapsWithHelpPayload,
  GeneratedMCQOption,
  GenerateQuestionResponsePayload,
  MatchingPairsCard,
  MatchingPayload,
  MCQForDSATCard,
  MCQWithHelpPayload,
  MemeContentPayload,
  MultipleChoiceAnswer,
  MultipleChoiceOption,
  MultipleChoiceQuestionV2,
  RapPayload,
  Topic,
  TruthOrLieCard,
  TruthOrLieContentPayload,
  Unit,
} from '../../entities';
import {
  ENV_KEYS,
  environment,
  generateUniqueId,
  getShuffledArray,
} from '../utils';

import {
  mapTestPrepTutorForAp,
  mapTestPrepTutorForSat,
} from './TestPrepContentMapper';

const mapGeneratedMCQOptionToOption = (
  option: GeneratedMCQOption,
): MultipleChoiceOption => ({
  id: option.id,
  answer: option.answer,
  explanation: option.explanation,
});

const mapGeneratedMCQOptionsToAnswer = (
  options: GeneratedMCQOption[],
): MultipleChoiceAnswer => {
  return {
    id: 0,
    correct_options: options
      .filter(option => option.correct)
      .map(mapGeneratedMCQOptionToOption),
  };
};

export const mapCardToUniqueCard = (card: Card): Card => {
  return {
    ...card,
    uniqueId: generateUniqueId(card.type, card.generatedContentId ?? 'none'),
  };
};

export const mapContentCardToMCQWithHelp = (
  card: ContentCard,
  payload: MCQWithHelpPayload,
): MultipleChoiceQuestionV2 => {
  const type = CardType.MCQ_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  return {
    ...card,
    uniqueId,
    type,
    question: payload.question,
    image: { url: payload.image.url },
    options: getShuffledArray(
      payload.answer_options.map(mapGeneratedMCQOptionToOption),
    ),
    answer: mapGeneratedMCQOptionsToAnswer(payload.answer_options),
    character: {
      name: payload.help.video.speaker.name,
      title: payload.help.video.speaker.biography_short,
      videoUrl: payload.help.video.url,
      subtitleUrl: payload.help.video.subtitles?.url,
    },
  };
};

export const mapContentCardToRap = (
  card: ContentCard,
  payload: RapPayload,
): ContentCard => {
  const type = CardType.CONTENT_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  return {
    ...card,
    uniqueId,
    type,
    videoUrl: payload.video.url,
  };
};

export const mapContentCardToContentCardThumbnail = (
  card: ContentCard,
): ContentCardThumbnail => {
  const thumbnailCard: ContentCardThumbnail = {
    id: card.generatedContentId,
    uniqueId: card.uniqueId,
    type: card.type,
    typeName: card.typeName,
    views: card.totalViews,
    backgroundUrl: card.image?.url,
  };

  if (card.type === CardType.MCQ_CARD) {
    const contentCard = card as MultipleChoiceQuestionV2;

    thumbnailCard.text = contentCard.question;
  } else if (card.type === CardType.CONTROVERSIAL_CONVERSATION_CARD) {
    const contentCard = card as ControversialConversationCard;

    thumbnailCard.backgroundUrl = contentCard.speakers.debaters[0].avatarUrl;
    thumbnailCard.text = contentCard.controversy;
    thumbnailCard.avatarUrls = contentCard.speakers.debaters.map(
      debater => debater.avatarUrl,
    );
  } else if (card.type === CardType.DID_YOU_KNOW_CARD) {
    const contentCard = card as DidYouKnowCard;

    thumbnailCard.backgroundUrl = contentCard.character.avatarUrl;
    thumbnailCard.text = contentCard.text;
  } else if (card.type === CardType.MEME_CARD) {
    const contentCard = card as DoubleMemeTextCard;

    thumbnailCard.backgroundUrl = contentCard.blurredImage?.url;
  } else if (card.type === CardType.TRUTH_OR_LIE_CARD) {
    const contentCard = card as TruthOrLieCard;

    thumbnailCard.text = contentCard.statement;
  } else if (card.type === CardType.FILL_IN_THE_BLANK_CARD) {
    const contentCard = card as FillGapsWithHelpCard;

    const gapName = Object.keys(contentCard.gaps)[0];
    const gapValue = contentCard.gaps[gapName]
      .split('')
      .map(() => '_')
      .join('');
    thumbnailCard.text = contentCard.text.replace(gapName, gapValue);
  } else if (card.type === CardType.DMs_FROM_THE_DEAD_CARD) {
    const contentCard = card as DmsFromDeadCard;

    thumbnailCard.backgroundUrl = contentCard.character.avatarUrl;
    thumbnailCard.text = contentCard.question;
  }

  return thumbnailCard;
};

export const mapContentCardToFillGapsWithHelpCard = (
  card: ContentCard,
  payload: FillGapsWithHelpPayload,
): FillGapsWithHelpCard => {
  const type = CardType.FILL_IN_THE_BLANK_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  return {
    ...card,
    uniqueId,
    type,
    hint: payload.hint,
    image: payload.image,
    text: payload.question,
    gaps: {
      '<blank>': payload.answer,
    },
    possibleAnswers: [payload.answer, ...(payload?.alternate_answers || [])],
    character: {
      name: payload.help.video.speaker.name,
      title: payload.help.video.speaker.biography_short,
      videoUrl: payload.help.video.url,
      subtitleUrl: payload.help.video.subtitles?.url,
    },
  };
};

export const mapContentCardToDidYouKnowCard = (
  card: ContentCard,
  payload: DidYouKnowPayload,
): DidYouKnowCard => {
  const type = CardType.DID_YOU_KNOW_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  return {
    ...card,
    uniqueId,
    type,
    blurredImage: undefined,
    image: undefined,
    text: payload.facts.main,
    videoUrl: payload.video.url,
    subtitleUrl: payload.video.subtitles?.url,
    character: {
      name: payload.video.speaker.name,
      title: payload.video.speaker.biography_short,
      // Making sure that DYKs won't break for older content
      avatarUrl:
        payload.video.speaker.thumbnail_image?.url ??
        payload.video.speaker.image.url,
    },
  };
};

export const mapContentCardToControversialCard = (
  card: ContentCard,
  payload: ControversialCardPayload,
): ControversialConversationCard => {
  const type = CardType.CONTROVERSIAL_CONVERSATION_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  const debaters: Array<CharacterInfo> = payload.speakers.debaters.map(
    (debater: any) => {
      return {
        name: debater.name,
        title: debater.biography_short,
        avatarUrl: debater.thumbnail_image.url,
      };
    },
  );
  let moderator: CharacterInfo | undefined;
  if (payload.speakers.moderator) {
    moderator = {
      name: payload.speakers.moderator.name,
      title: payload.speakers.moderator.biography_short,
      avatarUrl: payload.speakers.moderator.thumbnail_image.url,
    };
  }

  return {
    ...card,
    uniqueId,
    type,
    controversy: payload.controversy,
    speakers: {
      debaters: debaters,
      moderator: moderator,
    },
    video: payload.video,
    videoUrl: payload.video.url,
    subtitleUrl: payload.video.subtitles?.url,
  };
};

export const mapContentCardToDoubleMemeCard = (
  card: ContentCard,
  payload: MemeContentPayload,
): DoubleMemeTextCard => {
  const CONTENT_URL_PREFIX = environment.getValue(ENV_KEYS.CONTENT_URL_PREFIX);
  const type = CardType.MEME_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  // limit images to max 4
  const images = payload.images
    .filter((image: string) => image.length > 3)
    .slice(0, 4);
  const texts = payload.text.slice(0, 4);

  return {
    ...card,
    uniqueId,
    type,
    blurredImage: {
      url: CONTENT_URL_PREFIX + 'meme_templates/' + images[0],
    },
    images: images.map(
      (image: string) => CONTENT_URL_PREFIX + 'meme_templates/' + image,
    ),
    texts: texts,
  };
};

export const mapContentCardToDMsFromDeadCard = (
  card: ContentCard,
  payload: DMsFromTheDeadPayload,
): DmsFromDeadCard => {
  const type = CardType.DMs_FROM_THE_DEAD_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);
  const response = payload.response;

  const speaker: DMSpeaker = {
    name: response.speaker.name,
    title: response.speaker.biography_short,
    avatarUrl: response.speaker.image.url,
    username: response.speaker.handle,
    biography: response.speaker.biography_fun,
    platformId: response.speaker?.platformId || '',
  };

  return {
    ...card,
    uniqueId,
    type,
    character: speaker,
    question: payload.question,
    appropriate: response.appropriate,
    languageStyle: response.language_style,
    excuse: response.excuse,
    transcript: response.text,
    video: response.video.url,
    sender: UserType.dmFigure,
    timestamp: new Date().toISOString(),
  };
};

export const mapContentCardToDMsFromDeadCardV2 = (
  card: ContentCard,
  payload: DMsFromTheDeadV2,
): DmsFromDeadV2Card => {
  const type = CardType.DMs_FROM_THE_DEAD_CARD_V2;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  return {
    ...card,
    uniqueId,
    type,
    question: payload.userMessage.content,
    questionTimestamp: payload.userMessage.timestamp,
    response: payload.figureResponse.content,
    responseTimestamp: payload.figureResponse.timestamp,
  };
};

export const mapContentCardToMCQWithDSATCard = (
  card: ContentCard,
  payload: SATUnitTestContent,
): MCQForDSATCard => {
  const type = CardType.MCQ_FOR_DSAT;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  const testPrepQuestion = {
    id: uniqueId,
    difficulty: card.difficulty ?? 0,
    question: payload.question,
    options: payload.options.map(option => ({
      id: option.id,
      answer: option.answer,
      correct: option.correct,
    })),
    generatedContentId: card.generatedContentId,
    renderer: payload?.renderer,
    stimulus: payload?.stimulus,
    stimulusImage: payload?.stimulus_image,
    levelId: card.levelId || '',
    tutor: mapTestPrepTutorForSat(payload),
  };
  return {
    ...card,
    uniqueId,
    type,
    questionContent: testPrepQuestion,
  };
};

export const mapContentCardToMCQWithAPCourseCard = (
  card: ContentCard,
  payload: APUnitTestContent,
): MCQForDSATCard => {
  const type = CardType.MCQ_FOR_DSAT;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  const testPrepQuestion = {
    id: uniqueId,
    difficulty: card.difficulty ?? 0,
    question: payload.question,
    options: payload.answer_options.map(option => ({
      id: option.id,
      answer: option.answer,
      correct: option.correct,
    })),
    generatedContentId: card.generatedContentId,
    levelId: card.levelId || '',
    tutor: mapTestPrepTutorForAp(payload),
  };
  return {
    ...card,
    uniqueId,
    type,
    questionContent: testPrepQuestion,
  };
};

export const mapContentCardToTruthOrLieCard = (
  card: ContentCard,
  payload: TruthOrLieContentPayload,
): TruthOrLieCard => {
  const type = CardType.TRUTH_OR_LIE_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);
  return {
    ...card,
    uniqueId,
    type,
    visibilityStyleSwitch: false,
    showTapToReveal: false,
    image: { url: payload.image.url },
    statement: payload.statement,
    answer: payload.answer,
    character: {
      videoUrl: payload.help.video.url,
      subtitleUrl: payload.help.video.subtitles?.url,
      name: payload.help.video.speaker.name,
      title: payload.help.video.speaker.biography_short,
    },
    options: [
      {
        id: 1,
        value: locale.home_screen_card.truth,
        emoji: locale.home_screen_card.truth_emoji,
        isCorrect: payload.answer.value === locale.home_screen_card.truth,
        selection_rate:
          payload.answer.value === locale.home_screen_card.truth
            ? payload.answer.selection_rate
            : 100 - payload.answer.selection_rate,
      },
      {
        id: 2,
        value: locale.home_screen_card.lie,
        emoji: locale.home_screen_card.lie_emoji,
        isCorrect: payload.answer.value === locale.home_screen_card.lie,
        selection_rate:
          payload.answer.value === locale.home_screen_card.lie
            ? payload.answer.selection_rate
            : 100 - payload.answer.selection_rate,
      },
    ],
  };
};

export const mapContentCardToMatchingPairsCard = (
  card: ContentCard,
  payload: MatchingPayload,
): MatchingPairsCard => {
  const type = CardType.MATCHING_PAIRS_CARD;
  const uniqueId = generateUniqueId(type, card.generatedContentId);

  const pairs = payload.match.pairs.map((pair, index) => {
    return {
      ...pair,
      uniqueId: generateUniqueId(
        type,
        `${card.generatedContentId}-pair-${index}`,
      ),
    };
  });

  return {
    ...card,
    uniqueId,
    type,
    title: payload.title,

    matchFrom: payload.match.from,
    matchTo: payload.match.to,

    image: { url: payload.image.url },

    leftOptions: getShuffledArray(
      pairs.map(pair => {
        return {
          uniqueId: pair.uniqueId,
          text: pair.from,
        };
      }),
    ),

    rightOptions: getShuffledArray(
      pairs.map(pair => {
        return {
          uniqueId: pair.uniqueId,
          text: pair.to,
        };
      }),
    ),

    pairsFromLeftOptions: pairs.reduce((acc, pair) => {
      return {
        ...acc,
        [pair.uniqueId]: {
          answerUniqueId: pair.uniqueId,
        },
      };
    }, {}),

    charactersFromLeftOptions: pairs.reduce((acc, pair) => {
      return {
        ...acc,
        [pair.uniqueId]: {
          name: pair.help.video.speaker.name,
          title: pair.help.video.speaker.biography_short,
          videoUrl: pair.help.video.url,
          subtitleUrl: pair.help.video.subtitles?.url,
        },
      };
    }, {}),
  };
};

type GenerateQuestionResponseToContentCardMapperParams = {
  response: GenerateQuestionResponse;
  course: Course;
  unit: Unit;
  topic: Topic;
  feedId: FeedType;
};

export const mapGenerateQuestionResponseToContentCard = (
  params: GenerateQuestionResponseToContentCardMapperParams,
): ContentCard => {
  const { response, course, unit, topic, feedId } = params;
  const generatedContentId: string = response.contentId;

  let contentMapper = (item: ContentCard, _: any) => item;

  const questionContentRaw: GenerateQuestionResponsePayload = JSON.parse(
    response.content,
  );

  switch (response.contentTypeId) {
    case AIContentType.Meme:
      contentMapper = mapContentCardToDoubleMemeCard;
      break;
    case AIContentType.Rap:
      contentMapper = mapContentCardToRap;
      break;
    case AIContentType.DidYouKnow:
      contentMapper = mapContentCardToDidYouKnowCard;
      break;
    case AIContentType.MCQWithHelp:
      contentMapper = mapContentCardToMCQWithHelp;
      break;
    case AIContentType.FillInTheBlankWithHelp:
      contentMapper = mapContentCardToFillGapsWithHelpCard;
      break;
    case AIContentType.ControversialConversation:
      contentMapper = mapContentCardToControversialCard;
      break;
    case AIContentType.TruthOrLie:
      contentMapper = mapContentCardToTruthOrLieCard;
      break;
    case AIContentType.MatchingPairs:
      contentMapper = mapContentCardToMatchingPairsCard;
      break;
    case AIContentType.DMsFromTheDead:
      contentMapper = mapContentCardToDMsFromDeadCard;
      break;
    case AIContentType.DMsFromTheDeadV2Text:
      contentMapper = mapContentCardToDMsFromDeadCardV2;
      break;
    case AIContentType.MCQForDSAT:
      contentMapper = mapContentCardToMCQWithDSATCard;
      break;
    case AIContentType.MCQForAPCourse:
      contentMapper = mapContentCardToMCQWithAPCourseCard;
      break;
    default:
      console.log('Unknown content type');
  }

  return contentMapper(
    {
      feedId,
      type: CardType.CONTENT_CARD,
      typeName: response.contentTypeName,
      uniqueId: generateUniqueId(CardType.CONTENT_CARD, generatedContentId),
      course,
      unit,
      topic,
      generatedContentId,
      levelId: response.levelId,
      subjectId: response.subjectId,
      totalLikes: response.totalLikes,
      totalBookmarks: response.totalBookmarks,
      totalShares: response.totalShares,
      totalComments: response.totalComments,
      totalViews: response.totalViews,
      isLiked: response.isLikedByUser,
      isBookmarked: response.isBookmarkedByUser,
      points: response.difficulty * 10,
      difficulty: response.difficulty ?? 0,
    },
    questionContentRaw,
  );
};

export const mapCardTypeToAIContentType = (type: CardType): AIContentType => {
  switch (type) {
    case CardType.MEME_CARD:
      return AIContentType.Meme;
    case CardType.RAP_CARD:
      return AIContentType.Rap;
    case CardType.CONTROVERSIAL_CONVERSATION_CARD:
      return AIContentType.ControversialConversation;
    case CardType.TRUTH_OR_LIE_CARD:
      return AIContentType.TruthOrLie;
    case CardType.FILL_IN_THE_BLANK_CARD:
      return AIContentType.FillInTheBlankWithHelp;
    case CardType.DID_YOU_KNOW_CARD:
      return AIContentType.DidYouKnow;
    case CardType.MCQ_CARD:
      return AIContentType.MCQWithHelp;
    case CardType.DMs_FROM_THE_DEAD_CARD:
      return AIContentType.DMsFromTheDead;
    case CardType.DMs_FROM_THE_DEAD_CARD_V2:
      return AIContentType.DMsFromTheDeadV2Text;
    case CardType.MATCHING_PAIRS_CARD:
      return AIContentType.MatchingPairs;
    case CardType.MCQ_FOR_DSAT:
      return AIContentType.MCQForDSAT;
    default:
      return AIContentType.Unknown;
  }
};
