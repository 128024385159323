import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { CommonBadge, CommonCourseAvatar } from '../../Common/components';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { QuestionTutorHelp } from '../entities';

type Props = {
  courseName: string;
  unitName: string;
  questionId: string;
  isCorrect?: boolean;
  tutor?: QuestionTutorHelp;
  onPress?: () => void;
};

export const TestPrepHelpPanelNoVideo = (props: Props): React.ReactElement => {
  const chatPanelOpenedStatus = useAppSelector(
    state => state.testPrep.chatPanelOpenedStatus[props.questionId],
    (prev, next) => prev === next,
  );

  const handlePress = useCallback(() => {
    if (props.onPress) {
      props.onPress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onPress]);

  const text = useMemo(() => {
    return props.isCorrect
      ? locale.testPrep.help_learn_correct
      : locale.testPrep.help_learn_incorrect;
  }, [props.isCorrect]);

  const shouldShowBadge = useMemo(() => {
    return !chatPanelOpenedStatus && !props.isCorrect;
  }, [chatPanelOpenedStatus, props.isCorrect]);

  return (
    <TouchableOpacity style={styles.container} onPress={handlePress}>
      <View style={styles.courseContainer}>
        <View>
          <CommonCourseAvatar
            url={props.tutor?.avatarURL}
            size={Sizes.xlarge}
          />

          <CommonBadge
            count={1}
            showBadge={shouldShowBadge}
            containerStyle={styles.badgeContainer}
          />
        </View>
      </View>

      <Text style={styles.textChatMe}>{locale.testPrep.help_chat_me}</Text>
      <Text style={styles.textDetail}>{text}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 116,
    backgroundColor: Colors.lightBlue40,
    padding: Sizes.small,
    borderTopLeftRadius: Sizes.small,
    borderBottomLeftRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white12,
  },

  courseContainer: {
    alignItems: 'center',
  },

  textChatMe: {
    ...Fonts.smallBold,
    textAlign: 'center',
    marginTop: Sizes.xsmall,
  },
  textDetail: {
    ...Fonts.xsmall,
    color: Colors.white60,
    textAlign: 'center',
  },

  badgeContainer: {
    right: -2,
    top: -2,
  },
});
