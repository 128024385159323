import { useAppDispatch } from '../../../App/store';
import {
  setFinishedReadingExplanation,
  setHelpShown,
} from '../../../Learn/services/slices';
import { ContentCard } from '../../entities';
import { isWebPlatform } from '../utils/AppConstants';

type CardRecorderReturnType = {
  recordFinishedReadingExplanation: () => void;
  recordHelpShown: () => void;
};

export const useCardTimeRecorder = (
  card: ContentCard,
): CardRecorderReturnType => {
  const dispatch = useAppDispatch();

  const recordFinishedReadingExplanation = () => {
    if (isWebPlatform) {
      return;
    }

    dispatch(
      setFinishedReadingExplanation({
        item: card,
      }),
    );
  };

  const recordHelpShown = () => {
    if (isWebPlatform) {
      return;
    }

    dispatch(
      setHelpShown({
        item: card,
      }),
    );
  };

  return {
    recordFinishedReadingExplanation,
    recordHelpShown,
  };
};
