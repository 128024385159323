import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { RegisterDeviceForPushNotificationsRequest } from '../../entities/NotificationRequestTypes';

export const registerDeviceForPushNotificationsGraphQLCall = async (
  request: RegisterDeviceForPushNotificationsRequest,
): Promise<void> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: { input: request },
      query: `
        mutation RegisterNotificationsMutation($input: RegisterDeviceInput!) {
          registerDeviceForPushNotifications(input: $input) {
            endpointId
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};

export const unregisterDeviceForPushNotificationsGraphQLCall = async (
  deviceId: string,
): Promise<any> => {
  const response = await graphqlApi.post(
    '',
    {
      variables: {
        deviceId,
      },
      query: `
        mutation UnregisterNotificationsMutation($deviceId: String!) {
          unregisterDeviceForPushNotifications(input: {deviceId: $deviceId}) {
            success
          }
        }`,
    },
    {
      headers: {
        ...(await getAuthHeader()),
        'x-api-key': undefined,
      },
    },
  );
  handleGraphQLError(response);
  return response.data?.data;
};
