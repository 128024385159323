import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { CommonStudyMode } from '../../../../Common/entities';
import { mapApProgramDetailsAvailableStatus } from '../../mappers';
import { getAPTestPrepTimeSpentSecondsByCourseId } from '../../utils';
import { AP_SLICE_NAME, setApTestPrepTimeSpentSeconds } from '../APSlice';

import { recordApProgramTimeSpent } from './RecordApProgramTimeSpentAction';

export const finalizeApTestPrepTimeSpent = createAsyncThunk(
  `${AP_SLICE_NAME}/finalizeApTestPrepTimeSpent`,
  async (courseId: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const isProgramAvailable = mapApProgramDetailsAvailableStatus(rootState);

    if (!isProgramAvailable) {
      return;
    }

    const timeSpentSeconds = getAPTestPrepTimeSpentSecondsByCourseId(courseId);
    if (!timeSpentSeconds || !courseId) {
      return;
    }

    const timeSpentMinutes = Math.floor(timeSpentSeconds / 60);
    if (timeSpentMinutes > 0) {
      thunkAPI.dispatch(
        recordApProgramTimeSpent({
          courseId,
          timeSpentMinutes,
          studyMode: CommonStudyMode.TEST_PREP,
        }),
      );
    }

    thunkAPI.dispatch(
      setApTestPrepTimeSpentSeconds({
        courseId,
        timeSpentSeconds: 0,
      }),
    );
  },
);
