import {
  BootcampEntryPoint,
  SATBootcampProgramDetails,
} from './OnboardingTypes';

export type SATBootcampProgramAnalytics = {
  bootcampName: string;
  bootcampDuration: string;
  targetScore: number;
  examDate: string;
  currentScore: number;
  timeCommitment: string;
};

export enum AppInstalledEntryPoint {
  INTRO = 'intro',
  EXAM_SELECTION = 'exam-selection',
}

export type SATBootcampUpsellShownAnalyticsParams = {
  satProgramDetails?: SATBootcampProgramDetails;
  bootcampEntryPoint?: BootcampEntryPoint;
  discount?: number;
};

export type SATBootcampUpsellShownAnalytics = {
  course: string;
  examDate: string;
  from: BootcampEntryPoint;
  purchaseType: string;
  discount: string;
};
