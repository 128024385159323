import { RootState } from '../../../App/store';
import { APProgramCourse, ProgramAccessType } from '../../../Common/entities';
import { isAPCourse } from '../../../Common/services/utils';
import { mapCourseDataById } from '../../../Learn/services/mappers';

export const mapApProgramTargetGradeByCourseId = (
  state: RootState,
  courseId?: string,
): string | undefined => {
  if (!courseId) {
    return undefined;
  }

  const courses = state.AP.program?.details?.courses;
  if (!courses?.length) {
    return undefined;
  }

  return (
    courses.find(course => course.id === courseId)?.targetGrade ||
    state?.AP?.tempCourseTargetGrade?.[courseId] ||
    undefined
  );
};

export const mapApCourseSubmitAndEnrollApCourseLoading = (
  state: RootState,
  courseId?: string,
): boolean => {
  if (!courseId) {
    return false;
  }

  return state.AP.submitAndEnrollApCourseLoading[courseId];
};

export const mapApProgramCourseDetailsById = (
  state: RootState,
  courseId?: string,
): APProgramCourse | undefined => {
  if (!courseId) {
    return undefined;
  }

  return state.AP.program?.details?.courses.find(
    course => course.id === courseId,
  );
};

export const mapApProgramCourseDetailsStatusById = (
  state: RootState,
  courseId?: string,
): boolean => {
  const courseData = mapCourseDataById(state, courseId);
  if (!courseData || !isAPCourse(courseData)) {
    return false;
  }

  return Boolean(mapApProgramCourseDetailsById(state, courseId));
};

export const mapApProgramDetailsAvailableStatus = (
  state: RootState,
): boolean => {
  const accessTypeAvailable = Boolean(
    state.AP.program?.accessDetails?.accessType,
  );
  const accessTypeIsNotExpired =
    state.AP.program?.accessDetails?.accessType !== ProgramAccessType.EXPIRED;

  const coursesAvailable =
    (state.AP.program?.details?.courses || []).length > 0;
  const expiresAtNotAvailable = !state.AP.program?.accessDetails?.expiresAt;

  return (
    accessTypeAvailable &&
    accessTypeIsNotExpired &&
    (coursesAvailable || expiresAtNotAvailable)
  );
};
