import React, { useMemo } from 'react';
import { StyleProp, StyleSheet } from 'react-native';
import FastImage, { ImageStyle, Source } from 'react-native-fast-image';

import { Colors } from '../services/utils/AppConstants';

type Props = {
  url?: string;
  source?: Source;
  style?: StyleProp<ImageStyle>;
  size?: number;
};

export const CommonCourseAvatar = (props: Props): React.ReactElement => {
  const imageStyle = useMemo(() => {
    return [
      styles.courseImage,
      props.style,
      props.size
        ? {
            width: props.size,
            height: props.size,
            borderRadius: props.size / 2,
          }
        : undefined,
    ];
  }, [props.style, props.size]);

  const source = useMemo(() => {
    return props.url ? { uri: props.url } : props.source;
  }, [props.url, props.source]);

  return <FastImage style={imageStyle} source={source} />;
};

const styles = StyleSheet.create({
  courseImage: {
    backgroundColor: Colors.white90,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
});
