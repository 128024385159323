import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootNavigatorRef } from '../../../../App/navigation/RootNavigator';
import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { RootState } from '../../../../App/store';
import {
  MainStack,
  TabNames,
} from '../../../../Common/services/utils/AppConstants';
import { SelectedCourseEntryPoint } from '../../../../CourseEnrollment/entities';
import { setCurrentSelectedCourse } from '../../../../CourseEnrollment/services/slices';
import { ONBOARDING_SLICE_NAME } from '../OnboardingSlice';

export const proceedRedirectionAfterApProgramPurchase = createAsyncThunk(
  `${ONBOARDING_SLICE_NAME}/proceedRedirectionAfterApProgramPurchase`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const isAuthenticated = Boolean(rootState.auth.authUser?.id);

      if (!RootNavigatorRef.current?.isReady() || !isAuthenticated) {
        return;
      }

      const courseEnrollmentState = rootState.courseEnrollments;
      const followingCourses = courseEnrollmentState?.followedCoursesWithGoal;
      if (!followingCourses || Object.keys(followingCourses).length === 0) {
        return;
      }

      const sortedCourses = Object.values(followingCourses).sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      thunkApi.dispatch(
        setCurrentSelectedCourse({
          id: sortedCourses[0].id,
          tab: SelectedCourseEntryPoint.AUTO_SELECT,
        }),
      );

      RootNavigatorRef.current?.reset({
        index: 0,
        routes: [
          {
            name: MainStack.BOTTOM_TABS,
            params: {
              screen: TabNames.HOME,
            },
          },
        ],
      });
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);
