import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { formatNumber } from '../../Common/services/utils';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../assets';
import { CourseSelectionItem } from '../entities';

export type APCourseSelectionItemProps = {
  selected: boolean;
  onSelect: (course: CourseSelectionItem) => void;
  item: CourseSelectionItem;
};

export const APCourseSelectionItem = React.memo(
  ({
    selected,
    onSelect,
    item,
  }: APCourseSelectionItemProps): React.ReactElement => {
    const containerStyle = useMemo(() => {
      return [
        styles.container,
        { backgroundColor: selected ? Colors.actionGreen30 : Colors.white06 },
      ];
    }, [selected]);

    const handlePress = useCallback(() => {
      onSelect(item);
    }, [onSelect, item]);

    const subtitle = useMemo(() => {
      if (item.available) {
        return locale.onboarding.ap_exam_selection_item_subtitle
          .replace('{UNITS}', formatNumber(item.numberOfUnits ?? 0))
          .replace('{ENROLLMENTS}', formatNumber(item.followers ?? 0));
      } else {
        return item.status || locale.common.coming_soon;
      }
    }, [item.numberOfUnits, item.followers, item.available, item.status]);

    return (
      <TouchableOpacity style={containerStyle} onPress={handlePress}>
        <View style={styles.imageContainer}>
          <FastImage
            source={{ uri: item.avatar }}
            style={styles.image}
            resizeMode="cover"
          />
        </View>
        <Text style={styles.title}>{item.name}</Text>
        <Text style={styles.subtitle}>{subtitle}</Text>

        {selected && (
          <View style={styles.checkmarkContainer}>
            <AssetImage asset={OnboardingAssets.CheckIcon} height={14} />
          </View>
        )}
      </TouchableOpacity>
    );
  },
);

export type RenderAPCourseSelectionItemProps = {
  item: CourseSelectionItem;
  selected: boolean;
  onSelect: (course: CourseSelectionItem) => void;
  showEmptyBox?: boolean;
};

// If the item is the last item and it is an odd item, then show the empty box,
// to make sure that the items are aligned properly.
export const renderAPCourseSelectionItem = ({
  item,
  selected,
  onSelect,
  showEmptyBox,
}: RenderAPCourseSelectionItemProps): React.ReactElement => {
  return (
    <>
      <APCourseSelectionItem
        item={item}
        selected={selected}
        onSelect={onSelect}
      />

      {showEmptyBox && <View style={styles.emptyBoxContainer} />}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white06,
    borderRadius: Sizes.normal,
    padding: Sizes.small,
    minHeight: 125,
    borderColor: Colors.white06,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyBoxContainer: {
    flex: 1,
    padding: Sizes.small,
  },

  imageContainer: {
    width: Sizes.xlarge,
    height: Sizes.xlarge,
    borderRadius: Sizes.medium,
    backgroundColor: Colors.white100,
    padding: 1,
  },
  image: {
    flex: 1,
    borderRadius: Sizes.medium,
  },
  title: {
    ...Fonts.caption500,
    marginTop: Sizes.small,
    textAlign: 'center',
  },
  subtitle: {
    ...Fonts.small,
    color: Colors.white70,
    marginTop: Sizes.xxsmall,
    textAlign: 'center',
  },
  checkmarkContainer: {
    position: 'absolute',
    right: Sizes.small,
    top: Sizes.small,
    backgroundColor: Colors.actionGreen,
    width: Sizes.large,
    height: Sizes.large,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 6,
  },
});
