import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { useAppSelector } from '../../App/services/hooks';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { DMSpeaker } from '../entities';
import { getIsSpeakerHasUnreadMessages } from '../services/slices';

import { MessageListItemDescription } from './MessageListItemDescription';

type MessageListItemProps = {
  speaker: DMSpeaker;
  onPress: (speaker: DMSpeaker) => void;
};

export const MessageListItem = React.memo(
  ({ speaker, onPress }: MessageListItemProps): React.ReactElement => {
    const speakerHasUnreadMessages = useAppSelector(
      state =>
        getIsSpeakerHasUnreadMessages(state.messages, speaker.platformId),
      (prev, next) => prev === next,
    );

    return (
      <TouchableOpacity
        style={styles.itemContainer}
        onPress={() => onPress(speaker)}>
        <View style={styles.leftSideWrapper}>
          <FastImage
            source={{ uri: speaker.avatarUrl }}
            style={styles.avatar}
          />
          <View style={styles.nameContainer}>
            <Text style={styles.name}>{speaker.name}</Text>
            <MessageListItemDescription
              speaker={speaker}
              isUnread={speakerHasUnreadMessages}
            />
          </View>
        </View>

        {speakerHasUnreadMessages && (
          <View style={styles.rightSideWrapper}>
            <View style={styles.unreadMessageItem} />
          </View>
        )}
      </TouchableOpacity>
    );
  },
);

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    columnGap: Sizes.small,
    paddingHorizontal: Sizes.small,
    paddingVertical: Sizes.small,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftSideWrapper: {
    flexDirection: 'row',
    columnGap: Sizes.small,
    alignItems: 'center',
    maxWidth: Sizes.getAppWindowWidth() - 32,
    flexGrow: 1,
  },
  rightSideWrapper: {
    flexDirection: 'row',
    columnGap: Sizes.small,
    alignItems: 'center',
    paddingRight: Sizes.small,
  },
  unreadMessageItem: {
    width: Sizes.small,
    height: Sizes.small,
    borderRadius: Sizes.small,
    backgroundColor: Colors.actionGreen,
  },
  nameContainer: {
    rowGap: 2,
    paddingRight: 5,
  },
  name: {
    ...Fonts.caption,
  },
  avatar: {
    width: 44,
    height: 44,
    borderRadius: 25,
  },
});
