import { FlashList } from '@shopify/flash-list';
import React, { useEffect, useRef } from 'react';
import { StyleSheet } from 'react-native';

import { Props } from '.';
import {
  emitVisibilityChangeEvent,
  VisibilityChangeEventPayload,
} from '../../../events';
import { isAndroidPlatform } from '../../../services/utils/AppConstants';

export default ({
  data,
  ItemView,
  holderTag,
  itemHeight,
  onEndReached,
  isVisible,
  onEndReachedThreshold,
  scrollToCardID,
  onPlaylistPress,
  shouldShowPlaylist,
  isScrollDisabled,
  initialScrollIndex,
  showCoinsAnimation = false,
}: Props): React.ReactElement => {
  const listRef = useRef<any>();

  const indexRef = useRef(0);

  const [event, setEvent] = React.useState<VisibilityChangeEventPayload>();

  useEffect(() => {
    if (event && isVisible) {
      emitVisibilityChangeEvent(event);
    }
  }, [event, isVisible]);

  useEffect(() => {
    if (scrollToCardID) {
      const index = data.findIndex(
        item => item.uniqueId === scrollToCardID.uniqueId,
      );
      if (index >= 0) {
        listRef.current.scrollToIndex({
          index,
          animated: scrollToCardID.animated,
        });
      }
    }
  }, [data, scrollToCardID]);

  // make sure to catch events where
  useEffect(() => {
    if (data[indexRef.current]) {
      if (isVisible) {
        emitVisibilityChangeEvent({
          visibleItemUniqueId: data[indexRef.current].uniqueId,
          holderTag,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data[indexRef.current], holderTag, isVisible]);

  const renderItem = ({
    item,
    index,
  }: {
    item: any;
    index: number;
  }): React.ReactElement => {
    return (
      <ItemView
        item={item}
        holderTag={holderTag}
        index={index}
        onPlaylistPress={onPlaylistPress}
        itemHeight={itemHeight}
        scrollDisabled={isScrollDisabled}
        showCoinsAnimation={showCoinsAnimation}
      />
    );
  };

  const refreshVisibility = (): void => {
    // set a small timeout to allow listeneres to register
    setTimeout(() => {
      if (isVisible) {
        emitVisibilityChangeEvent({
          visibleItemIndex: indexRef.current,
          holderTag,
        });
      } else {
        emitVisibilityChangeEvent({
          visibleItemIndex: undefined,
          holderTag,
        });
      }
    }, 100);
  };

  useEffect(() => {
    refreshVisibility();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return (
    <FlashList
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag"
      disableIntervalMomentum={!isAndroidPlatform}
      pagingEnabled
      nestedScrollEnabled
      ref={listRef}
      data={data}
      overScrollMode={isAndroidPlatform ? 'never' : 'auto'}
      style={styles.listContainer}
      renderItem={renderItem}
      keyExtractor={item => item.uniqueId}
      estimatedItemSize={itemHeight}
      decelerationRate={'fast'}
      bounces={false}
      showsVerticalScrollIndicator={false}
      snapToAlignment={isAndroidPlatform ? undefined : 'start'}
      scrollsToTop={false}
      initialScrollIndex={initialScrollIndex}
      viewabilityConfig={{
        waitForInteraction: true,
        itemVisiblePercentThreshold: 50,
        minimumViewTime: 100,
      }}
      onEndReached={onEndReached}
      onEndReachedThreshold={onEndReachedThreshold}
      onViewableItemsChanged={e => {
        if (e.viewableItems.length > 0) {
          indexRef.current = e.viewableItems[0]?.index ?? 0;
          setEvent({
            visibleItemUniqueId: e.viewableItems[0].item.uniqueId,
            holderTag,
          });
        }
      }}
    />
  );
};

const styles = StyleSheet.create({
  listContainer: {},
});
