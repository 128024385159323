import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text } from 'react-native';

import locale from '../../../App/locale';
import { PaywallEntryPoint } from '../../../Common/entities';
import {
  openWebLink,
  trackAnalyticsEvent,
} from '../../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
} from '../../../Common/services/utils/AppConstants';
import { useBootcampPaywallData } from '../../services/hooks';

export const BootcampPaywallGuarantee = (): React.ReactElement => {
  const { programDetails } = useBootcampPaywallData();

  const handleLearnMorePress = useCallback(() => {
    trackAnalyticsEvent(Analytics.moneyBackLearnMoreClicked, {
      from: PaywallEntryPoint.SAT_BOOTCAMP_PAYWALL_SCREEN,
    });

    openWebLink(locale.auth_screen.sat_money_back_guarantee_url);
  }, []);

  const content = useMemo(() => {
    const dedication = programDetails?.studyCommitmentHours.toString() || 'N';

    return {
      moneyBack: locale.bootcamp_paywall.money_back_details.replace(
        '${HOURS}',
        dedication,
      ),
    };
  }, [programDetails]);

  return (
    <Text style={styles.moneyBackGuarantee}>
      <Text style={styles.moneyBackGuaranteeBold}>
        {locale.bootcamp_paywall.money_back_heading}
      </Text>
      {content.moneyBack}&nbsp;
      <Text style={styles.learnMore} onPress={handleLearnMorePress}>
        {locale.common.learn_more}
      </Text>
    </Text>
  );
};

const styles = StyleSheet.create({
  moneyBackGuarantee: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'center',
  },
  moneyBackGuaranteeBold: {
    ...Fonts.smallBold,
    color: Colors.buttonGreen,
  },
  learnMore: {
    ...Fonts.small,
    color: Colors.white60,
    textDecorationLine: 'underline',
  },
});
