import { useIsFocused } from '@react-navigation/native';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Animated,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import MuteButtonView from '../../Common/components/home/MuteButtonView';
import RateButtonView from '../../Common/components/home/RateButtonView';
import { AIContentType, Course, FeedType, Unit } from '../../Common/entities';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { RNVideo } from '../../Common/services/utils/polyfills';
import { RaiseHandScreenTypes } from '../../RaiseHand/entities';

type Props = {
  tutorName: string;
  videoURL?: string;
  isVisible: boolean;
  videoHeight: Animated.Value;
  minimumVideoHeight: number;
  initialAndMaxVideoHeight: number;
  generatedContentId: string;
  unit: Unit;
  course: Course;
};

const PLAY_BUTTON_SIZE = Sizes.normalizeIP14PM(20);

export const TutorHelpVideoView = (props: Props): React.ReactElement => {
  const isFocused = useIsFocused();

  const videoRef = useRef<RNVideo>(null);

  const [showContinue, setShowContinue] = useState<boolean>(false);

  const [isMediaLoading, setMediaLoading] = useState<boolean>(true);

  const [isMediaPaused, setMediaPaused] = useState<boolean>(false);
  const [isMediaMuted, setMediaMuted] = useState<boolean>(false);
  const [isVideoEnded, setVideoEnded] = useState<boolean>(false);
  const [videoRate, setVideoRate] = useState<number>(1);

  useEffect(() => {
    if (props.isVisible) {
      videoRef.current?.seek(0);
      setMediaPaused(false);
    }
  }, [props.isVisible]);

  const iconOpacity = props.videoHeight.interpolate({
    inputRange: [props.minimumVideoHeight, props.initialAndMaxVideoHeight],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });

  useEffect(() => {
    if (!isFocused) {
      setMediaPaused(true);
    }
  }, [isFocused]);

  const renderVideoButtonControls = useCallback(() => {
    if (isMediaLoading) {
      return (
        <ActivityIndicator
          size="large"
          color="white"
          style={styles.playButtonContainer}
        />
      );
    } else if (isVideoEnded) {
      return (
        <View style={styles.playButtonContainer}>
          <AssetImage
            asset={Assets.home.repeatIcon}
            fill="white"
            height={PLAY_BUTTON_SIZE}
            containerStyle={styles.playButton}
          />
        </View>
      );
    } else if (!isVideoEnded && isMediaPaused) {
      return (
        <View style={styles.playButtonContainer}>
          <AssetImage
            asset={Assets.home.playVideoButtonIcon}
            fill="white"
            height={PLAY_BUTTON_SIZE}
            containerStyle={styles.playButton}
          />
        </View>
      );
    }

    return null;
  }, [isVideoEnded, isMediaPaused, isMediaLoading]);

  const mapVideoAnalyticsPayload = useMemo(() => {
    return {
      from: RaiseHandScreenTypes.UnitTestQuestion,
      tutorName: props.tutorName,
      generatedContentId: props.generatedContentId,
      cardType: AIContentType.UnitTestMCQ,
      unit: props.unit?.name ?? 'none',
      course: props.course?.name ?? 'none',
      feedId: FeedType.TestPractice,
    };
  }, [props.course, props.generatedContentId, props.tutorName, props.unit]);

  const onVideoEnd = useCallback(() => {
    setVideoEnded(true);
    if (!showContinue) {
      setShowContinue(true);
    }
    trackAnalyticsEvent(Analytics.videoFinished, mapVideoAnalyticsPayload);
  }, [mapVideoAnalyticsPayload, showContinue]);

  const onVideoPress = useCallback(() => {
    if (isVideoEnded) {
      setVideoEnded(false);
      videoRef.current?.seek(0);
      setMediaPaused(false);
      trackAnalyticsEvent(Analytics.videoRestarted, mapVideoAnalyticsPayload);
    } else {
      setMediaPaused(!isMediaPaused);
    }
  }, [isMediaPaused, isVideoEnded, mapVideoAnalyticsPayload]);

  return (
    <Pressable
      style={[styles.container, !props.isVisible && styles.visible]}
      onPress={onVideoPress}>
      <RNVideo
        ref={videoRef}
        source={{
          uri: props.videoURL,
        }}
        style={styles.media}
        paused={isMediaPaused || !props.isVisible}
        resizeMode="cover"
        onLoad={() => {
          setMediaLoading(false);
          trackAnalyticsEvent(Analytics.videoStarted, mapVideoAnalyticsPayload);
        }}
        repeat={false}
        onEnd={onVideoEnd}
        ignoreSilentSwitch="ignore"
        muted={isMediaMuted}
        rate={videoRate}
      />
      {renderVideoButtonControls()}

      <Animated.View style={[styles.muteButton, { opacity: iconOpacity }]}>
        <MuteButtonView
          iconSize={22}
          isFromTestPrep
          isTutorVideoMuted={isMediaMuted}
          handleMutePressed={() => setMediaMuted(!isMediaMuted)}
        />
      </Animated.View>

      <Animated.View style={[styles.rateButton, { opacity: iconOpacity }]}>
        <RateButtonView
          tutorVideoRate={videoRate}
          handleRatePressed={setVideoRate}
          isFromTestPrep
        />
      </Animated.View>
      <View style={styles.tutorNameContainer}>
        <Text style={styles.tutorName}>{props.tutorName}</Text>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
  visible: {
    display: 'none',
  },
  playButton: {
    alignSelf: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
  closeButton: {
    position: 'absolute',
    alignSelf: 'flex-end',
    top: Sizes.normalizeIP14PM(40),
    end: 16,
    padding: 10,
    backgroundColor: Colors.white24,
    borderRadius: 8,
    margin: Sizes.normalizeIP14PM(15),
  },
  playButtonContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: '45%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  media: {
    top: Sizes.safeAreaInsetsTop,
    width: '100%',
    height: '100%',
    alignSelf: 'center',
  },
  rateButton: {
    position: 'absolute',
    top: Sizes.safeAreaInsetsTop + Sizes.normalizeIP14PM(80),
    right: Sizes.normalizeIP14PM(10),
    transform: [{ scale: 0.8 }],
  },
  muteButton: {
    position: 'absolute',
    right: Sizes.normalizeIP14PM(27),
    top: Sizes.safeAreaInsetsTop + Sizes.normalizeIP14PM(50),
  },
  tutorNameContainer: {
    backgroundColor: Colors.black60,
    padding: 3,
    borderRadius: 8,
    height: Sizes.normalizeIP14PM(30),
    position: 'absolute',
    marginTop: Sizes.safeAreaInsetsTop + Sizes.normalizeIP14PM(45),
    marginLeft: Sizes.normalizeIP14PM(15),
    marginRight: Sizes.normalizeIP14PM(10),
  },
  tutorName: {
    ...Fonts.normalize(Fonts.mediumBold),
  },
});
