import { Course, Unit } from '../../../Common/entities';
import {
  mapTestPrepTutorForAp,
  mapTestPrepTutorForSat,
} from '../../../Common/services/mappers';
import {
  LearningContentTextType,
  SATUnitTestContent,
} from '../../../SAT/entities';
import {
  APUnitTestContent,
  CreateUnitTestResponse,
  GetGeneratorExternalUrlResponse,
  SendUserEmailRequest,
  TestPrepData,
  TestPrepQuestion,
  TestType,
  UnitTestQuestion,
  UserEmailType,
} from '../../entities';
import { getTestDurationMultiplier } from '../utils';

export const mapAPUnitTestQuestionToTestPrepQuestion = (
  question: UnitTestQuestion,
): TestPrepQuestion => {
  const questionContent = JSON.parse(question.content) as APUnitTestContent;
  return {
    id: question.questionId,
    question: questionContent.question,
    difficulty: question.difficulty,
    options: questionContent.answer_options.map(option => ({
      id: option.id,
      answer: option.answer,
      correct: option.correct,
    })),
    tutor: mapTestPrepTutorForAp(questionContent),
    generatedContentId: question.contentId,
    levelId: question.levelId,
    contentTypeId: question.contentTypeId,
  };
};

export const mapSATUnitTestQuestionToTestPrepQuestion = (
  question: UnitTestQuestion,
): TestPrepQuestion => {
  const questionContent = JSON.parse(question.content) as SATUnitTestContent;

  const isLearningContentTextType =
    questionContent.learning_content?.type === LearningContentTextType.TEXT;
  const initialTutorMessage = isLearningContentTextType
    ? questionContent.learning_content?.text
    : '';

  return {
    id: question.questionId,
    question: questionContent.question,
    difficulty: question.difficulty,
    options: questionContent.options.map(option => ({
      id: option.id,
      answer: option.answer,
      correct: option.correct,
    })),
    contentTypeId: question.contentTypeId,
    tutor: mapTestPrepTutorForSat(questionContent),
    generatedContentId: question.contentId,
    levelId: question.levelId,
    renderer: questionContent?.renderer,
    stimulus: questionContent?.stimulus,
    stimulusImage: questionContent?.stimulus_image,
    initialTutorMessage: initialTutorMessage,
  };
};

export const mapUnitTestResponseToTestPrepData = (
  course: Course,
  unit: Unit,
  testType: TestType,
  response: CreateUnitTestResponse & GetGeneratorExternalUrlResponse,
): TestPrepData => {
  const questions =
    testType === TestType.AP_TEST
      ? response.questions.map(mapAPUnitTestQuestionToTestPrepQuestion)
      : response.questions.map(mapSATUnitTestQuestionToTestPrepQuestion);

  const timeRemainingSeconds =
    (response.questions.length ?? 0) *
    getTestDurationMultiplier(testType, unit.category);
  const timeRemainingMinutes = Math.round(timeRemainingSeconds / 60);

  return {
    id: response.testId,
    course,
    unit,
    questions: questions,
    externalUrl: response.externalUrl,
    timeRemaining: timeRemainingMinutes * 60,
    maxScore: response?.maxScore,
    minScore: response?.minScore,
    endedDueToTimeout: false,
  };
};

export const mapFrqLinkToSendUserEmailRequest = (
  frqLink: string,
  courseId: string,
  unitId: string,
): SendUserEmailRequest => {
  return {
    emailType: UserEmailType.ShareFrqLink,
    attributes: [
      { name: 'frqLink', value: frqLink },
      { name: 'courseId', value: courseId },
      { name: 'unitId', value: unitId },
    ],
  };
};
