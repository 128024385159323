import React, { useMemo } from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';

import { useVideoSubtitlesData } from '../../services/hooks';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  uniqueId: string;
  subtitleUrl?: string;
  containerStyle?: ViewStyle;
  isSmall?: boolean;
};

export const VideoSubtitles = (props: Props): React.ReactElement | null => {
  const { hideSubtitle, currentSubtitle } = useVideoSubtitlesData(props);

  const managedStyles = useMemo(() => {
    const containerStyles = props.isSmall
      ? styles.smallContainer
      : styles.container;
    const textStyles = props.isSmall ? styles.smallText : styles.text;

    return {
      container: [containerStyles, props.containerStyle],
      text: textStyles,
    };
  }, [props.containerStyle, props.isSmall]);

  if (hideSubtitle) {
    return null;
  }

  return (
    <View style={managedStyles.container}>
      <Text style={managedStyles.text}>{currentSubtitle}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 50,
    position: 'absolute',
    alignSelf: 'center',
    padding: Sizes.small,
    borderRadius: Sizes.small,
    backgroundColor: Colors.gray29_70,
    marginHorizontal: Sizes.semiMedium,
  },
  text: {
    ...Fonts.semiLarge,
    textAlign: 'center',
  },

  smallContainer: {
    padding: Sizes.xsmall,
    borderRadius: Sizes.small,
    position: 'absolute',
    backgroundColor: Colors.gray29_70,
    top: Sizes.xsmall,
    left: Sizes.xsmall,
  },
  smallText: {
    ...Fonts.xsmall500,
  },
});
