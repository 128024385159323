import React, { ReactElement, useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { useAppDispatch } from '../../App/store';
import { Course, PaywallEntryPoint } from '../../Common/entities';
import { useProgramCourseNameGetter } from '../../Common/services/hooks';
import {
  getCourseGoalsFromCourse,
  isAPCourse,
  isSATCourse,
} from '../../Common/services/utils';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { setFollowGoalPopup } from '../../Popup/services/slices';
import { CourseTagType } from '../entities';
import { useGetCourseTag } from '../services/hooks';
import { unenrollUserFromCourse } from '../services/slices';
import {
  enrollUserToAPCourse,
  enrollUserToSATCourse,
} from '../services/slices/CourseEnrllmentsSliceActions';

import { CourseTag } from './CourseTag';

const PROFILE_IMAGE_SIZE = 46;
const DEFAULT_FOLLOW_BUTTON_WIDTH = 72;

// need to lazy load to avoid error on web-preview build, react-native-skeleton-placeholder is not supported on web
const SkeletonPlaceholder = React.lazy(
  () => import('react-native-skeleton-placeholder'),
);
const SkeletonPlaceholderItem = React.lazy(() =>
  import('react-native-skeleton-placeholder').then(module => ({
    default: module.default.Item,
  })),
);

type Props = {
  course: Course | null;
  entryPoint: PaywallEntryPoint;
};

const CourseNull = () => {
  return (
    <SkeletonPlaceholder backgroundColor={'grey'}>
      <SkeletonPlaceholderItem style={styles.container}>
        <SkeletonPlaceholderItem flexDirection={'row'} alignItems={'center'}>
          <SkeletonPlaceholderItem
            width={PROFILE_IMAGE_SIZE}
            height={PROFILE_IMAGE_SIZE}
            borderRadius={PROFILE_IMAGE_SIZE / 2}
            marginStart={16}
          />
          <SkeletonPlaceholderItem>
            <SkeletonPlaceholderItem width={180} height={12} marginStart={16} />
            <SkeletonPlaceholderItem
              width={105}
              height={12}
              marginStart={16}
              marginTop={4}
            />
          </SkeletonPlaceholderItem>
          <SkeletonPlaceholderItem flex={1} />
          <SkeletonPlaceholderItem width={72} height={28} marginEnd={16} />
        </SkeletonPlaceholderItem>
        <SkeletonPlaceholderItem
          width={500}
          height={90}
          marginStart={16}
          marginTop={16}
        />
      </SkeletonPlaceholderItem>
    </SkeletonPlaceholder>
  );
};

export const CourseItem = (props: Props): ReactElement<any, any> => {
  const dispatch = useAppDispatch();

  const isFollowed = useAppSelector(
    state =>
      state.courseEnrollments.following.find(
        item => item.id === props.course?.id,
      ) !== undefined,
  );

  const courseEnrollmentsCount = useAppSelector(
    state => {
      return (
        state.courseEnrollments.coursesEnrollmentCount[
          props.course?.id || ''
        ] || 0
      );
    },
    (prev, next) => prev === next,
  );

  const courseTag = useGetCourseTag({ course: props.course });
  const courseName = useProgramCourseNameGetter(props.course);

  const unitText = useMemo(() => {
    const unitsLength = props.course?.units?.length || 0;

    return locale.courses.total_units.replace(
      '${COUNT}',
      unitsLength.toLocaleString('en-US'),
    );
  }, [props.course?.units?.length]);

  const studyingText = useMemo(() => {
    return locale.courses.total_enrollments.replace(
      '${COUNT}',
      courseEnrollmentsCount.toLocaleString('en-US'),
    );
  }, [courseEnrollmentsCount]);

  const handleFollowPress = useCallback(() => {
    if (!props.course?.grade) {
      return;
    }

    if (isFollowed) {
      dispatch(unenrollUserFromCourse(props.course));
    } else if (isSATCourse(props.course)) {
      dispatch(
        enrollUserToSATCourse({
          course: props.course,
          paywallEntrypoint: props.entryPoint,
          isFromCoursePicker: false,
        }),
      );
    } else if (isAPCourse(props.course)) {
      dispatch(
        enrollUserToAPCourse({
          course: props.course,
          paywallEntrypoint: props.entryPoint,
          isFromCoursePicker: false,
        }),
      );
    } else {
      dispatch(
        setFollowGoalPopup({
          course: props.course,
          goalsList: getCourseGoalsFromCourse(props.course),
          entryPoint: props.entryPoint,
          isFromCoursePicker: true,
        }),
      );
    }
  }, [dispatch, isFollowed, props.course, props.entryPoint]);

  const courseButtonText = useMemo(() => {
    if (!props.course) {
      return '';
    }

    return isFollowed
      ? locale.home_screen_card.unfollow_action
      : locale.home_screen_card.follow_action;
  }, [isFollowed, props.course]);

  if (props.course === null) {
    return <CourseNull />;
  }

  return (
    <View style={styles.container}>
      {courseTag.isVisible && (
        <CourseTag
          textStyle={styles.purchasedText}
          containerStyle={styles.purchasedContainer}
          isFreeTrial={courseTag.tag === CourseTagType.FREETRIAL}
        />
      )}
      <View style={styles.titleRow}>
        <FastImage
          style={styles.profileImage}
          source={{ uri: props?.course?.avatar || '' }}
        />

        <View style={styles.titleTextContainer}>
          <Text numberOfLines={2} style={styles.titleText}>
            {courseName}
          </Text>

          <View style={styles.courseDetailContainer}>
            <Text style={styles.courseDetailText}>{unitText}</Text>

            <View style={styles.courseDetailSeparator} />

            <Text style={styles.courseDetailText}>{studyingText}</Text>
          </View>
        </View>

        <View style={styles.followButtonContainer}>
          <TouchableOpacity
            style={
              isFollowed
                ? styles.followButtonInactive
                : styles.followButtonActive
            }
            onPress={handleFollowPress}>
            <Text style={styles.buttonText}>{courseButtonText}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  purchasedText: {
    color: Colors.white80,
  },
  purchasedContainer: {
    backgroundColor: Colors.white08,
    borderTopRightRadius: Sizes.semiMedium,
    borderBottomLeftRadius: Sizes.semiMedium,
  },
  container: {
    backgroundColor: Colors.white10,
    borderColor: Colors.white12,
    borderRadius: Sizes.semiMedium,
    borderWidth: 1,
    padding: Sizes.medium,
    maxWidth: Sizes.getAppWindowWidth(),
  },
  expanded: {
    flex: 1,
  },
  titleRow: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  titleTextContainer: {
    marginHorizontal: 12,
    maxWidth: Sizes.getAppWindowWidth() - 200,
    justifyContent: 'center',
    flex: 1,
  },
  titleText: {
    ...Fonts.caption,
  },
  profileImage: {
    width: PROFILE_IMAGE_SIZE,
    height: PROFILE_IMAGE_SIZE,
    borderRadius: PROFILE_IMAGE_SIZE / 2,
  },
  followButtonContainer: {
    justifyContent: 'center',
    minWidth: DEFAULT_FOLLOW_BUTTON_WIDTH,
  },
  followButtonInactive: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF1A',
    borderRadius: 6,
    justifyContent: 'center',
    paddingHorizontal: Sizes.xsmall,
    paddingVertical: 6,
  },
  followButtonActive: {
    alignItems: 'center',
    backgroundColor: Colors.buttonGreen,
    borderRadius: 6,
    justifyContent: 'center',
    paddingHorizontal: Sizes.xsmall,
    paddingVertical: 6,
  },
  buttonText: {
    ...Fonts.small,
    lineHeight: isAndroidPlatform ? 18 : undefined,
  },

  courseDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  courseDetailText: {
    ...Fonts.xsmall,
    fontSize: 12,
    color: Colors.white60,
  },
  courseDetailSeparator: {
    width: 3,
    height: 3,
    borderRadius: 3,
    backgroundColor: Colors.white60,
    marginHorizontal: Sizes.small,
  },
});
