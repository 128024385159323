import { Mixpanel, MixpanelProperties } from 'mixpanel-react-native';

import {
  ENV_KEYS,
  environment,
  isAppStoreBuild,
  isProductionEnv,
} from '../../../Common/services/utils';

export class MixpanelTracker {
  private static instance: MixpanelTracker;

  mixpanelClient: Mixpanel;

  constructor(private readonly useProductionProject: boolean) {
    this.mixpanelClient = this.createMixpanelClient();
  }

  createMixpanelClient(): Mixpanel {
    const { MIXPANEL_TEACHTAP_PROD_TOKEN, MIXPANEL_DEV_TOKEN } =
      environment.getValues([
        ENV_KEYS.MIXPANEL_TEACHTAP_PROD_TOKEN,
        ENV_KEYS.MIXPANEL_DEV_TOKEN,
      ]);

    const mixpanelClient = new Mixpanel(
      this.useProductionProject
        ? MIXPANEL_TEACHTAP_PROD_TOKEN
        : MIXPANEL_DEV_TOKEN,
      false,
    );
    mixpanelClient.init();
    return mixpanelClient;
  }

  static getInstance(): MixpanelTracker {
    if (!MixpanelTracker.instance) {
      MixpanelTracker.instance = new MixpanelTracker(
        isProductionEnv() && isAppStoreBuild(),
      );
    }
    return MixpanelTracker.instance;
  }

  async getDistinctId(): Promise<string> {
    const mixpanelClient = this.mixpanelClient;
    return mixpanelClient.getDistinctId();
  }

  async identify(distinctId: string): Promise<void> {
    await this.mixpanelClient.identify(distinctId);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setPeople(prop: string, to: any): void {
    this.mixpanelClient.getPeople().set(prop, to);
  }

  track(eventName: string, properties?: MixpanelProperties): void {
    this.mixpanelClient.track(eventName, properties);
  }

  reset(): void {
    const mixpanelClient = this.mixpanelClient;
    mixpanelClient.reset();
  }
}

export const getMixpanelTracker = (): MixpanelTracker =>
  MixpanelTracker.getInstance();
