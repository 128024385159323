import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import { useAppNavigation } from '../../App/services/hooks';
import { CommonBackButton } from '../../Common/components';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import { trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
  LoginStack,
  MainStack,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../Common/services/utils/polyfills';
import { OnboardingContainerEntryPoint } from '../entities';

type Props = {
  children: React.ReactNode;
  from: OnboardingContainerEntryPoint;
  preventSwitchToSignup?: boolean;
  hexColor?: string;
  showBackButton?: boolean;
};

export const OnboardingLoginContainer = (props: Props): React.ReactElement => {
  const { safeAreaBottom } = useSafeAreaCustomInsets();
  const navigation = useAppNavigation();

  const linearGradientColors = useMemo(() => {
    if (props.hexColor) {
      return [`${props.hexColor}00`, `${props.hexColor}FF`];
    }

    return Colors.topAlphaGradientStrongInvert;
  }, [props.hexColor]);

  const linearGradientStyle = useMemo(() => {
    return [styles.linearGradientContainer, { paddingBottom: safeAreaBottom }];
  }, [safeAreaBottom]);

  const handleLoginPressed = useCallback(() => {
    trackAnalyticsEvent(Analytics.loginClicked, {
      from: props.from,
    });

    navigation.navigate(MainStack.LOGIN_STACK, {
      screen: LoginStack.FULL_LOGIN_SCREEN,
      params: {
        isFromOnboarding: true,
        preventSwitchToSignup: props.preventSwitchToSignup,
      },
    });
  }, [props.from, props.preventSwitchToSignup, navigation]);

  const shouldShowBackButton = useMemo(() => {
    return props.showBackButton && navigation.canGoBack();
  }, [props.showBackButton, navigation]);

  const handleBackButtonPressed = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.pop();
    }
  }, [navigation]);

  return (
    <View style={styles.container}>
      {shouldShowBackButton && (
        <CommonBackButton
          onPress={handleBackButtonPressed}
          absoluteTopLeft
          containerStyle={styles.buttonContainer}
        />
      )}

      {props.children}

      <View style={styles.loginMainContainer}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 0, y: 0.5 }}
          colors={linearGradientColors}
          style={linearGradientStyle}>
          <View style={styles.bottomActionContainer}>
            <Text style={styles.bottomLabel}>
              {locale.auth_screen.already_have_account}
            </Text>

            <TouchableOpacity onPress={handleLoginPressed}>
              <Text style={styles.bottomLabelHighlight}>
                {locale.auth_screen.login}
              </Text>
            </TouchableOpacity>
          </View>
        </LinearGradient>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.prussianBlue,
  },

  loginMainContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 20,
  },
  linearGradientContainer: {
    paddingTop: Sizes.xlarge,
    width: '100%',
  },

  buttonContainer: {
    zIndex: 20,
  },

  bottomActionContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    paddingVertical: 16,
    width: '100%',
  },
  bottomLabel: {
    ...Fonts.medium,
    color: 'white',
  },
  bottomLabelHighlight: {
    ...Fonts.medium,
    color: Colors.actionGreen,
    marginLeft: 4,
  },
});
