import React, { useCallback, useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { StudyNavigationProps } from '../../App/navigation/bottomTabs/StudyStackNavigator';
import { useSafeAreaCustomInsets } from '../../Common/services/hooks';
import {
  Fonts,
  Sizes,
  StudyStack,
} from '../../Common/services/utils/AppConstants';
import { useNavigation } from '../../Common/services/utils/polyfills';

export const TestFeedHeader = (): React.ReactElement => {
  const { safeAreaTop } = useSafeAreaCustomInsets();
  const navigation =
    useNavigation<StudyNavigationProps<StudyStack.TOPIC_COMPLETION_SCREEN>>();

  const headerContainerStyle = useMemo(
    () => [
      styles.headerContainer,
      {
        paddingTop: safeAreaTop + Sizes.small,
      },
    ],
    [safeAreaTop],
  );

  const handleBackPress = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  return (
    <View style={headerContainerStyle}>
      <View style={styles.headerContent}>
        <TouchableOpacity style={styles.backButton} onPress={handleBackPress}>
          <AssetImage
            asset={Assets.common.backArrowButtonIcon}
            height={18}
            width={18}
          />
        </TouchableOpacity>

        <TouchableOpacity
          onPress={handleBackPress}
          style={styles.courseTextContainer}>
          <Text style={styles.backButtonTitle} numberOfLines={1}>
            {locale.testPrep.back_to_course}
          </Text>
        </TouchableOpacity>

        <View style={styles.expanded} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    alignItems: 'center',
    zIndex: 50,
  },
  backToCourseText: {
    ...Fonts.small,
  },
  backButton: {
    flexDirection: 'row',
    paddingLeft: 8,
    paddingRight: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  expanded: {
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  courseTextContainer: {
    flexShrink: 1,
  },
  backButtonTitle: {
    ...Fonts.medium,
  },
  unitTitle: {
    ...Fonts.caption,
  },
});
