import React from 'react';

import locale from '../../App/locale';
import { APTutorialDescriptionWithLearnMore } from '../../Tutorial/components';
import { Tutorial, TutorialType } from '../../Tutorial/entities';
import { OnboardingAssets } from '../assets';
import {
  SATTestimonialsCarousel,
  SATTestimonialsHeader,
} from '../components/tutorials';

export const SATTutorialListData: Tutorial[] = [
  {
    tutorialKey: TutorialType.SATBootcampTutorial1,
    screenImage: OnboardingAssets.SATBootcampTutorial1,
    title: locale.onboarding.sat_bootcamp_tutorial_1_title,
    description: locale.onboarding.sat_bootcamp_tutorial_1_description,
  },
  {
    tutorialKey: TutorialType.SATBootcampTutorial2,
    screenImage: OnboardingAssets.SATBootcampTutorial2,
    title: locale.onboarding.sat_bootcamp_tutorial_2_title,
    description: locale.onboarding.sat_bootcamp_tutorial_2_description,
  },
  {
    tutorialKey: TutorialType.SATBootcampTutorial3,
    screenImage: OnboardingAssets.SATBootcampTutorial3,
    title: locale.onboarding.sat_bootcamp_tutorial_3_title,
    description: locale.onboarding.sat_bootcamp_tutorial_3_description,
  },
  {
    tutorialKey: TutorialType.SATBootcampTutorial4,
    header: React.createElement(SATTestimonialsHeader),
    component: React.createElement(SATTestimonialsCarousel),
    title: locale.tutorial.ap_tutorial_4_title,
    description: React.createElement(APTutorialDescriptionWithLearnMore),
  },
];
