import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ImageSourcePropType,
  LayoutChangeEvent,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import Animated, { FadeIn, FadeOut } from 'react-native-reanimated';

import Assets from '../../../App/assets';
import locale from '../../../App/locale';
import { useStateWithRef } from '../../services/hooks';
import { Colors, Fonts, Sizes } from '../../services/utils/AppConstants';

type Props = {
  points: number;
  isPositive?: boolean;
  isSmall?: boolean;
  isAnswered?: boolean;
  parentY?: number;
  onLayoutChange?: (layout: {
    x: number;
    y: number;
    width: number;
    height: number;
  }) => void;
};

type PointsFeedbackProps = {
  showFeedback: boolean;
  correct: boolean;
};

const PointsFeedback = ({
  showFeedback,
  correct,
}: PointsFeedbackProps): React.ReactElement | null => {
  const [showThumbs, setShowThumbs] = useState(false);
  const timeoutRef = useRef<number | NodeJS.Timeout | null>(null);

  const showThumbsUp = useMemo(() => {
    return showThumbs && correct;
  }, [showThumbs, correct]);

  const showThumbsDown = useMemo(() => {
    return showThumbs && !correct;
  }, [showThumbs, correct]);

  const handleShowThumbs = useCallback((shown: boolean) => {
    if (shown) {
      timeoutRef.current = setTimeout(() => {
        setShowThumbs(true);
      }, 400);
    } else {
      clearTimeout(timeoutRef.current as number);
      setShowThumbs(false);
    }
  }, []);

  useEffect(() => {
    handleShowThumbs(showFeedback);
  }, [showFeedback, handleShowThumbs]);

  if (!showFeedback && !showThumbs) {
    return null;
  }

  return (
    <Animated.View
      entering={FadeIn.duration(300)}
      exiting={FadeOut.duration(400)}
      style={[
        styles.overlayContainer,
        !correct ? styles.incorrectOverlayContainer : null,
      ]}>
      {showThumbsUp ? (
        <Animated.Image
          entering={FadeIn.duration(300)}
          exiting={FadeOut.duration(400)}
          source={Assets.home.correctAnswerGif.path as ImageSourcePropType}
          resizeMode="contain"
          style={styles.thumbsUp}
        />
      ) : null}
      {showThumbsDown ? (
        <Animated.Image
          entering={FadeIn.duration(300)}
          exiting={FadeOut.duration(400)}
          source={Assets.home.wrongAnswerGif.path as ImageSourcePropType}
          resizeMode="contain"
          style={[styles.thumbsUp, styles.thumbsDown]}
        />
      ) : null}
    </Animated.View>
  );
};

const ContentPointsView = (props: Props): React.ReactElement => {
  const [showFeedback, setShowFeedback] = useStateWithRef(
    props?.isAnswered ?? false,
  );

  const onLayout = (event: LayoutChangeEvent) => {
    const { x, y, width, height } = event.nativeEvent.layout;
    const adjustedY = y + (props.parentY ?? 0);
    props.onLayoutChange?.({ x: x, y: adjustedY, width, height });
  };

  useEffect(() => {
    setShowFeedback(props.isAnswered ?? false);
    if (props.isAnswered) {
      const timeoutId = setTimeout(() => {
        setShowFeedback(false);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    return () => {};
  }, [props.isAnswered, setShowFeedback]);

  const correctAnsContainerStyle = () => {
    if (props?.isPositive) {
      return styles.correctAnsContainer;
    } else if (showFeedback && !props?.isPositive) {
      return styles.incorrectOverlayContainer;
    }
    return null;
  };

  const correctAnsTextStyle = () => {
    if (props?.isPositive) {
      return styles.feedbackAnsText;
    }
    return null;
  };

  return (
    <View
      onLayout={onLayout}
      style={[
        styles.container,
        props.isSmall && styles.smallContainer,
        correctAnsContainerStyle(),
      ]}>
      <Text style={[styles.title, correctAnsTextStyle()]}>
        {props.points} {locale.home_screen_card.points}
      </Text>
      {showFeedback && (
        <PointsFeedback
          showFeedback={showFeedback}
          correct={props?.isPositive ?? false}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.black60,
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    height: Sizes.normalizeIP14PM(30),
    paddingHorizontal: Sizes.normalizeIP14PM(10),
    borderRadius: Sizes.normalizeIP14PM(20),
    marginBottom: Sizes.normalizeIP14PM(4),
  },
  smallContainer: {
    height: Sizes.normalizeIP14PM(24),
  },
  title: {
    ...Fonts.normalize(Fonts.small),
    color: Colors.actionGreen,
  },
  overlayContainer: {
    position: 'absolute',
    width: '140%',
    height: Sizes.normalizeIP14PM(30),
    backgroundColor: Colors.emeraldGreen,
    zIndex: 30,
    borderRadius: Sizes.normalizeIP14PM(20),
    borderColor: Colors.emeraldGreen,
  },
  incorrectOverlayContainer: {
    backgroundColor: Colors.lightRed,
    borderColor: Colors.lightRed,
  },
  thumbsUp: {
    width: Sizes.xlarge,
    height: Sizes.xlarge,
    alignSelf: 'center',
    zIndex: 4,
    transform: [{ translateY: -Sizes.large / 4 }],
  },
  thumbsDown: {
    transform: [{ rotate: '180deg' }, { translateY: -Sizes.small / 2 }],
  },
  correctAnsContainer: {
    backgroundColor: Colors.emeraldGreen,
  },
  feedbackAnsText: {
    color: 'white',
  },
});

export default ContentPointsView;
