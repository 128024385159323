import React, { useCallback, useState } from 'react';

import { Card } from '../../entities';

export const useCardState = <T>(
  card: Card,
  initialState: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [state, setState] = useState<Record<string, T>>({});

  const newSetState = useCallback(
    (newState: T) => {
      return setState(prevState => ({
        ...prevState,
        [card.uniqueId]: newState,
      }));
    },
    [card.uniqueId],
  ) as React.Dispatch<React.SetStateAction<T>>;

  const newState = state[card.uniqueId] ?? initialState;

  return [newState, newSetState];
};
