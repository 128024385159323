import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import {
  AP_ONBOARDING_SLICE_NAME,
  ApOnboardingSliceState,
} from '../ApOnboardingSlice';

export const toggleApCourseAndTrackAction = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/toggleApCourseAndTrackAction`,
  async (courseName: string, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const localState = rootState.apOnboarding;

    const isCourseSelected = localState.selectedCourses.includes(courseName);
    const analytics = isCourseSelected
      ? Analytics.apOnboardingCourseDeselected
      : Analytics.apOnboardingCourseSelected;

    trackAnalyticsEvent(analytics, {
      courseName,
      from: rootState.onboarding.bootcampEntryPoint,
    });
  },
);

export const toggleApCourseAndTrackActionExtraReducers = (
  builder: ActionReducerMapBuilder<ApOnboardingSliceState>,
): void => {
  builder.addCase(toggleApCourseAndTrackAction.fulfilled, (state, action) => {
    const courseName = action.meta.arg;
    const isCourseSelected = state.selectedCourses.includes(courseName);

    if (isCourseSelected) {
      state.selectedCourses = state.selectedCourses.filter(
        selectedCourse => selectedCourse !== courseName,
      );
    } else {
      state.selectedCourses.push(courseName);
    }
  });
};
