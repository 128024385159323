import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  PanResponder,
  PanResponderInstance,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

import { useAiFigureCharacterNavigator } from '../../../AiFigure/services/hooks';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { setContentMuted } from '../../../Learn/services/slices';
import { RaiseHandScreenTypes } from '../../../RaiseHand/entities';
import { CommonAssets } from '../../assets';
import { ContentCard } from '../../entities';
import {
  addMediaPlaybackEventListener,
  addMediaProgressEventListener,
  MediaPlayback,
} from '../../events';
import { useStateWithRef } from '../../services/hooks';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../services/utils/AppConstants';
import { LinearGradient } from '../../services/utils/polyfills';
import { VideoSubtitles, VideoSubtitlesButtonToggle } from '../video-subtitles';

import BackgroundVideo from './BackgroundVideo';
import { BackgroundVideoRefType } from './BackgroundVideo/container';
import MuteButtonView from './MuteButtonView';
import RateButtonView from './RateButtonView';
import { SeekbarView } from './SeekbarView';
import { VideoFrameViewButton } from './VideoFrameViewButton';

const VIDEO_FRAME_HEIGHT = Sizes.normalizeIP14PM(200);
const VIDEO_FRAME_WIDTH = VIDEO_FRAME_HEIGHT * 0.73;
const SUBTITLE_FRAME_WIDTH = VIDEO_FRAME_WIDTH - 32;
const VIDEO_BORDER_RADIUS = Sizes.normalizeIP14PM(8);
const TIME_TO_SHOW_VIDEO = 500;
const SEEKBAR_WIDTH = VIDEO_FRAME_WIDTH - 6;

const VIDEO_FRAME_PADDING = 0;

type Props = {
  card: ContentCard;
  videoUrl: string;
  subtitleUrl?: string;
  name: string;
  title: string;
  isVisible: boolean;
  showVideo: boolean;
  shouldRestart: boolean;
  onVideoEnded?: () => void;
  hideVideo?: () => void;
  relative?: boolean;
  showChatMe?: boolean;
  onPressChatMe?: () => void;
  from?: RaiseHandScreenTypes;
};

export type VideoFrameViewRef = {
  pause: () => void;
};

export const VideoFrameView = forwardRef<VideoFrameViewRef, Props>(
  (props, ref): React.ReactElement => {
    const dispatch = useAppDispatch();

    const handleOpenAiFigure = useAiFigureCharacterNavigator();

    const backgroundVideoRef = useRef<BackgroundVideoRefType>();
    const moveTransition = useRef(
      new Animated.Value(Sizes.getAppWindowWidth()),
    );

    const contentMuted = useAppSelector(state => state.questions.contentMuted);

    const [isVideoEnded, setIsVideoEnded, isVideoEndedRef] =
      useStateWithRef(false);
    const [isVideoPaused, setIsVideoPaused] = useState(false);

    const showVideoAnimation = useCallback(
      (animationTimingMultiplier: number = 1) => {
        Animated.timing(moveTransition.current, {
          toValue:
            -VIDEO_FRAME_PADDING +
            Sizes.getAppWindowWidth() -
            VIDEO_FRAME_WIDTH,
          duration: TIME_TO_SHOW_VIDEO * animationTimingMultiplier,
          useNativeDriver: false,
        }).start();
      },
      [],
    );

    const hideVideoAnimation = (animationTimingMultiplier: number = 1) => {
      Animated.timing(moveTransition.current, {
        toValue: Sizes.getAppWindowWidth(),
        duration: TIME_TO_SHOW_VIDEO * animationTimingMultiplier,
        useNativeDriver: false,
      }).start();
    };

    const onVideoCloseAnimationEnded = () => {
      if (props.hideVideo) {
        props.hideVideo();
      }
    };

    const panResponder = React.useRef<PanResponderInstance>(
      PanResponder.create({
        // Ask to be the responder.
        onStartShouldSetPanResponder: (_evt, _gestureState) => true,
        onMoveShouldSetPanResponder: (evt, gestureState) => {
          //return true if user is swiping, return false if it's a single click
          return Math.abs(gestureState.dx) + Math.abs(gestureState.dy) > 0;
        },
        onPanResponderGrant: (_evt, _gestureState) => {},
        onPanResponderMove: (_evt, gestureState) => {
          moveTransition.current.setValue(
            Math.max(gestureState.dx, 0) +
              Sizes.getAppWindowWidth() -
              VIDEO_FRAME_WIDTH,
          );
        },
        onPanResponderRelease: (evt, gestureState) => {
          const animationTimingMultiplier =
            1 - Math.abs(gestureState.dx / VIDEO_FRAME_WIDTH);
          if (gestureState.dx > VIDEO_FRAME_WIDTH / 3) {
            hideVideoAnimation(animationTimingMultiplier);
            setTimeout(
              onVideoCloseAnimationEnded,
              TIME_TO_SHOW_VIDEO * animationTimingMultiplier,
            );
          } else {
            showVideoAnimation(animationTimingMultiplier);
          }
        },
      }),
    ).current;

    useEffect(() => {
      if (props.showVideo) {
        showVideoAnimation();

        if (isWebPlatform) {
          dispatch(setContentMuted(false));
        }
        setIsVideoPaused(false);
      } else {
        hideVideoAnimation();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.showVideo]);

    useEffect(() => {
      const unsubscribe = addMediaPlaybackEventListener(e => {
        if (e.uniqueId !== props.card.uniqueId) {
          return;
        }

        if (e.status === MediaPlayback.PAUSE) {
          setIsVideoPaused(true);
        }
      });

      return () => {
        unsubscribe();
      };
    }, [props.card.uniqueId, setIsVideoPaused]);

    useEffect(() => {
      const unsubscribe = addMediaProgressEventListener(event => {
        if (event.uniqueId === props.card.uniqueId) {
          if (event.progress / event.duration < 0.5) {
            setIsVideoEnded(false);
          }
        }
      });
      return () => {
        unsubscribe();
      };
    }, [props.card.uniqueId, setIsVideoEnded]);

    useImperativeHandle(ref, () => ({
      pause: () => setIsVideoPaused(true),
    }));

    const onVideoPress = useCallback(() => {
      setIsVideoPaused(prev => !prev);
    }, []);

    const onVideoEnded = useCallback(() => {
      setIsVideoEnded(true);
      setIsVideoPaused(true);
    }, [setIsVideoEnded]);

    const onVideoRestarted = useCallback(() => {
      setIsVideoEnded(false);
    }, [setIsVideoEnded]);

    const handleSetSeekMediaPosition = useCallback(
      (position: number) => {
        backgroundVideoRef.current?.seekToRelativePosition(position);

        if (isVideoEndedRef.current) {
          setIsVideoEnded(false);
        }
      },
      [isVideoEndedRef, setIsVideoEnded],
    );

    const mainAnimatedStyle = useMemo(() => {
      return [
        styles.mainContainer,
        props.relative && { position: 'relative', right: 0, top: 0 },
        !props.relative && customStyle.mainContainer(moveTransition.current),
      ] as Animated.WithAnimatedArray<ViewStyle>;
    }, [props.relative]);

    const mainTouchableStyle = useMemo(() => {
      return [
        styles.touchableContainer,
        props.showChatMe && { borderBottomLeftRadius: 0 },
      ];
    }, [props.showChatMe]);

    const managedPanResponder = useMemo(() => {
      return props.relative ? {} : panResponder.panHandlers;
    }, [panResponder.panHandlers, props.relative]);

    const fromParam = useMemo(() => {
      return props.from || RaiseHandScreenTypes.StudyFeed;
    }, [props.from]);

    const wrapperStyle = useMemo(() => {
      return {
        position: props.relative ? 'relative' : 'absolute',
      } as ViewStyle;
    }, [props.relative]);

    return (
      <View style={wrapperStyle}>
        <Animated.View style={mainAnimatedStyle} collapsable={false}>
          <Animated.View
            style={styles.contentContainer}
            collapsable={false}
            {...managedPanResponder}>
            <TouchableOpacity
              activeOpacity={1}
              onPress={onVideoPress}
              style={mainTouchableStyle}>
              <BackgroundVideo
                ref={backgroundVideoRef}
                card={props.card}
                videoUrl={props.videoUrl}
                style={styles.video}
                isPaused={isVideoPaused || !props.showVideo}
                isMuted={contentMuted}
                isVisible={props.isVisible}
                videoStyle={styles.videoLayer}
                onVideoEnded={onVideoEnded}
                onVideoRestarted={onVideoRestarted}
                controlButtonSize={20}
                controlButtonStyle={styles.videoControlButton}
                controlButtonContainerStyle={styles.videoControlButtonContainer}
                shouldRestart={props.shouldRestart}
                tutorName={props.name}
                from={fromParam}
              />
              {props.name && (
                <View style={styles.labelView}>
                  <LinearGradient
                    colors={['#00000000', '#000000AA']}
                    style={styles.labelGradient}
                  />
                  <View style={styles.LabelTextContainer}>
                    <TouchableWithoutFeedback
                      onPress={() => handleOpenAiFigure(props.name)}>
                      <Text style={styles.nameLabel}>{props.name}</Text>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback
                      onPress={() => handleOpenAiFigure(props.name)}>
                      <Text style={styles.titleLabel}>{props.title}</Text>
                    </TouchableWithoutFeedback>
                  </View>
                </View>
              )}

              <VideoFrameViewButton
                isVideoEnded={isVideoEnded}
                isVideoPaused={isVideoPaused}
              />

              <VideoSubtitles
                uniqueId={props.card.uniqueId}
                subtitleUrl={props.subtitleUrl}
                containerStyle={styles.videoSubtitleContainer}
                isSmall
              />

              {!props.relative && <View style={styles.dragHandle} />}

              <RateButtonView style={styles.rateButton} />
              <MuteButtonView iconSize={22} style={styles.muteButton} />
              {props.subtitleUrl && (
                <VideoSubtitlesButtonToggle
                  iconSize={24}
                  style={styles.subtitleButton}
                />
              )}
            </TouchableOpacity>
          </Animated.View>

          <View style={styles.scrubberContainer}>
            <SeekbarView
              uniqueId={props.card.uniqueId}
              customWidth={SEEKBAR_WIDTH}
              style={styles.seekbarContainer}
              circleStyle={styles.seekbarCircle}
              fillStyle={styles.seekbarFill}
              trackStyle={styles.seekbarTrack}
              fillFollowPosition
              seekTo={handleSetSeekMediaPosition}
            />
          </View>
        </Animated.View>

        {props.showChatMe && (
          <TouchableOpacity
            style={styles.chatMeContainer}
            onPress={props.onPressChatMe}>
            <AssetImage
              asset={CommonAssets.PlainChat}
              containerStyle={styles.chatInputIcon}
              height={Sizes.semiMedium}
            />
            <Text style={styles.chatMeText}>{locale.common.chat_with_me}</Text>
          </TouchableOpacity>
        )}
      </View>
    );
  },
);

const customStyle = {
  mainContainer: (moveTransition: Animated.Value) => ({
    left: moveTransition,
  }),
};

const styles = StyleSheet.create({
  mainContainer: {
    top: Sizes.normalizeIP14PM(42),
    right: 30,
    position: 'absolute',
  },
  contentContainer: {
    zIndex: 10,
  },
  touchableContainer: {
    width: VIDEO_FRAME_WIDTH,
    height: VIDEO_FRAME_HEIGHT,
    borderTopLeftRadius: VIDEO_BORDER_RADIUS,
    borderBottomLeftRadius: VIDEO_BORDER_RADIUS,
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  video: {
    width: '100%',
    height: '100%',
    borderRadius: VIDEO_BORDER_RADIUS,
  },
  labelView: {
    bottom: 0,
    left: 0,
    right: 0,
    position: 'absolute',
  },
  labelGradient: {
    height: 40,
  },
  LabelTextContainer: {
    paddingHorizontal: 8,
    backgroundColor: '#000000AA',
    paddingBottom: 16,
    borderBottomEndRadius: 8,
    borderBottomStartRadius: 8,
  },
  nameLabel: {
    ...Fonts.caption,
    textAlign: 'left',
  },
  titleLabel: {
    ...Fonts.small,
    textAlign: 'left',
    color: 'white',
    opacity: 0.7,
    marginTop: 2,
  },
  videoControlButton: {
    opacity: 1,
    width: 20,
  },
  videoControlButtonContainer: {
    bottom: '60%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  videoLayer: {
    borderRadius: VIDEO_BORDER_RADIUS,
  },
  rateButton: {
    position: 'absolute',
    top: 36,
    right: 0,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    transform: [{ scale: 0.8 }, { translateX: 4 }],
  },
  muteButton: {
    position: 'absolute',
    right: 2,
    top: 8,
  },
  subtitleButton: {
    position: 'absolute',
    right: 0,
    top: 68,
  },

  videoSubtitleContainer: {
    maxWidth: SUBTITLE_FRAME_WIDTH,
  },

  dragHandle: {
    left: 4,
    top: '50%',
    transform: [{ translateY: -14 }],
    borderRadius: 2,
    width: 4,
    height: 28,
    backgroundColor: Colors.white60,
    position: 'absolute',
  },

  scrubberContainer: {
    position: 'absolute',
    bottom: 0,
    width: VIDEO_FRAME_WIDTH,
    zIndex: 20,
  },
  seekbarContainer: {
    height: 24,
    position: 'relative',
    opacity: 1,
  },
  seekbarCircle: { top: 12, left: 6 },
  seekbarFill: { height: 4 },
  seekbarTrack: { backgroundColor: 'transparent' },

  chatMeContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: Sizes.small,
    paddingHorizontal: Sizes.xsmall,
    backgroundColor: Colors.white22,
    borderBottomLeftRadius: VIDEO_BORDER_RADIUS,
  },
  chatMeText: {
    ...Fonts.small500,
  },
  chatInputIcon: {
    marginRight: Sizes.xsmall,
  },
});
