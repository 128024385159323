import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import { TeachTapGetUserAccessQueryResponse } from '../../entities';

export const teachTapGetUserAccessGraphQLQuery =
  async (): Promise<TeachTapGetUserAccessQueryResponse> => {
    const response = await graphqlApi.post(
      '',
      {
        query: `
      query TeachTapGetUserAccess {
        teachTapGetUserAccess {
          accessibleCourses {
            courseId
          }
          hasUltimateAccess
          hasSATPrepAccess
        }
      }`,
      },
      {
        headers: {
          ...(await getAuthHeader()),
          'x-api-key': undefined,
        },
      },
    );

    handleGraphQLError(response);

    return response.data.data?.teachTapGetUserAccess;
  };
