import React, { useMemo } from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';

import { getOrdinalSuffixOf } from '../services/utils';
import { Fonts } from '../services/utils/AppConstants';

type Props = {
  numberInString?: string;
  textStyle?: TextStyle;
  ordinalTextStyle?: TextStyle;
};

export const CommonOrdinalNumber = (props: Props): React.ReactElement => {
  const managedNumber = useMemo(() => {
    if (!props.numberInString) {
      return '1';
    }

    if (['1-', '99+'].includes(props.numberInString)) {
      return props.numberInString.replace('-', '').replace('+', '');
    }

    if (isNaN(Number(props.numberInString))) {
      return '1';
    }

    return props.numberInString;
  }, [props.numberInString]);

  const ordinalSuffix = useMemo(() => {
    if (props.numberInString === '1-' || !props.numberInString) {
      return 'st';
    }

    if (props.numberInString === '99+') {
      return 'th';
    }

    return getOrdinalSuffixOf(parseInt(managedNumber, 10));
  }, [managedNumber, props.numberInString]);

  const ordinalSuffixSign = useMemo(() => {
    if (props.numberInString === '1-' || !props.numberInString) {
      return '-';
    }

    if (props.numberInString === '99+') {
      return '+';
    }

    return '';
  }, [props.numberInString]);

  return (
    <View style={styles.container}>
      <Text style={[styles.textMain, props.textStyle]}>{managedNumber}</Text>
      <Text style={[styles.textOrdinal, props.ordinalTextStyle]}>
        {ordinalSuffix}
      </Text>

      {ordinalSuffixSign && (
        <Text style={[styles.textMain, props.textStyle]}>
          {ordinalSuffixSign}
        </Text>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  textMain: {
    ...Fonts.smallBold,
    color: 'white',
  },
  textOrdinal: {
    ...Fonts.xxsmallBold,
    color: 'white',
  },
});
