import { Course, Topic, Unit } from '../../Common/entities';

export class CoursesAlgorithm {
  static getRandomTopic = (course: Course[]): Topic | undefined => {
    const allTopics = course.flatMap(c => c.units.flatMap(u => u.topics));
    if (!allTopics.length) {
      return undefined;
    }

    const randomIndex = Math.floor(Math.random() * allTopics.length);
    return allTopics[randomIndex];
  };

  static getCourseByTopic = (
    course: Course[],
    topic?: Topic,
  ): Course | undefined => {
    return course.find(c =>
      c.units.some(u => u.topics.some(t => t.id === topic?.id)),
    );
  };

  static getCourseByUnit = (
    course: Course[],
    unit?: Unit,
  ): Course | undefined => {
    return course.find(c => c.units.some(u => u.id === unit?.id));
  };
}
