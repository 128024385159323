import React, { useMemo } from 'react';
import { View, StyleSheet, Text } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { PaywallAssets } from '../../assets';
import { useBootcampPaywallData } from '../../services/hooks';

export const BootcampMoneybackGuaranteeCard = (): React.ReactElement => {
  const { programDetails } = useBootcampPaywallData();

  const content = useMemo(() => {
    return {
      title: locale.bootcamp_paywall.study_commitment_card_title.replace(
        '${TARGET_SCORE}',
        programDetails?.targetScore?.toString() || 'N',
      ),
      subtitle: locale.bootcamp_paywall.study_commitment_card_subtitle
        .replace(
          '${HOURS}',
          programDetails?.studyCommitmentHours.toString() || 'N',
        )
        .replace(
          '${TARGET_SCORE}',
          programDetails?.targetScore?.toString() || 'N',
        ),
    };
  }, [programDetails]);

  return (
    <View style={styles.container}>
      <View style={styles.iconContainer}>
        <AssetImage asset={PaywallAssets.checkStar} height={Sizes.semiLarge} />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.featureText}>{content.title}</Text>
        <Text style={styles.description}>{content.subtitle}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: Colors.white10,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.normal,
    rowGap: Sizes.medium,
  },
  iconContainer: {
    justifyContent: 'center',
  },
  textContainer: {
    columnGap: Sizes.small,
  },
  featureText: {
    ...Fonts.semiMedium500,
    marginLeft: Sizes.xsmall,
  },
  description: {
    ...Fonts.small500,
    marginLeft: Sizes.xsmall,
    color: Colors.white60,
  },
});
