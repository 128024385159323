import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { openSATBootcampFlow } from '../../../../Onboarding/services/slices';
import { EnrollUserToSATCourseParams } from '../../../entities';
import { getBootcampEntryPointForPaywall } from '../../utils';
import {
  COURSE_ENROLLMENTS_SLICE_NAME,
  enrollUserToCourse,
} from '../CourseEnrollmentsSlice';

export const enrollUserToSATCourse = createAsyncThunk(
  `${COURSE_ENROLLMENTS_SLICE_NAME}/enrollUserToSATCourse`,
  async (params: EnrollUserToSATCourseParams, thunkAPI) => {
    if (!params.course?.id) {
      return false;
    }

    const rootState = thunkAPI.getState() as RootState;
    const programDetails = rootState.SAT.bootcamp;
    const hasSATPrepAddon = rootState.userAccess.hasSATPrepAccess;

    if (!programDetails?.details?.programType && !hasSATPrepAddon) {
      const bootcampEntrypoint = getBootcampEntryPointForPaywall(
        params.paywallEntrypoint,
      );

      thunkAPI.dispatch(
        openSATBootcampFlow({
          entrypoint: bootcampEntrypoint,
        }),
      );
      return false;
    }

    thunkAPI.dispatch(
      enrollUserToCourse({
        course: params.course,
        entryPoint: params.paywallEntrypoint,
        isFromCoursePicker: params.isFromCoursePicker,
        skipRedirectToPaywall: true,
      }),
    );

    return true;
  },
);
