import React, { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  PaywallCourseName,
  PaywallPurchaseType,
} from '../../../Common/entities';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  Colors,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';
import { useIsFocused } from '../../../Common/services/utils/polyfills/functions';
import { useAPProgramPaywallData } from '../../../Paywall/services/hooks/APProgramPaywallData';
import { BootcampEntryPoint } from '../../entities';

import { ApProgramUpsellOverlayAction } from './ApProgramUpsellOverlayAction';
import { ApProgramUpsellOverlayFeatures } from './ApProgramUpsellOverlayFeatures';
import { ApProgramUpsellOverlayHeader } from './ApProgramUpsellOverlayHeader';

type Props = {
  courseName?: string;
};

const CONTAINER_HEIGHT = Sizes.getAppWindowHeight() * 0.6;

export const ApProgramUpsellOverlay = (props: Props): React.ReactElement => {
  const isFocused = useIsFocused();
  const { discountPercentage } = useAPProgramPaywallData();

  useEffect(() => {
    if (isFocused) {
      trackAnalyticsEvent(Analytics.apOfferShown, {
        course: PaywallCourseName.AP_PROGRAM,
        from: BootcampEntryPoint.LOCKED_AP_COURSE,
        purchaseType: PaywallPurchaseType.AP_PROGRAM,
        discount: `${discountPercentage}%`,
      });
    }
  }, [isFocused, discountPercentage]);

  return (
    <LinearGradient
      style={styles.container}
      colors={Colors.topAlphaGradientStrongInvert}
      start={{ x: 0, y: 0 }}
      end={{ x: 0, y: 0.45 }}>
      <ApProgramUpsellOverlayHeader courseName={props.courseName} />

      <View style={styles.featureListContainer}>
        <ApProgramUpsellOverlayFeatures />
      </View>

      <View style={styles.actionContainer}>
        <ApProgramUpsellOverlayAction courseName={props.courseName} />
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    minHeight: CONTAINER_HEIGHT,
    width: '100%',
    position: 'absolute',
    padding: Sizes.medium,
    justifyContent: 'flex-end',
    bottom: 0,
    left: 0,
    right: 0,
  },

  featureListContainer: {
    marginTop: Sizes.small,
  },

  actionContainer: {
    marginTop: Sizes.medium,
  },
});
