import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { handleNetworkActionErrorSilently } from '../../../../App/services/utils';
import { GetCourseMaxTopicGradeParams } from '../../../entities';
import { getMaxGradeGraphQLCall } from '../../../graphql';
import { COURSES_SLICE_NAME, CoursesSliceState } from '../CoursesSlice';

export const getMaxCourseTopicGrade = createAsyncThunk(
  `${COURSES_SLICE_NAME}/getMaxCourseTopicGrade`,
  async (params: GetCourseMaxTopicGradeParams) => {
    try {
      return await getMaxGradeGraphQLCall({ courseId: params.courseId });
    } catch (e) {
      if (e instanceof Error) {
        handleNetworkActionErrorSilently(e);
      }

      return { courseId: params.courseId, topics: [] };
    }
  },
);

export const getMaxCourseTopicGradeExtraReducers = (
  builder: ActionReducerMapBuilder<CoursesSliceState>,
): void => {
  builder.addCase(getMaxCourseTopicGrade.pending, (state, action) => {
    const { courseId } = action.meta.arg;
    if (!state.maxCourseTopicMasteryGrade[courseId] && courseId) {
      state.maxCourseTopicMasteryGrade[courseId] = {};
    }
  });

  builder.addCase(getMaxCourseTopicGrade.fulfilled, (state, action) => {
    const { topics, courseId } = action.payload;

    if (!courseId) {
      return;
    }

    topics.forEach(topic => {
      const { id, maxGrade } = topic;
      state.maxCourseTopicMasteryGrade[courseId][id] = maxGrade || '';
    });
  });
};
