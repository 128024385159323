import { createAsyncThunk } from '@reduxjs/toolkit';

import { CommonAwardPointsActivityType } from '../../../../Common/entities/CommonAwardPointsRequestTypes';
import { mapAwardPointsAttributesRequest } from '../../../../Common/services/mappers';
import { setTotalAwardedPoints } from '../../../../Profile/services/slices';
import { awardPointsGraphQLCall } from '../../../../TestPrep/graphql';
import { AwardMilestoneAchievedPointsParams } from '../../../entities';
import { COURSES_SLICE_NAME } from '../CoursesSlice';

export const awardMilestoneAchievedPoints = createAsyncThunk(
  `${COURSES_SLICE_NAME}/awardMilestoneAchievedPoints`,
  async (params: AwardMilestoneAchievedPointsParams, thunkAPI) => {
    const { courseId, unitId, topicId, subjectId } = params;

    if (!courseId || !unitId || !topicId || !subjectId) {
      return;
    }

    const result = await awardPointsGraphQLCall({
      attributes: mapAwardPointsAttributesRequest({
        subjectId,
        courseId,
        unitId,
        topicId,
      }),
      activityType: CommonAwardPointsActivityType.MASTERY_MILESTONE_ACHIEVED,
    });

    thunkAPI.dispatch(setTotalAwardedPoints(result.newTotalPoints));
  },
);
