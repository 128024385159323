import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Dimensions,
  GestureResponderEvent,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';

import { useAppSelector } from '../../../../App/services/hooks';
import store, { useAppDispatch } from '../../../../App/store';
import VideoPlayButton from '../../../../Common/components/common/VideoPlayButton';
import AnimatedBackgroundImage from '../../../../Common/components/home/AnimatedBackgroundImage';
import BackgroundVideo from '../../../../Common/components/home/BackgroundVideo';
import BlurredBackgroundImage from '../../../../Common/components/home/BlurredBackgroundImage';
import { PLAYLIST_HEIGHT } from '../../../../Common/components/home/PlaylistLabelView';
import { SeekbarView } from '../../../../Common/components/home/SeekbarView';
import { VideoSubtitles } from '../../../../Common/components/video-subtitles';
import {
  Card,
  CardType,
  ContentCard,
  ControversialConversationCard,
  DidYouKnowCard,
  DmsFromDeadCard,
  DoubleMemeTextCard,
  FeedType,
  FillGapsWithHelpCard,
  MatchingPairsCard,
  MCQForDSATCard,
  MultipleChoiceQuestionV2,
  TruthOrLieCard,
} from '../../../../Common/entities';
import {
  addMediaPlaybackEventListener,
  addVisibilityChangeEventListener,
  emitCardGestureEvent,
  MediaPlayback,
} from '../../../../Common/events';
import {
  useRefWithSubscribe,
  useSafeAreaCustomInsets,
} from '../../../../Common/services/hooks';
import {
  createDoubleTapAction,
  throttle,
} from '../../../../Common/services/utils';
import {
  Colors,
  isAndroidPlatform,
  isWebPlatform,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../../Common/services/utils/polyfills';
import {
  MessageVideoExplanation,
  VideoWithQuestionTooltip,
} from '../../../../Messages/components';
import { MessageScreenEntryPoints } from '../../../../Messages/entities';
import {
  getCardAttributes,
  setCardAttributes,
  setContentMuted,
  setPassedQuestions,
  viewQuestion,
} from '../../../services/slices';
import ContentCardView from '../content/ContentCardView';
import ControversialConversationView from '../content/ControversialConversationView';
import DidYouKnowView from '../content/DidYouKnowView';
import DoubleMemeView from '../content/DoubleMemeView';
import FillGapsWithHelpCardView from '../content/FillGapsWithHelpCardView';
import MatchingPairsView from '../content/MatchingPairsView';
import MCQDSATItemView from '../content/MCQForDSATItemView';
import MCQItemView from '../content/MCQItemView';
import TruthOrLieCardView from '../content/TruthOrLieCardView';

export type QuestionItemProps = {
  item: Card;
  index: number;
  holderTag: string;
  onPlaylistPress?: () => void;
  itemHeight: number;
  scrollDisabled?: boolean;
  showCoinsAnimation?: boolean;
};

// Found an edge-case where the listener triggers multiple times,
// so we throttle the event to avoid multiple dispatches.

// Potentially will skip some events if the user move too fast (like faster than 500ms), but it's very less likely.
// If the user moves too fast, we can assume they are not really viewing the card
const throttledViewQuestion = throttle((viewedItem: Card) => {
  store.dispatch(viewQuestion(viewedItem));
}, 500);

export default ({
  item,
  index,
  holderTag,
  itemHeight,
  scrollDisabled,
  showCoinsAnimation = false,
}: QuestionItemProps): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { topHeaderSafeAreaHeight, safeAreaBottom } = useSafeAreaCustomInsets();

  // Make sure content peace always visible on web
  const [isVisible, setIsVisible] = useState(isWebPlatform);
  const [isSpeakerMediaPaused, setIsSpeakerMediaPaused] = useState(false);
  const [seekMediaPosition, setSeekMediaPosition] = useState(0);

  const isMediaPaused = useAppSelector(
    state => getCardAttributes(state.questions, item).isMediaPaused,
    (prev, next) => prev === next,
  );

  const contentPaddingBottom = useAppSelector(
    state => getCardAttributes(state.questions, item).contentPaddingBottom,
    (prev, next) => prev === next,
  );

  const isMediaMuted = useAppSelector(
    state => state.questions.contentMuted,
    (prev, next) => prev === next,
  );

  const subtitlesStyle = useMemo(() => {
    let extraTopPadding = 0;

    if (isWebPlatform) {
      extraTopPadding = Sizes.medium;
    } else if (
      [FeedType.Topic, FeedType.TestPractice].includes(holderTag as FeedType)
    ) {
      extraTopPadding = 52;
    }

    return {
      top: topHeaderSafeAreaHeight + extraTopPadding,
    };
  }, [holderTag, topHeaderSafeAreaHeight]);

  const itemRef = useRefWithSubscribe(item);
  const holderTagRef = useRefWithSubscribe(holderTag);
  const indexRef = useRefWithSubscribe(index);

  const setIsPaused = useCallback(
    (value: boolean): void => {
      dispatch(
        setCardAttributes({
          card: itemRef.current,
          attributes: { isMediaPaused: value },
        }),
      );
    },
    [dispatch, itemRef],
  );

  const renderVideoControls = (): React.ReactElement | null => {
    if (isMediaPaused) {
      return <VideoPlayButton />;
    }
    return null;
  };

  const speakerMediaPaused = () => {
    setIsSpeakerMediaPaused(!isSpeakerMediaPaused);
    setIsPaused(!isSpeakerMediaPaused);
  };
  const speakerMediaMuted = (): void => {
    dispatch(setContentMuted(!isMediaMuted));
  };

  const renderQuestion = () => {
    if (item.type === CardType.MCQ_CARD) {
      return (
        <MCQItemView
          item={item as MultipleChoiceQuestionV2}
          isVisible={isVisible}
          showCoinsAnimation={showCoinsAnimation}
        />
      );
    } else if (item.type === CardType.MEME_CARD) {
      return (
        <DoubleMemeView
          item={item as DoubleMemeTextCard}
          isVisible={isVisible}
        />
      );
    } else if (item.type === CardType.DID_YOU_KNOW_CARD) {
      return (
        <DidYouKnowView item={item as DidYouKnowCard} isVisible={isVisible} />
      );
    } else if (item.type === CardType.FILL_IN_THE_BLANK_CARD) {
      return (
        <FillGapsWithHelpCardView
          item={item as FillGapsWithHelpCard}
          isVisible={isVisible}
          showCoinsAnimation={showCoinsAnimation}
        />
      );
    } else if (item.type === CardType.CONTROVERSIAL_CONVERSATION_CARD) {
      return (
        <ControversialConversationView
          item={item as ControversialConversationCard}
          isVisible={isVisible}
        />
      );
    } else if (item.type === CardType.TRUTH_OR_LIE_CARD) {
      return (
        <TruthOrLieCardView
          item={item as TruthOrLieCard}
          isVisible={isVisible}
          showCoinsAnimation={showCoinsAnimation}
        />
      );
    } else if (item.type === CardType.MATCHING_PAIRS_CARD) {
      return (
        <MatchingPairsView
          item={item as MatchingPairsCard}
          isVisible={isVisible}
          showCoinsAnimation={showCoinsAnimation}
        />
      );
    } else if (item.type === CardType.DMs_FROM_THE_DEAD_CARD) {
      return (
        <MessageVideoExplanation
          card={item as DmsFromDeadCard}
          onMediaPaused={() => speakerMediaPaused()}
          isMediaPaused={isMediaPaused}
          onMediaMuted={speakerMediaMuted}
          isMediaMuted={isMediaMuted}
        />
      );
    } else if (
      item.type === CardType.MCQ_FOR_DSAT ||
      item.type === CardType.MCQ_FOR_AP_COURSE
    ) {
      return (
        <MCQDSATItemView item={item as MCQForDSATCard} isVisible={isVisible} />
      );
    } else if ((item as ContentCard).course !== undefined) {
      return (
        <ContentCardView item={item as ContentCard} isVisible={isVisible} />
      );
    } else {
      return <View />;
    }
  };

  const onSingleTap = (e: GestureResponderEvent): void => {
    emitCardGestureEvent({
      gesture: 'tap',
      uniqueId: item.uniqueId,
      position: {
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
      },
    });
  };

  const onDoubleTap = (e: GestureResponderEvent): void => {
    emitCardGestureEvent({
      gesture: 'double-tap',
      uniqueId: item.uniqueId,
      position: {
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
      },
      isFromDM: item.type === CardType.DMs_FROM_THE_DEAD_CARD,
    });
  };

  const onLongTap = (e: GestureResponderEvent): void => {
    emitCardGestureEvent({
      gesture: 'long-tap',
      uniqueId: item.uniqueId,
      position: {
        x: e.nativeEvent.pageX,
        y: e.nativeEvent.pageY,
      },
    });
  };

  useEffect(() => {
    const unsubscribe = addVisibilityChangeEventListener(e => {
      if (e.holderTag !== holderTagRef.current) {
        return;
      }

      const isOnScreen =
        e.visibleItemIndex !== undefined
          ? indexRef.current === e.visibleItemIndex
          : itemRef.current.uniqueId === e.visibleItemUniqueId;

      setIsVisible(isOnScreen);
      setIsPaused(false);

      if (isOnScreen) {
        throttledViewQuestion(itemRef.current);
        dispatch(setPassedQuestions(itemRef.current.uniqueId));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [itemRef, holderTagRef, indexRef, setIsPaused, dispatch]);

  useEffect(() => {
    const unsubscribe = addMediaPlaybackEventListener(e => {
      if (e.uniqueId !== item.uniqueId) {
        return;
      }

      if (e.status === MediaPlayback.PAUSE) {
        setIsPaused(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [item, setIsPaused]);

  const getImageStyles = () => {
    return {
      ...styles.imageBox,
      height: itemHeight - contentPaddingBottom,
    };
  };

  const getContainerStyles = () => {
    return [styles.container, { height: itemHeight }];
  };

  const cardHeightStyle = {
    height:
      Math.min(itemHeight, Dimensions.get('window').height) -
      topHeaderSafeAreaHeight,
    paddingBottom: Math.max(
      itemHeight - (Dimensions.get('window').height - safeAreaBottom),
      0,
    ),
  };

  const questionContainerStyle = {
    marginTop: topHeaderSafeAreaHeight,
  };

  const renderContent = (): React.ReactElement => {
    if (item.type === CardType.EMPTY_LOADING_ITEM) {
      return (
        <View style={getContainerStyles()}>
          <View
            style={[styles.oppaqueHeader, { height: topHeaderSafeAreaHeight }]}
          />
          <View style={styles.flatQuestionContainer}>
            <View style={styles.indicatorContainer}>
              <ActivityIndicator size="large" color="white" />
            </View>
          </View>
        </View>
      );
    }

    if (item.image) {
      return (
        <>
          <AnimatedBackgroundImage
            imageUrl={item.image.url}
            isVisible={isVisible}
            isPaused={isMediaPaused}
            style={getImageStyles()}
            urlSuffix={null}
          />
          <LinearGradient
            style={styles.bottomGradient}
            colors={Colors.bottomCardAlphaGradient}
          />
          <TouchableWithoutFeedback
            onPress={createDoubleTapAction(onSingleTap, onDoubleTap)}
            onLongPress={onLongTap}>
            <View style={[questionContainerStyle, cardHeightStyle]}>
              {renderQuestion()}
            </View>
          </TouchableWithoutFeedback>
        </>
      );
    } else if (item.blurredImage) {
      return (
        <>
          <BlurredBackgroundImage
            imageUrl={item.blurredImage.url}
            isVisible={isVisible}
            style={getImageStyles()}
          />
          <LinearGradient
            style={styles.bottomGradient}
            colors={Colors.bottomCardAlphaGradient}
          />
          <TouchableWithoutFeedback
            onPress={createDoubleTapAction(onSingleTap, onDoubleTap)}
            onLongPress={onLongTap}>
            <View style={[questionContainerStyle, cardHeightStyle]}>
              {renderQuestion()}
            </View>
          </TouchableWithoutFeedback>
        </>
      );
    } else if (item.videoUrl || (item as DmsFromDeadCard).video) {
      const itemVideoUrl = item.videoUrl ?? (item as DmsFromDeadCard).video;
      const addToolkit = item.type === CardType.DMs_FROM_THE_DEAD_CARD ?? false;
      return (
        <>
          <BackgroundVideo
            videoUrl={itemVideoUrl}
            isVisible={isVisible}
            isPaused={isMediaPaused}
            style={getImageStyles()}
            isMuted={isMediaMuted}
            seekToRelativePosition={seekMediaPosition}
            card={item}
            repeat={true}
            {...(!addToolkit && {
              from: MessageScreenEntryPoints.DM_THE_TEACHER,
            })}
            {...(!addToolkit && {
              tutorName: (item as DmsFromDeadCard).character?.username,
            })}
          />
          <LinearGradient
            style={styles.bottomGradient}
            colors={Colors.bottomCardAlphaGradient}
          />

          <VideoSubtitles
            uniqueId={item.uniqueId}
            subtitleUrl={item.subtitleUrl}
            containerStyle={subtitlesStyle}
          />

          <TouchableWithoutFeedback
            onPress={createDoubleTapAction(onSingleTap, onDoubleTap)}
            onLongPress={onLongTap}>
            <View style={[questionContainerStyle, cardHeightStyle]}>
              {renderQuestion()}
            </View>
          </TouchableWithoutFeedback>
          <View style={styles.seekbar}>
            <SeekbarView
              uniqueId={item.uniqueId}
              seekTo={setSeekMediaPosition}
            />
          </View>
          {renderVideoControls()}
          {addToolkit && (
            <VideoWithQuestionTooltip
              message={(item as DmsFromDeadCard).question}
            />
          )}
        </>
      );
    } else {
      return (
        <LinearGradient
          colors={Colors.screenGradient}
          style={[styles.imageBox, { height: itemHeight }]}>
          {!item.ommitHeader && <View style={styles.oppaqueHeader} />}
          <LinearGradient
            style={styles.bottomGradient}
            colors={Colors.bottomCardAlphaGradient}
          />
          <TouchableWithoutFeedback
            onPress={createDoubleTapAction(onSingleTap, onDoubleTap)}
            onLongPress={onLongTap}>
            <View style={[questionContainerStyle, cardHeightStyle]}>
              {renderQuestion()}
            </View>
          </TouchableWithoutFeedback>
        </LinearGradient>
      );
    }
  };

  return (
    <ScrollView
      nestedScrollEnabled
      keyboardShouldPersistTaps="always"
      keyboardDismissMode="on-drag"
      pagingEnabled={!isAndroidPlatform}
      scrollEnabled={!scrollDisabled && !isWebPlatform}
      style={getContainerStyles()}>
      {renderContent()}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  bottomGradient: {
    bottom: PLAYLIST_HEIGHT,
    height: 100,
    position: 'absolute',
    width: '100%',
  },
  container: {
    backgroundColor: '#05071B00',
    width: '100%',
  },
  courseContainer: {
    backgroundColor: '#05071B00',
    width: '100%',
    flex: 1,
  },
  flatQuestionContainer: {
    flex: 1,
  },
  imageBox: {
    width: '100%',
  },
  indicatorContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  oppaqueHeader: {
    backgroundColor: 'black',
    width: '100%',
  },
  seekbar: {
    bottom: Math.max(PLAYLIST_HEIGHT, 3),
    left: 0,
    position: 'absolute',
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: 'transparent',

    justifyContent: 'center',

    alignSelf: 'center',
    bottom: '48.5%',

    position: 'absolute',
  },
  playButton: {
    alignSelf: 'center',
    opacity: 0.4,
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
});
