import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { AddOnType } from '../../../entities';
import {
  BOOTCAMP_PAYWALL_SLICE_NAME,
  BootcampPaywallSliceState,
} from '../BootcampPaywallSlice';

export const toggleSATBootcampAPAddonTrackAction = createAsyncThunk(
  `${BOOTCAMP_PAYWALL_SLICE_NAME}/toggleSATBootcampAPAddonTrackAction`,
  async (selected: boolean) => {
    trackAnalyticsEvent(Analytics.addOnToggled, {
      addOnType: AddOnType.AP_PROGRAM,
      selected,
    });

    return selected;
  },
);

export const toggleSATBootcampAPAddonTrackActionExtraReducers = (
  builder: ActionReducerMapBuilder<BootcampPaywallSliceState>,
): void => {
  builder.addCase(
    toggleSATBootcampAPAddonTrackAction.fulfilled,
    (state, action) => {
      state.isAPAddOnSelected = action.payload;
    },
  );
};
