import { CommonExamType } from '../../../Common/entities';
import {
  ExamDateItem,
  SATBootcampMetadata,
  SatBootcampSubmitDataParams,
  SATInputSummary,
  SATLastScoreType,
} from '../../entities';
import { OnboardingSliceState } from '../slices';
import { checkIfSATInputValid } from '../utils';

import {
  getFutureExamDatesWithLimit,
  mapSATLastScores,
  mapSATTargetScores,
} from './OnboardingStateMapper';

const EXAM_DATES_DISPLAY_LIMIT = 7;
const DEFAULT_SAT_MINIMUM_SCORE = 1000;

export const shouldShowOnboarding = (
  state: OnboardingSliceState,
  isLoggedIn: boolean,
): boolean => {
  const shouldRedirect =
    state.shouldOpenSatBootcampFlowForUpsell ||
    state.doOnboardingFlowAfterSignup;

  if (shouldRedirect && isLoggedIn) {
    return true;
  }

  return false;
};

export const getSATExamDatesToDisplay = (
  state: OnboardingSliceState,
): ExamDateItem[] => {
  return getFutureExamDatesWithLimit(
    state?.SATConfigs?.examDates || [],
    EXAM_DATES_DISPLAY_LIMIT,
  );
};

export const getSATLastScoreData = (
  state: OnboardingSliceState,
): SATLastScoreType[] => {
  return mapSATLastScores(
    state?.SATConfigs?.minimumScore || DEFAULT_SAT_MINIMUM_SCORE,
  );
};

export const getSATTargetScoreData = (
  state: OnboardingSliceState,
): number[] => {
  return mapSATTargetScores(
    state?.SATConfigs?.minimumScore || DEFAULT_SAT_MINIMUM_SCORE,
    state.examLastScore,
  );
};

export const isAllSatExamInputFilled = (
  state: OnboardingSliceState,
): boolean => {
  return checkIfSATInputValid({
    examLastScore: state.examLastScore,
    examTargetScore: state.examTargetScore,
    examDate: state.examDate,
  });
};

export const getSATInputSummary = (
  state: OnboardingSliceState,
): SATInputSummary => {
  const smallestLastScore = getSATLastScoreData(state)[0].value;

  return {
    examDate: state.examDate,
    lastScore: state.examLastScore || smallestLastScore,
    targetScore: state.examTargetScore,
  };
};

export const isSatBootcampSummaryReady = (
  state: OnboardingSliceState,
): boolean => {
  return Boolean(state.SATProgramDetails && !state.loadingSATProgramDetails);
};

export const isComingFromBootcampFlow = (
  state: OnboardingSliceState,
): boolean => Boolean(state.examType);

export const isComingFromSATBootcampFlow = (
  state: OnboardingSliceState,
): boolean => state.examType === CommonExamType.SAT;

export const isComingFromAPProgramFlow = (
  state: OnboardingSliceState,
): boolean => state.examType === CommonExamType.AP;

export const mapSatBootcampSubmitData = (
  props: SatBootcampSubmitDataParams,
): SATBootcampMetadata => {
  const { SATProgramDetails } = props;

  return {
    examDate: SATProgramDetails?.examDate,
    currentScore: SATProgramDetails?.currentScore,
    targetScore: SATProgramDetails?.targetScore,
    programType: SATProgramDetails?.programType,
    enrolledAt: SATProgramDetails?.enrolledAt,
    optedForAddon: props.optedForAddon || false,
  };
};
