import React, { useMemo } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { TestPrepQuestion } from '../entities';

type Props = {
  index: number;
  question: TestPrepQuestion;
  onPress: (index: number) => void;
  isReviewMode?: boolean;
  isActiveQuestion?: boolean;
  disabled?: boolean;
};

export const TestPrepQuestionSelectorButton = ({
  index,
  question,
  onPress,
  isReviewMode,
  isActiveQuestion,
  disabled,
}: Props): React.ReactElement => {
  const asset = useMemo(() => {
    if (question.chosenOption === undefined) {
      if (!isReviewMode) {
        return null;
      }
      return Assets.test.skippedAnswer;
    }
    if (question.chosenOption.correct) {
      return Assets.test.correctOptionWithBG;
    }
    return Assets.test.wrongOptionWithBG;
  }, [question.chosenOption, isReviewMode]);

  const buttonStyle = useMemo(
    () => [
      styles.questionStatus,
      isActiveQuestion && styles.questionStatusActive,
      disabled && styles.buttonDisabled,
    ],
    [isActiveQuestion, disabled],
  );

  const iconStyle = useMemo(
    () => [
      styles.questionStatusIcon,
      isActiveQuestion && styles.questionStatusIconActive,
    ],
    [isActiveQuestion],
  );

  const textStyle = useMemo(
    () => [
      isActiveQuestion ? styles.questionNumberActive : styles.questionNumber,
    ],
    [isActiveQuestion],
  );

  return (
    <TouchableOpacity
      style={buttonStyle}
      onPress={() => onPress(index)}
      disabled={disabled}>
      <Text style={textStyle}>{index + 1}</Text>
      <View style={iconStyle}>
        {asset && <AssetImage asset={asset} height={14} />}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  questionStatus: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 39,
    borderRadius: 5,
    gap: 2,
    backgroundColor: Colors.white10,
    paddingVertical: Sizes.xsmall,
    paddingHorizontal: 6,
  },
  questionNumber: {
    ...Fonts.xsmall600,
    color: Colors.white60,
  },
  questionNumberActive: {
    ...Fonts.xsmall800,
    color: Colors.white100,
  },
  questionStatusActive: {
    backgroundColor: Colors.lightBlue60,
  },
  questionStatusIcon: {
    width: 14,
    height: 14,
    backgroundColor: Colors.white10,
    borderRadius: 7,
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonDisabled: {
    opacity: 0.4,
  },
  questionStatusIconActive: {
    backgroundColor: Colors.white40,
  },
});
