import { GetMarkdownLatexHtmlTemplateProps } from '../../entities';

export const getMarkdownLatexHtmlTemplate = (
  props: GetMarkdownLatexHtmlTemplateProps,
): string => {
  return `
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">
      <style>
        body {
          padding: 0;
          margin: 0;
          min-height: 0;
         
          font-size: ${props.fontSize}px;
          font-family: ${props.fontFamily || 'sans-serif'};
          font-weight: 500;
        }
        * {
          min-height: 0;
        }
        ul {
          margin-left: -12px;
        }
        ol {
          margin-left: -12px;
        }
        #formula {
          word-wrap: break-word !important;
        }
        #formula br {
          display: block;
          content: "";
          border-bottom: 8px solid transparent; // Works in Chrome/Safari
        }
        @-moz-document url-prefix() {
          #formula br {
            margin-bottom: 10px; // 'border-bottom' doesn't work in firefox and 'margin-bottom' doesn't work in Chrome.
          }
        }
      </style>
      <div id="formula" class="latext_text" style="margin: 0; padding: 0; color: ${
        props.textColor
      };">
        ${props.content}
      </div>
    `;
};
