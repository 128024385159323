import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  initConnection,
  ProductPurchase,
  PurchaseError,
  purchaseErrorListener,
  purchaseUpdatedListener,
  SubscriptionPurchase,
} from 'react-native-iap';

import { RootState } from '../../../../App/store';
import {
  PURCHASE_SLICE_NAME,
  setListenersInitialized,
} from '../PurchasesSlice';

import { fetchProductsData } from './FetchProductsDataAction';
import { handlePurchaseUpdate } from './HandlePurchaseUpdateAction';

export const subscribeForPurchasesUpdate = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/registerPurchaseListeners`,
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    if (state.purchases.listenersInitialized) {
      return;
    }
    await initConnection();
    thunkApi.dispatch(fetchProductsData());

    purchaseUpdatedListener(
      async (purchase: SubscriptionPurchase | ProductPurchase) => {
        return await thunkApi.dispatch(handlePurchaseUpdate(purchase)).unwrap();
      },
    );

    purchaseErrorListener((error: PurchaseError) => {
      console.warn('purchaseErrorListener', error);
    });

    thunkApi.dispatch(setListenersInitialized(true));
  },
);
