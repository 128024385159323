import * as Sentry from '@sentry/react-native';
import appsFlyer from 'react-native-appsflyer';

import { registerDeepLink } from '../../../Common/services/slices';
import {
  ENV_KEYS,
  environment,
  isAppStoreBuild,
  isDevelopmentEnv,
  isProductionEnv,
} from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import store from '../../store';

// According to the docs, this needs to be done before the SDK is started
const onDeepLinkCanceller = appsFlyer.onDeepLink(res => {
  if (res?.deepLinkStatus !== 'NOT_FOUND' && res?.status !== 'failure') {
    store.dispatch(registerDeepLink(res));
  }
});

export class AppsFlyerTracker {
  private static instance: AppsFlyerTracker;

  private constructor(private readonly isAppStoreProductionBuild = false) {}

  static getInstance(): AppsFlyerTracker {
    if (!AppsFlyerTracker.instance) {
      AppsFlyerTracker.instance = new AppsFlyerTracker(
        isProductionEnv() && isAppStoreBuild(),
      );
    }
    return AppsFlyerTracker.instance;
  }

  initSdk(): Promise<boolean> {
    return new Promise(resolve => {
      const { APPSFLYER_APPID, APPSFLYER_DEV_KEY } = environment.getValues([
        ENV_KEYS.APPSFLYER_APPID,
        ENV_KEYS.APPSFLYER_DEV_KEY,
      ]);

      appsFlyer.initSdk(
        {
          devKey: APPSFLYER_DEV_KEY,
          isDebug: !this.isAppStoreProductionBuild,
          appId: APPSFLYER_APPID,
          onInstallConversionDataListener: true,
          onDeepLinkListener: true,
          timeToWaitForATTUserAuthorization: 10,
          manualStart: true,
        },
        result => {
          resolve(true);
          console.log('AppsFlyer SDK - Initialized', result);
        },
        e => {
          const error = e ?? new Error('AppsFlyer SDK initialization failed');
          Sentry.captureException(error, {
            tags: { tracker: 'AppsFlyer', activity: 'initSdk' },
          });

          console.error(error);
          resolve(false);
        },
      );
    });
  }

  startSdk(): void {
    appsFlyer.stop(false, () => {
      appsFlyer.startSdk();
    });
  }

  stop(): void {
    appsFlyer.stop(true);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  log(event: string, params: any): void {
    if (!this.isAppStoreProductionBuild) {
      return;
    }

    let selectedEventName = event;
    if (event === Analytics.coursePurchased) {
      selectedEventName = Analytics.inAppPurchase;
    }

    let managedEvent = this.isAppStoreProductionBuild
      ? selectedEventName
      : 'test_' + selectedEventName;

    if (isDevelopmentEnv()) {
      managedEvent = 'dev_' + managedEvent;
    }

    let managedParams = params;
    if (
      !this.isAppStoreProductionBuild &&
      event === Analytics.coursePurchased
    ) {
      managedParams = {
        ...params,
        dev_af_revenue: params.af_revenue,
        dev_af_currency: params.af_currency,
        course_id: params.courseId,
        course_name: params.courseName,
        af_revenue: undefined,
        af_currency: undefined,
      };
    }

    appsFlyer.logEvent(managedEvent, managedParams).catch(e => {
      Sentry.captureException(e, {
        extra: { eventKey: managedEvent, eventDetails: managedParams },
        tags: {
          tracker: 'AppsFlyer',
          activity: 'logEvent',
          event: managedEvent,
        },
      });
    });
  }
}

export const getAppsFlyerTracker = (): AppsFlyerTracker =>
  AppsFlyerTracker.getInstance();
