import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetHistoricalFigureRequest,
  GetHistoricalFigureResponse,
} from '../../entities';

export const getHistoricalFiguresGraphQLCall = async (
  request: GetHistoricalFigureRequest,
  signal?: AbortSignal,
): Promise<GetHistoricalFigureResponse[]> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: {
          name: request.name || undefined,
          facet: request.facet || undefined,
        },
      },
      query: `
        query GetHistoricalFigures($input: GetHistoricalFiguresInput!) {
          getHistoricalFigures(input: $input) {
            name
            imageUrl
            thumbnailUrl
            platformId
            subjectsITeach {
              id
              image
              name
            }
            properties
          }
        }`,
    },
    {
      signal,
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  const currentData = response.data.data?.getHistoricalFigures || [];
  if (currentData?.length) {
    currentData.forEach((figure: any) => {
      if (figure.properties) {
        figure.properties = JSON.parse(figure.properties);
      } else {
        figure.properties = {};
      }
    });
  }

  return currentData;
};
