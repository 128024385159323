import { Colors } from '../../../Common/services/utils/AppConstants';

import { mapGradeToNumberIndex } from './LearnCourseProgressUtil';

export const getKeyForLearningContentItems = (
  feedId: string,
  unitId?: string,
  topicId?: string,
  levelIds?: string[],
): string => {
  return `${feedId}_${unitId}_${topicId}_${levelIds?.join('_')}`;
};

export const getGradeStyle = (
  grade?: string,
): { backgroundColor: string; color: string } => {
  const numericGrade = mapGradeToNumberIndex(grade ?? '');
  if (numericGrade <= 1) {
    return { backgroundColor: Colors.white31, color: 'transparent' };
  } else if (numericGrade === 2) {
    return {
      backgroundColor: Colors.sliceLightGreen30,
      color: Colors.sliceLightGreen,
    };
  } else if (numericGrade === 3) {
    return { backgroundColor: Colors.sliceGreen30, color: Colors.sliceGreen };
  } else {
    return { backgroundColor: Colors.emeraldGreen, color: 'white' };
  }
};
