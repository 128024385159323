import Container from './container';

export type Props = {
  text: string;
  style?: any;
  textStyle?: any;
  width: number | undefined | null;
  color: string;
  outlineColor?: string;
  onButtonPress?: () => void;
  opacity?: number;
};

export const BLINK_BUTTON_TAG = 'blink_button';
export const WRONG_TEXT_TAG = 'wrong_text';
export const CORRECT_TEXT_TAG = 'correct_text';
export const PENDING_TEXT_TAG = 'pending_text';

export default Container;
