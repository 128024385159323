import React from 'react';
import { Image, StyleSheet } from 'react-native';

type Props = {
  imageUrl: string;
  isVisible: boolean;
  style: any;
};

export default (props: Props): React.ReactElement => {
  return (
    <Image
      source={{
        uri: props.imageUrl,
      }}
      resizeMode={'cover'}
      style={[styles.box, props.style]}
      blurRadius={10}
    />
  );
};

const styles = StyleSheet.create({
  box: {
    opacity: 0.8,
    position: 'absolute',
  },
});
