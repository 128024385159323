import React from 'react';
import { StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import FastImage from 'react-native-fast-image';

import { useAiFigureCharacterNavigator } from '../../../AiFigure/services/hooks';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
} from '../../services/utils/AppConstants';

type Props = {
  avatarUrl: string;
  name: string;
  description: string;
};

export default (props: Props): React.ReactElement => {
  const handleCharacterPress = useAiFigureCharacterNavigator();

  return (
    <TouchableWithoutFeedback
      style={styles.container}
      onPress={() => handleCharacterPress(props.name)}>
      <View style={styles.characterContainer}>
        <FastImage source={{ uri: props.avatarUrl }} style={styles.avatar} />
        <View style={styles.descriptionTextContainer}>
          <Text style={styles.speakerHeading}>{props.name}</Text>
          <Text style={styles.speakerTitle}>{props.description}</Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  characterContainer: {
    flexDirection: 'row',
    backgroundColor: Colors.white29,
    borderRadius: 100,
    alignItems: 'center',
    padding: 4,
    alignSelf: 'flex-start',
  },
  speakerHeading: {
    ...Fonts.caption,
    lineHeight: isAndroidPlatform ? 15 : undefined,
  },
  speakerTitle: {
    ...Fonts.small,
    color: 'rgba(255,255,255,0.8)',
    lineHeight: isAndroidPlatform ? 15 : undefined,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  descriptionTextContainer: {
    marginStart: 6,
    marginEnd: 9,
  },
});
