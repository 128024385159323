import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { getProducts, Product } from 'react-native-iap';

import { PURCHASABLE_ITEMS } from '../../utils/PaywallUtils';
import { PURCHASE_SLICE_NAME, PurchasesSliceState } from '../PurchasesSlice';

export const fetchProductsData = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/fetchProductsData`,
  async (_, thunkApi) => {
    try {
      return await getProducts({
        skus: Object.values(PURCHASABLE_ITEMS).map(item => item.sku),
      });
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

export const fetchProductsDataExtraReducers = (
  builder: ActionReducerMapBuilder<PurchasesSliceState>,
): void => {
  builder.addCase(fetchProductsData.fulfilled, (state, action) => {
    const products = action.payload;
    state.productsData = products.reduce((acc, product) => {
      acc[product.productId] = product;
      return acc;
    }, {} as Record<string, Product>);
  });
};
