import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import {
  ScreenNames,
  TabNames,
} from '../../../Common/services/utils/AppConstants';
import {
  RedirectToScreensFromLearnParams,
  RedirectToTopicCompletionFromLearnParams,
} from '../../entities';

export const redirectToMilestoneAchievedFromLearn = (
  params: RedirectToScreensFromLearnParams,
): void => {
  const { course, unit, topic } = params;
  if (!RootNavigatorRef.current?.isReady()) {
    return;
  }

  if (!course?.id || !unit?.id || !topic?.id) {
    return;
  }

  RootNavigatorRef.current?.reset({
    index: 1,
    routes: [
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.STUDY_FEED_SCREEN,
          },
        },
      },
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.MILESTONE_ACHIEVED_SCREEN,
            params: {
              course,
              unit,
              topic,
              isFromPracticeTest: false,
            },
          },
        },
      },
    ],
  });
};

export const redirectToTopicCompletionFromLearn = (
  params: RedirectToTopicCompletionFromLearnParams,
): void => {
  const { course, unit, topic, hasMasteryRegained } = params;
  if (!RootNavigatorRef.current?.isReady()) {
    return;
  }

  if (!course?.id || !unit?.id || !topic?.id) {
    return;
  }

  RootNavigatorRef.current?.reset({
    index: 1,
    routes: [
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.STUDY_FEED_SCREEN,
          },
        },
      },
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.TOPIC_COMPLETION_SCREEN,
            params: {
              course,
              unit,
              topic,
              hasMasteryRegained,
            },
          },
        },
      },
    ],
  });
};

export const redirectToTopicMasterDropFromLearn = (
  params: RedirectToScreensFromLearnParams,
): void => {
  const { course, unit, topic } = params;
  if (!RootNavigatorRef.current?.isReady()) {
    return;
  }

  if (!course?.id || !unit?.id || !topic?.id) {
    return;
  }

  RootNavigatorRef.current?.reset({
    index: 1,
    routes: [
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.STUDY_FEED_SCREEN,
          },
        },
      },
      {
        name: ScreenNames.MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: ScreenNames.StudyStack.TOPIC_MASTERY_DROP_SCREEN,
            params: {
              course,
              unit,
              topic,
              previousGrade: topic?.grade,
            },
          },
        },
      },
    ],
  });
};
