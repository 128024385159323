import { RootState } from '../../../App/store';
import {
  CommonTeachTapCourseStatus,
  Course,
  Topic,
  Unit,
} from '../../../Common/entities';
import { isSATCourseGrade } from '../../../Common/services/utils/CourseUtil';
import {
  CourseResponse,
  MapMaxGradeForCourseTopicParams,
  SAT_UNIT_CATEGORY,
  SubjectResponse,
  TopicResponse,
  UnitResponse,
} from '../../entities';
import { CoursesSliceState } from '../slices';

export const mapTopicResponseToTopic = (
  topicResponse: TopicResponse,
): Topic => {
  return {
    id: topicResponse.id,
    name: topicResponse.name,
    learningOrder: topicResponse.learningOrder,
    progress: 0,
    progressIndicator: 0,
    estimatedTimeToCompletion: {
      min: topicResponse.estimatedTimeToCompletion.min,
      max: topicResponse.estimatedTimeToCompletion.max,
    },
  };
};

export const mapUnitResponseToUnit = (
  unitResponse: UnitResponse,
  index?: number,
): Unit => {
  return {
    name: unitResponse.name,
    number: index ? index + 1 : 1,
    id: unitResponse.id,
    isAvailable: unitResponse.status === CommonTeachTapCourseStatus.ENABLED,
    topics: unitResponse.topics
      .map(mapTopicResponseToTopic)
      .sort((a, b) => a.learningOrder - b.learningOrder),
    learningOrder: unitResponse.learningOrder,
    hasTestPrep: unitResponse.hasTestPrep,
    hasLearnMode: unitResponse.hasLearnMode,
    category: unitResponse.category,
  };
};

export const mapNewCourseWithOldCourseWhileRetainingProgress = (
  newCourse: Course,
  oldCourse?: Course,
): Course => {
  if (oldCourse?.id !== newCourse.id) {
    return newCourse;
  }

  const units = newCourse.units.map(newUnit => {
    const oldUnit = oldCourse.units.find(oldUnit => oldUnit.id === newUnit.id);

    const topics = newUnit.topics.map(newTopic => {
      const oldTopic = oldUnit?.topics.find(
        oldTopic => oldTopic.id === newTopic.id,
      );

      return {
        ...newTopic,
        grade: oldTopic?.grade || undefined,
        progress: oldTopic?.progress || 0,
        progressIndicator: 0,
      };
    });

    return {
      ...newUnit,
      grade: oldUnit?.grade || undefined,
      topics,
    };
  });

  return {
    ...newCourse,
    units,
  };
};

export const mapUnitsFromResponse = (
  units: UnitResponse[],
  isSATCourse: boolean,
): Unit[] => {
  const result = units
    .map((unit, index) => mapUnitResponseToUnit(unit, index))
    .sort((a, b) => a.learningOrder - b.learningOrder)
    .filter(unit => unit.isAvailable);

  if (!isSATCourse) {
    return result;
  }

  const readWriteUnits = result
    .filter(unit => unit.category === SAT_UNIT_CATEGORY.READING)
    .sort((a, b) => a.number - b.number);
  const mathUnits = result
    .filter(unit => unit.category === SAT_UNIT_CATEGORY.MATH)
    .sort((a, b) => a.number - b.number);

  return [...readWriteUnits, ...mathUnits].map((unit, index) => ({
    ...unit,
    number: index + 1,
  }));
};

export const mapCourseResponseToCourse = (
  courseResponse: CourseResponse,
  subjectResponse: SubjectResponse,
): Course => {
  const units = mapUnitsFromResponse(
    courseResponse.units,
    isSATCourseGrade(courseResponse.grade?.grade),
  );

  return {
    name: courseResponse.name,
    units,
    username: courseResponse.name.toLowerCase().replace(' ', ''),
    avatar: courseResponse.thumbnailUrl,
    id: courseResponse.id,
    subject: subjectResponse.name,
    subjectId: subjectResponse.id,
    available: true,
    background: courseResponse.mainImageUrl,
    grade: courseResponse.grade.grade,
    hasTestPrep: courseResponse.hasTestPrep,
    hasLearnMode: courseResponse.hasLearnMode,
  };
};

export const mapSATCourseData = (state: RootState): Course | undefined => {
  return Object.values(state.courses.coursesMap).find(course =>
    isSATCourseGrade(course.grade),
  );
};

export const mapCourseDataById = (
  state: RootState,
  courseId?: string,
): Course | undefined => {
  if (!courseId) {
    return undefined;
  }

  return state.courses.coursesMap[courseId];
};

export const mapMaxGradeForCourseTopic = (
  state: CoursesSliceState,
  params: MapMaxGradeForCourseTopicParams,
): string | undefined => {
  const { courseId, topicId } = params;

  if (!courseId || !topicId) {
    return undefined;
  }

  return state.maxCourseTopicMasteryGrade?.[courseId]?.[topicId];
};
