import React from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';

import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { Testimonial } from '../entities';

const SCREEN_WIDTH = Dimensions.get('window').width;

const CAROUSEL_ITEM_WIDTH = SCREEN_WIDTH - 70;

export type CommonTestimonialCarouselItemProps = {
  item: Testimonial;
};

export const CommonTestimonialCarouselItem = ({
  item,
}: CommonTestimonialCarouselItemProps): React.ReactElement => {
  return (
    <View style={styles.container}>
      <Text style={styles.quote}>"{item.quote}"</Text>
      <Text style={styles.name}>{item.name}</Text>
    </View>
  );
};

export type RenderTestimonialCarouselItemProps = {
  item: Testimonial;
};

export const renderCommonTestimonialCarouselItem = ({
  item,
}: RenderTestimonialCarouselItemProps): React.ReactElement => {
  return <CommonTestimonialCarouselItem item={item} />;
};

const styles = StyleSheet.create({
  container: {
    width: CAROUSEL_ITEM_WIDTH - Sizes.normal,
    backgroundColor: Colors.white10,
    borderRadius: Sizes.semiMedium,
    padding: Sizes.semiMedium,

    marginHorizontal: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  quote: {
    ...Fonts.small,
    color: Colors.white60,
    textAlign: 'center',
  },
  name: {
    ...Fonts.caption,
    fontWeight: 'bold',
    marginTop: Sizes.small,
  },
});
