import {
  CardType,
  ContentCard,
  ControversialConversationCard,
  DidYouKnowCard,
  FillGapsWithHelpCard,
  MatchingPairsCard,
  MultipleChoiceQuestionV2,
  TruthOrLieCard,
} from '../../../Common/entities';

export const getSubtitleUrlsFromContentCard = (
  contentCard: ContentCard,
): string[] | undefined => {
  if (contentCard.type === CardType.MATCHING_PAIRS_CARD) {
    const characters =
      (contentCard as MatchingPairsCard)?.charactersFromLeftOptions || {};

    return Object.keys(characters)
      .map(key => characters[key].subtitleUrl)
      .filter(Boolean) as string[];
  }

  let subtitleUrl: string | undefined;

  switch (contentCard.type) {
    case CardType.MCQ_CARD:
      subtitleUrl = (contentCard as MultipleChoiceQuestionV2)?.character
        ?.subtitleUrl;
      break;
    case CardType.FILL_IN_THE_BLANK_CARD:
      subtitleUrl = (contentCard as FillGapsWithHelpCard)?.character
        ?.subtitleUrl;
      break;
    case CardType.CONTROVERSIAL_CONVERSATION_CARD:
      subtitleUrl = (contentCard as ControversialConversationCard)?.subtitleUrl;
      break;
    case CardType.DID_YOU_KNOW_CARD:
      subtitleUrl = (contentCard as DidYouKnowCard)?.subtitleUrl;
      break;
    case CardType.TRUTH_OR_LIE_CARD:
      subtitleUrl = (contentCard as TruthOrLieCard)?.character?.subtitleUrl;
      break;
    default:
      break;
  }

  return subtitleUrl ? [subtitleUrl] : undefined;
};
