import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { OnboardingAssets } from '../../assets';
import { TUTOR_BUBBLES } from '../../data';

export const SatBootcampTutorial3TutorBubbles = (): React.ReactElement => {
  return (
    <View style={styles.container}>
      {TUTOR_BUBBLES.map(bubble => (
        <View key={bubble.id} style={[styles.bubble, bubble.style]}>
          <AssetImage
            asset={bubble.asset}
            imageStyle={{
              height: bubble.size,
              width: bubble.size,
            }}
          />
        </View>
      ))}

      <View style={styles.aiTutorContainer}>
        <Text style={styles.aiTutorText}>{locale.tutorial.ai_tutor}</Text>
        <AssetImage
          asset={OnboardingAssets.CurvedArrowLeftIcon}
          height={54}
          containerStyle={styles.arrowContainer}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  bubble: {
    position: 'absolute',
    borderRadius: Sizes.normalizeIP14PM(50),
    overflow: 'hidden',
  },
  aiTutorContainer: {
    position: 'absolute',
    right: Sizes.normalizeIP14PM(48),
    top: Sizes.normalizeIP14PM(250),
    transform: [{ rotate: '17deg' }],
  },
  aiTutorText: {
    ...Fonts.xlargeGochiHand,
    color: Colors.actionGreen,
  },
  arrowContainer: {
    left: Sizes.normalizeIP14PM(-25),
    transform: [{ rotate: '-10deg' }],
  },
});
