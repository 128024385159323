import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { retryUntil } from '../../../../Common/services/utils';
import { unenrollUserFromCourse } from '../../../../CourseEnrollment/services/slices';
import { getSATCourse } from '../../../../Learn/services/utils';
import { SAT_SLICE_NAME } from '../SATSlice';

import { getUserSATProgramDetails } from './GetUserSATProgramDetailsAction';

export const unenrollSATCourse = createAsyncThunk(
  `${SAT_SLICE_NAME}/unenrollSATCourse`,
  async (_state, thunkApi) => {
    // make sure program details are updated here
    const bootcamp = await retryUntil(
      async () => await thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
      result => Boolean(result),
      3,
      600,
      undefined,
    );
    const hasSATPrepAddon = (thunkApi.getState() as RootState).userAccess
      .hasSATPrepAccess;
    const rootState = thunkApi.getState() as RootState;
    if (!bootcamp && !hasSATPrepAddon) {
      const course = getSATCourse();
      const satCourse = rootState.courseEnrollments.following.find(
        c => c.id === course?.id,
      );
      if (course && satCourse) {
        thunkApi.dispatch(unenrollUserFromCourse(course));
      }
    }
  },
);
