import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

import { environment, ENV_KEYS } from '../../../Common/services/utils';

export class GraphQLApi {
  private static instance: GraphQLApi;
  private apiInstance: AxiosInstance;

  private constructor() {
    this.apiInstance = this.createApiInstance();
  }

  public static getInstance(): GraphQLApi {
    if (!GraphQLApi.instance) {
      GraphQLApi.instance = new GraphQLApi();
    }
    return GraphQLApi.instance;
  }

  private createApiInstance(): AxiosInstance {
    return axios.create({
      baseURL: environment.getValue(ENV_KEYS.GRAPHQL_ENDPOINT),
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': environment.getValue(ENV_KEYS.API_KEY),
      },
    });
  }

  public async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    const api = this.apiInstance;
    return api.post(url, data, config);
  }

  public resetAPI(): void {
    const { GRAPHQL_ENDPOINT, API_KEY } = environment.getValues([
      ENV_KEYS.GRAPHQL_ENDPOINT,
      ENV_KEYS.API_KEY,
    ]);
    const api = this.apiInstance;
    api.defaults.baseURL = GRAPHQL_ENDPOINT;
    api.defaults.headers['x-api-key'] = API_KEY;
  }

  public setCustomBaseUrl(url: string): void {
    const api = this.apiInstance;
    api.defaults.baseURL = url;
  }

  public setCustomAPIKey(apiKey: string): void {
    const api = this.apiInstance;
    api.defaults.headers['x-api-key'] = apiKey;
  }
}
