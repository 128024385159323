export type TrackUserEventRequest = {
  eventType: string;
  attributes: { name: string; value: string }[];
};

export enum TrackedUserEventType {
  CourseActivity = 'COURSE_ACTIVITY',
  SessionInitiated = 'SESSION_INITIATED',
  FrqTestInteracted = 'FRQ_TEST_INTERACTED',
}

export type TrackUserEventAction = {
  eventType: TrackedUserEventType;
  courseId?: string;
  unitId?: string;
};
