import React, { useMemo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import Assets from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import { setContentMuted } from '../../../Learn/services/slices';

type Props = {
  style?: any;
  iconSize: number;
  isFromTestPrep?: boolean;
  handleMutePressed?: () => void;
  isTutorVideoMuted?: boolean;
};

export default (props: Props): React.ReactElement => {
  const dispatch = useAppDispatch();

  const isMediaMuted = useAppSelector(state => state.questions.contentMuted);

  const onPress = () => {
    if (props.isFromTestPrep) {
      props.handleMutePressed && props.handleMutePressed();
    } else {
      dispatch(setContentMuted(!isMediaMuted));
    }
  };

  const getMediaStatus: boolean = useMemo(() => {
    return props.isFromTestPrep
      ? props.isTutorVideoMuted ?? false
      : isMediaMuted;
  }, [isMediaMuted, props.isFromTestPrep, props.isTutorVideoMuted]);

  return (
    <TouchableOpacity style={[styles.container, props.style]} onPress={onPress}>
      <AssetImage
        asset={
          getMediaStatus
            ? Assets.home.volumeOffButtonIcon
            : Assets.home.volumeOnButtonIcon
        }
        height={props.iconSize}
        fill={'white'}
        imageStyle={styles.icon}
      />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    shadowColor: 'rgba(0, 0, 0, 1)',
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
});
