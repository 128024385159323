import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useStateWithRef } from './StateWithRef';

type TimedToggleProps = {
  toggleDuration: number;
};

type TimedToggleControls = {
  ref: React.MutableRefObject<boolean>;
  cancel: () => void;
  trigger: () => void;
};

type TimedToggleReturn = [
  boolean,
  React.Dispatch<boolean>,
  TimedToggleControls,
];

export const useTimedToggle = (props: TimedToggleProps): TimedToggleReturn => {
  const [toggle, setToggle, toggleRef] = useStateWithRef(false);
  const timeoutRef = useRef<unknown>(null);

  const cancelTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current as number);
  }, []);

  const triggerTimeout = useCallback(() => {
    cancelTimeout();
    setToggle(false);

    timeoutRef.current = setTimeout(() => {
      setToggle(true);
    }, props.toggleDuration);
  }, [cancelTimeout, setToggle, props.toggleDuration]);

  const controls = useMemo(() => {
    return {
      ref: toggleRef,
      cancel: cancelTimeout,
      trigger: triggerTimeout,
    };
  }, [cancelTimeout, triggerTimeout, toggleRef]);

  useEffect(() => {
    triggerTimeout();

    return () => {
      cancelTimeout();
    };
  }, [triggerTimeout, cancelTimeout]);

  return [toggle, setToggle, controls];
};
