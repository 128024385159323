import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { getSATCourse } from '../../../../Learn/services/utils';
import { mapSATUserProgramDetailsAvailableStatus } from '../../mappers';
import { SAT_SLICE_NAME, setSatTimeSpentSeconds } from '../SATSlice';

export const incrementSATTestPrepTimeSpent = createAsyncThunk(
  `${SAT_SLICE_NAME}/incrementSATTestPrepTimeSpent`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const satState = rootState.SAT;

    const isProgramAvailable =
      mapSATUserProgramDetailsAvailableStatus(rootState);
    const satCourse = getSATCourse();

    if (!satCourse?.id || !isProgramAvailable) {
      return;
    }

    thunkAPI.dispatch(setSatTimeSpentSeconds(satState.satTimeSpentSeconds + 1));
  },
);
