import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';

import { Colors, Fonts, Sizes } from '../services/utils/AppConstants';

type Props = {
  count: number;
  showBadge?: boolean;
  containerStyle?: ViewStyle;
};

export const CommonBadge = (props: Props): React.ReactElement | null => {
  if (!props.showBadge) {
    return null;
  }

  return (
    <View style={[styles.badgeContainer, props.containerStyle]}>
      <Text style={styles.badgeText}>{props.count}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  badgeText: {
    ...Fonts.xxsmallBold,
    textAlign: 'center',
  },
  badgeContainer: {
    position: 'absolute',
    backgroundColor: Colors.brightRed,
    borderRadius: 8,
    height: Sizes.semiMedium,
    minWidth: Sizes.semiMedium,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 20,
  },
});
