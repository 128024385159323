import React, { useEffect, useRef, useState } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import Animated, {
  interpolateColor,
  useDerivedValue,
  withTiming,
  useAnimatedStyle,
} from 'react-native-reanimated';

import Assets from '../../../../App/assets';
import { AssetImage } from '../../../../App/assets/AssetImage';
import locale from '../../../../App/locale';
import {
  Colors,
  Durations,
} from '../../../../Common/services/utils/AppConstants';

import styles from './styles';

type Props = {
  onHintPress: () => void;
  blinkHighlight?: boolean;
};

export default ({ onHintPress, blinkHighlight }: Props): React.ReactElement => {
  const [blink, setBlink] = useState<boolean>(false);
  const blinkRef = useRef<unknown>(null);

  const progress = useDerivedValue(() => {
    return withTiming(blink ? 1 : 0);
  });

  const reanimatedStyle = useAnimatedStyle(() => {
    return {
      backgroundColor: interpolateColor(
        progress.value,
        [0, 1],
        [Colors.lightBlue, Colors.greyBlinkingHighlight],
      ),
    };
  });

  useEffect(() => {
    if (blinkHighlight) {
      blinkRef.current = setInterval(() => {
        setBlink(prev => !prev);
      }, Durations.blinkInterval);
    } else {
      clearInterval(blinkRef.current as number);
      setBlink(false);
    }

    return () => {
      clearInterval(blinkRef.current as number);
    };
  }, [blinkHighlight]);

  return (
    <TouchableOpacity onPress={onHintPress} style={styles.container}>
      <Animated.View style={[styles.hintContainer, reanimatedStyle]}>
        <AssetImage asset={Assets.home.lightbulbIcon} width={14} fill="white" />
        <Text style={styles.hintText}>{locale.home_screen_card.fill_hint}</Text>
      </Animated.View>
    </TouchableOpacity>
  );
};
