import React from 'react';
import {
  Image,
  ImageResizeMode,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

type Props = {
  source: ImageSourcePropType;
  resizeMode?: ImageResizeMode;
  style?: ImageStyle;
  containerStyle?: ViewStyle;
};

export const CommonGifAnimation = React.memo((props: Props) => {
  return (
    <View style={[styles.gifContainer, props.containerStyle]}>
      <Image
        style={[styles.gif, props.style]}
        resizeMode={props.resizeMode ?? 'cover'}
        source={props.source}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  gifContainer: {},
  gif: {
    width: '100%',
    height: '100%',
  },
});
