import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { isAPCourse } from '../../../../Common/services/utils';
import { getCourseDataById } from '../../../../Learn/services/utils';
import { AP_SLICE_NAME } from '../APSlice';

import { finalizeApLearnModeTimeSpent } from './FinalizeApLearnModeTimeSpentAction';

export const finalizeApLearnModeCoursesTimeSpent = createAsyncThunk(
  `${AP_SLICE_NAME}/finalizeApLearnModeCoursesTimeSpent`,
  async (_, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const coursesTimeSpent = rootState.AP.learnModeTimeSpentSeconds;

    Object.keys(coursesTimeSpent).forEach(courseId => {
      const courseData = getCourseDataById(courseId);

      if (isAPCourse(courseData)) {
        thunkAPI.dispatch(finalizeApLearnModeTimeSpent(courseId));
      }
    });
  },
);
