import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  SubmitApAcademicDataRequest,
  SubmitApAcademicDataResponse,
} from '../../entities';

export const submitApAcademicDataGraphQLCall = async (
  request: SubmitApAcademicDataRequest,
): Promise<SubmitApAcademicDataResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      variables: {
        input: request,
      },
      query: `
        mutation TeachTapSubmitAPAcademicData($input: TeachTapSubmitAPAcademicDataInput!) {
          teachTapSubmitAPAcademicData(input: $input) {
            success
          }
        }`,
    },
    {
      headers: authHeader,
    },
  );

  handleGraphQLError(response);
  return response.data.data?.teachTapSubmitAPAcademicData;
};
