import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

import {
  NetworkError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import {
  StartStreamingRequestIdPayload,
  StopStreamingRequestIdPayload,
  StreamGeneratedContentInput,
  StreamingMessageDetails,
  Subscription,
  UpdateAiStreamedMessagePayload,
} from '../../../Messages/entities';
import { subscribeToGeneratedContentChunk } from '../../../Messages/graphql/subscriptions/MessagesGraphQLSubscription';
import {
  MAX_WAIT_FOR_CHUNK_TIME,
  MIN_TIME_BETWEEN_CHUNKS,
  checkForNewMessages,
  handleTimeout,
  throttle,
} from '../utils';

import { getChatHistory } from './RaiseHandSlice';

const PERSIST_KEY = 'streaming';
const SLICE_NAME = 'StreamingSlice';

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'aiStreamedMessages',
    'activeRequests',
    'requestIdToGeneratedContentId',
  ],
};

type StreamingState = {
  aiStreamedMessages: Record<string, StreamingMessageDetails>;
  activeRequests: string[];
  requestIdToGeneratedContentId: Record<string, string>;
};

const initialState: StreamingState = {
  aiStreamedMessages: {},
  activeRequests: [],
  requestIdToGeneratedContentId: {},
};

let subscription: Subscription | null = null;

export const handleResponseStreaming = createAsyncThunk(
  `${SLICE_NAME}/handleResponseStreaming`,
  async (_, thunkApi): Promise<void> => {
    const state = thunkApi.getState() as RootState;
    if (!subscription && state.streaming.activeRequests.length > 0) {
      const observable = await subscribeToGeneratedContentChunk();
      const throttledFunction = throttle(
        (action: UpdateAiStreamedMessagePayload) => {
          thunkApi.dispatch(updateAiStreamedMessage(action));
        },
        MIN_TIME_BETWEEN_CHUNKS,
      );
      const reloadChatOnTimeout = handleTimeout(async (requestId: string) => {
        const state = thunkApi.getState() as RootState;
        if (!state.streaming.activeRequests.includes(requestId)) {
          return;
        }
        const generatedContentId =
          state.streaming.requestIdToGeneratedContentId[requestId];
        const streamedMessage =
          state.streaming.aiStreamedMessages[generatedContentId];

        try {
          const hasNewMessages = await checkForNewMessages(
            streamedMessage,
            async page => {
              return await thunkApi
                .dispatch(
                  getChatHistory({
                    speaker: streamedMessage.speaker,
                    page,
                    generatedContentId: generatedContentId,
                  }),
                )
                .unwrap();
            },
          );

          if (hasNewMessages) {
            thunkApi.dispatch(stopStreamingRequestId({ requestId }));
          } else {
            reloadChatOnTimeout(requestId);
          }
        } catch (error) {
          handleNetworkActionErrorSilently(error as NetworkError);
          reloadChatOnTimeout(requestId);
        }
      }, MAX_WAIT_FOR_CHUNK_TIME);
      state.streaming.activeRequests.forEach(requestId => {
        reloadChatOnTimeout(requestId);
      });
      subscription = observable.subscribe({
        next: data => {
          throttledFunction(data.requestId, {
            message: data.contentSoFar,
            requestId: data.requestId,
          });
          reloadChatOnTimeout(data.requestId);
        },
        error: async err => {
          handleNetworkActionErrorSilently(err);
          const netInfo = await NetInfo.refresh();
          // If we are connected to the internet, we stop all streaming requests
          if (netInfo.isConnected && netInfo.isInternetReachable) {
            const state = thunkApi.getState() as RootState;
            state.streaming.activeRequests.forEach(requestId => {
              thunkApi.dispatch(stopStreamingRequestId({ requestId }));
            });
          } else {
            // If we are not connected to the internet, we wait for internet connection
            // and then we re-subscribe to the streaming
            thunkApi.dispatch(handleStreamingOnReconnect());
          }
        },
      });
    }
  },
);

export const handleStreamingOnReconnect = createAsyncThunk(
  `${SLICE_NAME}/handleStreamingOnReconnect`,
  async (_, thunkApi): Promise<void> => {
    subscription?.unsubscribe();
    subscription = null;
    const unsubscribe = NetInfo.addEventListener(state => {
      if (state.isConnected) {
        thunkApi.dispatch(handleResponseStreaming());
        unsubscribe?.();
      }
    });
  },
);

export const streamGeneratedContent = createAsyncThunk(
  `${SLICE_NAME}/streamGeneratedContent`,
  async (
    { generatedContentId, requestId, speaker }: StreamGeneratedContentInput,
    thunkApi,
  ): Promise<void> => {
    try {
      const state = thunkApi.getState() as RootState;
      if (state.streaming.activeRequests.includes(requestId)) {
        return;
      }

      thunkApi.dispatch(
        startStreamingRequestId({ generatedContentId, requestId, speaker }),
      );

      await thunkApi.dispatch(handleResponseStreaming());
    } catch (error) {
      handleNetworkActionErrorSilently(error as NetworkError);
      thunkApi.dispatch(stopStreamingRequestId({ requestId }));
    }
  },
);

const streamingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    startStreamingRequestId: (
      state: StreamingState,
      action: PayloadAction<StartStreamingRequestIdPayload>,
    ) => {
      const { requestId, generatedContentId, speaker } = action.payload;
      state.activeRequests.push(requestId);
      state.requestIdToGeneratedContentId[requestId] = generatedContentId;
      state.aiStreamedMessages[generatedContentId] = {
        message: '',
        streaming: true,
        requestId,
        speaker,
        createdAt: new Date().toISOString(),
      };
    },
    stopStreamingRequestId: (
      state: StreamingState,
      action: PayloadAction<StopStreamingRequestIdPayload>,
    ) => {
      const { requestId } = action.payload;
      const generatedContentId = state.requestIdToGeneratedContentId[requestId];

      delete state.aiStreamedMessages[generatedContentId];
      delete state.requestIdToGeneratedContentId[requestId];

      state.activeRequests = state.activeRequests.filter(
        request => request !== requestId,
      );

      if (state.activeRequests.length === 0) {
        subscription?.unsubscribe();
        subscription = null;
      }
    },
    updateAiStreamedMessage: (
      state: StreamingState,
      action: PayloadAction<UpdateAiStreamedMessagePayload>,
    ) => {
      const { message, requestId } = action.payload;
      const generatedContentId = state.requestIdToGeneratedContentId[requestId];
      if (
        state.aiStreamedMessages[generatedContentId] &&
        state.aiStreamedMessages[generatedContentId].requestId === requestId &&
        state.activeRequests.includes(requestId)
      ) {
        state.aiStreamedMessages[generatedContentId].message = message;
      }
    },
  },
});

export const {
  startStreamingRequestId,
  stopStreamingRequestId,
  updateAiStreamedMessage,
} = streamingSlice.actions;

export const StreamingSlice = persistReducer(
  persistConfig,
  streamingSlice.reducer,
);
