import React from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { Unit } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

type Props = {
  unit: Unit;
  nextTestNumber: number;
  onUnitSelected: (unit: Unit) => void;
  disabled?: boolean;
};

export const TestPrepStartTestButton = ({
  unit,
  nextTestNumber,
  onUnitSelected,
  disabled,
}: Props): React.ReactElement => {
  return (
    <TouchableOpacity
      style={styles.playButton}
      disabled={disabled}
      onPress={() => onUnitSelected(unit)}>
      <AssetImage
        asset={Assets.home.playVideoButtonIcon}
        height={16}
        width={12}
        containerStyle={styles.playButtonIcon}
        fill="white"
      />
      <Text style={styles.playButtonText}>
        {locale.testPrep.test} {nextTestNumber}
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  playButton: {
    borderRadius: 6,
    height: 36,
    backgroundColor: Colors.white10,
    flexDirection: 'row',
    gap: 6,
    padding: Sizes.small,
    justifyContent: 'center',
    alignItems: 'center',
  },
  playButtonIcon: {
    padding: 2,
  },
  playButtonText: {
    ...Fonts.smallSemiBold,
  },
});

export type RenderTestPrepStartTestButtonProps = {
  unit: Unit;
  nextTestNumber: number;
  onUnitSelected: (unit: Unit) => void;
  disabled?: boolean;
};
export const renderTestPrepStartTestButton = ({
  unit,
  nextTestNumber,
  onUnitSelected,
  disabled,
}: RenderTestPrepStartTestButtonProps): React.ReactElement => {
  return (
    <TestPrepStartTestButton
      unit={unit}
      nextTestNumber={nextTestNumber}
      onUnitSelected={onUnitSelected}
      disabled={disabled}
    />
  );
};
