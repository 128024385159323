import { showMessage } from 'react-native-flash-message';

import { ShowFlashMessageParams } from '../../entities';

export const showErrorMessage = (params: ShowFlashMessageParams): void => {
  showMessage({
    message: params.message,
    description: params.description,
    duration: params.duration || 2000,
    autoHide: params.autoHide ?? true,
    type: 'danger',
  });
};

export const showSuccessMessage = (params: ShowFlashMessageParams): void => {
  showMessage({
    message: params.message,
    description: params.description,
    duration: params.duration || 2000,
    autoHide: params.autoHide ?? true,
    type: 'success',
  });
};
