import * as Sentry from '@sentry/react-native';

import {
  ENV_KEYS,
  environment,
  getAppEnvironment,
  isDevelopmentEnv,
} from '../../../Common/services/utils';

export const initSentry = (): void => {
  const { SENTRY_DSN } = environment.getValues([ENV_KEYS.SENTRY_DSN]);
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getAppEnvironment(),
    enabled: !isDevelopmentEnv(),
  });
};
