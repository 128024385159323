import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../../App/store';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { SelectApCourseTargetGradeActionParams } from '../../../entities';
import {
  AP_ONBOARDING_SLICE_NAME,
  ApOnboardingSliceState,
} from '../ApOnboardingSlice';

export const selectApCourseTargetGradeAction = createAsyncThunk(
  `${AP_ONBOARDING_SLICE_NAME}/selectApCourseTargetGradeAction`,
  async (params: SelectApCourseTargetGradeActionParams, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;

    trackAnalyticsEvent(Analytics.apTargetGradeSet, {
      course: params.courseName,
      targetGrade: params.grade,
      from: rootState.onboarding.bootcampEntryPoint,
    });
  },
);

export const selectApCourseTargetGradeActionExtraReducers = (
  builder: ActionReducerMapBuilder<ApOnboardingSliceState>,
): void => {
  builder.addCase(
    selectApCourseTargetGradeAction.fulfilled,
    (state, action) => {
      const { courseName, grade } = action.meta.arg;
      state.apCourseTargetGrade[courseName] = grade;
    },
  );
};
