import locale from '../../../App/locale';
import { CommonPeriodType } from '../../../Common/entities';
import {
  BootcampProgramNameDetails,
  CheckIfSATInputValidProps,
  GetSATTargetScoreErrorProps,
} from '../../entities';

export const checkIfSATInputValid = (
  props: CheckIfSATInputValidProps,
): boolean => {
  const { examLastScore, examTargetScore, examDate } = props;

  if (!examTargetScore || !examDate) {
    return false;
  }

  return Boolean(!getSATTargetScoreError({ examLastScore, examTargetScore }));
};

export const getSATTargetScoreError = (
  props: GetSATTargetScoreErrorProps,
): string => {
  const { examLastScore, examTargetScore } = props;

  if (!examLastScore || !examTargetScore) {
    return '';
  }

  const isMaxScoreError =
    examLastScore === 1600 && examLastScore !== examTargetScore;
  const isNotMaxScoreError =
    examLastScore < 1600 && examLastScore >= examTargetScore;

  if (isMaxScoreError) {
    return locale.onboarding.target_score_max_error;
  } else if (isNotMaxScoreError) {
    return locale.onboarding.target_score_error;
  }

  return '';
};

export const getSATTargetScoreForRender = (targetScore?: number): string => {
  if (!targetScore) {
    return 'N';
  }

  if (targetScore === 1600) {
    return '1600';
  }

  return `${targetScore}+`;
};

export const getSATBootcampProgramNameDetails = (
  bootcampName?: string,
): BootcampProgramNameDetails => {
  if (!bootcampName) {
    return {};
  }

  // Accepts:
  // * 10-Week SAT Bootcamp
  // * 10-Weeks SAT Bootcamp
  // * 10-Day SAT Bootcamp
  // * 10-Days SAT Bootcamp

  const pattern = /(\d+)-(Weeks?|Days?)/i; // The 's?' makes the 's' optional in 'Weeks' or 'Days'
  const matches = bootcampName.match(pattern);

  if (matches && matches.length >= 3) {
    const count = parseInt(matches[1], 10); // The first captured group is the number
    const timeUnit = matches[2]; // The second captured group is 'Week(s)' or 'Day(s)'

    const period = timeUnit.toLowerCase().includes('week')
      ? CommonPeriodType.WEEK
      : CommonPeriodType.DAY;

    return { count, period };
  } else {
    return {};
  }
};
