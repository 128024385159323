import React, { useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { openWebLink, trackAnalyticsEvent } from '../../Common/services/utils';
import {
  Analytics,
  Colors,
  Fonts,
} from '../../Common/services/utils/AppConstants';
import { getPaywallEntryPointFromBootcamp } from '../../CourseEnrollment/services/utils';

export const PaywallFooter = (): React.ReactElement => {
  const bootcampEntryPoint = useAppSelector(
    state => state.onboarding.bootcampEntryPoint,
    (prev, next) => prev === next,
  );

  const handleTermsAndConditionsPress = useCallback(() => {
    openWebLink(locale.auth_screen.terms_of_service_url);
  }, []);

  const handlePrivacyPolicyPress = useCallback(() => {
    openWebLink(locale.auth_screen.privacy_policy_url);
  }, []);

  const handleLearnMorePress = useCallback(() => {
    trackAnalyticsEvent(Analytics.moneyBackLearnMoreClicked, {
      from: getPaywallEntryPointFromBootcamp(bootcampEntryPoint),
    });

    openWebLink(locale.auth_screen.sat_money_back_guarantee_url);
  }, [bootcampEntryPoint]);

  return (
    <View style={styles.footer}>
      <Text style={styles.footerText}>
        {locale.bootcamp_paywall.footer_text}
        <Text style={styles.footerLink} onPress={handleTermsAndConditionsPress}>
          {locale.bootcamp_paywall.terms_conditions},&nbsp;
        </Text>
        <Text style={styles.footerLink} onPress={handlePrivacyPolicyPress}>
          {locale.bootcamp_paywall.privacy_policy}
        </Text>
        &nbsp;{locale.common.and}&nbsp;
        <Text style={styles.footerLink} onPress={handleLearnMorePress}>
          {locale.bootcamp_paywall.ap_money_back_guarantee}
        </Text>
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
  },
  footerText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },
  footerLink: {
    color: Colors.white60,
    textDecorationLine: 'underline',
  },
});
