import { useIsFocused } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Animated, StyleSheet, Text, View } from 'react-native';

import { CommonLinearProgress } from '../../../Common/components';
import { useRefWithSubscribe } from '../../../Common/services/hooks';
import { Fonts, Sizes } from '../../../Common/services/utils/AppConstants';
import { Easing } from '../../../Common/services/utils/polyfills';

type Props = {
  onComplete: () => void;
};

const PROGRESS_BAR_TARGET = 100;
const PROGRESS_BAR_DURATION = 5000;
const PROGRESS_BAR_INITIAL = 0;

const ProgressBar = (props: Props): React.ReactElement => {
  const progress = useMemo(() => new Animated.Value(PROGRESS_BAR_INITIAL), []);
  const [currentProgress, setCurrentProgress] = useState(PROGRESS_BAR_INITIAL);
  const isFocused = useIsFocused();
  const isFocusedRef = useRefWithSubscribe(isFocused);

  const handleStartAnimation = useCallback(() => {
    const animation = Animated.timing(progress, {
      toValue: PROGRESS_BAR_TARGET,
      duration: PROGRESS_BAR_DURATION,
      useNativeDriver: false,
    });

    progress.addListener(({ value }) => {
      setCurrentProgress(value);
    });

    animation.start(({ finished }) => {
      if (finished && isFocusedRef.current) {
        props.onComplete();
        progress.removeAllListeners();
      }
    });
  }, [isFocusedRef, progress, props]);

  useEffect(() => {
    if (isFocused) {
      handleStartAnimation();
    } else {
      progress.resetAnimation();
      setCurrentProgress(PROGRESS_BAR_INITIAL);
    }

    return () => {
      progress.removeAllListeners();
    };
  }, [handleStartAnimation, isFocused, progress]);

  return (
    <View style={styles.container}>
      <Text style={styles.progressText}>{Math.floor(currentProgress)}%</Text>
      <CommonLinearProgress
        backgroundStyle={styles.progressBar}
        progressPercentage={currentProgress}
        easing={Easing.out(Easing.quad)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: Sizes.small,
  },
  progressText: {
    ...Fonts.xxxlarge,
    lineHeight: 28,
    opacity: 0.6,
  },
  progressBar: {
    height: Sizes.medium,
    width: '100%',
  },
});

export default ProgressBar;
