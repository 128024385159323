import React from 'react';
import { StyleSheet, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Sizes } from '../../Common/services/utils/AppConstants';

const PLAY_BUTTON_SIZE = Sizes.normalizeIP14PM(20);

type Props = {
  isMediaPaused: boolean;
};

export const OnboardingIntroVideoControl = (
  props: Props,
): React.ReactElement | null => {
  if (props.isMediaPaused) {
    return (
      <View style={styles.playButtonContainer}>
        <AssetImage
          asset={Assets.home.playVideoButtonIcon}
          fill="white"
          height={PLAY_BUTTON_SIZE}
          containerStyle={styles.playButton}
        />
      </View>
    );
  }

  return null;
};

const styles = StyleSheet.create({
  playButtonContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: '45%',
    backgroundColor: 'rgba(0,0,0,0.5)',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  playButton: {
    alignSelf: 'center',
    shadowColor: 'black',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.4,
    shadowRadius: 4,
  },
});
