import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UnifiedDeepLinkData } from 'react-native-appsflyer';
import { persistReducer } from 'redux-persist';

import { setIsAppBusyLoading } from '../../../App/services/slices';
import { RootState } from '../../../App/store';
import { handleDeepLink, trackAnalyticsEvent } from '../utils';
import { Analytics } from '../utils/AppConstants';

const PERSIST_KEY = 'deepLinking';
const SLICE_NAME = 'DeepLinkingSlice';

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  blacklist: [],
};

type DeepLinkingState = {
  deepLinkData?: UnifiedDeepLinkData;
  consumedDeepLink: boolean;
};

const initialState: DeepLinkingState = {
  deepLinkData: undefined,
  consumedDeepLink: false,
};

export const selectShouldFollowDeepLink = (state: RootState): boolean =>
  Boolean(
    state.deepLinking.deepLinkData && !state.deepLinking.consumedDeepLink,
  );

/**
 * Follows the deep link if it is valid.
 * This is used to delay the deep link handling until the user is signed in.
 * @returns A promise that resolves when the deep link is followed
 */
export const followDeepLink = createAsyncThunk(
  `${SLICE_NAME}/followDeepLink`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (selectShouldFollowDeepLink(state)) {
      thunkApi.dispatch(setIsAppBusyLoading(true));
      const deepLinkData = state.deepLinking.deepLinkData;
      if (deepLinkData) {
        trackAnalyticsEvent(Analytics.deepLinkFollowed, {
          link: deepLinkData.data.link,
        });
        await handleDeepLink(deepLinkData, state);
        thunkApi.dispatch(setIsAppBusyLoading(false));
      }
    }
  },
);

const deepLinkingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    registerDeepLink: (state, action: PayloadAction<UnifiedDeepLinkData>) => {
      trackAnalyticsEvent(Analytics.deepLinkRegistered, {
        link: action.payload.data.link,
      });
      state.deepLinkData = action.payload;
      state.consumedDeepLink = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(followDeepLink.fulfilled, (state, action) => {
      state.consumedDeepLink = true;
      state.deepLinkData = undefined;
    });
  },
});

export const { registerDeepLink } = deepLinkingSlice.actions;

export const DeepLinkingSlice = persistReducer(
  persistConfig,
  deepLinkingSlice.reducer,
);
