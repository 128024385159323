import React from 'react';
import { StyleSheet, View } from 'react-native';

import { ActionButtonsView } from '../../../../Common/components/home/ActionButtonsView';
import { ContentCard } from '../../../../Common/entities';

import BaseContentCard from './BaseContentCard';

type Props = {
  item: ContentCard;
  isVisible: boolean;
};

export default ({ item }: Props): React.ReactElement => {
  return (
    <BaseContentCard item={item}>
      <View style={styles.contentContainer}>
        <View style={styles.expanded} />
      </View>
      <ActionButtonsView item={item} />
    </BaseContentCard>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    paddingLeft: 16,
  },
  expanded: {
    flexShrink: 1,
  },
});
