import { createAsyncThunk } from '@reduxjs/toolkit';
import { endConnection } from 'react-native-iap';

import { RootState } from '../../../../App/store';
import {
  PURCHASE_SLICE_NAME,
  setListenersInitialized,
} from '../PurchasesSlice';

export const unsubscribeForPurchasesUpdate = createAsyncThunk(
  `${PURCHASE_SLICE_NAME}/unsubscribeForPurchasesUpdate`,
  async (_, thunkApi) => {
    let state = thunkApi.getState() as RootState;
    if (state.purchases.listenersInitialized) {
      return;
    }
    endConnection();
    thunkApi.dispatch(setListenersInitialized(false));
  },
);
