import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';

import { AUTH_SLICE_NAME, AuthSliceState } from '..';
import { getAuthService } from '../../../../App/services/amplify/AuthService';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../../App/services/utils';
import { getUserProfileGraphQLCall } from '../../../../Profile/graphql';
import { GetProfileProps } from '../../../entities';

export const getUserProfile = createAsyncThunk(
  `${AUTH_SLICE_NAME}/getUserProfile`,
  async (props: GetProfileProps = {}, thunkApi) => {
    try {
      const userId = await getAuthService().getCurrentUserId();

      const user = await getUserProfileGraphQLCall(userId);

      if (!user?.id) {
        throw new Error('User not found');
      }

      return user;
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        if (!props.silent) {
          handleNetworkActionError(error);
        } else {
          handleNetworkActionErrorSilently(error);
        }
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const getUserProfileExtraReducers = (
  builder: ActionReducerMapBuilder<AuthSliceState>,
): void => {
  builder.addCase(getUserProfile.fulfilled, (state: AuthSliceState, action) => {
    state.authUser = action.payload;
  });
};
