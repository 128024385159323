import React, { useMemo } from 'react';
import { Dimensions, ScrollView, StyleSheet, View } from 'react-native';

import { PaywallEntryPoint } from '../../../Common/entities';
import { Colors, Sizes } from '../../../Common/services/utils/AppConstants';
import { LinearGradient } from '../../../Common/services/utils/polyfills';
import { EntitlementConfirmationFeatures } from '../../../Onboarding/components/entitlement-confirmation';
import {
  BootcampPaywallButton,
  BootcampPaywallGuarantee,
  BootcampPaywallLimitedOffer,
} from '../../../Paywall/components';

import { SATFreeTrialHeaderView } from './SATFreeTrialHeaderView';

const SUPPORTED_SCREEN_HEIGHTS = [932];

export const SATFreeTrialExpireView = (): React.ReactElement => {
  const freeTrialEndContainerHeight = useMemo(() => {
    const screenHeight = Dimensions.get('screen').height;
    const isProMax = SUPPORTED_SCREEN_HEIGHTS.includes(screenHeight);
    return isProMax ? '71.5%' : '68.5%';
  }, []);

  return (
    <LinearGradient
      start={{ x: 0, y: 10 }}
      end={{ x: 0, y: 1 }}
      colors={Colors.bottomGradientTestPrepColor}
      style={[styles.container, { height: freeTrialEndContainerHeight }]}>
      <View style={styles.contentContainer}>
        <ScrollView style={styles.scrollContainer}>
          <SATFreeTrialHeaderView />
          <View style={styles.sectionContainer}>
            <EntitlementConfirmationFeatures />
          </View>
        </ScrollView>
        <View style={styles.smallSectionContainer}>
          <BootcampPaywallLimitedOffer />
        </View>

        <View style={styles.smallSectionContainer}>
          <BootcampPaywallButton
            entryPoint={PaywallEntryPoint.FREE_TRIAL_ENDED}
          />
        </View>
        <View style={styles.smallSectionContainer}>
          <BootcampPaywallGuarantee />
        </View>
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: Sizes.medium,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '71%',
  },
  contentContainer: {
    flex: 1,
    paddingVertical: Sizes.small,
  },
  scrollContainer: {
    flex: 1,
  },

  sectionContainer: {
    marginTop: Sizes.semiMedium,
  },

  smallSectionContainer: {
    marginTop: Sizes.small,
  },
});
