import React from 'react';
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';

import CircularProgress from '../../Common/components/common/CircularProgress';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';

type Props = {
  score: number;
  maxScore: number;
  onLayout?: (event: LayoutChangeEvent) => void;
};

export const SATGradeIndicator = (props: Props): React.ReactElement => {
  return (
    <View style={styles.container} onLayout={props.onLayout}>
      <CircularProgress
        percentage={(props.score / props.maxScore) * 100}
        radius={62}
        color={Colors.buttonGreen}
        strokeWidth={8}>
        <View style={styles.circle}>
          <Text style={styles.scoreText}>{props.score}</Text>
          <Text style={styles.maxScoreText}>/{props.maxScore}</Text>
        </View>
      </CircularProgress>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: 110,
    height: 110,
    borderRadius: 100,
    backgroundColor: '#1D4349',
  },
  scoreText: {
    ...Fonts.xxxlargeBold,
  },
  maxScoreText: {
    marginTop: 10,
    ...Fonts.smallBold,
  },
});
