import AsyncStorage from '@react-native-async-storage/async-storage';
import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import { SetAccessibleCourseParams } from '../../entities';

import { getUserAccessExtraReducers } from './UserAccessSliceActions';

export const USER_ACCESS_SLICE_NAME = 'UserAccessSlice';

export type UserAccessSliceState = {
  accessibleCourses: Record<string, boolean>;
  hasUltimateAccess: boolean;
  hasSATPrepAccess: boolean;
};

const initialState: UserAccessSliceState = {
  accessibleCourses: {},
  hasUltimateAccess: false,
  hasSATPrepAccess: false,
};

const persistConfig = {
  key: USER_ACCESS_SLICE_NAME,
  storage: AsyncStorage,
  whitelist: ['accessibleCourses', 'hasUltimateAccess', 'hasSATPrepAccess'],
} as PersistConfig<UserAccessSliceState>;

const slice = createSlice({
  name: USER_ACCESS_SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetUserAccessSliceState: state => {
      state.accessibleCourses = {};
      state.hasUltimateAccess = false;
      state.hasSATPrepAccess = false;
    },
    setAccessibleCourse: (state, action: SetAccessibleCourseParams) => {
      state.accessibleCourses[action.payload.courseId] =
        action.payload.accessible;
    },
  },
  extraReducers: builder => {
    getUserAccessExtraReducers(builder);
  },
});

export const { resetUserAccessSliceState, setAccessibleCourse } = slice.actions;

export const UserAccessSlice = persistReducer(persistConfig, slice.reducer);
