import { generateClient, GraphQLSubscription } from 'aws-amplify/api';
import { Observable } from 'zen-observable-ts';

import { getAuthService } from '../../../App/services/amplify/AuthService';
import {
  NetworkError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import {
  GeneratedContentChunkResponse,
  GeneratedContentChunkResponseGQLSchema,
  GeneratedContentChunkSubscriptionNext,
} from '../../entities';

const subscriptionQuery = `
  subscription MessageSubscription($userId: ID!) {
    onCreateGeneratedContentChunk(userId: $userId) {
      ${GeneratedContentChunkResponseGQLSchema}
    }
  }
`;

export const subscribeToGeneratedContentChunk = async (): Promise<
  Observable<GeneratedContentChunkResponse>
> => {
  try {
    const userId = await getAuthService().getCurrentUserId();
    const authToken = await getAuthService().getIdToken();

    const client = generateClient({
      authToken,
    });

    const sub = client.graphql<
      GraphQLSubscription<GeneratedContentChunkSubscriptionNext>
    >({
      authToken,
      authMode: 'userPool',
      query: subscriptionQuery,
      variables: { userId },
    });

    return new Observable<GeneratedContentChunkResponse>(observer => {
      sub.subscribe({
        next: value => observer.next(value.data.onCreateGeneratedContentChunk),
        error: (err: any) => observer.error(err),
        complete: () => observer.complete(),
      });
    });
  } catch (error) {
    handleNetworkActionErrorSilently(error as NetworkError);
    throw error;
  }
};
