import { Course, Unit } from '../../../Common/entities';
import { mapDataToUnitTestPayload } from '../../../Common/services/mappers';
import { TestPrepShareFrqEmailAnalyticsPayload } from '../../entities';

export const mapTestPrepShareFrqEmailPayload = (
  course?: Course,
  unit?: Unit,
  externalUrl?: string,
  testId?: string,
): TestPrepShareFrqEmailAnalyticsPayload => {
  return {
    ...mapDataToUnitTestPayload((course || {}) as Course, (unit || {}) as Unit),
    testId: testId || '',
    externalUrl: externalUrl || '',
  };
};
