import { Animated, Easing } from 'react-native';

export const startWavingAnimation = (
  waveAnim: Animated.Value,
  rotateAnim: Animated.Value,
): Animated.CompositeAnimation => {
  const bounce = Animated.sequence([
    Animated.timing(waveAnim, {
      toValue: 1.2,
      duration: 300,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }),
    Animated.timing(waveAnim, {
      toValue: 0.8,
      duration: 200,
      easing: Easing.inOut(Easing.quad),
      useNativeDriver: true,
    }),
    Animated.timing(waveAnim, {
      toValue: 1,
      duration: 200,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }),
  ]);
  const oneWaveCycle = Animated.sequence([
    Animated.timing(rotateAnim, {
      toValue: 1,
      duration: 150,
      easing: Easing.out(Easing.quad),
      useNativeDriver: true,
    }),
    Animated.timing(rotateAnim, {
      toValue: -1,
      duration: 300,
      easing: Easing.inOut(Easing.quad),
      useNativeDriver: true,
    }),
    Animated.timing(rotateAnim, {
      toValue: 0,
      duration: 150,
      easing: Easing.in(Easing.quad),
      useNativeDriver: true,
    }),
  ]);

  const bounceAndWave = Animated.sequence([bounce, oneWaveCycle]);
  return Animated.loop(bounceAndWave);
};
