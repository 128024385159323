import locale from '../../../App/locale';
import {
  AppVersionDetails,
  CheckVersionCompatibilityResponse,
  VersionStatus,
} from '../../entities';

export const mapAppVersionResponseToAppVersionDetails = (
  appVersionResponse: CheckVersionCompatibilityResponse,
): AppVersionDetails => {
  let message = appVersionResponse.message || '';
  let dismissible = true;

  if (!message) {
    if (appVersionResponse.status === VersionStatus.OUTDATED) {
      message = locale.update_app_screen.message_outdated;
    } else if (appVersionResponse.status === VersionStatus.EXPIRED) {
      message = locale.update_app_screen.message_expired;
    }
  }

  if (appVersionResponse.status === VersionStatus.EXPIRED) {
    dismissible = false;
  }

  return {
    status: appVersionResponse.status as VersionStatus,
    message,
    dismissible,
    androidUrl: appVersionResponse.androidUpgradeUrl || '',
    iosUrl: appVersionResponse.iosUpgradeUrl || '',
  };
};
