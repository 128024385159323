import { EncryptedStorage } from './polyfills';

const CREDENTIALS_KEY = 'credentials';

export type Credentials = {
  username: string;
  password: string;
};

export class SecureStorageUtil {
  static async saveCredentials(credentials: Credentials): Promise<void> {
    await EncryptedStorage.setItem(
      CREDENTIALS_KEY,
      JSON.stringify(credentials),
    );
  }

  static async getCredentials(): Promise<Credentials | null> {
    const credentials = await EncryptedStorage.getItem(CREDENTIALS_KEY);
    return credentials ? JSON.parse(credentials) : null;
  }

  static async removeCredentials(): Promise<void> {
    await EncryptedStorage.removeItem(CREDENTIALS_KEY);
  }
}
