import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import { getRootState } from '../../../App/services/utils';
import {
  DMSpeaker,
  MessageScreenEntryPoints,
} from '../../../Messages/entities';
import { TestPreviewOrigin } from '../../../TestPrep/screens';
import { mapCourseUnitLockedStatus } from '../../../UserAccess/services/mappers';
import {
  ContentCard,
  Course,
  PaywallEntryPoint,
  Topic,
  Unit,
} from '../../entities';

import {
  MainStack,
  MessageStack,
  StudyStack,
  TabNames,
  TestStack,
} from './AppConstants';
import { isSATCourse } from './CourseUtil';

// Test Prep Redirections
export const redirectToTestPrep = (
  shouldShowCourseManagement?: boolean,
): void => {
  RootNavigatorRef.reset({
    index: shouldShowCourseManagement ? 2 : 1,
    routes: [
      {
        name: MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.TEST,
          params: {
            screen: MainStack.TEST_SCREEN,
          },
        },
      },
      ...(shouldShowCourseManagement
        ? [
            {
              name: MainStack.COURSE_LIST_PROFILE_SCREEN,
            },
          ]
        : []),
    ],
  });
};

// Unit Test Redirections
export const redirectToPaywall = (
  course: Course,
  entryPoint: PaywallEntryPoint,
): void => {
  if (entryPoint === PaywallEntryPoint.TEST_PREP) {
    RootNavigatorRef?.current?.reset({
      index: 2,
      routes: [
        {
          name: MainStack.BOTTOM_TABS,
          params: {
            screen: TabNames.TEST,
            params: {
              screen: TestStack.TEST_PREP_LIST,
            },
          },
        },
        {
          name: MainStack.PAYWALL_SCREEN,
          params: {
            course: course,
            showTestPrep: true,
            entryPoint: PaywallEntryPoint.TEST_PREP,
          },
        },
      ],
    });
  }
  if (entryPoint === PaywallEntryPoint.FEED) {
    RootNavigatorRef?.current?.reset({
      index: 2,
      routes: [
        {
          name: MainStack.BOTTOM_TABS,
          params: {
            screen: TabNames.HOME,
            params: {
              screen: StudyStack.COURSE_LIST_SCREEN,
            },
          },
        },
        {
          name: MainStack.PAYWALL_SCREEN,
          params: {
            course: course,
            showTestPrep: false,
            entryPoint: PaywallEntryPoint.FEED,
          },
        },
      ],
    });
  }
};

export const redirectToUnitTest = (course: Course, unit: Unit): void => {
  const rootState = getRootState();
  const isUnitLocked = mapCourseUnitLockedStatus(rootState, course.id, unit.id);

  if (isUnitLocked) {
    redirectToPaywall(course, PaywallEntryPoint.TEST_PREP);
    return;
  }
  RootNavigatorRef?.current?.reset({
    index: 2,
    routes: [
      {
        name: MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.TEST,
          params: {
            screen: TestStack.TEST_PREP_LIST,
          },
        },
      },
      {
        name: isSATCourse(course)
          ? MainStack.SAT_TEST_PREVIEW_SCREEN
          : MainStack.TEST_SCREEN,
        params: {
          course,
          unit,
          origin: TestPreviewOrigin.TEST_PREP_TAB,
        },
      },
    ],
  });
};

// Study Redirections
export const redirectToStudy = (shouldShowCourseManagement?: boolean): void => {
  RootNavigatorRef.reset({
    index: shouldShowCourseManagement ? 2 : 1,
    routes: [
      {
        name: MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: StudyStack.COURSE_LIST_SCREEN,
          },
        },
      },
      ...(shouldShowCourseManagement
        ? [
            {
              name: MainStack.COURSE_LIST_PROFILE_SCREEN,
            },
          ]
        : []),
    ],
  });
};

// Feed Redirections
export const redirectToFeed = (
  course: Course,
  unit: Unit,
  topic: Topic,
  contentCard?: ContentCard,
  shouldShowCourseManagement?: boolean,
): void => {
  const rootState = getRootState();
  const isUnitLocked = mapCourseUnitLockedStatus(rootState, course.id, unit.id);

  if (!shouldShowCourseManagement && !contentCard && isUnitLocked) {
    redirectToPaywall(course, PaywallEntryPoint.FEED);
    return;
  }
  RootNavigatorRef.reset({
    index: 2,
    routes: [
      {
        name: MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.HOME,
          params: {
            screen: StudyStack.COURSE_LIST_SCREEN,
          },
        },
      },
      ...(!shouldShowCourseManagement
        ? [
            {
              name: MainStack.BOTTOM_TABS,
              params: {
                screen: TabNames.HOME,
                params: {
                  screen: StudyStack.FEED_SCREEN,
                  params: {
                    course,
                    unit,
                    topic,
                    contentCard,
                  },
                },
              },
            },
          ]
        : [
            {
              name: MainStack.COURSE_LIST_PROFILE_SCREEN,
            },
          ]),
    ],
  });
};

// Inbox Redirections
export const redirectToInbox = (tutor: DMSpeaker): void => {
  RootNavigatorRef.reset({
    index: 2,
    routes: [
      {
        name: MainStack.BOTTOM_TABS,
        params: {
          screen: TabNames.INBOX,
          params: {
            screen: MainStack.MESSAGE_MAIN,
            params: {
              screen: MessageStack.MESSAGE_SCREEN,
              params: {
                speaker: tutor,
                entrypoint: MessageScreenEntryPoints.DM_THE_TEACHER,
              },
            },
          },
        },
      },
      {
        name: MainStack.MESSAGE_MAIN,
        params: {
          screen: MessageStack.MESSAGE_SCREEN,
          params: {
            speaker: tutor,
            entrypoint: MessageScreenEntryPoints.DM_THE_TEACHER,
          },
        },
      },
    ],
  });
};

// Profile Redirections
const PROFILE_ROUTE = {
  name: MainStack.BOTTOM_TABS,
  params: {
    screen: TabNames.PROFILE,
    params: {
      screen: MainStack.PROFILE_MAIN,
    },
  },
};

export const redirectToProfile = (): void => {
  RootNavigatorRef.reset({
    index: 1,
    routes: [PROFILE_ROUTE],
  });
};

export const redirectToProfileManageCourses = (): void => {
  RootNavigatorRef.reset({
    index: 2,
    routes: [
      PROFILE_ROUTE,
      {
        name: MainStack.COURSE_LIST_PROFILE_SCREEN,
      },
    ],
  });
};
