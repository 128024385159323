import { CommonApExamsConfigs } from '../../../Common/entities';
import { ApExamsMappedConfigs } from '../../entities';

export const mapApExamsRawConfig = (
  rawConfig?: CommonApExamsConfigs,
): ApExamsMappedConfigs => {
  if (!rawConfig) {
    return {
      coursesExamsDate: {},
      previousYearCoursesExamsDate: {},
    };
  }

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;

  const coursesExamsDate: Record<string, string> = {};
  const previousYearCoursesExamsDate: Record<string, string> = {};

  for (const course of rawConfig?.apCourses || []) {
    const selectedApExamCourseDate = rawConfig?.apExamDates?.find(
      exam => exam.name === course,
    );
    if (!selectedApExamCourseDate) {
      continue;
    }

    coursesExamsDate[course] =
      selectedApExamCourseDate?.examDates?.[currentYear] || '';
    previousYearCoursesExamsDate[course] =
      selectedApExamCourseDate?.examDates?.[previousYear] || '';
  }

  return {
    coursesExamsDate,
    previousYearCoursesExamsDate,
    examResultsDate: rawConfig?.apResultDates?.[currentYear],
  };
};
