import React from 'react';
import { StyleSheet, View } from 'react-native';

import { Sizes } from '../../Common/services/utils/AppConstants';

import { AiFigureLoadingItem } from './AiFigureProfileListLoading';

const FIGURE_IMAGE_SIZE = Sizes.icons[80];
const FIGURE_IMAGE_SIZE_LOADING = FIGURE_IMAGE_SIZE + 8;

export const AiFigureProfileHeaderLoading = (): React.ReactElement => {
  return (
    <View style={style.container}>
      <View style={style.profileImageOuter}>
        <AiFigureLoadingItem
          itemWidth={FIGURE_IMAGE_SIZE_LOADING}
          itemHeight={FIGURE_IMAGE_SIZE_LOADING}
          borderRadius={FIGURE_IMAGE_SIZE_LOADING}
        />
      </View>

      <View>
        <AiFigureLoadingItem itemWidth={180} itemHeight={24} />

        <View style={style.actionsContainer}>
          <AiFigureLoadingItem itemWidth={180} itemHeight={12} />
        </View>
      </View>
    </View>
  );
};

const style = StyleSheet.create({
  container: {
    flexDirection: 'row',
    columnGap: 16,
    marginTop: 8,
    alignItems: 'center',
  },
  profileImageOuter: {
    padding: 4,
    borderRadius: 100,
  },
  actionsContainer: {
    flexDirection: 'row',
    columnGap: 12,
    marginTop: 6,
    alignItems: 'center',
  },
});
