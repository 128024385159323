import { AxiosResponse } from 'axios';

import { ENV_KEYS, environment } from '../../../Common/services/utils';
import { getAuthService } from '../amplify/AuthService';

import { AppBucketApi } from './AppBucketApi';
import { ExternalApi } from './ExternalApi';
import { GoogleFormApi } from './GoogleFormApi';
import { GraphQLApi } from './GraphQLApi';

// In the web build, the instances need to be assigned to variables manually.
// This is due to an issue where the DynamicEnv import fails to initialize correctly.
// As a result, we explicitly instantiate and assign the APIs here.
export let graphqlApi: GraphQLApi;
export let appBucketApi: AppBucketApi;
export const googleFormApi = GoogleFormApi.getInstance();
export const externalApi = ExternalApi.getInstance();

export const assignRequestInstancesForWeb = (): void => {
  graphqlApi = GraphQLApi.getInstance();
  appBucketApi = AppBucketApi.getInstance();
};

export const handleGraphQLError = (response: AxiosResponse): void => {
  if (response.data?.errors) {
    const error = new Error(response.data.errors[0].message);
    error.name = 'GraphQLError';

    let configData: string = response.config.data;

    if (configData.includes('"query"')) {
      configData = configData.replace(/\\n/g, '\n');
      const functionNameRegExResults = configData.match(
        /(?<=mutation|query)\s\w+/,
      );
      if (functionNameRegExResults && functionNameRegExResults.length > 0) {
        const functionName = functionNameRegExResults[0];
        error.name += ': ' + functionName.trim();
      }
    }

    (error as any).extraPayload = {
      requestData: configData,
      responseData: response.data ? JSON.stringify(response.data) : undefined,
    };

    handleInvalidApiKey(response);

    throw error;
  }
};

const handleInvalidApiKey = (response: AxiosResponse): void => {
  if (
    response.data.errors[0].errorType === 'UnhautorizedException' &&
    response.config.headers['x-api-key']
  ) {
    environment.invalidateCachedValue(ENV_KEYS.API_KEY);
  }
};

export const handleRequestError = (response: AxiosResponse): void => {
  if (response.status >= 300) {
    const error = new Error(response.statusText);

    if (response.status >= 500) {
      error.name = 'ServerError';
    } else if (response.status >= 400) {
      error.name = 'RequestError';
    } else if (response.status >= 300) {
      error.name = 'RedirectionError';
    } else {
      error.name = 'UnknownError';
    }

    (error as any).extraPayload = {
      requestData: response.config.data,
      responseData: response.data ? JSON.stringify(response.data) : undefined,
    };

    throw error;
  }
};

export const getAuthHeader = async (): Promise<
  Partial<{ Authorization: string }>
> => {
  if (await getAuthService().isAuthenticated()) {
    return {
      Authorization: await getAuthService().getIdToken(),
    };
  } else {
    return {};
  }
};
