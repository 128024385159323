import { StyleSheet } from 'react-native';

import { Fonts } from '../../../../Common/services/utils/AppConstants';

export default StyleSheet.create({
  horizontalContainer: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    width: '100%',
  },
  questionText: {
    ...Fonts.normalize(Fonts.xlarge),
  },
  questionTextContainer: {
    marginTop: 10,
  },
  questionTextContainerCollapsed: {
    height: 0,
  },
  questionTextContainerHidden: {
    opacity: 0,
  },
  verticalContainer: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  contentTypeContainer: {
    flexDirection: 'row',
    columnGap: 6,
  },
});
