import React, { useEffect } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import FastImage, { Source } from 'react-native-fast-image';

import Assets from '../../../../App/assets';
import { AssetImage } from '../../../../App/assets/AssetImage';
import locale from '../../../../App/locale';
import { MainNavigationProp } from '../../../../App/navigation/main/MainStackNavigator';
import { useAppSelector } from '../../../../App/services/hooks';
import { useAppDispatch } from '../../../../App/store';
import { ContentCard } from '../../../../Common/entities';
import { addCardGestureEventListener } from '../../../../Common/events';
import { useSafeAreaCustomInsets } from '../../../../Common/services/hooks';
import {
  HapticUtil,
  redirectToAppstore,
} from '../../../../Common/services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
  ScreenNames,
} from '../../../../Common/services/utils/AppConstants';
import {
  Animated,
  useAnimatedStyle,
  useNavigation,
  withTiming,
} from '../../../../Common/services/utils/polyfills';
import { likeContent } from '../../../../Feedback/services/slices';
import {
  getCardAttributes,
  setCardAttributes,
  switchCardVisibilityStyle,
} from '../../../services/slices';

import homeStyles from './styles';

type Props = {
  item: ContentCard;
  onCardTap?: () => boolean;
  children?: React.ReactNode[];
  onHintPress?: () => void;
};

const SHOW_LIKE_DURATION = 700;
const LIKE_POPUP_DURATION = 300;

export default ({
  item,
  onCardTap,
  children,
  onHintPress,
}: Props): React.ReactElement => {
  const [showLike, setShowLike] = React.useState(false);
  const [likePosition, setLikePosition] = React.useState({ x: 0, y: 0 });

  const { topHeaderSafeAreaHeight } = useSafeAreaCustomInsets();

  const isLoggedIn = useAppSelector(state => state.auth.authUser);

  const navigation = useNavigation<MainNavigationProp<any>>();

  const isMediaPaused = useAppSelector(
    state => getCardAttributes(state.questions, item).isMediaPaused,
  );

  const isLiked = useAppSelector(
    state => getCardAttributes(state.questions, item).isContentLiked,
  );

  const tapToRevealShown = useAppSelector(
    state => getCardAttributes(state.questions, item).tapToRevealShown,
  );

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, item).visibilityStyle,
  );

  const heartStyle = useAnimatedStyle(() => {
    return {
      opacity: showLike ? withTiming(1, { duration: LIKE_POPUP_DURATION }) : 0,
      transform: [
        {
          scale: showLike
            ? withTiming(1, { duration: LIKE_POPUP_DURATION })
            : 0.5,
        },
        {
          translateY: showLike
            ? withTiming(-100, { duration: LIKE_POPUP_DURATION })
            : 0,
        },
      ],
    };
  });

  const dispatch = useAppDispatch();

  const onCardTapped = (): void => {
    if (onCardTap) {
      if (onCardTap()) {
        return;
      }
    }

    if (isWebPlatform) {
      if (onHintPress) {
        onHintPress();
        return;
      }
      redirectToAppstore();
      return;
    }

    if (item.visibilityStyleSwitch && visibilityStyle === 'no-text') {
      dispatch(switchCardVisibilityStyle(item));
      return;
    }

    dispatch(
      setCardAttributes({
        card: item,
        attributes: { isMediaPaused: !isMediaPaused },
      }),
    );
  };

  const triggerLogin = (): void => {
    if (!isLoggedIn) {
      navigation.navigate(ScreenNames.MainStack.LOGIN_STACK, {
        screen: ScreenNames.LoginStack.FULL_LOGIN_SCREEN,
        params: {},
      });
    }
  };

  useEffect(() => {
    const unsubscribe = addCardGestureEventListener(e => {
      if (e.uniqueId === item.uniqueId) {
        if (e.gesture === 'tap') {
          onCardTapped();
        } else if (e.gesture === 'double-tap') {
          if (isWebPlatform) {
            redirectToAppstore();
            return;
          }

          HapticUtil.effectDoubleClick();

          if (!isLoggedIn) {
            triggerLogin();
            return;
          }

          setShowLike(true);
          setLikePosition(e.position);

          setTimeout(() => {
            setShowLike(false);
          }, SHOW_LIKE_DURATION);
          if (!isLiked) {
            dispatch(
              likeContent({
                content: item,
                value: true,
                isFromDM: e.isFromDM ?? false,
              }),
            );
          }
        }
      }
    });
    return () => {
      unsubscribe();
    };
  });

  return (
    <View style={homeStyles.verticalContainer}>
      <View style={homeStyles.horizontalContainer}>{children}</View>
      {showLike && (
        <Animated.View
          style={[
            styles.heartContainer,
            heartStyle,
            {
              left: likePosition.x - 50,
              top: likePosition.y - topHeaderSafeAreaHeight - 100,
            },
          ]}>
          <AssetImage
            asset={Assets.home.likeIcon}
            height={100}
            fill={Colors.additional.red[100]}
          />
        </Animated.View>
      )}
      {tapToRevealShown && (
        <View style={styles.revealContainer} pointerEvents="none">
          <FastImage
            style={styles.revealImage}
            resizeMode={FastImage.resizeMode.contain}
            source={Assets.home.tapToRevealGif.path as Source}
          />
          <Text style={styles.revealText}>
            {locale.home_screen_card.tap_to_reveal}
          </Text>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  expanded: {
    flexShrink: 1,
  },

  heartContainer: {
    bottom: 200,
    position: 'absolute',
    right: 100,
  },
  revealContainer: {
    alignItems: 'center',
    alignSelf: 'center',
    bottom: 100,
    position: 'absolute',
    width: '100%',
  },
  revealImage: {
    height: 100,
    width: '100%',
  },
  revealText: {
    ...Fonts.large,
  },
});
