import { Card, DmsFromDeadV2Card } from '../../../Common/entities';
import { mapCardToAnalyticsPayload } from '../../../Common/services/mappers';
import { DMSpeaker } from '../../../Messages/entities';
import {
  RaiseHandAnalyticsPayload,
  RaiseHandAnswerAnalyticsPayload,
  RaiseHandSpeakerAnalyticsPayload,
} from '../../entities';
import { RaiseHandMessageType, RaiseHandScreenTypes } from '../../entities';

export const mapCardToRaiseHandPayload = (
  card: Card,
  speaker: DMSpeaker,
  contentGeneratedId: string,
  fromScreen: RaiseHandScreenTypes,
  contentGeneratorId: RaiseHandMessageType,
): RaiseHandAnswerAnalyticsPayload => {
  const dmsFromDeadCard = card as DmsFromDeadV2Card;
  return {
    ...mapCardToAnalyticsPayload(card),
    question: dmsFromDeadCard.question,
    speakerUsername: speaker.username,
    speakerName: speaker.name,
    answer: dmsFromDeadCard.response,
    contentGeneratedId: contentGeneratedId,
    from: fromScreen,
    contentGeneratorId: contentGeneratorId,
  };
};

export const mapRaiseHandToPayload = (
  question: string,
  dmFigure: { username: string; name: string },
  contentGeneratedId: string,
  contentGeneratorId: RaiseHandMessageType,
  fromScreen: RaiseHandScreenTypes,
): RaiseHandAnalyticsPayload => {
  return {
    question: question,
    speakerUsername: dmFigure.username,
    speakerName: dmFigure.name,
    contentGeneratedId: contentGeneratedId,
    contentGeneratorId: contentGeneratorId,
    from: fromScreen,
  };
};

export const mapDmsSpeakerWithContentToPayload = (
  speaker: DMSpeaker,
  generatedContentId: string,
  from: RaiseHandScreenTypes,
): RaiseHandSpeakerAnalyticsPayload => {
  return {
    speakerUsername: speaker.username,
    speakerName: speaker.name,
    generatedContentId: generatedContentId,
    from,
  };
};
