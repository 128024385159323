import { useIsFocused } from '@react-navigation/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, TextInput, TouchableOpacity, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import store, { useAppDispatch } from '../../App/store';
import {
  Colors,
  Durations,
  Fonts,
  isAndroidPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';
import { setRaiseHandDraftMessage } from '../../RaiseHand/services/slices';
import { setDraftMessage } from '../services/slices';

type Props = {
  onSendPress: (message: string) => void;
  speakerPlatformId: string;
  generatedContentId?: string;
  isEditable?: boolean;
  speakerName?: string;
};

export const MessageTextInputContainer = ({
  onSendPress,
  speakerPlatformId,
  generatedContentId = '',
  isEditable = true,
  speakerName,
}: Props): React.ReactElement => {
  const [message, setMessage] = useState('');
  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  const handleSetMessageWithTimeout = useCallback((message: string) => {
    setTimeout(() => {
      setMessage(message);
    }, Durations.quickTransition);
  }, []);

  const handleSendPress = useCallback(
    (message: string) => {
      if (generatedContentId) {
        dispatch(setRaiseHandDraftMessage({ generatedContentId, message: '' }));
      } else {
        dispatch(setDraftMessage({ speakerPlatformId, message: '' }));
      }

      handleSetMessageWithTimeout('');

      if (onSendPress) {
        onSendPress(message);
      }
    },
    [
      dispatch,
      generatedContentId,
      handleSetMessageWithTimeout,
      onSendPress,
      speakerPlatformId,
    ],
  );

  const isButtonEnabled = useMemo(() => {
    return message.length > 0 && isEditable;
  }, [message.length, isEditable]);

  useEffect(() => {
    if (speakerPlatformId && isFocused) {
      let initialMessage =
        store.getState().messages.draftMessage[speakerPlatformId] || '';

      if (generatedContentId) {
        initialMessage =
          store.getState().raiseHand.draftMessage[generatedContentId] || '';
      }

      handleSetMessageWithTimeout(initialMessage);
    }
  }, [
    speakerPlatformId,
    generatedContentId,
    isFocused,
    handleSetMessageWithTimeout,
  ]);

  useEffect(() => {
    if (isFocused && speakerPlatformId) {
      if (generatedContentId) {
        dispatch(setRaiseHandDraftMessage({ generatedContentId, message }));
        return;
      }

      dispatch(setDraftMessage({ speakerPlatformId, message }));
    }
  }, [dispatch, message, speakerPlatformId, generatedContentId, isFocused]);

  const placeholder = useMemo(() => {
    if (isEditable) {
      return locale.dms_screen.send_a_message;
    }
    return locale.dms_screen.responding_to_message.replace(
      '${SPEAKER}',
      speakerName || locale.dms_screen.the_tutor,
    );
  }, [isEditable, speakerName]);

  return (
    <View style={styles.inputContainer}>
      <View
        style={[
          styles.textInputContainer,
          !isEditable && styles.disabledTextInputContainer,
        ]}>
        <TextInput
          editable={isEditable}
          value={message}
          style={[
            styles.textInput,
            isAndroidPlatform && styles.androidTextInput,
          ]}
          multiline
          autoCapitalize="none"
          // returnKeyType should be 'default' keyboard because it allows new-line
          returnKeyType="default"
          placeholder={placeholder}
          placeholderTextColor={Colors.white60}
          onChangeText={setMessage}
        />
      </View>
      <TouchableOpacity
        style={[styles.sendButton, isButtonEnabled && styles.sendButtonActive]}
        onPress={() => handleSendPress(message)}
        disabled={!isEditable}>
        <AssetImage
          asset={Assets.message.messageSendIcon}
          width={20}
          height={22}
          fill="white"
          fillOpacity={message.length > 0 ? 1 : 0.2}
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  inputContainer: {
    alignSelf: 'center',
    alignItems: 'flex-end',
    flexDirection: 'row',
    alignContent: 'center',
    marginHorizontal: Sizes.medium,
    marginBottom: Sizes.medium,
    backgroundColor: 'transparent',
    paddingBottom: 20,
  },
  container: {
    flex: 1,
  },
  sendButton: {
    alignItems: 'center',
    borderRadius: 19,
    height: 38,
    width: 38,
    justifyContent: 'center',
    marginStart: 8,
    backgroundColor: Colors.white12,
  },
  sendButtonActive: {
    backgroundColor: Colors.actionGreen,
  },
  sendButtonIcon: {
    height: 20,
    width: 22,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    ...Fonts.semiMedium,
    flex: 1,
    paddingTop: 0,
  },
  androidTextInput: {
    height: '95%',
    paddingVertical: 2,
  },
  textInputContainer: {
    alignItems: 'center',
    backgroundColor: Colors.white24,
    borderRadius: 20,
    flex: 1,
    flexDirection: 'row',
    minHeight: 40,
    maxHeight: 92,
    paddingVertical: 8,
    paddingEnd: 12,
    paddingStart: 16,
  },
  disabledTextInputContainer: {
    backgroundColor: Colors.white12,
  },
});
