import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../../App/locale';
import { MatchingPairsCard } from '../../../../Common/entities';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../../Common/services/utils/AppConstants';

type Props = {
  item: MatchingPairsCard;
};

const MAX_WIDTH = Sizes.getAppWindowWidth() - 80;

const renderMatchingPairsHeader = ({ item }: Props): React.ReactElement[] => {
  return locale.home_screen_card.matching_pairs_detail.split(' ').map(word => {
    if (word === '${FROM}') {
      return <Text style={styles.titleHighlight}>{item.matchFrom}&nbsp;</Text>;
    }

    if (word === '${TO}') {
      return <Text style={styles.titleHighlight}>{item.matchTo}</Text>;
    }

    return <>{word}&nbsp;</>;
  });
};

export default ({ item }: Props): React.ReactElement => {
  return (
    <View style={{ maxWidth: MAX_WIDTH }}>
      <Text style={styles.title}>{renderMatchingPairsHeader({ item })}</Text>

      <Text style={styles.topic}>
        {locale.home_screen_card.matching_pairs_topic.replace(
          '${TITLE}',
          item.title,
        )}
      </Text>

      <Text style={styles.instruction}>
        {locale.home_screen_card.matching_pairs_instructions}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    ...Fonts.normalize(Fonts.medium),
    color: Colors.grayscale[0],
    textShadowColor: 'black',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    marginTop: Sizes.xsmall,
  },
  titleHighlight: {
    ...Fonts.normalize(Fonts.mediumBold),
    color: Colors.actionGreen,
  },

  topic: {
    ...Fonts.normalize(Fonts.largeBold),
    color: Colors.grayscale[0],
    textShadowColor: 'black',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    marginTop: Sizes.semiLarge,
  },

  instruction: {
    ...Fonts.normalize(Fonts.medium),
    color: Colors.grayscale[0],
    textShadowColor: 'black',
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 1,
    marginTop: Sizes.xsmall,
  },
});
