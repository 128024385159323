import { useIsFocused } from '@react-navigation/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import Assets from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import { Colors, Fonts } from '../../Common/services/utils/AppConstants';
import { DMSpeaker } from '../entities';
import { MessageDateFormats } from '../services/utils';

type MessageListItemDescriptionProps = {
  speaker: DMSpeaker;
  isUnread: boolean;
};

export const MessageListItemDescription = ({
  speaker,
  isUnread,
}: MessageListItemDescriptionProps): React.ReactElement => {
  const isFocused = useIsFocused();
  const messages = useAppSelector(
    state => state.messages.messages[speaker.platformId],
  );

  const formatDate = useMemo((): string => {
    if (!messages?.length || !isFocused) {
      return '';
    }

    const lastMessage = messages[messages?.length - 1];
    if (!lastMessage?.date) {
      return '';
    }

    let date = moment(lastMessage.date, MessageDateFormats.dateWithTimeUS);
    if (!date.isValid()) {
      date = moment(lastMessage.date);
    }

    if (date.isValid()) {
      const now = moment();

      const diffMinutes = now.diff(date, 'minutes');
      if (diffMinutes < 60) {
        return diffMinutes < 1 ? locale.dms_screen.now : `${diffMinutes}mins`;
      }

      const diffHours = now.diff(date, 'hours');
      if (diffHours < 24) {
        return `${diffHours}hr`;
      }

      const startOfNow = now.clone().startOf('day');
      const startOfDate = date.clone().startOf('day');
      const diffDays = startOfNow.diff(startOfDate, 'days');
      return `${diffDays}d`;
    } else {
      return '';
    }
  }, [messages, isFocused]);

  const lastMessage = useMemo(() => {
    if (!messages?.length) {
      return null;
    }

    return messages[messages.length - 1];
  }, [messages]);

  const descriptionStyle = useMemo(() => {
    return [
      styles.description,
      styles.messageMaxWidth,
      isUnread && styles.descriptionBold,
    ];
  }, [isUnread]);

  if (lastMessage) {
    const descriptionText = lastMessage.isVideoMessage
      ? locale.dms_screen.video
      : lastMessage.message;

    return (
      <View style={styles.descriptionContainer}>
        <Text style={descriptionStyle} numberOfLines={1}>
          {descriptionText}
        </Text>
        <AssetImage asset={Assets.message.dotIcon} height={5} />
        <Text style={styles.time}>{formatDate}</Text>
      </View>
    );
  } else {
    return (
      <Text style={styles.description}>{locale.dms_screen.tap_to_chat}</Text>
    );
  }
};

const styles = StyleSheet.create({
  time: {
    paddingLeft: 5,
    ...Fonts.small,
    color: Colors.captionGrey,
  },
  descriptionContainer: {
    flexDirection: 'row',
    columnGap: 2,
    alignItems: 'center',
    paddingRight: 5,
  },
  messageMaxWidth: {
    maxWidth: '70%',
  },
  description: {
    ...Fonts.small,
    color: Colors.captionGrey,
    paddingRight: 5,
  },
  descriptionBold: {
    ...Fonts.smallBold,
    color: Colors.grayscale[0],
  },
});
