import {
  ITEM_VISIBILITY_CHANGE_EVENT,
  MEDIA_PLAYBACK_EVENT,
  MEDIA_PROGRESS_EVENT,
  MediaPlaybackEventPayload,
  MediaProgressEventPayload,
  VisibilityChangeEventPayload,
} from './types';
import { addEventEmitterListenerPair } from './utils';

export const [emitVisibilityChangeEvent, addVisibilityChangeEventListener] =
  addEventEmitterListenerPair<VisibilityChangeEventPayload>(
    ITEM_VISIBILITY_CHANGE_EVENT,
  );

export const [emitMediaProgressEvent, addMediaProgressEventListener] =
  addEventEmitterListenerPair<MediaProgressEventPayload>(MEDIA_PROGRESS_EVENT);

export const [emitMediaPlaybackEvent, addMediaPlaybackEventListener] =
  addEventEmitterListenerPair<MediaPlaybackEventPayload>(MEDIA_PLAYBACK_EVENT);
