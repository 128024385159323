import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { AssetImage } from '../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { SATRowData } from '../entities';

type SATStarterInfoViewProps = {
  rowData: SATRowData[];
};

export const SATStarterInfoView = ({
  rowData,
}: SATStarterInfoViewProps): React.ReactElement => {
  return (
    <View style={[styles.satInfoContainer, styles.gap20]}>
      {rowData.map(rowData => (
        <View style={styles.satInfoRow} key={rowData.key}>
          <AssetImage
            asset={rowData.image}
            height={rowData.size}
            width={rowData.size}
            containerStyle={[
              styles.satInfoAssetContainer,
              { height: rowData.size },
            ]}
          />
          <Text style={styles.satInfoText}>{rowData.text}</Text>
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  satInfoContainer: {
    flex: 1,
  },
  gap20: {
    gap: Sizes.normalizeIP14PM(10),
  },
  satInfoRow: {
    gap: 8,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12,
    borderWidth: 1,
    borderColor: Colors.white12,
    paddingVertical: 16,
    paddingHorizontal: 14,
    backgroundColor: Colors.white10,
    minHeight: Sizes.normalizeIP14PM(100),
  },
  satInfoAssetContainer: {
    width: Sizes.normalizeIP14PM(34),
    alignItems: 'center',
    alignSelf: 'center',
  },
  satInfoText: {
    ...Fonts.caption,
    color: Colors.white80,
    flex: 1,
    textAlign: 'center',
  },
});

export const renderSATStarterInfoView = (
  rowData: SATRowData[],
): React.ReactElement => <SATStarterInfoView rowData={rowData} />;
