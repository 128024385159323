import { UserSignupDataInput } from '../../Auth/entities';

import { ProfileUpdateEntryPoints } from './ProfileTypes';

export type MapSignUpDataToUserSettingsInput = {} & UserSignupDataInput;

export enum CommonTrueFalse {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export type MapProfileDetailsUpdateAnalyticsParams = {
  from?: ProfileUpdateEntryPoints;
  profileImageUpdated?: boolean;
  nameUpdated?: boolean;
  usernameUpdated?: boolean;
  passwordChanged?: boolean;
};

export type MapProfileDetailsUpdateAnalyticsType = {
  from: ProfileUpdateEntryPoints;
  profileImageUpdated?: CommonTrueFalse;
  nameUpdated?: CommonTrueFalse;
  usernameUpdated?: CommonTrueFalse;
  passwordChanged?: CommonTrueFalse;
};
