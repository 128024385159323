import {
  getAuthHeader,
  graphqlApi,
  handleGraphQLError,
} from '../../../App/services/requests';
import {
  GetSATProgramDetailsRequest,
  GetSATProgramDetailsResponse,
} from '../../entities';

export const getSATProgramDetailsGraphQLCall = async (
  input: GetSATProgramDetailsRequest,
): Promise<GetSATProgramDetailsResponse> => {
  const authHeader = (await getAuthHeader()) as any;
  if (Object.keys(authHeader).length > 0) {
    authHeader['x-api-key'] = undefined;
  }

  const response = await graphqlApi.post(
    '',
    {
      query: `
      query TeachTapGetSATProgramDetails($input: TeachTapGetSATProgramDetailsInput!) {
        teachTapGetSATProgramDetails(input: $input) {
          programName
          programDuration
          goalTitle
          studyCommitmentTitle
          studyCommitmentHours
          studyCommitmentPeriod
          deadlineToEnroll
          enrolledAt
          programType
        }
      }`,
      variables: {
        input,
      },
    },
    {
      headers: {
        ...authHeader,
      },
    },
  );

  handleGraphQLError(response);

  return response.data.data?.teachTapGetSATProgramDetails;
};
