import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';

import locale from '../../App/locale';
import {
  Colors,
  Fonts,
  isAndroidPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';

type Props = {
  onFollowPress: () => void;
};

const DEFAULT_FOLLOW_BUTTON_WIDTH = 72;

export const CourseFollowButton = (props: Props): React.ReactElement => {
  return (
    <View style={styles.followButtonContainer}>
      <TouchableOpacity
        style={styles.followButtonActive}
        onPress={props.onFollowPress}>
        <Text style={styles.buttonText}>
          {locale.home_screen_card.follow_action}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  followButtonContainer: {
    justifyContent: 'center',
    minWidth: DEFAULT_FOLLOW_BUTTON_WIDTH,
  },
  followButtonActive: {
    alignItems: 'center',
    backgroundColor: Colors.buttonGreen,
    borderRadius: 6,
    justifyContent: 'center',
    paddingHorizontal: Sizes.xsmall,
    paddingVertical: 6,
  },
  buttonText: {
    ...Fonts.small,
    lineHeight: isAndroidPlatform ? 18 : undefined,
  },
});

export const renderNullableCourseFollowButton = (
  isFollowed: boolean,
  onFollowPress: () => void,
): React.ReactElement | null =>
  isFollowed ? null : <CourseFollowButton onFollowPress={onFollowPress} />;
