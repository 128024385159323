import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const APP_SLICE_NAME = 'AppSlice';
export const APP_SLICE_INITIAL_STATE = {
  isAppActive: false,
};

const slice = createSlice({
  name: APP_SLICE_NAME,
  initialState: APP_SLICE_INITIAL_STATE,
  reducers: {
    setIsAppActive: (state, action: PayloadAction<boolean>) => {
      state.isAppActive = action.payload;
    },
  },
});

export const { setIsAppActive } = slice.actions;

export const AppSlice = slice.reducer;
