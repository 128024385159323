import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { Alert } from 'react-native';
import { persistReducer } from 'redux-persist';
import { PersistConfig } from 'redux-persist/es/types';

import locale from '../../../App/locale';
import { RootNavigatorRef } from '../../../App/navigation/RootNavigator';
import { useAppSelector } from '../../../App/services/hooks';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import { RootState } from '../../../App/store';
import { logout } from '../../../Auth/services/slices';
import {
  AIContentType,
  BetaFeedbackSource,
  ContentCard,
  Course,
  DmsFromDeadCard,
  FeedType,
  Unit,
} from '../../../Common/entities';
import {
  mapAiFigureAnalyticsPayload,
  mapCardToAnalyticsPayload,
  mapGenerateQuestionResponseToContentCard,
} from '../../../Common/services/mappers';
import { trackAnalyticsEvent } from '../../../Common/services/utils';
import {
  Analytics,
  isWebPlatform,
  ScreenNames,
} from '../../../Common/services/utils/AppConstants';
import { StoreReview } from '../../../Common/services/utils/polyfills';
import {
  fetchSubtitleDataFromCard,
  getCardAttributes,
  setBookmarkContent,
  setCardAttributes,
  setLikeContent,
  setQuestionPoints,
  setShareContent,
  syncAllAttributes,
} from '../../../Learn/services/slices';
import { mapCardToDMFromDeadPayload } from '../../../Messages/services/mappers';
import {
  setVideoBookmarkContent,
  setVideoLikeContent,
  setVideoShareContent,
} from '../../../Messages/services/slices';
import {
  AppFeedbackType,
  CommentFeedbackAction,
  FeedbackAction,
  FeedbackTypes,
  IncreaseInteractionStateAction,
  ReportContentPayload,
  ReportContentType,
  RetrieveContentPayload,
  ShareAiFigureAction,
} from '../../entities';
import {
  addContentFeedbackGraphQLCall,
  getInteractedContentGraphQLCall,
  removeContentFeedbackGraphQLCall,
  updateCommentFeedbackGraphQLCall,
} from '../../graphql';

import { updateCommentLocally } from './CommentsSlice';

const PERSIST_KEY = 'feedback';
const SLICE_NAME = 'FeedbackSlice';

type State = {
  bookmarksList: ContentCard[];
  likesList: ContentCard[];
  commentsLikes: Record<string, boolean>;
  commentsDislikes: Record<string, boolean>;
  dislikes: Record<string, boolean>;

  bookmarksFilterCourse: string | null;
  bookmarksFilterUnit: string | null;

  likedContentCount: number;
  bookmarkedContentCount: number;

  isNextBookmarkAvailable: boolean;
  bookmarkLastRequestPage: number;
  isNextLikeAvailable: boolean;
  likeLastRequestPage: number;

  isFetchingBookmarkList: boolean;
  isFetchingLikeList: boolean;

  feedbackRequestedOn?: string;
  feedbackProvided: boolean;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: [
    'commentsLikes',
    'commentsDislikes',
    'dislikes',
    'feedbackProvided',
    'feedbackRequestedOn',
    'likedContentCount',
    'bookmarkedContentCount',
  ],
} as PersistConfig<State>;

const initialState: State = {
  bookmarksList: [],
  likesList: [],
  commentsLikes: {},
  commentsDislikes: {},
  dislikes: {},

  bookmarksFilterCourse: null,
  bookmarksFilterUnit: null,

  bookmarkLastRequestPage: 0,
  likeLastRequestPage: 0,
  isNextBookmarkAvailable: true,
  isNextLikeAvailable: true,

  likedContentCount: 0,
  bookmarkedContentCount: 0,

  isFetchingBookmarkList: false,
  isFetchingLikeList: false,

  feedbackProvided: false,
};

// Decreased tp 6 to reduce the request time out issue (sometimes it persist)
export const BOOKMARKS_PAGE_LIMIT = 6;
const ASK_USER_FEEDBACK_INTERVAL_HOURS = 12;
const CONTENT_REACTIONS_NEEDED_FOR_FEEDBACK = 5;

export const reportContent = createAsyncThunk(
  `${SLICE_NAME}/reportContent`,
  async (payload: ReportContentPayload, thunkApi) => {
    const { content, type, feedbackNotes } = payload;

    if (!isWebPlatform) {
      if (payload.isFromDM) {
        trackAnalyticsEvent(Analytics.dmAnswerReport, {
          ...mapCardToDMFromDeadPayload(content),
          type,
        });
      } else {
        trackAnalyticsEvent(Analytics.reportCard, {
          ...mapCardToAnalyticsPayload(content),
          type,
        });
      }
    }

    let feedbackType = FeedbackTypes.report_other;

    switch (type) {
      case ReportContentType.Incorrect:
        feedbackType = FeedbackTypes.report_incorrect;
        break;
      case ReportContentType.Unrelated:
        feedbackType = FeedbackTypes.report_unrelated;
        break;
      case ReportContentType.Weird:
        feedbackType = FeedbackTypes.report_offensive;
        break;
      case ReportContentType.NotInteresting:
        feedbackType = FeedbackTypes.report_not_interested;
        break;
      case ReportContentType.Other:
        feedbackType = FeedbackTypes.report_other;
        break;
    }

    const isLiked = useAppSelector(
      state => getCardAttributes(state.questions, content).isContentLiked,
    );

    if (isLiked) {
      if (payload.isFromDM) {
        thunkApi.dispatch(
          setVideoLikeContent({
            content: content as DmsFromDeadCard,
            value: false,
          }),
        );
      } else {
        thunkApi.dispatch(
          likeContent({
            content,
            value: false,
          }),
        );
      }
    }

    try {
      await addContentFeedbackGraphQLCall({
        feedbackType,
        generatedContentId: content.generatedContentId,
        feedbackNotes,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const increaseLikeContentCount = createAsyncThunk(
  `${SLICE_NAME}/increaseLikeContentCount`,
  async (payload: IncreaseInteractionStateAction, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.feedback;

    const likedContentCount = localState.likedContentCount + payload.count;
    setLikedContentCount(likedContentCount > 0 ? likedContentCount : 0);

    thunkApi.dispatch(checkAndProceedUserFeedback());
  },
);

export const increaseBookmarkContentCount = createAsyncThunk(
  `${SLICE_NAME}/increaseBookmarkContentCount`,
  async (payload: IncreaseInteractionStateAction, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.feedback;

    const bookmarkedContentCount =
      localState.bookmarkedContentCount + payload.count;
    setBookmarkedContentCount(
      bookmarkedContentCount > 0 ? bookmarkedContentCount : 0,
    );

    thunkApi.dispatch(checkAndProceedUserFeedback());
  },
);

export const checkAndProceedUserFeedback = createAsyncThunk(
  `${SLICE_NAME}/checkAndProceedUserFeedback`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const localState = state.feedback;

    if (
      localState.likedContentCount + localState.bookmarkedContentCount >=
      CONTENT_REACTIONS_NEEDED_FOR_FEEDBACK
    ) {
      thunkApi.dispatch(requestUserFeedback());
    }
  },
);

export const retrieveNextLikedContent = createAsyncThunk(
  `${SLICE_NAME}/retrieveNextLikedContent`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (
      state.feedback.isFetchingLikeList ||
      !state.feedback.isNextLikeAvailable
    ) {
      return;
    }

    const lastPage = state.feedback.likeLastRequestPage + 1;

    const results = await thunkApi
      .dispatch(
        retrieveContent({
          page: lastPage,
          interactionType: FeedbackTypes.like,
        }),
      )
      .unwrap();

    const currentLikedList = [...state.feedback.likesList];
    const newLikedList = results.filter(
      content =>
        !currentLikedList.some(
          c => c.generatedContentId === content.generatedContentId,
        ),
    );

    thunkApi.dispatch(setLikedList([...currentLikedList, ...newLikedList]));
  },
);

export const retrieveNextBookmarkedContent = createAsyncThunk(
  `${SLICE_NAME}/retrieveNextBookmarkedContent`,
  async (_, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    if (
      state.feedback.isFetchingBookmarkList ||
      !state.feedback.isNextBookmarkAvailable
    ) {
      return;
    }

    const lastPage = state.feedback.bookmarkLastRequestPage + 1;

    const results = await thunkApi
      .dispatch(
        retrieveContent({
          page: lastPage,
          interactionType: FeedbackTypes.bookmark,
        }),
      )
      .unwrap();

    const currentBookmarkList = [...state.feedback.bookmarksList];
    const newBookmarkList = results.filter(
      content =>
        !currentBookmarkList.some(
          c => c.generatedContentId === content.generatedContentId,
        ),
    );

    thunkApi.dispatch(
      setBookmarkedList([...currentBookmarkList, ...newBookmarkList]),
    );
  },
);

export const initRetrieveBookmarkedLikedContent = createAsyncThunk(
  `${SLICE_NAME}/initRetrieveBookmarkedContent`,
  async (_, thunkApi) => {
    const [bookmarkedResults, likedResults] = await Promise.all([
      thunkApi
        .dispatch(
          retrieveContent({ page: 1, interactionType: FeedbackTypes.bookmark }),
        )
        .unwrap(),
      thunkApi
        .dispatch(
          retrieveContent({ page: 1, interactionType: FeedbackTypes.like }),
        )
        .unwrap(),
    ]);

    thunkApi.dispatch(setBookmarkedList(bookmarkedResults));
    thunkApi.dispatch(setLikedList(likedResults));
  },
);

export const retrieveContent = createAsyncThunk(
  `${SLICE_NAME}/retrieveContent`,
  async (payload: RetrieveContentPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const selectedPageNum = payload.page || 1;

    try {
      const response = await getInteractedContentGraphQLCall({
        pageNum: selectedPageNum,
        pageSize: BOOKMARKS_PAGE_LIMIT,
        interactionType: payload.interactionType,
      });

      thunkApi.dispatch(checkAndProceedUserFeedback());

      if (response.items.length >= BOOKMARKS_PAGE_LIMIT) {
        if (payload.interactionType === FeedbackTypes.bookmark) {
          thunkApi.dispatch(setNextBookmarkAvailable(true));
        } else {
          thunkApi.dispatch(setNextLikeAvailable(true));
        }
      } else {
        if (payload.interactionType === FeedbackTypes.bookmark) {
          thunkApi.dispatch(setNextBookmarkAvailable(false));
        } else {
          thunkApi.dispatch(setNextLikeAvailable(false));
        }
      }

      if (payload.interactionType === FeedbackTypes.bookmark) {
        thunkApi.dispatch(setLastBookmarkRequestPage(selectedPageNum));
      } else {
        thunkApi.dispatch(setLastLikeRequestPage(selectedPageNum));
      }

      return response.items
        .filter(content =>
          Object.values(AIContentType).includes(content.contentTypeId as any),
        )
        .map(content => {
          const course = state.courses.coursesMap[content.courseId];
          const unit = course?.units?.find(unit => unit.id === content.unitId);
          const topic = unit?.topics?.find(
            topic => topic.id === content.topicId,
          );

          const result = mapGenerateQuestionResponseToContentCard({
            feedId: FeedType.Favorites,
            course,
            unit: unit!,
            topic: topic!,
            response: content,
          });
          thunkApi.dispatch(fetchSubtitleDataFromCard(result));

          thunkApi.dispatch(
            setQuestionPoints({
              id: result.uniqueId,
              points: result.points ?? 0,
            }),
          );

          return result;
        });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const shareAiFigure = createAsyncThunk(
  `${SLICE_NAME}/shareAiFigure`,
  async (action: ShareAiFigureAction, _thunkApi) => {
    const { content } = action;

    trackAnalyticsEvent(Analytics.shareAiFigure, {
      ...mapAiFigureAnalyticsPayload(content),
    });
  },
);

export const shareContent = createAsyncThunk(
  `${SLICE_NAME}/shareContent`,
  async (action: FeedbackAction, thunkApi) => {
    const { content } = action;
    if (!isWebPlatform) {
      if (action.isFromDM) {
        trackAnalyticsEvent(Analytics.dmAnswerShare, {
          ...mapCardToDMFromDeadPayload(content),
        });
      } else {
        trackAnalyticsEvent(Analytics.shareCard, {
          ...mapCardToAnalyticsPayload(content),
        });
      }
    }

    try {
      await addContentFeedbackGraphQLCall({
        feedbackType: FeedbackTypes.share,
        generatedContentId: content.generatedContentId,
      });
      if (action.isFromDM) {
        thunkApi.dispatch(setVideoShareContent(content as DmsFromDeadCard));
      } else {
        thunkApi.dispatch(setShareContent(content));
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const likeComment = createAsyncThunk(
  `${SLICE_NAME}/likeComment`,
  async (action: CommentFeedbackAction, thunkApi) => {
    const { content, value, card } = action;

    thunkApi.dispatch(
      updateCommentLocally({
        commentId: content.id,
        parentCommentId: action.parentCommentId,
        card: card,
        fields: {
          likes_count: value
            ? content.likes_count + 1
            : content.likes_count - 1,
        },
      }),
    );

    if (!isWebPlatform) {
      if (action.isFromDM) {
        trackAnalyticsEvent(Analytics.dmAnswerLikeComment, {
          ...mapCardToDMFromDeadPayload(card),
        });
      } else {
        trackAnalyticsEvent(Analytics.likeComment, {
          ...mapCardToAnalyticsPayload(card),
        });
      }
    }

    try {
      await updateCommentFeedbackGraphQLCall({
        commentId: content.id,
        feedback: value ? true : null,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const dislikeComment = createAsyncThunk(
  `${SLICE_NAME}/dislikeComment`,
  async (action: CommentFeedbackAction, thunkApi) => {
    const { content, value, card, isFromDM } = action;

    const state: RootState = thunkApi.getState() as RootState;

    if (state.feedback.commentsLikes[content.id]) {
      thunkApi.dispatch(
        updateCommentLocally({
          commentId: content.id,
          parentCommentId: action.parentCommentId,
          card: card,
          fields: {
            likes_count: content.likes_count - 1,
          },
        }),
      );

      if (!isWebPlatform) {
        if (isFromDM) {
          trackAnalyticsEvent(Analytics.dmAnswerDislikeComment, {
            ...mapCardToDMFromDeadPayload(card),
          });
        } else {
          trackAnalyticsEvent(Analytics.dislikeComment, {
            ...mapCardToAnalyticsPayload(card),
          });
        }
      }

      thunkApi.dispatch(
        setCommentLiked({
          content: content,
          card: card,
          value: false,
        }),
      );
    }

    try {
      await updateCommentFeedbackGraphQLCall({
        commentId: content.id,
        feedback: value ? false : null,
      });
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const likeContent = createAsyncThunk(
  `${SLICE_NAME}/likeContent`,
  async (action: FeedbackAction, thunkApi) => {
    const { content, value } = action;

    if (!isWebPlatform) {
      if (action.value) {
        if (action.isFromDM) {
          trackAnalyticsEvent(Analytics.dmAnswerLike, {
            ...mapCardToDMFromDeadPayload(content),
          });
        } else {
          trackAnalyticsEvent(Analytics.likeCard, {
            ...mapCardToAnalyticsPayload(content),
          });
        }
      }
    }
    if (action.isFromDM) {
      thunkApi.dispatch(
        setVideoLikeContent({
          content: content as DmsFromDeadCard,
          value: !!value,
        }),
      );
    } else {
      thunkApi.dispatch(setLikeContent({ content: content, value: !!value }));
    }

    thunkApi.dispatch(
      setContentListLiked({ content: content, value: !!value }),
    );

    thunkApi.dispatch(
      setCardAttributes({
        card: content,
        attributes: {
          isContentLiked: value,
        },
      }),
    );

    thunkApi.dispatch(
      syncAllAttributes({
        card: content,
        attributes: { isContentLiked: value },
      }),
    );

    try {
      if (value) {
        await addContentFeedbackGraphQLCall({
          feedbackType: FeedbackTypes.like,
          generatedContentId: content.generatedContentId,
        });
        thunkApi.dispatch(increaseLikeContentCount({ count: 1 }));
        thunkApi.dispatch(addToLikedContentList(content));
      } else {
        await removeContentFeedbackGraphQLCall({
          feedbackType: FeedbackTypes.like,
          generatedContentId: content.generatedContentId,
        });
        thunkApi.dispatch(increaseLikeContentCount({ count: -1 }));
        thunkApi.dispatch(
          subtractFromLikedContentList(content.generatedContentId),
        );
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const requestUserFeedback = createAsyncThunk(
  `${SLICE_NAME}/requestUserFeedback`,
  async (_, thunkApi) => {
    // show alert with title body and two buttons
    const state = thunkApi.getState() as RootState;
    const localState = state.feedback;

    if (localState.feedbackProvided) {
      return;
    }

    if (localState.feedbackRequestedOn) {
      if (
        moment().isBefore(
          moment(localState.feedbackRequestedOn).add(
            ASK_USER_FEEDBACK_INTERVAL_HOURS,
            'h',
          ),
        )
      ) {
        return;
      }
    }

    thunkApi.dispatch(feedbackSlice.actions.feedbackRequested());

    Alert.alert(
      locale.feedback_screen.request_feedback_title,
      locale.feedback_screen.request_feedback_description,
      [
        {
          text: locale.feedback_screen.request_feedback_yes,
          onPress: () => {
            trackAnalyticsEvent(Analytics.userLikesApp);
            StoreReview.requestReview();
            thunkApi.dispatch(feedbackSlice.actions.feedbackProvided());
          },
          isPreferred: true,
        },
        {
          text: locale.feedback_screen.request_feedback_no,
          onPress: () => {
            trackAnalyticsEvent(Analytics.userDislikesApp);
            // show alert with title body and two buttons
            Alert.alert(
              locale.feedback_screen.bad_experience_title,
              locale.feedback_screen.bad_experience_description,
              [
                {
                  text: locale.feedback_screen.bad_experience_later,
                  style: 'cancel',
                },
                {
                  text: locale.feedback_screen.bad_experience_share,
                  onPress: () => {
                    if (RootNavigatorRef.isReady()) {
                      RootNavigatorRef.navigate(
                        ScreenNames.MainStack.BETA_FEEDBACK as never,
                        {
                          feedbackType: AppFeedbackType.COMPLAINT,
                          source: BetaFeedbackSource.PROFILE,
                        } as never,
                      );
                    }

                    thunkApi.dispatch(feedbackSlice.actions.feedbackProvided());
                  },
                  isPreferred: true,
                },
              ],
              { cancelable: true },
            );
          },
        },
        {
          text: locale.feedback_screen.request_feedback_later,
          onPress: () => {
            trackAnalyticsEvent(Analytics.userSkippedRating);
          },
          style: 'cancel',
        },
      ],
      { cancelable: true },
    );
  },
);

export const bookmarkContent = createAsyncThunk(
  `${SLICE_NAME}/bookmarkContent`,
  async (action: FeedbackAction, thunkApi) => {
    const { content, value } = action;

    if (!isWebPlatform) {
      if (action.value) {
        if (action.isFromDM) {
          trackAnalyticsEvent(Analytics.dmAnswerBookmark, {
            ...mapCardToDMFromDeadPayload(content),
          });
        } else {
          trackAnalyticsEvent(Analytics.bookmarkCard, {
            ...mapCardToAnalyticsPayload(content),
          });
        }
      }
    }

    if (action.isFromDM) {
      thunkApi.dispatch(
        setVideoBookmarkContent({
          content: content as DmsFromDeadCard,
          value: !!value,
        }),
      );
    } else {
      thunkApi.dispatch(
        setBookmarkContent({ content: content, value: !!value }),
      );
    }

    thunkApi.dispatch(
      setContentListBookmarked({ content: content, value: !!value }),
    );

    thunkApi.dispatch(
      setCardAttributes({
        card: content,
        attributes: {
          isContentBookmarked: value,
        },
      }),
    );

    thunkApi.dispatch(
      syncAllAttributes({
        card: content,
        attributes: { isContentBookmarked: value },
      }),
    );

    try {
      if (value) {
        await addContentFeedbackGraphQLCall({
          feedbackType: FeedbackTypes.bookmark,
          generatedContentId: content.generatedContentId,
        });
        thunkApi.dispatch(increaseBookmarkContentCount({ count: 1 }));
        thunkApi.dispatch(addToBookmarkedContentList(content));
      } else {
        await removeContentFeedbackGraphQLCall({
          feedbackType: FeedbackTypes.bookmark,
          generatedContentId: content.generatedContentId,
        });
        thunkApi.dispatch(increaseBookmarkContentCount({ count: -1 }));
        thunkApi.dispatch(
          subtractFromBookmarkedContentList(content.generatedContentId),
        );
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

const feedbackSlice = createSlice({
  name: SLICE_NAME,
  initialState: initialState,
  reducers: {
    resetFeedbackState: state => {
      state.likedContentCount = 0;
      state.bookmarkedContentCount = 0;
      state.isNextLikeAvailable = true;
      state.isNextBookmarkAvailable = true;
      state.bookmarkLastRequestPage = 0;
      state.likeLastRequestPage = 0;
      state.bookmarksList = [];
      state.likesList = [];
    },
    setLikedContentCount: (state, action: PayloadAction<number>) => {
      state.likedContentCount = action.payload;
    },
    setBookmarkedContentCount: (state, action: PayloadAction<number>) => {
      state.bookmarkedContentCount = action.payload;
    },
    setBookmarkedList: (state, action: PayloadAction<ContentCard[]>) => {
      state.bookmarksList = action.payload;
    },
    setLikedList: (state, action: PayloadAction<ContentCard[]>) => {
      state.likesList = action.payload;
    },
    setCommentLiked: (state, action: PayloadAction<CommentFeedbackAction>) => {
      const { content, value } = action.payload;
      state.commentsLikes[content.id] = value ?? false;
    },
    setBookmarksFilterCourse: (state, action: PayloadAction<string | null>) => {
      state.bookmarksFilterCourse = action.payload;
      state.bookmarksFilterUnit = null;
    },
    setBookmarksFilterUnit: (state, action: PayloadAction<string | null>) => {
      state.bookmarksFilterUnit = action.payload;
    },
    feedbackRequested: state => {
      state.feedbackRequestedOn = moment().toISOString();
    },
    feedbackProvided: state => {
      state.feedbackProvided = true;
    },
    addToBookmarkedContentList: (state, action: PayloadAction<ContentCard>) => {
      state.bookmarksList.unshift({
        ...action.payload,
        feedId: FeedType.Favorites,
      });
    },
    subtractFromBookmarkedContentList: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.bookmarksList = state.bookmarksList.filter(
        c => c.generatedContentId !== action.payload,
      );
    },
    addToLikedContentList: (state, action: PayloadAction<ContentCard>) => {
      state.likesList.unshift({
        ...action.payload,
        feedId: FeedType.Favorites,
      });
    },
    subtractFromLikedContentList: (state, action: PayloadAction<string>) => {
      state.likesList = state.likesList.filter(
        c => c.generatedContentId !== action.payload,
      );
    },
    setNextBookmarkAvailable: (state, action: PayloadAction<boolean>) => {
      state.isNextBookmarkAvailable = action.payload;
    },
    setNextLikeAvailable: (state, action: PayloadAction<boolean>) => {
      state.isNextLikeAvailable = action.payload;
    },
    setLastBookmarkRequestPage: (state, action: PayloadAction<number>) => {
      state.bookmarkLastRequestPage = action.payload;
    },
    setLastLikeRequestPage: (state, action: PayloadAction<number>) => {
      state.likeLastRequestPage = action.payload;
    },

    setContentListLiked: (
      state,
      action: PayloadAction<{ content: ContentCard; value: boolean }>,
    ) => {
      const { content, value } = action.payload;

      const bookmarkCard = state.bookmarksList.find(
        c => c.generatedContentId === content.generatedContentId,
      );
      if (bookmarkCard) {
        if (value) {
          bookmarkCard.totalLikes += 1;
        } else if (bookmarkCard.totalLikes > 0) {
          bookmarkCard.totalLikes -= 1;
        }
      }

      const likeCard = state.likesList.find(
        c => c.generatedContentId === content.generatedContentId,
      );
      if (likeCard) {
        if (value) {
          likeCard.totalLikes += 1;
        } else if (likeCard.totalLikes > 0) {
          likeCard.totalLikes -= 1;
        }
      }
    },
    setContentListBookmarked: (
      state,
      action: PayloadAction<{ content: ContentCard; value: boolean }>,
    ) => {
      const { content, value } = action.payload;

      const bookmarkCard = state.bookmarksList.find(
        c => c.generatedContentId === content.generatedContentId,
      );
      if (bookmarkCard) {
        if (value) {
          bookmarkCard.totalBookmarks += 1;
        } else if (bookmarkCard.totalBookmarks > 0) {
          bookmarkCard.totalBookmarks -= 1;
        }
      }

      const likeCard = state.likesList.find(
        c => c.generatedContentId === content.generatedContentId,
      );
      if (likeCard) {
        if (value) {
          likeCard.totalBookmarks += 1;
        } else if (likeCard.totalBookmarks > 0) {
          likeCard.totalBookmarks -= 1;
        }
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(likeContent.pending, (state, action) => {
      const { content, value } = action.meta.arg as FeedbackAction;

      if (state.dislikes[content.generatedContentId] && value) {
        state.dislikes[content.generatedContentId] = false;
      }
    });

    builder.addCase(likeComment.pending, (state, action) => {
      const { content, value } = action.meta.arg as CommentFeedbackAction;
      state.commentsLikes[content.id] = value ?? false;
      state.commentsDislikes[content.id] = !value
        ? state.commentsDislikes[content.id]
        : false;
    });

    builder.addCase(dislikeComment.pending, (state, action) => {
      const { content, value } = action.meta.arg as CommentFeedbackAction;
      state.commentsDislikes[content.id] = value ?? false;
    });

    builder.addCase(logout.fulfilled, state => {
      return initialState;
    });

    builder.addCase(reportContent.pending, (state, action) => {
      const arg = action.meta.arg as ReportContentPayload;
      state.dislikes[arg.content.generatedContentId] = true;
    });

    builder.addCase(retrieveContent.pending, (state, action) => {
      if (action.meta.arg.interactionType === FeedbackTypes.bookmark) {
        state.isFetchingBookmarkList = true;
      } else {
        state.isFetchingLikeList = true;
      }
    });

    builder.addCase(retrieveContent.rejected, (state, action) => {
      if (action.meta.arg.interactionType === FeedbackTypes.bookmark) {
        state.isFetchingBookmarkList = false;
      } else {
        state.isFetchingLikeList = false;
      }
    });

    builder.addCase(retrieveContent.fulfilled, (state, action) => {
      if (action.meta.arg.interactionType === FeedbackTypes.bookmark) {
        state.isFetchingBookmarkList = false;
      } else {
        state.isFetchingLikeList = false;
      }
    });
  },
});

export const getFilterCourseSelector = (state: RootState): Course | null =>
  state.feedback.bookmarksFilterCourse
    ? state.courses.coursesMap[state.feedback.bookmarksFilterCourse]
    : null;

export const getFilterUnitSelector = (state: RootState): Unit | null => {
  const filterCourse = getFilterCourseSelector(state);
  if (!filterCourse) {
    return null;
  }
  return (
    filterCourse.units.find(
      unit => unit.id === state.feedback.bookmarksFilterUnit,
    ) ?? null
  );
};

export const {
  setCommentLiked,
  setBookmarksFilterCourse,
  setBookmarksFilterUnit,
  setContentListBookmarked,
  setContentListLiked,
  setLikedContentCount,
  setBookmarkedContentCount,
  setBookmarkedList,
  setLikedList,
  addToBookmarkedContentList,
  subtractFromBookmarkedContentList,
  addToLikedContentList,
  subtractFromLikedContentList,
  resetFeedbackState,
  setNextBookmarkAvailable,
  setNextLikeAvailable,
  setLastBookmarkRequestPage,
  setLastLikeRequestPage,
} = feedbackSlice.actions;

export const FeedbackSlice = persistReducer(
  persistConfig,
  feedbackSlice.reducer,
);
