import {
  APCourseGoals,
  GoalItem,
  HighSchoolCourseGoals,
  SATCourseGoals,
} from '../entities';

export const AP_COURSE_GOALS_DATA: GoalItem[] = Object.entries(
  APCourseGoals,
).map(([key, value]) => ({
  key,
  value,
}));

export const SAT_COURSE_GOALS_DATA: GoalItem[] = Object.entries(
  SATCourseGoals,
).map(([key, value]) => ({
  key,
  value,
}));

export const HIGH_SCHOOL_COURSE_GOALS_DATA: GoalItem[] = Object.entries(
  HighSchoolCourseGoals,
).map(([key, value]) => ({
  key,
  value,
}));
