import { useEffect } from 'react';
import { Keyboard } from 'react-native';

import { KeyboardListenerParams } from '../../entities';

import { useRefWithSubscribe } from './RefWithSubscribe';

export const useKeyboardListener = (params: KeyboardListenerParams): void => {
  const onKeyboardDidShowRef = useRefWithSubscribe(params.onKeyboardDidShow);
  const onKeyboardDidHideRef = useRefWithSubscribe(params.onKeyboardDidHide);
  const onKeyboardWilShowRef = useRefWithSubscribe(params.onKeyboardWillShow);

  useEffect(() => {
    const subscriptionShow = Keyboard.addListener(
      'keyboardDidShow',
      onKeyboardDidShowRef.current || (() => {}),
    );

    const subscriptionHide = Keyboard.addListener(
      'keyboardDidHide',
      onKeyboardDidHideRef.current || (() => {}),
    );

    const subscriptionWillShow = Keyboard.addListener(
      'keyboardWillShow',
      onKeyboardWilShowRef.current || (() => {}),
    );

    return () => {
      subscriptionShow?.remove();
      subscriptionHide?.remove();
      subscriptionWillShow?.remove();
    };
  }, [onKeyboardDidShowRef, onKeyboardDidHideRef, onKeyboardWilShowRef]);
};
