import LogRocket from '@logrocket/react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react-native';
import { Platform, StatusBar } from 'react-native';
import { showMessage } from 'react-native-flash-message';
import { requestTrackingPermission } from 'react-native-tracking-transparency';
import { PersistConfig, persistReducer } from 'redux-persist';

import {
  finalizeApLearnModeCoursesTimeSpent,
  getUserApProgramDetails,
} from '../../../AP/services/slices';
import {
  getAppVersion,
  isAppVersionExpired,
} from '../../../AppVersion/services/slices';
import {
  setApExamsConfig,
  setApUpcomingCoursesConfig,
} from '../../../ApResults/services/slices';
import { getUserProfile, logout } from '../../../Auth/services/slices';
import { updateUserIdentifier } from '../../../Auth/services/slices/UserProfileActions/UpdateUserIdentifierAction';
import { getTeachTapConfigurationsGraphQLCall } from '../../../Common/graphql';
import { mapRawAppConfigsData } from '../../../Common/services/mappers';
import {
  getAppConfigsFromS3,
  initCustomEnvOnAppLoad,
  setTrackingPermission,
} from '../../../Common/services/slices';
import {
  checkIfShouldSkipActionWithCoolDown,
  environment,
  getExamTypeFromString,
  isProductionEnv,
  logger,
} from '../../../Common/services/utils';
import { isWebPlatform } from '../../../Common/services/utils/AppConstants';
import {
  fetchCoursesEnrollmentCount,
  fetchEnrolledCourses,
  syncSelectedCourse,
} from '../../../CourseEnrollment/services/slices';
import { initRetrieveBookmarkedLikedContent } from '../../../Feedback/services/slices';
import {
  getAllCourses,
  getCourseProgressOnSessionInit,
  getPaywallCharacters,
  setContentMuted,
} from '../../../Learn/services/slices';
import {
  clearScheduledLocalNotification,
  getNotificationPermission,
  initNotificationListener,
} from '../../../Notification/services/slices/NotificationSlice';
import { BootcampEntryPoint } from '../../../Onboarding/entities';
import {
  checkIfUserHasEntitlement,
  setApExamTestimonials,
  setExamType,
  setSATConfigs,
} from '../../../Onboarding/services/slices';
import {
  setNextLastApExamDate,
  subscribeForPurchasesUpdate,
} from '../../../Paywall/services/slices';
import {
  getTeachTapUserSettingsGraphQLCall,
  upsertUserSettingsGraphQLCall,
} from '../../../Profile/graphql';
import { mapSignUpDataToUserSettings } from '../../../Profile/services/mappers';
import {
  getAndAssignUserFlags,
  getUserStats,
  setSignupMarketingConsent,
} from '../../../Profile/services/slices';
import {
  getUserSATProgramDetails,
  unenrollSATCourse,
} from '../../../SAT/services/slices';
import { getUserAccess } from '../../../UserAccess/services/slices';
import { TrackedUserEventType } from '../../../UserEvent/entities';
import { trackUserEvent } from '../../../UserEvent/services/slices';
import {
  AppLogRocketSlug,
  UpsertTeachTapUserSettingsParams,
} from '../../entities';
import locale from '../../locale';
import { RootState } from '../../store';
import { getAuthService } from '../amplify/AuthService';
import { getConfigService } from '../amplify/ConfigService';
import { initSentry } from '../trackers/Sentry';
import { handleNetworkActionErrorSilently } from '../utils';

import { setIsAppActive } from './AppSlice';
import { initAppsFlyer } from './AppSliceActions';

export enum LoadingItem {
  GetAllCourses = 'GET_ALL_COURSES',
  GetAllCoursesProgress = 'GET_ALL_COURSES_PROGRESS',
  RetrieveBookmarks = 'RETRIEVE_BOOKMARKS',
  GetUserProfile = 'GET_USER_PROFILE',
}

const PERSIST_KEY = 'lifecyle';
const SLICE_NAME = 'LifecycleSlice';

export const onAppGoesToBackground = createAsyncThunk(
  `${SLICE_NAME}/onAppGoesToBackground`,
  async (_, thunkApi) => {
    thunkApi.dispatch(setIsAppActive(false));
  },
);

export const onAppGoesToForeground = createAsyncThunk(
  `${SLICE_NAME}/onAppGoesToForeground`,
  async (_, thunkApi) => {
    thunkApi.dispatch(setIsAppActive(true));

    const isAuthValid = await getAuthService().isAuthenticated();

    if (isAuthValid) {
      thunkApi.dispatch(getNotificationPermission());
      thunkApi.dispatch(
        trackUserEvent({
          eventType: TrackedUserEventType.SessionInitiated,
        }),
      );

      await Promise.all([
        await thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
        await thunkApi.dispatch(getUserApProgramDetails()).unwrap(),
      ]);
    }
  },
);

export const getAppConfigs = createAsyncThunk(
  `${SLICE_NAME}/getAppConfigs`,
  async (_, thunkApi) => {
    try {
      const appConfigsRaw = await getTeachTapConfigurationsGraphQLCall();

      const { apExams, satBootcamp, apExamsTestimonials, apUpcomingCourses } =
        mapRawAppConfigsData(appConfigsRaw.configurations);

      thunkApi.dispatch(
        setSATConfigs({
          examDates: satBootcamp?.exam?.SAT?.examDates || [],
          minimumScore: satBootcamp?.exam?.SAT?.minimumScore || 0,
          competitors: satBootcamp?.bootcamp?.competitors || [],
          testimonials: satBootcamp?.bootcamp?.testimonials || [],
        }),
      );

      thunkApi.dispatch(setApExamsConfig(apExams));
      thunkApi.dispatch(setNextLastApExamDate(apExams));

      thunkApi.dispatch(setApUpcomingCoursesConfig(apUpcomingCourses));
      thunkApi.dispatch(setApExamTestimonials(apExamsTestimonials));
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const upsertTeachTapUserSettings = createAsyncThunk(
  `${SLICE_NAME}/upsertTeachTapUserSettings`,
  async (params: UpsertTeachTapUserSettingsParams | undefined, thunkApi) => {
    try {
      // If this is the first time user has set a goal and they have no enrolled courses,
      // show the onboarding.
      const rootState = thunkApi.getState() as RootState;
      const onboardingState = rootState.onboarding;
      const signupState = rootState.signup;
      const profileState = rootState.profile;

      const examType = rootState.onboarding.examType;
      if (!examType) {
        return;
      }

      await upsertUserSettingsGraphQLCall(
        mapSignUpDataToUserSettings({
          examType: examType,
          emailOptIn: profileState.marketingConsent,
          SATProgramDetails: onboardingState.SATProgramDetails,
          studentGrade: params?.noStudentGrade ? undefined : signupState.grade,
        }),
      );
      setUserSettingsCoolDown(new Date().toISOString());
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const upsertTimezoneToUserSettings = createAsyncThunk(
  `${SLICE_NAME}/upsertTimezoneToUserSettings`,
  async (_, thunkApi) => {
    try {
      const rootState = thunkApi.getState() as RootState;
      const userSettingsCoolDown = rootState.lifecycle.userSettingsCoolDown;

      const shouldSkip = checkIfShouldSkipActionWithCoolDown(
        30 * 60 * 1000,
        userSettingsCoolDown,
      );
      if (shouldSkip) {
        return;
      }

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await upsertUserSettingsGraphQLCall({
        timezone,
      });
      setUserSettingsCoolDown(new Date().toISOString());
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const getTeachTapUserSettings = createAsyncThunk(
  `${SLICE_NAME}/getTeachTapUserSettings`,
  async (_, thunkApi) => {
    try {
      const response = await getTeachTapUserSettingsGraphQLCall();
      const examType = getExamTypeFromString(response.examType);
      if (examType) {
        thunkApi.dispatch(setExamType(examType));
      }

      const emailOptIn = response.emailOptIn || false;
      thunkApi.dispatch(setSignupMarketingConsent(emailOptIn));
      return response;
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

export const syncUserDataOnAppLoad = createAsyncThunk(
  `${SLICE_NAME}/syncUserData`,
  async (_, thunkApi) => {
    try {
      const isAuthValid = await getAuthService().isAuthenticated();

      const state = thunkApi.getState() as RootState;
      const isSignedOut = state.auth.isSignedOut;

      if (!isAuthValid || isSignedOut) {
        // if the user is not logged in, invalidate any user data
        await thunkApi.dispatch(logout()).unwrap();
        return;
      }

      if (isAuthValid) {
        if (!state.auth.authUser?.id) {
          await thunkApi
            .dispatch(
              getUserProfile({
                silent: true,
              }),
            )
            .unwrap()
            .catch((e: any) => {
              // extra-checking: if the user data is not loaded, logout the user
              thunkApi.dispatch(logout());
              throw e;
            });
        }

        thunkApi.dispatch(
          trackUserEvent({
            eventType: TrackedUserEventType.SessionInitiated,
          }),
        );

        await Promise.all([
          thunkApi.dispatch(getTeachTapUserSettings()).unwrap(),
          thunkApi.dispatch(fetchEnrolledCourses()).unwrap(),
          thunkApi.dispatch(fetchCoursesEnrollmentCount()).unwrap(),
          thunkApi
            .dispatch(updateUserIdentifier({ updateAlias: true }))
            .unwrap(),
          thunkApi.dispatch(getUserSATProgramDetails()).unwrap(),
          thunkApi.dispatch(getUserApProgramDetails()).unwrap(),
          thunkApi.dispatch(getAndAssignUserFlags()).unwrap(),
          thunkApi.dispatch(getUserStats()).unwrap(),
          thunkApi.dispatch(getUserAccess()).unwrap(),
        ]);

        thunkApi.dispatch(getNotificationPermission());
        thunkApi.dispatch(initRetrieveBookmarkedLikedContent());
        thunkApi.dispatch(syncSelectedCourse({ overrideStudyListState: true }));
        thunkApi.dispatch(upsertTimezoneToUserSettings());
        thunkApi.dispatch(unenrollSATCourse());

        thunkApi.dispatch(
          checkIfUserHasEntitlement(BootcampEntryPoint.UNCLAIMED_ENTITLEMENT),
        );
        thunkApi.dispatch(finalizeApLearnModeCoursesTimeSpent());
      }
    } catch (e) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
      }
    }
  },
);

export const loadApp = createAsyncThunk(
  `${SLICE_NAME}/loadApp`,
  async (_, thunkApi) => {
    thunkApi.dispatch(setIsAppActive(true));
    await logger.clear();

    let state = thunkApi.getState() as RootState;
    const settings = state.settings;

    await environment.load();
    initSentry();
    getConfigService().setDefaultEnvironment();

    await thunkApi.dispatch(initAppsFlyer()).unwrap();

    if (!isWebPlatform && isProductionEnv()) {
      LogRocket.init(AppLogRocketSlug.DEFAULT);
    }

    // Super-special case for this action, please always prioritize the following at the top:
    // 1. Switching environment - we want to make sure all the data is properly loaded from the selected env.
    // 2. App version checking - we want to make sure the app version is up-to-date before we do any further action.
    const shouldCheckCustomEnvForDeveloperMode =
      isProductionEnv() &&
      settings.developerMode &&
      settings.useCustomEnvironment;

    // This is where: Switching environment
    if (!isProductionEnv() || shouldCheckCustomEnvForDeveloperMode) {
      await thunkApi.dispatch(initCustomEnvOnAppLoad()).unwrap();
    }

    // This is where: App version checking
    try {
      if (!isWebPlatform) {
        await thunkApi
          .dispatch(getAppVersion())
          .unwrap()
          .catch(e => e);

        state = thunkApi.getState() as RootState;

        thunkApi.dispatch(subscribeForPurchasesUpdate());

        thunkApi.dispatch(getPaywallCharacters());
      }

      StatusBar.setBarStyle('light-content', true);

      // if app version expired, do not proceed at all
      // this is to prevent any further action that might cause the app to crash

      // other than this, we can proceed with gathering the rest of the data
      // because we still expect the app to be fully functional
      if (isAppVersionExpired(state.appVersion)) {
        return;
      }

      await thunkApi.dispatch(getAppConfigsFromS3()).unwrap();

      thunkApi.dispatch(clearScheduledLocalNotification());
      thunkApi.dispatch(initNotificationListener());

      if (
        state.settings.trackingPermission === undefined &&
        Platform.OS === 'ios'
      ) {
        requestTrackingPermission()
          .then(result => {
            thunkApi.dispatch(setTrackingPermission(result));
          })
          .catch(e => {
            handleNetworkActionErrorSilently(e);
          });
      }

      thunkApi.dispatch(setContentMuted(false));
      thunkApi.dispatch(getAppConfigs());

      const blockingPromises: Promise<any>[] = [
        thunkApi.dispatch(syncUserDataOnAppLoad()).unwrap(),
      ];

      if (Object.values(state.courses.coursesMap).length === 0) {
        blockingPromises.push(thunkApi.dispatch(getAllCourses()).unwrap());
      } else {
        thunkApi.dispatch(getAllCourses());
      }

      await Promise.all(blockingPromises);

      state = thunkApi.getState() as RootState;
      if (!state.auth.isSignedOut) {
        thunkApi.dispatch(getCourseProgressOnSessionInit());
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        return thunkApi.rejectWithValue(error);
      }
    }
  },
);

type State = {
  appLoading: boolean;
  loadingItemsTimestamps: Record<LoadingItem, number>;
  deviceToken?: string;
  userGoal: Record<string, string>;
  isAppLoaded: boolean;
  isAppBusyLoading: boolean;

  // ISO string
  userSettingsCoolDown?: string;
  isAppInstalledEventFired: boolean;
};

const persistConfig = {
  key: PERSIST_KEY,
  storage: AsyncStorage,
  whitelist: ['userGoal', 'userSettingsCoolDown', 'isAppInstalledEventFired'],
  blacklist: ['isAppLoaded'],
} as PersistConfig<State>;

const initialState: State = {
  appLoading: true,
  deviceToken: undefined,
  loadingItemsTimestamps: {
    [LoadingItem.GetAllCourses]: 0,
    [LoadingItem.GetAllCoursesProgress]: 0,
    [LoadingItem.RetrieveBookmarks]: 0,
    [LoadingItem.GetUserProfile]: 0,
  },
  userGoal: {},
  isAppLoaded: false,
  isAppBusyLoading: false,
  isAppInstalledEventFired: false,
};

const slice = createSlice({
  name: 'LifecycleSlice',
  initialState: initialState,
  reducers: {
    resetLifeCycleState: state => {
      // never reset device token within this action, we'll need to handle it after unregister-call action
      state.userSettingsCoolDown = undefined;
    },
    setDeviceToken: (state, action: { payload: string }) => {
      state.deviceToken = action.payload;
    },
    resetDeviceToken: state => {
      state.deviceToken = undefined;
    },
    countLoadingItem: (state, action: { payload: LoadingItem }) => {
      const loadingItem = action.payload;
      state.loadingItemsTimestamps[loadingItem] = Date.now();
    },
    setUserSettingsCoolDown: (state, action: { payload: string }) => {
      state.userSettingsCoolDown = action.payload;
    },
    setIsAppBusyLoading: (state, action: { payload: boolean }) => {
      state.isAppBusyLoading = action.payload;
    },
    setIsAppInstalledEventFired: (state, action: { payload: boolean }) => {
      state.isAppInstalledEventFired = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(loadApp.pending, state => {
      state.appLoading = true;
      state.isAppLoaded = false;
    });
    builder.addCase(loadApp.fulfilled, state => {
      state.appLoading = false;
      state.isAppLoaded = true;
    });
    builder.addCase(loadApp.rejected, (state, action) => {
      Sentry.captureException(action.payload);
      showMessage({
        message: locale.errors.something_went_wrong,
        type: 'danger',
      });
      state.appLoading = false;
      state.isAppLoaded = true;
    });

    builder.addCase(getTeachTapUserSettings.fulfilled, (state, action) => {
      if (action.payload?.goal) {
        state.userGoal = { goal: action.payload.goal };
      }
    });
  },
});

export const {
  countLoadingItem,
  setDeviceToken,
  resetDeviceToken,
  setUserSettingsCoolDown,
  resetLifeCycleState,
  setIsAppBusyLoading,
  setIsAppInstalledEventFired,
} = slice.actions;

export const LifecycleSlice = persistReducer(persistConfig, slice.reducer);
