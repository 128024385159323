import Assets from '../../App/assets';

import CheckIcon from './icons/check.svg';
import LockIcon from './icons/lock.svg';
import APExamTargetScore from './images/ap_exam_input_target_score.svg';
import BestValueAndResultsTagIcon from './images/best_value_results_tag.svg';
import CalculatorIcon from './images/calculator.svg';
import CircleIcon from './images/circle.svg';
import CurvedArrow from './images/curved_arrow.svg';
import CurvedArrowLeft from './images/curved_arrow_left.svg';
import MoneyBackBadgeIcon from './images/money_back_badge.svg';
import NotificationBellRing from './images/notification_bell_ring.svg';
import SATExamInputDate from './images/sat_exam_input_date.svg';
import SATExamLastScore from './images/sat_exam_input_last_score.svg';
import SATExamTargetScore from './images/sat_exam_input_target_score.svg';
import ShortUnderlineIcon from './images/short_underline.svg';
import TimerIcon from './images/timer.svg';
import ZigZagArrow from './images/zig_zag_arrow.svg';
import ZigZagLine from './images/zig_zag_line.svg';

export const OnboardingAssets = {
  SATExamInputDateIcon: Assets.getVector(SATExamInputDate),
  SATExamLastScoreIcon: Assets.getVector(SATExamLastScore),
  SATExamTargetScoreIcon: Assets.getVector(SATExamTargetScore),
  NotificationBellRing: Assets.getVector(NotificationBellRing),
  NotificationSampleScreenshot: Assets.getBitmap(
    require('./images/notification_sample_screenshot.png'),
  ),
  BookAnimated: Assets.getBitmap(require('./gifs/book_animated.gif')),
  PencilCharacterAnimated: Assets.getBitmap(
    require('./gifs/pencil_character_animated.gif'),
  ),
  SATBootcampTutorial1: Assets.getBitmap(
    require('./images/sat_bootcamp_tutorial_1.png'),
  ),
  SATBootcampTutorial2: Assets.getBitmap(
    require('./images/sat_bootcamp_tutorial_2.png'),
  ),
  SATBootcampTutorial3: Assets.getBitmap(
    require('./images/sat_bootcamp_tutorial_3.png'),
  ),
  ZigZagArrowIcon: Assets.getVector(ZigZagArrow),
  ZigZagLineIcon: Assets.getVector(ZigZagLine),
  CurvedArrowIcon: Assets.getVector(CurvedArrow),
  CurvedArrowLeftIcon: Assets.getVector(CurvedArrowLeft),
  CalculatorIcon: Assets.getVector(CalculatorIcon),
  CircleIcon: Assets.getVector(CircleIcon),
  MoneyBackBadgeIcon: Assets.getVector(MoneyBackBadgeIcon),
  ShortUnderline: Assets.getVector(ShortUnderlineIcon),
  BestValueAndResultsTagIcon: Assets.getVector(BestValueAndResultsTagIcon),
  ConfettiExplosionAnimated: Assets.getBitmap(
    require('./gifs/confetti_explosion.gif'),
  ),
  CheckIcon: Assets.getVector(CheckIcon),
  LockIcon: Assets.getVector(LockIcon),
  TargetHitAnimation: Assets.getBitmap(
    require('./gifs/target_hit_animation.gif'),
  ),
  APExamTargetScoreIcon: Assets.getVector(APExamTargetScore),
  TimerIcon: Assets.getVector(TimerIcon),
  StackedBooksAnimated: Assets.getBitmap(require('./gifs/stacked_books.gif')),

  avatars: {
    AlbertEinstein: Assets.getBitmap(require('./avatars/albert_einstein.png')),
    AbrahamLincoln: Assets.getBitmap(require('./avatars/abraham_lincoln.png')),
    MartinLutherKing: Assets.getBitmap(
      require('./avatars/martin_luther_king.png'),
    ),
    WilliamShakespeare: Assets.getBitmap(
      require('./avatars/william_shakespeare.png'),
    ),
    Cleopatra: Assets.getBitmap(require('./avatars/cleopatra.png')),
    MarieCurie: Assets.getBitmap(require('./avatars/marie_curie.png')),
  },
};
