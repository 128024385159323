import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { useAppSelector } from '../../../App/services/hooks';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';

export const BootcampPaywallDetails = (): React.ReactElement => {
  const programDetails = useAppSelector(
    state => state.onboarding.SATProgramDetails,
  );

  const duration = useMemo(() => {
    return programDetails?.programDuration || 'N/A';
  }, [programDetails]);

  const deadlineToEnroll = useMemo(() => {
    return (
      programDetails?.deadlineToEnroll || locale.bootcamp_paywall.enroll_by
    );
  }, [programDetails]);

  return (
    <View style={styles.detailsSection}>
      <View style={styles.detailsBox}>
        <Text style={styles.detailsTitle}>
          {locale.bootcamp_paywall.duration_label}
        </Text>
        <Text style={styles.detailsText}>{duration}</Text>
      </View>
      <View style={styles.detailsBox}>
        <Text style={styles.detailsTitle}>
          {locale.bootcamp_paywall.enroll_label}
        </Text>
        <Text style={styles.detailsText}>{deadlineToEnroll}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  detailsSection: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: Sizes.semiMedium,
  },
  detailsBox: {
    backgroundColor: Colors.white10,
    padding: 10,
    borderRadius: 10,
    flex: 1,
    rowGap: 2,
  },
  detailsTitle: {
    ...Fonts.small,
    color: Colors.white60,
  },
  detailsText: {
    ...Fonts.semiLargeBold,
  },
});
