export enum ApResultsEntryPoint {
  APP_OPEN = 'appOpen',
  PUSH_NOTIFICATION = 'pushNotification',
}

export enum ApResultsYesNoEnum {
  YES = 'TRUE',
  NO = 'FALSE',
}

export enum ApResultsScoreEnum {
  FIVE = 5,
  FOUR = 4,
  THREE = 3,
  TWO = 2,
  ONE = 1,
  NO_TEST = 'Did not take test',
}

export type ApResultsScoreType = {
  key: keyof typeof ApResultsScoreEnum;
  value: ApResultsScoreEnum;
  label: string;
};

export type ApResultsNextInputsSummary = {
  planSat?: ApResultsYesNoEnum;
  satDate?: string;
  takingApNextYear?: ApResultsYesNoEnum;
  nextYearApCourses?: string[];
};
