import React, { useCallback, useEffect, useRef } from 'react';
import {
  findNodeHandle,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';

import locale from '../../App/locale';
import {
  Colors,
  Fonts,
  isWebPlatform,
  Sizes,
} from '../../Common/services/utils/AppConstants';

type Props = {
  title: string;
  children: React.ReactNode;
};

type AiFigureProfileListProps<T> = {
  data: T[];
  children: (props: { item: T }) => React.ReactElement;
  keyExtractor?: (item: T) => string;
};

type AiFigureProfileEmptyListProps = {
  height?: number;
};

function HorizontalWebScrollView<T>({
  children,
  keyExtractor,
  data,
}: AiFigureProfileListProps<T>): React.ReactElement {
  const scrollViewRef = useRef<ScrollView>(null);

  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleKeyExtractor = useCallback(
    (item: T) => {
      if (keyExtractor) {
        return keyExtractor(item);
      }

      return JSON.stringify(item);
    },
    [keyExtractor],
  );

  useEffect(() => {
    const slider = findNodeHandle(
      scrollViewRef.current,
    ) as unknown as HTMLDivElement;

    const mouseDown = (e: MouseEvent) => {
      isDragging.current = true;
      startX.current = e.pageX - slider.offsetLeft;
      scrollLeft.current = slider.scrollLeft;

      slider.style.cursor = 'grab';
    };
    const mouseLeave = () => {
      isDragging.current = false;
    };

    const mouseUp = () => {
      isDragging.current = false;
      slider.style.cursor = 'default';
    };

    const mouseMove = (e: MouseEvent) => {
      if (!isDragging.current) {
        return;
      }

      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX.current;
      slider.scrollLeft = scrollLeft.current - walk;
    };

    slider.addEventListener('mousedown', mouseDown);
    slider.addEventListener('mouseleave', mouseLeave);
    slider.addEventListener('mouseup', mouseUp);
    slider.addEventListener('mousemove', mouseMove);

    return () => {
      slider.removeEventListener('mousedown', mouseDown);
      slider.removeEventListener('mouseleave', mouseLeave);
      slider.removeEventListener('mouseup', mouseUp);
      slider.removeEventListener('mousemove', mouseMove);
    };
  }, []);

  return (
    <ScrollView
      ref={scrollViewRef}
      style={styles.contentContainer}
      horizontal
      nestedScrollEnabled
      showsHorizontalScrollIndicator={false}>
      {data.map(item => (
        <View key={handleKeyExtractor(item)}>{children({ item })}</View>
      ))}
    </ScrollView>
  );
}

export const AiFigureProfileListContainer = ({
  title,
  children,
}: Props): React.ReactElement => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>

      {children}
    </View>
  );
};

/**
 * A container for the list of subjects that an AI figure teaches.
 */
export function AiFigureProfileList<T>({
  children,
  keyExtractor,
  data,
}: AiFigureProfileListProps<T>): React.ReactElement {
  if (isWebPlatform) {
    return (
      <HorizontalWebScrollView data={data} keyExtractor={keyExtractor}>
        {children}
      </HorizontalWebScrollView>
    );
  }

  return (
    <FlatList
      data={data}
      renderItem={({ item }) => children({ item })}
      keyExtractor={keyExtractor}
      onEndReachedThreshold={0.1}
      contentContainerStyle={styles.contentContainer}
      horizontal
      nestedScrollEnabled
      showsHorizontalScrollIndicator={false}
    />
  );
}

export const AiFigureProfileEmptyList = ({
  height,
}: AiFigureProfileEmptyListProps): React.ReactElement => {
  return (
    <View style={[styles.emptyContainer, { height }]}>
      <Text style={styles.emptyText}>{locale.ai_profile.not_available}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { marginTop: Sizes.medium },
  title: {
    ...Fonts.small,
    color: Colors.white60,
    marginHorizontal: Sizes.semiMedium,
  },
  contentContainer: {
    paddingLeft: Sizes.semiMedium,
    paddingRight: Sizes.small,
    marginTop: Sizes.xsmall,
  },

  emptyContainer: {
    backgroundColor: Colors.white06,
    marginHorizontal: Sizes.semiMedium,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Sizes.small,
    borderWidth: 1,
    borderColor: Colors.white30,
    borderStyle: 'dashed',
  },
  emptyText: {
    ...Fonts.small,
    color: Colors.white30,
  },
});
