import React from 'react';
import { Image, StyleSheet } from 'react-native';

import { Props } from '.';

export default (props: Props): React.ReactElement => {
  return (
    <Image
      source={{
        uri: props.imageUrl,
      }}
      resizeMode="cover"
      style={[props.style, styles.box]}
    />
  );
};

const styles = StyleSheet.create({
  box: {
    opacity: 0.8,
    position: 'absolute',
  },
});
