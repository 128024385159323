import React, { useEffect, useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Asset } from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import locale from '../../../App/locale';
import { CommonLinearProgress } from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';

type Props = {
  shouldShowStats: boolean;
  imageAsset: Asset;
  title: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
};

export const SATStatsCategoryScore = (props: Props): React.ReactElement => {
  const { shouldShowStats, score, maxScore, unitsIndex } = props;

  const noScoreTextDescription = useMemo(() => {
    return locale.sat.stats_score_hidden
      .replace('${FIRST_UNIT}', `${unitsIndex?.first || 'N'}`)
      .replace('${LAST_UNIT}', `${unitsIndex?.last || 'N'}`);
  }, [unitsIndex]);

  const progressPercentage = useMemo(() => {
    const currentMaxScore = maxScore || 1;
    const currentScore = score || 0;

    return (currentScore / currentMaxScore) * 100;
  }, [score, maxScore]);

  return (
    <View style={styles.container}>
      <View style={styles.titleContainer}>
        <AssetImage
          asset={props.imageAsset}
          height={Sizes.large}
          containerStyle={styles.icon}
        />

        <Text style={styles.titleText}>{props.title}</Text>
      </View>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <View>
            <Text style={styles.scoreText}>{score}</Text>
            <CommonLinearProgress progressPercentage={progressPercentage} />
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white12,
    padding: Sizes.semiMedium,
    borderRadius: Sizes.semiMedium,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
  },
  titleText: {
    ...Fonts.semiMediumBold,
    color: 'white',
  },
  icon: {
    height: Sizes.large,
  },

  detailContainer: {
    marginTop: Sizes.xsmall,
  },
  detailText: {
    ...Fonts.semiMedium,
    color: Colors.white60,
  },

  scoreText: {
    ...Fonts.xxxlargeBold,
    color: Colors.emeraldGreen,
    marginBottom: Sizes.xsmall,
  },
});
