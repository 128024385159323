import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../App/locale';
import { useAppSelector } from '../../App/services/hooks';
import CommonCircularProgress from '../../Common/components/common/CircularProgress';
import { CommonPeriodType } from '../../Common/entities';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';
import { useSATProgramDetailsExist } from '../services/hooks';

export const SATProgramCommitment = (): React.ReactElement | null => {
  const bootcamp = useAppSelector(state => state.SAT.bootcamp);
  const doesBootcampExists = useSATProgramDetailsExist();

  const label = useMemo(() => {
    if (!bootcamp?.details?.targetTimeCommitmentPeriod) {
      return '';
    }

    const selectedCommitmentWord =
      bootcamp.details?.targetTimeCommitmentPeriod === CommonPeriodType.WEEK
        ? locale.sat.weekly
        : locale.sat.daily;

    return locale.sat.commitment_label.replace(
      '${PERIOD}',
      selectedCommitmentWord,
    );
  }, [bootcamp?.details?.targetTimeCommitmentPeriod]);

  const committedHours = useMemo(() => {
    if (!bootcamp?.details?.timeSpentMinutes) {
      return '0';
    }

    const hours = bootcamp?.details?.timeSpentMinutes / 60;

    // Floor the hours to 1 decimal place, because this is a "committed" time
    return (Math.floor(hours * 10) / 10).toString();
  }, [bootcamp?.details?.timeSpentMinutes]);

  const commitmentTargetHours = useMemo(() => {
    return locale.sat.commitment_label_hours.replace(
      '${COMMITMENT}',
      `${bootcamp?.details?.targetTimeCommitmentHours || 'N'}`,
    );
  }, [bootcamp?.details?.targetTimeCommitmentHours]);

  const commitmentPercentage = useMemo(() => {
    if (!bootcamp?.details?.targetTimeCommitmentHours) {
      return 0;
    }

    const timeSpentInHours = (bootcamp?.details?.timeSpentMinutes || 0) / 60;

    const percentage =
      (timeSpentInHours / bootcamp?.details?.targetTimeCommitmentHours) * 100;

    return Math.min(100, Math.max(0, percentage));
  }, [
    bootcamp?.details?.timeSpentMinutes,
    bootcamp?.details?.targetTimeCommitmentHours,
  ]);

  if (!doesBootcampExists) {
    return null;
  }

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>

      <View style={styles.commitmentContainer}>
        <CommonCircularProgress
          radius={10}
          color="white"
          strokeWidth={5}
          percentage={commitmentPercentage}
          strokeOpacity="100%"
          hideText
        />

        <View style={styles.commitmentTextContainer}>
          <Text style={styles.commitmentHours}>{committedHours}</Text>
          <Text style={styles.commitmentTargetHours}>
            {commitmentTargetHours}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: Sizes.medium,
    paddingVertical: Sizes.small,
    backgroundColor: Colors.lightBlue,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  label: {
    ...Fonts.small,
    color: Colors.white90,
  },

  commitmentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: Sizes.small,
    marginLeft: Sizes.small,
  },

  commitmentTextContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  commitmentHours: {
    ...Fonts.mediumBold,
    color: 'white',
  },
  commitmentTargetHours: {
    ...Fonts.small,
    color: 'white',
  },
});
