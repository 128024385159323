import store from '../../../App/store';
import { trackMastery } from '../../../Learn/services/slices';
import {
  CardType,
  ContentCard,
  FillGapsWithHelpCard,
  MatchingPairsCard,
  MultipleChoiceQuestionV2,
  TruthOrLieCard,
} from '../../entities';

import { isDevelopmentEnv } from './CommonUtil';

export const commonExposeDevCardAnswer = (
  card?: ContentCard,
  isEnabled = false,
): void => {
  if (!isDevelopmentEnv() || !isEnabled) {
    return;
  }

  if (!card) {
    console.log('[dev-card-answer]: No card provided');
    return;
  }

  if (card.type === CardType.MCQ_CARD) {
    const currentCard = card as MultipleChoiceQuestionV2;
    console.log(
      `[dev-card-answer]: MCQ Card Answer: ${currentCard.answer.correct_options[0].answer}`,
    );
    return;
  }

  if (card.type === CardType.MATCHING_PAIRS_CARD) {
    const currentCard = card as MatchingPairsCard;

    console.log('[dev-card-answer]: Matching Pairs Card Answer:');
    currentCard.leftOptions.forEach((leftOption, index) => {
      const rightOptionId =
        currentCard.pairsFromLeftOptions[leftOption.uniqueId].answerUniqueId;
      const rightOption = currentCard.rightOptions.find(
        rightOption => rightOption.uniqueId === rightOptionId,
      );

      console.log(
        `- Pair ${index + 1}: ${leftOption.text} - ${rightOption?.text}`,
      );
    });
    return;
  }

  if (card.type === CardType.FILL_IN_THE_BLANK_CARD) {
    const currentCard = card as FillGapsWithHelpCard;
    console.log(
      `[dev-card-answer]: Fill in the blank card answer: ${currentCard.possibleAnswers[0]}`,
    );
    return;
  }

  if (card.type === CardType.TRUTH_OR_LIE_CARD) {
    const currentCard = card as TruthOrLieCard;
    console.log(
      `[dev-card-answer]: Truth or Lie Card Answer: ${currentCard.answer.value}`,
    );
    return;
  }
};

export const commonAutoCorrectOnSimulator = async (
  card?: ContentCard,
  isEnabled = false,
  defaultTrackCount = 3,
): Promise<void> => {
  if (!isDevelopmentEnv() || !card || !isEnabled) {
    return;
  }

  for (let i = 0; i < defaultTrackCount; i++) {
    await store
      .dispatch(
        trackMastery({
          card: card,
          correct: true,
        }),
      )
      .unwrap();

    await new Promise(resolve => setTimeout(resolve, 2000));
  }
};
