import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { showMessage } from 'react-native-flash-message';

import locale from '../../../../App/locale';
import { getAuthService } from '../../../../App/services/amplify/AuthService';
import {
  countLoadingItem,
  getAppConfigs,
  LoadingItem,
} from '../../../../App/services/slices';
import { trackAnalyticsEvent } from '../../../../Common/services/utils';
import { Analytics } from '../../../../Common/services/utils/AppConstants';
import { BootcampEntryPoint } from '../../../../Onboarding/entities';
import { checkIfUserHasEntitlement } from '../../../../Onboarding/services/slices';
import { SignInParams } from '../../../entities';
import {
  AUTH_SLICE_NAME,
  AuthSliceState,
  handleSyncUserDataAfterSignIn,
  logout,
} from '../AuthSlice';
import { getUserProfile } from '../UserProfileActions';

export const signIn = createAsyncThunk(
  `${AUTH_SLICE_NAME}/signIn`,
  async (params: SignInParams, thunkApi) => {
    try {
      await getAuthService().signIn(
        params.username,
        params.password,
        params.saveToIcloud,
      );

      const user = await thunkApi
        .dispatch(getUserProfile({ silent: true }))
        .unwrap();
      await thunkApi.dispatch(handleSyncUserDataAfterSignIn()).unwrap();

      if (params.fromScreen) {
        trackAnalyticsEvent(Analytics.login);
      }

      thunkApi.dispatch(countLoadingItem(LoadingItem.GetUserProfile));
      thunkApi.dispatch(getAppConfigs());

      thunkApi.dispatch(
        checkIfUserHasEntitlement(BootcampEntryPoint.UNCLAIMED_ENTITLEMENT),
      );

      return user;
    } catch (e: unknown) {
      // if signin process failed due to any reason, we should invalidate all user data
      // so that the user will be forced to sign in again
      await thunkApi.dispatch(logout({ keepPreSignedInData: true })).unwrap();

      if (e instanceof Error) {
        const error: Error = e;
        if (
          error?.name === 'UserNotFoundException' ||
          error?.name === 'NotAuthorizedException'
        ) {
          showMessage({
            message: locale.errors.wrong_email_or_password,
            type: 'danger',
          });
        } else {
          showMessage({
            message: locale.errors.something_went_wrong,
            type: 'danger',
          });
        }
        return thunkApi.rejectWithValue(error?.message);
      }
    }
  },
);

export const signInExtraReducers = (
  builder: ActionReducerMapBuilder<AuthSliceState>,
): void => {
  builder.addCase(signIn.fulfilled, (state: AuthSliceState, action) => {
    state.isLoading = false;
    state.authUser = action.payload;
    state.hasBeenLoggedInBefore = true;
    state.isSignedOut = false;

    state.signInLoading = false;
    state.isLoading = false;
  });
  builder.addCase(signIn.pending, (state: AuthSliceState) => {
    state.signInLoading = true;
    state.isLoading = true;
  });
  builder.addCase(signIn.rejected, (state: AuthSliceState) => {
    state.signInLoading = false;
    state.isLoading = false;
  });
};
