import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { Asset } from '../../App/assets';
import { AssetImage } from '../../App/assets/AssetImage';
import { Colors, Fonts, Sizes } from '../../Common/services/utils/AppConstants';

interface InfoRow {
  image: Asset;
  text: string;
  size: number;
}

type RowDataProps = {
  key: number;
} & InfoRow;

type TestPreviewInfoViewProps = {
  rowData: InfoRow[];
};

export const TestPreviewInfoView = ({
  rowData,
}: TestPreviewInfoViewProps): React.ReactElement => {
  const testInfoRow = ({ image, text, key, size }: RowDataProps) => {
    return (
      <View style={styles.testInfoRow} key={key}>
        <AssetImage
          asset={image}
          height={size}
          width={size}
          fill="white"
          containerStyle={[styles.testInfoAssetContainer, { height: size }]}
        />
        <Text style={styles.testInfoText}>{text}</Text>
      </View>
    );
  };

  return (
    <View style={[styles.testInfoContainer, styles.gap20]}>
      {rowData.map((rowData, index) =>
        testInfoRow({
          image: rowData.image,
          text: rowData.text,
          key: index,
          size: rowData.size,
        }),
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  testInfoContainer: {
    backgroundColor: Colors.white10,
    borderRadius: 12,
    borderWidth: 1,
    paddingVertical: 16,
    paddingHorizontal: 14,
    borderColor: Colors.white12,
  },
  gap20: {
    gap: Sizes.normalizeIP14PM(20),
  },
  testInfoRow: {
    flexDirection: 'row',
    gap: 8,
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  testInfoAssetContainer: {
    width: Sizes.normalizeIP14PM(34),
    alignItems: 'center',
    alignSelf: 'center',
  },
  testInfoText: {
    ...Fonts.caption,
    color: Colors.white80,
    flex: 1,
  },
});
