import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';

import locale from '../../../App/locale';
import { CommonLinearProgress } from '../../../Common/components';
import {
  Colors,
  Fonts,
  Sizes,
} from '../../../Common/services/utils/AppConstants';
import { SATUnitsIndex } from '../../entities';

type Props = {
  shouldShowStats: boolean;
  title: string;
  score?: number;
  maxScore?: number;
  unitsIndex?: SATUnitsIndex;
};

export const SATProfileStatsCategoryScore = (
  props: Props,
): React.ReactElement => {
  const { shouldShowStats, score, maxScore, unitsIndex } = props;

  const noScoreTextDescription = useMemo(() => {
    return locale.sat.stats_score_hidden
      .replace('${FIRST_UNIT}', `${unitsIndex?.first || 'N'}`)
      .replace('${LAST_UNIT}', `${unitsIndex?.last || 'N'}`);
  }, [unitsIndex]);

  const progressPercentage = useMemo(() => {
    const currentMaxScore = maxScore || 1;
    const currentScore = score || 0;

    return (currentScore / currentMaxScore) * 100;
  }, [score, maxScore]);

  return (
    <View style={styles.container}>
      <Text style={styles.titleText}>{props.title}</Text>

      <View style={styles.detailContainer}>
        {!shouldShowStats && (
          <Text style={styles.detailText}>{noScoreTextDescription}</Text>
        )}

        {shouldShowStats && (
          <>
            <Text style={styles.scoreText}>{score}</Text>

            <View style={styles.scoreProgressContainer}>
              <CommonLinearProgress progressPercentage={progressPercentage} />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },

  titleText: {
    ...Fonts.smallBold,
    color: 'white',
    textAlign: 'center',
  },
  detailContainer: {
    marginTop: Sizes.xsmall,
  },
  detailText: {
    ...Fonts.small,
    textAlign: 'center',
    color: Colors.white60,
  },

  scoreProgressContainer: {
    marginTop: Sizes.xsmall,
  },
  scoreText: {
    ...Fonts.xlargeBold,
    color: Colors.emeraldGreen,
    textAlign: 'center',
  },
});
