import SrtParser2 from 'srt-parser-2';

import { CommonSubtitleData } from '../../entities';

export const getSubtitleTextForPosition = (
  subtitleData: CommonSubtitleData[],
  position: number,
): string => {
  const subtitle = subtitleData.find(
    subtitle =>
      position >= subtitle.startSeconds && position <= subtitle.endSeconds,
  );
  return subtitle?.text ?? '';
};

export const parseSubtitleStringToData = (
  subtitle: string,
): CommonSubtitleData[] => {
  if (!subtitle) {
    return [];
  }

  try {
    const parser = new SrtParser2();
    return parser.fromSrt(subtitle) as CommonSubtitleData[];
  } catch (e) {
    return [];
  }
};
