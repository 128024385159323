import { createSlice } from '@reduxjs/toolkit';

import { signUpExtraReducers } from './SignupSliceActions';

export const SIGNUP_SLICE_NAME = 'SignUpSlice';

export type SignupSliceState = {
  isLoading: boolean;
  signUpLoading: boolean;
  email: string;
  password: string;
  preferredName: string;
  grade: string;
};

export const SignupSliceInitialState: SignupSliceState = {
  isLoading: false,
  signUpLoading: false,
  email: '',
  password: '',
  preferredName: '',
  grade: '',
};

type StringPayload = {
  payload: string;
};

const signupSlice = createSlice({
  name: SIGNUP_SLICE_NAME,
  initialState: SignupSliceInitialState,
  reducers: {
    setSignupGrade: (state, action: StringPayload) => {
      state.grade = action.payload;
    },
    setSignupPassword: (state, action: StringPayload) => {
      state.password = action.payload;
    },
    setSignupPreferredName: (state, action: StringPayload) => {
      state.preferredName = action.payload;
    },
    setSignupEmail: (state, action: StringPayload) => {
      state.email = action.payload;
    },
  },
  extraReducers: builder => {
    signUpExtraReducers(builder);
  },
});

export const {
  setSignupPassword,
  setSignupPreferredName,
  setSignupEmail,
  setSignupGrade,
} = signupSlice.actions;

export const SignupSlice = signupSlice.reducer;
