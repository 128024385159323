import {
  isAppStoreBuild,
  isProductionEnv,
} from '../../../Common/services/utils';
import { PurchaseTypeBySku, RegisterPurchaseSource } from '../../entities';
import { StorePurchaseItems } from '../utils/PaywallUtils';

export const mapPurchaseSourceBasedOnAppBuild = (
  targetPurchaseSource: RegisterPurchaseSource,
): RegisterPurchaseSource => {
  // If the build is non-AppStore (potentially internal TestFlight build) and the environment is not production, always return EMPLOYEE
  return isAppStoreBuild() && isProductionEnv()
    ? targetPurchaseSource
    : RegisterPurchaseSource.EMPLOYEE;
};

export const mapPurchaseTypeBySku = (sku: string): PurchaseTypeBySku => {
  switch (sku) {
    case StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_CONSUMABLE:
    case StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_DISCOUNT_CONSUMABLE:
    case StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_CONSUMABLE:
    case StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_DISCOUNT_CONSUMABLE:
    case StorePurchaseItems.SAT_DIGITAL_BOOTCAMP_WITH_AP_PROGRAM_ADDON_CONSUMABLE:
    case StorePurchaseItems.SAT_DIGITAL_CRASH_COURSE_WITH_AP_PROGRAM_ADDON_CONSUMABLE:
      return { SAT: true, AP: false };

    case StorePurchaseItems.AP_PROGRAM_CONSUMABLE:
    case StorePurchaseItems.AP_PROGRAM_REGULAR_CONSUMABLE:
    case StorePurchaseItems.AP_PROGRAM_WITH_SAT_PREP_ADDON_CONSUMABLE:
      return { SAT: false, AP: true };
  }

  return { SAT: false, AP: false };
};
