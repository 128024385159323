import {
  CommonTeachTapConfigType,
  CommonTeachTapRawConfigType,
  TeachTapConfigKeys,
} from '../../entities';

export const mapRawAppConfigsData = (
  configs: CommonTeachTapRawConfigType[],
): CommonTeachTapConfigType => {
  const mappedConfigs: CommonTeachTapConfigType = {};
  configs.forEach(config => {
    switch (config.key) {
      case TeachTapConfigKeys.SAT_BOOTCAMP:
        mappedConfigs.satBootcamp = JSON.parse(config.value);
        break;
      case TeachTapConfigKeys.AP_EXAMS:
        mappedConfigs.apExams = JSON.parse(config.value);
        break;
      case TeachTapConfigKeys.AP_UPCOMMING_COURSES:
        mappedConfigs.apUpcomingCourses = JSON.parse(config.value);
        break;
      case TeachTapConfigKeys.AP_EXAMS_TESTIMONIALS:
        mappedConfigs.apExamsTestimonials = JSON.parse(config.value);
        break;
    }
  });

  return mappedConfigs;
};
