import React from 'react';
import { StyleSheet, ViewStyle, Pressable } from 'react-native';

type Props = {
  onPress: () => void;
  isVisible: boolean;
  style?: ViewStyle;
};

export const CommonSheetBackdrop = (
  props: Props,
): React.ReactElement | null => {
  if (!props.isVisible) {
    return null;
  }

  return (
    <Pressable style={[styles.backdrop, props.style]} onPress={props.onPress} />
  );
};

export const renderCommonSheetBackdrop = (
  props: Props,
): React.ReactElement | null => {
  return <CommonSheetBackdrop {...props} />;
};

const styles = StyleSheet.create({
  backdrop: {
    backgroundColor: 'transparent',
    flex: 1,
    height: '100%',
    position: 'absolute',
    width: '100%',
  },
});
