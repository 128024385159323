import { useIsFocused } from '@react-navigation/core';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import {
  useRefWithSubscribe,
  useStateWithRef,
} from '../../../Common/services/hooks';
import { Durations } from '../../../Common/services/utils/AppConstants';
import {
  DMSpeaker,
  MessageItemData,
  MessageItemType,
} from '../../../Messages/entities';
import {
  getDateMessageItem,
  getMessageDateTime,
} from '../../../Messages/services/utils';
import { RaiseHandScreenTypes } from '../../entities';
import {
  CHAT_HISTORY_PAGE_SIZE,
  getChatHistory,
  getTutorMessages,
  initTestPrepMathMessages,
  raiseHandAskQuestion,
  setContentScreenAndClearUnreadMessages,
  setIsFetchingInitialMessage,
  setTutorTyping,
} from '../slices';
import { generateRequestId, getRaiseHandContentGeneratorId } from '../utils';

type RaiseHandMessagingServiceReturnValues = {
  isFigureTyping: boolean;
  isHistoryLoading: boolean;
  isChatHistoryLoadingRef: MutableRefObject<boolean>;
  messages: MessageItemData[];
  handleSetContentScreen: (generatedContentId?: string) => void;
  handleSendMessage: (speaker: DMSpeaker, message: string) => void;
  handleGetChatHistory: (initial: boolean) => void;
};

const RETRY_INITIAL_CHAT_HISTORY = 8;

export const useRaiseHandMessagingService = (
  speaker: DMSpeaker,
  generatedContentId: string,
  isFromTestPrep: boolean,
  isSAT: boolean,
  isMathQuestion: boolean,
  currentQuestionLevelId?: string,
): RaiseHandMessagingServiceReturnValues => {
  const dispatch = useAppDispatch();
  const isFocused = useIsFocused();
  const [page, setPage, pageRef] = useStateWithRef(1);

  const isTutorTyping = useAppSelector(
    state => state.raiseHand.isTutorTyping[generatedContentId] || false,
  );

  const isAskingFollowUp = useAppSelector(
    state => state.raiseHand.isAskingFollowUp[generatedContentId] || false,
  );

  const isFetchingInitialMessage = useAppSelector(
    state =>
      state.raiseHand.isFetchingInitialMessage[generatedContentId] || false,
  );
  const isFetchingInitialMessageRef = useRefWithSubscribe(
    isFetchingInitialMessage,
  );

  const isChatHistoryLoading = useAppSelector(
    state => state.raiseHand.isChatHistoryLoading[generatedContentId] || false,
  );

  const messagesData = useAppSelector(
    state => getTutorMessages(state.raiseHand, generatedContentId),
    (prev, next) => prev.length === next.length && !prev.length,
  );
  const messagesDataRef = useRefWithSubscribe(messagesData);

  const questionLevelId = useMemo(
    () => (isFromTestPrep ? currentQuestionLevelId ?? '' : ''),
    [currentQuestionLevelId, isFromTestPrep],
  );

  const [
    isChatHistoryReachedEnd,
    setIsChatHistoryReachedEnd,
    isChatHistoryReachedEndRef,
  ] = useStateWithRef(false);
  const lastSpeakerContentId = useRef<string | undefined>(generatedContentId);
  const isChatHistoryLoadingRef = useRefWithSubscribe(isChatHistoryLoading);

  const managedMessagesData = useMemo(() => {
    if (!messagesData?.length) {
      return [];
    }

    const messagesDataCopy = [...messagesData];
    const sortedMessages = messagesDataCopy.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    // Algorithm to show the messages (check each line):
    // 1. find the new conversation messages
    const newConversationMessages = sortedMessages.filter(
      m => m.isNewConversation,
    );

    // 2. find the rest of the messages
    const notNewConversationMessages = sortedMessages.filter(
      m => !m.isNewConversation,
    );

    // 3. slice the rest of the messages based on the page
    // get last X messages based on the page
    const endIndex = notNewConversationMessages.length;
    let startIndex =
      endIndex - (page * CHAT_HISTORY_PAGE_SIZE + CHAT_HISTORY_PAGE_SIZE);

    if (startIndex < 0 || isChatHistoryReachedEnd) {
      startIndex = 0;
    }

    const slicedMessages = notNewConversationMessages.slice(
      startIndex,
      endIndex,
    );

    // 4. combine the new conversation messages and the sliced messages, filter to make sure we have unique-ids
    const uniqueIds: Record<string, boolean> = {};
    const slicedMessagesWithNewConversation = [
      ...newConversationMessages,
      ...slicedMessages,
    ].filter(m => {
      if (!uniqueIds[m.id]) {
        uniqueIds[m.id] = true;
        return true;
      }

      return false;
    });

    // 5. sort the combined messages
    const sortedManagedMessages = slicedMessagesWithNewConversation.sort(
      (a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      },
    );

    // 6. create a date message item for each date
    const markedDates: Record<string, boolean> = {};
    const messageItems: MessageItemData[] = sortedManagedMessages.flatMap(m => {
      const dateString = getMessageDateTime(m.date, isFocused);

      // 7. add the date message item to the message items
      if (!markedDates[dateString]) {
        markedDates[dateString] = true;
        return [
          getDateMessageItem(m.date, dateString),
          { data: m, type: MessageItemType.Message },
        ];
      }

      // 8. map the message item to the message items
      return [{ data: m, type: MessageItemType.Message }];
    });

    // 9. reverse the message items
    messageItems.reverse();

    return messageItems;
  }, [messagesData, isChatHistoryReachedEnd, page, isFocused]);

  const handleSendMessage = useCallback(
    (speaker: DMSpeaker, message: string) => {
      const trimmedMessage = message.trim();
      if (!trimmedMessage) {
        return;
      }
      dispatch(
        raiseHandAskQuestion({
          speaker,
          question: trimmedMessage,
          contentGeneratorId: getRaiseHandContentGeneratorId({
            isFromTestPrep,
            isSAT,
            isMathQuestion,
          }),
          requestId: generateRequestId(generatedContentId),
          generatedContentId: generatedContentId,
          testPrepLevelId: questionLevelId,
          fromScreen: isFromTestPrep
            ? RaiseHandScreenTypes.UnitTestQuestion
            : RaiseHandScreenTypes.StudyFeed,
        }),
      );
    },
    [
      dispatch,
      isFromTestPrep,
      isSAT,
      isMathQuestion,
      generatedContentId,
      questionLevelId,
    ],
  );

  const handleGetInitialChatHistory = useCallback(
    async (
      generatedContentId: string,
      speaker: DMSpeaker,
      retryCount: number = 0,
    ) => {
      dispatch(setTutorTyping({ generatedContentId, isTyping: true }));

      const { messages } = await dispatch(
        getChatHistory({
          generatedContentId,
          page: 0,
          speaker,
        }),
      ).unwrap();

      if (!messages.length && retryCount < RETRY_INITIAL_CHAT_HISTORY) {
        await new Promise(resolve =>
          setTimeout(resolve, Durations.defaultRetryDelay),
        );

        await handleGetInitialChatHistory(
          generatedContentId,
          speaker,
          retryCount + 1,
        );
        return;
      }

      dispatch(setTutorTyping({ generatedContentId, isTyping: false }));
    },
    [dispatch],
  );

  const handleInitTutorMessage = useCallback(async () => {
    if (isFetchingInitialMessageRef.current) {
      return;
    }

    if (isFromTestPrep && isMathQuestion) {
      await dispatch(
        initTestPrepMathMessages({
          generatedContentId,
          speakerUsername: speaker.username,
        }),
      ).unwrap();

      return;
    }

    dispatch(
      raiseHandAskQuestion({
        speaker,
        question: '',
        contentGeneratorId: getRaiseHandContentGeneratorId({
          isFromTestPrep,
          isSAT,
          isMathQuestion,
          isInitial: true,
        }),
        generatedContentId: generatedContentId,
        testPrepLevelId: questionLevelId,
        requestId: generateRequestId(generatedContentId),
        fromScreen: isFromTestPrep
          ? RaiseHandScreenTypes.UnitTestQuestion
          : RaiseHandScreenTypes.StudyFeed,
      }),
    )
      .unwrap()
      .catch(_e => {
        handleGetInitialChatHistory(generatedContentId, speaker);
      });
  }, [
    isFetchingInitialMessageRef,
    isFromTestPrep,
    isMathQuestion,
    dispatch,
    generatedContentId,
    speaker,
    isSAT,
    handleGetInitialChatHistory,
    questionLevelId,
  ]);

  const handleGetChatHistory = useCallback(
    async (initial = false) => {
      if (
        isChatHistoryLoadingRef.current ||
        isChatHistoryReachedEndRef.current
      ) {
        return;
      }

      try {
        const selectedPage = initial ? 0 : pageRef.current + 1;

        if (initial && !messagesDataRef.current.length) {
          dispatch(setTutorTyping({ generatedContentId, isTyping: true }));
        }

        setPage(selectedPage);
        const { messages, hasNextPage } = await dispatch(
          getChatHistory({
            generatedContentId: generatedContentId,
            page: selectedPage,
            speaker,
          }),
        ).unwrap();

        if (!messages.length && initial) {
          if (
            (isFromTestPrep && !messagesDataRef.current.length) ||
            !isFromTestPrep
          ) {
            handleInitTutorMessage();
          }
        } else {
          dispatch(
            setIsFetchingInitialMessage({
              generatedContentId,
              isFetching: false,
            }),
          );
          dispatch(setTutorTyping({ generatedContentId, isTyping: false }));
        }

        if (!hasNextPage) {
          setIsChatHistoryReachedEnd(true);
        }
      } catch (e) {
        console.error('Error fetching chat history', e);
      }

      // only updated when the speaker and generated-content-id changes
    },
    [
      isChatHistoryLoadingRef,
      isChatHistoryReachedEndRef,
      pageRef,
      messagesDataRef,
      setPage,
      dispatch,
      generatedContentId,
      speaker,
      isFromTestPrep,
      handleInitTutorMessage,
      setIsChatHistoryReachedEnd,
    ],
  );

  const handleSetContentScreen = useCallback(
    (generatedContentId?: string) => {
      dispatch(
        setContentScreenAndClearUnreadMessages({
          generatedContentId,
          fromScreen: isFromTestPrep
            ? RaiseHandScreenTypes.UnitTestQuestion
            : RaiseHandScreenTypes.StudyFeed,
        }),
      );
    },
    [dispatch, isFromTestPrep],
  );

  // since react-navigation doesn't unmount the screen when exploring to another page
  // we need to reset the page when the speaker changes
  useEffect(() => {
    if (lastSpeakerContentId.current !== generatedContentId) {
      setPage(0);
      setIsChatHistoryReachedEnd(false);
      lastSpeakerContentId.current = generatedContentId;
    }
  }, [setPage, setIsChatHistoryReachedEnd, generatedContentId]);

  return {
    isFigureTyping:
      isTutorTyping || isFetchingInitialMessage || isAskingFollowUp,
    isHistoryLoading: isChatHistoryLoading,
    isChatHistoryLoadingRef,
    messages: managedMessagesData,
    handleSetContentScreen,
    handleSendMessage,
    handleGetChatHistory,
  };
};
