import React, { useEffect, useMemo, useRef } from 'react';
import {
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';

import Assets, { Asset } from '../../../App/assets';
import { AssetImage } from '../../../App/assets/AssetImage';
import { useAppNavigation, useAppSelector } from '../../../App/services/hooks';
import { useAppDispatch } from '../../../App/store';
import {
  bookmarkContent,
  likeContent,
  shareContent,
} from '../../../Feedback/services/slices';
import {
  getCardAttributes,
  setHelpShown,
  switchCardVisibilityStyle,
} from '../../../Learn/services/slices';
import { DMSpeaker } from '../../../Messages/entities';
import {
  setCardFeedbackPopup,
  setCommentsPopup,
} from '../../../Popup/services/slices';
import { RaiseHandScreenTypes } from '../../../RaiseHand/entities';
import {
  setContentCardData,
  setContentDmSpeaker,
  setShouldShowTryMe,
  trackRaiseHandPress,
} from '../../../RaiseHand/services/slices';
import { startWavingAnimation } from '../../../RaiseHand/services/utils';
import { CardType, ContentCard, ContentVisibilityStyle } from '../../entities';
import { emitMediaPlaybackEvent, MediaPlayback } from '../../events';
import {
  getQuestionShareOptions,
  HapticUtil,
  redirectToAppstore,
} from '../../services/utils';
import {
  Colors,
  Fonts,
  isWebPlatform,
  ScreenNames,
  Sizes,
} from '../../services/utils/AppConstants';
import { Share } from '../../services/utils/polyfills';
import { VideoSubtitlesButtonToggle } from '../video-subtitles';

import MuteButtonView from './MuteButtonView';
import RateButtonView from './RateButtonView';

type SocialButtonProps = {
  icon: Asset;
  text?: string;
  width?: number;
  onPress?: () => void;
  isSelected?: boolean;
  selectedColor?: string;
};

const SocialButton = ({
  icon,
  text,
  onPress,
  isSelected = false,
  selectedColor,
  width = Sizes.normalizeIP14PM(35),
}: SocialButtonProps) => (
  <TouchableOpacity style={styles.socialButtonContainer} onPress={onPress}>
    <AssetImage
      asset={icon}
      height={width}
      fill={isSelected ? selectedColor : 'white'}
      imageStyle={styles.icon}
    />
    {text && <Text style={styles.socialButtonText}>{text}</Text>}
  </TouchableOpacity>
);

const Tooltip = () => {
  return (
    <View style={styles.tooltipContainer}>
      <View style={styles.tooltip}>
        <Text style={styles.tooltipText}>Try me!</Text>
      </View>
      <View style={styles.tooltipTail} />
    </View>
  );
};

type Props = {
  item: ContentCard;
  onChangeVisibilityStyle?: () => void;
  forceMutable?: boolean;
  animateHand?: boolean;
  handleRaiseHand?: () => void;
  speakerCharacter?: DMSpeaker;
};

export const ActionButtonsView = (props: Props): React.ReactElement => {
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();

  const isBookmarked = useAppSelector(
    state => getCardAttributes(state.questions, props.item).isContentBookmarked,
    (prev, next) => prev === next,
  );

  const isLiked = useAppSelector(
    state => getCardAttributes(state.questions, props.item).isContentLiked,
    (prev, next) => prev === next,
  );

  const isDisliked = useAppSelector(
    state => state.feedback.dislikes[props.item.generatedContentId],
    (prev, next) => prev === next,
  );

  const comments = useAppSelector(
    state => state.comments.comments[props.item.generatedContentId] ?? [],
  );

  const shouldShowTryMe = useAppSelector(
    state => state.raiseHand.shouldShowTryMe,
    (prev, next) => prev === next,
  );

  const visibilityStyle = useAppSelector(
    state => getCardAttributes(state.questions, props.item).visibilityStyle,
  );

  const totalLikes = props.item.totalLikes;
  const totalComments = props.item.totalComments;
  const totalBookmarks = props.item.totalBookmarks;
  const totalShares = props.item.totalShares;

  const waveAnim = useRef(new Animated.Value(1)).current;
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (props.animateHand) {
      const animation = startWavingAnimation(waveAnim, rotateAnim);
      animation.start();
      return () => animation.stop();
    } else {
      waveAnim.setValue(1);
      rotateAnim.setValue(0);
    }
    return () => {};
  }, [props.animateHand, props.item, rotateAnim, waveAnim]);

  const onChangeVisibilityStyle = () => {
    dispatch(switchCardVisibilityStyle(props.item));
  };

  const cardVisibilityIcon = (iconType: ContentVisibilityStyle): Asset => {
    switch (iconType) {
      case 'eye':
        return Assets.home.cardEyeOpenIcon;
      case 'no-text':
        return Assets.home.cardNoTextIcon;
    }
  };

  const onShare = async (): Promise<void> => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    try {
      await Share.open(getQuestionShareOptions(props.item));
      dispatch(
        shareContent({
          content: props.item,
        }),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const onPressBookmark = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    if (!isBookmarked) {
      HapticUtil.triggerLight();
    }

    dispatch(
      bookmarkContent({
        content: props.item,
        value: !isBookmarked,
      }),
    );
  };

  const onPressDislike = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    emitMediaPlaybackEvent({
      uniqueId: props.item.uniqueId,
      status: MediaPlayback.PAUSE,
    });

    HapticUtil.triggerMedium();
    dispatch(
      setCardFeedbackPopup({
        item: props.item,
      }),
    );
  };

  const onPressLike = async (): Promise<void> => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    if (!isLiked) {
      HapticUtil.triggerLight();
    }

    dispatch(
      likeContent({
        content: props.item,
        value: !isLiked,
      }),
    );
  };

  const onPressComment = (): void => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    emitMediaPlaybackEvent({
      uniqueId: props.item.uniqueId,
      status: MediaPlayback.PAUSE,
    });

    dispatch(
      setCommentsPopup({
        item: props.item,
      }),
    );
  };

  const isFullscreenVideo = useMemo(() => {
    return !!props.item.videoUrl;
  }, [props.item.videoUrl]);

  const shouldShowRaiseHand = useMemo(() => {
    return !isFullscreenVideo && props.item.type !== CardType.MEME_CARD;
  }, [isFullscreenVideo, props.item]);

  const raiseHandStyle = {
    transform: [
      { scale: waveAnim },
      {
        rotate: rotateAnim.interpolate({
          inputRange: [-1, 1],
          outputRange: ['-10deg', '10deg'],
        }),
      },
    ],
  };

  const onRaiseHandPress = () => {
    if (isWebPlatform) {
      redirectToAppstore();
      return;
    }

    if (props.speakerCharacter) {
      dispatch(setShouldShowTryMe(false));

      dispatch(
        trackRaiseHandPress({
          contentCard: props.item,
          from: RaiseHandScreenTypes.StudyFeed,
        }),
      );
      dispatch(setContentCardData({ card: props.item }));
      dispatch(
        setContentDmSpeaker({
          generatedContentId: props.item.generatedContentId,
          speaker: props.speakerCharacter,
        }),
      );

      dispatch(
        setHelpShown({
          item: props.item,
        }),
      );

      navigation.navigate(ScreenNames.MainStack.MESSAGE_MAIN, {
        screen: ScreenNames.MessageStack.RAISE_HAND_MESSAGE_SCREEN,
        params: {
          speaker: props.speakerCharacter,
          generatedContentId: props.item.generatedContentId,
          courseId: props.item?.course?.id,
        },
      });
    }

    if (props.handleRaiseHand) {
      props.handleRaiseHand();
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.avatarMargin}>
        {isFullscreenVideo && (
          <>
            <RateButtonView style={styles.indicatorButton} />
            <MuteButtonView
              iconSize={Sizes.normalizeIP14PM(30)}
              style={styles.indicatorButton}
            />

            {props.item.subtitleUrl && (
              <VideoSubtitlesButtonToggle style={styles.indicatorButton} />
            )}
          </>
        )}

        {shouldShowRaiseHand && (
          <TouchableOpacity
            style={styles.raiseHandContainer}
            onPress={onRaiseHandPress}>
            <Animated.View style={raiseHandStyle}>
              <SocialButton
                width={Sizes.normalizeIP14PM(30)}
                icon={Assets.home.raiseHandIcon}
                onPress={onRaiseHandPress}
              />
            </Animated.View>
            {shouldShowTryMe && props.animateHand && <Tooltip />}
          </TouchableOpacity>
        )}
      </View>

      {props.item.visibilityStyleSwitch && (
        <SocialButton
          icon={cardVisibilityIcon(visibilityStyle)}
          width={Sizes.normalizeIP14PM(30)}
          onPress={onChangeVisibilityStyle}
        />
      )}
      <SocialButton
        icon={Assets.home.likeIcon}
        isSelected={isLiked}
        selectedColor={Colors.additional.red[100]}
        onPress={onPressLike}
        text={`${totalLikes}`}
      />
      {!isWebPlatform && (
        <SocialButton
          icon={Assets.home.commentsIcon}
          onPress={onPressComment}
          text={`${comments.length > 0 ? comments.length : totalComments}`}
        />
      )}
      <SocialButton
        icon={Assets.home.bookmarkIcon}
        selectedColor={Colors.actionGreen}
        isSelected={isBookmarked}
        onPress={onPressBookmark}
        text={`${totalBookmarks}`}
      />
      <SocialButton
        icon={Assets.home.shareIcon}
        onPress={onShare}
        text={`${totalShares}`}
      />
      <SocialButton
        width={Sizes.normalizeIP14PM(24)}
        icon={Assets.home.thumbDownFilledIcon}
        selectedColor={Colors.answerWrong}
        isSelected={isDisliked}
        onPress={onPressDislike}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  avatarMargin: {
    marginBottom: Sizes.normalizeIP14PM(15),
    justifyContent: 'center',
    alignSelf: 'center',
    rowGap: 2,
  },
  avatarPlusIcon: {
    bottom: -10,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginEnd: 6,
    marginStart: Sizes.normalizeIP14PM(12),
    maxWidth: 50,
  },
  icon: {
    shadowColor: 'rgba(0, 0, 0, 1)',
    shadowOffset: {
      width: 0,
      height: 0.5,
    },
    shadowOpacity: 0.5,
    shadowRadius: 1,
  },
  paperButtonContainer: {
    alignItems: 'center',
    marginBottom: 16,
  },
  socialButtonContainer: {
    alignItems: 'center',
    rowGap: 2,
    paddingBottom: Sizes.normalizeIP14PM(10),
  },
  socialButtonText: {
    ...Fonts.smallBold,
    fontSize: Fonts.smallBold.fontSize - 1,
    marginTop: 0,
  },
  percentage: {
    ...Fonts.xsmallBold,
    textAlign: 'left',
    alignSelf: 'flex-end',
    fontSize: 9,
  },
  percentageContainer: {
    flexDirection: 'row',
    columnGap: 0.5,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    bottom: isWebPlatform ? 3 : 0,
  },
  unfollowedPercentageContainer: {
    top: 8,
  },
  indicatorButton: {
    marginBottom: Sizes.semiMedium,
  },
  raiseHandContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    borderRadius: 8,
    paddingTop: 5,
    flexDirection: 'row',
    width: Sizes.normalizeIP14PM(50),
    height: Sizes.normalizeIP14PM(50),
  },
  tooltipContainer: {
    position: 'absolute',
    bottom: 0,
    right: '100%',
    marginRight: 10,
  },
  tooltip: {
    backgroundColor: Colors.tryMeToolTipBlueColor,
    borderRadius: 10,
    padding: 10,
  },
  tooltipText: {
    ...Fonts.smallBold,
    textAlign: 'center',
  },
  tooltipTail: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderLeftWidth: 10,
    borderRightWidth: 0,
    borderTopWidth: 5,
    borderBottomWidth: 5,
    borderLeftColor: Colors.tryMeToolTipBlueColor,
    borderTopColor: 'transparent',
    borderBottomColor: 'transparent',
    position: 'absolute',
    right: -10,
    top: '50%',
    marginTop: -5,
  },
});
