import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PersistConfig, persistReducer } from 'redux-persist';

import locale from '../../../App/locale';
import {
  handleNetworkActionError,
  handleNetworkActionErrorSilently,
} from '../../../App/services/utils';
import store, { RootState } from '../../../App/store';
import {
  Card,
  ContentCard,
  DmsFromDeadV2Card,
  FeedType,
} from '../../../Common/entities';
import {
  mapCardTypeToAIContentType,
  mapGenerateQuestionResponseToContentCard,
} from '../../../Common/services/mappers';
import {
  selectRandomFromArray,
  trackAnalyticsEvent,
} from '../../../Common/services/utils';
import { Analytics } from '../../../Common/services/utils/AppConstants';
import { FeedbackTypes } from '../../../Feedback/entities';
import {
  addContentFeedbackGraphQLCall,
  removeContentFeedbackGraphQLCall,
} from '../../../Feedback/graphql';
import { recordContentViewGraphQLCall } from '../../../Learn/graphql';
import { MessagesAlgorithm } from '../../../Messages/algorithm';
import {
  DMSpeaker,
  MessageItem,
  PrefillThreshold,
  UserType,
} from '../../../Messages/entities';
import { askDMFigureGraphQLCall } from '../../../Messages/graphql';
import {
  AssignMessagePayload,
  DeleteUnreadMessagePayload,
  GetChatHistoryPayload,
  InitTestPrepMessagePayload,
  RaiseHandRequestParams,
  RaiseHandScreenTypes,
  RaiseHandUserInteractionHistory,
  SaveDmsFromTheDeadCard,
  SetContentDmSpeakerPayload,
  SetIsFetchingInitialMessagePayload,
  SetRaiseHandDraftMessagePayload,
  SetSpeakerScreenPayload,
  SetTutorTypingPayload,
  SetUnreadMessagePayload,
  SetUserInteractionHistoryPayload,
  TrackRaiseHandAction,
} from '../../entities';
import { getRaiseHandChatHistoryGraphQLCall } from '../../graphql';
import {
  mapCardToRaiseHandPayload,
  mapDmsSpeakerWithContentToPayload,
  mapRaiseHandAskRequest,
  mapRaiseHandToPayload,
} from '../mappers';
import {
  checkIfContentGeneratorIsInitial,
  generateRaiseHandMessageId,
  isAskingFollowUpQuestion,
} from '../utils';

import {
  stopStreamingRequestId,
  streamGeneratedContent,
} from './StreamingSlice';

type State = {
  isLoading: boolean;
  error: string | null;
  // key: generatedContentId, value: tutor messages
  messages: Record<string, MessageItem[]>;

  // key: generatedContentId, value: draft message
  draftMessage: Record<string, string>;

  // key: generatedContentId, value: generatedContentId[]
  unreadMessages: Record<string, string[]>;

  // key: generatedContentId, value: ContentCard
  contentCardData: Record<string, ContentCard>;

  // key: generatedContentId, value: DMSpeaker
  contentDmSpeaker: Record<string, DMSpeaker>;

  // key: generatedContentId, value: RaiseHandUserInteractionHistory[]
  userInteractionHistory: Record<string, RaiseHandUserInteractionHistory[]>;

  // key: generatedContentId, value: ChatFeedback
  likeStatus: Record<string, FeedbackTypes>;

  dmsFromTheDead: Record<string, DmsFromDeadV2Card[]>;
  isTutorTyping: Record<string, boolean>;
  isChatHistoryLoading: Record<string, boolean>;
  currentContentScreen?: string;

  shouldShowTryMe: boolean;

  // key: generatedContentId, value: boolean
  isFetchingInitialMessage: Record<string, boolean>;
  isAskingFollowUp: Record<string, boolean>;
};

const SLICE_NAME = 'RaiseHandSlice';

const persistConfig = {
  key: SLICE_NAME,
  storage: AsyncStorage,
  whitelist: [
    'dmsFromTheDead',
    'messages',
    'draftMessage',
    'unreadMessages',
    'contentCardData',
    'userInteractionHistory',
    'shouldShowTryMe',
    'contentDmSpeaker',
    'likeStatus',
    'isFetchingInitialMessage',
  ],
  blacklist: ['currentContentScreen', 'isAskingFollowUp'],
} as PersistConfig<State>;

const initialState: State = {
  isLoading: false,
  error: null,
  dmsFromTheDead: {},

  likeStatus: {},

  contentCardData: {},
  contentDmSpeaker: {},
  userInteractionHistory: {},
  unreadMessages: {},

  messages: {},
  draftMessage: {},
  isTutorTyping: {},
  isChatHistoryLoading: {},
  currentContentScreen: undefined,
  shouldShowTryMe: true,
  isFetchingInitialMessage: {},
  isAskingFollowUp: {},
};

export const CHAT_HISTORY_PAGE_SIZE = 50;

export const initTestPrepMathMessages = createAsyncThunk(
  `${SLICE_NAME}/initTestPrepMathMessages`,
  async (props: InitTestPrepMessagePayload, thunkApi) => {
    const { generatedContentId, speakerUsername } = props;

    const rootState = thunkApi.getState() as RootState;
    const state = rootState.raiseHand;

    if (state.messages[generatedContentId]?.length > 0) {
      return;
    }

    const currentTest = rootState.testPrep.currentTest;
    if (!currentTest) {
      return;
    }

    const selectedQuestion = currentTest.questions.find(
      question => question.generatedContentId === generatedContentId,
    );

    if (!selectedQuestion?.initialTutorMessage) {
      return;
    }

    const mathPregeneratedMessages =
      rootState.testPrep.mathPregeneratedMessages;
    if (!mathPregeneratedMessages?.length) {
      return;
    }

    thunkApi.dispatch(setTutorTyping({ generatedContentId, isTyping: true }));
    thunkApi.dispatch(
      setIsFetchingInitialMessage({ generatedContentId, isFetching: true }),
    );

    await new Promise(resolve => setTimeout(resolve, 1000));

    const newMessage: MessageItem = {
      sender: UserType.dmFigure,
      message: selectedQuestion.initialTutorMessage,
      date: new Date().toISOString(),
      speakerUsername: speakerUsername,
      id: generateRaiseHandMessageId(
        generatedContentId,
        UserType.dmFigure,
        new Date().toISOString(),
      ),
      generatedContentId: generatedContentId,
      isNewConversation: true,
    };

    trackAnalyticsEvent(Analytics.pregeneratedTestPrepMessage, {
      pregeneratedMessage: newMessage.message,
      tutor: newMessage.speakerUsername,
      sender: newMessage.sender,
      contentId: generatedContentId,
    });

    thunkApi.dispatch(
      assignRaiseHandMessage({ message: newMessage, generatedContentId }),
    );

    await new Promise(resolve => setTimeout(resolve, 2000));

    const bufferMessage: MessageItem = {
      sender: UserType.dmFigure,
      message: selectRandomFromArray(mathPregeneratedMessages),
      date: new Date().toISOString(),
      speakerUsername: speakerUsername,
      id: generateRaiseHandMessageId(
        generatedContentId,
        UserType.dmFigure,
        new Date().toISOString(),
      ),
      isNewConversation: true,
    };

    trackAnalyticsEvent(Analytics.pregeneratedTestPrepMessage, {
      pregeneratedMessage: bufferMessage.message,
      tutor: bufferMessage.speakerUsername,
      sender: bufferMessage.sender,
      contentId: generatedContentId,
    });

    thunkApi.dispatch(
      assignRaiseHandMessage({ message: bufferMessage, generatedContentId }),
    );

    thunkApi.dispatch(setTutorTyping({ generatedContentId, isTyping: false }));
    thunkApi.dispatch(
      setIsFetchingInitialMessage({ generatedContentId, isFetching: false }),
    );
  },
);

export const trackRaiseHandPress = createAsyncThunk(
  `${SLICE_NAME}/trackRaiseHandAction`,
  async (payload: TrackRaiseHandAction, thunkApi) => {
    const { contentCard, from } = payload;

    const state = thunkApi.getState() as RootState;
    const userInteractions =
      state.raiseHand.userInteractionHistory[contentCard.generatedContentId] ||
      [];

    trackAnalyticsEvent(Analytics.raiseHand, {
      contentId: contentCard.generatedContentId,
      contentType: mapCardTypeToAIContentType(contentCard.type),
      userInteractions,
      from,
    });
  },
);

export const feedbackRaiseHandMessage = createAsyncThunk(
  `${SLICE_NAME}/likeRaiseHandMessage`,
  async (
    payload: {
      generatedContentId: string;
      feedbackType: FeedbackTypes;
      fromScreen: RaiseHandScreenTypes;
    },
    thunkApi,
  ) => {
    const { generatedContentId, feedbackType, fromScreen } = payload;

    const state = thunkApi.getState() as RootState;
    const value = state.raiseHand.likeStatus[payload.generatedContentId];

    if (value !== feedbackType) {
      await addContentFeedbackGraphQLCall({
        feedbackType,
        generatedContentId,
      });

      trackAnalyticsEvent(Analytics.raiseHandMessageFeedback, {
        contentId: payload.generatedContentId,
        feedbackType: value,
        from: fromScreen,
      });

      return feedbackType;
    }

    await removeContentFeedbackGraphQLCall({
      feedbackType,
      generatedContentId: payload.generatedContentId,
    });

    trackAnalyticsEvent(Analytics.raiseHandMessageFeedback, {
      contentId: payload.generatedContentId,
      feedbackType: value,
      from: fromScreen,
    });

    return null;
  },
);

export const setContentScreenAndClearUnreadMessages = createAsyncThunk(
  `${SLICE_NAME}/setContentScreenAndClearUnreadMessages`,
  async (payload: SetSpeakerScreenPayload, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const lastContentScreen = state.raiseHand.currentContentScreen;
    const generatedContentId = payload.generatedContentId;

    thunkApi.dispatch(setContentScreen(payload));

    if (!generatedContentId) {
      if (lastContentScreen) {
        const contentCard = state.raiseHand.contentCardData[lastContentScreen];
        const speaker = state.raiseHand.contentDmSpeaker[lastContentScreen];

        if (payload.fromScreen === RaiseHandScreenTypes.UnitTestQuestion) {
          trackAnalyticsEvent(
            Analytics.closeRaiseHandMessageScreen,
            mapDmsSpeakerWithContentToPayload(
              speaker,
              lastContentScreen,
              payload.fromScreen,
            ),
          );
        } else {
          if (!contentCard || !speaker) {
            return;
          }
          trackAnalyticsEvent(
            Analytics.closeRaiseHandMessageScreen,
            mapDmsSpeakerWithContentToPayload(
              speaker,
              contentCard.generatedContentId,
              payload.fromScreen,
            ),
          );
        }
      }

      return;
    }

    const contentCard = state.raiseHand.contentCardData[generatedContentId];
    const speaker = state.raiseHand.contentDmSpeaker[generatedContentId];

    if (speaker) {
      trackAnalyticsEvent(
        Analytics.openRaiseHandMessageScreen,
        mapDmsSpeakerWithContentToPayload(
          speaker,
          generatedContentId ?? contentCard.generatedContentId ?? '',
          payload.fromScreen,
        ),
      );
    }

    const unreadMessages = [
      ...(state.raiseHand.unreadMessages[generatedContentId] || []),
    ];
    if (!unreadMessages?.length) {
      return;
    }

    for (let i = 0; i < unreadMessages.length; i++) {
      const generatedContentId = unreadMessages[i];
      if (generatedContentId) {
        recordContentViewGraphQLCall(generatedContentId).catch(e => {
          const error: Error = e;
          handleNetworkActionErrorSilently(error);
        });
      }
    }

    thunkApi.dispatch(deleteUnreadMessage({ generatedContentId }));
  },
);

export const getChatHistory = createAsyncThunk(
  `${SLICE_NAME}/getChatHistory`,
  async (payload: GetChatHistoryPayload, thunkApi) => {
    const { speaker, page, generatedContentId } = payload;

    try {
      const response = await getRaiseHandChatHistoryGraphQLCall({
        keyFigureName: speaker.name,
        generatedContentId,
        page,
      });

      const messages: MessageItem[] = response.chatHistory.map(
        chatHistoryItem => {
          const sender = chatHistoryItem.senderUserId
            ? UserType.user
            : UserType.dmFigure;

          return {
            sender,
            date: chatHistoryItem.timestamp,
            speakerUsername: speaker.username,
            message: chatHistoryItem.message,
            id: generateRaiseHandMessageId(
              generatedContentId,
              sender,
              chatHistoryItem.timestamp,
            ),
            generatedContentId: chatHistoryItem.platformGeneratedContentId,
            isNewConversation: false,
            isVideoMessage: false,
            showDate: false,
          };
        },
      );

      return { messages, hasNextPage: response.hasNextPage };
    } catch (e: unknown) {
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionError(error);
        return thunkApi.rejectWithValue(e.message);
      } else {
        return thunkApi.rejectWithValue(locale.errors.unknown_error);
      }
    }
  },
);

export const raiseHandAskQuestion = createAsyncThunk(
  `${SLICE_NAME}/raiseHandAskQuestion`,
  async (
    {
      speaker,
      question,
      contentGeneratorId,
      generatedContentId,
      testPrepLevelId,
      fromScreen,
      requestId,
    }: RaiseHandRequestParams,
    thunkApi,
  ) => {
    let state = thunkApi.getState() as RootState;

    const currentTest = store.getState().testPrep.currentTest;
    const selectedContentCard =
      state.raiseHand.contentCardData[generatedContentId];

    const userInteractionHistory =
      state.raiseHand.userInteractionHistory[generatedContentId] || [];

    if (checkIfContentGeneratorIsInitial(contentGeneratorId)) {
      thunkApi.dispatch(
        resetChatHistoryForGeneratedContentId({ generatedContentId }),
      );
    }

    const courseId = selectedContentCard
      ? selectedContentCard.course?.id
      : currentTest?.course.id;

    const levelId = selectedContentCard
      ? selectedContentCard.levelId ?? ''
      : testPrepLevelId ?? '';

    thunkApi.dispatch(
      streamGeneratedContent({
        generatedContentId,
        requestId,
        speaker,
      }),
    );

    const request = mapRaiseHandAskRequest(
      speaker.platformId,
      contentGeneratorId,
      generatedContentId,
      userInteractionHistory,
      question,
      courseId as string,
      levelId as string,
      PrefillThreshold.generateNewRequest,
      requestId,
    );

    trackAnalyticsEvent(Analytics.raiseHandAskTutorQuestion, {
      ...mapRaiseHandToPayload(
        question,
        {
          username: speaker.username,
          name: speaker.name,
        },
        generatedContentId,
        contentGeneratorId,
        fromScreen,
      ),
    });

    try {
      const response = await askDMFigureGraphQLCall(request);
      thunkApi.dispatch(stopStreamingRequestId({ requestId }));

      const allCourses = Object.values(state.courses.coursesMap);

      const course =
        state.courses.coursesMap[response.courseId] || allCourses[0];

      const selectedUnit =
        course.units.find(unit => unit.id === selectedContentCard?.unit?.id) ||
        course.units[0];

      const selectedTopic =
        selectedUnit.topics.find(
          topic => topic.id === selectedContentCard?.topic?.id,
        ) || selectedUnit.topics[0];

      const card = mapGenerateQuestionResponseToContentCard({
        course,
        unit: selectedUnit,
        response,
        topic: selectedTopic,
        feedId: FeedType.Messages,
      }) as DmsFromDeadV2Card;

      thunkApi.dispatch(
        saveDmsFromTheDeadCard({
          generatedContentId: generatedContentId,
          card,
        }),
      );

      state = thunkApi.getState() as RootState;
      const isCurrentContentScreenOpen =
        state.raiseHand.currentContentScreen === generatedContentId;

      if (isCurrentContentScreenOpen) {
        if (card.generatedContentId) {
          recordContentViewGraphQLCall(card.generatedContentId).catch(e => {
            const error: Error = e;
            handleNetworkActionErrorSilently(error);
          });
        }
      } else {
        thunkApi.dispatch(
          setUnreadMessage({
            targetGeneratedContentId: generatedContentId,
            generatedContentId: card.generatedContentId,
          }),
        );
      }

      trackAnalyticsEvent(Analytics.answerRaiseHandQuestion, {
        ...mapCardToRaiseHandPayload(
          card as Card,
          speaker,
          generatedContentId,
          fromScreen,
          contentGeneratorId,
        ),
      });

      return { card };
    } catch (e: unknown) {
      const state = thunkApi.getState() as RootState;
      const isStreaming = state.streaming.activeRequests.includes(requestId);
      if (e instanceof Error) {
        const error: Error = e;
        handleNetworkActionErrorSilently(error);
        return thunkApi.rejectWithValue({
          error: error.message,
          isStreaming,
        });
      } else {
        return thunkApi.rejectWithValue({
          error: locale.errors.error_generating_response_video,
          isStreaming,
        });
      }
    }
  },
);

const raiseHandSlice = createSlice({
  name: 'RaiseHandSlice',
  initialState: initialState,
  reducers: {
    clearRaiseHandMessageData: (state: State) => {
      state.isLoading = false;
      state.error = null;
      state.messages = {};
      state.dmsFromTheDead = {};
      state.draftMessage = {};
      state.isTutorTyping = {};
      state.isChatHistoryLoading = {};
      state.currentContentScreen = undefined;
      state.unreadMessages = {};
      state.contentCardData = {};
      state.userInteractionHistory = {};
      state.shouldShowTryMe = true;
      state.likeStatus = {};
    },
    setRaiseHandDraftMessage: (
      state: State,
      action: { payload: SetRaiseHandDraftMessagePayload },
    ) => {
      const { generatedContentId, message } = action.payload;
      state.draftMessage[generatedContentId] = message;
    },
    setTestPrepInitialMessage: (
      state: State,
      action: { payload: { message: MessageItem; generatedContentId: string } },
    ) => {
      const message = action.payload.message;
      const generatedContentId = action.payload.generatedContentId;
      if (
        state.messages[generatedContentId] &&
        state.messages[generatedContentId].length > 0
      ) {
        state.messages[generatedContentId].push(message);
      } else {
        state.messages[generatedContentId] = [message];
      }
      state.isTutorTyping[generatedContentId] = false;
      state.isFetchingInitialMessage[generatedContentId] = false;
      trackAnalyticsEvent(Analytics.pregeneratedTestPrepMessage, {
        pregeneratedMessage: message.message,
        tutor: message.speakerUsername,
        sender: message.sender,
        contentId: generatedContentId,
      });
    },
    assignRaiseHandMessage: (
      state: State,
      action: PayloadAction<AssignMessagePayload>,
    ) => {
      const { message, generatedContentId } = action.payload;
      const currentMessages = state.messages[generatedContentId] ?? [];
      state.messages[generatedContentId] = [...currentMessages, message];
    },
    setContentScreen: (
      state: State,
      action: { payload: SetSpeakerScreenPayload },
    ) => {
      // Algorithm on handling this:
      // 1. set the current speaker screen
      const generatedContentId = action.payload.generatedContentId;
      state.currentContentScreen = generatedContentId;

      if (generatedContentId && state.messages[generatedContentId]?.length) {
        const messageCopy = [...state.messages[generatedContentId]];

        // 2. set the new conversation flag to false, because when we re-open the chat, we expect the user to read the messages
        // based on the latest pagination (which may be updated from backend).
        messageCopy.forEach(message => {
          message.isNewConversation = false;
        });
      }
    },
    saveDmsFromTheDeadCard: (
      state: State,
      action: { payload: SaveDmsFromTheDeadCard },
    ) => {
      const { generatedContentId, card } = action.payload;
      const dmsFromTheDead = state.dmsFromTheDead[generatedContentId] ?? [];

      const existingCardIndex = dmsFromTheDead.findIndex(
        existingCard =>
          existingCard.generatedContentId === card.generatedContentId,
      );

      if (existingCardIndex === -1) {
        state.dmsFromTheDead[generatedContentId] = [...dmsFromTheDead, card];
      }
    },
    setUnreadMessage: (
      state: State,
      action: { payload: SetUnreadMessagePayload },
    ) => {
      const { generatedContentId, targetGeneratedContentId } = action.payload;
      if (!generatedContentId || !targetGeneratedContentId) {
        return;
      }

      const unreadMessages =
        state.unreadMessages[targetGeneratedContentId] ?? [];

      if (!unreadMessages.includes(generatedContentId)) {
        state.unreadMessages[targetGeneratedContentId] = [
          ...unreadMessages,
          generatedContentId,
        ];
      }
    },
    deleteUnreadMessage: (
      state: State,
      action: { payload: DeleteUnreadMessagePayload },
    ) => {
      const { generatedContentId } = action.payload;
      if (state.unreadMessages[generatedContentId]) {
        delete state.unreadMessages[generatedContentId];
      }
    },
    setContentCardData: (
      state: State,
      action: { payload: { card: ContentCard } },
    ) => {
      const { card } = action.payload;
      state.contentCardData[card.generatedContentId] = card;
    },
    setContentDmSpeaker: (
      state: State,
      action: PayloadAction<SetContentDmSpeakerPayload>,
    ) => {
      const { speaker, generatedContentId } = action.payload;
      state.contentDmSpeaker[generatedContentId] = speaker;
    },
    setShouldShowTryMe: (state: State, action: { payload: boolean }) => {
      state.shouldShowTryMe = action.payload;
    },
    setUserInteractionHistory: (
      state: State,
      action: PayloadAction<SetUserInteractionHistoryPayload>,
    ) => {
      const { generatedContentId, userInteractionHistory } = action.payload;
      const currentInteractionHistory =
        state.userInteractionHistory[generatedContentId] ?? [];

      state.userInteractionHistory[generatedContentId] = [
        ...currentInteractionHistory,
        userInteractionHistory,
      ];
    },
    resetChatHistoryForGeneratedContentId: (
      state: State,
      action: PayloadAction<{ generatedContentId: string }>,
    ) => {
      const { generatedContentId } = action.payload;
      state.messages[generatedContentId] = [];
    },
    setTutorTyping: (
      state: State,
      action: PayloadAction<SetTutorTypingPayload>,
    ) => {
      const { generatedContentId, isTyping } = action.payload;
      state.isTutorTyping[generatedContentId] = isTyping;
    },
    setIsFetchingInitialMessage: (
      state: State,
      action: PayloadAction<SetIsFetchingInitialMessagePayload>,
    ) => {
      const { generatedContentId, isFetching } = action.payload;
      state.isFetchingInitialMessage[generatedContentId] = isFetching;
    },
  },
  extraReducers: builder => {
    builder.addCase(raiseHandAskQuestion.pending, (state: State, action) => {
      const { generatedContentId, question, speaker, contentGeneratorId } =
        action.meta.arg;

      if (checkIfContentGeneratorIsInitial(contentGeneratorId)) {
        state.isFetchingInitialMessage[generatedContentId] = true;
      }

      if (isAskingFollowUpQuestion(contentGeneratorId)) {
        state.isAskingFollowUp[generatedContentId] = true;

        const message: MessageItem = {
          sender: UserType.user,
          message: question,
          date: new Date().toISOString(),
          speakerUsername: speaker.username,
          // this is a temporary id, we will replace this with the actual id from the server
          id: generateRaiseHandMessageId(
            speaker.platformId,
            UserType.user,
            new Date().toISOString(),
            true,
          ),
          isNewConversation: true,
        };

        state.messages[generatedContentId] =
          state.messages[generatedContentId] ?? [];

        state.messages[generatedContentId].push(message);
      }

      state.isLoading = true;
      state.isTutorTyping[generatedContentId] = true;
    });
    builder.addCase(raiseHandAskQuestion.fulfilled, (state: State, action) => {
      const { speaker, generatedContentId, contentGeneratorId } =
        action.meta.arg;
      const currentScreenOpen =
        state.currentContentScreen === generatedContentId;

      const card = action.payload.card as DmsFromDeadV2Card;
      const { questionTimestamp, response, responseTimestamp } = card;

      // Algorithm on handling this:
      // 1. Assign the response to the dmFigure
      const message: MessageItem = {
        sender: UserType.dmFigure,
        message: response,
        date: responseTimestamp,
        speakerUsername: speaker.username,
        id: generateRaiseHandMessageId(
          generatedContentId,
          UserType.dmFigure,
          responseTimestamp,
        ),
        generatedContentId: card.generatedContentId,

        // 2. If the current screen is open, keep that message to be visible in the chat
        isNewConversation: currentScreenOpen,
      };

      // 3. push the message to the messages for speaker
      state.messages[generatedContentId] =
        state.messages[generatedContentId] ?? [];
      state.messages[generatedContentId].push(message);

      // 4. update the last message of the speaker, from local-generated id to the actual id
      const lastUserMessage = state.messages[generatedContentId].find(m => {
        return m.id.includes('new-conversation');
      });

      if (lastUserMessage) {
        lastUserMessage.id = generateRaiseHandMessageId(
          generatedContentId,
          UserType.user,
          questionTimestamp,
        );
        lastUserMessage.generatedContentId = card.generatedContentId;
      }

      // 5. update the last message of the speaker
      state.isLoading = false;
      state.isTutorTyping[generatedContentId] = false;

      if (checkIfContentGeneratorIsInitial(contentGeneratorId)) {
        state.isFetchingInitialMessage[generatedContentId] = false;
      } else if (isAskingFollowUpQuestion(contentGeneratorId)) {
        state.isAskingFollowUp[generatedContentId] = false;
      }
    });

    builder.addCase(raiseHandAskQuestion.rejected, (state: State, action) => {
      const { speaker, generatedContentId, contentGeneratorId } =
        action.meta.arg;
      const { isStreaming } = action.payload as {
        error: string;
        isStreaming: boolean;
      };

      if (checkIfContentGeneratorIsInitial(contentGeneratorId)) {
        state.isLoading = false;
        state.isTutorTyping[generatedContentId] = false;
        state.isFetchingInitialMessage[generatedContentId] = false;

        state.error = action.payload as string;

        return;
      }

      const currentScreenOpen =
        state.currentContentScreen === generatedContentId;

      const messages = state.messages[generatedContentId] ?? [];
      const lastUserMessage = messages.find(m => {
        return m.id.includes('new-conversation');
      });

      if (lastUserMessage) {
        lastUserMessage.id = generateRaiseHandMessageId(
          generatedContentId,
          UserType.user,
          lastUserMessage.date,
          false,
          true,
        );
      }

      const errorMessage: MessageItem = {
        sender: UserType.error,
        message: locale.dms_screen.chat_response_error,
        date: new Date().toISOString(),
        speakerUsername: speaker.username,
        id: generateRaiseHandMessageId(
          generatedContentId,
          UserType.error,
          new Date().toISOString(),
          false,
          true,
        ),
        isNewConversation: currentScreenOpen,
      };

      if (!isStreaming) {
        messages.push(errorMessage);
      }

      state.messages[generatedContentId] = messages;

      state.isLoading = false;
      state.isTutorTyping[generatedContentId] = false;
      state.isAskingFollowUp[generatedContentId] = false;
      state.error = action.payload as string;
    });

    builder.addCase(getChatHistory.pending, (state: State, action) => {
      const { generatedContentId } = action.meta.arg;
      state.isChatHistoryLoading[generatedContentId] = true;
    });
    builder.addCase(getChatHistory.fulfilled, (state: State, action) => {
      const { generatedContentId } = action.meta.arg;
      const { messages, hasNextPage } = action.payload;

      // algorithm on handling this:
      // 1. sort the new messages from chat history
      const newMessages = (messages || []).sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      // 2. get the current messages from the state
      const currentMessages = state.messages[generatedContentId] ?? [];
      let currentMessagesCopy = [...currentMessages];

      if (!currentMessages.length) {
        // 3. if there are no current messages, just set the new messages
        currentMessagesCopy = newMessages;
      } else if (newMessages.length) {
        // 4. get latest date from the new messages
        const latestDate = newMessages[newMessages.length - 1].date;

        // 5. exclude all the messages that has 'new-conversation' id and date is less than the latest date
        currentMessagesCopy = currentMessagesCopy.filter(message => {
          return !(
            message.id.includes('new-conversation') &&
            new Date(message.date).getTime() < new Date(latestDate).getTime()
          );
        });

        currentMessagesCopy = [...newMessages, ...currentMessagesCopy];

        // 6. make sure all the messages are unique
        const messageIds: Record<string, boolean> = {};
        currentMessagesCopy = currentMessagesCopy.filter(message => {
          if (messageIds[message.id]) {
            return false;
          }
          messageIds[message.id] = true;
          return true;
        });
      }

      // 7. sort the messages ascending
      currentMessagesCopy = currentMessagesCopy.sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      });

      // 8. Edge-case: There's a case when the request timed-out but the BE still safe the response
      // into the database. This will cause the message (the error VS the one from history) to be duplicated.
      const potentialDuplicatedErrorMessages =
        MessagesAlgorithm.getPotentialDuplicatedErrorMessages(
          currentMessagesCopy,
        );

      // 9. Exclude the potential duplicated error messages
      currentMessagesCopy = currentMessagesCopy.filter(
        message =>
          !potentialDuplicatedErrorMessages.some(
            potentialDuplicatedErrorMessage =>
              potentialDuplicatedErrorMessage.id === message.id,
          ),
      );

      // 11. Exclude the first user and error messages for raise hand
      if (!hasNextPage) {
        currentMessagesCopy =
          MessagesAlgorithm.excludeFistUserMessageForRaiseHand(
            currentMessagesCopy || [],
          );
      }

      // 12. Remove the first message when there are two consecutive messages from the same sender with the same message
      currentMessagesCopy =
        MessagesAlgorithm.removeConsecutiveDuplicateMessages(
          currentMessagesCopy,
        );

      // 10. set the messages to the state
      state.messages[generatedContentId] = currentMessagesCopy;
      state.isChatHistoryLoading[generatedContentId] = false;
    });
    builder.addCase(getChatHistory.rejected, (state: State, action) => {
      const { generatedContentId } = action.meta.arg;
      state.isChatHistoryLoading[generatedContentId] = false;
      state.error = action.payload as string;
    });

    builder.addCase(
      feedbackRaiseHandMessage.pending,
      (state: State, action) => {
        const { generatedContentId, feedbackType } = action.meta.arg;

        if (state.likeStatus[generatedContentId] === feedbackType) {
          delete state.likeStatus[generatedContentId];
          return;
        }

        state.likeStatus[generatedContentId] = feedbackType;
      },
    );
    builder.addCase(
      feedbackRaiseHandMessage.rejected,
      (state: State, action) => {
        const { generatedContentId, feedbackType } = action.meta.arg;

        if (state.likeStatus[generatedContentId] === feedbackType) {
          delete state.likeStatus[generatedContentId];
          return;
        }

        state.likeStatus[generatedContentId] = feedbackType;
      },
    );
  },
});

export const getTutorMessages = (
  state: State,
  generatedContentId: string,
): MessageItem[] => {
  return state.messages[generatedContentId] ?? [];
};

export const {
  clearRaiseHandMessageData,
  deleteUnreadMessage,
  setTestPrepInitialMessage,
  setRaiseHandDraftMessage,
  setContentScreen,
  saveDmsFromTheDeadCard,
  setUnreadMessage,
  setUserInteractionHistory,
  setContentCardData,
  setContentDmSpeaker,
  setShouldShowTryMe,
  setTutorTyping,
  resetChatHistoryForGeneratedContentId,
  setIsFetchingInitialMessage,
  assignRaiseHandMessage,
} = raiseHandSlice.actions;

export const RaiseHandSlice = persistReducer(
  persistConfig,
  raiseHandSlice.reducer,
);
