import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAppsFlyerTracker } from '../../trackers';
import { APP_SLICE_NAME } from '../AppSlice';

export const initAppsFlyer = createAsyncThunk(
  `${APP_SLICE_NAME}/initAppsFlyer`,
  async _ => {
    await getAppsFlyerTracker().initSdk();
  },
);
