import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class GoogleFormApi {
  private static instance: GoogleFormApi;
  private apiInstance: Promise<AxiosInstance>;

  private constructor() {
    this.apiInstance = this.createApiInstance();
  }

  public static getInstance(): GoogleFormApi {
    if (!GoogleFormApi.instance) {
      GoogleFormApi.instance = new GoogleFormApi();
    }
    return GoogleFormApi.instance;
  }

  private async createApiInstance(): Promise<AxiosInstance> {
    return axios.create({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public async post<T = any, R = AxiosResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    const api = await this.apiInstance;
    return api.post(url, data, config);
  }
}
